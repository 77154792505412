import React from 'react';
import { Error404Illustration } from '../../assets/icons/icons';
import { TextNavyBlue } from '../../constants/styles/text';
import { ERRORCODE } from '../../constants';
import styled from 'styled-components';

const Error404: React.FC = () => {
    return (
        <Wrapper>
            <div style={{ textAlign: 'center', fontSize: '0' }}>
                <Error404Illustration />
                <TextNavyBlue weight="700" style={{ marginTop: '33px' }}>
                    {ERRORCODE.TITLE404}
                </TextNavyBlue>
                <TextNavyBlue size="12px">
                    Error code:
                    <TextNavyBlue weight="700" size="12px" style={{ display: 'inline-block' }}>
                        404
                    </TextNavyBlue>
                </TextNavyBlue>
                <TextNavyBlue size="12px" style={{ marginTop: '33px' }}>
                    {ERRORCODE.ERROR_MESSAGE_404}
                </TextNavyBlue>
            </div>
        </Wrapper>
    );
};

export default Error404;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 100%;
    border-radius: 8px;
`;
