/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import NavBar from '../../components/organisms/Navbar';
import { LABEL, sideNavButtonsHQ } from '../../constants';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import { IHqPermissions } from '../../utils/permissionTypes';

interface WrapperProps {
    children: React.ReactNode;
}

export const HQCEWrapper = (props: WrapperProps): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { hq } = JSON.parse(userLoginContext.permission);
    const parsedPermission: IHqPermissions['permission'] = hq.permission;
    const {
        accountManagement,
        activityLogs,
        branchOffices,
        ceDashboard,
        // financeDashboard,
        productSettings,
        uploads,
        userManagement,
        eddCase,
        systemSettings,
    } = parsedPermission;

    const getHQSideNavButtons = (): ISideNavMenuItemSection[] => {
        const sideNav: ISideNavMenuItemSection[] = [];
        //Account Management
        const accountSection: ISideNavMenuItemSection = {
            section: LABEL.accountMenuTitle,
            menu: [],
        };
        if (accountManagement.inbox.actions.canReadNotifications === 'maker') {
            sideNavButtonsHQ.account && accountSection.menu.push(sideNavButtonsHQ.account[0]);
        }
        if (accountManagement.profile.actions.canViewStaffDetails === 'maker') {
            sideNavButtonsHQ.account && accountSection.menu.push(sideNavButtonsHQ.account[1]);
        }
        //Activity&Access
        const activitySection: ISideNavMenuItemSection = {
            section: LABEL.accessActivity,
            menu: [],
        };
        const permissionActivitySection = [userManagement.isAll, activityLogs.isAll];
        permissionActivitySection.map((permission, index) => {
            if (permission === 'true' && sideNavButtonsHQ.activityAndAccess) {
                activitySection.menu.push(sideNavButtonsHQ.activityAndAccess[index]);
            }
        });
        // CE
        const customerExperienceSection: ISideNavMenuItemSection = {
            section: LABEL.customerExperienceCE,
            menu: [],
        };
        const permissionsCESection = [ceDashboard.isAll, ceDashboard.pendingTab.isAll, branchOffices.isAll];
        permissionsCESection.map((permission, index) => {
            if (permission === 'true' && sideNavButtonsHQ.customerExperience) {
                customerExperienceSection.menu.push(sideNavButtonsHQ.customerExperience[index]);
            }
        });
        // EDD
        const eddSection: ISideNavMenuItemSection = {
            section: LABEL.EDD,
            menu: [],
        };
        const permissionsEddSection = [eddCase.isAll];
        permissionsEddSection.map((permission, index) => {
            if (permission === 'true' && sideNavButtonsHQ.edd) {
                eddSection.menu.push(sideNavButtonsHQ.edd[index]);
            }
        });
        //TODO Finance and Ops
        // Product&Services
        const productsSection: ISideNavMenuItemSection = {
            section: LABEL.productAndServiceManagement,
            menu: [],
        };
        const permissionsProductsSection = [productSettings.isAll, uploads.isAll];
        permissionsProductsSection.map((permission, index) => {
            if (String(permission) === 'true' && sideNavButtonsHQ.productAndServices) {
                productsSection.menu.push(sideNavButtonsHQ.productAndServices[index]);
            }
        });
        //SystemConfiguration
        const systemConfigurationSection: ISideNavMenuItemSection = {
            section: LABEL.systemConfiguration,
            menu: [],
        };
        const permissionsSystemConfigurationSection = [systemSettings.isAll];
        permissionsSystemConfigurationSection.map((permission, index) => {
            if (permission === 'true' && sideNavButtonsHQ.systemConfiguration) {
                systemConfigurationSection.menu.push(sideNavButtonsHQ.systemConfiguration[index]);
            }
        });
        //UserManagement
        const userManagementSection: ISideNavMenuItemSection = {
            section: LABEL.userManagement,
            menu: [],
        };
        const permissionsUserManagementSection = [userManagement.isAll];
        permissionsUserManagementSection.map((permission, index) => {
            if (permission === 'true' && sideNavButtonsHQ.userManagement) {
                userManagementSection.menu.push(sideNavButtonsHQ.userManagement[index]);
            }
        });
        //Order of items to be pushed in sideNav array first
        // userManagementSection,activitySection,systemConfigurationSection,customerExperience,edd,productAndServices,account
        userManagementSection.menu.length !== 0 ? sideNav.push(userManagementSection) : null;
        activitySection.menu.length !== 0 ? sideNav.push(activitySection) : null;
        systemConfigurationSection.menu.length !== 0 ? sideNav.push(systemConfigurationSection) : null;
        customerExperienceSection.menu.length !== 0 ? sideNav.push(customerExperienceSection) : null;
        eddSection.menu.length !== 0 ? sideNav.push(eddSection) : null;
        productsSection.menu.length !== 0 ? sideNav.push(productsSection) : null;
        accountSection.menu.length !== 0 ? sideNav.push(accountSection) : null;
        return sideNav;
    };
    return (
        <>
            <Layout style={{ display: 'flex', flexDirection: 'row' }} data-testid="wrapper">
                <NavBar navData={getHQSideNavButtons()} />
                <Layout>
                    <NavigatorContainer className="navigator" data-testid="container">
                        {props.children}
                    </NavigatorContainer>
                </Layout>
            </Layout>
        </>
    );
};

export default HQCEWrapper;
const Layout = styled.section`
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #f0f2f5;
`;
const NavigatorContainer = styled.div`
    border-radius: 22px;
    position: absolute;
    min-height: 96.2vh;
    padding-top: 2rem;
    width: 81.111vw;
    height: 100%;
    left: 17.222vw;
`;
