/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useState } from 'react';
import { LABEL, EMPTY_STATES } from '../../../constants';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../../routes';
import { subTitleStyle, sh38, sh80, sh112, sh120, sh192, sh96, sh128, sh152 } from '../../../styles';
import { statusStyle } from '../../../utils';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import CustomItem from './CustomItem';
import ReceiveHardCopy from './ReceiveHardCopy';
import { API, graphqlOperation } from 'aws-amplify';
import { createTransactionsRef } from '../../../_graphql/mutations/branchCE/receiveHardcopy/createTransactionsRef';
import { IHardcopyTab } from '../../../utils/permissionTypes';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';

interface IWithHardcopyProps {
    handleReceived: () => void;
    hardcopyTab: IHardcopyTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    searchInput: string;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
}

export const WithHardcopy: React.FC<IWithHardcopyProps> = ({
    handleReceived,
    hardcopyTab,
    idTokenHeader,
    isSearchResult,
    isFilterApplied,
    searchInput,
    setSortData,
    sortData,
    setErrorHandler,
    setErrorMessage,
}: IWithHardcopyProps) => {
    const history = useHistory();
    const { hardcopy } = useContext(DashboardBranchContext);
    const [eventClicked, setEventClicked] = useState<boolean>(false);
    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data = {
            orderNo: item.rawData.orderNo,
            tab: 'hardcopy',
            endpoint: 'branchDashboard',
            url: history.location.pathname,
        };
        const trxType = item.rawData.transactionType as string;
        const result = trxType.match(/(Change Request)|(CR)/);
        result && result[0] === null
            ? history.push(`${history.location.pathname}/${Routes.orderSummary}`, data)
            : history.push(`${history.location.pathname}/${Routes.orderSummaryChangeRequest}`, data);
    };
    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleReceiveHardcopy = async (item: IColumnItemAccordion) => {
        setEventClicked(true);
        try {
            const response: any = await API.graphql(
                graphqlOperation(createTransactionsRef, {
                    input: {
                        orderNo: item.rawData.orderNo,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.createTransactionsRef;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.createTransactionsRef.data;
            if (result.status === true) {
                handleReceived();
                setEventClicked(false);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setEventClicked(false);
            setErrorHandler();
            setErrorMessage({
                title: 'Cannot mark as Received',
                message: _error.message,
                errorCode: _error.errorCode,
                testId: 'branch-error-modal',
            });
        }
    };
    const columnsWithHardcopy: ITableColumn[] = [
        {
            key: [{ key: 'submittedOn' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.submittedOn,
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === 'submittedOn' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('submittedOn'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('submittedOn'),
        },
        {
            key: [{ key: 'clientName' }, { key: 'clientIdNum', textStyle: subTitleStyle }, { key: 'accountType' }],
            viewStyle: {
                width: sh192,
            },
            title: LABEL.investorName,
            subtitle: LABEL.idNo,
            customItem: true,
            icon: {
                name: handleSortIcon('clientName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('clientName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'clientName' ? 700 : 400,
            },
        },
        {
            key: [
                { key: 'orderNo', textStyle: { fontWeight: sortData[0].column === 'orderNo' ? 700 : 400 } },
                { key: 'transactionRef', textStyle: subTitleStyle },
            ],

            viewStyle: {
                width: sh112,
            },
            icon: {
                name: handleSortIcon('orderNo'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('orderNo'),
            titleStyle: {
                fontWeight: sortData[0].column === 'orderNo' ? 700 : 400,
            },
            title: LABEL.orderNo,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh96,
            },
            icon: {
                name: 'arrow-down',
                size: '1rem',
                style: { opacity: '0.2' },
            },
            title: LABEL.transTypes,
        },
        {
            key: [{ key: 'fundType' }, { key: 'paymentMethod', textStyle: subTitleStyle }],
            viewStyle: {
                width: sh80,
            },
            title: LABEL.products,
            subtitle: LABEL.fundType,
        },
        {
            key: [{ key: 'totalInvestment', textStyle: { fontWeight: 700 } }],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.totalInvestments,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },

        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh152,
            },
            title: LABEL.status,
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: hardcopyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                hardcopyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'withhardcopy-view-column',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                actionsLabel={LABEL.receive}
                data={hardcopy.current}
                columns={columnsWithHardcopy}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableWithHardcopy
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                RenderOptions={
                    hardcopyTab.actions.canReceiveHardCopy === LABEL.maker
                        ? (props: ITableOptions) => (
                              <ReceiveHardCopy
                                  {...props}
                                  handleReceiveHardcopy={handleReceiveHardcopy}
                                  hardcopyTab={hardcopyTab}
                                  eventClicked={eventClicked}
                              />
                          )
                        : undefined
                }
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                testId="branchwithhardcopy"
            />
        </Fragment>
    );
};
