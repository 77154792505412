/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, ReactText, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL } from '../../../constants';
import { CustomButton, FlexedDiv, PreviousPage, TextArea, Modal } from '../../../components';
import { reasonsPageButtons } from '../../../styles';
import approveReject from '../../../_graphql/mutations/systemAdmin/approveReject';
import * as Routes from '../../../routes';
import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
interface IRouteProps {
    uploadId: string;
}

interface IBulkUserApproval {
    requestId: string;
    action: string;
    remarks: ReactText;
}
const initialCheckerModal = {
    requestId: '',
    action: '',
    remarks: '',
};
const RejectBulkImportUsers: React.FC = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(true);
    const [checkerModal, setCheckerModal] = useState<IBulkUserApproval>(initialCheckerModal);

    const [remarks, setRemarks] = useState<string>('');
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { state: propsData } = useLocation<IRouteProps>();

    const submitHandler = () => {
        const checkerPayload: IBulkUserApproval = { ...checkerModal };
        checkerPayload.remarks = remarks;
        rejectBulkImportUsersMutation(checkerPayload);
    };

    //mutation function to reject bulk import users
    const rejectBulkImportUsersMutation = async (checkerPayload: IBulkUserApproval) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: checkerPayload,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.approveReject;
            if (!error) {
                handleShowConfirmation();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    testId: 'bulkusers-error-modal',
                    title: 'Bulk Import User request rejected',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };
    const goBacktoDashboard = () => {
        history.push(Routes.dashboardSystemAdmin);
    };
    useEffect(() => {
        setCheckerModal({
            ...checkerModal,
            requestId: propsData.uploadId,
            action: 'reject',
        });
    }, []);
    return (
        <Fragment>
            <PreviousPage title={LABEL.rejectRemark} />
            <StyledInner>
                <PageDescription>You’re about to reject this request.</PageDescription>
                <TextArea
                    label="Remarks"
                    name="remarks"
                    value={remarks}
                    handleInput={handleInput}
                    maxLength={255}
                    style={{ width: '492px' }}
                />
                <ButtonDiv>
                    <CustomButton style={reasonsPageButtons} onClick={() => history.goBack()}>
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary
                        style={reasonsPageButtons}
                        onClick={() => submitHandler()}
                        disabled={remarks.trim() === ''}
                    >
                        {LABEL.submit}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={() => handleShowConfirmation()}
                    title="Bulk Import User request rejected"
                    primaryBtn={{
                        onClick: () => {
                            goBacktoDashboard();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="bulkusers-reject-modal"
                    icon="user-modal-success"
                ></Modal>
            ) : null}
        </Fragment>
    );
};

const StyledInner = styled.div`
    margin-left: 3.5rem;
`;

const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;

export default RejectBulkImportUsers;
