/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, ChangeEvent, useRef, useMemo, useContext, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { CustomButton, CustomSpacer, FlexedDiv } from '../../components/atoms';
import {
    SearchBar,
    ComponentLoader,
    ISelectValue,
    DatePicker,
    SelectDropdown,
    Dashboard,
    TrackOrderModal,
} from '../../components';
import moment from 'moment';
import { LABEL, SearchOptionsBranch, TextDarkBlack, TextDarkGrey6 } from '../../constants';
import { IFilterBranch } from '../DashboardBranch/dashboardBranchTypes';
import { exportList } from '../../utils/exportListBranchDashboard';
import { dateSortingPendingSubmission } from '../DashboardBranch/helpers/filterDropdownsBranch';
import { clearContextOnNavigation, removeArrayElement } from '../../utils';
import { useDebounce } from '../../customHooks/useDebounce';
import { ICeDashboard } from '../../utils/permissionTypes';
import AuthContext from '../../context/AuthContext';
import { useHistory } from 'react-router';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import styled from 'styled-components';
import * as ROUTES from '../../routes';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import { SearchBarFilterTags } from '../../templates/SearchBarFilterTags';
import { getFilterTagLabels, removeSearchFilterTagFromFilter } from '../DashboardBranch/helpers/getFilterTagLabels';
import { pickSearchLabel } from '../DashboardBranch/helpers/pickSearchLabel';
import Incomplete from './Incomplete';
import PendingSubmissionContext from '../../context/PendingSubmissionContext/PendingSubmissionContext';
import { pendingAllSubmissionExportList } from '../../_graphql/queries/branchCE/transaction/pendingSubmission/exportListAllPendingSubmission';
import { pendingSubmissionExportList } from '../../_graphql/queries/branchCE/transaction/pendingSubmission/exportListPendingSubmission';
import { decoupleGroupedTrx } from '../DashboardBranch/decoupleGroupedTrx';
import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';

const initialInCompleteTabFilter: ISort_FilterInput = [
    { column: 'createdOn', value: '' },
    { column: 'tab', value: 'all' },
    { column: 'transactionType', value: '' },
    { column: 'accountType', value: '' },
];

export const PendingSubmission: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);

    const {
        dateFilter,
        disableResultLimit,
        filterInput,
        filterSelectStates,
        getAllPendingSubmissionDashboardCE,
        getPendingSubmissionDashboardCE,
        loading,
        loadingHandler,
        page,
        pages,
        resultLimit,
        searchbarFilterTags,
        searchInput,
        setDateFilter,
        setFilterInput,
        setFilterSelectStates,
        setPage,
        setResultLimit,
        setSearchbarFilterTags,
        setSearchInput,
        setSortData,
        setStatusesCount,
        setStatusPills,
        setTab,
        setTempFilters,
        setTempTarget,
        sortData,
        statusesCount,
        statusPills,
        tab,
        tabsCount,
        tempFilters,
        tempTargetDate,
        accountTypeOptions,
        transactionTypeOptions,
        filterAccountTypes,
        setFilterAccountTypes,
    } = useContext(PendingSubmissionContext);

    const { trackOrderInput, setTrackOrderInput, onTrackOrderSearch, trackSearchOptions, setPreviousPageURL } =
        useContext(TrackOrderContext);

    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // get permission object from session storage
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;
    const { pendingTab } = parsedPermissionPrivilege;
    const history = useHistory();
    const branchId = sessionStorage.getItem('branchId');
    // Common States
    const [createdOn, setCreatedOn] = useState<string>('Created On');
    const [expand, setExpand] = useState<boolean>(false); //Filter expansion Panel Visibility State
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State
    const [trackModal, setTrackModal] = useState<boolean>(false); //modal for track order
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>(''); // datepicker error messages state
    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 700);
    const initialRenderTabs = useRef(true);
    const initialRenderFilters = useRef(true);
    /** @function Handle Search Placeholders for each Tab  */
    const handlePlaceHolder = () => {
        const placeholder =
            LABEL.branchIncompleteSearch +
            ' ' +
            `${
                searchInput.column !== 'all'
                    ? `by ${pickSearchLabel(searchInput.column)}`
                    : 'by Adviser Name & Code, Investor Name & ID, Order Number'
            }`;
        return placeholder;
    };

    const TrackOrderButton: IActionButton = {
        title: 'Track Order',
        type: 'secondary',
        isDropdown: false,
        actionIcon: 'trackIcon',
        testId: 'track-order',
        disabled: loading,
    };

    const reInitializeDashboard = () => {
        setPage(1);
        setSearchInput({ value: '', column: 'all' });
        setFilterSelectStates({ transactionType: '' });
        setFilterAccountTypes([]);
        setDateFilter({ dateSort: 'createdOn', range: '' });
        setStatusesCount({ statusCount: [], statusTags: [] });
        setSortData({ column: 'createdOn', value: 'ascending' });
        setCreatedOn('Created On');
        setTempTarget([null, null] as [moment.Moment | null, moment.Moment | null]);
        setSearchbarFilterTags([]);
        setTempFilters(initialInCompleteTabFilter);
        setFilterInput(initialInCompleteTabFilter);
    };
    const clearContext = () => {
        setPage(1);
        setSearchInput({ value: '', column: 'all' });
        setFilterSelectStates({ transactionType: '' });
        setFilterAccountTypes([]);
        setDateFilter({ dateSort: 'createdOn', range: '' });
        setStatusesCount({ statusCount: [], statusTags: [] });
        setStatusPills({ tab: 0, label: LABEL.status, tags: [] });
        setCreatedOn('Created On');
        setTempTarget([null, null] as [moment.Moment | null, moment.Moment | null]);
        setSearchbarFilterTags([]);
        setTempFilters(initialInCompleteTabFilter);
        setFilterInput(initialInCompleteTabFilter);
        setTab(0);
    };

    /**@function Fetch data based  tab given @param tab number */
    const fetchData = () => {
        history.location.pathname === ROUTES.hqAllPendingSubmission
            ? getAllPendingSubmissionDashboardCE()
            : getPendingSubmissionDashboardCE();
    };

    //Queries

    const getExportList = async () => {
        try {
            const exportQuery =
                history.location.pathname === ROUTES.hqAllPendingSubmission
                    ? pendingAllSubmissionExportList
                    : pendingSubmissionExportList;
            const _endPoint =
                history.location.pathname === ROUTES.hqAllPendingSubmission
                    ? 'allpendingdashboard'
                    : 'pendingsubmissiondashboard';
            const response: any = await API.graphql(
                graphqlOperation(exportQuery, {
                    input: {
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filterInput,
                        endpoint: _endPoint,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;
            const decoupledData =
                history.location.pathname === ROUTES.hqAllPendingSubmission
                    ? decoupleGroupedTrx(result.allpendingdashboard.grouping)
                    : decoupleGroupedTrx(result.pendingsubmissiondashboard.grouping);
            exportList('Pending Submissions', 'incomplete', decoupledData);
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'branch-error-modal',
            });
        }
    };

    const trackOrder = () => {
        if (history.location.pathname === ROUTES.hqAllPendingSubmission)
            history.push(ROUTES.hqAllPendingSubmissionTrackOrder);
        else
            history.push(
                branchId !== null && branchId !== ''
                    ? ROUTES.hqPendingSubmissionTrackOrder
                    : ROUTES.branchPendingSubmissionTrackOrder,
            );
    };
    //Fn for ExternalLink for Searchbar
    const getExternalLink = (): ExternalLink | undefined => {
        let temp = undefined;
        if (history.location.pathname === '/hq/dashboard/view-branch/pending-submission')
            temp = { title: 'View Submitted Order', link: '/hq/dashboard/view-branch' };
        return temp;
    };
    //Pagination Functions
    const handleNext = () => {
        if (page < pages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const switchTabs = (index: number) => {
        setTab(index);
    };

    // Search Secondary Actions
    const handleSearchActions = (action: IActionButton) => {
        action.title === 'Export List' ? getExportList() : null;
    };

    // opens modal for track order
    const OpenTrackOrderModal = () => {
        setTrackModal(true);
    };

    const closeTrackModal = () => {
        setTrackModal(false);
    };

    const handlePillStatuses = (item: IDashboardFilterTags) => {
        const temp = [...tempFilters];
        const tempPill = statusPills;
        const itemIndex = item.index;
        if (itemIndex === 0 && item.active === false) {
            temp[1] = { column: 'tab', value: 'all' };
            tempPill.tags[0].active = true;
            for (let i = 0; i < tempPill.tags.length; i++) {
                i !== 0 ? (tempPill.tags[i].active = false) : null;
            }
        } else if (itemIndex !== 0 && item.active === false) {
            temp[1] = { column: 'tab', value: item.title.toLowerCase() };
            tempPill.tags[itemIndex].active = true;
            for (let i = 0; i < tempPill.tags.length; i++) {
                i !== itemIndex ? (tempPill.tags[i].active = false) : null;
            }
        }
        setStatusPills(tempPill);
        setTempFilters(temp);
        setFilterInput(temp);
    };

    // Common filters
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            setDateFilter({ dateSort: dateFilter.dateSort, range: range });
            const obj: IFilterBranch = {
                column: dateFilter.dateSort,
                value: range,
            };
            const tmp = [...tempFilters];
            tmp[0] = obj;
            setTempFilters(tmp);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            const tmp = [...tempFilters];
            tmp[0] = {
                column: dateFilter.dateSort,
                value: '',
            };
            setTempFilters(tmp);
        }
    };
    const handleDateSorting = (value: string | number) => {
        const tmp = [...tempFilters];
        setDateFilter({ ...dateFilter, dateSort: value.toString() });
        tmp[0].column = value.toString();
        setTempFilters(tmp);
    };

    //Commented out as not used now but may use to for next phase
    // const handleTransactionTypes = (value: SelectValue) => {
    //     const tmp = [...tempFilters];
    //     const obj = {
    //         column: 'transactionType',
    //         value: value.toString(),
    //     };
    //     tmp.push(obj);
    //     setTempFilters(tmp);
    //     setFilterSelectStates({ ...filterSelectStates, transactionType: value.toString() });
    // };

    const handleAccountTypes = (item: React.ReactText[]) => {
        const tmp = [...tempFilters];
        setFilterAccountTypes(item);
        const newFilter = tmp.filter((item, index) => item.column !== 'accountType' || index === 3);
        item.map((_item) => {
            newFilter.push({ column: 'accountType', value: _item as string });
        });
        setTempFilters(newFilter);
    };

    const handleCancelFilter = () => {
        const temp = [...tempFilters];
        temp[temp.findIndex((ele) => ele.column === 'tab')].value =
            statusPills.tags[statusPills.tags.findIndex((tag) => tag.active)].title.toLowerCase() ?? 'all';
        temp.filter((ele) => (ele.column !== 'tab' ? (ele.value = '') : null));
        const tempSort = { ...sortData };
        setCreatedOn(LABEL.createdOn);
        setDateFilter({ dateSort: 'createdOn', range: '' });
        tempSort.column === 'lastUpdated' ? setSortData({ column: 'createdOn', value: 'ascending' }) : null;
        setSearchbarFilterTags([]);
        setFilterAccountTypes([]);
        setFilterSelectStates({ transactionType: 'SALES-AO' });
        setTempTarget([null, null]);
        setTempFilters(temp);
        setFilterInput(temp);
    };

    const handleApplyFilter = () => {
        let _searchFilterTags: ISelectValue[] = [];
        let currentFilterLabel = '';
        switch (tempFilters[0].column) {
            case 'lastUpdated':
                currentFilterLabel = LABEL.lastUpdated;
                break;
            case 'createdOn':
                currentFilterLabel = LABEL.createdOn;
                break;
        }
        setCreatedOn(currentFilterLabel);
        _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
        setSearchbarFilterTags(_searchFilterTags);
        setFilterInput(tempFilters);
        setSortData({ column: tempFilters[0].column, value: 'ascending' });
        setExpand(!expand);
    };

    const getSecondaryButtons = () => {
        const secondaryButtons: IActionButton[] = [];
        if (parsedPermissionPrivilege.pendingTab.actions.canExportList === LABEL.maker) {
            secondaryButtons.push({
                type: 'secondary',
                title: 'Export List',
                actionIcon: 'export',
                disabled: tabsCount[tab] === 0 || loading,
                testId: 'export-list-btn',
            });
        }
        return secondaryButtons;
    };

    const sameFilter = JSON.stringify(filterInput) !== JSON.stringify(initialInCompleteTabFilter);

    const tabs: IDashboardTabs[] = [];
    if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'incomplete',
            table: (
                <Incomplete
                    filters={filterInput}
                    submittedOnDropdown={createdOn}
                    searchInput={searchInput.value ?? ''}
                    setFilters={setFilterInput}
                    setSubmittedOnDropdown={setCreatedOn}
                    setSortData={setSortData}
                    sortData={sortData}
                    pendingTab={pendingTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={sameFilter}
                    loadingHandler={loadingHandler}
                />
            ),
            testId: 'incomplete-tab',
        });
    }

    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsBranch.pending.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `branch-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    const handleRemoveFilter = (item: ISelectValue, index: number) => {
        let tempValues = [...searchbarFilterTags];
        tempValues = removeArrayElement(tempValues, tempValues[index]);
        setSearchbarFilterTags(tempValues);
        switch (item.label) {
            case LABEL.accountTypes:
                const tempAccountTypes = [...filterAccountTypes];
                let arrayIndex = -1;
                arrayIndex = tempAccountTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempAccountTypes.splice(arrayIndex, 1) : null;
                setFilterAccountTypes(tempAccountTypes);
                break;
            case LABEL.lastUpdated:
            case LABEL.submittedOn:
            case LABEL.createdOn:
                setTempTarget([null, null]);
                break;
        }
        let _tempFilters = [...tempFilters];
        _tempFilters = removeSearchFilterTagFromFilter(item, tempFilters);
        setTempFilters(_tempFilters);
        setFilterInput(_tempFilters);
    };
    // Main LifeCycle Hooks Resets everything
    useEffect(() => {
        setPreviousPageURL(window.location.pathname);
        if (initialRenderTabs.current) {
            setTab(0);
            setDateFilter({ dateSort: 'createdOn', range: '' });
            fetchData();
            initialRenderTabs.current = false;
        } else {
            initialRenderFilters.current = true;
            reInitializeDashboard();
        }
        return function cleanup() {
            !clearContextOnNavigation('pendingSubmission', window.location.pathname) ? clearContext() : null;
        };
    }, [tab]);
    //  Fetch data based on Updates in any dependents
    useMemo(() => {
        if (initialRenderFilters.current) {
            initialRenderFilters.current = false;
        } else {
            fetchData();
        }
    }, [page, resultLimit, debouncedSearchTerm, filterInput, sortData]);

    useMemo(() => {
        setStatusPills({ ...statusPills, tags: statusesCount.statusTags });
    }, [statusesCount.statusTags]);

    return (
        <Fragment>
            <SearchBar
                expand={expand}
                externalLink={getExternalLink()}
                setExpand={setExpand}
                placeHolder={handlePlaceHolder()}
                searchInput={searchInput}
                searchTitle={LABEL.pendingSubmissionOrder}
                dropDownOptions={searchOptions()}
                data-testid="branch-searchbar"
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                filter={true}
                secondaryButton={getSecondaryButtons()}
                tertiaryButton={[TrackOrderButton]}
                handlePrimary={handleSearchActions}
                handleSecondary={handleSearchActions}
                handleTertiary={OpenTrackOrderModal}
                testId="branch-searchbar"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                disableFilters={tabsCount[0] === 0 || loading}
                filterTags={searchbarFilterTags}
                showTags={showAllTags}
                disabled={loading}
            >
                <Fragment>
                    {expand ? (
                        <FlexedDiv direction="column">
                            <TextDarkBlack weight="700">{LABEL.filterOrdersBy}</TextDarkBlack>
                            <CustomSpacer space={'0.5rem'} />
                            <FlexedDiv direction="row">
                                <FlexedDiv direction="column" style={{ marginRight: '1.5rem' }}>
                                    <TextDarkGrey6 weight="700" size="12px">
                                        {LABEL.dateSorting}
                                    </TextDarkGrey6>
                                    <SelectWrapper>
                                        <SelectDropdown
                                            onChange={handleDateSorting}
                                            options={dateSortingPendingSubmission}
                                            testId={`date-dropdown-filter`}
                                            selectedValue={dateFilter.dateSort}
                                            placeHolder="Select one or more"
                                        />
                                    </SelectWrapper>
                                </FlexedDiv>
                                <FlexedDiv direction="column">
                                    <TextDarkGrey6 weight="700" size="12px" style={{ marginBottom: '4px' }}>
                                        {LABEL.dateRange}
                                    </TextDarkGrey6>
                                    <DatePicker
                                        // label="Date Range"
                                        setTargetDate={(date) => {
                                            const start: moment.Moment = date?.[0] as moment.Moment;
                                            const end: moment.Moment = date?.[1] as moment.Moment;
                                            date?.[0] !== null ? start.set({ h: 0, m: 0, s: 0 }) : null;
                                            date?.[1] ? end.set({ h: 23, m: 59, s: 59 }) : null;
                                            // Setting Date range state for Date picker
                                            setTempTarget([start, end] as [moment.Moment | null, moment.Moment | null]);
                                            handleDate([start, end] as [moment.Moment | null, moment.Moment | null]);
                                        }}
                                        targetDate={tempTargetDate}
                                        range={true}
                                        primaryBorderColor="#002043"
                                        errorMessage={datePickerInvalidMessage}
                                        setErrorMessage={setDatePickerInvalidMessage}
                                    />
                                </FlexedDiv>
                            </FlexedDiv>
                            <DashedLine />
                            <FlexedDiv wrap="wrap">
                                <FlexedDiv direction="column" style={{ marginRight: '1.5rem' }}>
                                    <TextDarkGrey6 weight="700" size="12px">
                                        {LABEL.transactionTypeLong}
                                    </TextDarkGrey6>
                                    <SelectWrapper>
                                        <SelectDropdown
                                            onChange={handleDateSorting}
                                            options={transactionTypeOptions}
                                            testId={`transactionType-dropdown-filter`}
                                            selectedValue={filterSelectStates.transactionType || ''}
                                            isDisabled={transactionTypeOptions.length <= 1}
                                        />
                                    </SelectWrapper>
                                </FlexedDiv>
                                <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                                    <MultiSelect
                                        checkedState={filterAccountTypes.filter((options) =>
                                            accountTypeOptions.includes(options),
                                        )}
                                        handleMultiSelect={handleAccountTypes}
                                        label={LABEL.accountTypes}
                                        placeHolder={LABEL.selectOneOrMore}
                                        options={accountTypeOptions}
                                        selectId={1}
                                        noOverlay={false}
                                        testId={`account-dropdown-filter`}
                                    />
                                </FlexedDiv>
                            </FlexedDiv>
                            <FlexedDiv style={{ marginTop: '2.5rem' }} justifyContent="center">
                                <CustomButton
                                    onClick={() => {
                                        handleCancelFilter();
                                        setExpand(!expand);
                                    }}
                                    style={{ width: '16.67vw' }}
                                    size="large"
                                    id="brach-reset-btn"
                                >
                                    {LABEL.reset}
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    primary={true}
                                    onClick={handleApplyFilter}
                                    style={{ width: '16.67vw' }}
                                    id="brach-apply-btn"
                                    size="large"
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </FlexedDiv>
                        </FlexedDiv>
                    ) : (
                        <SearchBarFilterTags
                            tags={searchbarFilterTags.filter((tag) => {
                                if (tag.label === 'Account Type') {
                                    return accountTypeOptions.includes(tag.value);
                                }
                                // else if (tag.label === 'Transaction Type') {
                                //     return (
                                //         transactionTypeOptions.findIndex(
                                //             (option) => option.value.toLowerCase() === tag.value.toLowerCase(),
                                //         ) !== -1
                                //     );
                                // }
                                else {
                                    return true;
                                }
                            })}
                            showAll={showAllTags}
                            handleShowAll={() => setShowAllTags(!showAllTags)}
                            handleClearAll={handleCancelFilter}
                            handleRemoveTag={handleRemoveFilter}
                            testId="branch-dashboard"
                        />
                    )}
                </Fragment>
            </SearchBar>
            <div style={{ position: 'relative' }}>
                <Dashboard
                    tabs={tabs}
                    selectedTab={tab}
                    orderCounter={tabsCount}
                    switchTabs={switchTabs}
                    pills={statusPills}
                    onClickPills={handlePillStatuses}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    pillCount={statusesCount.statusCount}
                    page={page}
                    maxPage={pages}
                    resultLimit={resultLimit}
                    setResultLimit={setResultLimit}
                    testId="branch"
                    disableResultLimit={disableResultLimit}
                    setCurrentPage={setPage}
                    adjustTabMargin={searchbarFilterTags.length !== 0}
                />

                {/* track order modal */}
                {trackModal ? (
                    <TrackOrderModal
                        modalActive={trackModal}
                        setModalActive={setTrackModal}
                        trackOrder={trackOrder}
                        closeTrackModal={closeTrackModal}
                        trackOrderInput={trackOrderInput}
                        trackSearchOptions={trackSearchOptions()}
                        onTrackOrderSearch={onTrackOrderSearch}
                        setTrackOrderInput={setTrackOrderInput}
                        title="Track Order"
                        testId="track-order-modal"
                    />
                ) : null}

                {/* Loading Component */}
                {loading ? <ComponentLoader /> : null}
            </div>
        </Fragment>
    );
};

const SelectWrapper = styled.div`
    min-width: 360px;
    max-width: 600px;
    width: 18.33vw;
    height: 2.5rem;
`;
const DashedLine = styled.div`
    height: 0;
    border: 1px dashed #cecece;
    margin: 1.5rem 0rem;
`;
