import './index.less';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import awsconfig from './aws-exports';
import { COGNITO_CONFIG } from './aws-userpools';
import STORAGE_CONFIG from './aws-storage';
import * as serviceWorker from './serviceWorker';
import packageJson from '../package.json';
console.log('Current-Build-Version', packageJson.version);
console.log('Build-Version', process.env.REACT_APP_VERSION);

Amplify.configure({
    ...awsconfig,
    ...COGNITO_CONFIG,
    Storage: {
        AWSS3: {
            ...STORAGE_CONFIG,
        },
    },
});

export interface ErrorFallbackProps {
    error: Error;
}
function ErrorFallback({ error }: ErrorFallbackProps) {
    return (
        <div role="alert">
            <p>An Error/Something Occurred during runtime that caused the app to fail load successfully: </p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
        </div>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

serviceWorker.unregister();
