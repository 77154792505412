import gql from 'graphql-tag';

/**
 * @fundDaraDashboardQuery @query - Fund Summary
 * @description:  Returns fund summary for fundId
 */

export const fundSummaryQuery = gql`
    query fundSummary($input: FundSummaryInput) {
        fundSummary(input: $input) {
            data {
                result {
                    fundName
                    utmc
                    description
                    fundSummary {
                        nonBusinessDayFrom
                        nonBusinessDayTo
                        fundCode
                        fundCategory
                        fundProcessingGroup
                        fundDescription
                        fundType
                        isSyariah
                        fundClass
                        trustee
                        scProcessingGroup
                        fundStartDate
                        iopEndDate
                        maturityDate
                        commercialStartDate
                        lipperCode
                        felSharing
                        ageGroup
                        riskCategory
                        closedEnd
                        transactionsAfterIopAllow
                        minimumBalanceHoldingUnits
                        distributedBy
                        isFea
                        landingFund
                    }
                    investmentSummary {
                        instruction
                        dividentTiming
                        minimumDividentPayoutAmount
                        dailyIncomeDistribution
                        fundBaseCurrency
                        fundClassCurrency
                        isAipAllowed
                        coolOffDays
                        moneySightedFund
                        dedicatedFund
                        differenceFundPrice
                        salesSettlementDay
                    }
                    feesSummary {
                        issuePrice
                        annualManagementFee
                        annualTrusteeFee
                        ampFee
                        minSalesFees
                        maxSalesFees
                        exitFeesCharge
                        exitFeesChargeAmount
                        gstParameter
                    }
                    transferSummary {
                        transactionsAfterIopTransfer
                        minTransferOutUnits
                        transferFee
                    }
                    topUpSummary {
                        transactionsAfterIopTopUpSales
                        minTopUpAmount
                        maxTopUpAmount
                    }
                    salesSummary {
                        transactionsAfterIopNewSales
                        minNewSalesAmount
                        maxNewSalesAmount
                        minBalanceHoldingUnits
                        salesCutOffTime
                        batchCutOffTime
                    }
                    epfSummary {
                        epfApproved
                        epfStatus
                        minSalesFee
                        maxSalesFee
                        minNewSalesAmountEpf
                        maxNewSalesAmountEpf
                        minTopupAmountEpf
                        maxTopupAmountEpf
                        exitFeesCharge
                        exitFeesChargeAmount
                    }
                    switchingSummary {
                        switchingType
                        switchingFee
                        transactionsAfterIopSwitchIn
                        transactionsAfterIopSwitchOut
                        minSwitchOutUnits
                        switchingSettlementDate
                        switchingGroup
                        minSalesAmountEpf
                        minSalesAmountNonEpf
                        minTopUpEpf
                        minTopUpNonEpf
                    }
                    redemptionSummary {
                        redemptionType
                        minRedemptionUnits
                        redemptionSettleDate
                        redemption
                        redemptionCoolOff
                        redemptionCutOffTime
                        batchCutOffTime
                    }
                    filters {
                        fundClass
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
