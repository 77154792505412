import React, { Dispatch } from 'react';
import { IDropdownWithKey } from '../../../pages';
import { IFilterBranch } from '../../../pages/DashboardBranch/dashboardBranchTypes';

export interface AdvisersContextProps {
    adviserCount: number;
    adviserData: ITableData[];
    createdOnDropdown: IDropdownWithKey;
    currentPage: number;
    dateSorting: string;
    filters: IColumnValue[];
    getAdviserBranchData: () => Promise<void>;
    loading: boolean;
    loadingHandler: () => void;
    maxPages: number;
    resultLimit: number;
    searchInput: IFilterBranch;
    setAdviserCount: Dispatch<React.SetStateAction<number>>;
    setAdviserData: Dispatch<React.SetStateAction<ITableData[]>>;
    setCreatedOnDropdown: Dispatch<React.SetStateAction<IDropdownWithKey>>;
    setCurrentPage: Dispatch<React.SetStateAction<number>>;
    setDateSorting: Dispatch<React.SetStateAction<string>>;
    setFilters: Dispatch<React.SetStateAction<IColumnValue[]>>;
    setMaxPages: Dispatch<React.SetStateAction<number>>;
    setResultLimit: Dispatch<React.SetStateAction<number>>;
    setSearchInput: Dispatch<React.SetStateAction<IFilterBranch>>;
    setSortData: Dispatch<React.SetStateAction<ISort_FilterInput>>;
    setStatus: Dispatch<React.SetStateAction<string>>;
    setTab: Dispatch<React.SetStateAction<number>>;
    setTargetRange: Dispatch<React.SetStateAction<[moment.Moment | null, moment.Moment | null]>>;
    setTempFilters: React.Dispatch<React.SetStateAction<IColumnValue[]>>;
    sortData: ISort_FilterInput;
    status: string;
    tab: number;
    targetDateRange: [moment.Moment | null, moment.Moment | null];
    tempFilters: IColumnValue[];
}

const AdvisersContext = React.createContext({} as AdvisersContextProps);

export default AdvisersContext;
