import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Collapsible, FlexedDiv } from '../../../components';
import { Fs18BoldDarkGrey, LABEL, TextDarkBlack, TextNavyBlue } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { MultiSection } from '../../../templates';

const physicalCopyAccountData: SectionIntro = {
    title: 'Account',
};

export const DocumentSummary = (props: IDocumentSummaryProps): JSX.Element => {
    const [expandSoftCopy, setExpandSoftCopy] = useState<boolean>(true);
    const [expandPhysical, setExpandPhysical] = useState<boolean>(false);

    const toggleExpandCollapse = () => {
        if (expandSoftCopy && expandPhysical) {
            setExpandSoftCopy(false);
            setExpandPhysical(false);
        } else {
            setExpandSoftCopy(true);
            setExpandPhysical(true);
        }
    };

    const getPhysicalCopyAccountSection = (): ISection[] | ISectionWithSubSections[] => {
        if (!props.jointAcc && props.accDocs?.length) {
            return [
                {
                    sectionTitle: physicalCopyAccountData,
                    data: props.accDocs[0].documents,
                },
            ];
        } else if (props.accDocs?.length && props.jointAcc) {
            return [
                {
                    sectionTitle: physicalCopyAccountData,
                    subSection: props.accDocs.map((acc) => {
                        return {
                            sectionTitle: { title: acc.mainHeader, rightLabel: acc.subHeader },
                            data: acc.documents,
                        };
                    }),
                },
            ];
        } else {
            return [];
        }
    };

    return (
        <Fragment>
            {/* <FlexedDiv justifyContent={'space-between'}>
                <Fs18BoldDarkGrey>{LABEL.uploadedDocument}</Fs18BoldDarkGrey>
                <FlexedDiv onClick={toggleExpandCollapse}>
                    <TextNavyBlue size="12px" weight="bold" style={{ marginRight: '5px' }}>
                        {expandSoftCopy && (props.noPhysical ? true : expandPhysical)
                            ? LABEL.collapseAll
                            : LABEL.expandAll}
                    </TextNavyBlue>
                    <IcoMoon
                        name={expandSoftCopy && (props.noPhysical ? true : expandPhysical) ? 'collapse' : 'expand'}
                        size="20px"
                    />
                </FlexedDiv>
            </FlexedDiv> */}
            {/* <Collapsible
                title="Softcopy Document"
                content={<MultiSection sections={props.softCopy} noSectionImage={props.noInfoImage} />}
                isCheckable={false}
                handleExpand={() => {
                    setExpandSoftCopy(!expandSoftCopy);
                }}
                expanded={expandSoftCopy}
                showBoxShadow
                noXMargin
            /> */}
            <BlockContainer>
                <BlockContainerHeader>
                    <TextDarkBlack weight="700" size="18px">
                        Softcopy Documents
                    </TextDarkBlack>
                </BlockContainerHeader>
                <BlockContainerContent>
                    <MultiSection sections={props.softCopy} noSectionImage={props.noInfoImage} />
                </BlockContainerContent>
            </BlockContainer>
            {!props.noPhysical && (
                <BlockContainer>
                    <BlockContainerHeader>
                        <TextDarkBlack weight="700" size="18px">
                            Physical Documents
                        </TextDarkBlack>
                    </BlockContainerHeader>
                    <BlockContainerContent>
                        {props.physicalCopy.length || props.accDocs?.length ? (
                            <>
                                {props.accDocs && props.accDocs.length ? (
                                    <MultiSection sections={getPhysicalCopyAccountSection()} noShowLabel />
                                ) : null}
                                <MultiSection sections={props.physicalCopy} noShowLabel />
                            </>
                        ) : (
                            <FlexedDiv direction="column" alignItems={'center'} margin={'32px 0 40px 0'}>
                                <IcoMoon name={props.noInfoImage ?? ''} size={'160px'} />
                                <TextNavyBlue weight="700">{LABEL.noInformation}</TextNavyBlue>
                            </FlexedDiv>
                        )}
                    </BlockContainerContent>
                </BlockContainer>
                // <Collapsible
                //     title="Physical Documents"
                //     content={
                //         props.physicalCopy.length || props.accDocs?.length ? (
                //             <>
                //                 <MultiSection sections={props.physicalCopy} />
                //                 {props.accDocs && props.accDocs.length ? (
                //                     <MultiSection sections={getPhysicalCopyAccountSection()} />
                //                 ) : null}
                //             </>
                //         ) : (
                //             <FlexedDiv direction="column" alignItems={'center'} margin={'32px 0 40px 0'}>
                //                 <IcoMoon name={props.noInfoImage ?? ''} size={'160px'} />
                //                 <TextNavyBlue weight="700">{LABEL.noInformation}</TextNavyBlue>
                //             </FlexedDiv>
                //         )
                //     }
                //     isCheckable={false}
                //     handleExpand={() => {
                //         setExpandPhysical(!expandPhysical);
                //     }}
                //     expanded={expandPhysical}
                //     showBoxShadow
                //     noXMargin
                // />
            )}
        </Fragment>
    );
};
const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;
