/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LABEL, sideNavButtonsBranch } from '../../constants';
import { useContext } from 'react';
import { IBranchPermissions } from '../../utils/permissionTypes';

import AuthContext from '../../context/AuthContext';
import styled from 'styled-components';
import NavBar from '../../components/organisms/Navbar';

interface WrapperProps {
    children: React.ReactNode;
}

export const BranchWrapper = (props: WrapperProps): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { branch } = JSON.parse(userLoginContext.permission);
    const parsedPermission: IBranchPermissions['permission'] = branch.permission;
    const { accountManagement, adviser, ceTransaction } = parsedPermission;

    const getSideNavButtons = () => {
        const sideNav: ISideNavMenuItemSection[] = [];
        //Account Management
        const accountSection: ISideNavMenuItemSection = {
            section: LABEL.accountMenuTitle,
            menu: [],
        };
        //TODO Accounts page

        if (accountManagement.inbox.actions.canReadNotifications === 'maker') {
            sideNavButtonsBranch.account && accountSection.menu.push(sideNavButtonsBranch.account[0]);
        }
        if (accountManagement.profile.actions.canViewStaffDetails === 'maker') {
            sideNavButtonsBranch.account && accountSection.menu.push(sideNavButtonsBranch.account[1]);
        }
        // CE
        const customerExperienceSection: ISideNavMenuItemSection = {
            section: LABEL.customerExperienceCE,
            menu: [],
        };
        //TODO INVESTOR
        const permissionsCESection = [ceTransaction.isAll, adviser.isAll, 'true'];
        permissionsCESection.map((permission, index) => {
            if (permission === 'true' && sideNavButtonsBranch.customerExperience) {
                customerExperienceSection.menu.push(sideNavButtonsBranch.customerExperience[index]);
            }
        });
        customerExperienceSection.menu.length !== 0 ? sideNav.push(customerExperienceSection) : null;
        // investorSection.menu.length !== 0 ? sideNav.push(investorSection) : null;
        accountSection.menu.length !== 0 ? sideNav.push(accountSection) : null;
        return sideNav;
    };

    return (
        <>
            <Layout style={{ display: 'flex', flexDirection: 'row' }}>
                <NavBar navData={getSideNavButtons()} />
                <Layout>
                    <NavigatorContainer className="navigator">{props.children}</NavigatorContainer>
                </Layout>
            </Layout>
        </>
    );
};

export default BranchWrapper;
const NavigatorContainer = styled.div`
    border-radius: 22px;
    position: absolute;
    min-height: 96.2vh;
    padding-top: 2rem;
    width: 81.111vw;
    height: 100%;
    left: 17.222vw;
`;

const Layout = styled.section`
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #f0f2f5;
`;
