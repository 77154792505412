export const decoupleGroupedTrx = (groupedTrx: TGroupingInfo[]): ITableData[] => {
    let temp: ITableData[] = [];
    groupedTrx.map((item) => {
        item.transactionData.grouping
            ? (temp = recursiveMap(item.transactionData.grouping, temp))
            : temp.push(...item.transactionData.data);
    });
    return temp;
};
const recursiveMap = (groupedTrx: TGroupingInfo[], data: ITableData[]): ITableData[] => {
    let tempArray: ITableData[] = [...data];
    const trx = [...groupedTrx];
    trx.map((item) => {
        if (item.transactionData.data !== undefined) {
            tempArray.push(...item.transactionData.data);
        } else tempArray = recursiveMap(item.transactionData.grouping, tempArray);
    });
    return tempArray;
};
export const decoupleNonGroupedTrx = (groupedTrx: ITransactionWithoutGrouping): ITableData[] => {
    let temp: ITableData[] = [];
    if (groupedTrx.data !== undefined) {
        temp = groupedTrx.data;
    }
    return temp;
};

export const getGroupedTrx = (trx: ITableData[], orderBy: string): TGroupingInfo[] => {
    const temp: TGroupingInfo[] = [];
    if (orderBy === 'orderNo') {
        trx.forEach((item) => {
            if (Object.keys(item).includes(orderBy)) {
                const obj: TGroupingInfo = {
                    type: 'orderNo',
                    groupingData: {
                        principalName: item.clientName ?? '',
                        principalNumber: item.clientIdNum ?? '',
                        jointName: item.jointName ?? '',
                        jointNumber: item.jointNumber ?? '',
                        orderNumber: item.orderNo ?? '',
                        sameBranch: item.sameBranch ?? 'true',
                    },
                    transactionData: {
                        data: [item],
                    },
                };
                temp.push(obj);
            }
        });
    }
    return temp;
};
