/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getDropDownList } from '../../../_graphql/queries/common/getDropDownList';
import { PreviousPage, Banner, FlexedDiv } from '../../../components';
import { Modal, TableSheet } from '../../../components/organisms';
import { expandBulkUserCols } from '../../../utils';
import { getIdInfo } from '../../../_graphql/queries/systemadmin/getIdInfo';
import approveReject from '../../../_graphql/mutations/systemAdmin/approveReject';
import styled from 'styled-components';

import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import { LABEL } from '../../../constants';
import { getSystemAdminInboxData } from '../../../_graphql/queries/inbox/getSystemAdminInboxData';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    uploadId: string;
    fromInbox?: boolean;
}
interface StyledInnerProps {
    paddingBottom: number;
}
type BulkUserData = {
    [key: string]: string;
};
interface BranchGroupObj {
    id: string;
    value: string;
}
interface IBulkUserApproval {
    requestId: string;
    action: string;
    remarks: string;
}
interface IApprovalStateObject {
    approverName: string;
    approvalStatus: string;
    remarks: string;
}

const initialCheckerModal = {
    requestId: '',
    action: '',
    remarks: '',
};

const initialApprovalObject: IApprovalStateObject = {
    approverName: '',
    approvalStatus: '',
    remarks: '',
};
// bulk users table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 150,
    secondColWidth: 200,
    thirdColWidth: 42,
};
const ReviewBulkImportUsers: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { userManagement } = parsedPermission.hq.permission;
    const history = useHistory();
    // states for user permissions
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [author, setAuthor] = useState<string>('');
    // states for modals, title and description
    // const [pageTitle, setPageTitle] = useState<string>('');
    // const [pageDescription, setPageDescription] = useState<string>('');
    const [showModal, setShowModal] = useState(true);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    // states to generate table
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [branches, setBranches] = useState<BranchGroupObj[]>([]);
    const [departments, setDepartments] = useState<BranchGroupObj[]>([]);
    const [groups, setGroups] = useState<BranchGroupObj[]>([]);
    // state for approval request body
    const [checkerModal, setCheckerModal] = useState<IBulkUserApproval>(initialCheckerModal);
    //  state for approval/rejected request if from Inbox
    const [approvalStatusObj, setApprovalStatusObj] = useState<IApprovalStateObject>(initialApprovalObject);
    // states for UI utility components
    const [isProcessing, setIsProcessing] = useState(false);
    const { state: propsData } = useLocation<IRouteProps>();

    const [bannerHeight, setBannerHeight] = useState<number>(0);
    // check page permissions
    const checkPermissions = () => {
        if (
            userManagement.userTab.reviewApproval &&
            (userManagement.userTab.reviewApproval.canApproveBulkImport === 'auto-authorizer' ||
                userManagement.userTab.reviewApproval.canApproveBulkImport === 'checker')
        ) {
            setIsAuthorized(true);
        }
    };
    //call query to get master fund doc by id
    const getBulkUserDataQuery = async (id: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getIdInfo, {
                    input: {
                        id: id,
                        viewType: 'bulk',
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.getIdInformation;
            if (!error) {
                const parsedData = JSON.parse(data.result.info);

                setAuthor(parsedData.author);
                const bulkUserData: BulkUserData[] = parsedData.data;

                // process to bind resposnse data to  table
                processBulkUsersData(bulkUserData);
            } else {
            }

            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                errorCode: _error.errorCode,
                message: _error.message,
                testId: 'bulkusers-error-modal',
                title: 'Cannot Fetch Data',
            });
            handleErrorHandler();
        }
    };
    //mutation function to approve bulk users
    const approveBulkUserMutation = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: checkerModal,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.approveReject;
            if (!error) {
                handleShowConfirmation();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    testId: 'bulkusers-error-modal',
                    title: 'Bulk Import User request request failed',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };
    //code to build columns and cells
    const processBulkUsersData = (rawData: BulkUserData[]) => {
        //code to build columns and cells
        let tempCols: string[] = [];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};

        try {
            tempCols = Object.keys(rawData[0]);

            // setCamelCasecolumns(tempCols);
            tempCols = expandBulkUserCols(tempCols);

            //separate data to header columns
            const columnsOutput: ISheetColumn[] = [];
            tempCols.map((column: string) => {
                //fixed values from incoming data
                switch (column.toLowerCase().trim()) {
                    case 'group id':
                        columnObj = {
                            colName: 'Group Name',
                            type: 'string',
                        };
                        break;
                    case 'branch id':
                        columnObj = {
                            colName: 'Branch Name',
                            type: 'string',
                        };
                        break;
                    case 'department id':
                        columnObj = {
                            colName: 'Department Name',
                            type: 'string',
                        };
                        break;
                    default:
                        columnObj = {
                            colName: column,
                            type: 'string',
                        };
                }

                columnsOutput.push(columnObj);
            });

            //separaete data to rows
            const cellRow: ISheetCell[][] = [];
            tempCells = rawData.map((row: BulkUserData) => Object.values(row));

            //make tempCells to objects and then push to an array
            tempCells.map((row: any[]) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, index) => {
                    switch (column.colName.toLowerCase()) {
                        case 'group name':
                            cellObj = {
                                cellValue: getGroupNames(column.colName, row[index]),
                                isValid: true,
                            };
                            break;
                        default:
                            cellObj = {
                                cellValue: getBranchDeptName(column.colName, row[index]),
                                isValid: true,
                            };
                    }

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };

    const getBulkUserNotificationQuery = async (id: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getSystemAdminInboxData, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.systemAdminInboxData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { data } = resultCheck.data.result;

            const tempObj: IApprovalStateObject = {
                approvalStatus: data.approvalStatus,
                approverName: data.approverName,
                remarks: data.remarks,
            };
            setApprovalStatusObj(tempObj);
            setAuthor(data.authorName);
            const parsedData = JSON.parse(data.value);
            const bulkUserData: BulkUserData[] = parsedData.OfficeUser;
            // process to bind resposnse data to  table
            processBulkUsersData(bulkUserData);

            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                errorCode: _error.errorCode,
                message: _error.message,
                testId: 'bulkusers-error-modal',
                title: 'Cannot Fetch Bulk Import Users Data',
            });
            handleErrorHandler();
        }
    };

    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    const handleReject = () => {
        history.push({
            pathname: Routes.rejectBulkImportUsers,
            state: {
                uploadId: propsData.uploadId,
            },
        });
    };

    const goBacktoDashboard = () => {
        history.push(Routes.dashboardSystemAdmin);
    };
    //function to get user group names and banches
    const getUserGroupBranchDepartData = async () => {
        try {
            const response: any = await API.graphql(graphqlOperation(getDropDownList, {}), idTokenHeader);
            const { data, error } = response.data.getDropDownList;
            if (data !== null) {
                setBranches(data.result.branches);
                setDepartments(data.result.departments);
                setGroups(data.result.groups);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    testId: 'bulkusers-error-modal',
                    title: 'Bulk Import User request request failed',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const getGroupNames = (colName: string, value: string[]): string => {
        const _tempArray: string[] = [];

        value.map((item) => {
            groups.map((group) => {
                if (item === group.id) _tempArray.push(group.value);
            });
        });

        return _tempArray.join();
    };
    const getBranchDeptName = (colName: string, value: string): string => {
        let _temp = value;
        switch (colName.toLowerCase()) {
            case 'department name':
                departments.map((deaprtment) => {
                    if (_temp === deaprtment.id) _temp = deaprtment.value;
                });
                break;
            case 'branch name':
                branches.map((branch) => {
                    if (_temp === branch.id) _temp = branch.value;
                });
                break;
        }

        return _temp;
    };
    useEffect(() => {
        if (propsData) {
            checkPermissions();
            setIsProcessing(true);
            getUserGroupBranchDepartData();

            //handle fund approve
            setCheckerModal({
                ...checkerModal,
                requestId: propsData.uploadId,
                action: 'approve',
            });
        }
    }, [propsData]);
    useEffect(() => {
        if (propsData) {
            propsData.fromInbox !== true
                ? getBulkUserDataQuery(propsData.uploadId)
                : getBulkUserNotificationQuery(propsData.uploadId);
        }
    }, [groups]);
    const bannerDescription = `Requested by ${author}`;
    const isPendingReview = (): boolean => {
        if (propsData.fromInbox === undefined) return true;
        else {
            if (propsData.fromInbox === true && approvalStatusObj.approvalStatus.toLowerCase() === 'pending')
                return true;
            else return false;
        }
    };
    return (
        <Fragment>
            <PreviousPage title="Review Bulk Import Users Request" />
            <StyledInner paddingBottom={bannerHeight}>
                <PageDescription>
                    Please review the following request. You can either approve it or reject it using the buttons in the
                    bottom right corner. You’ll also be able to leave additional remark if you reject it.
                </PageDescription>

                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <Fragment>
                        {propsData &&
                        propsData.fromInbox &&
                        approvalStatusObj.approvalStatus.toLowerCase() !== LABEL.pending.toLowerCase() ? (
                            <ApprovalBanner direction="column">
                                <Title>{`This request was ${approvalStatusObj.approvalStatus.toLowerCase()} by ${
                                    approvalStatusObj.approverName
                                }`}</Title>

                                <FlexedDiv
                                    style={{ paddingTop: '0.5rem', whiteSpace: 'break-spaces', width: '100%' }}
                                    justifyContent="space-between"
                                >
                                    {approvalStatusObj.remarks !== undefined && approvalStatusObj.remarks !== null
                                        ? `${LABEL.rejectedReason}: ${approvalStatusObj.remarks}`
                                        : ''}
                                </FlexedDiv>
                            </ApprovalBanner>
                        ) : null}
                        <TableSheet columns={columns} rows={rows} readyOnly={true} columnWidths={fixedCoLumnlWidth} />
                    </Fragment>
                )}
            </StyledInner>
            {isAuthorized && isPendingReview() && (
                <Banner
                    title="Pending Approval"
                    description={bannerDescription}
                    toggle={true}
                    setBannerHeight={setBannerHeight}
                    testId="review-bulkimportusers-banner"
                    primaryButtonObject={{
                        handlePrimaryBtn: () => approveBulkUserMutation(),
                        label: LABEL.approve,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: handleReject,
                        label: LABEL.reject,
                    }}
                />
            )}
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={() => setShowModal(!showModal)}
                    title="Bulk Import User request approved"
                    primaryBtn={{
                        onClick: () => {
                            goBacktoDashboard();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="adviserprofile-modal"
                    icon="user-modal-success"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${rows.length} Users has been created.`}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 3.5rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;

const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;

export default ReviewBulkImportUsers;
