import gql from 'graphql-tag';

/**
 * @fileCheckQuery @query - Fund - File Check
 * @description:  To check whether file has already been uploaded.
 */

const fileCheckQuery = gql`
    query fileCheck($input: FileCheckInput) {
        fileCheck(input: $input) {
            data {
                result {
                    message
                    status
                    fundData {
                        fundAbbr
                        fundName
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
export default fileCheckQuery;
