/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { DateColumnItem, FileSizeColumnItem } from '../../../../templates';
import { InitiatedByColumnItem } from '../../../../templates/InitiatedByColumnItem';
import { Roles } from './Roles';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const renderItems = (key: string) => {
        switch (key) {
            case 'name':
                return <InitiatedByColumnItem data={data} itemKey={itemKey} />;
            case 'roles':
                return <Roles data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
            case 'createdOn':
            case 'submittedOn':
            case 'lastActivity':
            case 'lastUpdated':
                return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
            case 'fileSize':
                return <FileSizeColumnItem data={data} itemKey={itemKey} type="MB" isSortedColumn={isSortedColumn} />;
        }
    };
    return <Fragment>{renderItems(itemKey[0].key)}</Fragment>;
};
export default CustomItem;
