/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, useContext } from 'react';

import { SYSTEM_ADMIN } from '../../../../constants';
import { IAdviserProfileDetails, handleRestructureData } from '../../../AdvisorProfile/function';
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { AgentProfileQuery } from '../../../../_graphql/queries/systemadmin/adviserProfile';
import { Profile } from '../../../AdvisorProfile/Profile';
import { ComponentLoader, Tabs } from '../../../../components';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { useComponentLoader } from '../../../../customHooks';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

const { ADVISER_PROFILE } = SYSTEM_ADMIN;

const INITIAL_PROFILE = {
    name: 'Edgar Constantine',
    status: 'Active',
    code: '73465256208562060',
    nric: '880808088888',
    details: [
        {
            title: ADVISER_PROFILE.LABEL_PERSONAL_DETAILS,
            sections: [
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_HOME_BRANCH, data: 'KIB HQ Office' },
                        { label: ADVISER_PROFILE.LABEL_REGION, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_ADVISER_CHANNEL, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_OMNI_ENABLED, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_ADVISER_RANK, data: 'Customer Experience' },
                    ],
                },
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_ADVISER_BDM, data: 'KIB HQ Office' },
                        { label: ADVISER_PROFILE.LABEL_ADVISER_BDM_EMAIL, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_UPLINE_NAME_CODE, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_AGENCY, data: 'Customer Experience' },
                    ],
                },
            ],
        },
        {
            title: ADVISER_PROFILE.LABEL_CONTACT_DETAILS,
            sections: [
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_EMAIL, data: 'christine.r@kenanga.com' },
                        { label: ADVISER_PROFILE.LABEL_MOBILE, data: '+60 121231234' },
                    ],
                },
            ],
        },
        {
            title: ADVISER_PROFILE.LABEL_ADDRESS_INFO,
            sections: [
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_PERMANENT_ADDRESS, data: 'christine.r@kenanga.com' },
                        { label: ADVISER_PROFILE.LABEL_POSTCODE, data: '+60 121231234' },
                        { label: ADVISER_PROFILE.LABEL_TOWN, data: '+60 121231234' },
                        { label: ADVISER_PROFILE.LABEL_STATE, data: '+60 121231234' },
                        { label: ADVISER_PROFILE.LABEL_COUNTRY, data: '+60 121231234' },
                    ],
                },
            ],
        },
    ],
};
export const AdviserActivity: React.FC = () => {
    const [profile, setProfile] = useState<IAdviserProfileDetails>(INITIAL_PROFILE);
    const location = useLocation();
    const locationState: any = location.state;
    // loader
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const getProfile = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: locationState.id,
                    },
                }),
                idTokenHeader,
            );

            const result = response.data.getLogData.data.result;

            const allData = JSON.parse(result.data.data);

            const responseFromAdviser: any = await API.graphql(
                graphqlOperation(AgentProfileQuery, {
                    input: {
                        userId: allData.agentId,
                        // filter: filters,
                    },
                }),
                idTokenHeader,
            );

            if (responseFromAdviser.data.agentProfile.data !== null) {
                const restructuredData: IAdviserProfileDetails = handleRestructureData(
                    responseFromAdviser.data.agentProfile.data.result,
                );

                restructuredData.name = locationState.name;
                restructuredData.code = locationState.code;
                restructuredData.status = locationState.status;
                setProfile(restructuredData);
            } else {
                throw responseFromAdviser.data.agentProfile.error;
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Advisers Data',
                testId: 'activitylogs-adviseractivity-error-modal',
            });
            handleErrorHandler();
        }
        // setProfile(data);
    };

    useEffect(() => {
        getProfile();
    }, []);

    const tabs = [<Profile profile={profile} key={1} />];
    return (
        <Fragment>
            <Tabs
                title={locationState.name}
                tabTitles={[SYSTEM_ADMIN.LABEL_PROFILE]}
                suffixHelper={locationState.status}
                tabs={tabs}
            />
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

export default AdviserActivity;
