import gql from 'graphql-tag';

export const editUserGroup = gql`
    mutation editGroup($input: EditGroupInput) {
        editGroup(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;
