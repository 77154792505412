/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { DateColumnItem } from '../../../templates';
import { OmniEnabledColumnItem } from './OMNIEnabled';

declare interface ICustomItemProps extends ITableCustomItem {
    shouldUpdate: boolean;
    setShouldUpdate: (toggle: boolean) => void;
}

const CustomItem = ({
    data,
    itemKey,
    shouldUpdate,
    setShouldUpdate,
    isSortedColumn,
}: ICustomItemProps): JSX.Element => {
    const renderItems = (key: string) => {
        switch (key) {
            case 'createdOn':
            case 'lastUpdated':
                return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
            case 'omniEnabled':
                return (
                    <OmniEnabledColumnItem data={data} shouldUpdate={shouldUpdate} setShouldUpdate={setShouldUpdate} />
                );
        }
    };
    return <Fragment>{renderItems(itemKey[0].key)}</Fragment>;
};
export default CustomItem;
