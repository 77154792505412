import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES, LABEL } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { AdvanceTable } from '../../../components';
import { DateFilter } from './';
import { subTitleStyle, sh108, sh100, sh38, sh216, sh96, sh92 } from '../../../styles';

import * as Routes from '../../../routes';
import ProductSettingsContext from '../../../context/ProductSettingsContext';
import CustomItem from './CustomItem';
import AuthContext from '../../../context/AuthContext';

export interface IAllFundsProps {
    allFundsData: ITableData[];
    getTabIndex: () => void;
}

export const AllFunds: React.FC<IAllFundsProps> = ({ allFundsData, getTabIndex }: IAllFundsProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { allFundsTab } = parsedPermission.hq.permission.productSettings;

    const history = useHistory();
    const { sortData, setSortData, columnFilterValues } = useContext(ProductSettingsContext);

    const handleViewDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeProductSetingsDetails,
            state: {
                type: 'allfunds',
                code: item.rawData.fundId,
                tabIndex: getTabIndex(),
            },
        });
    };

    const handleSort = (keyName: string) => {
        const tempSort = { ...sortData };
        const sortType = sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortData.column === keyName) {
            const icon = sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };

    const handleDateFilterDrop = (): JSX.Element => {
        return <DateFilter />;
    };

    const allFundsCols: ITableColumn[] = [
        {
            icon: {
                name: handleSortIcon('fundAbbr'),
                size: '1rem',
            },
            key: [{ key: 'fundAbbr', textStyle: { fontWeight: sortData.column === 'fundAbbr' ? 700 : 400 } }],
            viewStyle: {
                width: sh92,
            },
            title: LABEL.code,
            titleStyle: {
                fontWeight: sortData.column === 'fundAbbr' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fundAbbr'),
        },
        {
            icon: {
                name: handleSortIcon('fundName'),
                size: '1rem',
            },
            key: [{ key: 'fundName', textStyle: { fontWeight: sortData.column === 'fundName' ? 700 : 400 } }],
            viewStyle: {
                width: sh216,
            },
            title: LABEL.fundName,
            titleStyle: {
                fontWeight: sortData.column === 'fundName' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fundName'),
        },
        {
            key: [{ key: 'fundType' }, { key: 'fundCategory', textStyle: subTitleStyle }],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.productTypes,
            subtitle: 'Categories',
        },
        {
            icon: {
                name: handleSortIcon('issuingHouse'),
                size: '1rem',
            },
            key: [{ key: 'issuingHouse', textStyle: { fontWeight: sortData.column === 'issuingHouse' ? 700 : 400 } }],
            viewStyle: {
                width: sh216,
            },
            title: LABEL.fundUtmc,
            titleStyle: {
                fontWeight: sortData.column === 'issuingHouse' ? 700 : 400,
            },
            onPressHeader: () => handleSort('issuingHouse'),
        },
        {
            key: [{ key: 'isSyariah' }],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.type,
            customItem: true,
        },
        {
            key: [{ key: 'fundCurrency' }, { key: 'fundClass', textStyle: subTitleStyle }],
            viewStyle: {
                width: sh100,
            },
            title: LABEL.currency,
            subtitle: 'Fund Class',
        },
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [
                {
                    key:
                        columnFilterValues && columnFilterValues['lastUpdated'].columnLabel === 'Last Updated'
                            ? 'lastUpdated'
                            : 'startDate',
                },
            ],
            viewStyle: {
                width: sh100,
            },
            title: columnFilterValues !== undefined ? columnFilterValues['lastUpdated'].columnLabel : '',
            titleStyle: {
                fontWeight:
                    sortData.column ===
                    (columnFilterValues && columnFilterValues['lastUpdated'].columnLabel === 'Last Updated'
                        ? 'lastUpdated'
                        : 'fundStartDate')
                        ? 700
                        : 400,
            },
            customItem: true,
            RenderHeaderMenu: () => handleDateFilterDrop(),
        },
        {
            icon: {
                name: handleSortIcon('fundStatus'),
                size: '1rem',
            },
            key: [{ key: 'fundStatus' }],
            viewStyle: {
                width: sh108,
            },
            title: LABEL.status,
            titleStyle: {
                fontWeight: sortData.column === 'fundStatus' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fundStatus'),
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: allFundsTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                allFundsTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'allFunds-view-btn',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allFundsData}
                columns={allFundsCols}
                onEmptyState={EMPTY_STATES.emptyStateAllfund}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData.column} {...props} />}
            />
        </Fragment>
    );
};
