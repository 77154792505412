/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISectionTypes } from '../AddRoles/CollapsibleContent';
import { ISectionCollapsible } from '../../../../constants';
import { BRANCH_ROLE_KEYS } from '../../../../constants/branchRoleCollapsible';
import { HQ_ROLE_KEYS } from '../../../../constants/hqRoleCollapsible';

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
}

const handlePermissionLevel = (text: string, module: any) => {
    switch (text) {
        case 'general access':
            return 'General Access';
        case 'maker':
            return 'Maker';
        case 'maker (edited)':
            return 'Maker (Edited)';
        case 'checker':
            return 'Checker';
        case 'checker (edited)':
            return 'Checker (Edited)';
        case 'auto-authorizer':
            return 'Auto-Authorizer';
        case 'auto-authorizer (edited)':
            return 'Auto-Authorizer (Edited)';
        default:
            return getPermissionLevelByModule(module);
    }
};

const getDefaultPermissionLevelByModule = (module: string) => {
    switch (module) {
        case 'accountManagement':
        case 'adviser':
        case 'activityLogs':
        case 'branchOffices':
        case 'productSettings':
            return 'general';
        default:
            return 'maker';
    }
};

const getPermissionLevelByModule = (module: string) => {
    switch (module) {
        case 'accountManagement':
        case 'adviser':
        case 'activityLogs':
        case 'branchOffices':
        case 'productSettings':
            return 'General Access';
        default:
            return 'Maker';
    }
};

export const setInitialData = (
    newData: any,
    currentData: ISectionTypes,
    privilege: string,
    module: string,
): ISectionTypes => {
    const actions = 'actions';
    const reviewApproval = 'reviewApproval';
    const checker = 'checker';
    const updatedData = { ...newData };
    let sectionObject: ISectionTypes = { ...currentData };
    const keyData: any = privilege === 'branch' ? BRANCH_ROLE_KEYS : HQ_ROLE_KEYS;
    const keyModule: any = keyData[module];

    Object.keys(updatedData).map((keyName) => {
        const permissionLevel = updatedData.permissionLevel;
        if (keyName !== 'isAll' && keyName !== 'permissionLevel') {
            let keyObject = {};
            const keyArray: boolean[] = [];
            const reviewArray: boolean[] = [];

            const sectionData = updatedData[keyName][actions];
            const updatedPermission =
                permissionLevel !== '' ? permissionCheck(permissionLevel) : getDefaultPermissionLevelByModule(module);

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const keysData = keyModule.data[updatedPermission!][keyName];
            keysData.actions.map((keyName: string) => {
                if (keyName !== 'isAll') {
                    keyArray.push(permissionLevel !== '' ? sectionData[keyName] === 'true' : false);
                }
            });
            if (hasKey(updatedData[keyName], reviewApproval)) {
                const sectionReviewData = updatedData[keyName][reviewApproval];
                const reviewKeys: string[] = keyModule.data[checker][keyName][reviewApproval];

                reviewKeys.map((reviewKey: string) => {
                    if (keyName !== 'isAll') {
                        reviewArray.push(permissionLevel !== '' ? sectionReviewData[reviewKey] === 'true' : false);
                    }
                });
            }
            const review = reviewArray.length !== 0 ? { reviewApproval: reviewArray } : null;
            keyObject = { isAll: newData[keyName].isAll === 'true', actions: keyArray, ...review };

            sectionObject = { ...sectionObject, [keyName]: keyObject };
        }
    });

    sectionObject = {
        ...sectionObject,
        isAll: updatedData.isAll === 'true',
        permissionLevel: handlePermissionLevel(updatedData.permissionLevel.toLowerCase(), module),
    };

    return sectionObject;
};

export const handleUserChange = (data: ISectionCollapsible[], stateData: ISectionTypes, dropdown: ISelectValue) => {
    let newData = {};
    const toggle = stateData.isAll;
    data.map((item) => {
        const reviewApproval =
            item.reviewApproval !== undefined
                ? { reviewApproval: new Array(item.reviewApproval.length).fill(toggle) }
                : null;
        if (hasKey(stateData, item.keyName)) {
            const tempObject: any = stateData[item.keyName];
            const tempActionArray = new Array(item.actions.length).fill(toggle);
            const newObject = { ...tempObject, isAll: toggle, actions: tempActionArray, ...reviewApproval };
            newData = {
                ...newData,
                [item.keyName]: newObject,
            };
        }
    });
    newData = { ...newData, isAll: stateData.isAll, permissionLevel: dropdown.label };
    return newData;
};

export const handleChangeData = (temp: ISectionTypes): ISectionTypes => {
    let newData = {};
    const toggle = !temp.isAll;
    const actions = 'actions';
    const reviewApproval = 'reviewApproval';
    Object.keys(temp).map((keyName) => {
        if (keyName !== 'isAll' && keyName !== 'permissionLevel' && hasKey(temp, keyName)) {
            const tempData = temp[keyName];
            if (hasKey(tempData, actions)) {
                const tempArray: boolean[] = tempData[actions];
                tempArray.fill(toggle);
                let keyObject = {};
                if (hasKey(tempData, reviewApproval)) {
                    const tempArray: boolean[] = tempData[reviewApproval];
                    tempArray.fill(toggle);
                    keyObject = { [reviewApproval]: tempArray };
                }
                keyObject = { ...keyObject, isAll: toggle, actions: tempArray };
                newData = { ...newData, [keyName]: keyObject };
            }
        }
    });
    temp = { ...temp, ...newData, isAll: toggle, permissionLevel: temp.permissionLevel };
    return temp;
};

const permissionCheck = (permission: string) => {
    if (permission.toLowerCase() === 'general access') {
        return 'general';
    } else if (permission.toLowerCase() === 'maker' || permission.toLowerCase() === 'maker (edited)') {
        return 'maker';
    } else if (permission.toLowerCase() === 'checker' || permission.toLowerCase() === 'checker (edited)') {
        return 'checker';
    } else if (
        permission.toLowerCase() === 'auto-authorizer' ||
        permission.toLowerCase() === 'auto-authorizer (edited)'
    ) {
        return 'autoAuthorizer';
    }
};

export const submitData = (data: any, privilege: string) => {
    let permission = {};
    const actions = 'actions';
    const reviewApproval = 'reviewApproval';
    const isAll = 'isAll';
    const keyData = privilege === 'branch' ? BRANCH_ROLE_KEYS : HQ_ROLE_KEYS;
    Object.keys(data).map((sectionKey) => {
        const sectionData: ISectionTypes = data[sectionKey];

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const permissionLevel = permissionCheck(sectionData.permissionLevel)!;
        if (hasKey(keyData, sectionKey)) {
            const outsideData = keyData[sectionKey].data;

            if (hasKey(outsideData, permissionLevel)) {
                const keyData = outsideData[permissionLevel];

                let sectionObject = {};
                Object.keys(sectionData).map((subSectionKey) => {
                    if (subSectionKey !== 'isAll' && subSectionKey !== 'permissionLevel') {
                        if (hasKey(sectionData, subSectionKey) && hasKey(keyData, subSectionKey)) {
                            const subSectionData = sectionData[subSectionKey];
                            const subSectionKeyData = keyData[subSectionKey];

                            let actionsObject = {};
                            let reviewObject = {};
                            if (hasKey(subSectionData, actions) && hasKey(subSectionKeyData, actions)) {
                                const subSectionDataActionsKey = subSectionKeyData[actions];
                                const actionsMap: boolean[] = subSectionData[actions];
                                actionsMap.map((actionValue, actionIndex) => {
                                    actionsObject = {
                                        ...actionsObject,
                                        [subSectionDataActionsKey[actionIndex]]:
                                            actionValue === true ? 'true' : 'false',
                                    };
                                });
                                if (
                                    hasKey(subSectionData, reviewApproval) &&
                                    hasKey(subSectionKeyData, reviewApproval)
                                ) {
                                    const subSectionDataReviewKey = subSectionKeyData[reviewApproval];
                                    const reviewMap: boolean[] = subSectionData[reviewApproval];
                                    reviewMap.map((reviewValue, reviewIndex) => {
                                        reviewObject = {
                                            ...reviewObject,
                                            [subSectionDataReviewKey[reviewIndex]]:
                                                reviewValue === true ? 'true' : 'false',
                                        };
                                    });
                                }
                            }
                            const reviewApprovalObject =
                                Object.keys(reviewObject).length !== 0 ? { reviewApproval: reviewObject } : null;
                            const section = {
                                isAll: hasKey(subSectionData, isAll)
                                    ? subSectionData[isAll] === true
                                        ? 'true'
                                        : 'false'
                                    : false,
                                actions: actionsObject,
                                ...reviewApprovalObject,
                            };
                            sectionObject = {
                                ...sectionObject,
                                [subSectionKey]: section,
                                isAll: sectionData.isAll === true ? 'true' : 'false',
                                permissionLevel: sectionData.permissionLevel.toLowerCase(),
                            };
                        }
                    }
                });

                permission = { ...permission, [sectionKey]: sectionObject };
            }
        }
    });

    return permission;
};
