import * as ROUTES from '../routes';
import { LABEL } from './labels';

type SideNavMenuDataItem = {
    conditionalTitle?: string;
    groupTitle?: string;
    icon?: () => JSX.Element;
    iconName?: string;
    route: string;
    subMenu?: ISideNavSubMenuItem[];
    title: string;
};

declare interface ISideNavMenuDataBranch {
    account: SideNavMenuDataItem[];
    customerExperience?: SideNavMenuDataItem[];
}
declare interface ISideNavMenuDataHQ {
    account: SideNavMenuDataItem[];
    activityAndAccess?: SideNavMenuDataItem[];
    customerExperience?: SideNavMenuDataItem[];
    edd?: SideNavMenuDataItem[];
    finance?: SideNavMenuDataItem[];
    operations?: SideNavMenuDataItem[];
    productAndServices?: SideNavMenuDataItem[];
    systemConfiguration?: SideNavMenuDataItem[];
    userManagement?: SideNavMenuDataItem[];
}

export const sideNavButtonsBranch: ISideNavMenuDataBranch = {
    account: [
        { title: 'Inbox', route: ROUTES.branchInbox, iconName: 'bell-notification' },
        { title: 'Profile', route: ROUTES.profile, iconName: 'user-profile' },
    ],
    customerExperience: [
        {
            title: 'Transactions',

            route: ROUTES.dashboardBranch,
            iconName: 'transactions',
            subMenu: [
                {
                    menuTitle: 'Submitted',
                    route: ROUTES.dashboardBranch,
                },
                {
                    menuTitle: 'Pending Submission',
                    route: ROUTES.branchPendingSubmission,
                },
            ],
        },
        { title: 'Advisers', route: ROUTES.advisers, iconName: 'advisers-new' },
        { title: 'Investors', route: ROUTES.branchInvestors, iconName: 'investors' },
    ],
};

export const sideNavButtonsHQ: ISideNavMenuDataHQ = {
    account: [
        { title: 'Inbox', route: ROUTES.hqInbox, iconName: 'bell-notification' },
        { title: 'Profile', route: ROUTES.hqProfile, iconName: 'user-profile' },
    ],
    activityAndAccess: [
        {
            title: 'Adviser Access',
            route: ROUTES.adviserAccess,
            iconName: 'adviser-access',
        },
        {
            title: 'Activity Logs',
            route: ROUTES.activityLogs,
            iconName: 'hour-glass',
        },
    ],
    customerExperience: [
        {
            title: 'Dashboard',
            conditionalTitle: 'CE (HQ)',
            route: ROUTES.dashboard,
            groupTitle: LABEL.customerExperienceCE,
            iconName: 'dashboard-new',
        },
        {
            title: 'Branch Transactions',
            conditionalTitle: 'CE (HQ)',
            route: '/hq/all-branches',
            iconName: 'transactions',
            subMenu: [
                {
                    menuTitle: 'Submitted',
                    route: '/hq/all-branches',
                },
                {
                    menuTitle: 'Pending Submission',
                    route: '/hq/all-branches/all-pending-submission',
                },
            ],
        },
        {
            title: 'Branches',
            conditionalTitle: 'Branch',
            route: ROUTES.branchOffices,
            iconName: 'branches',
        },
    ],
    edd: [
        {
            title: 'EDD Management',
            conditionalTitle: 'Edd',
            route: ROUTES.amlaEDDManagement,
            groupTitle: LABEL.EDD,
            iconName: 'edd',
        },
    ],
    finance: [
        {
            title: 'Dashboard',
            conditionalTitle: 'Finance',
            route: ROUTES.financesDashboard,
            groupTitle: LABEL.finance,
            iconName: 'dashboard-new',
        },
    ],
    operations: [
        {
            title: 'Dashboard',

            route: ROUTES.amlaEDDManagement,
            groupTitle: LABEL.operation,
            iconName: 'dashboard-new',
        },
    ],
    productAndServices: [
        {
            title: 'Product Settings',
            route: ROUTES.financeProductSetingsDashboard,
            iconName: 'settings',
        },
        { title: 'Uploads', route: ROUTES.financeUploads, iconName: 'uploads' },
    ],
    systemConfiguration: [
        {
            title: 'Settings',

            route: ROUTES.systemConfiguration,
            iconName: 'settings',
        },
    ],
    userManagement: [
        {
            title: 'Dashboard',

            route: ROUTES.dashboardSystemAdmin,
            groupTitle: LABEL.userManagement,
            iconName: 'dashboard-new',
        },
    ],
};
