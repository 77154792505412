// import moment from 'moment';

export function checkValid(column: string, value: string | Date): boolean {
    let returnVal = false;

    switch (column.toLowerCase()) {
        case 'fund start date':
        case 'iop end date':
        case 'non business day from date':
        case 'non business day to date':
        case 'value date':
            if (value instanceof Date) value instanceof Date ? (returnVal = true) : (returnVal = false);
            if (typeof value === 'string') {
                const ddmmyyyyVal = value
                    .toString()
                    .match(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/); //check dd/mm/yyyy

                ddmmyyyyVal ? (returnVal = true) : (returnVal = false);
            }
            break;
        default:
            returnVal = true;
    }

    return returnVal;
}

export function convertToLowerCamelCase(columns: ISheetColumn[]): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item.colName.trim()) {
            case 'Fund Code':
                tempColumns[index] = 'fundCode';
                break;
            case 'TOMS Fund Abbreviation':
            case 'AMP Abbreviation':
                tempColumns[index] = 'tomsFundAbbreviation';
                break;
            case 'OMNI Fund Abbreviation':
                tempColumns[index] = 'omniFundAbbreviation';
                break;
            case 'Fund Name':
            case 'Fund name':
            case 'AMP name':
                tempColumns[index] = 'fundName';
                break;
            case 'Fund Status':
            case 'AMP Status':
                tempColumns[index] = 'fundStatus';
                break;
            case 'Fund Category':
            case 'AMP Category':
                tempColumns[index] = 'fundCategory';
                break;
            case 'Fund Processing Group':
            case 'AMP Processing Group':
                tempColumns[index] = 'fundProcessingGroup';
                break;
            case 'Retail Fund / Wholesale Fund':
                tempColumns[index] = 'retailFundWholesaleFund';
                break;
            case 'Closed-end':
            case 'Closed-end (Y/N)':
                tempColumns[index] = 'closedEnd';
                break;
            case 'SC Fund Type':
                tempColumns[index] = 'scFundType';
                break;
            case 'Conventional / Syariah':
                tempColumns[index] = 'conventionalSyariah';
                break;
            case 'Fund Class':
            case 'AMP Class':
                tempColumns[index] = 'fundClass';
                break;
            case 'UTMC':
                tempColumns[index] = 'utmc';
                break;
            case 'Trustee':
                tempColumns[index] = 'trustee';
                break;
            case 'SC processing group':
                tempColumns[index] = 'scProcessingGroup';
                break;
            case 'EPF Approved':
            case 'EPF Approved (Y/N)':
                tempColumns[index] = 'epfApproved';
                break;
            case 'EPF Status':
                tempColumns[index] = 'epfStatus';
                break;
            case 'Fund Start Date':
                tempColumns[index] = 'fundStartDate';
                break;
            case 'IOP End Date':
                tempColumns[index] = 'iopEndDate';
                break;
            case 'Maturity date (Closed-end)':
                tempColumns[index] = 'maturityDate';
                break;
            case 'Commercial start Date':
                tempColumns[index] = 'commercialStartDate';
                break;
            case 'After IOP - Allow':
            case 'After IOP - Allow (Y/N)':
                tempColumns[index] = 'allow';
                break;
            case 'After IOP Allow-New Sales':
                tempColumns[index] = 'newSales';
                break;
            case 'After IOP Allow-Top Up Sales':
                tempColumns[index] = 'topUpSales';
                break;
            case 'After IOP Allow-Redemption':
                tempColumns[index] = 'redemption';
                break;
            case 'After IOP Allow-Redemption Cool Off':
                tempColumns[index] = 'redemptionCoolOff';
                break;
            case 'After IOP Allow-Switch IN':
                tempColumns[index] = 'switchIn';
                break;
            case 'After IOP Allow-Switch OUT':
                tempColumns[index] = 'switchOut';
                break;
            case 'After IOP Allow-Transfer':
                tempColumns[index] = 'transfer';
                break;
            case 'Income Distribution Instruction':
                tempColumns[index] = 'incomeDistributionInstruction';
                break;
            case 'Dividend timing':
                tempColumns[index] = 'dividendTiming';
                break;
            case 'Min Dividend Amount payout':
                tempColumns[index] = 'minDividendAmountPayout';
                break;
            case 'Daily Income Distribution':
            case 'Daily Income Distribution (Y/N)':
                tempColumns[index] = 'dailyIncomeDistribution';
                break;
            case 'Fund Base Currency':
                tempColumns[index] = 'fundBaseCurrency';
                break;
            case 'Fund Class Currency':
                tempColumns[index] = 'fundClassCurrency';
                break;
            case 'Issue Price':
                tempColumns[index] = 'issuePrice';
                break;
            case 'Annual Management Fee (%)':
                tempColumns[index] = 'annualManagementFee';
                break;
            case 'Annual Trustee Fee (%)':
                tempColumns[index] = 'annualTrusteeFee';
                break;
            case 'Min. Sales Fee (EPF) (%)':
                tempColumns[index] = 'minSalesFeeEpf';
                break;
            case 'Min. Sales Fee (Non-EPF) (%)':
                tempColumns[index] = 'minSalesFeeNonEpf';
                break;
            case 'Max. Sales Fee (EPF) (%)':
                tempColumns[index] = 'maxSalesFeeEpf';
                break;
            case 'Max. Sales Fee (Non-EPF) (%)':
                tempColumns[index] = 'maxSalesFeeNonEpf';
                break;
            case 'AIP Allowed':
            case 'AIP Allowed (Y/N)':
                tempColumns[index] = 'aipAllowed';
                break;
            case 'Min. New Sales Amt (EPF)':
                tempColumns[index] = 'minNewSalesAmtEpf';
                break;
            case 'Max. New Sales Amt (EPF)':
                tempColumns[index] = 'maxNewSalesAmtEpf';
                break;
            case 'Min. Topup Amt (EPF)':
                tempColumns[index] = 'minTopupAmtEpf';
                break;
            case 'Max. Topup Amt (EPF)':
                tempColumns[index] = 'maxTopupAmtEpf';
                break;
            case 'Min. New Sales Amt (NON-EPF)-Individual':
                tempColumns[index] = 'minNewSalesAmtNonEpfIndividual';
                break;
            case 'Max. New Sales Amt (NON-EPF)-Individual':
                tempColumns[index] = 'maxNewSalesAmtNonEpfIndividual';
                break;
            case 'Min. Topup Amt (NON-EPF)-Individual':
                tempColumns[index] = 'minTopUpAmtNonEpfIndividual';
                break;
            case 'Max. Topup Amt (NON-EPF)-Individual':
                tempColumns[index] = 'maxTopUpAmtNonEpfIndividual';
                break;
            case 'No Of Cool Of Days':
                tempColumns[index] = 'noOfCoolOfDays';
                break;
            case 'Exit fees charge - EPF %':
                tempColumns[index] = 'exitFeesChargeEpfPercentage';
                break;
            case 'Exit fees charge - EPF Amount':
                tempColumns[index] = 'exitFeesChargeEpfAmount';
                break;
            case 'Exit fees charge - NON-EPF %':
                tempColumns[index] = 'exitFeesChargeNonEpfPercentage';
                break;
            case 'Exit fees charge - NON-EPF Amount':
                tempColumns[index] = 'exitFeesChargeNonEpfAmount';
                break;
            case 'Redemption Type':
                tempColumns[index] = 'redemptionType';
                break;
            case 'Min. Redemption Units':
                tempColumns[index] = 'minRedemptionUnits';
                break;
            case 'Switching Type':
                tempColumns[index] = 'switchingType';
                break;
            case 'Min Switch out Units':
                tempColumns[index] = 'minSwitchOutUnits';
                break;
            case 'Min Switch IN Units - Min. New Sales (EPF) Amount':
                tempColumns[index] = 'minSalesEpfAmount';
                break;
            case 'Min Switch IN Units - Min. Topup (EPF) Amount':
                tempColumns[index] = 'minTopupEpfAmount';
                break;
            case 'Min Switch IN Units - Min. New Sales (Non-EPF) Amount':
                tempColumns[index] = 'minSalesNonEpfAmount';
                break;
            case 'Min Switch IN Units - Min. Topup (Non-EPF) Amount':
                tempColumns[index] = 'minTopupNonEpfAmount';
                break;

            case 'Switching Fee':
                tempColumns[index] = 'switchingFee';
                break;
            case 'Min. Transfer of PRS Provider-OUT (Units)':
            case 'Min. Transfer Out Units':
                tempColumns[index] = 'minTransferOutUnits';
                break;
            case 'Min. Initial Transfer of PRS Provider-IN (Amount)':
                tempColumns[index] = 'minInitialTransferInUnits';
                break;
            case 'Min. Topup Transfer of PRS Provider-IN (Amount)':
                tempColumns[index] = 'minTopupTransferInUnits';
                break;
            case 'Transfer Fee (PRS Provider)':
                tempColumns[index] = 'transferFeePrsProvider';
                break;
            case 'Min. Balance Holdings Units':
                tempColumns[index] = 'minBalanceHoldingsUnits';
                break;
            case 'Master Cut off Time':
            case 'Sales Cut off Time':
                tempColumns[index] = 'salesCutOffTime';
                break;
            case 'Redemption Cut off Time':
                tempColumns[index] = 'redemptionCutOffTime';
                break;
            case 'Batch cut off time Sales':
            case 'Batch cut off time - Sales':
                tempColumns[index] = 'batchCutOffTimeSales';
                break;
            case 'Batch cut off time Redemption':
            case 'Batch cut off time - Redemption':
                tempColumns[index] = 'batchCutOffTimeRedemption';
                break;
            case 'GST parameter':
            case 'GST parameter (%)':
                tempColumns[index] = 'gstParameter';
                break;
            case 'Risk Category':
                tempColumns[index] = 'riskCategory';
                break;
            case 'Non Business Day From date':
                tempColumns[index] = 'nonBusinessDayFromDate';
                break;
            case 'Non Business Day To date':
                tempColumns[index] = 'nonBusinessDayToDate';
                break;
            case 'Difference Fund Price':
            case 'Difference Fund Price (T+?)':
                tempColumns[index] = 'differenceFundPrice';
                break;
            case 'Sales Settlement day':
                tempColumns[index] = 'salesSettlementDay';
                break;
            case 'Redemption Settle date':
            case 'Redemption Settle date (T+?)':
                tempColumns[index] = 'redemptionSettleDate';
                break;
            case 'Switching Settle date':
            case 'Switching Settle date (T+?)':
                tempColumns[index] = 'switchingSettleDate';
                break;
            case 'Switching Group Settlement day':
                tempColumns[index] = 'switchingGroupSettlementDay';
                break;
            case 'Fund Objective':
                tempColumns[index] = 'fundObjective';
                break;
            case 'Dedicated fund':
                tempColumns[index] = 'dedicatedFund';
                break;
            case 'AMP Fee':
            case 'AMP Fee (%)':
                tempColumns[index] = 'ampFee';
                break;
            case 'Money sighted fund':
                tempColumns[index] = 'moneySightedFund';
                break;
            case 'FEL sharing':
                tempColumns[index] = 'felSharing';
                break;
            case 'Default Asset Allocation':
                tempColumns[index] = 'defaultAssetAllocation';
                break;
            case 'Lipper Code':
                tempColumns[index] = 'lipperCode';
                break;
            case 'Distributed (KUT / KIBB)':
                tempColumns[index] = 'distributed';
                break;
            case 'FEA Tagging':
                tempColumns[index] = 'feaTagging';
                break;
            case 'Landing Fund':
                tempColumns[index] = 'landingFund';
                break;
            // NAV, distribution starts here
            case 'Date':
            case 'Value Date':
                tempColumns[index] = 'date';
                break;
            case 'NAV per unit':
                tempColumns[index] = 'navPerUnit';
                break;
            case 'Gross Distribution':
            case 'Gross distribution per unit':
                tempColumns[index] = 'gross';
                break;
            case 'Net Distribution':
            case 'Net distribution per unit':
                tempColumns[index] = 'net';
                break;
            case 'Unit Split / Cancellation':
            case 'Unit Split/ Capital Consolidation (%)':
                tempColumns[index] = 'unit';
                break;
            default:
                tempColumns[index] = `${item.colName}-ERROR`;
                return;
        }
    });

    return tempColumns;
}
export function expandMasterFundCols(columns: Array<string>): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item) {
            case 'fundCode':
                tempColumns[index] = 'Fund Code';
                break;
            case 'tomsFundAbbreviation':
                tempColumns[index] = 'TOMS Fund Abbreviation';
                break;
            case 'omniFundAbbreviation':
                tempColumns[index] = 'OMNI Fund Abbreviation';
                break;
            case 'fundName':
                tempColumns[index] = 'Fund Name';
                break;
            case 'fundStatus':
                tempColumns[index] = 'Fund Status';
                break;
            case 'fundCategory':
                tempColumns[index] = 'Fund Category';
                break;
            case 'fundProcessingGroup':
                tempColumns[index] = 'Fund Processing Group';
                break;
            case 'retailFundWholesaleFund':
                tempColumns[index] = 'Retail Fund / Wholesale Fund';
                break;
            case 'closedEnd':
                tempColumns[index] = 'Closed-end';
                break;
            case 'scFundType':
                tempColumns[index] = 'SC Fund Type';
                break;
            case 'conventionalSyariah':
                tempColumns[index] = 'Conventional / Syariah';
                break;
            case 'fundClass':
                tempColumns[index] = 'Fund Class';
                break;
            case 'utmc':
                tempColumns[index] = 'UTMC';
                break;
            case 'trustee':
                tempColumns[index] = 'Trustee';
                break;
            case 'scProcessingGroup':
                tempColumns[index] = 'SC Processing Group';
                break;
            case 'epfApproved':
                tempColumns[index] = 'EPF Approved';
                break;
            case 'epfStatus':
                tempColumns[index] = 'EPF Status';
                break;
            case 'fundStartDate':
                tempColumns[index] = 'Fund Start Date';
                break;
            case 'iopEndDate':
                tempColumns[index] = 'IOP End Date';
                break;
            case 'maturityDate':
                tempColumns[index] = 'Maturity Date (Closed-end)';
                break;
            case 'commercialStartDate':
                tempColumns[index] = 'Commercial Start Date';
                break;
            case 'allow':
                tempColumns[index] = 'After IOP - Allow';
                break;
            case 'newSales':
                tempColumns[index] = 'After IOP Allow-New Sales';
                break;
            case 'topUpSales':
                tempColumns[index] = 'After IOP Allow-Top Up Sales';
                break;
            case 'redemption':
                tempColumns[index] = 'After IOP Allow-Redemption';
                break;
            case 'redemptionCoolOff':
                tempColumns[index] = 'After IOP Allow-Redemption Cool Off';
                break;
            case 'switchIn':
                tempColumns[index] = 'After IOP Allow-Switch IN';
                break;
            case 'switchOut':
                tempColumns[index] = 'After IOP Allow-Switch OUT';
                break;
            case 'transfer':
                tempColumns[index] = 'After IOP Allow-Transfer';
                break;
            case 'incomeDistributionInstruction':
                tempColumns[index] = 'Income Distribution Instruction';
                break;
            case 'dividendTiming':
                tempColumns[index] = 'Dividend timing';
                break;
            case 'minDividendAmountPayout':
                tempColumns[index] = 'Min Dividend Amount payout';
                break;
            case 'dailyIncomeDistribution':
                tempColumns[index] = 'Daily Income Distribution';
                break;
            case 'fundBaseCurrency':
                tempColumns[index] = 'Fund Base Currency';
                break;
            case 'fundClassCurrency':
                tempColumns[index] = 'Fund Class Currency';
                break;
            case 'issuePrice':
                tempColumns[index] = 'Issue Price';
                break;
            case 'annualManagementFee':
                tempColumns[index] = 'Annual Management Fee (%)';
                break;
            case 'annualTrusteeFee':
                tempColumns[index] = 'Annual Trustee Fee (%)';
                break;
            case 'minSalesFeeEpf':
                tempColumns[index] = 'Min. Sales Fee (EPF) (%)';
                break;
            case 'minSalesFeeNonEpf':
                tempColumns[index] = 'Min. Sales Fee (Non-EPF) (%)';
                break;
            case 'maxSalesFeeEpf':
                tempColumns[index] = 'Max. Sales Fee (EPF) (%)';
                break;
            case 'maxSalesFeeNonEpf':
                tempColumns[index] = 'Max. Sales Fee (Non-EPF) (%)';
                break;
            case 'aipAllowed':
                tempColumns[index] = 'AIP Allowed';
                break;
            case 'minNewSalesAmtEpf':
                tempColumns[index] = 'Min. New Sales Amt (EPF)';
                break;
            case 'maxNewSalesAmtEpf':
                tempColumns[index] = 'Max. New Sales Amt (EPF)';
                break;
            case 'minTopupAmtEpf':
                tempColumns[index] = 'Min. Topup Amt (EPF)';
                break;
            case 'maxTopupAmtEpf':
                tempColumns[index] = 'Max. Topup Amt (EPF)';
                break;
            case 'minNewSalesAmtNonEpfIndividual':
                tempColumns[index] = 'Min. New Sales Amt (NON-EPF)-Individual';
                break;
            case 'maxNewSalesAmtNonEpfIndividual':
                tempColumns[index] = 'Max. New Sales Amt (NON-EPF)-Individual';
                break;
            case 'minTopUpAmtNonEpfIndividual':
                tempColumns[index] = 'Min. Topup Amt (NON-EPF)-Individual';
                break;
            case 'maxTopUpAmtNonEpfIndividual':
                tempColumns[index] = 'Max. Topup Amt (NON-EPF)-Individual';
                break;
            case 'noOfCoolOfDays':
                tempColumns[index] = 'No Of Cool Of Days';
                break;
            case 'exitFeesChargeEpfPercentage':
                tempColumns[index] = 'Exit fees charge - EPF %';
                break;
            case 'exitFeesChargeEpfAmount':
                tempColumns[index] = 'Exit fees charge - EPF Amount';
                break;
            case 'exitFeesChargeNonEpfPercentage':
                tempColumns[index] = 'Exit fees charge - NON-EPF %';
                break;
            case 'exitFeesChargeNonEpfAmount':
                tempColumns[index] = 'Exit fees charge - NON-EPF Amount';
                break;
            case 'redemptionType':
                tempColumns[index] = 'Redemption Type';
                break;
            case 'minRedemptionUnits':
                tempColumns[index] = 'Min. Redemption Units';
                break;
            case 'switchingType':
                tempColumns[index] = 'Switching Type';
                break;
            case 'minSwitchOutUnits':
                tempColumns[index] = 'Min Switch out Units';
                break;
            case 'switchingFee':
                tempColumns[index] = 'Switching Fee';
                break;
            case 'minTransferOutUnits':
                tempColumns[index] = 'Min. Transfer of PRS Provider-OUT (Units)';
                break;
            case 'minInitialTransferInUnits':
                tempColumns[index] = 'Min. Initial Transfer of PRS Provider-IN (Amount)';
                break;
            case 'minTopupTransferInUnits':
                tempColumns[index] = 'Min. Topup Transfer of PRS Provider-IN (Amount)';
                break;
            case 'transferFeePrsProvider':
                tempColumns[index] = 'Transfer Fee (PRS Provider)';
                break;
            case 'minBalanceHoldingsUnits':
                tempColumns[index] = 'Min. Balance Holdings Units';
                break;
            case 'salesCutOffTime':
                tempColumns[index] = 'Master Cut off Time';
                break;
            case 'redemptionCutOffTime':
                tempColumns[index] = 'Redemption Cut off Time';
                break;
            case 'batchCutOffTimeSales':
                tempColumns[index] = 'Batch cut off time Sales';
                break;
            case 'batchCutOffTimeRedemption':
                tempColumns[index] = 'Batch cut off time Redemption';
                break;
            case 'gstParameter':
                tempColumns[index] = 'GST parameter';
                break;
            case 'riskCategory':
                tempColumns[index] = 'Risk Category';
                break;
            case 'nonBusinessDayFromDate':
                tempColumns[index] = 'Non Business Day From date';
                break;
            case 'nonBusinessDayToDate':
                tempColumns[index] = 'Non Business Day To date';
                break;
            case 'differenceFundPrice':
                tempColumns[index] = 'Difference Fund Price';
                break;
            case 'salesSettlementDay':
                tempColumns[index] = 'Sales Settlement Day';
                break;
            case 'redemptionSettleDate':
                tempColumns[index] = 'Redemption Settle Date';
                break;
            case 'switchingSettleDate':
                tempColumns[index] = 'Switching Settle Date';
                break;
            case 'switchingGroupSettlementDay':
                tempColumns[index] = 'Switching Group Settlement Day';
                break;
            case 'fundObjective':
                tempColumns[index] = 'Fund Objective';
                break;
            case 'dedicatedFund':
                tempColumns[index] = 'Dedicated Fund';
                break;
            case 'ampFee':
                tempColumns[index] = 'AMP Fee';
                break;
            case 'moneySightedFund':
                tempColumns[index] = 'Money Sighted Fund';
                break;
            case 'felSharing':
                tempColumns[index] = 'FEL sharing';
                break;
            case 'defaultAssetAllocation':
                tempColumns[index] = 'Default Asset Allocation';
                break;
            case 'lipperCode':
                tempColumns[index] = 'Lipper Code';
                break;
            case 'distributed':
                tempColumns[index] = 'Distributed  (KUT / KIBB)';
                break;
            case 'feaTagging':
                tempColumns[index] = 'FEA Tagging';
                break;
            case 'landingFund':
                tempColumns[index] = 'Landing Fund';
                break;
            // NAV, distribution starts here
            case 'date':
                tempColumns[index] = 'Date';
                break;
            case 'value':
                tempColumns[index] = 'Value';
                break;
            case 'gross':
                tempColumns[index] = 'Gross Distribution';
                break;
            case 'net':
                tempColumns[index] = 'Net Distribution';
                break;
            case 'unit':
                tempColumns[index] = 'Unit Split/ Capital Consolidation (%)';
                break;
            case 'navPerUnit':
                tempColumns[index] = 'NAV Per Unit';
                break;
            default:
                tempColumns[index] = `${item}-ERROR`;
                return;
        }
    });
    return tempColumns;
}
export function expandAMPFundCols(columns: Array<string>): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item) {
            case 'fundCode':
                tempColumns[index] = 'Fund Code';
                break;
            case 'tomsFundAbbreviation':
                tempColumns[index] = 'AMP Abbreviation';
                break;
            case 'omniFundAbbreviation':
                tempColumns[index] = 'OMNI Fund Abbreviation';
                break;
            case 'fundName':
                tempColumns[index] = 'AMP name';
                break;
            case 'fundStatus':
                tempColumns[index] = 'AMP Status';
                break;
            case 'fundCategory':
                tempColumns[index] = 'AMP Category';
                break;
            case 'fundProcessingGroup':
                tempColumns[index] = 'AMP Processing Group';
                break;
            case 'retailFundWholesaleFund':
                tempColumns[index] = 'Retail Fund / Wholesale Fund';
                break;
            case 'closedEnd':
                tempColumns[index] = 'Closed-end (Y/N)';
                break;
            case 'scFundType':
                tempColumns[index] = 'SC Fund Type';
                break;
            case 'conventionalSyariah':
                tempColumns[index] = 'Conventional / Syariah';
                break;
            case 'fundClass':
                tempColumns[index] = 'AMP Class';
                break;
            case 'utmc':
                tempColumns[index] = 'UTMC';
                break;
            case 'trustee':
                tempColumns[index] = 'Trustee';
                break;
            case 'scProcessingGroup':
                tempColumns[index] = 'SC Processing Group';
                break;
            case 'epfApproved':
                tempColumns[index] = 'EPF Approved (Y/N)';
                break;
            case 'epfStatus':
                tempColumns[index] = 'EPF Status';
                break;
            case 'fundStartDate':
                tempColumns[index] = 'Fund Start Date';
                break;
            case 'iopEndDate':
                tempColumns[index] = 'IOP End Date';
                break;
            case 'maturityDate':
                tempColumns[index] = 'Maturity Date (Closed-end)';
                break;
            case 'commercialStartDate':
                tempColumns[index] = 'Commercial Start Date';
                break;
            case 'allow':
                tempColumns[index] = 'After IOP - Allow (Y/N)';
                break;
            case 'newSales':
                tempColumns[index] = 'After IOP Allow-New Sales';
                break;
            case 'topUpSales':
                tempColumns[index] = 'After IOP Allow-Top Up Sales';
                break;
            case 'redemption':
                tempColumns[index] = 'After IOP Allow-Redemption';
                break;
            case 'redemptionCoolOff':
                tempColumns[index] = 'After IOP Allow-Redemption Cool Off';
                break;
            case 'switchIn':
                tempColumns[index] = 'After IOP Allow-Switch IN';
                break;
            case 'switchOut':
                tempColumns[index] = 'After IOP Allow-Switch OUT';
                break;
            case 'transfer':
                tempColumns[index] = 'After IOP Allow-Transfer';
                break;
            case 'incomeDistributionInstruction':
                tempColumns[index] = 'Income Distribution Instruction';
                break;
            case 'dividendTiming':
                tempColumns[index] = 'Dividend timing';
                break;
            case 'minDividendAmountPayout':
                tempColumns[index] = 'Min Dividend Amount payout';
                break;
            case 'dailyIncomeDistribution':
                tempColumns[index] = 'Daily Income Distribution (Y/N)';
                break;
            case 'fundBaseCurrency':
                tempColumns[index] = 'Fund Base Currency';
                break;
            case 'fundClassCurrency':
                tempColumns[index] = 'Fund Class Currency';
                break;
            case 'issuePrice':
                tempColumns[index] = 'Issue Price';
                break;
            case 'annualManagementFee':
                tempColumns[index] = 'Annual Management Fee (%)';
                break;
            case 'annualTrusteeFee':
                tempColumns[index] = 'Annual Trustee Fee (%)';
                break;
            case 'minSalesFeeEpf':
                tempColumns[index] = 'Min. Sales Fee (EPF) (%)';
                break;
            case 'minSalesFeeNonEpf':
                tempColumns[index] = 'Min. Sales Fee (Non-EPF) (%)';
                break;
            case 'maxSalesFeeEpf':
                tempColumns[index] = 'Max. Sales Fee (EPF) (%)';
                break;
            case 'maxSalesFeeNonEpf':
                tempColumns[index] = 'Max. Sales Fee (Non-EPF) (%)';
                break;
            case 'aipAllowed':
                tempColumns[index] = 'AIP Allowed (Y/N)';
                break;
            case 'minNewSalesAmtEpf':
                tempColumns[index] = 'Min. New Sales Amt (EPF)';
                break;
            case 'maxNewSalesAmtEpf':
                tempColumns[index] = 'Max. New Sales Amt (EPF)';
                break;
            case 'minTopupAmtEpf':
                tempColumns[index] = 'Min. Topup Amt (EPF)';
                break;
            case 'maxTopupAmtEpf':
                tempColumns[index] = 'Max. Topup Amt (EPF)';
                break;
            case 'minNewSalesAmtNonEpfIndividual':
                tempColumns[index] = 'Min. New Sales Amt (NON-EPF)-Individual';
                break;
            case 'maxNewSalesAmtNonEpfIndividual':
                tempColumns[index] = 'Max. New Sales Amt (NON-EPF)-Individual';
                break;
            case 'minTopUpAmtNonEpfIndividual':
                tempColumns[index] = 'Min. Topup Amt (NON-EPF)-Individual';
                break;
            case 'maxTopUpAmtNonEpfIndividual':
                tempColumns[index] = 'Max. Topup Amt (NON-EPF)-Individual';
                break;
            case 'noOfCoolOfDays':
                tempColumns[index] = 'No Of Cool Of Days';
                break;
            case 'exitFeesChargeEpfPercentage':
                tempColumns[index] = 'Exit fees charge - EPF %';
                break;
            case 'exitFeesChargeEpfAmount':
                tempColumns[index] = 'Exit fees charge - EPF Amount';
                break;
            case 'exitFeesChargeNonEpfPercentage':
                tempColumns[index] = 'Exit fees charge - NON-EPF %';
                break;
            case 'exitFeesChargeNonEpfAmount':
                tempColumns[index] = 'Exit fees charge - NON-EPF Amount';
                break;
            case 'redemptionType':
                tempColumns[index] = 'Redemption Type';
                break;
            case 'minRedemptionUnits':
                tempColumns[index] = 'Min. Redemption Units';
                break;
            case 'switchingType':
                tempColumns[index] = 'Switching Type';
                break;
            case 'minSwitchOutUnits':
                tempColumns[index] = 'Min Switch out Units';
                break;
            case 'switchingFee':
                tempColumns[index] = 'Switching Fee';
                break;
            case 'minTransferOutUnits':
                tempColumns[index] = 'Min. Transfer Out Units';
                break;
            case 'minInitialTransferInUnits':
                tempColumns[index] = 'Min. Initial Transfer of PRS Provider-IN (Amount)';
                break;
            case 'minTopupTransferInUnits':
                tempColumns[index] = 'Min. Topup Transfer of PRS Provider-IN (Amount)';
                break;
            case 'transferFeePrsProvider':
                tempColumns[index] = 'Transfer Fee (PRS Provider)';
                break;
            case 'minBalanceHoldingsUnits':
                tempColumns[index] = 'Min. Balance Holdings Units';
                break;
            case 'salesCutOffTime':
                tempColumns[index] = 'Sales Cut off Time';
                break;
            case 'redemptionCutOffTime':
                tempColumns[index] = 'Redemption Cut off Time';
                break;
            case 'batchCutOffTimeSales':
                tempColumns[index] = 'Batch cut off time - Sales';
                break;
            case 'batchCutOffTimeRedemption':
                tempColumns[index] = 'Batch cut off time - Redemption';
                break;
            case 'gstParameter':
                tempColumns[index] = 'GST parameter (%)';
                break;
            case 'riskCategory':
                tempColumns[index] = 'Risk Category';
                break;
            case 'nonBusinessDayFromDate':
                tempColumns[index] = 'Non Business Day From date';
                break;
            case 'nonBusinessDayToDate':
                tempColumns[index] = 'Non Business Day To date';
                break;
            case 'differenceFundPrice':
                tempColumns[index] = 'Difference Fund Price (T+?)';
                break;

            case 'redemptionSettleDate':
                tempColumns[index] = 'Redemption Settle date (T+?)';
                break;
            case 'switchingSettleDate':
                tempColumns[index] = 'Switching Settle date (T+?)';
                break;

            case 'fundObjective':
                tempColumns[index] = 'Fund Objective';
                break;
            case 'dedicatedFund':
                tempColumns[index] = 'Dedicated Fund';
                break;
            case 'ampFee':
                tempColumns[index] = 'AMP Fee (%)';
                break;
            case 'moneySightedFund':
                tempColumns[index] = 'Money Sighted Fund';
                break;
            case 'felSharing':
                tempColumns[index] = 'FEL sharing';
                break;
            case 'defaultAssetAllocation':
                tempColumns[index] = 'Default Asset Allocation';
                break;
            case 'lipperCode':
                tempColumns[index] = 'Lipper Code';
                break;
            case 'distributed':
                tempColumns[index] = 'Distributed (KUT / KIBB)';
                break;
            case 'landingFund':
                tempColumns[index] = 'Landing Fund';
                break;
            case 'minSalesEpfAmount':
                tempColumns[index] = 'Min Switch IN Units - Min. New Sales (EPF) Amount';
                break;
            case 'minTopupEpfAmount':
                tempColumns[index] = 'Min Switch IN Units - Min. Topup (EPF) Amount';
                break;
            case 'minSalesNonEpfAmount':
                tempColumns[index] = 'Min Switch IN Units - Min. New Sales (Non-EPF) Amount';
                break;
            case 'minTopupNonEpfAmount':
                tempColumns[index] = 'Min Switch IN Units - Min. Topup (Non-EPF) Amount';
                break;
            default:
                tempColumns[index] = `${item}-ERROR`;
                return;
        }
    });
    return tempColumns;
}
export function expandBulkUserCols(columns: Array<string>): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item) {
            case 'name':
                tempColumns[index] = 'Name';
                break;
            case 'email':
                tempColumns[index] = 'Email';
                break;
            case 'username':
                tempColumns[index] = 'User Name';
                break;
            case 'branch_id':
                tempColumns[index] = 'Branch ID';
                break;
            case 'department_id':
                tempColumns[index] = 'Department ID';
                break;
            case 'sso_id':
                tempColumns[index] = 'SSO ID';
                break;
            case 'group_id':
                tempColumns[index] = 'Group ID';
                break;
            case 'mobile_no':
                tempColumns[index] = 'Mobile No';
                break;
            case 'description':
                tempColumns[index] = 'Description';
                break;
            default:
                tempColumns[index] = `${item}-ERROR`;
                return;
        }
    });
    return tempColumns;
}
export function checkFileType(fileType: string): string {
    let mimeType = '';
    switch (fileType) {
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            mimeType = 'application/vnd.ms-excel';
            break;
        case 'application/vnd.ms-excel':
            mimeType = 'text/csv';
            break;
        default:
            mimeType = fileType;
    }
    return mimeType;
}

export function moveItemInArray(from: number, to: number, array: ISheetColumn[]): ISheetColumn[] {
    const item = array.splice(from, 1)[0];
    array.splice(to, 0, item);
    return array;
}

export function getDocumentType(documentType: string): string {
    let docName = '';
    switch (documentType) {
        case 'AR':
            docName = 'Annual Report';
            break;
        case 'FFS':
            docName = 'Monthly Fund Fact Sheet';
            break;
        case 'PHS':
            docName = 'Product Highlight Sheet';
            break;
        case 'PI':
            docName = 'Prospectus Information';
            break;
        case 'IM':
            docName = 'Information Memorandum';
            break;
        case 'DD':
            docName = 'Disclosure Document';
            break;
        default:
            docName = 'DOCUMENT TYPE NOT IN SYSTEM';
    }
    return docName;
}

export function getFileSize(bytes: string): string {
    const _bytes = parseInt(bytes);
    let _tempValue = '';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (_bytes == 0) return '0 Byte';

    const sizeIndex = Math.floor(Math.log(_bytes) / Math.log(1024));
    switch (sizeIndex) {
        case 1:
            _tempValue = Math.floor(_bytes / Math.pow(1024, sizeIndex)) + ' ' + sizes[sizeIndex];
            break;
        default:
            _tempValue = (_bytes / Math.pow(1024, sizeIndex)).toFixed(2) + ' ' + sizes[sizeIndex];
    }
    return _tempValue;
}

export function getSpecificFileSize(bytes: string, type: string): string {
    const _bytes = parseInt(bytes);
    let _tempValue = '';

    if (_bytes == 0) return `0 ${type}`;
    switch (type) {
        case 'KB':
            _tempValue = Math.floor(_bytes / Math.pow(1024, 1)) + ' ' + type;
            break;
        case 'MB':
            _tempValue = (_bytes / Math.pow(1024, 2)).toFixed(2) + ' ' + type;
    }
    return _tempValue;
}
