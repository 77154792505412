import gql from 'graphql-tag';

export const validateRolesDropdown = gql`
    mutation validateDropdown($input: VDropDownInput) {
        validateDropdown(input: $input) {
            data {
                result {
                    roles
                }
            }
            error {
                errorCode
                message
                statusCode
            }
        }
    }
`;
