import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv, CustomSpacer, CustomButton, TextArea, PreviousPage } from '../../components';
import { SYSTEM_ADMIN, LABEL, Fs15BoldPrimaryBlack } from '../../constants';
import { reasonsPageButtons } from '../../styles';

interface IRejectRemarksProps {
    rejectRemarks: string;
    handleRejectRemarks: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleCancel: () => void;
    handleSubmit: () => void;
}
const RejectRemarks = ({
    handleRejectRemarks,
    rejectRemarks,
    handleCancel,
    handleSubmit,
}: IRejectRemarksProps): JSX.Element => {
    return (
        <Fragment>
            <FlexedDiv direction="row">
                {/* <CustomSpacer horizontal={true} space={'3rem'} /> */}

                <FlexedDiv direction="column" style={{ width: '100%' }}>
                    <PreviousPage
                        title={SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS}
                        subTitle={SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS_SUB}
                        // secondSubtitle={
                        //     isRequest ? (approval.status === '' ? '' : '') : LABEL.rerouteOrderDescription_part2
                        // }
                    />
                    {/* <Fs24BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS}</Fs24BoldPrimaryBlack> */}
                    {/* <CustomSpacer space={'0.5rem'} /> */}
                    {/* <Fs16SemiBoldSecPrimaryBlack>
                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS_SUB}
                    </Fs16SemiBoldSecPrimaryBlack> */}
                    <RejectRemarksWrap>
                        <TextArea
                            value={rejectRemarks}
                            handleInput={handleRejectRemarks}
                            maxLength={255}
                            label={LABEL.remarks}
                            style={{ minWidth: '512px', width: '100%', maxWidth: '560px' }}
                            placeHolder={LABEL.rejectTextAreaPlaceholder}
                        />

                        <ButtonDiv>
                            <CustomButton style={reasonsPageButtons} onClick={() => handleCancel()}>
                                <Fs15BoldPrimaryBlack>{LABEL.cancel}</Fs15BoldPrimaryBlack>
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                disabled={rejectRemarks.trim().length === 0}
                                primary
                                style={reasonsPageButtons}
                                onClick={() => handleSubmit()}
                            >
                                {LABEL.submit}
                            </CustomButton>
                        </ButtonDiv>
                    </RejectRemarksWrap>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};
export default RejectRemarks;

const RejectRemarksWrap = styled.div`
    max-width: 600px;
    margin-left: 2.5rem;
`;

const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    height: 3rem;
    max-width: 25vw;
    margin-top: 3.5rem;
`;
