/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment } from 'react';
import { TextArea } from '../../atoms';
import CustomCheckbox from '../../atoms/Checkbox';
interface ICheckboxWithInputProps extends ICustomCheckboxProps {
    disabled?: boolean;
    handleText: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    inputLabel?: string;
    text: string;
}

const CheckboxWithInput = ({
    checked,
    className,
    disabled,
    handleCheckbox,
    handleText,
    id,
    inputLabel,
    label,
    text,
    value,
}: ICheckboxWithInputProps): JSX.Element => {
    return (
        <Fragment>
            <div className={className}>
                <CustomCheckbox
                    checked={checked}
                    label={label}
                    id={id}
                    value={value}
                    disabled={disabled}
                    handleCheckbox={handleCheckbox}
                />
                <div style={{ marginTop: '0.75rem', marginLeft: '1.875rem' }}>
                    {checked ? (
                        <TextArea
                            maxLength={255}
                            disabled={disabled}
                            label={inputLabel ?? ''}
                            value={text}
                            handleInput={handleText!}
                        />
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};
export default CheckboxWithInput;
