/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { ReactText, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import FlexSpacer, {
    TextArea,
    ComponentLoader,
    CustomSpacer,
    PreviousPage,
    FlexedDiv,
    SelectDropdown,
} from '../../../../components';
import { CustomInput } from '../../../../components/atoms/Input';
import { PhoneInput } from '../../../../components/atoms/Input/PhoneInput';
import MultiSelectV2 from '../../../../components/molecules/MultiSelect';
import { LABEL, TextPureBlack, Fs12BoldPrimaryBlack, Fs12RegPrimaryBlack } from '../../../../constants';
import { addUserModes } from '../../../../constants/addUserModes';
import { TextBold12, TextBold18 } from '../../../../constants/textStyles';
import { IValidation } from '../../../../interfaces/IValidation';
import * as Routes from '../../../../routes';
import { sh24, sh32, sh64 } from '../../../../styles';
import { Validate } from '../../../../validation/validate';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { useComponentLoader } from '../../../../customHooks';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface addUserStateProps {
    loginID: string;
    name: string;
    ssoID: string;
    verified: boolean;
    status: string;
    homeBranch: string;
    department: string;
    email: string;
    mobile: string;
    description: string;
    userGroups: ReactText[];
    rejectRemark: string;
    userID: string;
    requestId: string;
}

const initialUser: addUserStateProps = {
    loginID: '',
    name: '',
    ssoID: '',
    verified: false,
    status: 'Pending',
    homeBranch: '',
    department: '',
    email: '',
    mobile: '',
    description: '',
    userGroups: [],
    rejectRemark: '',
    userID: '',
    requestId: '',
};

export interface locationData {
    mode: string;
    data?: ITableData;
}

export const UserActivity = (): JSX.Element => {
    const [error, setError] = useState({ errorCount: 0, loginID: '', name: '', email: '', mobile: '' });
    // const [apiGroups, setApiGroups] = useState<string[]>([]);
    const [userData, setUserData] = useState({ ...initialUser });
    const [operationName, setOperationName] = useState<string>('');
    const [rejectedBy, setRejectedBy] = useState<string>('');
    const [rejectRemarks, setRejectRemarks] = useState<string>('');
    const [operationStatus, setOperationStatus] = useState<string>('Successful');
    // loader
    const { loading, loadingHandler } = useComponentLoader();
    const { branchOptions, departmentOptions, groupsOptions } = useContext(SystemAdminContext);

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const state: any = useLocation<locationData>().state;

    const getMode = async () => {
        const mode = 'reviewUser';
        loadingHandler();
        if (mode !== addUserModes.addUser) {
            const tempUser = { ...initialUser };
            try {
                const response: any = await API.graphql(
                    graphqlOperation(ActivityLogsSummaryQuery, {
                        input: {
                            id: state.id,
                        },
                    }),
                    idTokenHeader,
                );

                const { data, error } = await response.data.getLogData;
                if (error !== null) throw error;
                setOperationName(data.result.data.operationName);
                setOperationStatus(data.result.data.operationStatus);
                if (data.result.data.status.toLowerCase() === 'rejected') {
                    setRejectedBy(data.result.data.name);
                    setRejectRemarks(data.result.data.remark);
                }
                if (data.result.data.status.toLowerCase() === 'failed') {
                    const failedUserInfo = JSON.parse(data.result.data.data);

                    if (failedUserInfo) {
                        if (
                            data.result.data.operationName.toLowerCase().includes('terminate') ||
                            data.result.data.operationName.toLowerCase().includes('suspend') ||
                            data.result.data.operationName.toLowerCase().includes('reactivate')
                        ) {
                            const parsedInfo = failedUserInfo;
                            const branch_id_string =
                                typeof parsedInfo.branch_id === 'string'
                                    ? parsedInfo.branch_id
                                    : parsedInfo.branch_id.toString();
                            const department_id_string =
                                typeof parsedInfo.department_id === 'string'
                                    ? parsedInfo.department_id
                                    : parsedInfo.department_id.toString();
                            const userBranch = branchOptions.filter((branch) => branch.key === branch_id_string);
                            const department = departmentOptions.filter(
                                (department) => department.key === department_id_string,
                            );

                            const groupIds =
                                typeof parsedInfo.groupIds === 'string'
                                    ? JSON.parse(parsedInfo.groupIds)
                                    : parsedInfo.groupIds.ids;

                            const groupString = groupIds.map((id: number) =>
                                typeof id === 'string' ? id : id.toString(),
                            );
                            tempUser.loginID = parsedInfo.username;
                            tempUser.name = parsedInfo.name;
                            tempUser.email = parsedInfo.email;
                            tempUser.description = parsedInfo.description ? parsedInfo.description : '';
                            tempUser.mobile = parsedInfo.mobile_no ? parsedInfo.mobile_no : '';
                            tempUser.verified = false;
                            tempUser.homeBranch = userBranch[0] !== undefined ? (userBranch[0].label as string) : '';
                            const tempStatus = parsedInfo.currentStatus !== undefined ? parsedInfo.currentStatus : '';
                            tempUser.status =
                                data.result.data.status.toLowerCase() === 'rejected'
                                    ? tempStatus.toLowerCase() === 'pending'
                                        ? '-'
                                        : tempStatus
                                    : parsedInfo.requestedStatus === null
                                    ? '-'
                                    : parsedInfo.requestedStatus;
                            tempUser.department = department[0] !== undefined ? (department[0].label as string) : '';
                            tempUser.userGroups = groupString;
                            tempUser.userID = parsedInfo.userId;
                            setUserData({ ...tempUser });
                        } else {
                            const branch_id_string = failedUserInfo.branch !== undefined ? failedUserInfo.branch : '';
                            const department_id_string =
                                failedUserInfo.department_id !== undefined ? failedUserInfo.department_id : '';
                            const userBranch = branchOptions.filter((branch) => branch.key === branch_id_string);
                            const department = departmentOptions.filter(
                                (department) => department.key === department_id_string,
                            );
                            const groupString = failedUserInfo.userGroup.map((id: number) =>
                                typeof id === 'string' ? id : id.toString(),
                            );
                            tempUser.loginID = failedUserInfo.username;
                            tempUser.name = failedUserInfo.name;
                            tempUser.email = failedUserInfo.email;
                            tempUser.description = failedUserInfo.description ? failedUserInfo.description : '';
                            tempUser.mobile = failedUserInfo.mobile_no ? failedUserInfo.mobile_no : '';
                            tempUser.verified = false;
                            tempUser.homeBranch = userBranch[0] !== undefined ? (userBranch[0].label as string) : '';
                            tempUser.status = failedUserInfo.status;
                            tempUser.department = department[0] !== undefined ? (department[0].label as string) : '';
                            tempUser.userGroups = groupString;
                            // tempUser.userID = failedUserInfo.userId;
                            setUserData({ ...tempUser });
                        }
                    }
                } else {
                    const info = JSON.parse(data.result.data.data);

                    // if (error) {

                    //     console.log(error.message);
                    // }
                    if (data) {
                        if (info) {
                            const parsedInfo = info;
                            const branch_id_string =
                                typeof parsedInfo.branch_id === 'string'
                                    ? parsedInfo.branch_id
                                    : parsedInfo.branch_id.toString();
                            const department_id_string =
                                typeof parsedInfo.department_id === 'string'
                                    ? parsedInfo.department_id
                                    : parsedInfo.department_id.toString();
                            const userBranch = branchOptions.filter((branch) => branch.key === branch_id_string);
                            const department = departmentOptions.filter(
                                (department) => department.key === department_id_string,
                            );

                            const groupIds =
                                typeof parsedInfo.groupIds === 'string'
                                    ? JSON.parse(parsedInfo.groupIds)
                                    : parsedInfo.groupIds.ids;

                            const groupString = groupIds.map((id: number) =>
                                typeof id === 'string' ? id : id.toString(),
                            );
                            tempUser.loginID = parsedInfo.username;
                            tempUser.name = parsedInfo.name;
                            tempUser.email = parsedInfo.email;
                            tempUser.description = parsedInfo.description ? parsedInfo.description : '';
                            tempUser.mobile = parsedInfo.mobile_no ? parsedInfo.mobile_no : '';
                            tempUser.verified = false;
                            tempUser.homeBranch = userBranch[0] !== undefined ? (userBranch[0].label as string) : '';
                            const tempStatus = parsedInfo.currentStatus !== undefined ? parsedInfo.currentStatus : '';
                            tempUser.status =
                                data.result.data.status.toLowerCase() === 'rejected'
                                    ? tempStatus.toLowerCase() === 'pending'
                                        ? '-'
                                        : tempStatus
                                    : parsedInfo.requestedStatus === null
                                    ? '-'
                                    : parsedInfo.requestedStatus;
                            tempUser.department = department[0] !== undefined ? (department[0].label as string) : '';
                            tempUser.userGroups = groupString;
                            tempUser.userID = parsedInfo.userId;
                            setUserData({ ...tempUser });
                        }
                    }
                    loadingHandler();
                }
            } catch (error) {
                loadingHandler();
                const _error = error as IErrorHandling;
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch User Data',
                    testId: 'activitylogs-user-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    useEffect(() => {
        getMode();
    }, [departmentOptions]);

    const updateUserData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        const att = e.currentTarget.getAttribute('validation');
        let valueMessage = e.currentTarget.getAttribute('validationMessage');
        if (valueMessage === null) {
            valueMessage = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;

        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valueMessage);
            let errVal = '';
            if (err.code !== 'NoError') {
                errVal = err.message;
            }
            const update = { ...error, [key]: errVal };
            update.errorCount = 0;
            for (const [key, val] of Object.entries(update)) {
                if (key !== 'errorCount') {
                    if (typeof val === 'string' && val.length > 0) {
                        update.errorCount += 1;
                    }
                }
            }
            setError(update);
        }
        if (key === 'verify') {
            setUserData({ ...userData, verified: !userData.verified });
        } else {
            setUserData({ ...userData, [key]: value });
        }
    };
    const updateUserDataSelect = (value: ReactText, field: string) => {
        setUserData({ ...userData, [field]: value.toString() });
    };

    const getSelectedList = (list: Array<ReactText>) => {
        setUserData({ ...userData, userGroups: list });
    };

    const handleTitle = (operationName: string) => {
        switch (operationName) {
            case 'Add New User':
                if (state.maker === true) {
                    return 'Review New User Request';
                } else {
                    return operationName;
                }
            case 'Update User':
                if (state.maker === true) {
                    return 'Review Edit User Request';
                } else {
                    return operationName;
                }
            default:
                return operationName;
        }
    };

    return (
        <Container>
            <FlexedDiv alignItems="center">
                <PreviousPage
                    backIcon={true}
                    title={handleTitle(operationName)}
                    handleBack={Routes.activityLogs}
                    suffixHelper={operationStatus}
                />
            </FlexedDiv>
            <BodyContainer>
                <div style={{ paddingLeft: sh32 }}>
                    {rejectedBy !== '' && rejectRemarks !== '' ? (
                        <RejectedBanner direction="column">
                            <TextPureBlack>{`${LABEL.thisRequestRejectedBy} ${rejectedBy}.`}</TextPureBlack>
                            <CustomSpacer space={'.5rem'} />
                            <Fs12BoldPrimaryBlack>{LABEL.rejectReason}</Fs12BoldPrimaryBlack>
                            <Fs12RegPrimaryBlack>{rejectRemarks}</Fs12RegPrimaryBlack>
                        </RejectedBanner>
                    ) : null}
                    <CustomSpacer space={'1.5rem'} />
                    <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userProfile}</TextBold18>
                    <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                        <CustomInput
                            label={LABEL.loginID}
                            name="loginID"
                            disabled={true}
                            style={{ marginRight: '64px' }}
                            value={userData.loginID}
                            inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                            onChange={(e) => updateUserData(e)}
                            onLostFocus={(e) => updateUserData(e)}
                            validation={JSON.stringify({ type: 'alphanumeric', minLength: 3 } as IValidation)}
                            validationMessage="Login ID"
                            errorText={error.loginID}
                        />
                    </FlexedDiv>
                    <FlexedDiv style={{ marginBottom: sh24 }} alignItems="center">
                        <CustomInput
                            label={LABEL.name}
                            name="name"
                            disabled={true}
                            onChange={(e) => updateUserData(e)}
                            style={{ marginRight: '64px' }}
                            inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                            value={userData.name}
                            validation={JSON.stringify({ type: 'string', minLength: 3 } as IValidation)}
                            validationMessage="Name"
                            errorText={error.name}
                        />
                        <FlexedDiv direction="column">
                            <TextBold12 style={{ color: '#333333' }}>{LABEL.status}</TextBold12>
                            <SelectWrapper>
                                <SelectDropdown
                                    onChange={(value) => updateUserDataSelect(value, 'status')}
                                    options={[{ label: userData.status as string, value: userData.status as string }]}
                                    testId={`status-dropdown`}
                                    selectedValue={userData.status as string}
                                    isDisabled={true}
                                    fontWeight={700}
                                />
                            </SelectWrapper>
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv alignItems="center">
                        <FlexedDiv direction="column">
                            <FlexedDiv direction="column">
                                <CustomInput
                                    label={LABEL.homeBranch}
                                    name="homeBranch"
                                    disabled={true}
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    onChange={() => {
                                        // console.log(e);
                                    }}
                                    style={{ marginRight: '64px' }}
                                    inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                    value={userData.homeBranch}
                                />
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv direction="column">
                            <CustomInput
                                label={LABEL.departments}
                                name="homeBranch"
                                disabled={true}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onChange={() => {
                                    // console.log(e);
                                }}
                                style={{ marginRight: '64px' }}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                value={userData.department}
                            />
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv style={{ marginBottom: sh24, marginTop: sh24 }} alignItems="center">
                        <CustomInput
                            label={LABEL.emailAddress}
                            name="email"
                            disabled={true}
                            onChange={(e) => updateUserData(e)}
                            style={{ marginRight: '64px' }}
                            inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                            value={userData.email}
                            validation={JSON.stringify({ type: 'email' } as IValidation)}
                            validationMessage="Email"
                            errorText={error.email}
                        />
                        <PhoneInput
                            label={LABEL.mobileNo}
                            name="mobile"
                            onChange={(e) => updateUserData(e)}
                            prefix="+60"
                            disabled={true}
                            style={{ marginRight: '64px' }}
                            value={userData.mobile}
                            validation={JSON.stringify({
                                type: 'phone',
                                minLength: 9,
                                maxLength: 9,
                            } as IValidation)}
                            validationMessage="Mobile number"
                            errorText={error.mobile}
                        />
                    </FlexedDiv>
                    <TextArea
                        name="description"
                        label={LABEL.description}
                        value={userData.description}
                        handleInput={(e) => updateUserData(e)}
                        maxLength={255}
                        disabled={true}
                        style={{ width: '360px' }}
                    />
                    <TextBold18 style={{ marginBottom: sh24, marginTop: sh64 }}>{LABEL.assignUserGroup}</TextBold18>
                    <MultiSelectV2
                        disabled={true}
                        expandable={true}
                        label={LABEL.userGroup}
                        options={groupsOptions}
                        placeHolder={LABEL.selectOneOrMultiple}
                        checkedState={userData.userGroups}
                        handleMultiSelect={(item) => getSelectedList(item)}
                        selectId={1}
                    />

                    <FlexSpacer />
                </div>
            </BodyContainer>
            {loading ? <ComponentLoader /> : null}
            <CustomSpacer space={'2rem'} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    pointer-events: none;
`;

export const RejectedBanner = styled((props) => <FlexedDiv {...props} />)`
    background-color: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
    max-width: 70vw;
`;

const SelectWrapper = styled.div`
    width: 360px;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    margin-top: -7px;
`;
