import React, { useContext } from 'react';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { CustomDropdownV2, IDropdownItemV2 } from '../../../components';
import ProductSettingsContext from '../../../context/ProductSettingsContext';

export const DateFilter = (): JSX.Element => {
    const { handleColumnFilterChange, sortData, setSortData } = useContext(ProductSettingsContext);
    const handleAction = (item: string, rawData: ITableData | undefined, value: string | undefined) => {
        item === 'Fund Start Date'
            ? handleColumnFilterChange('', 'lastUpdated', 'Fund Start Date')
            : handleColumnFilterChange('', 'lastUpdated', 'Last Updated');
        const tempSort = { ...sortData };
        tempSort.column = item === 'Fund Start Date' ? 'fundStartDate' : 'lastUpdated';
        tempSort.value = value as string;
        setSortData(tempSort);
    };
    const handleIcon = (item: string) => {
        if (item === LABEL.lastUpdated && sortData.column === 'lastUpdated')
            return sortData.value === 'descending'
                ? 'arrow-down'
                : sortData.value === 'ascending'
                ? 'arrow-up'
                : undefined;
        else if (item === 'Fund Start Date' && sortData.column === 'fundStartDate')
            return sortData.value === 'descending'
                ? 'arrow-down'
                : sortData.value === 'ascending'
                ? 'arrow-up'
                : undefined;
        else return undefined;
    };
    const handleValue = (value: string) => {
        return value === 'descending' ? 'ascending' : 'descending';
    };
    const items: IDropdownItemV2[] = [
        {
            item: LABEL.lastUpdated,
            handleItem: handleAction,
            icon: handleIcon(LABEL.lastUpdated),
            value: handleValue(sortData.value),
        },
        {
            item: 'Fund Start Date',
            handleItem: handleAction,
            icon: handleIcon('Fund Start Date'),
            value: handleValue(sortData.value),
        },
    ];
    return (
        <CustomDropdownV2
            items={items}
            handleReset={() => handleColumnFilterChange('', 'lastUpdated', 'Last Updated')}
            selectIcon={true}
            showDivider={false}
            iconPosition="after"
            noMinWidth
        >
            <IcoMoon name="caret-down" size="1rem" />
        </CustomDropdownV2>
    );
};
