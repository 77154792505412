/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES } from '../../../constants';

import HistoryActions from './HistoryActions';
import CustomItem from './CustomItem';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import { IHistoryTab } from '../../../utils/permissionTypes';
import { useHistoryHandler } from './useHistoryHandler';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';

interface HistoryProps {
    filters: ISort_FilterInput;
    historyTab: IHistoryTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    lastUpdatedDropdown: string;
    loadingHandler: () => void;
    searchInput: string;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
}

export const History: React.FC<HistoryProps> = ({
    filters,
    historyTab,
    idTokenHeader,
    isFilterApplied,
    isSearchResult,
    lastUpdatedDropdown,
    loadingHandler,
    searchInput,
    setErrorHandler,
    setErrorMessage,
    setFilters,
    setLastUpdatedDropdown,
    setSortData,
    sortData,
}: HistoryProps) => {
    const { handlePrintTransactionReport, handleDownloadPPA, columnsHistory, historyGrouped, historyNonGrouped } =
        useHistoryHandler({
            filters,
            historyTab,
            idTokenHeader,
            isFilterApplied,
            isSearchResult,
            lastUpdatedDropdown,
            loadingHandler,
            searchInput,
            setErrorHandler,
            setErrorMessage,
            setFilters,
            setLastUpdatedDropdown,
            setSortData,
            sortData,
        });
    const { orderTypePills } = useContext(DashboardBranchContext);
    const grouped = orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0;
    return (
        <Fragment>
            {grouped ? (
                <AdvanceTable
                    data={historyGrouped.current}
                    columns={columnsHistory}
                    RenderOptions={(props: ITableOptions) => (
                        <HistoryActions
                            {...props}
                            handlePrintTransactionReport={handlePrintTransactionReport}
                            handleDownloadPPA={handleDownloadPPA}
                            historyTab={historyTab}
                        />
                    )}
                    onEmptyState={
                        isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableHistory
                    }
                    RenderItem={(props: ITableCustomItem) => (
                        <CustomItem isSortedColumn={sortData[0].column} {...props} />
                    )}
                    isSearchResult={isSearchResult}
                    searchInput={searchInput}
                    groupedRow
                    testId="branchhistory"
                />
            ) : (
                <AdvanceTable
                    data={historyNonGrouped.current}
                    columns={columnsHistory}
                    RenderOptions={(props: ITableOptions) => (
                        <HistoryActions
                            {...props}
                            handlePrintTransactionReport={handlePrintTransactionReport}
                            handleDownloadPPA={handleDownloadPPA}
                            historyTab={historyTab}
                        />
                    )}
                    onEmptyState={
                        isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableHistory
                    }
                    RenderItem={(props: ITableCustomItem) => (
                        <CustomItem isSortedColumn={sortData[0].column} {...props} />
                    )}
                    isSearchResult={isSearchResult}
                    searchInput={searchInput}
                    testId="branchhistory"
                />
            )}
        </Fragment>
    );
};

export default History;
