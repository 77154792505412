import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import { IcoMoon } from '../../../icons';
import { AdvanceTableRow } from './AdvanceTableRow';
import { TableHeader } from './TableHeader';
import { EmptyState } from '../../../templates/EmptyState';
import { Fs12RegSecNavyBlue, TextNavyBlue } from '../../../constants';
import { CustomSpacer } from '../../atoms/CustomSpacer';
import styled from 'styled-components';

interface AdvanceTableProps {
    actionsLabel?: string;
    activeAccordion?: string[];
    activeDisabledRows?: IDisabledRows;
    columns: ITableColumn[];
    // data: ITableData[];
    disabledRows?: IDisabledRows;
    emptyState?: ReactNode;
    groupByLabel?: string;
    handleSelectHeaderCheck?: () => void;
    hasHeaderCheckbox?: boolean;
    headerCheckbox?: boolean;
    headerCheckboxDisabled?: boolean;
    headerCheckboxInter?: boolean;
    onEmptyState: IEmptyState;
    onRowSelect?: (record: ITableData) => void;
    RenderAccordion?: (record: ITableData, index: number) => JSX.Element;
    RenderGroupByLabel?: (props: ITableGroupBy) => JSX.Element;
    RenderItem?: (props: ITableCustomItem) => JSX.Element;
    RenderOptions?: (props: ITableOptions) => JSX.Element;
    rowSelection?: ITableData[];
    rowSelectionLabel?: string;
    isSearchResult?: boolean;
    searchInput?: string;
    testId?: string;
    headerBorder?: boolean;
}

declare interface ITableWithGoup extends AdvanceTableProps {
    groupedRow: true;
    data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[];
    groupByLevel?: false | undefined;
}

declare interface ITableWithouthGroup extends AdvanceTableProps {
    groupedRow?: false | undefined;
    data: ITableData[];
    groupByLevel?: false | undefined;
}
declare interface ITableWithGroupLevel extends AdvanceTableProps {
    data: ITableDataWithGroupByLevel[];
    groupedRow?: false | undefined;
    groupByLevel: true;
}
export const AdvanceTable: FunctionComponent<ITableWithGoup | ITableWithouthGroup | ITableWithGroupLevel> = ({
    actionsLabel,
    activeAccordion,
    activeDisabledRows,
    columns,
    data,
    disabledRows,
    groupByLabel,
    handleSelectHeaderCheck,
    hasHeaderCheckbox,
    headerCheckbox,
    headerCheckboxDisabled,
    headerCheckboxInter,
    onEmptyState,
    onRowSelect,
    RenderAccordion,
    RenderGroupByLabel,
    RenderItem,
    RenderOptions,
    rowSelection,
    rowSelectionLabel,
    isSearchResult,
    searchInput,
    testId,
    groupedRow,
    groupByLevel,
    headerBorder,
}: ITableWithGoup | ITableWithouthGroup | ITableWithGroupLevel) => {
    const icon: ITableIcon = { ...onEmptyState.icon };
    /**
     *
     * @param row - Data with group row info
     */
    const GroupedTableRow = (
        rows: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData | ITableData)[],
        startIndex?: string,
    ): JSX.Element => {
        return (
            <>
                {rows.map((row, index) => {
                    const _startIndex = startIndex ? startIndex + '.' + (index + 1) : `${index + 1}`;
                    return (
                        <>
                            {row.mainGrouping}
                            {row.subGrouping
                                ? GroupedTableRow(row.subGrouping, _startIndex)
                                : row.data.map((groupRow: ITableData, inx: number) => {
                                      return (
                                          <AdvanceTableRow
                                              key={_startIndex + '.' + (inx + 1)}
                                              activeAccordion={activeAccordion}
                                              activeDisabledRows={activeDisabledRows}
                                              columns={columns}
                                              RenderAccordion={RenderAccordion}
                                              index={_startIndex + '.' + (inx + 1)}
                                              item={groupRow}
                                              groupByLabel={groupByLabel}
                                              onRowSelect={onRowSelect}
                                              RenderOptions={RenderOptions}
                                              RenderItem={RenderItem}
                                              RenderGroupByLabel={RenderGroupByLabel}
                                              rowSelection={rowSelection}
                                              disabledRows={disabledRows}
                                              data-testid="advance-table"
                                              rowId={`${index + 1}`}
                                              testId={testId}
                                          />
                                      );
                                  })}
                        </>
                    );
                })}
            </>
        );
    };

    const GroupByLevel = (data: ITableDataWithGroupByLevel[]): JSX.Element => {
        return (
            <>
                {/* eslint-disable-next-line react/prop-types */}
                {data.map((gropedRows: ITableDataWithGroupByLevel, i: number) => {
                    return (
                        <Fragment key={i}>
                            {gropedRows.level && <GroupLevel>{gropedRows.level}</GroupLevel>}
                            {gropedRows.data.map((row, index) => {
                                return (
                                    <AdvanceTableRow
                                        key={index}
                                        activeAccordion={activeAccordion}
                                        activeDisabledRows={activeDisabledRows}
                                        columns={columns}
                                        RenderAccordion={RenderAccordion}
                                        index={index.toString()}
                                        item={row}
                                        groupByLabel={groupByLabel}
                                        onRowSelect={onRowSelect}
                                        RenderOptions={RenderOptions}
                                        RenderItem={RenderItem}
                                        RenderGroupByLabel={RenderGroupByLabel}
                                        rowSelection={rowSelection}
                                        disabledRows={disabledRows}
                                        data-testid="advance-table"
                                        rowId={`${index + 1}`}
                                        testId={testId}
                                    />
                                );
                            })}
                        </Fragment>
                    );
                })}
            </>
        );
    };
    const generateTableRows = (): JSX.Element | JSX.Element[] => {
        if (data.length === 0) {
            if (isSearchResult === true) {
                return (
                    <EmptyState
                        iconName={'search'}
                        title={onEmptyState.title}
                        searchInput={searchInput}
                        subtitle={onEmptyState.helperText}
                    />
                );
            } else {
                return (
                    <EmptyTable>
                        <IcoMoon {...icon} />
                        <CustomSpacer space={'2.5rem'} />
                        <TextNavyBlue weight="700">{onEmptyState.title}</TextNavyBlue>
                        <Fs12RegSecNavyBlue>{onEmptyState.helperText}</Fs12RegSecNavyBlue>
                        <CustomSpacer space={'1rem'} />
                        <Fs12RegSecNavyBlue>{onEmptyState.secondaryText}</Fs12RegSecNavyBlue>
                    </EmptyTable>
                );
            }
        } else if (!groupedRow && !groupByLevel) {
            const _array: JSX.Element[] = [];
            data.forEach((row, index: number) => {
                _array.push(
                    <AdvanceTableRow
                        key={index}
                        activeAccordion={activeAccordion}
                        activeDisabledRows={activeDisabledRows}
                        columns={columns}
                        RenderAccordion={RenderAccordion}
                        index={index.toString()}
                        item={row}
                        groupByLabel={groupByLabel}
                        onRowSelect={onRowSelect}
                        RenderOptions={RenderOptions}
                        RenderItem={RenderItem}
                        RenderGroupByLabel={RenderGroupByLabel}
                        rowSelection={rowSelection}
                        disabledRows={disabledRows}
                        data-testid="advance-table"
                        rowId={`${index + 1}`}
                        testId={testId}
                    />,
                );
            });
            return _array;
        } else if (groupedRow) {
            const x = GroupedTableRow(data, '');
            return x;
        } else {
            const row = GroupByLevel(data);
            return row;
        }
    };
    return (
        <Fragment>
            {data.length !== 0 ? (
                <TableHeader
                    actionsLabel={actionsLabel}
                    columns={columns}
                    rowSelectionLabel={rowSelectionLabel}
                    withActions={RenderOptions !== undefined ? true : false}
                    data={data}
                    hasHeaderCheckbox={hasHeaderCheckbox}
                    headerCheckbox={headerCheckbox}
                    headerCheckboxDisabled={headerCheckboxDisabled}
                    headerCheckboxInter={headerCheckboxInter}
                    handleSelectHeaderCheck={handleSelectHeaderCheck}
                    testId={testId}
                    headerBorder={headerBorder}
                />
            ) : null}

            <AdvanceTableBody>{generateTableRows()}</AdvanceTableBody>
        </Fragment>
    );
};
const AdvanceTableBody = styled.div`
    padding: 15px 1.5rem;
    min-height: 50vh;
    margin-bottom: 2rem;
`;

const EmptyTable = styled.div`
    display: flex;
    justify-content: center;
    height: 68vh;
    align-items: center;
    flex-direction: column;
`;
const GroupLevel = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    margin: 1.5% 0%;
`;
