import gql from 'graphql-tag';
/**
 * @allBranchQuery @query - HQ Dashboard
 * @description: TAB: All branches
 */

export const adviserDashboardExportListCreatedOn = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    adviserdashboard {
                        agentId
                        agentName
                        agentCode
                        bdmName
                        bdmCode
                        uplineName
                        uplineCode
                        status
                        createdOn
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
