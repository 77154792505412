import gql from 'graphql-tag';

/**
 * @createUser @mutation System admin Dashboard
 * @description mutation for creating new user
 */

export const createGroup = gql`
    mutation CreateGroup($input: GroupInput) {
        createGroup(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default createGroup;
