import gql from 'graphql-tag';

export const allOtherTabsQuery = gql`
    query allSubmittedDashboard($input: BranchDashboardInput) {
        allSubmittedDashboard(input: $input) {
            data {
                result {
                    filters {
                        transactionType
                        fundType
                        paymentMethod
                        accountType
                        branchStatus
                    }
                    transactions {
                        requestId
                        isSeen
                        orderNo
                        transactionRef
                        accountType
                        aging
                        clientName
                        clientIdNum
                        transactionType
                        utmc
                        fundType
                        paymentMethod
                        totalInvestment {
                            fundCurrency
                            investmentAmount
                        }
                        agentName
                        agentCode
                        lastUpdated
                        submittedOn
                        createdOn
                        status
                        remarks {
                            title
                            content
                        }
                        targetDate
                        cutOffTime
                        requestedBy
                        extendTargetDate
                        extendRemarks {
                            title
                            content
                        }
                    }
                    dailyCount
                    upcomingCount
                    hardcopyCount
                    rerouteCount
                    historyCount
                    page
                    pages
                    transactionsCount
                    changeRequestsCount
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
