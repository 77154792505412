/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, ChangeEvent, useContext, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import { HistoryEDD } from '.';
import { SearchBar, Dashboard } from '../../../components/organisms';
import {
    CustomSpacer,
    CustomButton,
    FlexedDiv,
    ComponentLoader,
    SelectDropdown,
    DatePicker,
} from '../../../components';
import { Fs12BoldPrimaryBlack, LABEL, EDD, SearchOptionsEddDashboard } from '../../../constants';
import Rerouted from './Rerouted/Rerouted';
import New from './New/New';

import moment from 'moment';
import { useDebounce } from '../../../customHooks';
import * as Routes from '../../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import eddDashboard from '../../../context/EDDContext/EDDContext';
import { API, graphqlOperation } from 'aws-amplify';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import AuthContext from '../../../context/AuthContext';
import { IEddManagement } from '../../../utils/permissionTypes';
import { hasKey } from '../../SystemAdmin/RoleSettings/utils/functions';
import { clearContextOnNavigation, updateIsSeenDashboard } from '../../../utils';
import { selectedTabEdd } from '../Helpers/selectedTabEdd';
import { exportList } from '../../../utils';
import { eddDashboardExportListCreatedOn } from '../../../_graphql/queries/edd/eddDashboard/exportList/exportListEddDashboardCreatedOn';
import { dateFilterBranch } from '../../DashboardBranch/dashboardBranchTypes';
import MultiSelectV2 from '../../../components/molecules/MultiSelect';

const _initialFilterInputEdd: ISort_FilterInput = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];
const _initialDateFilterEdd: dateFilterBranch = {
    dateSort: 'createdOn',
    range: '',
};
interface IDashboardEddPageProps {
    currentTab?: string;
}
export const DashboardEDD: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: IEddManagement = parsedPermission.hq.permission.eddCase;
    const { newTab, reRoutedTab, historyTab } = parsedPermissionPrivilege;
    const {
        page,
        setPage,
        pageMax,
        tabsCount,
        resultLimit,
        setResultLimit,
        setInvestorsResultLimit,
        searchInput,
        setSearchInput,
        getDropdownData,
        getEddDashboardHistory,
        getEddDashboardNew,
        getEddDashboardRerouted,
        loading,
        sortInput,
        setSortInput,
        filterInput,
        setFilterInput,
        dateFilter,
        setDateFilter,
        setTempTarget,
        statusFilter,
        setStatusFilter,
        tempFilters,
        setTempFilters,
        setSearchInvestorInput,
        eddCaseStatusGroup,
        disableResultLimit,
        newCases,
        reroutedCases,
        closedCases,
    } = useContext(eddDashboard);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //Location Variable
    const locationState = useLocation<IDashboardEddPageProps>();
    const [tab, setTab] = useState<number>(0);
    const [expand, setExpand] = useState<boolean>(false);
    const [lastUpdated, setLastUpdated] = useState<string>('Created On'); //Header dropdown states
    const [dateSortingitems, setDateSortingitems] = useState<ISelectOption[]>([]);
    const debouncedSearchTerm = useDebounce(searchInput, 700);
    const [downloadDelay, setDownloadDelay] = useState<boolean>(false);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    const history = useHistory();

    // Refs for controlling initial Render of hooks
    const initialRenderTabs = useRef(true);
    const initialRenderFilters = useRef(true);
    const sameFilter = JSON.stringify(_initialFilterInputEdd) !== JSON.stringify(filterInput);

    const tabs: IDashboardTabs[] = [];
    // Temp state to handle date range picker's to and from states
    const [dateTo, setDateTo] = useState<moment.Moment | null>(null);
    const [dateFrom, setDateFrom] = useState<moment.Moment | null>(null);
    const [targetDateRange, setTargetDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);

    if (newTab.isAll === 'true' || newTab.actions.canViewNewCasesList === LABEL.maker) {
        tabs.push({
            name: 'New',
            table: (
                <New
                    isSearchResult={searchInput.value.length !== 0}
                    searchInput={searchInput.value}
                    isFilterApplied={sameFilter}
                    newTab={newTab}
                    lastUpdatedDropdown={lastUpdated}
                    setLastUpdatedDropdown={setLastUpdated}
                    setSortInput={setSortInput}
                    sortInput={sortInput}
                    filters={filterInput}
                    setFilters={setFilterInput}
                />
            ),
            testId: 'new-tab',
        });
    }
    if (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewReRoutedCasesList === LABEL.maker) {
        tabs.push({
            name: 'Rerouted',
            table: (
                <Rerouted
                    searchInput={searchInput.value}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={sameFilter}
                    reRoutedTab={reRoutedTab}
                    lastUpdatedDropdown={lastUpdated}
                    setLastUpdatedDropdown={setLastUpdated}
                    setSortInput={setSortInput}
                    sortInput={sortInput}
                    filters={filterInput}
                    setFilters={setFilterInput}
                    setDownloadDelay={setDownloadDelay}
                />
            ),
            testId: 'rerouted-tab',
        });
    }
    if (historyTab.isAll === 'true' || historyTab.actions.canViewDetails === LABEL.maker) {
        tabs.push({
            name: 'History',
            table: (
                <HistoryEDD
                    searchInput={searchInput.value}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={sameFilter}
                    historyTab={historyTab}
                    lastUpdatedDropdown={lastUpdated}
                    setLastUpdatedDropdown={setLastUpdated}
                    setSortInput={setSortInput}
                    sortInput={sortInput}
                    filters={filterInput}
                    setFilters={setFilterInput}
                    setDownloadDelay={setDownloadDelay}
                />
            ),
            testId: 'history-tab',
        });
    }

    // Search Secondary Actions
    const handleSearchActions = (action: IActionButton) => {
        switch (action.title) {
            case 'Add New Case': {
                setInvestorsResultLimit(12);
                setSearchInvestorInput({ value: '', column: 'all' });
                history.push(Routes.investorsList);
                break;
            }
            case 'Download EDD Status Report': {
                history.push(Routes.downloadMonthlyReport, { currentTab: tabs[tab].name });
                break;
            }
            case 'Export List': {
                getExportList();
                break;
            }
        }
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };
    const handleNext = () => {
        if (page < pageMax) {
            setPage(page + 1);
        }
    };
    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };
    const handleResultLimit = (limit: number) => {
        setResultLimit(limit);
    };

    const getTab = (tabNumber: number) => {
        if (tabs.length < 1) return 0;
        const index = tabs[tabNumber].name === 'New' ? 0 : tabs[tabNumber].name === 'Rerouted' ? 1 : 2;
        return index;
    };

    const searchPlaceholder: string[] = [
        LABEL.searchPlaceholderAmlaEddNewCases,
        LABEL.searchPlaceholderAmlaEddReroutedCases,
        LABEL.searchPlaceholderAmlaEddHistoryCases,
    ];
    //function to set primary and secondary button permissions for each tab
    const handleSearchbarButtons = () => {
        const _tempSecondary: IActionButton[] = [];
        let section = 'newTab';
        if (parsedPermissionPrivilege.newTab.actions.canDownloadEddStatusReport === LABEL.maker)
            _tempSecondary.push({
                type: 'secondary',
                title: 'Download EDD Status Report',
                actionIcon: 'download',
                width: '256px',
                testId: 'download-edd-status-report-btn',
            });
        switch (tabs[tab].name) {
            case 'New':
                section = 'newTab';
                break;
            case 'Rerouted':
                section = 'reRoutedTab';
                break;
            case 'History':
                section = 'historyTab';
                break;
        }
        const currentSection: any = hasKey(parsedPermissionPrivilege, section)
            ? parsedPermissionPrivilege[section]
            : parsedPermissionPrivilege.newTab;
        currentSection.actions.canExportList === LABEL.maker
            ? _tempSecondary.push({
                  type: 'secondary',
                  title: 'Export List',
                  actionIcon: 'export',
                  disabled: tabsCount[tab] === 0 || disableExportButton(),
                  testId: 'export-list-btn',
              })
            : null;
        return _tempSecondary;
    };

    const disableExportButton = () => {
        let disable = false;
        switch (tabs[tab].name) {
            case 'New':
                disable = !newCases.length;
                break;
            case 'Rerouted':
                disable = !reroutedCases.length;
                break;
            case 'History':
                disable = !closedCases.length;
                break;
        }
        return disable;
    };
    /**@function Fetch data based  tab given @param tab number */
    const fetchData = (_tab: string) => {
        switch (_tab) {
            case 'New':
                getEddDashboardNew();
                break;
            case 'Rerouted':
                getEddDashboardRerouted();
                break;
            case 'History':
                getEddDashboardHistory();
                break;
        }
    };

    const handleStatusFilter = (selectedStatus: React.ReactText[]) => {
        setStatusFilter(selectedStatus);
        const temp = [...tempFilters];
        const newFilter = temp.filter((item) => item.column !== 'status');
        selectedStatus.map((item) => {
            newFilter.push({ column: 'status', value: item as string });
        });
        setTempFilters(newFilter);
    };
    useEffect(() => {
        const _dateSortingitems = [];
        if (initialRenderTabs.current) {
            if (locationState.state?.currentTab !== undefined) {
                const name = locationState.state.currentTab;
                tabs.map((ele, index) => {
                    ele.name === name ? setTab(index) : null;
                });
            }
            fetchData(tabs[tab].name);
            if (tabs[tab].name.toLowerCase() !== 'history') {
                _dateSortingitems.push(
                    { label: LABEL.createdOn, value: 'createdOn', testId: 'created-on-option' },
                    {
                        label: LABEL.lastUpdated,
                        value: 'lastUpdated',
                        testId: 'last-updated-option',
                    },
                );
            } else {
                _dateSortingitems.push(
                    { label: LABEL.createdOn, value: 'createdOn', testId: 'created-on-option' },
                    { label: LABEL.closedOn, value: 'lastUpdated', testId: 'closed-on-option' },
                );
            }
            setDateSortingitems(_dateSortingitems);
            initialRenderTabs.current = false;
        } else {
            setPage(1);
            setResultLimit(10);
            setSearchInput({ value: '', column: 'all' });
            setStatusFilter([]);
            setTempTarget([null, null]);
            setFilterInput(_initialFilterInputEdd);
            setTempFilters(_initialFilterInputEdd);
            setDateFilter(_initialDateFilterEdd);
            tabs[tab].name.toLowerCase() !== 'history'
                ? setSortInput([{ column: 'createdOn', value: 'descending' }])
                : setSortInput([{ column: 'lastUpdated', value: 'descending' }]);
            // to reset search date filter options on tab change
            if (tabs[tab].name.toLowerCase() !== 'history') {
                setLastUpdated('Created On');
                _dateSortingitems.push(
                    { label: LABEL.createdOn, value: 'createdOn', testId: 'created-on-option' },
                    {
                        label: LABEL.lastUpdated,
                        value: 'lastUpdated',
                        testId: 'last-updated-option',
                    },
                );
            } else {
                setLastUpdated('Closed On');
                _dateSortingitems.push(
                    { label: LABEL.createdOn, value: 'createdOn', testId: 'created-on-option' },
                    { label: LABEL.closedOn, value: 'lastUpdated', testId: 'closed-on-option' },
                );
                setDateFilter({ ...dateFilter, dateSort: 'lastUpdated' });
            }
            setDateSortingitems(_dateSortingitems);
        }
        return function cleanup() {
            history.location.pathname !== '/'
                ? updateIsSeenDashboard('edddashboard', [selectedTabEdd(tabs[tab].name)], idTokenHeader)
                : null;
            clearContextOnNavigation('eddDashboard', history.location.pathname) === false ? clearContext() : null;
        };
    }, [tab]);

    useEffect(() => {
        if (initialRenderFilters.current) {
            initialRenderFilters.current = false;
        } else {
            fetchData(tabs[tab].name);
        }
    }, [debouncedSearchTerm, page, resultLimit, sortInput, filterInput]);

    useEffect(() => {
        switch (tabs[tab].name.toLowerCase()) {
            case 'new':
                getDropdownData('new');
                break;
            case 'rerouted':
                getDropdownData('reroute');
                break;
            case 'history':
                getDropdownData('close');
                break;
        }
    }, [tab]);
    const clearContext = () => {
        setPage(1);
        setResultLimit(10);
        setSearchInput({ value: '', column: 'all' });
        setStatusFilter([]);
        setTempTarget([null, null]);
        setFilterInput(_initialFilterInputEdd);
        setTempFilters(_initialFilterInputEdd);
        setDateFilter(_initialDateFilterEdd);
        setSortInput([{ column: 'createdOn', value: 'descending' }]);
        const _dateSortingitems: ISelectOption[] = [];
        _dateSortingitems.push(
            { label: LABEL.createdOn, value: 'createdOn', testId: 'created-on-option' },
            {
                label: LABEL.lastUpdated,
                value: 'lastUpdated',
                testId: 'last-updated-option',
            },
        );
        setDateSortingitems(_dateSortingitems);
    };
    const getExportList = async () => {
        const query = eddDashboardExportListCreatedOn;
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        tab: selectedTabEdd(tabs[tab].name),
                        filter: filterInput,
                        search: searchInput,
                        sort: sortInput,
                        endpoint: 'edddashboard',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;
            exportList('Hq', tabs[tab].name, result.edddashboard);
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'edd-error-modal',
            });
        }
    };

    // Filters

    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');

            setDateFilter({ dateSort: dateFilter.dateSort, range: range });
            const obj: ISearchInput = {
                column: dateFilter.dateSort,
                value: range,
            };
            const tmp = [...tempFilters];
            tmp[0] = obj;

            setTempFilters(tmp);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };

    const handleDateSorting = (value: string | number) => {
        const tmp = [...tempFilters];
        setDateFilter({ ...dateFilter, dateSort: value.toString() });
        tmp[0].column = value.toString();
        setTempFilters(tmp);
    };

    const handleCancel = () => {
        const temp = _initialFilterInputEdd;
        const tempSort = [...sortInput];
        setStatusFilter([]);
        setTempTarget([null, null]);
        setDatePickerInvalidMessage('');
        if (tabs[tab].name.toLowerCase() !== 'history') {
            setLastUpdated(LABEL.createdOn);
            setDateFilter(_initialDateFilterEdd);
            tempSort[0].column === 'lastUpdated' ? (tempSort[0].column = 'createdOn') : null;
            tempSort[0].column === 'createdOn' ? setSortInput(tempSort) : null;
        } else {
            setLastUpdated(LABEL.closedOn);
            setDateFilter({ dateSort: 'lastUpdated', range: '' });
            tempSort[0].column === 'createdOn' ? (tempSort[0].column = 'lastUpdated') : null;
            tempSort[0].column === 'lastUpdated' ? setSortInput(tempSort) : null;
        }
        setTempFilters(temp);

        setFilterInput(temp);
        setExpand(false);
        setDateFrom(null);
        setDateTo(null);
    };
    const handleApply = () => {
        // console.log(tempFilters, 'tempFilters');
        setFilterInput(tempFilters);
        let currentFilterLabel = '';
        switch (tempFilters[0].column) {
            case 'lastUpdated':
                currentFilterLabel = tabs[tab].name.toLowerCase() !== 'history' ? LABEL.lastUpdated : LABEL.closedOn;
                break;
            case 'createdOn':
                currentFilterLabel = LABEL.createdOn;
                break;
        }
        setLastUpdated(currentFilterLabel);
        setSortInput([{ column: tempFilters[0].column, value: 'descending' }]);
        setExpand(false);
    };

    const isEmptyState = () => {
        switch (tabs[tab].name.toLowerCase()) {
            case 'new':
                return newCases.length === 0 ? true : false;
            case 'rerouted':
                return reroutedCases.length === 0 ? true : false;
            case 'history':
                return closedCases.length === 0 ? true : false;
        }
    };

    interface ErrorFallbackProps {
        error: string | any;
    }
    function ErrorFallback({ error }: ErrorFallbackProps) {
        return (
            <div role="alert">
                <p>An Error/Something Occurred during runtime that caused the app to fail load successfully: </p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
            </div>
        );
    }
    // // Temp fn to handle date from value
    // const handleFromDate = (value: moment.Moment | null) => {
    //     setDateFrom(value);
    // };
    // // Temp fn to handle date to value
    // const handleToDate = (value: moment.Moment | null) => {
    //     setDateTo(value);
    // };
    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const dates = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetDateRange(dates);
    };
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsEddDashboard.new.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `edd-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };
    return (
        <Fragment>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <SearchBar
                    expand={expand}
                    setExpand={setExpand}
                    placeHolder={searchPlaceholder[getTab(tab)]}
                    searchTitle={LABEL.eddCases}
                    searchInput={searchInput}
                    dropDownOptions={searchOptions()}
                    onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                        setSearchInput({ ...searchInput, value: e.currentTarget.value });
                    }}
                    filter={true}
                    primaryButton={
                        tabs[tab].name === 'New' && newTab.actions.canAddNewCase === LABEL.maker
                            ? {
                                  type: 'primary',
                                  title: LABEL.addNewCase,
                                  actionIcon: 'plus',
                                  testId: 'add-new-case-btn',
                              }
                            : undefined
                    }
                    secondaryButton={handleSearchbarButtons()}
                    handlePrimary={handleSearchActions}
                    handleSecondary={handleSearchActions}
                    testId="edd-searchbar"
                    disabled={isEmptyState() && !sameFilter && searchInput.value === ''}
                    clearSearchInput={() => {
                        setSearchInput({ ...searchInput, value: '' });
                    }}
                >
                    <SearchInner>
                        <SearchInnerTitle>{EDD.LABEL_EDD_FILTER_TITLE}</SearchInnerTitle>
                        <FlexedDiv direction="row" style={{ paddingBottom: '1.6rem' }}>
                            <InputWrap>
                                <SelectDropdown
                                    onChange={handleDateSorting}
                                    options={dateSortingitems}
                                    testId={`date-dropdown-filter`}
                                    selectedValue={dateFilter.dateSort}
                                    placeHolder="Select one or more"
                                    label="Date"
                                />
                            </InputWrap>
                            <InputWrap>
                                <DatePicker
                                    targetDate={targetDateRange}
                                    setTargetDate={handleTargetDate}
                                    label="Date Range"
                                    range={true}
                                    width="360"
                                    primaryBorderColor="#002043"
                                    handleDate={handleDate}
                                    errorMessage={datePickerInvalidMessage}
                                    setErrorMessage={setDatePickerInvalidMessage}
                                />
                            </InputWrap>
                        </FlexedDiv>
                        <FlexedDiv direction="row" style={{ borderTop: '1px dashed #CECECE', paddingTop: '1.6rem' }}>
                            <InputWrap>
                                <MultiSelectV2
                                    label={LABEL.status}
                                    options={eddCaseStatusGroup}
                                    placeHolder={LABEL.selectOneOrMore}
                                    checkedState={statusFilter}
                                    handleMultiSelect={(item) => handleStatusFilter(item)}
                                    selectId={1}
                                    hasSelectAll
                                    noOverlay={false}
                                    style={{ maxWidth: '360px' }}
                                    disabled={eddCaseStatusGroup.length === 0}
                                />
                            </InputWrap>
                        </FlexedDiv>
                    </SearchInner>
                    <FlexedDiv direction="column">
                        <FlexedDiv direction="column"></FlexedDiv>
                        <FlexedDiv style={{ margin: '1.6rem 0rem' }} justifyContent="center">
                            <CustomButton onClick={handleCancel} style={{ width: '16.67vw' }}>
                                <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                primary={true}
                                onClick={handleApply}
                                style={{ width: '16.67vw', padding: '14px 0' }}
                            >
                                {LABEL.apply}
                            </CustomButton>
                        </FlexedDiv>
                    </FlexedDiv>
                </SearchBar>
                <TabWrapper>
                    <Dashboard
                        tabs={tabs}
                        page={page}
                        maxPage={pageMax}
                        selectedTab={tab}
                        orderCounter={tabsCount}
                        switchTabs={switchTabs}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        resultLimit={resultLimit}
                        setResultLimit={(limit) => handleResultLimit(limit)}
                        setCurrentPage={setPage}
                        disableResultLimit={disableResultLimit}
                    />
                    {loading ? <ComponentLoader /> : null}
                    {downloadDelay ? <ComponentLoader /> : null}
                </TabWrapper>
            </ErrorBoundary>
        </Fragment>
    );
};

const TabWrapper = styled.div`
    position: relative;
`;
const SearchInner = styled.div``;
const SearchInnerTitle = styled.h2`
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;

export default DashboardEDD;
