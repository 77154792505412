/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Fragment, useEffect, useState, FunctionComponent, ReactText, ChangeEvent, useContext } from 'react';
import {
    SearchBar,
    Dashboard,
    FlexedDiv,
    CustomSpacer,
    CustomButton,
    ComponentLoader,
    DatePicker,
    SelectDropdown,
} from '../../../components';
import { LABEL, SYSTEM_ADMIN, Fs12BoldPrimaryBlack, TextPureBlack } from '../../../constants';
import { Advisers } from './Advisers/AdviserAccess';
import { API, graphqlOperation } from 'aws-amplify';
import { IColumnValue } from '../../Advisers';
import { AdviserAccessExportList } from '../../../_graphql/queries/systemadmin/adviserExportList';
import { exportList } from '../../../utils';
import { dateSortingDropDownAdvisers } from '../../Advisers/filterData';
import { SearchOptionsAdvisers } from './searchDropdown';
import { useDebounce } from '../../../customHooks/useDebounce';

import styled from 'styled-components';
import moment from 'moment';
import SystemAdminContext from '../../../context/SystemAdminContext/SAContext';
import AdviserAccessContext from '../../../context/SystemAdminContext/adviserAccess';
import MultiSelect from '../../../components/molecules/MultiSelect/MultiSelect';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

const initialAdviserFilters = [
    { column: 'createdOn', value: '' },
    { column: 'branch', value: '' },
    { column: 'channel', value: '' },
    { column: 'agency', value: '' },
    { column: 'status', value: '' },
    { column: 'omni', value: 'yes' },
    { column: 'omni', value: 'no' },
];

declare interface IDropdownWithId {
    id: string;
    value: string;
}
const statusOptions: Array<ISelectOption> = [
    { value: '', label: LABEL.all },
    { value: 'active', label: LABEL.active },
    { value: 'suspended', label: LABEL.suspended },
    { value: 'terminated', label: LABEL.terminated },
];
const omniOptions: Array<ISelectOption> = [
    { value: '', label: LABEL.both },
    { value: 'yes', label: LABEL.enabled },
    { value: 'no', label: LABEL.disabled },
];

export const AdviserAccessDashboard: FunctionComponent = (): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { branches, agencyList, channelList, getDropdown } = useContext(SystemAdminContext);
    const {
        tab,
        resultLimit,
        currentPage,
        maxPages,
        searchInput,
        sortData,
        filters,
        counter,
        shouldUpdate,
        setTab,
        setCurrentPage,
        setResultLimit,
        setSearchInput,
        setLabel,
        setFilters,
        getAllAdvisers,
        loadingHandler,
        disableResultLimit,
        loading,
    } = useContext(AdviserAccessContext);
    const [expand, setExpand] = useState<boolean>(false);
    const [homeBranch, setHomeBranch] = useState<ReactText[]>([]);
    const [channel, setChannel] = useState<ReactText[]>([]);
    const [agency, setAgency] = useState<ReactText[]>([]);
    const [omni, setOmni] = useState<string>();
    const [tempFilters, setTempFilters] = useState<IColumnValue[]>(initialAdviserFilters);
    const [status, setStatus] = useState<string>('');
    const [dateSorting, setDateSorting] = useState<string>('createdOn');
    const [targetDateRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>(''); // datepicker error messages state

    //Custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleReset = () => {
        setDateSorting('createdOn');
        setHomeBranch([]);
        setChannel([]);
        setAgency([]);
        setStatus('');
        setOmni('');
        setTempFilters(initialAdviserFilters);
    };

    const handleApply = () => {
        setExpand(false);
        setFilters(tempFilters);
    };

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const update = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetRange(update);
        const temp = [...tempFilters];
        temp[0] = {
            column: tempFilters[0].column,
            value: `${moment(update[0]).valueOf()}~${moment(update[1]).valueOf()}`,
        };

        setTempFilters(temp);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            const obj: ISearchInput = {
                column: dateSorting,
                value: range,
            };
            const tmp = [...tempFilters];
            tmp[0] = obj;
            setTempFilters(tmp);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };
    const handleDateSorting = (value: string | number) => {
        const tmp = [...tempFilters];
        const obj: IColumnValue = {
            column: value!.toString(),
            value:
                moment(targetDateRange[0]!).format('DD/MM/YYYY') !== moment().format('DD/MM/YYYY') &&
                targetDateRange[0] !== null
                    ? `${moment(targetDateRange[0]).valueOf()}~${moment(targetDateRange[1]).valueOf()}`
                    : '',
        };

        setDateSorting(value!.toString());
        tmp[0] = obj;
        setTempFilters(tmp);
    };

    const handleHomeBranch = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item, index) => item.column !== 'branch' || index === 1);
        data.map((item) => {
            newFilter.push({ column: 'branch', value: item as string });
        });
        setHomeBranch(data);
        setTempFilters(newFilter);
    };

    const handleChannel = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item, index) => item.column !== 'channel' || index === 2);
        data.map((item) => {
            newFilter.push({ column: 'channel', value: item as string });
        });
        setChannel(data);
        setTempFilters(newFilter);
    };

    const handleAgency = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item, index) => item.column !== 'agency' || index === 2);
        data.map((item) => {
            newFilter.push({ column: 'agency', value: item as string });
        });
        setAgency(data);
        setTempFilters(newFilter);
    };

    const handleStatus = (text: string | number) => {
        setStatus(text!.toString());
        const temp = [...tempFilters];
        temp[4] = { column: 'status', value: text!.toString() };
        setTempFilters(temp);
    };

    const handleOmni = (value: string | number) => {
        setOmni(value!.toString());
        const tmp = [...tempFilters];
        const newFilter = tmp.filter((item) => item.column !== 'omni');
        if (value!.toString() === '') {
            newFilter.push({ column: 'omni', value: 'yes' });
            newFilter.push({ column: 'omni', value: 'no' });
        } else {
            newFilter.push({ column: 'omni', value: value!.toString() });
        }

        setTempFilters(newFilter);
    };

    const handleExport = () => {
        getExportList();
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };

    const getExportList = async () => {
        loadingHandler();

        try {
            const response: any = await API.graphql(
                graphqlOperation(AdviserAccessExportList, {
                    input: {
                        endpoint: 'systemadminadviserdashboard',
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            if (response.data.dashboardExportList.data !== null) {
                const { result } = response.data.dashboardExportList.data;

                const allAdvisers: ITableData[] = [];
                result.systemadminadviserdashboard.map((location: any) => {
                    location.advisers.map((adviser: ITableData) => {
                        const adviserObj = { ...adviser, branch: location.branch };
                        allAdvisers.push(adviserObj);
                    });
                });
                exportList('Hq', 'adviser', allAdvisers);
            } else {
                throw response.data.dashboardExportList.error;
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'adviseraccess-dashboard-error-modal',
            });
            handleErrorHandler();
        }
    };

    useEffect(() => {
        setLabel([]);
    }, [maxPages, counter, branches, currentPage]);

    useEffect(() => {
        getAllAdvisers();
    }, [currentPage, sortData, debouncedSearchTerm, resultLimit, shouldUpdate, filters]);

    const sameFilter = JSON.stringify(initialAdviserFilters) !== JSON.stringify(filters);
    const searchOrFiltered = sameFilter;

    const tabs: IDashboardTabs[] = [
        {
            name: SYSTEM_ADMIN.LABEL_ADVISERS,
            table: <Advisers searchEmpty={searchOrFiltered} />,
        },
    ];
    useEffect(() => {
        branches.length === 0 ? getDropdown() : null;
    }, []);
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsAdvisers.advisers.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `adviser-access-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };
    return (
        <Fragment>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={LABEL.searchPlaceholderAdviserAccess}
                searchTitle={SYSTEM_ADMIN.LABEL_ADVISERS}
                // selectDefaultValue={LABEL.all}
                dropDownOptions={searchOptions()}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                secondaryButton={[{ type: 'secondary', title: 'Export List', actionIcon: 'export' }]}
                handleSecondary={handleExport}
                searchInput={searchInput}
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                testId="adviser-access"
            >
                <TextPureBlack>{LABEL.filterAdvisersBy}</TextPureBlack>
                <CustomSpacer space={'0.5rem'} />
                <FlexedDiv direction="row">
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <Fs12BoldPrimaryBlack>{LABEL.dateSorting}</Fs12BoldPrimaryBlack>
                        <SelectWrapper>
                            <SelectDropdown
                                onChange={handleDateSorting}
                                options={dateSortingDropDownAdvisers}
                                testId={`date-sorting`}
                                selectedValue={filters[0] !== undefined ? tempFilters[0].column : 'lastUpdated'}
                            />
                        </SelectWrapper>
                    </FlexedDiv>
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack>{LABEL.dateRange}</Fs12BoldPrimaryBlack>

                        <InputWrap>
                            <DatePicker
                                setTargetDate={(date) => {
                                    handleTargetDate(date as [moment.Moment | null, moment.Moment | null]);
                                }}
                                targetDate={targetDateRange}
                                range={true}
                                width="360"
                                handleDate={handleDate}
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                            />
                        </InputWrap>
                    </FlexedDiv>
                </FlexedDiv>
                <FlexedDiv wrap="wrap">
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <MultiSelect
                            checkedState={homeBranch}
                            handleMultiSelect={handleHomeBranch}
                            hasSelectAll={true}
                            label={LABEL.homeBranch}
                            noOverlay={false}
                            options={branches}
                            placeHolder={LABEL.select}
                        />
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <MultiSelect
                            checkedState={channel}
                            handleMultiSelect={handleChannel}
                            hasSelectAll={true}
                            label={LABEL.adviserChannel}
                            noOverlay={false}
                            options={channelList}
                            placeHolder={LABEL.select}
                        />
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <MultiSelect
                            checkedState={agency}
                            handleMultiSelect={handleAgency}
                            hasSelectAll={true}
                            label={LABEL.agency}
                            noOverlay={false}
                            options={agencyList}
                            placeHolder={LABEL.select}
                        />
                    </FlexedDiv>
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack>{LABEL.agentStatus}</Fs12BoldPrimaryBlack>
                        <SelectWrapper>
                            <SelectDropdown
                                onChange={handleStatus}
                                options={statusOptions}
                                testId={`status-sorting`}
                                selectedValue={status}
                            />
                        </SelectWrapper>
                    </FlexedDiv>
                </FlexedDiv>
                <CustomSpacer space={'1.5rem'} />
                <FlexedDiv>
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack>{LABEL.omni}</Fs12BoldPrimaryBlack>
                        <SelectWrapper>
                            <SelectDropdown
                                onChange={handleOmni}
                                options={omniOptions}
                                testId={`omni-sorting`}
                                selectedValue={omni ? omni : ''}
                                placeHolder={LABEL.selectOne}
                            />
                        </SelectWrapper>
                    </FlexedDiv>
                </FlexedDiv>
                <FlexedDiv style={{ marginTop: '2rem' }} justifyContent="center">
                    <CustomButton onClick={handleReset} style={{ width: '16.67vw' }}>
                        <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                    </CustomButton>
                    <CustomSpacer horizontal={true} space={'1rem'} />
                    <CustomButton primary={true} onClick={handleApply} style={{ width: '16.67vw', padding: '14px 0' }}>
                        {LABEL.apply}
                    </CustomButton>
                </FlexedDiv>
            </SearchBar>
            <TabWrapper>
                <Dashboard
                    tabs={tabs}
                    selectedTab={tab}
                    orderCounter={counter}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    page={currentPage}
                    maxPage={maxPages}
                    resultLimit={resultLimit}
                    setResultLimit={setResultLimit}
                    setCurrentPage={setCurrentPage}
                    disableResultLimit={disableResultLimit}
                />

                {loading ? <ComponentLoader /> : null}
            </TabWrapper>
        </Fragment>
    );
};

const TabWrapper = styled.div`
    position: relative;
`;
const InputWrap = styled.div`
    width: 18.33vw;
    min-width: 18.33vw;
    margin-right: 1rem;
    height: 2.5rem;
    margin-bottom: 3rem;
`;
const SelectWrapper = styled.div`
    min-width: 250px;
    max-width: 600px;
    width: 18.33vw;
`;
