import gql from 'graphql-tag';

/**
 * @createRole @mutation - createRole Dashboard
 * @description: Creates the user role
 */

export const editRoleMutation = /* GraphQL */ gql`
    mutation editRole($input: EditRoleInput) {
        editRole(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;
