/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL, SYSTEM_ADMIN, Fs15BoldNeutralWhite, TextDarkGrey6 } from '../../../constants';
import { IcoMoonSelection } from '../../../icons';
import { CustomSpacer, FlexedDiv, CustomButton, TextArea, Modal, DatePicker } from '../../../components';
import { targetDateRequestQuery } from '../../../_graphql/queries/branchCE/extendTargetDate/extendTargetDate';
import { ICeDashboard, IDueDateTab } from '../../../utils/permissionTypes';

import * as Routes from '../../../routes';
import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

export interface LocationState {
    item: ITableData;
    hq: boolean;
}

export type Page = 'approve' | 'reject';

export const dateFormat = 'DD/MM/YYYY';

const modalMessages = {
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};
export const ExtendTargetDateBranch: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [showModal, setShowModal] = useState<boolean>(false);

    const [targetDate, setTargetDate] = useState<IExtendTargetDate>({
        date: [moment().add(1, 'day'), null],
        remarks: '',
    });
    const [initialDate, setInitialDate] = useState<moment.Moment | undefined>(moment());
    const history = useHistory();
    const location = useLocation();
    const locationState: any = location.state;
    const tableData = locationState.item;
    // get permission object from session storage

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        locationState.hq !== true
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;

    const dueDateTab: IDueDateTab | undefined =
        locationState.hq === true ? parsedPermissionPrivilege.dueDateTab : undefined;

    const disabled = targetDate.remarks?.trim().length === 0;

    const handleRemarks = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTargetDate({ ...targetDate, remarks: e.target.value });
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        date[0] !== null ? setTargetDate({ ...targetDate, date: date }) : null;
    };

    const handleSubmit = async () => {
        const _targetDate = targetDate.date && moment(targetDate.date[0]).valueOf();

        initFeedBackModal();

        try {
            const response: any = await API.graphql(
                graphqlOperation(targetDateRequestQuery, {
                    input: {
                        transactionRef: tableData.transactionRef,
                        targetDate: _targetDate,
                        remark: targetDate.remarks,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.targetDateRequest.data !== null) {
                setShowModal(true);
            } else throw response.data.targetDateRequest.error;
        } catch (error) {
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Submit Due Date Extension Request',
                testId: 'extendtargetdate-error-modal',
            });
        }
    };

    const handleCancel = () => {
        if (locationState.hq === true) {
            history.push(Routes.viewBranch, { currentTab: 'reroute' });
        } else {
            history.push(Routes.dashboardBranch, { currentTab: 'reroute' });
        }
    };
    const handleBack = () => {
        if (locationState.hq === true) {
            history.push(Routes.viewBranch, { currentTab: 'reroute' });
        } else {
            history.push(Routes.dashboardBranch, { currentTab: 'reroute' });
        }
    };
    const handleDone = () => {
        setShowModal(false);
        if (locationState.hq === true) {
            history.push(Routes.viewBranch);
        } else {
            history.push(Routes.dashboardBranch);
        }
    };
    // Fn to disable certain dates weekends
    const handleDisabledDate = (current: moment.Moment): boolean => {
        let _disbale = false;

        _disbale =
            current &&
            (current < moment(moment(initialDate), 'DD/MM/YYYY') || current < moment(moment(), 'DD/MM/YYYY'));

        return _disbale;
    };

    const initFeedBackModal = () => {
        modalMessages.feedback.heading =
            locationState.hq === true
                ? dueDateTab && dueDateTab.reviewApproval.canApproveDueDateExtension === 'auto-authorizer'
                    ? LABEL.dueDateUpdatedTitle
                    : LABEL.dueDateRequestTitle
                : LABEL.dueDateRequestTitle;

        modalMessages.feedback.content = (
            <span>
                {locationState.hq === true
                    ? dueDateTab && dueDateTab.reviewApproval.canApproveDueDateExtension === 'auto-authorizer'
                        ? `${LABEL.dueDateUpdateSubtitle1} ${
                              targetDate.date && moment(targetDate.date[0]).format('DD/MM/YYYY')
                          } ${LABEL.dueDateUpdateSubtitle2}`
                        : LABEL.dueDateRequestSubtitle
                    : LABEL.dueDateRequestSubtitle}
            </span>
        );

        modalMessages.feedback.icon =
            locationState.hq === true && dueDateTab?.reviewApproval.canApproveDueDateExtension === 'auto-authorizer'
                ? 'targetdate-modal'
                : 'target-date-request';
    };

    useEffect(() => {
        const locationState: LocationState = location.state as LocationState;
        const tableData = locationState.item;
        const dateInt = parseInt(tableData.targetDate, 10);
        if (dateInt > moment().valueOf()) {
            setTargetDate({ ...targetDate, date: [moment(dateInt), null] });
            setInitialDate(moment(dateInt));
        } else {
            const _today = moment().valueOf();
            setTargetDate({ ...targetDate, date: [moment(_today), null] });
            setInitialDate(moment(_today));
        }
    }, []);

    return (
        <Fragment>
            <Header>
                <Icon onClick={handleBack}>
                    <IcomoonReact icon="arrow-left" size="1.5rem" iconSet={IcoMoonSelection} />
                </Icon>
                <CustomSpacer horizontal={true} space={'1.25rem'} />
                <TextDarkGrey6 size="24px" weight="700">
                    {LABEL.extendDueDateRequest}
                </TextDarkGrey6>
            </Header>
            <CustomSpacer space={'3.556vh'} />
            <Container>
                <InputWrapper>
                    <TextDarkGrey6 size="12px" weight="700">
                        {LABEL.newDueDate}
                    </TextDarkGrey6>

                    <DatePicker
                        disableCustomDates={handleDisabledDate}
                        disableWeekends={true}
                        targetDate={targetDate.date}
                        setTargetDate={handleDate}
                        iconSize="1.5rem"
                        primaryBorderColor="#CECECE"
                        backgroundColor="#F4F4F4"
                    />
                </InputWrapper>
                <CustomSpacer space={'2rem'} />
                <InputWrapper>
                    <TextDarkGrey6 size="12px" weight="700">
                        {LABEL.remarks}
                    </TextDarkGrey6>
                    <TextArea
                        handleInput={handleRemarks}
                        maxLength={255}
                        name="rejectRemark"
                        style={{ width: '38.89vw' }}
                        value={targetDate.remarks as string}
                        testId="branch-extendtargetdate-textarea"
                    />
                </InputWrapper>

                <FlexedDiv style={{ marginTop: '5rem' }}>
                    <CustomButton
                        primary={false}
                        onClick={handleCancel}
                        size="large"
                        style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                        id="branch-extendtargetdate-cancel-btn"
                    >
                        <TextDarkGrey6 size="15px" weight="700">
                            {SYSTEM_ADMIN.BUTTON_CANCEL}
                        </TextDarkGrey6>
                    </CustomButton>
                    <CustomSpacer horizontal={true} space={'1rem'} />
                    <CustomButton
                        disabled={disabled}
                        primary={true}
                        onClick={handleSubmit}
                        size="large"
                        style={{ width: '16.667vw' }}
                        id="branch-extendtargetdate-submit-btn"
                    >
                        <Fs15BoldNeutralWhite>{LABEL.submit}</Fs15BoldNeutralWhite>
                    </CustomButton>
                </FlexedDiv>
            </Container>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalMessages.feedback.heading}
                    primaryBtn={{
                        onClick: async () => {
                            handleDone();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    icon={modalMessages.feedback.icon}
                    testId="extendtargetdate-modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalMessages.feedback.content}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    width: 360px;
    max-width: 360px;
`;
const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Container = styled.div`
    padding-left: 2.5vw;
`;
