import gql from 'graphql-tag';
/**
 * @fundDocumentQuery @query - Upload Fund data
 * @description: Returns uploaded fund details
 */

const fundUploadDataQuery = gql`
    query fundUploadData($input: fundUploadDataInput) {
        fundUploadData(input: $input) {
            data {
                result {
                    message
                    data
                    user
                    transaction {
                        user
                        status
                        remark
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default fundUploadDataQuery;
