import gql from 'graphql-tag';

export const transactionReasonList = /* GraphQL */ gql`
    query transactionReasonList($input: TransactionReasonListInput) {
        transactionReasonList(input: $input) {
            data {
                result {
                    reasons {
                        reasonId
                        category
                        subCategory
                    }
                    targetDate
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
