/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, ReactText, ChangeEvent, useContext, useRef } from 'react';
import { SliderOverview, SearchBar, Dashboard, DatePicker, TrackOrderModal } from '../../components/organisms';
import { LABEL, TextPureBlack, Fs12BoldPrimaryBlack, Fs24RegSecNavyBlue, Fs24BoldSecNavyBlue } from '../../constants';
import { ErrorBoundary } from 'react-error-boundary';
import { API, graphqlOperation } from 'aws-amplify';
import { IColumnValue } from '../Advisers';
import { CustomSpacer, CustomButton, FlexedDiv, ComponentLoader, SelectDropdown } from '../../components';

import { dateSortingDropDown, accountTypes, ProductTypes, FundingOptions } from './filterData';
import { exportList, removeArrayElement, updateIsSeenDashboard } from '../../utils';
import { hqceDashboardBranchExportList } from '../../_graphql/queries/hqCE/exportList/hqceDashboard';
import { hqceDashboardExtensionExportListCreatedOn } from '../../_graphql/queries/hqCE/exportList/hqceExtensionCreatedOn';
import { hqceDashboardExtensionExportListLastUpdated } from '../../_graphql/queries/hqCE/exportList/hqceExtensionLastUpdated';
import { SearchOptionsHQCE } from './searchDropdown';
import { useDebounce } from '../../customHooks/useDebounce';
import { useHistory, useLocation } from 'react-router-dom';
import AllBranch from './AllBranches';
import TargetDateExtension from './TargetDateExtensions';
import moment from 'moment';
import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import HQCEContext from '../../context/HQCEContext/HQCEContext';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import * as ROUTES from '../../routes';
import { SearchBarFilterTags } from '../../templates';
import { getFilterTagLabels, removeSearchFilterTagFromFilter } from '../DashboardBranch/helpers/getFilterTagLabels';

const initialDueDateFilters = [
    { column: 'lastUpdated', value: '' },
    { column: 'transactionType', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
];

const initialBranchFilter = [
    {
        column: 'state',
        value: '',
    },
];

export interface IDropdownWithKey {
    value: string;
    keyName: string;
}

declare interface ILocationState {
    tab: string;
}

const TrackOrderButton: IActionButton = {
    title: 'Track Order',
    type: 'secondary',
    isDropdown: false,
    actionIcon: 'trackIcon',
    testId: 'track-order',
};

export const DashboardHQ: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    const {
        accountType,
        loadingHandler,
        loading,
        resultLimit,
        currentPage,
        filters,
        fundingOption,
        getAllBranchData,
        getDateExtensionData,
        lastUpdatedDropdown,
        maxPages,
        offices,
        orderCounter,
        overview,
        productTypes,
        searchInput,
        setAccountType,
        setCurrentPage,
        setFilters,
        setFundingOption,
        setLastUpdatedDropdown,
        setProductTypes,
        setResultLimit,
        setSearchInput,
        setSortData,
        setStates,
        setTab,
        setTargetRange,
        setTempFilters,
        setTransactionType,
        sortData,
        stateList,
        states,
        tab,
        targetDate,
        targetDateRange,
        tempFilters,
        transactionType,
        disableResultLimit,
        searchbarFilterTags,
        setSearchbarFilterTags,
    } = useContext(HQCEContext);
    const { trackOrderInput, trackSearchOptions, onTrackOrderSearch, setTrackOrderInput, setPreviousPageURL } =
        useContext(TrackOrderContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [expand, setExpand] = useState<boolean>(false);
    const [dateSorting, setDateSorting] = useState<string>('lastUpdated');
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State
    // datepicker error messages state
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    // track modal
    const [trackModal, setTrackModal] = useState<boolean>(false);

    const location: any = useLocation();
    const history = useHistory();
    const locationState: ILocationState = location.state;
    const data: string | undefined = locationState !== undefined && locationState ? locationState.tab : undefined;
    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 500);
    const { hq } = JSON.parse(userLoginContext.permission);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { permission } = hq;
    const { ceDashboard } = permission;

    const { allBranch, dueDateTab } = ceDashboard;
    const overViewTab = ceDashboard.overview;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const initialRenderTabsHQCE = useRef(true);
    const initialRenderFiltersHQCE = useRef(true);
    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleCardClick = (tab: number) => {
        if (tab === 1) {
            if (tabs.length > 1) {
                setTab(1);
            } else {
                setTab(0);
            }
        }
    };

    const handleReset = () => {
        const resetFilters =
            tabs.length > 0 && tabs[tab].name === 'all branches' ? initialBranchFilter : initialDueDateFilters;
        setTempFilters(resetFilters);
        setFilters(resetFilters);
        setStates([]);
        setTargetRange([null, null]);
        setFundingOption([]);
        setProductTypes([]);
        setSearchbarFilterTags([]);
        setDateSorting('lastUpdated');
        setAccountType('');
    };

    const handleApply = () => {
        setExpand(false);
        let _searchFilterTags: ISelectValue[] = [];
        _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
        setSearchbarFilterTags(_searchFilterTags);
        setFilters(tempFilters);
    };

    const switchTabs = (index: number) => {
        setTab(index);
    };

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const update = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetRange(update);
        const temp = [...filters];
        temp[0] = {
            column: filters[0].column,
            value: `${moment(update[0]).valueOf()}~${moment(update[1]).valueOf()}`,
        };
        setTempFilters(temp);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };
    const handleDateSorting = (value: string | number) => {
        const tmp = [...tempFilters];
        const obj: IColumnValue = {
            column: value!.toString(),
            value:
                moment(targetDateRange[0]).format('DD/MM/YYYY') !== moment().format('DD/MM/YYYY') &&
                targetDateRange[0] !== null
                    ? `${moment(targetDateRange[0]).valueOf()}~${moment(targetDateRange[1]).valueOf()}`
                    : '',
        };
        tmp[0] = obj;
        setDateSorting(value!.toString());
        setTempFilters(tmp);
    };

    const handleProductTypes = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item, index) => item.column !== 'fundType' || index === 2);
        data.map((item) => {
            newFilter.push({ column: 'fundType', value: item as string });
        });
        setProductTypes(data);
        setTempFilters(newFilter);
    };

    const handleFundingOption = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item, index) => item.column !== 'paymentMethod' || index === 3);
        data.map((item) => {
            newFilter.push({ column: 'paymentMethod', value: item as string });
        });
        setFundingOption(data);
        setTempFilters(newFilter);
    };

    const handleAccountTypes = (value: string | number) => {
        const tmp = [...tempFilters];
        const obj = {
            column: 'accountType',
            value: value!.toString(),
        };
        tmp[4] = obj;
        setAccountType(value!.toString());
        setTempFilters(tmp);
    };

    const selectedTab = (tab: number): string => {
        let temp = '';
        switch (tab) {
            case 0:
                temp = 'branch';
                break;
            case 1:
                temp = 'extension';
                break;
        }
        return temp;
    };

    const handleSecondary = () => {
        getExportList();
    };

    const handleMultiSelectDropdown = (data: ReactText[]) => {
        const temp = [...filters];
        temp.splice(0);
        data.map((state) => {
            temp.push({ column: 'state', value: state as string });
        });
        setStates(data);
        setTempFilters(temp);
    };

    const getExportList = async () => {
        const extensionQuery =
            lastUpdatedDropdown.keyName === 'createdOn'
                ? hqceDashboardExtensionExportListCreatedOn
                : hqceDashboardExtensionExportListLastUpdated;
        const query = tabs[tab].name === 'all branches' ? hqceDashboardBranchExportList : extensionQuery;
        const objectName = tabs[tab].name === 'all branches' ? 'hqcedashboardBranch' : 'hqcedashboardExtension';
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        tab: selectedTab(tab),
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filters,
                        endpoint: 'hqcedashboard',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;

            exportList('Hq', selectedTab(tab), result[objectName]);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'hq-dashboard-error-modal',
            });
        }
    };

    useEffect(() => {
        if (initialRenderFiltersHQCE.current) {
            initialRenderFiltersHQCE.current = false;
        } else
            switch (tabs[tab].name) {
                case 'all branches': {
                    getAllBranchData();
                    break;
                }
                case 'Due date extension': {
                    getDateExtensionData();
                    break;
                }
                default: {
                    getAllBranchData();
                    break;
                }
            }
        setPreviousPageURL(window.location.pathname);
    }, [currentPage, resultLimit, debouncedSearchTerm, sortData, filters]);

    useEffect(() => {
        if (initialRenderTabsHQCE.current) {
            switch (tabs[tab].name) {
                case 'all branches': {
                    // getAllBranchData();
                    setSearchInput({ column: SearchOptionsHQCE.branch[0].value, value: '' });
                    setTempFilters(initialBranchFilter);
                    setFilters(initialBranchFilter);
                    setSortData([{ column: 'name', value: 'Ascending' }]);
                    break;
                }
                case 'Due date extension': {
                    // getDateExtensionData();
                    setTempFilters(initialDueDateFilters);
                    setFilters(initialDueDateFilters);
                    setSortData([{ column: 'clientName', value: 'Ascending' }]);

                    break;
                }
                default: {
                    break;
                }
            }
            initialRenderTabsHQCE.current = false;
        } else {
            setCurrentPage(1);

            switch (tabs[tab].name) {
                case 'all branches': {
                    // getAllBranchData();
                    setSearchInput({ column: SearchOptionsHQCE.branch[0].value, value: '' });
                    setTempFilters(initialBranchFilter);
                    setFilters(initialBranchFilter);
                    setSortData([{ column: 'name', value: 'Ascending' }]);
                    break;
                }
                case 'Due date extension': {
                    // getDateExtensionData();
                    setSearchInput({ column: 'all', value: '' });
                    setTempFilters(initialDueDateFilters);
                    setFilters(initialDueDateFilters);
                    setSortData([{ column: 'clientName', value: 'Ascending' }]);

                    break;
                }
                default: {
                    break;
                }
            }
            return function cleanup() {
                tabs[tab].name === 'Due date extension'
                    ? updateIsSeenDashboard('hqcedashboard', ['extension'], idTokenHeader)
                    : null;
            };
        }
    }, [tab]);

    useEffect(() => {
        if (showModal === false && tab === 1) {
            getDateExtensionData();
        }
    }, [showModal]);

    useEffect(() => {
        if (data !== undefined) {
            setTempFilters(initialDueDateFilters);
            setFilters(initialDueDateFilters);
            setSortData([{ column: 'clientName', value: 'Ascending' }]);
            setTab(tabs.length > 1 ? 1 : 0);
        }
    }, []);

    const tabs: IDashboardTabs[] = [];
    let searchOrFiltered = false;
    if (allBranch.actions.canViewAllBranchList) {
        tabs.push({
            name: 'all branches',
            table: <div />,
            testId: 'all-branches-tab',
        });
    }
    if (dueDateTab.actions.canViewDetails) {
        tabs.push({
            name: 'Due date extension',
            table: <div />,
            testId: 'due-date-extension-tab',
        });
    }
    const initialFilter: IColumnValue[] =
        tabs.length > 0
            ? tabs[tab].name === 'Due date extension'
                ? initialDueDateFilters
                : initialBranchFilter
            : initialBranchFilter;
    const sameFilter = JSON.stringify(initialFilter) !== JSON.stringify(filters);

    searchOrFiltered = sameFilter;

    const handleRemoveFilter = (item: ISelectValue, index: number) => {
        let tempValues = [...searchbarFilterTags];
        tempValues = removeArrayElement(tempValues, tempValues[index]);
        setSearchbarFilterTags(tempValues);
        let arrayIndex = -1;
        switch (item.label) {
            case LABEL.accountTypes:
                setAccountType('');
                break;
            case LABEL.productTypes:
                const tempProductTypes = [...productTypes];
                arrayIndex = tempProductTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempProductTypes.splice(arrayIndex, 1) : null;
                setProductTypes(tempProductTypes);
                break;
            case LABEL.fundingOptions:
                const tempFundingOptions = [...fundingOption];
                arrayIndex = tempFundingOptions.indexOf(item.value);
                arrayIndex !== -1 ? tempFundingOptions.splice(arrayIndex, 1) : null;
                setFundingOption(tempFundingOptions);
                break;
            case LABEL.lastUpdated:
            case LABEL.submittedOn:
            case LABEL.createdOn:
                setTargetRange([null, null]);
                break;
            case LABEL.state:
                const tempStates = [...states];
                arrayIndex = tempStates.indexOf(item.value);
                arrayIndex !== -1 ? tempStates.splice(arrayIndex, 1) : null;
                setStates(tempStates);
        }
        let _tempFilters = [...tempFilters];
        _tempFilters = removeSearchFilterTagFromFilter(item, tempFilters);
        setTempFilters(_tempFilters);
        setFilters(_tempFilters);
    };

    if (allBranch.actions.canViewAllBranchList) {
        tabs[0] = {
            name: 'all branches',
            table: (
                <AllBranch
                    allBranchData={offices}
                    searchEmpty={searchOrFiltered}
                    searchInput={searchInput.value}
                    setSortData={setSortData}
                    sortData={sortData}
                />
            ),
            testId: 'all-branches-tab',
        };
    }
    if (dueDateTab.actions.canViewDetails) {
        tabs[1] = {
            name: 'Due date extension',
            table: (
                <TargetDateExtension
                    dueDateTab={dueDateTab}
                    filters={filters}
                    lastUpdatedDropdown={lastUpdatedDropdown}
                    searchEmpty={searchOrFiltered}
                    searchInput={searchInput.value}
                    setFilters={setFilters}
                    setLastUpdatedDropdown={setLastUpdatedDropdown}
                    setShowModal={setShowModal}
                    setSortData={setSortData}
                    setTransactionType={setTransactionType}
                    showModal={showModal}
                    sortData={sortData}
                    targetDateData={targetDate}
                    transactionType={transactionType}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'due-date-extension-tab',
        };
    }

    const getTab = () => {
        if (tabs.length > 0) {
            switch (tabs[tab].name) {
                case 'all branches':
                    return allBranch;
                    break;
                case 'Due date extension':
                    return dueDateTab;
                    break;
                default:
                    return allBranch;
                    break;
            }
        }
    };
    const currentTab = getTab();
    const secondaryButtons: IActionButton[] = [];
    if (currentTab && currentTab.actions.canExportList === 'maker') {
        secondaryButtons.push({
            type: 'secondary',
            title: 'Export List',
            actionIcon: 'export',
            testId: 'export-list-btn',
            disabled: orderCounter[tab] === 0,
        });
    }
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        const options =
            tabs.length > 0 && tabs[tab].name === 'all branches' ? SearchOptionsHQCE.branch : SearchOptionsHQCE.dueDate;
        options.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `hq-dashboard-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };
    interface ErrorFallbackProps {
        error: string | any;
    }
    function ErrorFallback({ error }: ErrorFallbackProps) {
        return (
            <div role="alert">
                <p>An Error/Something Occurred during runtime that caused the app to fail load successfully: </p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
            </div>
        );
    }

    // opens modal for track order
    const OpenTrackOrderModal = () => {
        setTrackModal(true);
    };

    const trackOrder = () => {
        history.push(ROUTES.hqTrackOrder);
    };

    const closeTrackModal = () => {
        setTrackModal(false);
    };
    return (
        <Fragment>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AuthContext.Consumer>
                    {({ userLoginContext }) => (
                        <FlexedDiv direction="row">
                            <Fs24RegSecNavyBlue>{LABEL.welcomeBack}</Fs24RegSecNavyBlue>
                            <CustomSpacer horizontal={true} space={'0.25rem'} />
                            <Fs24BoldSecNavyBlue>{userLoginContext.username}</Fs24BoldSecNavyBlue>
                        </FlexedDiv>
                    )}
                </AuthContext.Consumer>
                <CustomSpacer space={'1.5rem'} />
                {overViewTab.actions.canViewOverview ? (
                    <SliderOverview
                        handleCardClick={handleCardClick}
                        maxVisibleSlides={2}
                        slideMargin={10}
                        overviewData={overview}
                    />
                ) : null}
                <FlexedDiv direction="column">
                    <SearchBar
                        expand={expand}
                        setExpand={setExpand}
                        placeHolder={
                            tabs.length > 0 && tabs[tab].name === 'all branches'
                                ? LABEL.hqCeAllBranchSearch
                                : LABEL.hqCeDueDateSearch
                        }
                        handleSecondary={handleSecondary}
                        searchInput={searchInput}
                        searchTitle={
                            tabs.length > 0 && tabs[tab].name === 'all branches'
                                ? LABEL.branchTransactions
                                : LABEL.search
                        }
                        onSearch={(event: ChangeEvent<HTMLInputElement>) => {
                            setSearchInput({ ...searchInput, value: event.target.value });
                        }}
                        secondaryButton={secondaryButtons}
                        tertiaryButton={[TrackOrderButton]}
                        handleTertiary={OpenTrackOrderModal}
                        filter={true}
                        dropDownOptions={searchOptions()}
                        data-testid="search-bar"
                        testId="hq-dashboard-searchbar"
                        clearSearchInput={() => {
                            setSearchInput({ ...searchInput, value: '' });
                        }}
                        disableFilterSearchDropDown={searchOptions().length === 1}
                        filterTags={searchbarFilterTags}
                        showTags={showAllTags}
                        disabled={loading}
                        disableFilters={orderCounter[tab] === 0}
                    >
                        {expand ? (
                            <>
                                {/* <div style={{ borderBottom: '1px solid #EAEBEE', margin: '0rem -1.5rem' }} /> */}
                                <TextPureBlack>{LABEL.filterTransactionsBy}</TextPureBlack>
                                <CustomSpacer space={'0.5rem'} />
                                {tabs[tab].name === 'all branches' ? (
                                    <div style={{ maxWidth: '30rem' }}>
                                        <MultiSelect
                                            checkedState={states}
                                            handleMultiSelect={handleMultiSelectDropdown}
                                            label={LABEL.state}
                                            noOverlay={false}
                                            options={stateList !== undefined ? stateList : ['']}
                                            placeHolder={LABEL.select}
                                            testId={`state-dropdown-filter`}
                                        />

                                        <CustomSpacer space={'8vh'} />
                                    </div>
                                ) : (
                                    <Fragment>
                                        <FlexedDiv direction="row" wrap="wrap">
                                            <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                                                <Fs12BoldPrimaryBlack>{LABEL.dateSorting}</Fs12BoldPrimaryBlack>
                                                <SelectWrapper>
                                                    <SelectDropdown
                                                        onChange={handleDateSorting}
                                                        options={dateSortingDropDown}
                                                        testId={`date-sorting`}
                                                        selectedValue={dateSorting}
                                                        placeHolder="Select one or more"
                                                    />
                                                </SelectWrapper>
                                            </FlexedDiv>
                                            <FlexedDiv direction="column">
                                                <Fs12BoldPrimaryBlack>{LABEL.dateRange}</Fs12BoldPrimaryBlack>
                                                <InputWrap>
                                                    <DatePicker
                                                        setTargetDate={(date) => {
                                                            handleTargetDate(
                                                                date as [moment.Moment | null, moment.Moment | null],
                                                            );
                                                        }}
                                                        targetDate={targetDateRange}
                                                        range={true}
                                                        width="360"
                                                        handleDate={handleDate}
                                                        errorMessage={datePickerInvalidMessage}
                                                        setErrorMessage={setDatePickerInvalidMessage}
                                                    />
                                                </InputWrap>
                                            </FlexedDiv>
                                        </FlexedDiv>
                                        <FlexedDiv wrap="wrap" style={{ rowGap: '1.5rem', columnGap: '1rem' }}>
                                            <FlexedDiv direction="column">
                                                <Fs12BoldPrimaryBlack>{LABEL.transTypes}</Fs12BoldPrimaryBlack>
                                                <SelectWrapper>
                                                    <SelectDropdown
                                                        options={[{ value: LABEL.salesAo, label: LABEL.salesAo }]}
                                                        testId={`transactiontype-sorting`}
                                                        selectedValue={LABEL.salesAo}
                                                        isDisabled={true}
                                                    />
                                                </SelectWrapper>
                                            </FlexedDiv>
                                            <FlexedDiv direction="column">
                                                <MultiSelect
                                                    checkedState={productTypes}
                                                    handleMultiSelect={handleProductTypes}
                                                    hasSelectAll={false}
                                                    label={LABEL.productTypes}
                                                    noOverlay={false}
                                                    options={ProductTypes}
                                                    placeHolder={LABEL.select}
                                                    testId={`producttype-dropdown-filter`}
                                                />
                                            </FlexedDiv>
                                            <FlexedDiv direction="column">
                                                <MultiSelect
                                                    checkedState={fundingOption}
                                                    handleMultiSelect={handleFundingOption}
                                                    hasSelectAll={false}
                                                    label={LABEL.fundingOption}
                                                    noOverlay={false}
                                                    options={FundingOptions}
                                                    placeHolder={LABEL.select}
                                                    testId={`funding-dropdown-filter`}
                                                />
                                            </FlexedDiv>
                                            <FlexedDiv direction="column">
                                                <Fs12BoldPrimaryBlack>{LABEL.accountTypes}</Fs12BoldPrimaryBlack>
                                                <SelectWrapper>
                                                    <SelectDropdown
                                                        onChange={handleAccountTypes}
                                                        options={accountTypes}
                                                        testId={`date-sorting`}
                                                        selectedValue={accountType}
                                                    />
                                                </SelectWrapper>
                                            </FlexedDiv>
                                        </FlexedDiv>
                                    </Fragment>
                                )}
                                <FlexedDiv style={{ marginTop: '2rem' }} justifyContent="center">
                                    <CustomButton
                                        onClick={() => {
                                            handleReset();
                                            setExpand(!expand);
                                        }}
                                        style={{ width: '16.67vw' }}
                                        id="hq-reset-btn"
                                    >
                                        <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                                    </CustomButton>
                                    <CustomSpacer horizontal={true} space={'1rem'} />
                                    <CustomButton
                                        primary={true}
                                        onClick={handleApply}
                                        style={{ width: '16.67vw', padding: '14px 0' }}
                                        id="hq-apply-btn"
                                    >
                                        {LABEL.apply}
                                    </CustomButton>
                                </FlexedDiv>
                            </>
                        ) : (
                            <SearchBarFilterTags
                                tags={searchbarFilterTags}
                                disabled={loading}
                                showAll={showAllTags}
                                handleShowAll={() => setShowAllTags(!showAllTags)}
                                handleClearAll={handleReset}
                                handleRemoveTag={handleRemoveFilter}
                                testId="branch-dashboard"
                            />
                        )}
                    </SearchBar>
                    <TabWrapper>
                        <Dashboard
                            tabs={tabs}
                            selectedTab={tab}
                            page={currentPage}
                            maxPage={maxPages}
                            setResultLimit={setResultLimit}
                            orderCounter={orderCounter}
                            switchTabs={switchTabs}
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            testId="hq-dashboard"
                            setCurrentPage={setCurrentPage}
                            resultLimit={resultLimit}
                            disableResultLimit={disableResultLimit}
                            adjustTabMargin={searchbarFilterTags.length !== 0}
                        />
                        {loading ? <ComponentLoader /> : null}
                    </TabWrapper>
                </FlexedDiv>
            </ErrorBoundary>
            {/* track order modal */}
            {trackModal ? (
                <TrackOrderModal
                    modalActive={trackModal}
                    setModalActive={setTrackModal}
                    trackOrder={trackOrder}
                    closeTrackModal={closeTrackModal}
                    trackOrderInput={trackOrderInput}
                    trackSearchOptions={trackSearchOptions()}
                    onTrackOrderSearch={onTrackOrderSearch}
                    setTrackOrderInput={setTrackOrderInput}
                    title="Track Order"
                    testId="track-order-modal"
                />
            ) : null}
        </Fragment>
    );
};

export default DashboardHQ;

const TabWrapper = styled.div`
    position: relative;
`;
const InputWrap = styled.div`
    width: 18.33vw;
    min-width: 250px;
    height: 2.5rem;
    margin-top: 3px;
    margin-right: 1rem;
    margin-bottom: 2rem;
`;
const SelectWrapper = styled.div`
    margin-right: 1vw;
    min-width: 335px;
    max-width: 600px;
`;
