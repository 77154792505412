import React, { Fragment } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { FlexedDiv } from '..';
import { TextNormal12 } from '../../../constants/textStyles';
import { sh24 } from '../../../styles';

export interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    disabled?: boolean;
    errorText?: string;
    label?: string;
    name?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    prefix: string;
    style?: CSSProperties;
    value: string;
    validation?: string;
    validationMessage?: string;
}
const defaultWidth = '360px';

export const PhoneInput: React.FC<PhoneInputProps> = ({
    disabled,
    errorText,
    label,
    name,
    onChange,
    prefix,
    style,
    value,
    validation,
    validationMessage,
    ...rest
}: PhoneInputProps) => {
    return (
        <Fragment>
            <Container style={style}>
                <StyledInputLabel>{label}</StyledInputLabel>
                <FlexedDiv>
                    <CountryCodeDiv>{prefix}</CountryCodeDiv>
                    <StyledPhoneInput
                        placeholder="12 345 6789"
                        {...rest}
                        name={name}
                        value={value}
                        disabled={disabled}
                        accept={validation}
                        itemProp={validationMessage}
                        onChange={onChange}
                        prefix={prefix}
                    />
                </FlexedDiv>
                {errorText ? (
                    <TextNormal12 style={{ position: 'absolute', color: '#E84C3D', paddingLeft: sh24 }}>
                        {errorText}
                    </TextNormal12>
                ) : null}
            </Container>
        </Fragment>
    );
};

const Container = styled.div`
    width: ${defaultWidth};
    position: relative;
`;
const StyledInputLabel = styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 15.6px;
    color: #333333;
    flex: none;
    align-self: stretch;
    margin-bottom: 4px;
`;
const CountryCodeDiv = styled.div<IOnPropStyles>`
    max-height: 3rem;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-top: 1px solid #c9c9cd;
    border-left: 1px solid #c9c9cd;
    border-right: 0px solid #c9c9cd;
    border-bottom: 1px solid #c9c9cd;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    background-color: ${(props) => (props.disabled ? 'rgba(239,239,239,0.3)' : '#FFF')};
    padding: 0.75rem 0.25rem 0.75rem 1rem;
    color: #002043;
    line-height: 1.5rem;
    font-size: 1rem;
`;
const StyledPhoneInput = styled.input<PhoneInputProps>`
    padding: 0.75rem 1rem;
    background: ${(props) => (props.errorText !== undefined && props.errorText !== '' ? '#FDF1EF' : '#ffffff')};
    border-top: ${(props) =>
        props.errorText !== undefined && props.errorText !== '' ? '2px solid #E84C3D' : '1px solid #cecece'}!important;
    border-right: ${(props) =>
        props.errorText !== undefined && props.errorText !== '' ? '2px solid #E84C3D' : '1px solid #cecece'}!important;
    border-left: ${(props) =>
        props.errorText !== undefined && props.errorText !== '' ? '2px solid #E84C3D' : '0px solid #cecece'}!important;
    border-bottom: ${(props) =>
        props.errorText !== undefined && props.errorText !== '' ? '2px solid #E84C3D' : '1px solid #cecece'}!important;
    box-sizing: border-box;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-width: 310px;
    font-size: 1rem;
    line-height: 1.5rem;
    max-height: 3rem;
    &:disabled {
        background: #f4f4f4;
        opacity: 0.6;
        pointer-events: none;
        color: #171717;
    }
    &:hover {
        border: ${(props) =>
            props.errorText !== undefined && props.errorText !== '' ? '' : '1px solid #66798e'} !important;
        outline: ${(props) => (props.errorText !== undefined && props.errorText !== '' ? 'none' : '')};
    }
    &:focus {
        outline: ${(props) => (props.errorText !== undefined && props.errorText !== '' ? 'none' : '')};
        box-shadow: ${(props) =>
            props.errorText !== undefined && props.errorText !== ''
                ? ''
                : '0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02)'};
        color: #002043;
    }
    ::placeholder {
        font-weight: 400;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-text-fill-color: inherit;
        box-shadow: '';
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }
`;
