import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { IcoMoon } from '../../../icons';
import FlexSpacer, { CustomButton, FlexedDiv } from '../../atoms';
import { FilterSearch, IDropdownItem } from '../../molecules';
import styled, { css } from 'styled-components';
import CustomDropdown from '../../molecules/Dropdown';
import { LinkButton } from '../../atoms/LinkButton';
import { useHistory } from 'react-router-dom';
export interface SearchInput {
    column: string;
    value: string;
}

/**
 * CustomSearchBarProps interface that extends IFilterSearch for SearchBar component
 * @interface
 * @property {IDropdownItem[]}  actionDropdownMenuItems - optional to pass array of IDropdownItem to the search criteria drop down .
 * @property {boolean}  expand - optional boolean value of the advance filters tray.
 * @property {boolean}  filter - optional value to show the advance filters button.
 * @property {function(value:SelectValue):void}  handleSelect - optional fn of type (value: SelectValue) => void; to handle search criteria
 * @property {function(props:IActionButton):void}  handlePrimary - optional fn of type (props: IActionButton) => void; to handle primary button
 * @property {function(props:IActionButton):void}  handleSecondary - optional fn of type (props: IActionButton) => void; to handle secondary button
 * @property {function(props:IActionButton):void}  handleTertiary - optional fn of type (props: IActionButton) => void; to handle tertiary button
 * @property {boolean}  isSimpleSearch - optional boolean value to determine whether the search bar should be simple.
 * @property {IActionButton}  primaryButton - optional props of define the primary button.
 * @property {Array<IActionButton>}  secondaryButton - optional props of define the secondary buttons.
 * @property {Array<IActionButton>}  tertiaryButton - optional props of define the secondary buttons.
 * @property {function(props:IFilter):JSX.Element}  RenderFilter - optional fn of type (props: IFilter) => JSX.Element; to render the advance filter tray.
 * @property {SearchInput}  searchInput - value of the keyed in search criteria.
 * @property {string}  searchTitle - value to of the title in the Search Bar.
 * @property {function(toggle:boolean):void}  setExpand - optional fn of type (toggle: boolean) => void; to toggle the advance filter tray.
 */
interface CustomSearchBarProps extends IFilterSearch {
    actionDropdownMenuItems?: () => IDropdownItem[];
    children?: ReactNode;
    clearSearchInput?: () => void;
    disableActionDropdown?: boolean;
    disabled?: boolean;
    disableFilters?: boolean;
    expand?: boolean;
    filter?: boolean;
    filterTags?: Array<ISelectValue>;
    handleDropDown?: (props: IMenuItem) => void;
    handlePrimary?: (props: IActionButton) => void;
    handleSecondary?: (props: IActionButton) => void;
    handleTertiary?: (props: IActionButton) => void;
    isSimpleSearch?: boolean;
    primaryButton?: IActionButton;
    RenderFilter?: (props: IFilter) => JSX.Element;
    searchInput: SearchInput;
    searchTitle: string;
    secondaryButton?: Array<IActionButton>;
    tertiaryButton?: Array<IActionButton>;
    setExpand?: (toggle: boolean) => void;
    showTags?: boolean;
    testId?: string;
    externalLink?: ExternalLink;
    disableFilterSearchDropDown?: boolean;
}
type ExternalLink = {
    title: string;
    link: string;
};
type SearchBarExpandProps = {
    showContent: boolean;
    height: number;
};
type SearchBarBottomRightProps = {
    isExpand?: boolean;
};
type SearchBarInnerProps = {
    height: number;
    showContent: boolean;
};
/**
 * SearchBar component that handles searching and advance table filtering options and takes in props of interface {@link CustomSearchBarProps}
 * @category Organisms
 * @component
 */
export const SearchBar: React.FC<CustomSearchBarProps> = ({
    actionDropdownMenuItems,
    children,
    clearSearchInput,
    disableActionDropdown,
    disabled,
    disableFilters,
    dropDownOptions,
    expand,
    externalLink,
    filter,
    filterTags,
    handlePrimary,
    handleSecondary,
    handleTertiary,
    isSimpleSearch,
    onSearch,
    placeHolder,
    primaryButton,
    tertiaryButton,
    RenderFilter,
    searchInput,
    searchTitle,
    secondaryButton,
    setExpand,
    showTags,
    testId,
    disableFilterSearchDropDown,
}: CustomSearchBarProps) => {
    // const [showContent, setShowContent] = useState(false);
    const iconColor = expand === true ? '#ffffff' : '#002043';
    const ref = useRef<HTMLDivElement>(null);
    const [searchBarHeight, setSearchBarHeight] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const history = useHistory();
    useEffect(() => {
        if (expand === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
            setSearchBarHeight((ref.current?.scrollHeight as number) + 204);
        } else {
            if (filterTags !== undefined && filterTags.length !== 0) {
                setHeight((ref.current?.scrollHeight as number) + 48);
                setSearchBarHeight((ref.current?.scrollHeight as number) + 156);
            } else setSearchBarHeight(152);
        }
    }, [expand, disableFilters, showTags, filterTags]);
    return (
        <SearchBarContainer id="search-bar-container">
            <SearchBarInner height={searchBarHeight} showContent={expand ? expand : false}>
                <SearchBarTop id="search-bar-top">
                    <SearchBarH1 style={{ color: '#002043' }}>{searchTitle}</SearchBarH1>
                    {externalLink !== undefined ? (
                        <LinkButton
                            noFill
                            color="#002043"
                            onClick={() => {
                                history.push(externalLink.link);
                            }}
                            style={{ alignSelf: 'baseline', margin: '6px 1rem' }}
                        >
                            {externalLink.title}
                        </LinkButton>
                    ) : null}
                    <FlexSpacer />
                    <FlexedDiv style={{ height: '2rem' }} id="action-btn-container">
                        {tertiaryButton !== undefined && (
                            <>
                                {tertiaryButton.map((button, index) => {
                                    return (
                                        <CustomButton
                                            secondary={true}
                                            key={index + 1}
                                            style={{
                                                marginRight: primaryButton !== undefined ? '1rem' : '.5rem',
                                                width: button.width,
                                            }}
                                            prefixIcon={button.actionIcon}
                                            onClick={() =>
                                                handleTertiary !== undefined
                                                    ? handleTertiary(tertiaryButton[index])
                                                    : ''
                                            }
                                            disabled={button.disabled}
                                            id={button.testId}
                                        >
                                            {button.title}
                                        </CustomButton>
                                    );
                                })}
                                <FlexedDiv style={{ border: '1px solid #CECECE', marginRight: '1rem' }} />
                            </>
                        )}
                        {secondaryButton !== undefined ? (
                            <Fragment>
                                {secondaryButton.map((item, index) => {
                                    return (
                                        <CustomButton
                                            secondary={true}
                                            key={index + 1}
                                            style={{
                                                marginRight: primaryButton !== undefined ? '1rem' : '.5rem',
                                                width: item.width,
                                            }}
                                            prefixIcon={item.actionIcon}
                                            onClick={() =>
                                                handleSecondary !== undefined
                                                    ? handleSecondary(secondaryButton[index])
                                                    : ''
                                            }
                                            disabled={item.disabled}
                                            id={item.testId}
                                        >
                                            {item.title}
                                        </CustomButton>
                                    );
                                })}
                            </Fragment>
                        ) : null}
                        {primaryButton !== undefined ? (
                            <Fragment>
                                {primaryButton.isDropdown !== true ? (
                                    <CustomButton
                                        primary
                                        prefixIcon={primaryButton.actionIcon}
                                        onClick={() =>
                                            handlePrimary !== undefined ? handlePrimary(primaryButton) : ''
                                        }
                                        id={primaryButton.testId}
                                        data-testid="primary-btn"
                                    >
                                        {primaryButton.title}
                                    </CustomButton>
                                ) : actionDropdownMenuItems !== undefined ? (
                                    !disableActionDropdown ? (
                                        <CustomDropdown items={actionDropdownMenuItems()}>
                                            <CustomButton
                                                primary
                                                prefixIcon={primaryButton.actionIcon}
                                                suffixIcon="caret-down"
                                                id={primaryButton.testId}
                                                data-testid="primary-btn"
                                                disabled={disableActionDropdown}
                                            >
                                                {primaryButton.title}
                                            </CustomButton>
                                        </CustomDropdown>
                                    ) : (
                                        <CustomButton
                                            primary
                                            prefixIcon={primaryButton.actionIcon}
                                            suffixIcon="caret-down"
                                            id={primaryButton.testId}
                                            data-testid="primary-btn"
                                            disabled={disableActionDropdown}
                                        >
                                            {primaryButton.title}
                                        </CustomButton>
                                    )
                                ) : null}
                            </Fragment>
                        ) : null}
                    </FlexedDiv>
                </SearchBarTop>

                <SearchBarBottom
                    disabled={disableFilters || (filterTags !== undefined && filterTags?.length !== 0)}
                    id="search-bar-bottom"
                    isOnProp={expand}
                >
                    <SearchBarBottomRight isExpand={filter}>
                        <FilterSearch
                            placeHolder={placeHolder}
                            options={dropDownOptions}
                            handleClearContents={clearSearchInput}
                            handleSearch={onSearch}
                            searchValue={searchInput?.value}
                            selectValue={searchInput.column}
                            isSimpleSearch={isSimpleSearch}
                            testId={testId}
                            disabled={disabled || expand}
                            disableFilterSearchDropDown={disableFilterSearchDropDown}
                        />
                    </SearchBarBottomRight>
                    {filter === undefined || filter === true ? (
                        <FilterButton
                            renderStyle={expand !== undefined ? expand : false}
                            onClick={() => {
                                setExpand && setExpand(!expand);
                            }}
                            id={`${testId}-filter-btn`}
                            data-testid="searchBar-expand-toggle"
                            disabled={disabled || disableFilters}
                        >
                            <IcoMoon color={iconColor} name={expand === true ? 'close' : 'filter'} size="2rem" />
                        </FilterButton>
                    ) : (
                        ''
                    )}
                </SearchBarBottom>
                <SearchBarExpand
                    showContent={expand || (filterTags !== undefined && filterTags?.length !== 0)}
                    height={height}
                >
                    <SearchBarExpandInner
                        ref={ref}
                        id={`search-bar-expand-inner-${expand ? 'expected' : 'notexpected'}`}
                        data-testid="search-bar-expand-inner"
                    >
                        {RenderFilter !== undefined ? <RenderFilter /> : null}
                        {children}
                    </SearchBarExpandInner>
                </SearchBarExpand>
            </SearchBarInner>
        </SearchBarContainer>
    );
};

interface IOnRenderStyleProps {
    renderStyle: boolean;
}
const SearchBarContainer = styled.div`
    width: 100%;
`;
const SearchBarInner = styled.div<SearchBarInnerProps>`
    border-radius: 24px;
    border-bottom: 1px solid #eaebee;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
    height: ${(props) => (props.height ? props.height : 1)}px;
    position: absolute;
    background-color: white;
    z-index: 99;
    width: inherit;
    transition: all 0.3s ease;
    will-change: height;
`;
const SearchBarTop = styled.div`
    display: flex;
    padding: 1.5rem 1.5rem 0 1.5rem;
`;
const SearchBarBottom = styled.div<IOnPropStyles>`
    display: flex;
    align-items: center;
    padding: ${(props) => (props.disabled ? '0 1.5rem' : '0 1.5rem 1.5rem 1.5rem')};
    border-bottom: none;
    ${(props) =>
        props.isOnProp &&
        css`
            border-bottom: 1px solid #ececec;
        `}
`;
// const SearchBarTagRow = styled.div`
//     display: flex;
//     align-items: center;
//     padding: 0 1.5rem;
// `;
const SearchBarBottomRight = styled.div<SearchBarBottomRightProps>`
    margin-right: ${(props) => (!props.isExpand ? '0px' : '2.5rem')};
    width: 100%;
`;
const SearchBarExpand = styled.div<SearchBarExpandProps>`
    /* border-top: 1px solid rgb(236, 236, 236); */
    margin: 1.5rem;
    overflow: ${(props) => (props.showContent ? 'inherit' : 'hidden')};
    pointer-events: ${(props) => (props.showContent ? 'auto' : 'none')};
    visibility: ${(props) => (props.showContent ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.showContent ? 1 : 0)};
    height: ${(props) => (props.height ? props.height : 0)}px;
    transition: opacity 0.3s ease 0.4s;
    will-change: height opacity visibility;
`;
const SearchBarExpandInner = styled.div``;
const SearchBarH1 = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    line-height: 32.4px;
    letter-spacing: -0.666667px;
    margin-bottom: 1rem;
    height: 2rem;
    align-items: center;
`;
const FilterButton = styled.button`
    background-color: white;
    border: 1px solid #c6cbd4;
    box-shadow: 4px 4px 8px rgba(0, 32, 67, 0.1);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    &:hover {
        background-color: #edf0f4;
    }
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            background-color: #002043;
            border: 1px solid #002043;
            &:hover {
                background-color: #002043;
            }
        `};
    &:disabled {
        pointer-events: none;
        opacity: 0.4;
    }
`;
// const Normal = styled.div`
//     font-weight: 400;
// `;
