/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, lazy, useContext, useEffect } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

import {
    RejectTransaction,
    RerouteTransaction,
    AdvisorBranch,
    Inbox,
    AdviserProfile,
    TrackOrder,
    BranchWrapper,
} from '../../pages';
import { ExtendTargetDateBranch } from '../../pages/DashboardBranch/ExtendTargetDate/ExtendTargetDateBranch';
import { onRefreshFn } from '../../utils/authServices';
import { FlexedDiv, Modal } from '../../components';
import { CEExtendDueDateRequest } from '../../pages/Inbox/CEPages/CEExtendDueDateRequest';
import { AdvisersProvider } from '../../context/BranchContext/advisers/AdvisersProvider';
import { InboxProvider } from '../../context/InboxContext';
import { PendingSubmission } from '../../pages/PendingSubmission';
import { PendingSubmissionProvider } from '../../context/PendingSubmissionContext';

import * as ROUTES from '../../routes';
import DashboardBranch from '../../pages/DashboardBranch/DashboardBranch';
import TrackOrderProvider from '../../context/TrackOrderContext/TrackOrderProvider';
import WebSocketContext from '../../context/WebSocketContext';
import DueDateExtension from '../../pages/ExtendTargetDate';
import AuthContext from '../../context/AuthContext';
import DashboardBranchProvider from '../../context/BranchContext/DashboardBranchProvider';
import Profile from '../../pages/Profile';
import Error404 from '../../pages/ExceptionHandling/Error404';
import { LABEL } from '../../constants';
import InvestorsProvider from '../../context/InvestorContext/InvestorsProvider';

const InvestorDashboard = lazy<any>(() => import('../../pages/Investors/InvestorDashboard'));
const OrderDetails = lazy<any>(() => import('../../pages/OrderDetails'));
const OrderSummaryNew = lazy<any>(() => import('../../pages/OrderDetails/OrderSummaryNew'));
const AccountProfile = lazy<any>(() => import('../../pages/Investors/Pages/AccountProfile'));
const InvestorProfile = lazy<any>(() => import('../../pages/Investors/Pages/InvestorProfile'));

export interface IBranchRouteProps extends RouteProps {
    component?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BranchRoute = ({ component: Component, ...rest }: IBranchRouteProps): JSX.Element => {
    const { isLoggedOut, setIsLoggedOut } = useContext(WebSocketContext);
    const { userLoginContext, windowReloadFn } = useContext(AuthContext);

    const checkPermission = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.grant === 'branch' || parsedPermission.hq.grant === 'hq') {
            return true;
        }
        return false;
    };

    const checkTransactions = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.permission.ceTransaction.isAll === 'true') {
            return true;
        }
        return false;
    };

    const checkAdvisers = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.permission.adviser.isAll === 'true') {
            return true;
        }
        return false;
    };
    useEffect(() => {
        windowReloadFn();
    }, [onRefreshFn]);
    return (
        <Fragment>
            {userLoginContext.isAuthenticated && checkPermission(userLoginContext.permission) ? (
                <InboxProvider>
                    <BranchWrapper>
                        <DashboardBranchProvider>
                            <TrackOrderProvider>
                                <AdvisersProvider>
                                    <InvestorsProvider>
                                        <Switch>
                                            {checkTransactions(userLoginContext.permission) ? (
                                                <Route
                                                    exact
                                                    path={ROUTES.dashboardBranch}
                                                    component={DashboardBranch}
                                                />
                                            ) : null}

                                            <Route exact path={ROUTES.branchTrackOrder} component={TrackOrder} />
                                            <Route
                                                exact
                                                path={ROUTES.branchPendingSubmissionTrackOrder}
                                                component={TrackOrder}
                                            />
                                            <Route exact path={ROUTES.branchInbox} component={Inbox} />
                                            <Route
                                                // exact
                                                path={ROUTES.branchInboxOrderSummary}
                                                component={OrderDetails}
                                            />
                                            <Route
                                                // exact
                                                path={ROUTES.branchInboxOrderSummaryChangeRequest}
                                                component={OrderSummaryNew}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.branchInboxReRouteReview}
                                                component={RerouteTransaction}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.branchInboxRejectReview}
                                                component={RejectTransaction}
                                            />
                                            <Route exact path={ROUTES.profile} component={Profile} />

                                            <Route
                                                exact
                                                path={`${ROUTES.branchInbox}/due-date-extension`}
                                                component={CEExtendDueDateRequest}
                                            />
                                            {checkAdvisers(userLoginContext.permission) ? (
                                                <Route exact path={ROUTES.advisers} component={AdvisorBranch} />
                                            ) : null}
                                            <Route
                                                exact
                                                path={ROUTES.branchAdvisorProfile}
                                                component={AdviserProfile}
                                            />
                                            <Route exact path={ROUTES.branchInvestors} component={InvestorDashboard} />
                                            <Route
                                                exact
                                                path={ROUTES.branchAccountProfile}
                                                component={AccountProfile}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.branchInvestorProfile}
                                                component={InvestorProfile}
                                            />
                                            <PendingSubmissionProvider>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchPendingSubmission}
                                                        component={PendingSubmission}
                                                    />
                                                    <Route
                                                        path={`${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}`}
                                                        component={OrderDetails}
                                                    />
                                                    <Route
                                                        path={`${ROUTES.branchPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`}
                                                        component={OrderSummaryNew}
                                                    />
                                                    <Route
                                                        path={`/branch/dashboard/${ROUTES.orderSummary}`}
                                                        component={OrderDetails}
                                                    />
                                                    <Route
                                                        path={`/branch/dashboard/${ROUTES.orderSummaryChangeRequest}`}
                                                        component={OrderSummaryNew}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.extendTargetDate}`}
                                                        component={ExtendTargetDateBranch}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.extendTargetDateRequest}`}
                                                        component={DueDateExtension}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.rejectTransaction}`}
                                                        component={RejectTransaction}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.rerouteTransaction}`}
                                                        component={RerouteTransaction}
                                                    />
                                                    <Route component={Error404} />
                                                </Switch>
                                            </PendingSubmissionProvider>

                                            <Route component={Error404} />
                                        </Switch>
                                    </InvestorsProvider>
                                </AdvisersProvider>
                            </TrackOrderProvider>
                        </DashboardBranchProvider>
                    </BranchWrapper>
                </InboxProvider>
            ) : null}
            {isLoggedOut && (
                <Modal
                    title={'Duplicate log in'}
                    modalActive={isLoggedOut}
                    setModalActive={setIsLoggedOut}
                    primaryBtn={{
                        onClick: () => {
                            location.href = '/';
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon="logout-modal-error"
                    contentAlignment="center"
                    testId="notification-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`Your account has been logged in from another device.`}
                    </FlexedDiv>
                </Modal>
            )}
        </Fragment>
    );
};

export default BranchRoute;
