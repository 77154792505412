/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactText, useContext, useRef } from 'react';
import { useState } from 'react';
import { useComponentLoader } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL } from '../../constants';
import { IDailySummaryState } from '../../pages';
import {
    dateFilterBranch,
    filterSelectStatesBranch,
    initialDateFilterBranch,
    initialFilterSelectStatesBranch,
} from '../../pages/DashboardBranch/dashboardBranchTypes';
import { makeBatchTimeTags } from '../../pages/DashboardBranch/helpers/makeBatchTimeTags';
import { otherTabsQuery } from '../../_graphql/queries/branchCE/transaction/otherTabs/otherTabsQuery';
import { dailySummaryCount } from '../../_graphql/queries/branchCE/transaction/dailySummaryCount/dailySummaryCount';
import { formatTextCase, toTitleCase } from '../../utils';
import AuthContext from '../AuthContext';
import DashboardBranchContext from './DashboardBranchContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import moment from 'moment';
import dailyQueryV2 from '../../_graphql/queries/branchCE/transaction/daily/dailyQueryv2';
import { decoupleGroupedTrx, getGroupedTrx } from '../../pages/DashboardBranch/decoupleGroupedTrx';
import { allDailyQueryV2 } from '../../_graphql/queries/branchCE/transaction/daily/allDailyQuery';
import { allOtherTabsQuery } from '../../_graphql/queries/branchCE/transaction/otherTabs/allOtherTabsQuery';
import { ceCrQuery } from '../../_graphql/queries/branchCE/transaction/ceCrQuery';
import { allCrQuery } from '../../_graphql/queries/branchCE/transaction/allCrQuery';

interface DashboardBranchProviderProps {
    children: React.ReactNode;
}

type IFromInbox = {
    currentBranchId: string;
    status: boolean;
};
export const DashboardBranchProvider: React.FC<DashboardBranchProviderProps> = ({
    children,
}: DashboardBranchProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const branchId = sessionStorage.getItem('branchId');
    const initialDailyFilter: ISort_FilterInput = [
        { column: 'fundCategory', value: 'MONEY MARKET' },
        // { column: 'batchTime', value: '' },
        // { column: 'transactionType', value: '' },
        // { column: 'submittedOn', value: '' },
        // { column: 'fundType', value: '' },
        // { column: 'paymentMethod', value: '' },
        // { column: 'accountType', value: '' },
        { column: 'orderType', value: 'Transaction' },
    ];
    const initialOtherTabFilter: ISort_FilterInput = [
        // { column: 'transactionType', value: 'Sales-AO' },
        { column: 'orderType', value: 'Transaction' },
        // { column: 'submittedOn', value: '' },
        // { column: 'fundType', value: '' },
        // { column: 'paymentMethod', value: '' },
        // { column: 'accountType', value: '' },
    ];

    const initialPillFundType: IDashboardFilterTagsGroup = {
        tab: 99,
        label: 'Fund Types:',
        tags: [
            { active: true, index: 0, title: 'Money Sighted', testId: 'money-sighted' },
            { active: false, index: 1, title: 'Non-Money Sighted', testId: 'nonmoney-sighted' },
        ],
    };
    const initialPillOrderType: IDashboardFilterTagsGroup = {
        tab: 0,
        label: 'Order Types:',
        tags: [
            { active: true, index: 0, title: 'Transactions', testId: 'transaction' },
            { active: false, index: 1, title: 'Change Request', testId: 'change-request' },
        ],
    };
    // Common States
    const [tab, setTab] = useState(0);
    const [tabsCount, setTabsCount] = useState<number[]>([]);
    const [pages, setPages] = useState<number>(1); //Pagination States
    const [page, setPage] = useState<number>(1); //Pagination States
    const [resultLimit, setResultLimit] = useState<number>(10); //Pagination States
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false); //Result Limit Disable state
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'submittedOn', value: 'ascending' }]);
    const [searchbarFilterTags, setSearchbarFilterTags] = useState<Array<ISelectValue>>([]); // The filter tags to show when the dropdown filter is disabled

    // Daily Tab States
    const [dailyV2, setDailyV2] = useState<TGroupingInfo[]>([]);
    const [batchCutOffCount, setBatchCutOffCount] = useState<IBatchTimeTagsProps>({ batchCount: [], batchTags: [] }); //batchCutOffCount State
    const [typeCount, setTypeCount] = useState<number[]>([]); //Type Count
    const [orderCount, setOrderCount] = useState<number[]>([]); //OrderTypes Count
    const [cutOffTimePills, setCutOffTimePills] = useState<IDashboardFilterTagsGroup>({
        tab: 0,
        label: LABEL.batchCutOffTime,
        tags: batchCutOffCount.batchTags,
    });
    const [fundTypePills, setFundTypePills] = useState<IDashboardFilterTagsGroup>(initialPillFundType);
    const [orderTypePills, setOrderTypePills] = useState<IDashboardFilterTagsGroup>(initialPillOrderType);
    const [dailySummaryTrxCount, setDailySummaryTrxCount] = useState<IDailySummaryState>({ count: 0, result: [] }); // Count Of Transactions eligible for Daily Summary
    const [enableBulkVerify, setEnableBulkVerify] = useState<boolean>(false); //Enable the bulk verify button in Daily tab
    const [fromInbox, setFromInbox] = useState<IFromInbox>({ currentBranchId: '', status: false });
    const [decoupledGroupLength, setDecoupledGroupLength] = useState<number>(0);
    // Other Tabs Data
    const upcoming = useRef<ITableData[]>([]);
    const hardcopy = useRef<ITableData[]>([]);
    const rerouted = useRef<ITableData[]>([]);
    const history = useRef<ITableData[]>([]);
    const reroutedCr = useRef<TGroupingInfo[]>([]);
    const historyCr = useRef<TGroupingInfo[]>([]);
    //filter options
    const [transactionTypeOptions, setTransactionTypeOptions] = useState<ISelectValue[]>([]);
    const [fundTypeOptions, setFundTypeOptions] = useState<ReactText[]>([]);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState<ReactText[]>([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState<ReactText[]>([]);
    const [branchStatusOptions, setBranchStatusOptions] = useState<ReactText[]>([]);
    const [branchRiskOptions, setBranchRiskOptions] = useState<ReactText[]>([]);
    const [branchBatchCutoffOptions, setBranchBatchCutoffOptions] = useState<ReactText[]>([]);
    // Filter States
    const [dateFilter, setDateFilter] = useState<dateFilterBranch>(initialDateFilterBranch); //Data range + type filter
    const [tempFilters, setTempFilters] = useState<ISort_FilterInput>(initialDailyFilter); // Temp Filters
    const [filterSelectStates, setFilterSelectStates] = useState<filterSelectStatesBranch>(
        initialFilterSelectStatesBranch,
    ); // Initial Filter Values
    const dailyInitialFilter = initialDailyFilter;
    // const dailyInitialFilter = fromInbox.status ? dailyPendingFilter : initialDailyFilter;
    const [filterInputDaily, setFilterInputDaily] = useState<ISort_FilterInput>(dailyInitialFilter); // Filters for Daily Tab
    const [filterInput, setFilterInput] = useState<ISort_FilterInput>(initialOtherTabFilter); //Filters for other tabs
    const [filterProductTypes, setFilterProductTypes] = useState<ReactText[]>([]);
    const [filterFundingTypes, setFilterFundingTypes] = useState<ReactText[]>([]);
    const [filterAccountTypes, setFilterAccountTypes] = useState<ReactText[]>([]);
    const [filterStatusTypes, setFilterStatusTypes] = useState<ReactText[]>([]);
    const [filterRiskTypes, setFilterRiskTypes] = useState<ReactText[]>([]);
    const [filterBatchCutoffTypes, setFilterBatchCutoffTypes] = useState<ReactText[]>([]);

    const [tempTargetDate, setTempTarget] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const { loadingHandler, loading } = useComponentLoader();

    const getSelectOptionValueFromOption = (label: string): ISelectValue => {
        const _label = label.toLowerCase();
        let labelTitleCase = '';
        let value = '';
        switch (_label) {
            case 'individual':
                value = 'individual';
                labelTitleCase = toTitleCase(label);
                break;
            case 'joint':
                value = 'joint';
                labelTitleCase = toTitleCase(label);
                break;
            case 'both':
                value = '';
                break;
            case 'ut':
                value = 'ut';
                break;
            case 'sales-ao':
                value = 'Sales-AO';
                labelTitleCase = 'Sales-AO';
                break;
            default:
                value = label.toLowerCase();
                labelTitleCase = toTitleCase(label);
                break;
        }
        return {
            label: labelTitleCase !== '' ? labelTitleCase : label,
            value: value,
        };
    };

    //Queries
    /**@async query to fetch Daily tab data */
    const getBranchDashboardDailyV2 = async () => {
        // console.log('filterDaily>>', filterInputDaily);
        loadingHandler();
        const branchFromGlobalState = branchId !== null ? branchId : '';
        const selectBranchId =
            fromInbox !== undefined && fromInbox.status ? fromInbox.currentBranchId : branchFromGlobalState;

        try {
            const response: any = await API.graphql(
                graphqlOperation(dailyQueryV2, {
                    input: {
                        tab: 'daily',
                        page: page,
                        branchId: selectBranchId,
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filterInputDaily.filter((filter) => {
                            if (filter.column === 'fundType') {
                                return (
                                    fundTypeOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else if (filter.column === 'paymentMethod') {
                                return (
                                    paymentMethodOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else if (filter.column === 'status') {
                                return (
                                    branchStatusOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else {
                                return true;
                            }
                        }),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.branchDashboardV2;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.branchDashboardV2.data;

            // const grouped = groupTransactionsDaily(result.transactions);
            // const groupedFormatted = formatTextCase(grouped, ['clientName', 'agentName', 'paymentMethod']);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            setFundTypeOptions(result.filters.fundType);
            setBranchStatusOptions(result.filters.branchStatus);
            setTransactionTypeOptions(
                result.filters.transactionType.map((option: string) => {
                    return getSelectOptionValueFromOption(option);
                }),
            );
            setPaymentMethodOptions(result.filters.paymentMethod);
            const decoupledRows = decoupleGroupedTrx(result.groupedTransactions.grouping);
            setDecoupledGroupLength(decoupledRows.length);
            setDisableResultLimit(decoupledRows.length === 0);
            setDailyV2(result.groupedTransactions.grouping);
            // (data)
            setTypeCount(result.typeCount);
            setOrderCount([result.transactionsCount ?? 0, result.changeRequestsCount ?? 0]);
            const temp = makeBatchTimeTags(result.batchCount, cutOffTimePills.tags);
            setBatchCutOffCount(temp);
            setEnableBulkVerify(result.enableBulkVerify);
            setTabsCount([
                result.dailyCount,
                result.upcomingCount,
                result.hardcopyCount,
                result.rerouteCount,
                result.historyCount,
            ]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'branch-error-modal',
            });
        }
    };
    /**@async query to fetch other tabs data */
    const getOtherTabsData = async (tab: string) => {
        const _filter =
            tab.toLowerCase() === 'hardcopy'
                ? filterInput.filter((ele) => {
                      return ele.column !== 'transactionType' && ele.column !== 'orderType';
                  })
                : filterInput;
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(otherTabsQuery, {
                    input: {
                        tab: tab,
                        page: page,
                        branchId: branchId !== null ? branchId : '',
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: _filter.filter((filter) => {
                            if (filter.column === 'fundType') {
                                return (
                                    fundTypeOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else if (filter.column === 'paymentMethod') {
                                return (
                                    paymentMethodOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else {
                                return true;
                            }
                        }),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.branchDashboardV2;
            // console.log('otherTabsQuery RESPONSE>>', resultCheck);
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.branchDashboardV2.data;
            setOrderCount([result.transactionsCount ?? 0, result.changeRequestsCount ?? 0]);
            // console.log('reroutes', result);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            setFundTypeOptions(result.filters.fundType);
            setBranchStatusOptions(result.filters.branchStatus);
            setPaymentMethodOptions(result.filters.paymentMethod);
            const formattedResult = formatTextCase(result.transactions, ['clientName', 'agentName', 'paymentMethod']);
            switch (tab) {
                case 'upcoming':
                    upcoming.current = formattedResult;
                    break;
                case 'hardcopy':
                    hardcopy.current = formattedResult;
                    break;
                case 'reroute':
                    rerouted.current = formattedResult;
                    break;
                case 'history':
                    history.current = formattedResult;
                    break;
            }
            setDisableResultLimit(formattedResult.length === 0);
            setTabsCount([
                result.dailyCount,
                result.upcomingCount,
                result.hardcopyCount,
                result.rerouteCount,
                result.historyCount,
            ]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'branch-error-modal',
            });
        }
    };
    const getDailySummaryCount = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(dailySummaryCount, {
                    input: {
                        branchId: branchId !== null ? branchId : '',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dailySummaryCount;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            setDailySummaryTrxCount({ count: parseInt(result.count), result: result.result });
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Daily Count',
                testId: 'branch-error-modal',
            });
        }
    };
    /**@async query to fetch All Daily tab data */
    const getAllDailyV2 = async () => {
        loadingHandler();
        // const branchFromGlobalState = branchId !== null ? branchId : '';
        // const selectBranchId =
        //     fromInbox !== undefined && fromInbox.status ? fromInbox.currentBranchId : branchFromGlobalState;

        try {
            const response: any = await API.graphql(
                graphqlOperation(allDailyQueryV2, {
                    input: {
                        tab: 'daily',
                        page: page,
                        // branchId: selectBranchId,
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filterInputDaily.filter((filter) => {
                            if (filter.column === 'fundType') {
                                return (
                                    fundTypeOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else if (filter.column === 'paymentMethod') {
                                return (
                                    paymentMethodOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else {
                                return true;
                            }
                        }),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.allSubmittedDashboard;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.allSubmittedDashboard.data;
            // const grouped = groupTransactionsDaily(result.transactions);
            // const groupedFormatted = formatTextCase(grouped, ['clientName', 'agentName', 'paymentMethod']);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            setFundTypeOptions(result.filters.fundType);
            setBranchStatusOptions(result.filters.branchStatus);
            setTransactionTypeOptions(
                result.filters.transactionType.map((option: string) => {
                    return getSelectOptionValueFromOption(option);
                }),
            );
            setPaymentMethodOptions(result.filters.paymentMethod);
            const decoupledRows = decoupleGroupedTrx(result.groupedTransactions.grouping);
            setDecoupledGroupLength(decoupledRows.length);
            setDisableResultLimit(decoupledRows.length === 0);
            setDailyV2(result.groupedTransactions.grouping);
            // (data)
            setTypeCount(result.typeCount);
            setOrderCount([result.transactionsCount ?? 0, result.changeRequestsCount ?? 0]);
            const temp = makeBatchTimeTags(result.batchCount, cutOffTimePills.tags);
            setBatchCutOffCount(temp);
            setEnableBulkVerify(result.enableBulkVerify);
            setTabsCount([
                result.dailyCount,
                result.upcomingCount,
                result.hardcopyCount,
                result.rerouteCount,
                result.historyCount,
            ]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'branch-error-modal',
            });
        }
    };
    /**@async query to fetch All other tabs data */
    const getAllOtherTabsData = async (tab: string) => {
        // console.log('getAllOtherTabsData>>>>');
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allOtherTabsQuery, {
                    input: {
                        tab: tab,
                        page: page,
                        // branchId: branchId !== null ? branchId : '',
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filterInput.filter((filter) => {
                            if (filter.column === 'fundType') {
                                return (
                                    fundTypeOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else if (filter.column === 'paymentMethod') {
                                return (
                                    paymentMethodOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else {
                                return true;
                            }
                        }),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.allSubmittedDashboard;
            // console.log('otherTabsQuery RESPONSE>>', resultCheck);
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.allSubmittedDashboard.data;
            // console.log('reroutes', result);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            setFundTypeOptions(result.filters.fundType);
            setBranchStatusOptions(result.filters.branchStatus);
            setTransactionTypeOptions(
                result.filters.transactionType.map((option: string) => {
                    return getSelectOptionValueFromOption(option);
                }),
            );
            setPaymentMethodOptions(result.filters.paymentMethod);
            const formattedResult = formatTextCase(result.transactions, ['clientName', 'agentName', 'paymentMethod']);
            switch (tab) {
                case 'upcoming':
                    upcoming.current = formattedResult;
                    break;
                case 'hardcopy':
                    // console.log('formattedResult', formattedResult);
                    hardcopy.current = formattedResult;
                    break;
                case 'reroute':
                    rerouted.current = formattedResult;
                    break;
                case 'history':
                    history.current = formattedResult;
                    break;
            }
            setDisableResultLimit(formattedResult.length === 0);
            setTabsCount([
                result.dailyCount,
                result.upcomingCount,
                result.hardcopyCount,
                result.rerouteCount,
                result.historyCount,
            ]);
            setOrderCount([result.transactionsCount ?? 0, result.changeRequestsCount ?? 0]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'branch-error-modal',
            });
        }
    };

    const getCrOrders = async (tab: string) => {
        loadingHandler();
        const _filter = tab.toLowerCase() === 'daily' ? filterInputDaily : filterInput;
        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCrQuery, {
                    input: {
                        tab: tab,
                        page: page,
                        branchId: branchId !== null ? branchId : '',
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: _filter.filter((filter) => {
                            if (filter.column === 'status') {
                                return (
                                    branchStatusOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else {
                                return true;
                            }
                        }),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.branchDashboardV2;
            // console.log('otherTabsQuery RESPONSE>>', resultCheck);
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.branchDashboardV2.data;
            setOrderCount([result.transactionsCount ?? 0, result.changeRequestsCount ?? 0]);
            // console.log('reroutes', result);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            setFundTypeOptions(result.filters.fundType);
            setPaymentMethodOptions(result.filters.paymentMethod);
            setBranchRiskOptions(result.filters.riskCategory);
            const formattedResult = formatTextCase(result.transactions, ['clientName', 'agentName', 'paymentMethod']);
            switch (tab) {
                case 'daily':
                    const _daily = getGroupedTrx(formattedResult, 'orderNo');
                    setDailyV2(_daily);
                    break;
                case 'reroute':
                    rerouted.current = formattedResult;
                    break;
                case 'history':
                    history.current = formattedResult;
                    break;
            }
            setDisableResultLimit(formattedResult.length === 0);
            setTabsCount([
                result.dailyCount,
                result.upcomingCount,
                result.hardcopyCount,
                result.rerouteCount,
                result.historyCount,
            ]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'branch-error-modal',
            });
        }
    };
    const getAllCrOrders = async (tab: string) => {
        loadingHandler();
        const _filter = tab.toLowerCase() === 'daily' ? filterInputDaily : filterInput;
        try {
            const response: any = await API.graphql(
                graphqlOperation(allCrQuery, {
                    input: {
                        tab: tab,
                        page: page,
                        branchId: branchId !== null ? branchId : '',
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: _filter.filter((filter) => {
                            if (filter.column === 'status') {
                                return (
                                    branchStatusOptions.findIndex(
                                        (option) => option.toString().toLowerCase() === filter.value.toLowerCase(),
                                    ) !== -1
                                );
                            } else {
                                return true;
                            }
                        }),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.allSubmittedDashboard;
            // console.log('otherTabsQuery RESPONSE>>', resultCheck);
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.allSubmittedDashboard.data;
            setOrderCount([result.transactionsCount ?? 0, result.changeRequestsCount ?? 0]);
            // console.log('reroutes', result);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            setFundTypeOptions(result.filters.fundType);
            setBranchStatusOptions(result.filters.branchStatus);
            setPaymentMethodOptions(result.filters.paymentMethod);
            setBranchRiskOptions(result.filters.riskCategory);
            // const formattedResult = formatTextCase(result.transactions, ['clientName', 'agentName', 'paymentMethod']);
            const decoupledRows = decoupleGroupedTrx(result.groupedTransactionsCr.grouping);
            setDecoupledGroupLength(decoupledRows.length);
            setDisableResultLimit(decoupledRows.length === 0);
            switch (tab) {
                case 'daily':
                    setDailyV2(result.groupedTransactionsCr.grouping);
                    break;
                case 'reroute':
                    reroutedCr.current = result.groupedTransactionsCr.grouping;
                    break;
                case 'history':
                    historyCr.current = result.groupedTransactionsCr.grouping;
                    break;
            }
            setTabsCount([
                result.dailyCount,
                result.upcomingCount,
                result.hardcopyCount,
                result.rerouteCount,
                result.historyCount,
            ]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'branch-error-modal',
            });
        }
    };

    const ProviderValue: DashboardBranchContextProps = {
        batchCutOffCount,
        cutOffTimePills,
        dailySummaryTrxCount,
        dailyV2,
        dateFilter,
        decoupledGroupLength,
        disableResultLimit,
        enableBulkVerify,
        filterFundingTypes,
        filterInput,
        filterInputDaily,
        filterProductTypes,
        filterSelectStates,
        fromInbox,
        fundTypePills,
        getAllDailyV2,
        getAllOtherTabsData,
        getBranchDashboardDailyV2,
        getDailySummaryCount,
        getOtherTabsData,
        hardcopy,
        history,
        loading,
        loadingHandler,
        page,
        pages,
        rerouted,
        resultLimit,
        searchbarFilterTags,
        searchInput,
        setBatchCutOffCount,
        setCutOffTimePills,
        setDateFilter,
        setDisableResultLimit,
        setFilterFundingTypes,
        setFilterInput,
        setFilterInputDaily,
        setFilterProductTypes,
        setFilterSelectStates,
        setFromInbox,
        setFundTypePills,
        setPage,
        setPages,
        setResultLimit,
        setSearchbarFilterTags,
        setSearchInput,
        setSortData,
        setTab,
        setTempFilters,
        setTempTarget,
        sortData,
        tab,
        tabsCount,
        tempFilters,
        tempTargetDate,
        typeCount,
        upcoming,
        transactionTypeOptions,
        fundTypeOptions,
        paymentMethodOptions,
        accountTypeOptions,
        branchStatusOptions,
        filterAccountTypes,
        setFilterAccountTypes,
        orderTypePills,
        setOrderTypePills,
        branchRiskOptions,
        filterStatusTypes,
        setFilterStatusTypes,
        filterBatchCutoffTypes,
        setFilterBatchCutoffTypes,
        filterRiskTypes,
        setFilterRiskTypes,
        branchBatchCutoffOptions,
        setBranchBatchCutoffOptions,
        setBranchRiskOptions,
        orderCount,
        getCrOrders,
        getAllCrOrders,
        historyCr,
        reroutedCr,
    };
    return <DashboardBranchContext.Provider value={ProviderValue}>{children}</DashboardBranchContext.Provider>;
};

export default DashboardBranchProvider;
