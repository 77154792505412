import React, { Fragment, useContext } from 'react';
import { LABEL, EMPTY_STATES } from '../../../constants';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../../routes';
import { subTitleStyle, sh160, sh104, sh152, sh128, sh144, sh38, sh216, sh138 } from '../../../styles';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import CustomItem from './CustomItem';
import { DateHeader } from '../DateHeaderMenu';
import { stringToCamelCase } from '../../../utils';
import { IUpcomingTab } from '../../../utils/permissionTypes';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';

export interface UpcomingProps {
    filters: ISort_FilterInput;
    submittedOnDropdown: string;
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    searchInput: string;
    sortData: ISort_FilterInput;
    upcomingTab: IUpcomingTab;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setSubmittedOnDropdown: (value: string) => void;
    setSortData: (data: ISort_FilterInput) => void;
}

export const Upcoming: React.FC<UpcomingProps> = ({
    sortData,
    upcomingTab,
    filters,
    submittedOnDropdown,
    isFilterApplied,
    isSearchResult,
    searchInput,
    setFilters,
    setSubmittedOnDropdown,
    setSortData,
}: UpcomingProps) => {
    const history = useHistory();
    const { upcoming, dateFilter, setDateFilter } = useContext(DashboardBranchContext);
    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data = {
            transactionRef: item.rawData.transactionRef,
            orderNumber: item.rawData.orderNo,
            tab: 'upcoming',
            endpoint: 'branchDashboard',
            url: history.location.pathname,
        };
        history.push(`${history.location.pathname}/${Routes.orderSummary}`, data);
    };
    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };
    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    submittedOnDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(submittedOnDropdown) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };
    const handleDateFilter = (item: string, value: string) => {
        setSubmittedOnDropdown(item);
        const temp = [...filters];
        let currentFilter = 'submittedOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        temp[1] = tempObj;
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
        setFilters(temp);
    };
    const columnsUpComing: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: sh144,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
        },
        {
            key: [{ key: 'clientName' }, { key: 'clientIdNum', textStyle: subTitleStyle }, { key: 'accountType' }],
            viewStyle: {
                width: sh216,
            },
            title: LABEL.investorName,
            subtitle: LABEL.idNo,
            customItem: true,
            icon: {
                name: handleSortIcon('clientName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('clientName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'clientName' ? 700 : 400,
            },
        },
        {
            key: [
                { key: 'orderNo', textStyle: { fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400 } },
                { key: 'transactionRef', textStyle: subTitleStyle },
            ],

            viewStyle: {
                width: sh128,
                justifyContent: 'center',
            },
            title: LABEL.transNo,
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh152,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: '1rem',
                style: { opacity: '0.2' },
            },
        },
        {
            key: [{ key: 'fundType' }, { key: 'paymentMethod', textStyle: subTitleStyle }],
            viewStyle: {
                width: sh104,
            },
            title: LABEL.products,
            subtitle: LABEL.fundType,
        },
        {
            key: [{ key: 'totalInvestment', textStyle: { fontWeight: 700 } }],
            viewStyle: {
                width: sh160,
            },
            title: LABEL.totalInvestments,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh138,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },

        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: upcomingTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                upcomingTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'upcoming-view-column',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={upcoming.current}
                columns={columnsUpComing}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableUpcoming
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                testId="branchupcoming"
            />
        </Fragment>
    );
};

export default Upcoming;
