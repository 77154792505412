import React, { useState, Fragment, ReactNode } from 'react';
import { Link, Route, useRouteMatch, Switch } from 'react-router-dom';
import { FlexedDiv } from '../..';
import { PreviousPage } from '../../molecules/PreviousPage/PreviousPage';
import styled from 'styled-components';
interface ISelectedTab extends ITabsStyle {
    selected: boolean;
}

export const Tabs: React.FC<ITabsProps> = ({
    backIcon,
    tabTitles,
    tabs,
    title,
    customTitle,
    handleBack,
    handleBackFunction,
    children,
    suffixHelper,
    testId,
    primary,
    wrapperStyle,
    activeTab,
    selectTab,
    links,
    linkState,
}: ITabsProps) => {
    const [customTabs, setCustomTabs] = useState(1);
    const { path } = useRouteMatch();
    const showTab = () => {
        if (tabs !== undefined)
            for (const i in tabs) {
                const selectedTab = activeTab || customTabs;
                if (selectedTab === parseInt(i) + 1) return tabs[i];
            }
    };

    const getTabHeader = (index: number, selected: boolean) => {
        return (
            <DetailsTabOptions
                key={index + 1}
                selected={selected}
                primary={primary}
                onClick={() => (selectTab ? selectTab(index + 1) : setCustomTabs(index + 1))}
                // eslint-disable-next-line react/prop-types
                id={tabTitles && `${tabTitles[index].replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}-tab`}
            >
                {tabTitles && tabTitles[index]}
            </DetailsTabOptions>
        );
    };
    return (
        <Fragment>
            <ContainerTransactionDetails primary={primary} style={wrapperStyle}>
                {title && (
                    <PreviousPage
                        backIcon={backIcon}
                        title={customTitle ? customTitle : title}
                        handleBack={handleBack}
                        suffixHelper={suffixHelper}
                        handleBackFunction={handleBackFunction}
                    />
                )}

                <TabContainer id={`${testId}-container`}>
                    {tabTitles !== undefined ? (
                        <TabDetails primary={primary}>
                            {tabTitles &&
                                tabTitles.map(({}, index) =>
                                    links ? (
                                        <TabLink
                                            to={{
                                                pathname: `${path}${links[index]}`,
                                                state: linkState,
                                            }}
                                        >
                                            {getTabHeader(
                                                index,
                                                links.includes(window.location.pathname.replace(path, ''))
                                                    ? window.location.pathname.replace(path, '') === links[index]
                                                    : index === 0,
                                            )}
                                        </TabLink>
                                    ) : (
                                        getTabHeader(index, customTabs === index + 1)
                                    ),
                                )}
                        </TabDetails>
                    ) : (
                        <div />
                    )}
                    <TabContent>
                        <>
                            {links ? (
                                <>
                                    <Switch>
                                        {tabs &&
                                            tabs.map((Tab: ReactNode, index) => {
                                                return (
                                                    <Route
                                                        path={`${path}${links[index]}`}
                                                        key={`${path}${links[index]}`}
                                                        exact
                                                    >
                                                        {Tab}
                                                    </Route>
                                                );
                                            })}
                                    </Switch>
                                </>
                            ) : (
                                showTab()
                            )}
                            <div>{children}</div>
                        </>
                    </TabContent>
                </TabContainer>
            </ContainerTransactionDetails>
        </Fragment>
    );
};

const TabContainer = styled.div`
    background-color: #fbfbfb;
    border: 1px solid #eaebee;
    border-radius: 8px;
    // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.24);
    height: inherit;
    margin-bottom: 1.5rem;
`;
const TabContent = styled.div`
    padding: 2.5rem 1.5rem;
`;
const ContainerTransactionDetails = styled.div<ITabsStyle>`
    border-radius: 24px;
    margin-bottom: 7rem;
`;

const TabDetails = styled(FlexedDiv)<ITabsStyle>`
    ${(props) => (props.primary ? 'box-shadow: inset 0px -1px 0px #C61230;' : 'border-bottom: 1px solid #eaebee;')}
    align-items: center;
    height: 48px;
`;

const DetailsTabOptions = styled.div<ISelectedTab>`
    height: inherit;
    width: 10.3125rem;
    padding: 15px 1.5rem;
    opacity: ${(props) => (props.primary ? '1' : '0.5')};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    color: ${(props) => (props.primary ? (props.selected ? '#FFFFFF' : `#66798E`) : '#002043')};
    text-transform: capitalize;
    line-height: 1.125rem;
    letter-spacing: -0.444444px;
    ${(props) =>
        props.selected
            ? `    opacity: 1;
    ${
        props.primary
            ? `background: #C61230;
    border-radius: 8px 8px 0px 0px;
    `
            : `border-bottom: 2px solid #c61230;`
    }`
            : ''}
`;

const TabLink = styled(Link)`
    text-decoration: none;
    padding: 0;
    margin: 0;
    width: auto;
    height: 100%;
`;
