import React, { Fragment } from 'react';
import { FlexedDiv, Section, Divider } from '../components';
import { LABEL, TextNavyBlue } from '../constants';
import { IcoMoon } from '../icons';

interface multiSectionProps {
    sections: ISection[] | ISectionWithSubSections[];
    noSectionImage?: string;
    noShowLabel?: boolean;
}
export const MultiSection: React.FC<multiSectionProps> = ({
    sections,
    noSectionImage,
    noShowLabel,
}: multiSectionProps) => {
    return (
        <Fragment>
            {sections &&
                sections.map(({ data, sectionTitle, subSection }, index) => (
                    <Fragment key={`section${index}`}>
                        {data ? (
                            <Section sectionTitle={sectionTitle} data={data} />
                        ) : (
                            <Section sectionTitle={sectionTitle} subSection={subSection} />
                        )}
                        {/* {sections.length !== index + 1 ? <Divider /> : null} */}
                    </Fragment>
                ))}
            {sections.length === 0 && !noShowLabel && (
                <FlexedDiv direction="column" alignItems={'center'} margin={'32px 0 40px 0'}>
                    <IcoMoon name={noSectionImage ?? ''} size={'160px'} />
                    <TextNavyBlue weight="700">{LABEL.noInformation}</TextNavyBlue>
                </FlexedDiv>
            )}
        </Fragment>
    );
};
