import * as Routes from '../../../../routes';
import { IData, IRouteAndData } from './UserActivity';

export const handleRouteRedirect = (action: string, data: IData): IRouteAndData => {
    let updatedData: IData = data;
    let route = Routes.activityLogs;
    let routeAndData: IRouteAndData = { data: data, route: route };
    switch (action) {
        case 'orderSummary-auto-authorizer':
            route = Routes.activityLogsOrderSummary;
            updatedData = { ...updatedData };
            break;
        case 'orderSummary-checker':
            route = Routes.activityLogsOrderSummary;
            updatedData = { ...updatedData };
            break;
        case 'orderSummary-maker':
            route = Routes.activityLogsOrderSummary;
            updatedData = { ...updatedData };
            break;
        case 'rerouteTransaction-maker':
            route = Routes.activityLogsReroute;
            updatedData = { ...updatedData, approvalType: 'reroute', status: 'pending approval' };
            break;
        case 'rerouteTransaction-checker':
        case 'rerouteTransaction-auto-authorizer':
            route = Routes.activityLogsReroute;
            updatedData = { ...updatedData, approvalType: 'reroute', status: 'pending approval' };
            break;
        case 'rejectTransaction-maker':
            route = Routes.activityLogsReject;
            updatedData = { ...updatedData };
            break;
        case 'rejectTransaction-checker':
            route = Routes.activityLogsReject;
            updatedData = { ...updatedData };
            break;
        case 'rejectTransaction-auto-authorizer':
            route = Routes.activityLogsReject;
            updatedData = { ...updatedData };
            break;
        case 'dueDate-auto-authorizer':
            route = Routes.activityLogsDueDate;
            updatedData = { ...updatedData };
            break;
        case 'dueDate-checker':
            route = Routes.activityLogsDueDate;
            updatedData = { ...updatedData };
            break;
        case 'dueDate-maker':
            route = Routes.activityLogsDueDate;
            updatedData = { ...updatedData };
            break;
        case 'upload-masterFund-maker':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'Master Fund' };
            break;
        case 'upload-masterFund-checker':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'Master Fund' };
            break;
        case 'upload-masterFund-auto-authorizer':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'Master Fund' };
            break;
        case 'upload-nav-maker':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'NAV' };
            break;
        case 'upload-nav-checker':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'NAV' };
            break;
        case 'upload-nav-auto-authorizer':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'NAV' };
            break;
        case 'upload-distribution-maker':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'Distribution' };
            break;
        case 'upload-distribution-checker':
            route = Routes.activityLogsFund;
            updatedData = { ...updatedData, title: 'Distribution' };
            break;
        case 'upload-distribution-auto-authorizer':
            updatedData = { ...updatedData, title: 'Distribution' };
            route = Routes.activityLogsFund;
            break;
        case 'upload-bulk-maker':
            route = Routes.activityLogsBulkUpload;
            updatedData = { ...updatedData, maker: true };
            break;
        case 'upload-bulk-checker':
            route = Routes.activityLogsBulkUpload;
            updatedData = { ...updatedData, maker: false };
            break;
        case 'upload-bulk-auto-authorizer':
            route = Routes.activityLogsBulkUpload;
            break;
        case 'userRole-maker':
            route = Routes.activityLogsRoles;
            updatedData = { ...updatedData, approval: true, maker: true };
            break;
        case 'userRole-checker':
            route = Routes.activityLogsRoles;
            updatedData = { ...updatedData, approval: true, maker: false };
            break;
        case 'userRole-auto-authorizer':
            route = Routes.activityLogsRoles;
            updatedData = { ...updatedData, approval: true };
            break;
        case 'userGroup-maker':
            route = Routes.activityLogsGroups;
            updatedData = { ...updatedData, maker: true };
            break;
        case 'userGroup-checker':
            route = Routes.activityLogsGroups;
            updatedData = { ...updatedData, maker: false };
            break;
        case 'userGroup-auto-authorizer':
            route = Routes.activityLogsGroups;
            updatedData = { ...updatedData };
            break;
        case 'user-maker':
            route = Routes.activityLogsUser;
            updatedData = { ...updatedData, maker: true };
            break;
        case 'user-checker':
            route = Routes.activityLogsUser;
            updatedData = { ...updatedData, maker: false };
            break;
        case 'user-auto-authorizer':
            route = Routes.activityLogsUser;
            updatedData = { ...updatedData };
            break;
        case 'bulk-import-maker':
            route = Routes.activityLogsBulkImport;
            updatedData = { ...updatedData, maker: true };
            break;
        case 'bulk-import-checker':
            route = Routes.activityLogsBulkImport;
            updatedData = { ...updatedData, maker: false };
            break;
        case 'bulk-import-auto-authorizer':
            route = Routes.activityLogsBulkImport;
            updatedData = { ...updatedData };
            break;
        case 'adviser-maker':
            route = Routes.activityLogsAdviser;
            updatedData = { ...updatedData };
            break;
        case 'adviser-checker':
            route = Routes.activityLogsAdviser;
            updatedData = { ...updatedData };
            break;
        case 'adviser-auto-authorizer':
            route = Routes.activityLogsAdviser;
            updatedData = { ...updatedData };
            break;
        case 'approveRejectSettings-checker':
        case 'createSettings-maker':
            route = Routes.activityLogsEpfSettings;
            updatedData = { ...updatedData };
            break;
        case 'checkSystemSettings-cron':
            route = Routes.activityLogsEpfSettings;
            updatedData = { ...updatedData, status: 'cron-job' };
            break;
    }
    routeAndData = { data: updatedData, route: route };
    return routeAndData;
};
