/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentProps, CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useComponentLoader } from '../../../customHooks';
import { getHolidaysQuery } from '../../../_graphql/queries/common/getHolidays';
import { DateCell } from '../../atoms/DateCell';
import { DatePickerCalendar } from './DatePickerCalendar';
import { Moment, weekdaysShort } from 'moment';
import { IcoMoon } from '../../../icons';
import { InputValidationErrorText, LABEL } from '../../../constants';
import { CustomButton, FlexedDiv } from '../..';
import { DatePaginator } from './DatePaginator';

import moment from 'moment';
import styled from 'styled-components';
import Container from '../../atoms/Container';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

const format = 'DD/MM/YYYY';
const formatMonth = 'MMM YYYY';
const weekDays = [...weekdaysShort().slice(1), weekdaysShort()[0]];

type divProps = ComponentProps<'div'>;
interface IDatePickerProps extends divProps {
    targetDate?: [Moment | null, Moment | null];
    handleDate?: ([date, date2]: [Moment | null, Moment | null]) => void;
    setTargetDate: (targetDate: [Moment | null, Moment | null]) => void;
    style?: React.CSSProperties;
    popupX?: number;
    popupY?: number;
    placeholder?: string;
    range?: boolean;
    backgroundColor?: string;
    icon?: string;
    iconSize?: string;
    primaryBorderColor?: string;
    disable?: boolean;
    width?: string;
    popupMargin?: string;
    label?: string;
    testId?: string;
    disabledDate?: Moment | null;
    errorMessage?: string;
    setErrorMessage?: (value: string) => void;
    height?: string;
    disableWeekends?: boolean;
    disableHolidays?: boolean;
    disableOldDates?: boolean;
    disableCustomDates?: (current: Moment) => boolean;
}

interface ButtonBaseProps extends divProps {
    onFocusLost?: () => void;
    primaryBorderColor?: string;
    parentRef?: React.MutableRefObject<HTMLDivElement> | undefined;
    disable?: boolean;
    invalid?: boolean;
}

type DatePickerWeekInnerProps = {
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
};

type DatePickerOptionItemProps = {
    selected: boolean;
};

type PlaceholderValueProps = {
    selected: boolean;
};

type ButtonWrapperProps = {
    range: boolean;
};
const FlexCol2Style: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
};

const FlexRow2Style: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
};

const DaysOfWeeks = (date: Moment) => {
    const month = date.format('M');
    const year = date.format('Y');
    const firstDay = moment([year, parseInt(month) - 1, 1]); // date object for cur month first day
    const daysNo = firstDay.daysInMonth(); //number of days in cur month
    const firstWeekDay = moment(firstDay.valueOf()).weekday(); // the day number of first week (first week starts at which day)
    let i: number;
    const loopStart: number = 2 - firstWeekDay;
    const days: number[][] = [];
    let week: number[] = [];
    for (i = loopStart; i <= daysNo; i++) {
        if (i > 0) {
            week.push(i);
        } else week.push(0);
        if (week.length === 7) {
            days.push(week);
            week = [];
        }
    }
    let j: number;
    const k = week.length;
    if (week.length > 0) {
        for (j = 0; j < 7 - k; j++) {
            week.push(0);
        }
        days.push(week);
    }
    return days;
};

const FlexRow2 = (props: divProps): JSX.Element => {
    return (
        <div
            {...props}
            style={{
                ...FlexRow2Style,
                cursor: props.onClick ? 'pointer' : 'auto',
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
};

const FlexCol2 = (props: divProps) => (
    <div {...props} style={{ ...FlexCol2Style, ...props.style }}>
        {props.children}
    </div>
);

const FlexSpacer = () => <div style={{ flexGrow: 1 }} />;

export const DatePicker = (props: IDatePickerProps): JSX.Element => {
    const {
        targetDate,
        handleDate,
        setTargetDate,
        popupX,
        popupY,
        placeholder,
        range,
        icon,
        iconSize,
        disable,
        popupMargin,
        label,
        errorMessage,
        height,
        setErrorMessage,
        disableWeekends,
        disableHolidays,
        disableOldDates,
        disableCustomDates,
    } = props;
    const rangeMode = range === true ? true : false;

    const [show, setShow] = useState(false);
    const [step, setStep] = useState(0);

    const [displayDate, setDisplayDate] = useState(
        targetDate && targetDate[0] != null ? targetDate[0].clone() : moment().clone(),
    );
    const [holidays, setHolidays] = useState<[string]>(['']);

    const [selectedOption, setSelectedOption] = useState<number>(0);
    const [tempFromDate, setTempFromDate] = useState<Moment>();
    const days = DaysOfWeeks(displayDate);
    const curMonth = parseInt(displayDate.format('M')) - 1;
    const curYear = parseInt(displayDate.format('Y'));
    const nextMonthDays = DaysOfWeeks(displayDate.clone().add(1, 'months'));
    const today = moment().clone();
    const _icon = icon || 'calendar';
    const _iconSize = iconSize || '1.5rem';

    const DatePickerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const _errorHandler = useContext(ErrorHandlingContext);
    const { loadingHandler } = useComponentLoader();
    const options = [
        { label: 'Today', value: 1 },
        { label: 'Yesterday', value: 2 },
        { label: 'Last 7 days', value: 3 },
        { label: 'Last 30 Days', value: 4 },
        { label: 'This Month', value: 5 },
        { label: 'Last Year', value: 6 },
        { label: 'Custom', value: 7 },
    ];
    const getHolidays = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(getHolidaysQuery, {
                    input: {},
                }),
            );
            const { result, error } = await response.data.getHolidays.data;
            if (error != null) throw error;
            setHolidays(result.holidays);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            _errorHandler.handleErrorHandler();
            _errorHandler.setErrorMessage({
                ..._errorHandler.errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Holidays List',
                testId: 'edit-utmc-setting-error-modal',
            });
        }
    };

    const handleDateChange = (date: Moment) => {
        if (step === 0) {
            setTargetDate([date.clone(), null]);
            setTempFromDate(date.clone());
            if (rangeMode) {
                setStep(1);
            } else {
                setShow(false);
            }
            return;
        }
        if (step === 1) {
            setTargetDate([tempFromDate ? tempFromDate : null, date.clone().endOf('day')]);
            setStep(0);
            setSelectedOption(7);
            !rangeMode ? setShow(false) : null;
        }
    };
    const handleDisplayDate = (value: Moment) => {
        setDisplayDate(value.clone());
    };
    // Fn to highlight selected date cells
    const getHighlight = (curDay: Moment): number => {
        if (!rangeMode) {
            return 0;
        }
        if (targetDate && !targetDate[0]) {
            return 0;
        }
        if (targetDate && !targetDate[1]) {
            return 0;
        }
        if (
            moment(curDay).isSame(targetDate ? targetDate[0] : null, 'day') &&
            moment(curDay).isSame(targetDate ? targetDate[1] : null, 'day')
        ) {
            return 4;
        }

        if (moment(curDay).isSame(targetDate ? targetDate[0] : null, 'day')) {
            if (targetDate && targetDate && targetDate[0] && targetDate[1] && targetDate[0] > targetDate[1]) return 6;
            return 1;
        }
        if (moment(curDay).isSame(targetDate ? targetDate[1] : null, 'day')) {
            if (targetDate && targetDate && targetDate[0] && targetDate[1] && targetDate[0] > targetDate[1]) return 5;
            return 2;
        }
        return moment(curDay).isBetween(targetDate ? targetDate[0] : null, targetDate ? targetDate[1] : null, 'day')
            ? 3
            : 0;
    };
    // Fn to handle closing of picker when clicked outside
    const handleOutsideClick = (event: MouseEvent): void => {
        event.preventDefault();
        if (DatePickerRef.current?.contains(event.target as Node)) {
            // inside click
            return;
        }
        setShow(false);
        // outside click
    };

    // Fn to handle applying of date range filter
    const handleApply = () => {
        const _date = targetDate !== undefined ? targetDate[0] : null;
        const _date2 = targetDate !== undefined ? targetDate[1] : null;
        handleDate && handleDate([_date, _date2]);
        setShow(false);
    };
    // Fn to handle cancel of date range filter
    const handleCancel = () => {
        setTargetDate([null, null]);
        setErrorMessage && setErrorMessage('');
        setShow(false);
    };
    // Fn to handle quick options
    const handlePickerOptions = (value: number) => {
        const _dateFrom = moment();
        const _dateTo = moment(_dateFrom);
        switch (value) {
            case 1:
                setTargetDate([_dateTo.startOf('day'), _dateFrom.endOf('day')]);
                break;
            case 2:
                setTargetDate([_dateTo.subtract(1, 'days'), _dateFrom]);
                break;
            case 3:
                setTargetDate([_dateTo.subtract(7, 'days'), _dateFrom]);
                setStep(1);
                setStep(0);
                break;
            case 4:
                setTargetDate([_dateTo.subtract(30, 'days'), _dateFrom]);
                setStep(1);
                setStep(0);
                break;
            case 5:
                setTargetDate([_dateTo.startOf('month'), _dateFrom]);
                setStep(1);
                setStep(0);
                break;
            case 6:
                setTargetDate([
                    _dateTo.subtract(1, 'year').startOf('year'),
                    _dateFrom.subtract(1, 'year').endOf('year'),
                ]);
                setStep(1);
                setStep(0);
                break;
        }
        setSelectedOption(value);
    };
    useEffect(() => {
        if (disableHolidays) getHolidays();
    }, []);
    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [show]);

    return (
        <FlexCol id="date-picker">
            {label && <DatePickerLabel>{label}</DatePickerLabel>}
            <DatePickerInner
                height={height}
                invalid={errorMessage !== undefined && errorMessage !== ''}
                ref={DatePickerRef}
            >
                <ButtonBase
                    onClick={() => {
                        disable === true ? setShow(false) : setShow(!show);
                    }}
                    disable={disable}
                    parentRef={DatePickerRef}
                    invalid={errorMessage !== undefined && errorMessage !== ''}
                >
                    <PlaceholderValue selected={targetDate && targetDate[0] ? true : false}>
                        {targetDate && targetDate[0]
                            ? targetDate[1] && targetDate[0] > targetDate[1]
                                ? targetDate[1].format(format)
                                : targetDate[0].format(format)
                            : placeholder !== undefined
                            ? placeholder
                            : 'Start Date'}
                        {range && targetDate
                            ? targetDate[1]
                                ? `  -  ${
                                      targetDate[0] && targetDate[0] > targetDate[1]
                                          ? targetDate[0].format(format)
                                          : targetDate[1].format(format)
                                  }`
                                : `  -  End Date`
                            : ''}
                    </PlaceholderValue>
                    <FlexSpacer />
                    <IcoMoon name={_icon} size={_iconSize} />
                </ButtonBase>

                {show ? (
                    <DatePickerCalendar
                        id="date-picker-calendar"
                        onClose={() => setShow(false)}
                        popupX={popupX}
                        popupY={popupY}
                        style={{
                            width: rangeMode ? '657px' : '328px',
                            margin: popupMargin ? popupMargin : '0px',
                        }}
                        rangeMode={rangeMode}
                    >
                        {rangeMode ? (
                            <DatePickerOptions id="date-picker-options">
                                {options.map((result, index) => (
                                    <DatePickerOptionItem
                                        key={index}
                                        onClick={() => handlePickerOptions(result.value)}
                                        selected={selectedOption === result.value ? true : false}
                                    >
                                        {result.label}
                                    </DatePickerOptionItem>
                                ))}
                            </DatePickerOptions>
                        ) : null}

                        <FlexCol>
                            <FlexRow2 style={{ alignItems: 'stretch' }}>
                                <FlexCol2 style={{ padding: '20px 24px', width: rangeMode ? '328px' : '360px' }}>
                                    <DatePaginator
                                        date={displayDate}
                                        setDate={handleDisplayDate}
                                        style={{ marginBottom: '32px' }}
                                        rangeMode={rangeMode}
                                        previousMonth={rangeMode}
                                        formatMonth={formatMonth}
                                    />
                                    <DatePickerWeek id="date-picker-week-row">
                                        <DatePickerWeekInner>
                                            {weekDays.map((day, index) => (
                                                <DatePickerDay key={index}>{day}</DatePickerDay>
                                            ))}
                                        </DatePickerWeekInner>
                                    </DatePickerWeek>
                                    <DatePickerDays id="date-picker-days-wrapper">
                                        <FlexCol2 style={{ width: '100%' }}>
                                            {days.map((week, index) => (
                                                <FlexRow2
                                                    id={`date-picker-row-${index}`}
                                                    key={index}
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: index < days.length - 1 ? '16px' : 0,
                                                    }}
                                                >
                                                    {week.map((day, index2) => (
                                                        <DateCell
                                                            key={index2}
                                                            value={day}
                                                            today={moment(today).isSame(
                                                                moment([curYear, curMonth, day]),
                                                                'day',
                                                            )}
                                                            index={index2}
                                                            formattedDate={`${
                                                                day.toString().length === 1 ? '0' + day : day
                                                            }/${
                                                                curMonth.toString().length === 1 && curMonth != 9
                                                                    ? '0' + (curMonth + 1)
                                                                    : curMonth + 1
                                                            }`}
                                                            selected={
                                                                moment(targetDate ? targetDate[0] : null).isSame(
                                                                    moment([curYear, curMonth, day]),
                                                                ) ||
                                                                moment(targetDate ? targetDate[1] : null).isSame(
                                                                    moment([curYear, curMonth, day]),
                                                                )
                                                            }
                                                            onClickHandler={
                                                                day > 0
                                                                    ? () =>
                                                                          handleDateChange(
                                                                              moment([curYear, curMonth, day]),
                                                                          )
                                                                    : undefined
                                                            }
                                                            highlight={getHighlight(moment([curYear, curMonth, day]))}
                                                            disableWeekends={
                                                                disableWeekends == undefined ? false : disableWeekends
                                                            }
                                                            holidays={holidays}
                                                            disableHolidays={
                                                                disableHolidays === true ? disableHolidays : false
                                                            }
                                                            disableOldDates={disableOldDates ? disableOldDates : false}
                                                            isOldDate={moment(today).diff(
                                                                moment([curYear, curMonth, day]),
                                                                'day',
                                                            )}
                                                            disableCustomDates={
                                                                disableCustomDates &&
                                                                disableCustomDates(moment([curYear, curMonth, day]))
                                                            }
                                                        />
                                                    ))}
                                                </FlexRow2>
                                            ))}
                                        </FlexCol2>
                                    </DatePickerDays>
                                </FlexCol2>
                                {rangeMode ? (
                                    <FlexCol2
                                        style={{
                                            padding: '20px 24px',
                                            width: '328px',
                                            borderLeft: '1px solid #CECECE',
                                        }}
                                    >
                                        <DatePaginator
                                            date={displayDate}
                                            setDate={handleDisplayDate}
                                            style={{ marginBottom: '32px' }}
                                            rangeMode={rangeMode}
                                            previousMonth={false}
                                            formatMonth={formatMonth}
                                        />
                                        <DatePickerWeek id="date-picker-week-row">
                                            <DatePickerWeekInner>
                                                {weekDays.map((day, index) => (
                                                    <DatePickerDay key={index}>{day}</DatePickerDay>
                                                ))}
                                            </DatePickerWeekInner>
                                        </DatePickerWeek>
                                        <DatePickerDays id="date-picker-days-wrapper">
                                            <FlexCol2 style={{ width: '100%' }}>
                                                {nextMonthDays.map((week, index) => (
                                                    <FlexRow2
                                                        key={index}
                                                        style={{
                                                            width: '100%',
                                                            marginBottom: index < days.length - 1 ? '16px' : 0,
                                                        }}
                                                    >
                                                        {week.map((day, index2) => (
                                                            <DateCell
                                                                key={index2}
                                                                value={day}
                                                                today={moment(today).isSame(
                                                                    moment([curYear, curMonth + 1, day]),
                                                                    'day',
                                                                )}
                                                                formattedDate={`${
                                                                    day.toString().length === 1 ? '0' + day : day
                                                                }/${
                                                                    curMonth.toString().length === 1 && curMonth != 9
                                                                        ? '0' + (curMonth + 1)
                                                                        : curMonth + 1
                                                                }`}
                                                                index={index2}
                                                                selected={
                                                                    moment(targetDate ? targetDate[1] : null).isSame(
                                                                        moment([curYear, curMonth + 1, day]),
                                                                    ) ||
                                                                    moment(targetDate ? targetDate[0] : null).isSame(
                                                                        moment([curYear, curMonth + 1, day]),
                                                                    )
                                                                }
                                                                onClickHandler={
                                                                    day > 0
                                                                        ? () =>
                                                                              handleDateChange(
                                                                                  moment([curYear, curMonth + 1, day]),
                                                                              )
                                                                        : undefined
                                                                }
                                                                highlight={getHighlight(
                                                                    moment([curYear, curMonth + 1, day]),
                                                                )}
                                                                disableWeekends={
                                                                    disableWeekends == undefined
                                                                        ? false
                                                                        : disableWeekends
                                                                }
                                                                holidays={holidays}
                                                                disableHolidays={
                                                                    disableHolidays === true ? disableHolidays : false
                                                                }
                                                                disableOldDates={
                                                                    disableOldDates ? disableOldDates : false
                                                                }
                                                                isOldDate={moment(today).diff(
                                                                    moment([curYear, curMonth, day]),
                                                                    'day',
                                                                )}
                                                            />
                                                        ))}
                                                    </FlexRow2>
                                                ))}
                                            </FlexCol2>
                                        </DatePickerDays>
                                    </FlexCol2>
                                ) : null}
                            </FlexRow2>

                            <ButtonWrapper range={rangeMode}>
                                <CustomButton
                                    onClick={handleCancel}
                                    style={{
                                        width: !rangeMode ? '9.5rem' : '6.25rem',
                                        padding: '8px 0',
                                        height: '32px',
                                    }}
                                >
                                    {LABEL.cancel}
                                </CustomButton>
                                <CustomButton
                                    primary={true}
                                    onClick={handleApply}
                                    style={{
                                        width: !rangeMode ? '9.5rem' : '6.25rem',
                                        padding: '8px 0',
                                        marginLeft: '8px',
                                        height: '32px',
                                    }}
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </ButtonWrapper>
                        </FlexCol>
                    </DatePickerCalendar>
                ) : null}
            </DatePickerInner>
            {errorMessage !== undefined && errorMessage !== '' && !show ? (
                <Container visibility width="90%">
                    <FlexedDiv style={{ position: 'absolute', marginTop: '0.5rem' }}>
                        <IcoMoon name="validation-error" color="#E84C3D" style={{ height: '1rem', width: '1.25rem' }} />
                        <InputValidationErrorText>{errorMessage}</InputValidationErrorText>
                    </FlexedDiv>
                </Container>
            ) : null}
        </FlexCol>
    );
};

const ButtonBase = (props: ButtonBaseProps) => {
    const ButtonRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.onClick && props.onClick(e);
    };
    return (
        <ButtonBaseElm
            ref={ButtonRef}
            onClick={(e: any) => handleClick(e)}
            disabled={props.disable}
            invalid={props.invalid}
        >
            {props.children}
        </ButtonBaseElm>
    );
};

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const PlaceholderValue = styled.span<PlaceholderValueProps>`
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => (props.selected ? '#171717' : '#878787')};
    user-select: none;
`;

const DatePickerOptions = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    margin: 0px;
    border-right: 1px solid rgb(206, 206, 206);
    min-width: 135px;
    padding: 1.5rem 0px;
`;
const DatePickerOptionItem = styled.li<DatePickerOptionItemProps>`
    font-size: 0.75rem;
    font-weight: ${(props) => (props.selected ? '700' : '400')};
    padding: 0.8rem 1.5rem;
    background-color: ${(props) => (props.selected ? '#F4F4F4' : '#fff')};
`;
const DatePickerInner = styled.div<IOnPropStyles>`
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: ${(props) => (props.height ? props.height : '48px')};
    border-radius: 32px;
    border: ${(props) => (props.invalid ? '2px solid #E84C3D' : '1px solid #cecece')};
    box-shadow: 0px 0px 0px 1px #cecece inset;
    min-width: 360px;
`;
const ButtonWrapper = styled.div<ButtonWrapperProps>`
    display: flex;
    width: 100%;
    justify-content: ${(props) => (props.range ? 'flex-end' : 'center')};
    padding: 1rem 1.5rem;
    border-top: 1px solid rgb(206, 206, 206);
`;
const ButtonBaseElm = styled.div<IOnPropStyles>`
    padding: 0rem 1.1rem;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: ${(props) => (props.disabled == true ? '#f4f4f4' : props.invalid ? '#FDF1EF' : '#fff')};
    opacity: ${(props) => (props.disabled == true ? 0.6 : null)};
    border-radius: 32px;
    border: ${(props) => (props.disabled == true ? '1px solid #cecece' : null)};
`;
const DatePickerLabel = styled.label`
    display: block;
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 0.4rem;
`;

const DatePickerDay = styled.div`
    padding: 0 4px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #333333;
    user-select: none;
`;

const DatePickerWeek = styled.div`
    width: 100%;
    margin: 1rem 0px;
    padding: 0 8px;
    box-sizing: border-box;
`;

const DatePickerWeekInner = styled.div<DatePickerWeekInnerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    justify-content: space-between;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;
const DatePickerDays = styled.div`
    display: flex;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
`;
