/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { userBulkUpload } from '../../../_graphql/mutations/systemAdmin/userBulkUpload';
import { getDropDownList } from '../../../_graphql/queries/common/getDropDownList';
import { LABEL } from '../../../constants';
import { CustomButton, FlexedDiv, PreviousPage } from '../../../components';
import { reasonsPageButtons } from '../../../styles';
import { Modal, TableSheet } from '../../../components/organisms';
import { IcoMoon } from '../../../icons';
import styled, { css } from 'styled-components';
import { checkValidUserData, checkFileType, cleanUserColumns } from '../../../utils';
import * as Routes from '../../../routes';
import moment from 'moment';
import XLSX from 'xlsx';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

type UserDataPayload = {
    [key: string]: string;
};

interface IViewImportProps {
    file: File;
    uploadType: string;
}
interface IUserDataPayload {
    data: string;
    document: { fileName: string; fileString: string; fileSize: number; fileType: string };
}
interface temp {
    id: string;
    value: string;
}
const modalMessages = {
    approved: {
        heading: 'Bulk Import User request approved',
        subHeading: '',
        icon: 'bulk-modal-processing',
    },
    pending: {
        heading: 'Bulk Import User request submitted',
        subHeading: 'This request has been submitted and pending for review.',
        icon: 'bulk-modal-approve',
    },
};
// bulk users table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 110,
    secondColWidth: 250,
    thirdColWidth: 42,
};
const ViewImportUsers: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { userManagement } = parsedPermission.hq.permission;
    // states for user permissions
    const [isAutoAuth, setIsAutoAuth] = useState<boolean>(false);
    // states for modals, title and description
    const [showModal, setShowModal] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const history = useHistory();
    // states to generate table
    const [fileType, setFileType] = useState<string>('');
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [rowsMarked, setRowsMarked] = useState(false); // track if single row is marked or not
    const [isProcessing, setIsProcessing] = useState(false);
    const [branches, setBranches] = useState<temp[]>([]);
    const [departments, setDepartments] = useState<temp[]>([]);
    const [groups, setGroups] = useState<temp[]>([]);
    const { state: propsData } = useLocation<IViewImportProps>();

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    // check page permissions
    const checkPermissions = () => {
        if (
            userManagement.userTab.reviewApproval &&
            userManagement.userTab.reviewApproval.canApproveBulkImport === 'auto-authorizer'
        ) {
            setIsAutoAuth(true);
        }
    };
    // set row and column data to states
    const processData = (rawColumns: string[], rawRows: string[]) => {
        let tempCols: any = [];
        let tempCells: any = [];
        tempCols = rawColumns; //get first header row
        tempCells = rawRows; //get rest of the rows

        let columnObj: any = {};
        let cellObj: any = {};
        const columnsOutput: ISheetColumn[] = [];
        const cellRow: ISheetCell[][] = [];

        //separate data to header columns
        tempCols.map((column: string) => {
            const cleanColumn = column.replace(/\n|\r/g, ' ').replace('(Y/N)', ' ').trim();
            columnObj = {
                colName: cleanColumn,
                type: 'string',
            };
            columnsOutput.push(columnObj);
        });

        //separate data to rows
        tempCells.map((row: any[]) => {
            const tempRow: ISheetCell[] = [];

            columnsOutput.map((column, index) => {
                if (index == 0 && typeof row[index] !== 'undefined') {
                    cellObj = {
                        cellValue: row[index].toString().trim(),
                        isValid: checkValidUserData(column.colName, row[index].toString().trim()),
                        isMarked: false,
                        isDelete: false,
                    };
                } else if (row[index] instanceof Date) {
                    const tempDateVal = moment(row[index]).add(1, 'hours').format('DD/MM/YYYY');
                    cellObj = {
                        cellValue: tempDateVal,
                        isValid: checkValidUserData(column.colName, row[index]),
                    };
                } else if (typeof row[index] == 'undefined') {
                    cellObj = {
                        cellValue: '',
                        isValid: false,
                    };
                } else if (column.colName == 'User Group') {
                    cellObj = {
                        cellValue: row[index].toString().trim(),
                        isValid: validateGroupName(row[index]),
                    };
                } else if (column.colName == 'Department') {
                    cellObj = {
                        cellValue: row[index].toString().trim(),
                        isValid: validateDepartmentName(row[index]),
                    };
                } else if (column.colName == 'Home Branch') {
                    cellObj = {
                        cellValue: row[index].toString().trim(),
                        isValid: validateBranchName(row[index]),
                    };
                } else {
                    cellObj = {
                        cellValue: row[index].toString().trim(),
                        isValid: checkValidUserData(column.colName, row[index].toString().trim()),
                    };
                }

                tempRow.push(cellObj);
            });
            cellRow.push(tempRow);
        });

        setColumns(columnsOutput);

        setRows(cellRow);
    };

    // process sheet data according to tab
    const fetchSheetData = async (rawColumns: string[], rawRows: string[]) => {
        processData(rawColumns, rawRows);
        setTimeout(() => {
            setIsProcessing(false);
        }, 0);
    };
    //function to handle marking row as selected
    const markRowOkHandler = (rowIndex: number) => {
        const tempRow = [...rows];

        if (!tempRow[rowIndex][0].isDelete && !tempRow[rowIndex][0].isMarked) {
            tempRow[rowIndex][0].isMarked = true;
        } else {
            tempRow[rowIndex][0].isMarked = false;
        }
        setRows(tempRow);
    };
    //function to handle marking row as deselected
    const markRowDeleteHandler = (rowIndex: number) => {
        const tempRow = [...rows];

        if (tempRow[rowIndex][0].isDelete) {
            tempRow[rowIndex][0].isDelete = false;
            tempRow[rowIndex][0].isMarked = false;
        } else {
            tempRow[rowIndex][0].isDelete = true;
        }

        setRows(tempRow);
    };
    //function to handle marking all rows as selected
    const markAllRowHandler = () => {
        const tempRow = [...rows];

        tempRow.map((row, rowIndex) => {
            // tempRow[rowIndex][0].isMarked = false;
            if (row.some((e) => e.isValid === false)) {
                tempRow[rowIndex][0].isMarked = false;
            } else {
                tempRow[rowIndex][0].isMarked = true;
            }
        });

        setRows(tempRow); //set valid rows to marked
    };
    //function to handle marking all rows as deselected
    const undoAllHandler = () => {
        const tempRow = [...rows];
        tempRow.map((row, rowIndex) => {
            // tempRow[rowIndex][0].isMarked = false;
            if (row.some((e) => e.isValid === false)) {
                tempRow[rowIndex][0].isMarked = false;
            } else {
                tempRow[rowIndex][0].isMarked = false;
                tempRow[rowIndex][0].isDelete = false;
            }
        });
        setRows(tempRow);
    };
    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    const goBackToUploads = () => {
        history.push(Routes.dashboardSystemAdmin);
    };
    //call mutation to upload bulk users
    const uploadUserData = async (userDataPayload: IUserDataPayload) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(userBulkUpload, {
                    input: userDataPayload,
                }),
                idTokenHeader,
            );
            const { error } = response.data.userBulkUpload;

            if (!error) {
                handleShowConfirmation();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.errorList ? error.errorList[0] : error.message,
                    testId: 'viewimport-error-modal',
                    title: 'Import bulk request failed',
                });
                handleErrorHandler();
                setIsSubmitting(false);
                setSubmitDisabled(false);
            }
        } catch (error) {}
    };
    const getUserGroups = async () => {
        try {
            const response: any = await API.graphql(graphqlOperation(getDropDownList, {}), idTokenHeader);
            const { data } = response.data.getDropDownList;
            if (data !== null) {
                setBranches(data.result.branches);
                setDepartments(data.result.departments);
                setGroups(data.result.groups);
            }
        } catch (error) {}
    };
    //convert file to base64
    const toBase64 = (file: File): Promise<any | string> =>
        new Promise<any | string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const onSubmitHandler = async () => {
        setIsSubmitting(true);
        setSubmitDisabled(true);
        const usersImportData: UserDataPayload[] = [];
        const base64File: any | string = await toBase64(propsData.file);

        const userDataPayload: IUserDataPayload = {
            data: '',
            document: {
                fileName: propsData.file.name,
                fileString: base64File.split('base64,').pop(),
                fileSize: propsData.file.size,
                fileType: fileType,
            },
        };

        // covert column text to camelCase
        let cleanedCols: string[] = [];
        cleanedCols = cleanUserColumns(columns);

        //build bulk users data payload
        rows.map((row) => {
            const titleObject: any = {};
            if (row[0].isMarked) {
                cleanedCols.map((colName, colIndex) => {
                    if (
                        colName.toLowerCase() === 'branch_id' ||
                        colName.toLowerCase() === 'department_id' ||
                        colName.toLowerCase() === 'group_id'
                    ) {
                        titleObject[colName] = fetchIdFromData(row[colIndex].cellValue, colName);
                    } else if (colName.toLowerCase() === 'username') {
                        titleObject[colName] = row[colIndex].cellValue;
                        titleObject['sso_id'] = row[colIndex].cellValue;
                    } else titleObject[colName] = row[colIndex].cellValue;
                });

                usersImportData.push(titleObject);
            }
        });

        //check to make sure at least 1 record is marked before calling mutation
        if (usersImportData.length !== 0) {
            modalMessages.approved.subHeading = `${usersImportData.length} users has been updated.`;

            userDataPayload.data = JSON.stringify(usersImportData);
            uploadUserData(userDataPayload);
        } else {
            setSubmitDisabled(false);
        }
    };
    const fetchIdFromData = (data: string, type: string): string | string[] => {
        let temp: string | string[] = data;
        switch (type.toLowerCase()) {
            case 'department_id':
                departments.map((item) => {
                    item.value === data ? (temp = item.id) : 'Invalid';
                });
                break;
            case 'branch_id':
                branches.map((item) => {
                    item.value === data ? (temp = item.id) : '';
                });
                break;
            case 'group_id':
                const _groupIds: string[] = [];
                const _trimGroupNames = data.replace(/^[,\s]+|[,\s]+$/g, '').replace(/\s*,\s*/g, ',');
                const _groupNames = _trimGroupNames.split(',');
                _groupNames.map((name) => {
                    groups.map((item) => {
                        if (item.value.toLowerCase() === name.toLowerCase()) _groupIds.push(item.id);
                    });
                });
                temp = _groupIds;
                break;
        }
        return temp;
    };
    //Validate group names in the csv.
    const validateGroupName = (value: string): boolean => {
        let _isValid = false;
        const temp: string[] = value.split(',');

        temp.map((item, index) => {
            let _itemVal = false;
            _itemVal = groups.some(
                (group) => group.value.toLowerCase().trim() === item.toLowerCase().replace(/\s+/g, ' ').trim(),
            );

            if (index === 0) {
                _isValid = _itemVal;
            } else {
                _isValid = _itemVal && _isValid;
            }
        });

        return _isValid;
    };
    //Validate department names in the csv.
    const validateDepartmentName = (value: string): boolean => {
        let _isValid = false;
        const temp: string[] = value.split(',');

        temp.map((item, index) => {
            let _itemVal = false;
            _itemVal = departments.some(
                (depart) => depart.value.toLowerCase().trim() === item.toLowerCase().replace(/\s+/g, ' ').trim(),
            );

            if (index === 0) {
                _isValid = _itemVal;
            } else {
                _isValid = _itemVal && _isValid;
            }
        });

        return _isValid;
    };
    //Validate branch names in the csv.
    const validateBranchName = (value: string): boolean => {
        let _isValid = false;
        const temp: string[] = value.split(',');

        temp.map((item, index) => {
            let _itemVal = false;
            _itemVal = branches.some(
                (branch) => branch.value.toLowerCase().trim() === item.toLowerCase().replace(/\s+/g, ' ').trim(),
            );

            if (index === 0) {
                _isValid = _itemVal;
            } else {
                _isValid = _itemVal && _isValid;
            }
        });

        return _isValid;
    };
    //Read file using Sheetjs
    const readFile = (file: File) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            const rABS = !!fileReader.readAsBinaryString;

            fileReader.onload = () => {
                const wb = XLSX.read(fileReader.result, {
                    type: rABS ? 'binary' : 'array',
                    raw: true,
                    cellDates: true,
                    dateNF: 'dd/mm/yyyy',
                });
                const wsName = wb.SheetNames[0];
                const ws = wb.Sheets[wsName];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });

                resolve(data);
            };
            if (rABS) fileReader.readAsBinaryString(file);
            else fileReader.readAsArrayBuffer(file);
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((data: any) => {
            let tempCols: string[] = [];
            let tempCells: string[] = [];
            tempCols = data.shift(); //get first header row
            tempCells = data.slice(0); //get rest of the rows

            setIsProcessing(true);
            fetchSheetData(tempCols, tempCells);
        });
    };

    //function to check if one or more rows are marked
    useEffect(() => {
        const areRowsMarked = rows.filter((row) => row[0].isMarked);
        const areRowsEdited = rows.filter((row) => row[0].isDelete || row[0].isMarked);
        areRowsMarked.length > 0 ? setSubmitDisabled(false) : setSubmitDisabled(true);

        areRowsEdited.length > 0 ? setRowsMarked(true) : setRowsMarked(false);
    }, [rows]);

    //Component did mount hook
    useEffect(() => {
        checkPermissions();
        getUserGroups();
    }, []);

    useEffect(() => {
        if (propsData) {
            if (groups.length !== 0) {
                const fType = checkFileType(propsData.file.type);
                setFileType(fType);
                readFile(propsData.file);
            }
        }
    }, [groups]);
    return (
        <Fragment>
            <PreviousPage title={LABEL.bulkImportUsers} />
            <StyledInner>
                <PageDescription>{LABEL.bulkImportUsersDescription}</PageDescription>
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <Fragment>
                        <TableSheet
                            columns={columns}
                            rows={rows}
                            isRowMarked={rowsMarked}
                            handleMarkDelete={markRowDeleteHandler}
                            handleMarkOkay={markRowOkHandler}
                            handleMarkAll={markAllRowHandler}
                            handleUndoAll={undoAllHandler}
                            readyOnly={false}
                            columnWidths={fixedCoLumnlWidth}
                        />
                        <FlexedDiv>
                            <IcoMoon name="upload" size="1rem" />
                            <ReUploadBtn onClick={history.goBack}>{`Reupload CSV`}</ReUploadBtn>
                        </FlexedDiv>
                    </Fragment>
                )}

                <ButtonDiv>
                    <CustomButton style={reasonsPageButtons} onClick={() => history.push(Routes.dashboardSystemAdmin)}>
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary
                        style={reasonsPageButtons}
                        onClick={onSubmitHandler}
                        disabled={submitDisabled || isSubmitting}
                    >
                        {LABEL.submit}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={() => handleShowConfirmation()}
                    title={isAutoAuth ? modalMessages.approved.heading : modalMessages.pending.heading}
                    primaryBtn={{
                        onClick: () => {
                            goBackToUploads();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="viewimport-modal"
                    icon={isAutoAuth ? modalMessages.approved.icon : modalMessages.pending.icon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {isAutoAuth ? modalMessages.approved.subHeading : modalMessages.pending.subHeading}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

const StyledInner = styled.div`
    margin-left: 3.5rem;
`;

const SharedBtnStyles = css`
    border: 0px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 700;
    &:focus {
        outline: none;
    }
`;

const ReUploadBtn = styled.button`
    ${SharedBtnStyles}
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;

export default ViewImportUsers;
