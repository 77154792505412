import gql from 'graphql-tag';

/**
 * @FundUploadMutation @Mutation - Fund data Dashabord
 * @description:  upload masterfund,  navperunit, distribution
 */

const fundUploadMutation = /* GraphQL */ gql`
    mutation fundUpload($input: FundInput) {
        fundUpload(input: $input) {
            data {
                result {
                    message
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default fundUploadMutation;
