/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../..';
import {
    Avatar,
    Banner,
    Collapsible,
    CustomSpacer,
    DatePicker,
    FlexedDiv,
    Modal,
    NestedDropdown,
    NestedDropdownItemsProps,
    PreviousPage,
    TextArea,
} from '../../../../../components';
import { LabeledTitle } from '../../../../../components/molecules/LabeledTitle';
import {
    EDD,
    errorHandlerInitial,
    Fs12BoldPrimaryBlack,
    LABEL,
    SYSTEM_ADMIN,
    TextNavyBlue,
} from '../../../../../constants';
import { TextBold18 } from '../../../../../constants/textStyles';
import { IcoMoon } from '../../../../../icons';
import { MultiSection } from '../../../../../templates';
import { getInitialsByFirstTwo } from '../../../../../utils';
import { ContentContainer } from '../../../../SystemAdmin/AdviserProfile/Profile';
import { newCaseDefinitionQuery } from '../../../../../_graphql/queries/edd/newCaseDefinition/newCaseDefinition';
import { INewCaseDefinitionProps } from '../../eddInitialData';
import { timestampToDate } from '../../../../../utils/timestampToDate';
import { caseCheckQuery } from '../../../../../_graphql/queries/edd/newCaseDefinition/caseCheck';

import styled from 'styled-components';
import moment from 'moment';
import AuthContext from '../../../../../context/AuthContext/AuthContext';
import EDDContext from '../../../../../context/EDDContext/EDDContext';

const initialCaseDef: INewCaseDefinitionProps = {
    targetDate: '',
    eddTrigger: [
        {
            reasonId: '',
            category: '',
        },
    ],
    eddReason: [
        {
            reasonId: '',
            category: '',
            subCategory: [],
        },
    ],
    transactionDetails: {
        utaId: '',
        accountNo: null,
        registrationDate: '',
        servicingAdviserName: '',
        servicingAdviserCode: '',
    },
    investmentSummary: {
        accountFund: '',
        productType: '',
    },
    onboardingReceipt: {
        name: '',
        url: '',
        type: '',
    },
    profile: [
        {
            name: '',
            idNumber: '',
            idType: '',
            idFile: '',
            origin: '',
            accountHolder: '',
            personalDetails: {
                nationality: '',
                monthlyHouseholdIncome: '',
            },
            employmentInformation: {
                occupation: '',
                annualIncome: '',
                nameOfEmployer: '',
            },
            contactDetails: {
                mobileNumber: '',
                email: '',
            },
        },
    ],
};

export const NewCaseForm = (): JSX.Element => {
    //Context
    const {
        reasonValue,
        setReasonValue,
        triggerValue,
        setTriggerValue,
        targetDate,
        setTargetDate,
        otherText,
        setOtherText,
        setEddReason,
        setEddTrigger,
        setEddTriggerJ,
        setEddReasonJ,
        targetDateJ,
        setTargetDateJ,
        reasonValueJ,
        setReasonValueJ,
        triggerValueJ,
        setTriggerValueJ,
        otherTextJ,
        setOtherTextJ,
    } = useContext(EDDContext);
    const history = useHistory();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const location = useLocation();
    const state: any = location.state;

    const [errorHandling, setErrorHandling] = useState<boolean>(false);
    const [dropdownErrorMessage, setDropdownErrorMessage] = useState<string>('');
    const [dropdownErrorMessageJ, setDropdownErrorMessageJ] = useState<string>('');

    const handleErrorHandler = () => {
        setErrorHandling(!errorHandling);
    };
    const [errorMessage, setErrorMessage] = useState<IErrorHandling>(errorHandlerInitial);
    const [newCaseDefinition, setNewCaseDefinitionData] = useState<INewCaseDefinitionProps>({ ...initialCaseDef });

    const [expands, setExpands] = useState([true, false]);
    const updateExpands = (index: number) => {
        const exp = [...expands];
        if (index === -1) {
            if (!exp[1]) {
                setExpands([true, true]);
            } else {
                setExpands([true, false]);
            }

            return;
        }
        exp[index] = !exp[index];
        setExpands(exp);
    };

    const expandCheck = !expands[0] || !expands[1];

    const handleDate = (date: moment.Moment | null, i: number) => {
        i == 0 ? setTargetDate(date) : setTargetDateJ(date);
    };

    const runNewCaseDefinition = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(newCaseDefinitionQuery, {
                    input: {
                        utaId: state.utaId,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.newCaseDefinition;
            if (error !== null) throw error;
            if (data) {
                data.result.eddReason.push({
                    reasonId: '0',
                    category: 'Others',
                    subCategory: [],
                });
                setNewCaseDefinitionData(data.result);
                setTargetDate(moment(parseInt(data.result.targetDate)));
                setTargetDateJ(moment(parseInt(data.result.targetDate)));
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch User Details',
            });
            handleErrorHandler();
        }
    };

    const validateForm = () => {
        const reasonValueCheck = reasonValue === 'Others' ? !otherText.trim().length : !!'';
        const reasonValueJCheck = reasonValueJ === 'Others' ? !otherTextJ.trim().length : !!'';
        return newCaseDefinition.profile.length > 1
            ? !reasonValue ||
                  !triggerValue ||
                  !reasonValueJ ||
                  !triggerValueJ ||
                  reasonValueCheck ||
                  reasonValueJCheck ||
                  dropdownErrorMessage !== '' ||
                  dropdownErrorMessageJ !== ''
            : !reasonValue || !triggerValue || reasonValueCheck || dropdownErrorMessage !== '';
    };
    const redirectToQuestionTemplate = async () => {
        try {
            let reasonId, reasonIdJ;
            await newCaseDefinition.eddReason.map(async (item) => {
                if (item.category === reasonValue) {
                    reasonId = item.reasonId;
                    await setEddReason(item);
                }
                if (state.isJoinAccount && item.category === reasonValueJ) {
                    reasonIdJ = item.reasonId;
                    await setEddReasonJ(item);
                }
                item.subCategory.map(async (subItem) => {
                    if (subItem[1] === reasonValue) {
                        reasonId = subItem[0];

                        await setEddReason(item);
                    }
                    if (subItem[1] === reasonValueJ) {
                        reasonIdJ = subItem[0];
                        await setEddReasonJ(item);
                    }
                });
                return;
            });
            let triggerItem, triggerItemJ;
            await newCaseDefinition.eddTrigger.map(async (item) => {
                if (item.category === triggerValue) {
                    triggerItem = item;
                    await setEddTrigger(item);
                }
                if (state.isJoinAccount && item.category === triggerValueJ) {
                    triggerItemJ = item;
                    await setEddTriggerJ(item);
                }
                return;
            });
            history.push(ROUTES.questionTemplate, {
                eddReasonId: reasonId,
                eddReasonIdJ: reasonIdJ,
                newCaseDefinition,
                utaId: state.utaId,
                isJointAccount: state.isJoinAccount,
                eddTrigger: triggerItem,
                eddTriggerJ: triggerItemJ,
                targetDate: targetDate ? targetDate.valueOf().toString() : '',
                targetDateJ: targetDateJ ? targetDateJ.valueOf().toString() : '',
                otherText: otherText,
                otherTextJ: otherTextJ,
            });
            return history;
        } catch (e) {
            console.log(e);
        }
    };
    const runCaseCheck = async (value: string, isJointAccount: boolean) => {
        try {
            const triggerData = newCaseDefinition.eddTrigger.filter((trigger) => {
                return value.toLowerCase() === trigger.category.toLowerCase();
            });
            const response: any = await API.graphql(
                graphqlOperation(caseCheckQuery, {
                    input: {
                        utaId: state.utaId,
                        clientIdNum: isJointAccount ? state.jointIdNum : state.clientIdNum,
                        triggerId: triggerData[0].reasonId,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.caseCheck;
            if (error !== null) throw error;
            if (!data.result.status) {
                isJointAccount
                    ? setDropdownErrorMessageJ(data.result.message)
                    : setDropdownErrorMessage(data.result.message);
            } else {
                isJointAccount ? setDropdownErrorMessageJ('') : setDropdownErrorMessage('');
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Can not fetch case check',
            });
            handleErrorHandler();
        }
    };
    const filteredReason = () => {
        const reasons = newCaseDefinition.eddReason.map((reason) => {
            return reason.subCategory.length
                ? {
                      value: reason.category,
                      options: reason.subCategory.length
                          ? reason.subCategory.map((record) => {
                                return { value: record[1] };
                            })
                          : undefined,
                  }
                : { value: reason.category };
        });
        const orderedReasons = ['Client Confirmation', 'Political Exposure', 'AML System Risk Rating', 'Others'];
        const updatedData: NestedDropdownItemsProps[] = [];
        orderedReasons.map((item) => {
            reasons.filter((item2) => {
                if (item === item2.value) updatedData.push(item2);
            });
        });
        return updatedData;
    };
    const filteredTrigger = () => {
        const trigger = newCaseDefinition.eddTrigger.map((trigger) => {
            return { value: trigger.category };
        });
        const orderedTrigger = ['Account Opening', 'Transaction', 'End Of Day', 'Client Confirmation'];
        const updatedData: NestedDropdownItemsProps[] = [];
        orderedTrigger.map((item) => {
            trigger.filter((item2) => {
                if (item === item2.value) updatedData.push(item2);
            });
        });
        return updatedData;
    };
    useEffect(() => {
        runNewCaseDefinition();
        setTriggerValue('');
        setTriggerValueJ('');
        setReasonValueJ('');
        setReasonValue('');
        setOtherText('');
        setOtherTextJ('');
    }, []);
    return (
        <Fragment>
            <PreviousPage title={EDD.LABEL_ADD_EDD} subTitle={LABEL.eddNewCaseFormSubtitle} />
            <FlexCol>
                {newCaseDefinition &&
                    newCaseDefinition.profile.map((item, i) => {
                        return (
                            <Fragment key={i}>
                                <DetailsArea>
                                    <FlexRow style={{ marginBottom: '32px' }}>
                                        <Avatar
                                            customStyles={{
                                                fontSize: '40px',
                                                marginRight: '40px',
                                            }}
                                            backgroundColor="#A85846"
                                            size={120}
                                        >
                                            {getInitialsByFirstTwo(item.name)}
                                        </Avatar>
                                        <FlexCol>
                                            <LabeledTitle
                                                label="Name"
                                                title={item.name}
                                                style={{ marginBottom: '16px', fontWeight: 'bold' }}
                                            />
                                            <FlexRow>
                                                <LabeledTitle
                                                    label={item.idType}
                                                    title={item.idNumber}
                                                    style={{ marginBottom: '16px', fontWeight: 'bold' }}
                                                />
                                                <ProfileLink href={item.idFile} rel="noreferrer" target="_blank">
                                                    <IcoMoon name={'profile'} size="0.8rem" id="dataIconBtn" />
                                                </ProfileLink>
                                            </FlexRow>
                                        </FlexCol>
                                        <FlexRow
                                            style={{
                                                marginLeft: 'auto',
                                                marginTop: 'auto',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                            }}
                                            onClick={() => updateExpands(-1)}
                                        >
                                            <TextNavyBlue size="12px" weight="700" style={{ marginRight: '4px' }}>
                                                {expandCheck ? LABEL.expandAll : LABEL.collapseAll}
                                            </TextNavyBlue>
                                            <IcoMoon name={expandCheck ? LABEL.expand : LABEL.collapse} size="18px" />
                                        </FlexRow>
                                    </FlexRow>

                                    <Collapsible
                                        title={SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_PERSONAL_DETAILS}
                                        isCheckable={false}
                                        expanded={expands[0]}
                                        content={
                                            <ContentContainer>
                                                <MultiSection
                                                    sections={[
                                                        {
                                                            data: [
                                                                {
                                                                    label: EDD.LABEL_Nationality,
                                                                    data: item.personalDetails.nationality || '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Email,
                                                                    data: item.contactDetails
                                                                        ? item.contactDetails.email || '-'
                                                                        : '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Phone,
                                                                    data: item.contactDetails
                                                                        ? item.contactDetails.mobileNumber || '-'
                                                                        : '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Occupation,
                                                                    data: item.employmentInformation
                                                                        ? item.employmentInformation.occupation || '-'
                                                                        : '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Employer_Name,
                                                                    data: item.employmentInformation
                                                                        ? item.employmentInformation.nameOfEmployer ||
                                                                          '-'
                                                                        : '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Annual_Income,
                                                                    data: item.employmentInformation
                                                                        ? item.personalDetails.monthlyHouseholdIncome ||
                                                                          '-'
                                                                        : '-',
                                                                },
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </ContentContainer>
                                        }
                                        handleExpand={() => updateExpands(0)}
                                        noXMargin={true}
                                    />

                                    <Collapsible
                                        title={EDD.LABEL_Account_Summary}
                                        isCheckable={false}
                                        expanded={expands[1]}
                                        content={
                                            <ContentContainer>
                                                <MultiSection
                                                    sections={[
                                                        {
                                                            data: [
                                                                {
                                                                    label: EDD.LABEL_Account_Number,
                                                                    data:
                                                                        newCaseDefinition.transactionDetails
                                                                            .accountNo || '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Account_Created_On,
                                                                    data: timestampToDate(
                                                                        newCaseDefinition.transactionDetails
                                                                            .registrationDate || '-',
                                                                    ) as string,
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Type_Investor,
                                                                    data: 'Onboarding',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Account_Holder,
                                                                    data: 'Onboarding',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Assigned,
                                                                    data:
                                                                        newCaseDefinition.transactionDetails
                                                                            .servicingAdviserName || '-',
                                                                    subText:
                                                                        newCaseDefinition.transactionDetails
                                                                            .servicingAdviserCode,
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Onboarding_Receipts,
                                                                    data:
                                                                        newCaseDefinition.onboardingReceipt.name || '-',
                                                                    dataIcon: 'file',
                                                                    onClickData:
                                                                        newCaseDefinition.onboardingReceipt.url,
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Product_Type,
                                                                    data:
                                                                        newCaseDefinition.investmentSummary
                                                                            .productType || '-',
                                                                },
                                                                {
                                                                    label: EDD.LABEL_Funding_Option,
                                                                    data:
                                                                        newCaseDefinition.investmentSummary
                                                                            .accountFund || '-',
                                                                },
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </ContentContainer>
                                        }
                                        handleExpand={() => updateExpands(1)}
                                        noXMargin={true}
                                    />
                                </DetailsArea>
                                <StyledDivider />
                                <FlexCol
                                    style={{ paddingBottom: `${state.isJoinAccount && i == 0 ? '0rem' : '8rem'}` }}
                                >
                                    <TextBold18 style={{ marginBottom: '1rem' }}>{LABEL.eddReason}</TextBold18>
                                    <FlexWrapper>
                                        <NestedDropdown
                                            placeholder={EDD.LABEL_Select_One}
                                            suffixIcon={<IcoMoon name="caret-down" size="1.2rem" />}
                                            data={filteredTrigger()}
                                            value={item.accountHolder === 'Joint' ? triggerValueJ : triggerValue}
                                            onChange={(value) => {
                                                item.accountHolder === 'Joint'
                                                    ? setTriggerValueJ(value)
                                                    : setTriggerValue(value);
                                                runCaseCheck(value, item.accountHolder === 'Joint');
                                            }}
                                            label={EDD.LABEL_Trigger}
                                            style={{ borderColor: '#CECECE' }}
                                            openStyle={{
                                                borderRadius: '0px 0px 16px 16px',
                                                borderColor: '#002043',
                                            }}
                                            dropDownBoxStyle={{ borderWidth: '2px 2px 0px 2px' }}
                                            isSecondaryBlue={true}
                                            errorMessage={
                                                item.accountHolder === 'Joint'
                                                    ? dropdownErrorMessageJ
                                                    : dropdownErrorMessage
                                            }
                                        />
                                        <NestedDropdown
                                            placeholder={EDD.LABEL_Select_One}
                                            label={EDD.LABEL_Reason}
                                            suffixIcon={<IcoMoon name="caret-down" size="1.2rem" />}
                                            data={filteredReason()}
                                            value={item.accountHolder === 'Joint' ? reasonValueJ : reasonValue}
                                            onChange={(value) =>
                                                item.accountHolder === 'Joint'
                                                    ? setReasonValueJ(value)
                                                    : setReasonValue(value)
                                            }
                                            style={{ marginLeft: '64px', borderColor: '#CECECE' }}
                                            openStyle={{
                                                borderRadius: '0px 0px 16px 16px',
                                                borderColor: '#002043',
                                            }}
                                            dropDownBoxStyle={{ borderWidth: '2px 2px 0px 2px' }}
                                            isSecondaryBlue={true}
                                        />
                                    </FlexWrapper>
                                    <FlexWrapper
                                        style={{ marginTop: '2rem', alignItems: 'flex-start', display: 'block' }}
                                    >
                                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                            {LABEL.dueDate}
                                        </Fs12BoldPrimaryBlack>
                                        <CustomSpacer space={'2px'} />

                                        <InputWrap>
                                            <DatePicker
                                                setTargetDate={handleDate}
                                                targetDate={[moment(parseInt(newCaseDefinition.targetDate)), null]}
                                                range={false}
                                                width="360"
                                                primaryBorderColor="#002043"
                                                disable={true}
                                            />
                                        </InputWrap>

                                        {reasonValue === 'Others' && item.accountHolder === 'Principal' && (
                                            <TextArea
                                                name="otherRemark"
                                                label={LABEL.remarks}
                                                value={i == 0 ? otherText : otherTextJ}
                                                handleInput={(e) => setOtherText(e.currentTarget.value)}
                                                maxLength={255}
                                                style={{ margin: '-99px 0px 0px 435px', width: '360px' }}
                                            />
                                        )}
                                        {reasonValueJ === 'Others' && item.accountHolder === 'Joint' && (
                                            <TextArea
                                                name="otherRemarkJ"
                                                label={LABEL.remarks}
                                                value={i == 0 ? otherText : otherTextJ}
                                                handleInput={(e) => setOtherTextJ(e.currentTarget.value)}
                                                maxLength={255}
                                                style={{ margin: '-99px 0px 0px 435px', width: '360px' }}
                                            />
                                        )}
                                    </FlexWrapper>
                                    {newCaseDefinition.profile.length > 1 && i === 0 ? (
                                        <StyledDivider margin="0rem -0.8rem" />
                                    ) : null}
                                    <EmptySpaceDivider margin="0px 0px 45px 0px" />
                                </FlexCol>
                            </Fragment>
                        );
                    })}
            </FlexCol>
            <Banner
                disabled={validateForm()}
                toggle={true}
                description={
                    newCaseDefinition.profile.length > 1
                        ? newCaseDefinition.profile.map((item) => `${item.name} (${item.idNumber})`)[0] +
                          newCaseDefinition.profile.map((item) => `\n${item.name} (${item.idNumber})`)[1]
                        : newCaseDefinition.profile.map((item) => `${item.name} (${item.idNumber})`)[0]
                }
                title={`${newCaseDefinition.profile.length} ${
                    !state.isJoinAccount ? 'Investor Selected' : 'Investors Selected'
                }`}
                handleClose={() => console.log('handle close')}
                primaryButtonObject={{ handlePrimaryBtn: () => redirectToQuestionTemplate(), label: 'Continue' }}
                secondaryButtonObject={{
                    handleSecondaryBtn: () => history.push(ROUTES.amlaEDDManagement),
                    label: 'Cancel',
                }}
            />
            {errorHandling ? (
                <Modal
                    modalActive={errorHandling}
                    setModalActive={setErrorHandling}
                    title={errorMessage.title}
                    icon={'settings-request-success'}
                    primaryBtn={{
                        onClick: () => {
                            setErrorHandling(false);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    testId="warning"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Subtitle>{`${errorMessage.errorCode} ${errorMessage.message}`}</Subtitle>
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
declare interface StyledDividerProps {
    margin?: string;
}
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const DetailsArea = styled.div`
    background-color: #fbfbfb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 72px;
    border-radius: 16px;
    flex-direction: column;
`;

const StyledDivider = styled.div<StyledDividerProps>`
    height: 1px;
    border: 1px solid #eaebee;
    margin: ${(props) => (props.margin ? props.margin : '2rem -0.8rem')};
`;
const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const EmptySpaceDivider = styled.div<StyledDividerProps>`
    margin: ${(props) => (props.margin ? props.margin : '0px')};
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
    margin-bottom: 2rem;
`;

const ProfileLink = styled.a`
    display: inline-block;
    margin-left: 10px;
    fill: #002043;
    &:hover {
        fill: #1890ff;
    }
`;
