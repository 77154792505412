import React, { Fragment } from 'react';
import { FlexedDiv, Section, Divider } from '../components';
interface multiSectionProps {
    sections: ISection[][];
}
export const BankInformationSection: React.FC<multiSectionProps> = ({ sections }: multiSectionProps) => {
    return (
        <FlexedDiv direction="column">
            {sections.map((item, index) => (
                <Fragment key={index}>
                    {item.length > 0 ? (
                        <Fragment key={`section${index}`}>
                            {index !== 0 ? <Divider /> : null}
                            <FlexedDiv direction="column" style={{ width: '100%' }}>
                                {item.map(({ data, title, titleIcon }, index) => {
                                    return (
                                        <Fragment key={`section${index}`}>
                                            <Section titleIcon={titleIcon} title={title} data={data} />
                                            {item.length !== index + 1 ? <Divider /> : null}
                                        </Fragment>
                                    );
                                })}
                            </FlexedDiv>
                        </Fragment>
                    ) : null}
                </Fragment>
            ))}
        </FlexedDiv>
    );
};
