import React, { Fragment, ReactNode } from 'react';
import Container from '../Container';

interface ILoaderProps {
    loading: boolean;
    children: ReactNode;
}

const Loader = ({ loading, children }: ILoaderProps): JSX.Element => {
    return (
        <Fragment>
            {loading ? (
                <Container visibility>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>...loading</div>
                </Container>
            ) : (
                children
            )}
        </Fragment>
    );
};
export default Loader;
