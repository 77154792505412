import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES, LABEL } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { AdvanceTable } from '../../../components';
import { subTitleStyle, sh38, sh144, sh216, sh96, sh239, sh136, sh62 } from '../../../styles';

import * as Routes from '../../../routes';
import ProductSettingsContext from '../../../context/ProductSettingsContext';
import CustomItem from './CustomItem';
import AuthContext from '../../../context/AuthContext';

interface IAllNAVProps {
    allNAVData: ITableData[];
    getTabIndex: () => void;
}

export const AllNav: React.FC<IAllNAVProps> = ({ allNAVData, getTabIndex }: IAllNAVProps) => {
    /** FIX HERE  */
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { navPerUnitTab } = parsedPermission.hq.permission.productSettings;

    const history = useHistory();
    const { sortData, setSortData } = useContext(ProductSettingsContext);

    const handleViewDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeProductSetingsDetails,
            state: {
                type: 'NAV per unit History',
                code: item.rawData.fundId,
                name: item.rawData.fundName,
                tabIndex: getTabIndex(),
            },
        });
    };

    const handleSort = (keyName: string) => {
        const tempSort = { ...sortData };
        const sortType = sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortData.column === keyName) {
            const icon = sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    // const handleStatusFilter = (value: string, elemName: string) => {
    //     switch (elemName) {
    //         case 'fundCurrency':
    //             value !== '' ? setCurrencyFilterLabel(value) : setCurrencyFilterLabel(LABEL.currency);
    //             handleFilterChange(value, 'fundCurrency');
    //             break;
    //     }
    // };
    // const handleFundCurrencyFilterDrop = () => {
    //     return <FundCurrencyFilters />;
    // };

    const allNavCols: ITableColumn[] = [
        {
            icon: {
                name: handleSortIcon('fundAbbr'),
                size: '1rem',
            },
            key: [{ key: 'fundAbbr', textStyle: { fontWeight: sortData.column === 'fundAbbr' ? 700 : 400 } }],
            viewStyle: {
                width: sh62,
            },
            title: LABEL.code,
            titleStyle: {
                fontWeight: sortData.column === 'fundAbbr' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fundAbbr'),
        },
        {
            icon: {
                name: handleSortIcon('fundName'),
                size: '1rem',
            },
            key: [{ key: 'fundName', textStyle: { fontWeight: sortData.column === 'fundName' ? 700 : 400 } }],
            viewStyle: {
                width: sh239,
            },
            title: LABEL.fundName,
            titleStyle: { fontWeight: sortData.column === 'fundName' ? 700 : 400 },
            onPressHeader: () => handleSort('fundName'),
        },
        {
            icon: {
                name: handleSortIcon('issuingHouse'),
                size: '1rem',
            },
            key: [{ key: 'issuingHouse', textStyle: { fontWeight: sortData.column === 'issuingHouse' ? 700 : 400 } }],
            viewStyle: {
                width: sh216,
            },
            title: LABEL.fundUtmc,
            titleStyle: { fontWeight: sortData.column === 'issuingHouse' ? 700 : 400 },
            onPressHeader: () => handleSort('issuingHouse'),
        },
        {
            icon: {
                name: handleSortIcon('fundCurrency'),
                size: '1rem',
            },
            key: [
                { key: 'fundCurrency', textStyle: { fontWeight: sortData.column === 'fundCurrency' ? 700 : 400 } },
                { key: 'fundClass', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.currency,
            titleStyle: { fontWeight: sortData.column === 'fundCurrency' ? 700 : 400 },
            subtitle: 'Fund Class',
            onPressHeader: () => handleSort('fundCurrency'),
        },

        {
            icon: {
                name: handleSortIcon('lastUpdated'),
                size: '1rem',
            },
            key: [{ key: 'lastUpdated' }],
            viewStyle: {
                width: sh144,
            },
            title: LABEL.lastUpdated,
            titleStyle: { fontWeight: sortData.column === 'lastUpdated' ? 700 : 400 },
            onPressHeader: () => handleSort('lastUpdated'),
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('latestNavPerUnit'),
                size: '1rem',
            },
            key: [
                {
                    key: 'latestNavPerUnit',
                    textStyle: { fontWeight: sortData.column === 'latestNavPerUnit' ? 700 : 400 },
                },
                { key: 'latestNavDate', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: sh136,
            },
            title: 'Latest NAV per unit',
            titleStyle: { fontWeight: sortData.column === 'latestNavPerUnit' ? 700 : 400 },
            onPressHeader: () => handleSort('latestNavPerUnit'),
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('previousNavPerUnit'),
                size: '1rem',
            },
            key: [
                {
                    key: 'previousNavPerUnit',
                    textStyle: { fontWeight: sortData.column === 'previousNavPerUnit' ? 700 : 400 },
                },
                { key: 'previousNavDate', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: sh136,
            },
            title: 'Prev. NAV per unit',
            titleStyle: { fontWeight: sortData.column === 'previousNavPerUnit' ? 700 : 400 },
            onPressHeader: () => handleSort('previousNavPerUnit'),
            customItem: true,
        },

        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: navPerUnitTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                navPerUnitTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'navperunit-view-btn',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allNAVData}
                columns={allNavCols}
                onEmptyState={EMPTY_STATES.emptyStateAllNAVPerUnit}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData.column} {...props} />}
            />
        </Fragment>
    );
};
