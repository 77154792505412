import React, { Fragment, useEffect, useRef, useState } from 'react';
import { IcoMoon } from '../../../icons';

import styled from 'styled-components';
import { LABEL } from '../../../constants';

interface ISelectDropdownProps {
    options: Array<ISelectOption>;
    selectedValue: string;
    onChange?: (value: string | number) => void;
    isDisabled?: boolean;
    placeHolder?: string;
    label?: string;
    testId: string;
    fontWeight?: number;
}
type SelectDropdownInnerProps = {
    isExpand: boolean;
    isDisabled: boolean;
};
type PlaceHolderProps = {
    fontWeight?: number;
};
export const SelectDropdown = ({
    isDisabled,
    onChange,
    testId,
    options,
    selectedValue,
    placeHolder,
    label,
    fontWeight,
}: ISelectDropdownProps): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const handleOutsideClick = (event: MouseEvent): void => {
        event.preventDefault();
        if (dropDownRef.current?.contains(event.target as Node)) {
            // inside click
            return;
        } else {
        }
        setShow(false);
        // outside click
    };

    const handleOptionSelection = (value: string | number) => {
        onChange && onChange(value);
    };
    //Fn to render options
    const renderOptions = (selectOptions: Array<ISelectOption>, selectedValue: string): JSX.Element => {
        return (
            <Fragment>
                {selectOptions.map(
                    (result, index) =>
                        result.label && (
                            <ListOptionItem
                                onClick={() => handleOptionSelection(result.value)}
                                key={index}
                                id={result.testId}
                            >
                                <div>
                                    <SelectedValue>{result.label}</SelectedValue>
                                    {result.shortDescription && (
                                        <ShortDescription>Short description here</ShortDescription>
                                    )}
                                </div>
                                {result.value.toLowerCase() === selectedValue.toString().toLowerCase() ? (
                                    <GreenCircle>
                                        <IcoMoon name={'check'} size="0.9rem" color="#fff" />
                                    </GreenCircle>
                                ) : null}
                            </ListOptionItem>
                        ),
                )}
            </Fragment>
        );
    };
    //Fn to render selected option value
    const renderSelectedValue = (selectOptions: Array<ISelectOption>, selectedValue: string): string => {
        const _selectedValue = selectOptions.find(
            (result) => result.value.toString().toLowerCase() === selectedValue.toLowerCase(),
        );

        if (_selectedValue !== undefined) {
            return _selectedValue.label;
        } else {
            return placeHolder
                ? placeHolder
                : selectOptions[0] !== undefined
                ? selectOptions[0].label
                : LABEL.selectOne;
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [show]);

    return (
        <SelectDropDownWrap id={testId}>
            <DropDownLabel>{label}</DropDownLabel>
            <SelectDropdownInner
                className="__control"
                onClick={!isDisabled ? () => setShow(!show) : () => null}
                ref={dropDownRef}
                isExpand={show}
                isDisabled={isDisabled ? isDisabled : false}
            >
                <PlaceholderValue fontWeight={fontWeight}>
                    {renderSelectedValue(options, selectedValue)}
                </PlaceholderValue>
                <IcoMoon name="caret-down" size="21px" />
                {show && options.length !== 0 && <ListOptions>{renderOptions(options, selectedValue)}</ListOptions>}
            </SelectDropdownInner>
        </SelectDropDownWrap>
    );
};

const SelectDropDownWrap = styled.div`
    width: 100%;
`;

const SelectDropdownInner = styled.div<SelectDropdownInnerProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => (props.isDisabled ? '#f4f4f4' : '#fff')};
    border: 1px solid #cecece;
    padding: 0rem 1.1rem;
    border-radius: ${(props) => (props.isExpand ? ' 16px 16px 0 0' : '32px')};
    cursor: pointer;
    height: 48px;
    width: 100%;
    transition: all 0.2s ease;
    box-shadow: ${(props) => (props.isExpand ? '0px 0px 0px 2px #002043 inset' : undefined)};
    // box-shadow: ${(props) => (props.isExpand ? '0px 0px 0px 2px #002043 inset' : '0px 0px 0px 1px #cecece inset')};
    will-change: border-radius, box-shadow;
`;
const DropDownLabel = styled.label`
    display: block;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0.4rem;
`;
const ListOptions = styled.ul`
    padding: 0px;
    list-style: none;
    position: absolute;
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    width: 100%;
    box-shadow: 0px 0px 0px 2px rgb(0 32 67) inset;
    left: 0;
    top: 44px;
    padding: 1rem 0px;
    z-index: 9;
    margin: 0;
    overflow: auto;
    max-height: 280px;
`;
const ListOptionItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 1.1rem;
    margin: 0 2px;
    user-select: none;
    &:hover {
        background-color: #f2f4f6;
    }
`;
const PlaceholderValue = styled.span<PlaceHolderProps>`
    font-size: 1rem;
    font-weight: ${(PlaceHolderProps) => PlaceHolderProps.fontWeight || 400};
    user-select: none;
`;
const SelectedValue = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;
const ShortDescription = styled.div`
    font-size: 10px;
    color: #4d4d4d;
`;
const GreenCircle = styled.div`
    width: 18px;
    height: 18px;
    background-color: #2ecc82;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
`;
