/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Banner, Collapsible, FlexedDiv, Modal } from '../../../../../components';
import { EDD, LABEL } from '../../../../../constants';
import { ROUTES } from '../../../..';
import { QuestionaryTemplate } from '../../../Helpers';
import { ResponseInfo } from '../../../Helpers/common';

import styled from 'styled-components';
import EDDContext from '../../../../../context/EDDContext/EDDContext';

export const Responses: React.FC = () => {
    const { getCaseDetails, caseDetails, setResponseId, amlaRemarks, setAmlaRemarks, isSelected, setIsSelected } =
        useContext(EDDContext);
    const history = useHistory();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [expands, setExpands] = useState([true]);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [a, setA] = useState<boolean>(false);
    const location = useLocation();
    const state: any = location.state;

    useEffect(() => {
        if (state.callFrom === 'rerouteSatisfactory') {
            setIsSelected([]);
            setAmlaRemarks([]);
            delete state.callFrom;
            history.replace({ ...history.location, ...state });
        }
    }, []);
    useEffect(() => {
        validateAmlaRemarks(amlaRemarks);
    }, [a, isSelected]);

    /* Function to expand/close collapsable toggle */
    const updateExpands = async (index: number, responseId?: number) => {
        setResponseId(responseId ? responseId : null);
        await getCaseDetails(responseId ? responseId : null);
        const exp = [...expands];
        if (exp[index] === undefined) {
            exp.map((prevExp, i) => {
                if (prevExp === true) exp[i] = false;
            });
            exp[index] = true;
        } else {
            exp.map((prevExp, i) => {
                // console.log(i === index, i, index);
                if (i === index) exp[i] = !exp[index];
                else if (prevExp === true) exp[i] = false;
            });
        }
        setExpands(exp);
    };

    /* Function to update and validate the remarks of question */
    const handleRemark = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        item: { questionId: string; title: string },
        index: number,
    ) => {
        const remark = e.target.value;
        setAmlaRemarks((prevValue) => {
            const existingRemarks = [...prevValue];
            existingRemarks[index] =
                item.questionId === null
                    ? { title: item.title, remark: remark, questionId: '' }
                    : { questionId: item.questionId, remark: remark, title: '' };
            validateAmlaRemarks(existingRemarks);

            return existingRemarks;
        });
        return;
    };
    /* To handle Questionary Template height */
    const handleTextBox = () => {
        setA(!a);
    };

    const validateAmlaRemarks = (amlaRemarks: any[]) => {
        const processedData = amlaRemarks.map((item) => {
            if (item && item.remark.trim().length) {
                return true;
            } else {
                return false;
            }
        });
        return setIsValidated(processedData.includes(true));
    };

    return (
        <Fragment>
            <DetailsArea>
                <SpaceDivider />
                {caseDetails &&
                    caseDetails.headings.map((heading, i) => {
                        const response = caseDetails.response;
                        if (heading.responseId) {
                            return (
                                <Fragment key={i}>
                                    <PageHeader>
                                        {`Response ${caseDetails.headings.length - i}`}
                                        <Line />
                                    </PageHeader>
                                    {i === 0 ? (
                                        <ParagraphStyle>
                                            <strong style={{ background: '#132B53' }}>{`|`}</strong>&nbsp;&nbsp;
                                            <strong style={{ color: 'black' }}>{EDD.LABEL_AMLA}</strong>
                                        </ParagraphStyle>
                                    ) : (
                                        <ResponseInfo
                                            name={caseDetails.name}
                                            timestamp={caseDetails.headings[i].submittedOn}
                                            status="Read"
                                            badgeStyle={{ color: '#A82252' }}
                                        />
                                    )}
                                    <Collapsible
                                        title={i == 0 ? 'Follow-up Questions' : LABEL.answers}
                                        isCheckable={false}
                                        key={i}
                                        index={i}
                                        expanded={expands[i]}
                                        noXMargin={true}
                                        noMargin={true}
                                        showBoxShadow={true}
                                        handleExpand={() => updateExpands(i, caseDetails.headings[i].responseId)}
                                        content={
                                            <QuestionaryTemplate
                                                type="unsatisfactory"
                                                handleInput={handleRemark}
                                                content={response}
                                                key={i}
                                                onlyRemarks={caseDetails.headings.length - 1 !== i}
                                                status={i == 0 ? caseDetails.status : 'Submitted'}
                                                remark={amlaRemarks}
                                                handleTextBox={handleTextBox}
                                                showConclusion={false}
                                            />
                                        }
                                    />
                                    {i > 0 ? (
                                        <Fragment>
                                            <ResponseInfo
                                                style={{ margin: '12px 12px 10px 28px' }}
                                                name={EDD.LABEL_AMLA}
                                                timestamp={heading.remarkOn}
                                                status="Submitted"
                                                badgeStyle={{ background: '#132B53' }}
                                            />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <ResponseInfo
                                                style={{ margin: '12px 12px 10px 28px' }}
                                                name={caseDetails.name}
                                                timestamp={heading.submittedOn}
                                                status="Read"
                                                badgeStyle={{ color: '#A82252' }}
                                            />
                                        </Fragment>
                                    )}
                                    <SpaceDivider />
                                </Fragment>
                            );
                        }
                    })}
                <SpaceDivider />
            </DetailsArea>
            <FlexCol style={{ margin: '0 -2rem' }}>
                <Banner
                    disabled={!isValidated || !isSelected.includes(true)}
                    toggle={true}
                    title={EDD.LABEL_UNSATISFACTORY_BANNER_TITLE}
                    description={`Add minimum one remark for ${caseDetails.name} (${caseDetails.clientIdNum}) to proceed`}
                    handleClose={() => console.log('handle close')}
                    maxWidth="1152"
                    primaryButtonObject={{
                        handlePrimaryBtn: () =>
                            history.push(ROUTES.rerouteEddCaseUnsatisfactory, { amlaRemarks: amlaRemarks }),
                        label: EDD.BUTTON_CONTINUE,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: () => history.push(ROUTES.amlaEDDManagement),
                        label: EDD.BUTTON_CANCEL,
                    }}
                />
            </FlexCol>
            {showConfirmModal ? (
                <Modal
                    modalActive={showConfirmModal}
                    setModalActive={setShowConfirmModal}
                    title={EDD.LABEL_EDD_MODAL_CONFIRM_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            setShowConfirmModal(false);
                            setShowModal(true);
                        },
                        label: LABEL.yes,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: async () => {
                            setShowConfirmModal(false);
                            history.push(ROUTES.amlaEDDManagement);
                        },
                        label: LABEL.cancel,
                        primary: true,
                        size: 'large',
                    }}
                    testId="custom_modal"
                    contentAlignment="left"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {EDD.LABEL_EDD_MODAL_CONFIRM_TITLE_SUB}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={EDD.LABEL_EDD_MODAL_SATISFACTORY_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            setShowModal(false);
                            history.push(ROUTES.amlaEDDManagement, { tab: 'Closed', refresh: 'refresh' });
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    icon="cancel-request-done"
                    testId="custom_modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <b>{`${caseDetails.caseSummary.caseDetails.caseNo}`}</b> {`${EDD.LABEL_EDD_MODAL_SUB}`}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

declare interface ParagraphStyleProps {
    margin?: string;
}
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const DetailsArea = styled.div`
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 94px;
    flex-direction: column;
`;
const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;
const ParagraphStyle = styled.p<ParagraphStyleProps>`
    display: flex;
    font-size: 12px;
    color: #4d4d4d;
    margin: ${(props) => (props.margin ? props.margin : '0px 32px 10px')};
`;
const Line = styled.span`
    width: 90%;
    height: 0px;
    margin: 9px 0px;
    border: 1px solid #c6cbd4;
`;
const PageHeader = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 16px;
    color: #171717;
    line-height: 148%;
`;
