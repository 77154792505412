/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { PreviousPage } from '../../../../components';
import { TableSheet } from '../../../../components/organisms';
import { expandMasterFundCols, moveItemInArray, expandAMPFundCols } from '../../../../utils';
import styled from 'styled-components';

import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    title: string;
    id: string;
}
declare interface StyledInnerProps {
    paddingBottom: number;
}
type FundData = {
    [key: string]: string;
};
// masterdata table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 260,
    secondColWidth: 156,
    thirdColWidth: 42,
};
export const ReviewImportActivity: React.FC = () => {
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const { state: propsData } = useLocation<IRouteProps>();

    const [bannerHeight] = useState<number>(0);

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //call query to get master fund doc by id
    const getFundUploadDataQuery = async (id: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.getLogData;

            if (!error) {
                if (data.result.data.status === 'FAILED') {
                    const _tempFailData = JSON.parse(data.result.data.fundData);

                    const _rawData: FundData[] = JSON.parse(_tempFailData.data);

                    switch (propsData.title) {
                        case 'Master Fund':
                            processMasterFund(_rawData);
                            break;
                        case 'NAV':
                            processNavdata(_rawData);
                            break;
                        case 'Distribution':
                            processDistributionData(_rawData);
                            break;
                        default:
                    }
                } else {
                    const rawData: FundData[] = JSON.parse(data.result.data.fundData);

                    // process to bind resposnse data to  table
                    switch (propsData.title) {
                        case 'Master Fund':
                            processMasterFund(rawData);
                            break;
                        case 'NAV':
                            processNavdata(rawData);
                            break;
                        case 'Distribution':
                            processDistributionData(rawData);
                            break;
                        default:
                    }
                }
            } else throw error;

            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {
            setErrorMessage({
                ...errorMessage,
                message: error.message,
                errorCode: error.errorCode,
                title: 'Cannot Fetch Import Data',
                testId: 'activitylogs-import-error-modal',
            });
            handleErrorHandler();
        }
    };
    //code to build columns and cells
    const processDistributionData = (rawData: FundData[]) => {
        const tempCols: string[] = [
            'Fund Code',
            'Date',
            'Fund Name',
            'Gross Distribution',
            'Net Distribution',
            'Unit Split / Cancellation',
        ];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};

        //separate data to header columns
        const columnsOutput: ISheetColumn[] = [];
        tempCols.map((column: string) => {
            columnObj = {
                colName: column,
                type: 'string',
            };
            columnsOutput.push(columnObj);
        });

        //separaete data to rows
        const cellRow: ISheetCell[][] = []; // final row of cell objects
        const cellRowMerged: string[][] = [];
        tempCells = rawData.map((row: FundData) => Object.values(row));

        //extract gross, net and unit values from rawdata obj
        tempCells.map((row: Array<any>) => {
            const tempCellValues: any[] = [];
            const innerObjectArray = Object.values(row[4]);
            innerObjectArray.map((elm: any, elmIndex) => {
                for (const [key, value] of Object.entries(elm)) {
                    //build row with all string values
                    if (elmIndex === 0) {
                        tempCellValues.push(row[0], key, row[1], value);
                    } else {
                        tempCellValues.push(value);
                    }
                }
            });
            cellRowMerged.push(tempCellValues);
        });
        //make tempCells to objects and then push to an array
        cellRowMerged.map((row: any[]) => {
            const tempRow: ISheetCell[] = [];
            columnsOutput.map((column, index) => {
                cellObj = {
                    cellValue: row[index],
                    isValid: true,
                };

                tempRow.push(cellObj);
            });
            cellRow.push(tempRow);
        });
        setColumns(columnsOutput);
        setRows(cellRow);
    };
    const processNavdata = (rawData: FundData[]) => {
        //code to build columns and cells
        let tempCols: string[] = [];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};
        tempCols = Object.keys(rawData[0]);
        tempCols.push('date');
        tempCols = expandMasterFundCols(tempCols);

        //separate data to header columns
        let columnsOutput: ISheetColumn[] = [];
        tempCols.map((column: string) => {
            columnObj = {
                colName: column,
                type: 'string',
            };
            columnsOutput.push(columnObj);
        });

        //reshufle the columns
        columnsOutput = moveItemInArray(5, 1, columnsOutput);

        //separaete data to rows
        const cellRow: ISheetCell[][] = [];
        tempCells = rawData.map((row: FundData) => Object.values(row));

        //make tempCells to objects and then push to an array
        tempCells.map((row: any[]) => {
            const tempRow: ISheetCell[] = [];
            columnsOutput.map((column) => {
                switch (column.colName) {
                    case 'TOMS Fund Abbreviation':
                        cellObj = {
                            cellValue: row[0],
                            isValid: true,
                        };
                        break;
                    case 'Date':
                        cellObj = {
                            cellValue: Object.keys(row[4])[0],
                            isValid: true,
                        };
                        break;
                    case 'Fund Name':
                        cellObj = {
                            cellValue: row[1],
                            isValid: true,
                        };
                        break;
                    case 'Fund Class':
                        cellObj = {
                            cellValue: row[2],
                            isValid: true,
                        };
                        break;
                    case 'Fund Class Currency':
                        cellObj = {
                            cellValue: row[3],
                            isValid: true,
                        };
                        break;
                    case 'NAV Per Unit':
                        cellObj = {
                            cellValue: Object.values(row[4])[0],
                            isValid: true,
                        };
                        break;

                    default:
                }

                tempRow.push(cellObj);
            });
            cellRow.push(tempRow);
        });

        setColumns(columnsOutput);
        setRows(cellRow);
    };
    const processMasterFund = (rawData: FundData[]) => {
        //code to build columns and cells
        let tempCols: string[] = [];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};
        let isAmp = false;
        try {
            isAmp = rawData.some(
                (data) => data['tomsFundAbbreviation'] === '' || data['tomsFundAbbreviation'] === null,
            );
            tempCols = Object.keys(rawData[0]);

            // Get masterdata or AMP column names
            isAmp ? (tempCols = expandAMPFundCols(tempCols)) : (tempCols = expandMasterFundCols(tempCols));

            //separate data to header columns
            const columnsOutput: ISheetColumn[] = [];
            if (isAmp) {
                tempCols.map((column: string) => {
                    //dont include fund code and AMP Abbreviation
                    //fixed values from incoming data
                    if (column !== 'Fund Code' && column !== 'AMP Abbreviation') {
                        columnObj = {
                            colName: column,
                            type: 'string',
                        };
                        columnsOutput.push(columnObj);
                    }
                });
            } else {
                tempCols.map((column: string) => {
                    //dont include fund code and TOMS Fund Abbreviation
                    //fixed values from incoming data
                    if (column !== 'Fund Code' && column !== 'TOMS Fund Abbreviation') {
                        columnObj = {
                            colName: column,
                            type: 'string',
                        };
                        columnsOutput.push(columnObj);
                    }
                });
            }

            //separaete data to rows
            const cellRow: ISheetCell[][] = [];
            tempCells = rawData.map((row: FundData) => Object.values(row));

            //remove first two columns from each row and keep them for submission
            tempCells.map((row: any[]) => row.splice(0, 2));

            // setHiddenRows(firstTwoCols);
            //make tempCells to objects and then push to an array
            tempCells.map((row: any[]) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, index) => {
                    cellObj = {
                        cellValue: row[index],
                        isValid: true,
                    };

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };

    useEffect(() => {
        setIsProcessing(true);
        getFundUploadDataQuery(propsData.id);
    }, []);

    return (
        <Fragment>
            <PreviousPage title={`Review Import ${propsData.title} Request`} />
            <StyledInner paddingBottom={bannerHeight}>
                <PageDescription>
                    Please review the following request. You can either approve it or reject it using the buttons in the
                    bottom right corner. You’ll also be able to leave additional remark if you reject it.
                </PageDescription>
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <TableSheet columns={columns} rows={rows} readyOnly={true} columnWidths={fixedCoLumnlWidth} />
                )}
            </StyledInner>
        </Fragment>
    );
};

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 3.5rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;

const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
