import React from 'react';
import { TextWhite } from '../../../constants';
import styled from 'styled-components';

interface IRegularTooltipTemplateProps {
    message: ITooltip[];
    remarksPrefix?: boolean;
}

export const PermissionLevelInfoTemplate = ({ message, remarksPrefix }: IRegularTooltipTemplateProps): JSX.Element => {
    return (
        <div>
            {message &&
                message.map((item, index) => (
                    <ContainerDiv key={index} data-testid="parent_container" style={{ flexDirection: 'column' }}>
                        <div data-testid="message">
                            <TextWhite
                                weight="700"
                                size="12px"
                                style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces', lineHeight: '15.6px' }}
                            >
                                {item.label}
                            </TextWhite>
                        </div>
                        <CustomSpace />
                        {item.remarks !== undefined ? (
                            <div data-testid="remarks">
                                {remarksPrefix === true ? <div>Remarks:&nbsp;</div> : null}
                                <TextWhite size="12px" weight="400" style={{ lineHeight: '15.6px' }}>
                                    {item.remarks}
                                </TextWhite>
                            </div>
                        ) : null}

                        {item.content !== undefined ? (
                            <UL>
                                {item.content.map((content) => {
                                    return <Content key={`remarks${index}_${item.label}${content}`}>{content}</Content>;
                                })}
                            </UL>
                        ) : null}
                    </ContainerDiv>
                ))}
        </div>
    );
};

const Content = styled.li`
    flex-grow: 1;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15.6px;
    word-break: break-word;
    white-space: break-spaces;
`;

const UL = styled.ul`
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
`;
const CustomSpace = styled.div`
    margin-bottom: 3px;
`;
const ContainerDiv = styled.div`
    display: flex;
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
