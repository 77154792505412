import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';

import { CustomSpacer } from '../../../../../components';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { handleUserChange, hasKey } from '../../utils/functions';
import { IFinanceCasesSection, IFinanceDashboardSection } from '../../AddRoles/HqPrivileges/hqRolesInitialData';

export interface FinancePrivilegesProps {
    approval: boolean;
    expanded: boolean;
    financeDashboard: IFinanceDashboardSection;
    setFinanceDashboard: (data: IFinanceDashboardSection) => void;
    financeCases: IFinanceCasesSection;
    setFinanceCases: (data: IFinanceCasesSection) => void;
}

export const FinancePrivileges: FunctionComponent<FinancePrivilegesProps> = ({
    financeDashboard,
    setFinanceDashboard,
}: FinancePrivilegesProps) => {
    // TODO Update with new dropdown
    const [financeDropdown] = useState<ISelectValue>({
        label: 'Maker',
        value: 'maker',
    });

    // const handleFinanceDropdown = (label: string) => {
    //     let temp: ISelectValue = {
    //         label: '',
    //         value: '',
    //     };
    //     switch (label) {
    //         case 'Maker':
    //             temp = { label: label, value: 'maker' };
    //             break;
    //         case 'Checker':
    //             temp = { label: label, value: 'checker' };
    //             break;
    //         case 'Auto-Authorizer':
    //             temp = { label: label, value: 'autoAuthorizer' };
    //             break;
    //     }
    //     setFinanceDropdown(temp);
    // };

    useEffect(() => {
        const temp = financeData;
        const stateData = { ...financeDashboard };
        const newData: IFinanceDashboardSection = handleUserChange(
            temp ?? [],
            stateData,
            financeDropdown,
        ) as IFinanceDashboardSection;
        setFinanceDashboard(newData);
    }, [financeDropdown]);

    // const menuItemStyle: React.CSSProperties = {
    //     borderBottom: '1px solid #F2F4F6',
    //     height: '3rem',
    //     display: 'flex',
    //     alignItems: 'center',
    //     cursor: 'pointer',
    //     width: '100%',
    // };

    // const menu = (
    //     <Menu className="actions_menu">
    //         <Menu.Item
    //             className="actions_menu_items"
    //             style={menuItemStyle}
    //             onClick={() => handleFinanceDropdown('Maker')}
    //         >
    //             <div>{'Maker'}</div>
    //         </Menu.Item>
    //         <Menu.Item
    //             className="actions_menu_items"
    //             style={menuItemStyle}
    //             onClick={() => handleFinanceDropdown('Checker')}
    //         >
    //             <div>{'Checker'}</div>
    //         </Menu.Item>
    //         <Menu.Item
    //             className="actions_menu_items"
    //             style={menuItemStyle}
    //             onClick={() => handleFinanceDropdown('Auto-Authorizer')}
    //         >
    //             <div>{'Auto-Authorizer'}</div>
    //         </Menu.Item>
    //     </Menu>
    // );

    const financeData = hasKey(HQ_ROLE.financeDashboard.data, financeDropdown.value)
        ? HQ_ROLE.financeDashboard.data[financeDropdown.value]
        : undefined;

    return (
        <Fragment>
            {/* <BasicCollapsible
                checked={financeDashboard.isAll}
                expanded={expanded}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <Dropdown
                                    disabled={approval}
                                    overlay={menu}
                                    placement="bottomCenter"
                                    trigger={['click']}
                                >
                                    <DropdownBase>
                                        <BasicInput value={financeDashboard.permissionLevel} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </Dropdown>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={financeDashboard.isAll}
                            item={financeData}
                            data={financeDashboard}
                            setData={setFinanceDashboard as ISetSectionTypes}
                            offsetArray={[0, 3, 3]}
                        />
                    </Fragment>
                }
                handleChecked={handleFinanceDashboard}
                isCheckable={true}
                title={HQ_ROLE.financeDashboard.title}
                subtitle={HQ_ROLE.financeDashboard.subtitle}
                testId="finance-dashboard-accordion"
                noXMargin={true}
                noMargin={true}
            /> */}
            <CustomSpacer space={'1rem'} />
            {/* <BasicCollapsible
                checked={financeCases.isAll}
                expanded={expanded}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput
                                        style={{ backgroundColor: 'transparent' }}
                                        disabled={true}
                                        value={financeCases.permissionLevel}
                                    />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={financeCases.isAll}
                            item={HQ_ROLE.financeCases.data.general}
                            data={financeCases}
                            setData={setFinanceCases as ISetSectionTypes}
                        />
                    </Fragment>
                }
                handleChecked={handleFinanceCases}
                isCheckable={true}
                title={HQ_ROLE.financeCases.title}
                subtitle={HQ_ROLE.financeCases.subtitle}
                testId="finance-cases-accordion"
                noXMargin={true}
                noMargin={true}
            /> */}
        </Fragment>
    );
};
