/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ActivityLogsQuery } from '../../../_graphql/queries/systemadmin/activityLogs';
import { LABEL } from '../../../constants';

import ActivityLogsContext from './ActivityLogsContext';
import AuthContext from '../../AuthContext';
import ErrorHandlingContext from '../../ErrorHandling/ErrorHandlingContext';

interface IActivityLogsProviderProps {
    children: React.ReactNode;
}
const initialActivityFilter = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];

export const ActivityLogsProvider: React.FC<IActivityLogsProviderProps> = ({
    children,
}: IActivityLogsProviderProps) => {
    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [logCounter, setLogCounter] = useState<Array<number>>([1, 1]);

    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<IColumnValue>({ column: 'createdOn', value: 'descending' });
    const [filters, setFilters] = useState<IColumnValue[]>(initialActivityFilter);
    const [rolesFilter, setRolesFilter] = useState<string>(LABEL.roles);
    const [eventTypesFilter, setEventTypesFilter] = useState<string>(LABEL.eventType);
    const [statusFilter, setStatusFilter] = useState<string>(LABEL.status);
    // Dashboard tab data states
    const [allActivityData, setAllActivityData] = useState<ITableData[]>([]);
    const [allReportsData, setAllReportsData] = useState<ITableData[]>([]);
    // UI states
    const [loading, setLoading] = useState<boolean>(false);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    //fn to handle loading
    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };

    //call query to get all activity log data
    const getActivityLogData = async () => {
        loadingHandler();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsQuery, {
                    input: {
                        tab: tab === 0 ? 'userActivity' : 'reports',
                        page: currentPage,
                        resultLimit: resultLimit,
                        sort: {
                            column: sortData.column,
                            value: sortData.value,
                        },
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );
            // console.log(response.data.activityLogDashboard.data, 'result.logs');
            if (response.data.activityLogDashboard.data !== null) {
                const { result } = response.data.activityLogDashboard.data;

                setData(result.logs);
                setDisableResultLimit(result.logs.length === 0);
                if (currentPage > result.pages) {
                    setCurrentPage(1);
                }
                setMaxPages(result.pages);
                setLogCounter([result.activityCount, result.reportCount]);
            } else throw response.data.activityLogDashboard.error;
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard Data',
                testId: 'activitylogs-error-modal',
            });
            handleErrorHandler();
        }
    };
    //Fn to set data from query to states
    const setData = (data: ITableData[]) => {
        if (tab === 0) {
            setAllActivityData(data);
        } else {
            setAllReportsData(data);
        }
    };
    const ProviderValue: IActivityLogsContext = {
        allActivityData,
        allReportsData,
        currentPage,
        disableResultLimit,
        eventTypesFilter,
        filters,
        getActivityLogData,
        loading,
        loadingHandler,
        logCounter,
        maxPages,
        resultLimit,
        rolesFilter,
        searchInput,
        setCurrentPage,
        setEventTypesFilter,
        setFilters,
        setLogCounter,
        setResultLimit,
        setRolesFilter,
        setSearchInput,
        setSortData,
        setStatusFilter,
        setTab,
        sortData,
        statusFilter,
        tab,
    };
    return <ActivityLogsContext.Provider value={ProviderValue}>{children}</ActivityLogsContext.Provider>;
};
