export const SearchOptionsActivityLogs = {
    activityLogs: [
        { item: 'All', value: 'all' },
        { item: 'Operation Name', value: 'operationName' },
        { item: 'User Name', value: 'name' },
        { item: 'User ID', value: 'userName' },
        { item: 'User Roles', value: 'roles' },
    ],
};
// daily: ['All', 'Adviser Name', 'Adviser Code', 'Investor Name', 'Investor NRIC', 'Transaction Number', 'User ID'],
