/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, ReactText, SetStateAction, useContext } from 'react';
import { useState } from 'react';
import { useComponentLoader } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';
import { systemAdminDashboard } from '../../_graphql/queries/systemadmin/systemAdminDashboard';
import { IUserManagement } from '../../utils/permissionTypes';
import { LABEL, SYSTEM_ADMIN } from '../../constants';
import { exportList, toTitleCase } from '../../utils';
import { tabsLabels } from '../../pages';
import { getDropDownList } from '../../_graphql/queries/common/getDropDownList';
import { DashboardRoleExportList } from '../../_graphql/queries/systemadmin/dashboardExportList/dashboardRoleExportList';
import { DashboardGroupExportList } from '../../_graphql/queries/systemadmin/dashboardExportList/dashboardGroupExportList';
import { DashboardUserExportList } from '../../_graphql/queries/systemadmin/dashboardExportList/dashboardUserExportList';
import { IDropdownWithId } from '../../pages/SystemAdmin/ActivityLogs/Dashboard';
import { IMultiSelectLabel } from '../../components/molecules/MultiSelect/MultiSelectV2';
import { dateFilterBranch } from '../../pages/DashboardBranch/dashboardBranchTypes';
import { initialOverView } from '../../pages/SystemAdmin/Dashboard/helper';
import moment from 'moment';
import AuthContext from '../AuthContext';
import SystemAdminContext from './SAContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

const initialFilterInputSa: ISort_FilterInput = [
    { column: 'createdOn', value: '' },
    { column: 'department', value: '' },
    { column: 'status', value: '' },
    { column: 'group', value: '' },
];
const initialDateFilterSA: ISearchInput = { column: 'createdOn', value: '' };
const _initialDateFilterSA: dateFilterBranch = {
    dateSort: 'createdOn',
    range: '',
};
const initialSortInput: ISearchInput = { column: 'createdOn', value: 'ascending' };

export interface SystemAdminContextProps {
    accessFilter: string;
    agencyList: ReactText[];
    allGroups: ITableData[];
    allRoles: ITableData[];
    allUsers: ITableData[];
    ascendingFilter: number;
    branches: ReactText[];
    branchFilter: string;
    branchOptions: ISelectOption[];
    channelList: ReactText[];
    createdOnDropdown: string;
    dateFilter: dateFilterBranch;
    departmentFilter: string;
    departmentOptions: ISelectOption[];
    disableBulkButton: boolean;
    disableResultLimit: boolean;
    eventTypeList: ReactText[];
    fetchAllUsers: () => Promise<void>;
    getDropdown: () => Promise<void>;
    getExportList: () => Promise<void>;
    getTab: (tabNumber: number) => number;
    groupFilter: string;
    groupsOptions: ISelectOption[];
    loading: boolean;
    orderCounter: number[];
    overview: ISummaryCardData[][];
    overviewClicked: number;
    page: number;
    pageMax: number;
    paginationClicked: boolean;
    resultLimit: number;
    roleOptions: IMultiSelectLabel[];
    rolesList: ReactText[];
    searchInput: ISearchInput;
    setAccessFilter: Dispatch<React.SetStateAction<string>>;
    setAscendingFilter: Dispatch<React.SetStateAction<number>>;
    setBranches: Dispatch<React.SetStateAction<React.ReactText[]>>;
    setBranchFilter: Dispatch<React.SetStateAction<string>>;
    setCreatedOnDropdown: Dispatch<SetStateAction<string>>;
    setDateFilter: Dispatch<SetStateAction<dateFilterBranch>>;
    setDepartmentFilter: Dispatch<React.SetStateAction<string>>;
    setEventTypeList: Dispatch<React.SetStateAction<React.ReactText[]>>;
    setGroupFilter: Dispatch<React.SetStateAction<string>>;
    setOverviewClicked: Dispatch<React.SetStateAction<number>>;
    setPage: Dispatch<React.SetStateAction<number>>;
    setPaginationClicked: Dispatch<React.SetStateAction<boolean>>;
    setResultLimit: Dispatch<React.SetStateAction<number>>;
    setRoleOptions: Dispatch<React.SetStateAction<IMultiSelectLabel[]>>;
    setRolesList: Dispatch<React.SetStateAction<React.ReactText[]>>;
    setSearchInput: Dispatch<React.SetStateAction<ISearchInput>>;
    setSortInput: Dispatch<SetStateAction<ISearchInput>>;
    setStatusFilter: Dispatch<React.SetStateAction<string>>;
    setTab: Dispatch<React.SetStateAction<number>>;
    setTabs: Dispatch<React.SetStateAction<IDashboardTabs[]>>;
    setTarget: Dispatch<React.SetStateAction<ISearchInput>>;
    setTempFilters: Dispatch<React.SetStateAction<ISort_FilterInput>>;
    setTempTarget: Dispatch<React.SetStateAction<ISearchInput>>;
    setWait: Dispatch<SetStateAction<boolean>>;
    loadingHandler: () => void;
    sortInput: ISearchInput;
    statusFilter: string;
    tab: number;
    tabs: IDashboardTabs[];
    targetDate: ISearchInput;
    tempFilters: ISort_FilterInput;
    tempTargetDate: ISearchInput;
    uploadId: string;
    wait: boolean;
}

export interface SystemAdminProviderProps {
    children: React.ReactNode;
}

export const SystemAdminProvider: React.FC<SystemAdminProviderProps> = ({ children }: SystemAdminProviderProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;
    const { userTab } = userManagementPermission;

    const [allUsers, setAllUsers] = useState<ITableData[]>([]);
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [overviewClicked, setOverviewClicked] = useState(-1);
    const [statusFilter, setStatusFilter] = useState('');
    const [tempFilters, setTempFilters] = useState<ISort_FilterInput>(initialFilterInputSa); // Temp Filters
    const [sortInput, setSortInput] = useState<ISearchInput>(initialSortInput); // Sort Input
    const [createdOnDropdown, setCreatedOnDropdown] = useState<string>('Created On');
    const [dateFilter, setDateFilter] = useState<dateFilterBranch>(_initialDateFilterSA); //Data range + type filter
    const [departmentFilter, setDepartmentFilter] = useState<string>('');
    const [groupFilter, setGroupFilter] = useState<string>('');
    const [branchFilter, setBranchFilter] = useState<string>('');
    //Search Filter states
    const [departmentOptions, setDepartmentOptions] = useState<ISelectOption[]>([]);
    const [groupsOptions, setGroupsOptions] = useState<ISelectOption[]>([]);
    const [branchOptions, setBranchOptions] = useState<ISelectOption[]>([]);
    const [roleOptions, setRoleOptions] = useState<IMultiSelectLabel[]>([]);
    const [targetDate, setTarget] = useState<ISearchInput>(initialDateFilterSA);
    const [tempTargetDate, setTempTarget] = useState<ISearchInput>(initialDateFilterSA);
    const [tab, setTab] = useState(0);
    const [ascendingFilter, setAscendingFilter] = useState(1);
    const [accessFilter, setAccessFilter] = useState<string>('');
    const [page, setPage] = useState(1);
    const { loading, loadingHandler } = useComponentLoader();
    // Error handling
    const [wait, setWait] = useState(true);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [resultLimit, setResultLimit] = useState(10);
    const [disableResultLimit, setDisableResultLimit] = useState(false);
    const [paginationClicked, setPaginationClicked] = useState(false);
    const [overview, setOverview] = useState(
        initialOverView.map((overView): ISummaryCardData[] => {
            return overView.map((value): ISummaryCardData => {
                return {
                    dataLabel: value.label,
                    title: value.title,
                    data: '0',
                    cardId: value.tabInput,
                    testId: value.testId,
                };
            });
        }),
    );
    const [disableBulkButton, setDisableBulkButton] = useState<boolean>(false);
    const [orderCounter, setOrderCounter] = useState<Array<number>>([]);
    const [uploadId, setUploadId] = useState<string>('');
    const [pageMax, setPageMax] = useState(1);
    const [rolesList, setRolesList] = useState<ReactText[]>([]);
    const [eventTypeList, setEventTypeList] = useState<ReactText[]>([]);
    const [agencyList, setAgencyList] = useState<ReactText[]>([]);
    const [channelList, setChannelList] = useState<ReactText[]>([]);
    const [branches, setBranches] = useState<ReactText[]>([]);

    const [allGroups, setAllGroups] = useState<ITableData[]>([]);
    const [allRoles, setAllRoles] = useState<ITableData[]>([]);
    const [tabs, setTabs] = useState<IDashboardTabs[]>([]);

    const getInputs = () => {
        // building filer and sort input values
        const filterInput: any[] = [];
        let searchInputKey: any | undefined = undefined;

        if (searchInput.value !== '') {
            setOverviewClicked(-1);
            searchInputKey = { ...searchInput };
        }
        const statusInput =
            statusFilter === ''
                ? undefined
                : {
                      column: 'status',
                      value: statusFilter === 'Pending Approval' ? 'Pending' : statusFilter,
                  };
        const departmentInput = departmentFilter === '' ? undefined : { column: 'department', value: departmentFilter };
        // const timeInput = tempTargetDate;
        // const createdOnFilter =
        //     tab === 0 && ascendingFilter === 2 ? { column: 'createdOn', value: 'Descending' } : undefined;

        const branchInput = branchFilter === '' ? undefined : { column: 'branch', value: branchFilter };
        const accessInput = accessFilter === '' ? undefined : { column: 'accessLevel', value: accessFilter };
        // const sortFilter =
        //     tableSortFilter === '' || tableSortFilterStatus === 0
        //         ? undefined
        //         : { column: tableSortFilter, value: tableSortFilterStatus === 1 ? 'Ascending' : 'Descending' };

        const groupInput = groupFilter === '' ? undefined : { column: 'group', value: groupFilter };
        const overviewInput =
            overviewClicked === -1
                ? undefined
                : { column: 'source', value: initialOverView[tab][overviewClicked].source };
        if (statusInput) filterInput.push(statusInput);
        if (departmentInput) filterInput.push(departmentInput);
        if (targetDate) filterInput.push(targetDate);
        if (branchInput) filterInput.push(branchInput);
        if (accessInput) filterInput.push(accessInput);
        if (groupInput) filterInput.push(groupInput);
        if (overviewInput) filterInput.push(overviewInput);
        return { searchInputKey, filterInput };
    };

    const getTab = (tabNumber: number) => {
        if (tabs.length < 1) return 0;
        const index = tabs[tabNumber].name === 'All Users' ? 0 : tabs[tabNumber].name === 'User Groups' ? 1 : 2;
        return index;
    };

    const fetchAllUsers = async () => {
        const { searchInputKey, filterInput } = getInputs();
        loadingHandler();
        try {
            setWait(true);
            const response: any = await API.graphql(
                graphqlOperation(systemAdminDashboard, {
                    input: {
                        tab: tabsLabels[tab],
                        page: page,
                        resultLimit: resultLimit,
                        search: searchInputKey,
                        filter: filterInput,
                        sort: [sortInput],
                    },
                }),
                idTokenHeader,
            );
            setWait(false);
            const { data, error } = await response.data.systemAdminDashboard;
            setPaginationClicked(false);
            const users: any = [];
            const groups: any = [];
            const roles: any = [];
            if (data !== null) {
                setTabs([]);
                const updateOverview = [...overview];
                updateOverview[0][0].data = `${data.result.overview.totalCreatedUserRequest}`;
                updateOverview[0][1].data = `${data.result.overview.totalBulkImportRequest}`;
                updateOverview[0][2].data = `${data.result.overview.totalEditUserRequest}`;
                updateOverview[0][3].data = `${data.result.overview.totalSuspendedUserRequest}`;
                updateOverview[0][4].data = `${data.result.overview.totalReactivateUserRequest}`;
                updateOverview[0][5].data = `${data.result.overview.totalTerminateUserRequest}`;
                updateOverview[1][0].data = `${data.result.overview.totalGroupRequest}`;
                updateOverview[1][1].data = `${data.result.overview.totalEditGroupRequest}`;
                updateOverview[1][2].data = `${data.result.overview.totalSuspendedGroupRequest}`;
                updateOverview[1][3].data = `${data.result.overview.totalReactivateGroupRequest}`;
                updateOverview[1][4].data = `${data.result.overview.totalDeleteGroupRequest}`;
                updateOverview[2][0].data = `${data.result.overview.totalRoleRequest}`;
                updateOverview[2][1].data = `${data.result.overview.totalEditRoleRequest}`;
                updateOverview[2][2].data = `${data.result.overview.totalSuspendedRoleRequest}`;
                updateOverview[2][3].data = `${data.result.overview.totalReactivateRoleRequest}`;
                updateOverview[2][4].data = `${data.result.overview.totalDeleteRoleRequest}`;

                // check if there is any pending bulk import user request
                data.result.overview.totalBulkImportRequest > 0 && userTab.actions.canBulkImport !== 'auto-authorizer'
                    ? setDisableBulkButton(true)
                    : setDisableBulkButton(false);

                data.result.bulkId[0] && setUploadId(data.result.bulkId[0]);

                setOverview(updateOverview);
                setOrderCounter([
                    parseInt(data.result.totalUserCount),
                    parseInt(data.result.totalGroupCount),
                    parseInt(data.result.totalRoleCount),
                ]);
                setPage(parseInt(data.result.page));
                setPageMax(parseInt(data.result.pages));
                const cron = sortInput.column === 'createdOn' ? SYSTEM_ADMIN.LABEL_CREATED_ON : LABEL.lastUpdated;
                switch (tabs[tab].name) {
                    case 'All Users':
                        data.result.transactions.forEach((item: any) => {
                            users.push({
                                name: item.staffName,
                                code: item.loginId,
                                department: item.department,
                                userGroup: item.userGroup,
                                createdOn:
                                    cron === SYSTEM_ADMIN.LABEL_CREATED_ON
                                        ? moment(item.timestamp, 'x').format('DD/MM/YYYY')
                                        : item.lastUpdated !== null
                                        ? moment(item.lastUpdated, 'x').format('DD/MM/YYYY')
                                        : '-',
                                createdOnTime:
                                    cron === SYSTEM_ADMIN.LABEL_CREATED_ON
                                        ? moment(item.timestamp, 'x').format('hh:mm:ss A')
                                        : item.lastUpdated !== null
                                        ? moment(item.lastUpdated, 'x').format('hh:mm:ss A')
                                        : '',
                                lastLogin:
                                    item.lastLogin !== null ? moment(item.lastLogin, 'x').format('DD/MM/YYYY') : '-',
                                lastLoginTime:
                                    item.lastLogin !== null ? moment(item.lastLogin, 'x').format('hh:mm:ss A') : '',
                                status: toTitleCase(item.status),
                                apiCode: item.userId,
                                requestId: item.requestId,
                                source: item.source,
                                isSeen: item.isSeen,
                            });
                        });
                        setAllUsers(users);
                        setDisableResultLimit(users.length === 0);

                        break;
                    case 'User Groups':
                        data.result.transactions.forEach((item: any) => {
                            groups.push({
                                groupName: item.groupName,
                                branch: item.branch,
                                userCount: item.userCount,
                                roleCount: item.roleCount,
                                createdOn:
                                    cron === SYSTEM_ADMIN.LABEL_CREATED_ON
                                        ? moment(item.timestamp, 'x').format('DD/MM/YYYY')
                                        : item.lastUpdated !== null
                                        ? moment(item.lastUpdated, 'x').format('DD/MM/YYYY')
                                        : '-',
                                createdOnTime:
                                    cron === SYSTEM_ADMIN.LABEL_CREATED_ON
                                        ? moment(item.timestamp, 'x').format('hh:mm:ss A')
                                        : item.lastUpdated !== null
                                        ? moment(item.lastUpdated, 'x').format('hh:mm:ss A')
                                        : '',
                                status: toTitleCase(item.status),
                                groupId: item.groupId,
                                requestId: item.requestId,
                                source: item.source,
                                isSeen: item.isSeen,
                            });
                        });
                        await setAllGroups(groups);
                        await setDisableResultLimit(groups.length === 0);
                        // loadingHandler();
                        break;
                    case 'Roles & Permissions':
                        // console.log('check', cron === SYSTEM_ADMIN.LABEL_CREATED_ON);
                        data.result.transactions.forEach((item: any) => {
                            roles.push({
                                roleId: item.roleId,
                                roleName: item.roleName,
                                roleDescription: item.description,
                                accessLevel: item.accessLevel,
                                createdOn:
                                    cron === SYSTEM_ADMIN.LABEL_CREATED_ON
                                        ? moment(item.timestamp, 'x').format('DD/MM/YYYY')
                                        : item.lastUpdated !== null
                                        ? moment(item.lastUpdated, 'x').format('DD/MM/YYYY')
                                        : '-',
                                createdOnTime:
                                    cron === SYSTEM_ADMIN.LABEL_CREATED_ON
                                        ? moment(item.timestamp, 'x').format('hh:mm:ss A')
                                        : item.lastUpdated !== null
                                        ? moment(item.lastUpdated, 'x').format('hh:mm:ss A')
                                        : '',
                                status: toTitleCase(item.status),
                                requestId: item.requestId,
                                source: item.source,
                                isSeen: item.isSeen,
                            });
                        });
                        setAllRoles(roles);
                        setDisableResultLimit(roles.length === 0);
                        break;
                    default:
                        break;
                }
                loadingHandler();
            }

            if (error !== null) {
                loadingHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch Dashboard Data',
                    testId: 'systemadmin-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const getDropdown = async () => {
        try {
            const response: any = await API.graphql(graphqlOperation(getDropDownList, {}), idTokenHeader);

            const { data, error } = await response.data.getDropDownList;
            if (data !== null) {
                // console.log('getDropdown data->', data.result);
                interface temp {
                    id: string;
                    value: string;
                }
                let tempData: temp[] = data.result.branches;
                let temp: IMultiSelectLabel[] = [];

                let tempDup: ISelectOption[] = [];

                for (let i = 0; i < tempData.length; i++) {
                    tempDup.push({ key: tempData[i].id, value: tempData[i].value, label: tempData[i].value });
                }
                setBranchOptions(tempDup);
                tempData = data.result.departments;
                tempDup = [];
                for (let i = 0; i < tempData.length; i++) {
                    tempDup.push({
                        key: tempData[i].id,
                        value: tempData[i].value,
                        label: tempData[i].value,
                    });
                }
                setDepartmentOptions(tempDup);
                tempData = data.result.groups;
                tempDup = [];
                for (let i = 0; i < tempData.length; i++) {
                    tempDup.push({
                        key: tempData[i].id,
                        value: tempData[i].value,
                        label: tempData[i].value,
                    });
                }
                setGroupsOptions(tempDup);
                tempData = data.result.roles;
                temp = [];
                for (let i = 0; i < tempData.length; i++) {
                    temp.push({
                        key: tempData[i].id,
                        value: tempData[i].value,
                        label: tempData[i].value,
                    });
                }
                setRoleOptions(temp);
                const rolesFromApi: ReactText[] = [];
                data.result.roles.map((role: IDropdownWithId) => rolesFromApi.push(role.value));

                const branchesFromApi: ReactText[] = [];
                data.result.branches.map((branch: IDropdownWithId) => branchesFromApi.push(branch.value));

                const agencyFromApi: ReactText[] = [];
                data.result.agency.map((agency: IDropdownWithId) => agencyFromApi.push(agency.value));

                const channelFromApi: ReactText[] = [];
                data.result.channel.map((channel: IDropdownWithId) => channelFromApi.push(channel.value));
                setRolesList(rolesFromApi);
                setBranches(branchesFromApi);
                setAgencyList(agencyFromApi);
                setChannelList(channelFromApi);
                setEventTypeList(data.result.eventTypes);
            }
            if (error !== null) {
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch Dropdown Data',
                    testId: 'systemadmin-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const getExportList = async () => {
        const { searchInputKey, filterInput } = getInputs();

        let query = DashboardUserExportList;
        switch (tabs[tab].name) {
            case 'All Users':
                query = DashboardUserExportList;
                break;
            case 'User Groups':
                query = DashboardGroupExportList;
                break;
            case 'Roles & Permissions':
                query = DashboardRoleExportList;
                break;
        }

        const objectName = 'systemadmindashboard';
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        tab: tabsLabels[tab],
                        filter: filterInput,
                        search: searchInputKey,
                        sort: [sortInput],
                        endpoint: 'systemadmindashboard',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;
            // console.log('resultList', result);
            exportList('Hq', tabs[tab].name, result[objectName]);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'systemadmin-dashboard-error-modal',
            });
            handleErrorHandler();
        }
    };

    const ProviderValue: SystemAdminContextProps = {
        accessFilter,
        agencyList,
        allGroups,
        allRoles,
        allUsers,
        ascendingFilter,
        branches,
        branchFilter,
        branchOptions,
        channelList,
        createdOnDropdown,
        dateFilter,
        departmentFilter,
        departmentOptions,
        disableBulkButton,
        disableResultLimit,
        eventTypeList,
        fetchAllUsers,
        getDropdown,
        getExportList,
        getTab,
        groupFilter,
        groupsOptions,
        loading,
        orderCounter,
        overview,
        overviewClicked,
        page,
        pageMax,
        paginationClicked,
        resultLimit,
        roleOptions,
        rolesList,
        searchInput,
        setAccessFilter,
        setAscendingFilter,
        setBranches,
        setBranchFilter,
        setCreatedOnDropdown,
        setDateFilter,
        setDepartmentFilter,
        setEventTypeList,
        setGroupFilter,
        setOverviewClicked,
        setPage,
        setPaginationClicked,
        setResultLimit,
        setRoleOptions,
        setRolesList,
        setSearchInput,
        setSortInput,
        setStatusFilter,
        setTab,
        setTabs,
        setTarget,
        setTempFilters,
        setTempTarget,
        setWait,
        loadingHandler,
        sortInput,
        statusFilter,
        tab,
        tabs,
        targetDate,
        tempFilters,
        tempTargetDate,
        uploadId,
        wait,
    };
    return <SystemAdminContext.Provider value={ProviderValue}>{children}</SystemAdminContext.Provider>;
};

export default SystemAdminProvider;
