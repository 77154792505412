/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment } from 'react';
import { Pointer } from '../../../components';
import CustomDropdown, { IDropdownItem } from '../../../components/molecules/Dropdown/Dropdown';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { IPendingTab } from '../../../utils/permissionTypes';

interface DailyOptionsProps extends ITableOptions {
    handleViewDetails: (item: IColumnItemAccordion) => void;
    handleApproveTransaction: (item: IColumnItemAccordion) => void;
    handleApproveReviewRequest: (item: IColumnItemAccordion, action: string) => void;
    handlePrintTransaction: (item: IColumnItemAccordion) => void;
    handleRerouteTransaction: (item: IColumnItemAccordion) => void;
    handleRejectTransaction: (item: IColumnItemAccordion) => void;
    handleDownloadPPA: (item: IColumnItemAccordion) => void;
    pendingTab: IPendingTab;
}

export const DailyOptions = ({
    data,
    handleApproveTransaction,
    handleApproveReviewRequest,
    handlePrintTransaction,
    handleRejectTransaction,
    handleRerouteTransaction,
    pendingTab,
    handleDownloadPPA,
}: DailyOptionsProps): JSX.Element => {
    const status: string = data.rawData.status.toLowerCase();
    const isChangeRequestOrder: boolean =
        data.rawData.transactionType.toLowerCase() === 'cr' ||
        data.rawData.transactionType.toLowerCase() === 'change request';
    const approvalType: string = data.rawData.approvalType !== null ? data.rawData.approvalType : '';

    const handleApprove = (_item: string) => {
        handleApproveTransaction(data);
    };
    const handleReroute = (_item: string) => {
        handleRerouteTransaction(data);
    };
    const handleReject = (_item: string) => {
        handleRejectTransaction(data);
    };
    const handlePrint = (_item: string) => {
        handlePrintTransaction(data);
    };
    const handleRequest = (item: string) => {
        handleApproveReviewRequest(data, item);
    };
    const handlePPA = (_item: string) => {
        handleDownloadPPA(data);
        // setReroute(!reroute);
    };
    const menu = (status: string) => {
        switch (status) {
            case 'in review':
                return inReviewMenu;

            case 'pending approval':
                return pendingApprovalMenu();

            case 'completed':
                return completedMenu;
        }
    };

    const inReviewMenu: IDropdownItem[] = [];
    if (
        pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer ||
        pendingTab.actions.canVerifyTransactions === LABEL.maker
    ) {
        inReviewMenu.push({ item: LABEL.verifyTransaction, icon: 'check', handleItem: handleApprove });
    }
    if (
        (pendingTab.actions.canReroute === LABEL.autoAuthorizer || pendingTab.actions.canReroute === LABEL.maker) &&
        data.rawData.isVerified === false &&
        !isChangeRequestOrder
    ) {
        inReviewMenu.push({ item: LABEL.rerouteOrder, icon: 'reroute', handleItem: handleReroute });
    }
    if (
        (pendingTab.actions.canReject === LABEL.autoAuthorizer || pendingTab.actions.canReject === LABEL.maker) &&
        data.rawData.isVerified === false &&
        !isChangeRequestOrder
    ) {
        inReviewMenu.push({ item: LABEL.rejectOrder, icon: 'terminate', handleItem: handleReject, danger: true });
    }

    const pendingApprovalMenu = (): IDropdownItem[] => {
        const temp: IDropdownItem[] = [];
        approvalType === 'approve' &&
        (pendingTab.reviewApproval.canApproveVerify === LABEL.checker ||
            pendingTab.reviewApproval.canApproveVerify === LABEL.autoAuthorizer)
            ? temp.push(
                  {
                      item: LABEL.approveRequest,
                      icon: 'check',
                      handleItem: handleRequest,
                      testId: `daily-approve-request-btn`,
                  },
                  {
                      item: LABEL.rejectRequest,
                      icon: 'close',
                      danger: true,
                      handleItem: handleRequest,
                      testId: `daily-reject-request-btn`,
                  },
              )
            : null;
        approvalType === 'reroute' &&
        (pendingTab.reviewApproval.canApproveReRoute === LABEL.checker ||
            pendingTab.reviewApproval.canApproveReRoute === LABEL.autoAuthorizer)
            ? temp.push(
                  {
                      item: LABEL.approveRequest,
                      icon: 'check',
                      handleItem: handleRequest,
                      testId: `daily-approve-request-btn`,
                  },
                  {
                      item: LABEL.rejectRequest,
                      icon: 'close',
                      danger: true,
                      handleItem: handleRequest,
                      testId: `daily-reject-request-btn`,
                  },
              )
            : null;
        approvalType === 'reject' &&
        (pendingTab.reviewApproval.canApproveReject === LABEL.checker ||
            pendingTab.reviewApproval.canApproveReject === LABEL.autoAuthorizer)
            ? temp.push(
                  {
                      item: LABEL.approveRequest,
                      icon: 'check',
                      handleItem: handleRequest,
                      testId: `daily-approve-request-btn`,
                  },
                  {
                      item: LABEL.rejectRequest,
                      icon: 'close',
                      danger: true,
                      handleItem: handleRequest,
                      testId: `daily-reject-request-btn`,
                  },
              )
            : null;

        return temp;
    };

    const completedMenu: IDropdownItem[] = [];
    pendingTab.actions.canDownloadTransactionReport === LABEL.maker
        ? data.rawData.utmc === LABEL.kib && data.rawData.fundType.toLowerCase() === 'prs'
            ? completedMenu.push(
                  {
                      item: LABEL.downloadTransactionReport,
                      icon: 'download',
                      handleItem: handlePrint,
                      testId: `daily-download-transaction-btn`,
                  },
                  {
                      item: LABEL.ppaOnlineAccountOpeningFormDownload,
                      icon: 'download',
                      handleItem: handlePPA,
                      testId: `daily-download-ppa-btn`,
                  },
              )
            : completedMenu.push({
                  item: LABEL.downloadTransactionReport,
                  icon: 'download',
                  handleItem: handlePrint,
                  testId: `daily-download-transaction-btn`,
              })
        : null;
    let items: IDropdownItem[] = [];
    let showAction = false;
    const item = menu(status);

    if (item !== undefined && item.length !== 0) {
        items = item;
        showAction = true;
    }

    return (
        <Fragment>
            {showAction ? (
                items.length === 1 && status === 'in review' ? (
                    <Pointer onClick={() => handleApproveTransaction(data)}>
                        <IcoMoon name="check" size="1.5rem" />
                    </Pointer>
                ) : (
                    <CustomDropdown items={items}>
                        <Pointer>
                            <IcoMoon name="action-menu" size="1.5rem" />
                        </Pointer>
                    </CustomDropdown>
                )
            ) : null}
        </Fragment>
    );
};
