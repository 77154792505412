/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactText, Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { CustomButton, CustomSpacer, FlexedDiv, ComponentLoader, DatePicker, Modal } from '../../../../components';
import MultiSelectV2 from '../../../../components/molecules/MultiSelect';
import {
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    Fs16SemiBoldSecPrimaryBlack,
    Fs24BoldPrimaryBlack,
    LABEL,
    EDD,
} from '../../../../constants';
import { IcoMoonSelection } from '../../../../icons';
import { generateEddReportMonthlyQuery } from '../../../../_graphql/queries/edd/generateEddReportMonthly/generateEddReportMonthly';
import { useComponentLoader } from '../../../../customHooks';

import * as Routes from '../../../../routes';
import IcomoonReact from 'icomoon-react';
import styled from 'styled-components';
import EDDContext from '../../../../context/EDDContext/EDDContext';

import moment from 'moment';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import AuthContext from '../../../../context/AuthContext/AuthContext';

interface StatusReportInputProps {
    statusIds: Array<ReactText>;
    dateFrom: string;
    dateTo: string;
}

const initialData: StatusReportInputProps = {
    statusIds: [],
    dateFrom: '',
    dateTo: '',
};
interface IDownloadMonthlyRouteProps {
    currentTab: string;
}
export const DownloadMonthlyReport: React.FC = () => {
    const { eddCaseStatusGroup, getDropdownData } = React.useContext(EDDContext);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const locationState = useLocation<IDownloadMonthlyRouteProps>();
    const [statusReportInput, setStatusReportInput] = React.useState<StatusReportInputProps>(initialData);
    // const [targetDateRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const { loadingHandler, loading } = useComponentLoader();
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    // Temp state to handle date range picker's to and from states
    const [targetDateRange, setTargetDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);

    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');

    useEffect(() => {
        getDropdownData();
    }, []);

    const handleSubmit = async () => {
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(generateEddReportMonthlyQuery, {
                    input: statusReportInput,
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.generateEddReportMonthly;
            if (error !== null) throw error;
            if (data.result.url) {
                location.href = data.result.url;
                setShowModal(true);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Download Status Report',
            });
        }
    };
    const handleCancel = () => {
        history.push(Routes.amlaEDDManagement, { currentTab: locationState.state.currentTab });
    };

    const handleBack = () => {
        history.push(Routes.amlaEDDManagement, { currentTab: locationState.state.currentTab });
    };
    const getSelectedList = (list: Array<ReactText>) => {
        setStatusReportInput({ ...statusReportInput, statusIds: list });
    };

    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const start: moment.Moment = date?.[0] as moment.Moment;
        const end: moment.Moment = date?.[1] as moment.Moment;
        start.set({ h: 0, m: 0, s: 0 });
        end.set({ h: 23, m: 59, s: 59 });

        const update = [...date] as [moment.Moment, moment.Moment];
        const _timeFrom = update[0] && update[0].valueOf();
        const _timeTo = update[1] && update[1].valueOf();
        const _validFrom = _timeFrom < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = _timeTo < moment().add(1, 'day').startOf('day').valueOf() && _timeTo > _timeFrom;
        if (_validFrom && _validTo) {
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            statusReportInput.dateTo = `${moment(date[1], '"M/D/YYYY H:mm"').valueOf()}`;
            statusReportInput.dateFrom = `${date[0]?.valueOf()}`;
            // setTargetRange(update);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            _timeTo < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            _timeTo < _timeFrom && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${update[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };
    // Temp fn to handle date from value
    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const dates = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetDateRange(dates);
        setStatusReportInput({
            ...statusReportInput,
            dateFrom: dates[0]?.valueOf().toString() || '',
            dateTo: dates[1]?.valueOf().toString() || '',
        });
    };
    return (
        <Fragment>
            <FlexedDiv direction="row">
                <CustomSpacer horizontal={true} space={'2.778vw'} />
                <FlexedDiv direction="column">
                    <TitleContainer style={{ flexDirection: 'row', display: 'flex' }}>
                        <Icon onClick={handleBack}>
                            <IcomoonReact iconSet={IcoMoonSelection} icon="arrow-left" size="1.5rem" />
                        </Icon>
                        <CustomSpacer horizontal={true} space={'1.5rem'} />
                        <Fs24BoldPrimaryBlack>{EDD.LABEL_DOWNLOAD_EDD_STATUS_REPORT}</Fs24BoldPrimaryBlack>
                    </TitleContainer>
                    <div style={{ paddingLeft: '3rem' }}>
                        <CustomSpacer space={'0.5rem'} />
                        <Fs16SemiBoldSecPrimaryBlack>{EDD.DOWNLOAD_MONTHLY_REPORT_SUB}</Fs16SemiBoldSecPrimaryBlack>
                        <CustomSpacer space={'2rem'} />
                        <MultiSelectV2
                            disabled={false}
                            expandable={true}
                            label={EDD.LABEL_EDD_CASE_STATUS}
                            options={eddCaseStatusGroup}
                            placeHolder={EDD.LABEL_SELECT_STATUSES}
                            checkedState={statusReportInput.statusIds}
                            handleMultiSelect={(item) => getSelectedList(item)}
                            selectId={1}
                            noOverlay={true}
                            showCustomBorder={true}
                            style={{
                                width: '20vw',
                                maxWidth: '360px',
                            }}
                        />
                        <CustomSpacer space={'2rem'} />
                        <FlexedDiv direction="column">
                            <InputWrap>
                                <DatePicker
                                    label={LABEL.dateRange}
                                    targetDate={targetDateRange}
                                    setTargetDate={handleTargetDate}
                                    range={true}
                                    width="360"
                                    handleDate={handleDate}
                                    errorMessage={datePickerInvalidMessage}
                                    setErrorMessage={setDatePickerInvalidMessage}
                                />
                            </InputWrap>
                        </FlexedDiv>
                        <FlexedDiv style={{ marginTop: '3.25rem' }}>
                            <CustomButton
                                primary={false}
                                onClick={handleCancel}
                                style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                            >
                                <Fs15BoldPrimaryBlack>{EDD.BUTTON_CANCEL}</Fs15BoldPrimaryBlack>
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                disabled={
                                    statusReportInput.statusIds.length < 1 ||
                                    targetDateRange[0] === null ||
                                    targetDateRange[1] == null
                                }
                                primary={true}
                                onClick={handleSubmit}
                                style={{ width: '16.667vw' }}
                            >
                                <Fs15BoldNeutralWhite>{EDD.BUTTON_DOWNLOAD}</Fs15BoldNeutralWhite>
                            </CustomButton>
                        </FlexedDiv>
                    </div>
                </FlexedDiv>
            </FlexedDiv>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={EDD.DOWNLOAD_MONTHLY_REPORT_MODAL_SUB}
                    icon={'monthly-report-success'}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.amlaEDDManagement);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="edd-cancel-request"
                    contentAlignment="center"
                ></Modal>
            ) : null}
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;
const TitleContainer = styled.div`
    align-items: center;
`;

const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;
