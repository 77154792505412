import gql from 'graphql-tag';

/**
 * @adviserDashboardQuery @query - Adviser Dashboard
 * @description:  Returns all advisers of user’s branch
 */
export const targetDateRequestQuery = /* GraphQL */ gql`
    mutation targetDateRequest($input: TargetDateRequestInput) {
        targetDateRequest(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
