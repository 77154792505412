/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { useContext, useRef, useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LinkButton } from '../../components/atoms/LinkButton';
import { CE_DASHBOARD_LABELS, LABEL, SearchOptionsBranch } from '../../constants';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import WebSocketContext from '../../context/WebSocketContext';
import DashboardBranchContext from '../../context/BranchContext/DashboardBranchContext';
import { useDebounce } from '../../customHooks';
import { clearContextOnNavigation, exportList, removeArrayElement, ROUTES, updateIsSeenDashboard } from '../../utils';
import { ICeDashboard } from '../../utils/permissionTypes';
import { IFilterBranch, initialDateFilterBranch, initialFilterSelectStatesBranch } from './dashboardBranchTypes';
import { decoupleGroupedTrx, decoupleNonGroupedTrx } from './decoupleGroupedTrx';
import { exportListQueryPicker } from './helpers/exportListQueryPicker';
import { getFilterTagLabels, removeSearchFilterTagFromFilter } from './helpers/getFilterTagLabels';
import { pickSearchLabel } from './helpers/pickSearchLabel';
import { getTabDisplayName, selectedTab } from './helpers/selectedTab';
import { Rerouted } from './Rerouted';
import { Upcoming } from './Upcoming';
import { WithHardcopy } from './WithHardCopy';
import { History } from '../DashboardBranch/History/History';
import { transactionReportZip } from '../../_graphql/mutations/branchCE/downloadDaily/downloadDaily';
import { CollapsibleDisplay, CustomSpacer, FlexedDiv, IDropdownItem } from '../../components';
import styled from 'styled-components';
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import { dailySummaryReportFormA } from '../../_graphql/mutations/branchCE/dailySummaryRport/dailySummaryReport';
import { downloadPPAQuery } from '../../_graphql/queries/branchCE/transaction/downloadPPA/downloadPPAQuery';
import { statusSplice } from './helpers/statusSplice';
import moment from 'moment';
import { tempFilterFunctions } from './helpers/tempFilter';
import { hasKey } from '../SystemAdmin/RoleSettings/utils/functions';
import Daily from './Daily';
import { allExportListQueryPicker } from './helpers/allExportListQueryPicker';

const initialDailyFilter: IFilterBranch[] = [
    { column: 'fundCategory', value: 'MONEY MARKET' },
    { column: 'batchTime', value: '' },
    { column: 'transactionType', value: 'Sales-AO' },
    { column: 'submittedOn', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
    { column: 'orderType', value: 'Transaction' },
];
const dailyPendingFilter: IFilterBranch[] = [
    { column: 'fundCategory', value: '' },
    { column: 'batchTime', value: '' },
    { column: 'transactionType', value: 'Sales-AO' },
    { column: 'submittedOn', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
    { column: 'status', value: 'pending' },
    { column: 'orderType', value: 'Transaction' },
];
const initialDailyFilterNonMoney: IFilterBranch[] = [
    { column: '!fundCategory', value: 'MONEY MARKET' },
    { column: 'batchTime', value: '' },
    { column: 'transactionType', value: 'Sales-AO' },
    { column: 'submittedOn', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
    { column: 'orderType', value: 'Transaction' },
];
const initialOtherTabFilter: IFilterBranch[] = [
    { column: 'transactionType', value: 'Sales-AO' },
    { column: 'submittedOn', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
    { column: 'orderType', value: 'Transaction' },
];
const initialHistoryTabFilter: IFilterBranch[] = [
    { column: 'transactionType', value: 'Sales-AO' },
    { column: 'lastUpdated', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
    { column: 'orderType', value: 'Transaction' },
];
const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};

export interface IDailySummaryState {
    count: number;
    result: string[];
}
interface IDashboardBranchPageProps {
    fromInbox?: {
        currentBranchId: string;
        status: boolean;
    };
    currentTab?: string;
}
type confirmVerify = {
    state: boolean;
    trxRef: string[];
    expand: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UseDashboardBranchHandler = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);

    const { ws } = useContext(WebSocketContext);
    const {
        // batchCutOffCount,
        cutOffTimePills,
        dailySummaryTrxCount,
        dailyV2,
        dateFilter,
        enableBulkVerify,
        filterFundingTypes,
        filterInput,
        filterInputDaily,
        filterProductTypes,
        filterSelectStates,
        fundTypePills,
        getBranchDashboardDailyV2,
        getDailySummaryCount,
        getOtherTabsData,
        hardcopy,
        history,
        loading,
        loadingHandler,
        page,
        pages,
        rerouted,
        resultLimit,
        searchbarFilterTags,
        searchInput,
        setBatchCutOffCount,
        setCutOffTimePills,
        setDateFilter,
        setDisableResultLimit,
        setFilterFundingTypes,
        setFilterInput,
        setFilterInputDaily,
        setFilterProductTypes,
        setFilterSelectStates,
        setFundTypePills,
        setPage,
        setResultLimit,
        setSearchbarFilterTags,
        setSearchInput,
        setSortData,
        setTab,
        setTempFilters,
        setTempTarget,
        sortData,
        tab,
        tabsCount,
        tempFilters,
        upcoming,
        filterAccountTypes,
        setFilterAccountTypes,
        setOrderTypePills,
        orderTypePills,
        setFilterRiskTypes,
        setFilterBatchCutoffTypes,
        setFilterStatusTypes,
        getCrOrders,
        getAllCrOrders,
        getAllDailyV2,
        getAllOtherTabsData,
    } = useContext(DashboardBranchContext);

    const { setPreviousPageURL } = useContext(TrackOrderContext);

    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // get permission object from session storage
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;
    const { pendingTab, upcomingTab, hardCopyTab, reRoutedTab, historyTab } = parsedPermissionPrivilege;
    const _history = useHistory();
    const branchId = sessionStorage.getItem('branchId');
    const TrackOrderButton: IActionButton = {
        title: 'Track Order',
        type: 'secondary',
        isDropdown: false,
        actionIcon: 'trackIcon',
        testId: 'track-order',
        disabled: loading,
    };
    // Common States
    const [lastUpdated, setLastUpdated] = useState<string>('Last Updated'); //Header dropdown states
    const [submittedOn, setSubmittedOn] = useState<string>('Submitted On');
    const [expand, setExpand] = useState<boolean>(false); //Filter expansion Panel Visibility State
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State
    //Location Variable
    const locationState = useLocation<IDashboardBranchPageProps>();
    const [pillFundType, setPillFundType] = useState<number>(0); //FundType Pill State
    // const [pillCutOffTime, setPillCutOffTime] = useState<number>(-1); //CutOff Time Pill State
    const [showCheckBox, setShowCheckbox] = useState<boolean>(false); // Toggle Checkbox
    const [showBanner, setShowBanner] = useState<boolean>(false); // Toggle Banner
    const [selectedRows, setSelectedRows] = useState<ITableData[]>([]); // Selected Rows State
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle Action Modals
    const [bulkModal, setBulkModal] = useState<boolean>(false); //Toggle bulk verify modal
    const [trackModal, setTrackModal] = useState<boolean>(false); //modal for track order
    const [bannerType, setBannerType] = useState<string>(''); // Toggle Banner Type - Bulk Verify,Transaction,Daily Summary
    const [downloadLink, setDownloadLink] = useState<string>(''); //Downloadable link returned from APIs
    const [progressModal, setProgressModal] = useState<boolean>(false); //Progress Modal for transaction report generation
    const [progressWidth, setProgressWidth] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const [startTime, setStartTime] = useState(60); //60Seconds
    const [prevFilterState, setPrevFilterState] = useState<ISort_FilterInput>(initialDailyFilter); // Previous state for filters before export report
    const [selectAllFromBanner, setSelectAllFromBanner] = useState<boolean>(false); //To handle the select all shortcut from the Banner for export reports and Bulk verify
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>(''); // datepicker error messages state
    const [confirmVerify, setConfirmVerify] = useState<confirmVerify>({ state: false, trxRef: [], expand: false }); // Modal state for Confirm Modal for Bulk Verify Transactions
    const [disableSearchBarButtons, setDisableSearchBarButtons] = useState<boolean>(false); //Disabled state for Bulk Verify and Export Report in SearchBar for Daily tab
    // Other Tabs Data
    const [received, setReceived] = useState(false); //Hardcopy received or not
    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const dueDateTab = parsedPermissionPrivilege.dueDateTab;
    const initialRenderTabs = useRef(true);
    const initialRenderFilters = useRef(true);
    const renderBulkVerify = useRef(true);
    /** @function Handle Search Placeholders for each Tab  */
    const handlePlaceHolder = () => {
        let placeholder = '';
        //
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily': {
                placeholder =
                    LABEL.branchDailySearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'upcoming': {
                placeholder =
                    LABEL.branchUpcomingSearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'physical doc': {
                placeholder =
                    LABEL.branchHardCopySearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'rerouted': {
                placeholder =
                    LABEL.branchReroutedSearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'history': {
                placeholder =
                    LABEL.branchHistorySearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
        }
        return placeholder;
    };

    const reInitializeDashboard = () => {
        if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
            setFundTypePills({
                tab: 0,
                label: 'Fund Types:',
                tags: [
                    {
                        active:
                            locationState.state?.fromInbox !== undefined && locationState.state.fromInbox.status
                                ? false
                                : true,
                        index: 0,
                        title: 'Money Sighted',
                        testId: 'nmoney-sighted',
                    },
                    { active: false, index: 1, title: 'Non-Money Sighted', testId: 'nonmoney-sighted' },
                ],
            });
        }
        if (
            (tabs[tab].name.toLowerCase() === 'rerouted' &&
                (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'history' &&
                (historyTab.isAll === 'true' || historyTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'daily' &&
                (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker))
        ) {
            setOrderTypePills({
                tab: tab,
                label: 'Order Types:',
                tags: [
                    { active: true, index: 0, title: 'Transactions', testId: 'transaction' },
                    { active: false, index: 1, title: 'Change Request', testId: 'change-request' },
                ],
            });
        }
        setSearchbarFilterTags([]);
        showBanner ? setShowBanner(!showBanner) : null;
        setBannerType('');
        tabs[tab].name.toLowerCase() === 'daily' ? setTempFilters(initialDailyFilter) : null;
        setPillFundType(0);
        setBatchCutOffCount({ batchCount: [], batchTags: [] });
        setFilterProductTypes([]);
        setFilterFundingTypes([]);
        setFilterAccountTypes([]);
        setLastUpdated('Last Updated');
        setSubmittedOn('Submitted On');
        setFilterSelectStates(initialFilterSelectStatesBranch);
        setShowCheckbox(false);
        setDisableSearchBarButtons(false);
        setTempTarget([null, null] as [moment.Moment | null, moment.Moment | null]);
        setPage(1);
        setSearchInput({ value: '', column: 'all' });
        setFilterInputDaily(initialDailyFilter);
        if (tabs[tab].name.toLowerCase() === 'history') {
            setDateFilter(initialDateFilterBranch);
            setTempFilters(initialHistoryTabFilter);
            setFilterInput(initialHistoryTabFilter);
        } else {
            setDateFilter({ dateSort: 'submittedOn', range: '' });
            if (tabs[tab].name.toLowerCase() !== 'daily') {
                setTempFilters(initialOtherTabFilter);
                setFilterInput(initialOtherTabFilter);
            }
        }

        tabs[tab].name.toLowerCase() === 'history'
            ? setSortData([{ column: 'lastUpdated', value: 'ascending' }])
            : setSortData([{ column: 'submittedOn', value: 'ascending' }]);
    };
    const clearContext = () => {
        if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
            setFundTypePills({
                tab: 0,
                label: 'Fund Types:',
                tags: [
                    {
                        active: true,
                        index: 0,
                        title: 'Money Sighted',
                        testId: 'nmoney-sighted',
                    },
                    { active: false, index: 1, title: 'Non-Money Sighted', testId: 'nonmoney-sighted' },
                ],
            });
        }
        if (
            (tabs[tab].name.toLowerCase() === 'rerouted' &&
                (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'history' &&
                (historyTab.isAll === 'true' || historyTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'daily' &&
                (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker))
        ) {
            setOrderTypePills({
                tab: 0,
                label: 'Order Types:',
                tags: [
                    { active: true, index: 0, title: 'Transactions', testId: 'transaction' },
                    { active: false, index: 1, title: 'Change Request', testId: 'change-request' },
                ],
            });
        }
        setPage(1);
        setSearchInput({ value: '', column: 'all' });
        setFilterInputDaily(initialDailyFilter);
        setFilterSelectStates(initialFilterSelectStatesBranch);
        if (tabs[tab].name.toLowerCase() === 'history') {
            setDateFilter(initialDateFilterBranch);
            setTempFilters(initialHistoryTabFilter);
            setFilterInput(initialHistoryTabFilter);
        } else {
            setDateFilter({ dateSort: 'submittedOn', range: '' });
            if (tabs[tab].name.toLowerCase() !== 'daily') {
                setTempFilters(initialOtherTabFilter);
                setFilterInput(initialOtherTabFilter);
            }
        }
        tabs[tab].name.toLowerCase() === 'daily' ? setTempFilters(initialDailyFilter) : null;
        setPillFundType(0);
        setBatchCutOffCount({ batchCount: [], batchTags: [] });
        setFilterProductTypes([]);
        setFilterFundingTypes([]);
        setFilterAccountTypes([]);
        setLastUpdated('Last Updated');
        setSubmittedOn('Submitted On');
        setTab(0);
        setTempTarget([null, null] as [moment.Moment | null, moment.Moment | null]);
        setSearchbarFilterTags([]);
    };

    /**@function Fetch data based  tab given @param tab number */
    const fetchData = (_tab: string) => {
        if (_history.location.pathname.includes('all-branches')) {
            if (orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0) {
                getAllCrOrders(selectedTab(tabs[tab].name.toLowerCase()));
            } else
                _tab.toLowerCase() === 'daily'
                    ? getAllDailyV2()
                    : getAllOtherTabsData(selectedTab(tabs[tab].name.toLowerCase()));
        } else {
            if (orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0) {
                // console.log('hi');
                getCrOrders(selectedTab(tabs[tab].name.toLowerCase()));
            } else
                _tab.toLowerCase() === 'daily'
                    ? getBranchDashboardDailyV2()
                    : getOtherTabsData(selectedTab(tabs[tab].name.toLowerCase()));
        }
    };
    //Queries

    const getExportList = async () => {
        try {
            const exportQuery =
                selectedTab(tabs[tab].name.toLowerCase()) !== 'physical doc'
                    ? exportListQueryPicker(lastUpdated, selectedTab(tabs[tab].name.toLowerCase()) === 'daily')
                    : exportListQueryPicker('submittedOn', false);
            const response: any = await API.graphql(
                graphqlOperation(exportQuery, {
                    input: {
                        tab: selectedTab(tabs[tab].name.toLowerCase()),
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        branchId: branchId !== null ? branchId : '',
                        filter: tabs[tab].name.toLowerCase() === 'daily' ? filterInputDaily : filterInput,
                        endpoint: 'branchdashboardv2',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;
            const decoupledData =
                selectedTab(tabs[tab].name.toLowerCase()) === 'daily'
                    ? decoupleGroupedTrx(result.branchdashboardv2Grouping.grouping)
                    : result.branchdashboardv2NonGrouping;
            exportList('Branch', tabs[tab].name.toLowerCase(), decoupledData);
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'branch-error-modal',
            });
        }
    };

    const getExportListAllSubmitted = async () => {
        try {
            const exportQuery =
                selectedTab(tabs[tab].name) !== 'physical doc'
                    ? allExportListQueryPicker(lastUpdated, selectedTab(tabs[tab].name) === 'daily')
                    : allExportListQueryPicker('submittedOn', false);
            const response: any = await API.graphql(
                graphqlOperation(exportQuery, {
                    input: {
                        tab: selectedTab(tabs[tab].name),
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        branchId: '',
                        filter: tabs[tab].name === 'daily' ? filterInputDaily : filterInput,
                        endpoint: 'allsubmitteddashboard',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;
            const decoupledData =
                selectedTab(tabs[tab].name) === 'daily'
                    ? decoupleGroupedTrx(result.allsubmitteddashboardGrouping.grouping)
                    : result.allsubmitteddashboardNonGrouping;
            exportList('All Submitted', tabs[tab].name, decoupledData);
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'branch-error-modal',
            });
        }
    };

    // Mutation
    /** @description: The Parent function for transaction report download */
    const downloadTransactionReport = async () => {
        // Retrieving the transaction Refs of the selected rows
        setProgressWidth(0);
        setProgressModal(true);
        // setTimeLeft(selectedRows.length * 25);
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        if (ws !== undefined) {
            downloadReportAsZip(transactionRefs);
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);
                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;
                        if (result.link !== null) {
                            setDownloadLink(result.link);
                            setProgressWidth(100);
                            setTimeLeft(0);
                            setTimeout(() => {
                                setProgressModal(false);
                                setShowModal(!showModal);
                            }, 500);
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;
                        handleErrorHandler();
                        setErrorMessage({
                            ...errorMessage,
                            message: error.message,
                            errorCode: error.errorCode,
                            title: 'Cannot Generate Zip File',
                            testId: 'branch-error-modal',
                        });
                        setProgressModal(false);
                        setProgressWidth(0);
                    }
                }
            });
        }
    };
    /**
     * @param ref: Array of selected transaction refs
     * @description This query is called once the function downloadReports is executed to get the download link for the zip file containing the reports
     * */
    const downloadReportAsZip = async (transactionRefs: string[]) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionReportZip, {
                    input: {
                        transactionRef: transactionRefs,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.downloadDaily;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.downloadDaily.data;

            if (result.link !== null) {
                typeof result.link === 'string' ? setTimeLeft(parseInt(result.link)) : setTimeLeft(result.link);
                setStartTime(parseInt(result.link));
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Zip File',
                testId: 'branch-error-modal',
            });
            setProgressModal(false);
            setProgressWidth(0);
        }
    };
    const handleConfirmVerifyModal = () => {
        setConfirmVerify({ ...confirmVerify, expand: !confirmVerify.expand });
    };
    const getModalMessage = (selectedTrxns: ITableData[]) => {
        const temp = [...selectedTrxns];
        const transactionRefs = temp.map((item) => {
            const tempRef = `${item.orderNo}${item.transactionRef}`;
            return tempRef as string;
        });
        return (
            <FlexedDiv direction="column">
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage}</Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoMessage}</Subtitle>
                <CustomSpacer space={'1rem'} />
                <CollapseWrapper removeBorder={confirmVerify.expand}>
                    <CollapsibleDisplay
                        displayTextForExpand={CE_DASHBOARD_LABELS.DAILY.viewMore}
                        displayTextForCollapse={CE_DASHBOARD_LABELS.DAILY.viewLess}
                        textColor="#002043"
                        expanded={confirmVerify.expand}
                        handleExpand={() => handleConfirmVerifyModal()}
                        testId="confirm"
                        content={
                            <div>
                                <Bold
                                    style={{ fontSize: '0.75rem' }}
                                >{`${transactionRefs.length} selected transactions`}</Bold>
                                <TransactionRefList>
                                    {transactionRefs.map((item, index) => (
                                        <li key={index + 1}>{item}</li>
                                    ))}
                                </TransactionRefList>
                            </div>
                        }
                    />
                </CollapseWrapper>
            </FlexedDiv>
        );
    };
    const handleBulkVerifyTransactions = () => {
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        setConfirmVerify({ ...confirmVerify, trxRef: transactionRefs, state: true });

        // set confirmation modal data
        modalMessages.confirmation.heading = `Verify ${transactionRefs.length} transactions?`;
        modalMessages.confirmation.type = 'verify';
        if (pendingTab.actions.canVerifyTransactions === 'auto-authorizer') {
            // set feedback modal data
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>{`${transactionRefs.length} transactions`}</Bold>
                    &nbsp; has been verified. Adviser will be notified once all transactions in this order has been
                    verified.
                </p>
            );
        } else {
            // set feedback modal data
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                    This request has been submitted and pending for review.
                </p>
            );
        }
    };
    const bulkVerifyTransactions = async () => {
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: {
                        transactionRef: transactionRefs,
                        action: 'Approve',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionStatus.data;

            if (result.status === true) {
                loadingHandler();
                setBulkModal(!bulkModal);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Bulk Verify Transactions',
                testId: 'branch-error-modal',
            });
        }
    };

    const trackOrder = () => {
        if (_history.location.pathname.includes('all-branches')) _history.push(ROUTES.hqAllSubmissionsTrackOrder);
        else
            _history.push(
                branchId !== null && branchId !== '' ? ROUTES.hqSingleBranchTrackOrder : ROUTES.branchTrackOrder,
            );
    };

    const downloadDailySummary = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(dailySummaryReportFormA, {
                    input: {
                        branchId: branchId !== null ? branchId : '',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dailySummaryReport;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.dailySummaryReport.data;
            setDownloadLink(result.path);
            loadingHandler();
            setShowModal(!showModal);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Download Daily Summary Report',
                testId: 'branch-error-modal',
            });
            fetchData('daily');
        }
    };
    const downloadPPA = async () => {
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(downloadPPAQuery, {
                    input: {
                        transactionRef: transactionRefs,
                        tab: 'daily',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.ppaDownload;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.ppaDownload.data;

            setDownloadLink(result.link);
            loadingHandler();
            setShowModal(!showModal);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Download PPA Account Opening Form',
                testId: 'branch-error-modal',
            });
        }
    };
    //Pagination Functions
    const handleNext = () => {
        if (page < pages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };
    // Receive Hardcopy
    const handleReceived = () => {
        setReceived(!received);
        setTimeout(() => {
            fetchData('hardcopy');
        }, 1000);
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };
    // Search Primary
    const handleExportReportDropdown = (item: string) => {
        item !== undefined ? setShowCheckbox(!showCheckBox) : null;
        switch (item) {
            case LABEL.transactionsReport:
                setResultLimit(10); //Restricting download limit to 10
                setDisableResultLimit(true); //Disabling Result limit dropdown
                setBannerType(LABEL.transactionsReport);
                setSearchbarFilterTags([{ label: 'Status', value: 'Pending' }]);
                handleFilterTableForExportReport(LABEL.transactionsReport);
                break;
            case LABEL.dailySummaryReportFormA:
                setBannerType(LABEL.dailySummaryReportFormA);
                setSearchbarFilterTags([{ label: 'Status', value: 'Completed' }]);
                handleFilterTableForExportReport(LABEL.dailySummaryReportFormA);
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                setBannerType(LABEL.ppaOnlineAccountOpeningForm);
                setResultLimit(10); //Restricting download limit to 10
                setDisableResultLimit(true); //Disabling Result limit dropdown
                setSearchbarFilterTags([
                    { label: 'Status', value: 'Completed' },
                    { label: 'Product Type', value: 'PRS' },
                ]);
                handleFilterTableForExportReport(LABEL.ppaOnlineAccountOpeningForm);
                break;
        }
        setDisableSearchBarButtons(!disableSearchBarButtons);
        setShowBanner(!showBanner);
    };
    const handleFilterTableForExportReport = (filterAction: string) => {
        const currentFilter = [...filterInputDaily];
        switch (filterAction) {
            case LABEL.transactionsReport:
                setPrevFilterState(currentFilter);
                const tempTrx = [...currentFilter];
                const trxFilter = statusSplice(tempTrx, 'pending');
                setTempFilters(trxFilter);
                setFilterInputDaily(trxFilter);
                break;
            case LABEL.dailySummaryReportFormA:
                setPrevFilterState(currentFilter);
                const tempDsa = [...currentFilter];
                statusSplice(tempDsa, 'completed');
                setTempFilters(tempDsa);
                setFilterInputDaily(tempDsa);
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                setPrevFilterState(currentFilter);
                const tempPpa = [...currentFilter];
                statusSplice(tempPpa, 'completed');
                setTempFilters(tempPpa);
                setFilterInputDaily(tempPpa);
                break;
            case LABEL.bulkTransactionVerification:
                setPrevFilterState(currentFilter);
                const tempBulkVerify = [...currentFilter];
                statusSplice(tempBulkVerify, 'in review');
                setTempFilters(tempBulkVerify);
                setFilterInputDaily(tempBulkVerify);
                break;
            case 'revert':
                setTempFilters(prevFilterState);
                setFilterInputDaily(prevFilterState);
                break;
        }
    };
    // Search Secondary Actions
    const handleSearchActions = (action: IActionButton) => {
        action.title === 'Export List'
            ? _history.location.pathname.includes('all-branches')
                ? getExportListAllSubmitted()
                : getExportList()
            : null;
        action.title === 'Bulk Verify' ? handleBulkVerify() : null;
    };

    // opens modal for track order
    const OpenTrackOrderModal = () => {
        setTrackModal(true);
    };

    const closeTrackModal = () => {
        setTrackModal(false);
    };
    // Handle Bulk Verify
    const handleBulkVerify = () => {
        handleFilterTableForExportReport(LABEL.bulkTransactionVerification);
        setShowCheckbox(!showCheckBox);
        setBannerType(LABEL.bulkTransactionVerification);
        setDisableSearchBarButtons(!disableSearchBarButtons);
        setSearchbarFilterTags([{ label: 'Status', value: 'In Review' }]);
        setShowBanner(!showBanner);
    };

    const handleDownload = () => {
        switch (bannerType) {
            case LABEL.transactionsReport:
                setDisableResultLimit(false);
                ws !== undefined ? ws.send('Ping') : null;
                downloadTransactionReport();
                break;
            case LABEL.bulkTransactionVerification:
                handleBulkVerifyTransactions();
                break;
            case LABEL.dailySummaryReportFormA:
                loadingHandler();
                downloadDailySummary();
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                setDisableResultLimit(false);
                loadingHandler();
                downloadPPA();
                break;
            default:
                break;
        }
    };
    //Daily Filter functions
    const handlePillFundType = (item: IDashboardFilterTags) => {
        const temp = [...tempFilters];
        const tempPill = { ...fundTypePills };
        if (item.index === 0 && item.active === false) {
            temp[0] = { column: 'fundCategory', value: 'MONEY MARKET' };
            tempPill.tags[item.index].active = true;
            tempPill.tags[1].active = false;
        } else if (item.index === 0 && item.active) {
            temp[0] = { column: 'fundCategory', value: '' };
            tempPill.tags[item.index].active = false;
        }
        if (item.index === 1 && item.active === false) {
            temp[0] = { column: '!fundCategory', value: 'MONEY MARKET' };
            tempPill.tags[item.index].active = true;
            tempPill.tags[0].active = false;
        } else if (item.index === 1 && item.active) {
            temp[0] = { column: '!fundCategory', value: '' };
            tempPill.tags[item.index].active = false;
        }
        setPillFundType(item.index);
        setFundTypePills(tempPill);
        setTempFilters(temp);
        setFilterInputDaily(temp);
    };
    const handlePillCutOffTime = (item: IDashboardFilterTags) => {
        const temp = [...tempFilters];
        const tempPill = cutOffTimePills;

        const itemIndex = item.index;
        if (itemIndex === 0 && item.active === false) {
            temp[1] = { column: 'batchTime', value: 'all' };
            tempPill.tags[0].active = true;
            for (let i = 0; i < tempPill.tags.length; i++) {
                i !== 0 ? (tempPill.tags[i].active = false) : null;
            }
        } else if (item.active === true && itemIndex === 0) {
            temp[1] = { column: 'batchTime', value: '' };
            tempPill.tags[0].active = false;
        } else if (itemIndex !== 0 && item.active === false) {
            temp[1] = { column: 'batchTime', value: item.title };
            tempPill.tags[itemIndex].active = true;
            for (let i = 0; i < tempPill.tags.length; i++) {
                i !== itemIndex ? (tempPill.tags[i].active = false) : null;
            }
        } else if (itemIndex !== 0 && item.active === true) {
            temp[1] = { column: 'batchTime', value: '' };
            tempPill.tags[itemIndex].active = false;
        }
        setCutOffTimePills(tempPill);
        setTempFilters(temp);
        setFilterInputDaily(temp);
    };

    // Common filters
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            setDateFilter({ dateSort: dateFilter.dateSort, range: range });
            const obj: IFilterBranch = {
                column: dateFilter.dateSort,
                value: range,
            };
            const tmp = [...tempFilters];
            if (tabs[tab].name.toLowerCase() === 'daily') {
                tmp[3] = obj;
            } else {
                tmp[1] = obj;
            }
            setTempFilters(tmp);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            const tmp = [...tempFilters];
            if (tabs[tab].name.toLowerCase() === 'daily') {
                tmp[3] = {
                    column: dateFilter.dateSort,
                    value: '',
                };
            } else {
                tmp[1] = {
                    column: dateFilter.dateSort,
                    value: '',
                };
            }
            setTempFilters(tmp);
        }
    };
    const handleDateSorting = (value: string | number) => {
        const tmp = [...tempFilters];
        setDateFilter({ ...dateFilter, dateSort: value.toString() });
        if (tabs[tab].name.toLowerCase() === 'daily') {
            if (tmp.length === 3) {
                tmp.push({ column: value.toString(), value: '' });
            } else {
                tmp[3].column = value.toString();
            }
        } else {
            tmp[1].column = value.toString();
        }
        setTempFilters(tmp);
    };

    //Commented out as not used now but may use to for next phase
    // const handleTransactionTypes = (value: SelectValue) => {
    //     const tmp = [...tempFilters];
    //     const obj = {
    //         column: 'transactionType',
    //         value: value.toString(),
    //     };
    //     tmp.push(obj);
    //     setTempFilters(tmp);
    //     setFilterSelectStates({ ...filterSelectStates, transactionType: value.toString() });
    // };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleProductTypes = (item: React.ReactText[], _fn: string, _id: number) => {
        setFilterProductTypes(item);
        const formattedData = item.map((item) => {
            return item.toString().toLowerCase();
        });
        const tmp = [...tempFilters];
        if (tabs[tab].name.toLowerCase() === 'daily') {
            setTempFilters(tempFilterFunctions(tmp, 'fundType', 4, formattedData));
        } else {
            setTempFilters(tempFilterFunctions(tmp, 'fundType', 2, formattedData));
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleFundingOptions = (item: React.ReactText[], _fn: string, _id: number) => {
        setFilterFundingTypes(item);
        const tmp = [...tempFilters];
        if (tabs[tab].name.toLowerCase() === 'daily') {
            setTempFilters(tempFilterFunctions(tmp, 'paymentMethod', 5, item));
        } else {
            setTempFilters(tempFilterFunctions(tmp, 'paymentMethod', 3, item));
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleAccountTypes = (item: React.ReactText[], _fn: string, _id: number) => {
        setFilterAccountTypes(item);
        const tmp = [...tempFilters];
        tabs[tab].name.toLowerCase() === 'daily'
            ? setTempFilters(tempFilterFunctions(tmp, 'accountType', 6, item))
            : setTempFilters(tempFilterFunctions(tmp, 'accountType', 4, item));
    };

    const handleCancelFilter = () => {
        const temp = tabs[tab].name.toLowerCase() === 'history' ? initialHistoryTabFilter : initialOtherTabFilter;
        const tempDaily = [...tempFilters];
        const tempSort = [...sortData];
        if (tabs[tab].name.toLowerCase() === 'history') {
            setLastUpdated(LABEL.lastUpdated);
            setDateFilter(initialDateFilterBranch);
            tempSort[0].column === 'submittedOn' ? setSortData([{ column: 'lastUpdated', value: 'ascending' }]) : null;
        } else {
            setSubmittedOn(LABEL.submittedOn);
            setDateFilter({ dateSort: 'submittedOn', range: '' });
            tempSort[0].column === 'lastUpdated' ? setSortData([{ column: 'submittedOn', value: 'ascending' }]) : null;
        }
        setSearchbarFilterTags([]);
        tabs[tab].name.toLowerCase() === 'daily' ? removeDailyFilters(tempDaily) : setFilterInput(temp);
        setFilterSelectStates(initialFilterSelectStatesBranch);
        setFilterProductTypes([]);
        setFilterFundingTypes([]);
        setFilterAccountTypes([]);
        setFilterStatusTypes([]);
        setTempTarget([null, null]);
    };
    const removeDailyFilters = (temp: Array<IFilterBranch>) => {
        const removedFilterArray: Array<IFilterBranch> = [];
        temp.map((item) => {
            switch (item.column) {
                case 'fundCategory':
                    removedFilterArray.push(item);
                    break;
                case '!fundCategory':
                    removedFilterArray.push(item);
                    break;
                case 'batchTime':
                    removedFilterArray.push(item);
                    break;
                case 'transactionType':
                    removedFilterArray.push(item);
                    break;
                case 'orderType':
                    removedFilterArray.push(item);
                    break;
            }
        });
        removedFilterArray.push(
            { column: 'submittedOn', value: '' },
            { column: 'fundType', value: '' },
            { column: 'paymentMethod', value: '' },
            { column: 'accountType', value: '' },
        );
        setTempFilters(removedFilterArray);
        setFilterInputDaily(removedFilterArray);
    };
    const handleApplyFilter = () => {
        let _searchFilterTags: ISelectValue[] = [];
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily':
                switch (tempFilters[3].column) {
                    case 'lastUpdated':
                        setSubmittedOn(LABEL.lastUpdated);
                        break;
                    case 'submittedOn':
                        setSubmittedOn(LABEL.submittedOn);
                        break;
                }
                _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
                setSearchbarFilterTags(_searchFilterTags);
                setFilterInputDaily(tempFilters);
                setSortData([{ column: tempFilters[3].column, value: 'ascending' }]);
                break;
            default:
                let currentFilterLabel = '';
                switch (tempFilters[1].column) {
                    case 'lastUpdated':
                        currentFilterLabel = LABEL.lastUpdated;
                        break;
                    case 'submittedOn':
                        currentFilterLabel = LABEL.submittedOn;
                        break;
                }
                tabs[tab].name.toLowerCase() === 'history'
                    ? setLastUpdated(currentFilterLabel)
                    : setSubmittedOn(currentFilterLabel);
                _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
                setSearchbarFilterTags(_searchFilterTags);
                setFilterInput(tempFilters);
                setSortData([{ column: tempFilters[1].column, value: 'ascending' }]);
                break;
        }
        setExpand(!expand);
    };

    const getSecondaryButtons = () => {
        const secondaryButtons: IActionButton[] = [];
        let section = 'pendingTab';
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily': {
                section = 'pendingTab';
                break;
            }
            case 'upcoming': {
                section = 'upcomingTab';
                break;
            }
            case 'physical doc': {
                section = 'hardCopyTab';
                break;
            }
            case 'rerouted': {
                section = 'reRoutedTab';
                break;
            }
            case 'history': {
                section = 'historyTab';
                break;
            }
        }
        const currentSection: any = hasKey(parsedPermissionPrivilege, section)
            ? parsedPermissionPrivilege[section]
            : parsedPermissionPrivilege.pendingTab;
        if (currentSection.actions.canExportList === LABEL.maker) {
            secondaryButtons.push({
                type: 'secondary',
                title: 'Export List',
                actionIcon: 'export',
                disabled:
                    tabsCount[tab] === 0 ||
                    loading ||
                    disableSearchBarButtons ||
                    tabs[tab].name.toLowerCase() === 'daily'
                        ? decoupleGroupedTrx(dailyV2).length === 0
                        : decoupleNonGroupedTrx(getTabs(selectedTab(tabs[tab].name.toLowerCase()))).length === 0,
                testId: 'export-list-btn',
            });
        }
        if (
            currentSection.actions.canVerifyTransactions === LABEL.maker ||
            currentSection.actions.canVerifyTransactions === LABEL.autoAuthorizer
        ) {
            secondaryButtons.push({
                type: 'secondary',
                title: 'Bulk Verify',
                actionIcon: 'bulk',
                testId: 'bulk-verify-btn',
                disabled: tabsCount[tab] === 0 || enableBulkVerify === false || disableSearchBarButtons || loading,
            });
        }
        return secondaryButtons;
    };

    const getExportReportPermissionsCheck = (): IDropdownItem[] => {
        const dropdown: IDropdownItem[] = [];
        const menuItemDailySummaryFormA: IDropdownItem =
            dailySummaryTrxCount.count !== 0
                ? {
                      item: LABEL.dailySummaryReportFormA,
                      handleItem: handleExportReportDropdown,
                      testId: `daily-summary-report-btn`,
                      disabled: loading,
                  }
                : {
                      item: LABEL.dailySummaryReportFormA,
                      handleItem: handleExportReportDropdown,
                      disabled: true,
                      tooltipMessage: LABEL.noCompletedTransactionMessage,
                      testId: `daily-summary-report-btn`,
                  };
        const menuItemTransactionReport: IDropdownItem = {
            item: LABEL.transactionsReport,
            handleItem: handleExportReportDropdown,
            testId: `daily-trnsactions-report-btn`,
            disabled: loading,
        };
        const menuItemPPAOnlineAccountOpeningForm: IDropdownItem = {
            item: LABEL.ppaOnlineAccountOpeningForm,
            handleItem: handleExportReportDropdown,
            testId: `daily-ppa-form-btn`,
            disabled: loading,
        };
        pendingTab.actions.canDownloadDailySummary === LABEL.maker ? dropdown.push(menuItemDailySummaryFormA) : null;
        pendingTab.actions.canDownloadTransactionReport === LABEL.maker
            ? dropdown.push(menuItemPPAOnlineAccountOpeningForm)
            : null;
        pendingTab.actions.canDownloadTransactionReport === LABEL.maker
            ? dropdown.push(menuItemTransactionReport)
            : null;
        return dropdown;
    };

    const disableBannerButton = (): boolean => {
        let temp = false;
        if (
            bannerType === LABEL.transactionsReport ||
            bannerType === LABEL.bulkTransactionVerification ||
            bannerType === LABEL.ppaOnlineAccountOpeningForm
        ) {
            temp = selectedRows.length === 0;
        } else if (bannerType === LABEL.dailySummaryReportFormA) {
            temp = dailySummaryTrxCount.count === 0;
        }
        return temp;
    };
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsBranch.daily.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `branch-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    const handleOrderTypePills = (item: IDashboardFilterTags) => {
        setOrderTypePills({
            ...orderTypePills,
            tags: orderTypePills.tags.map((pill) => {
                return { ...pill, active: pill.title === item.title && pill.index === item.index };
            }),
        });
        const temp = [
            {
                column: 'orderType',
                value: item.index === 0 ? 'Transaction' : 'Change Request',
            },
        ];
        setTempFilters(temp);
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily':
                setFilterInputDaily(temp);
                break;
            case 'rerouted':
            case 'history':
                setFilterInput(temp);
                break;
            default:
                break;
        }
    };

    const handleStatusTypeFilter = (item: React.ReactText[], fn: string, _id: number) => {
        setFilterStatusTypes(item);
        const tmp = [...tempFilters].filter((filter) => filter.column !== 'status');
        item.map((filterItem) => {
            tmp.push({ column: 'status', value: filterItem as string });
        }),
            setTempFilters(tmp);
        console.log('handle status', item, tempFilters, tmp, 'fn', fn);
    };

    const handleRiskTypeFilter = (item: React.ReactText[]) => {
        setFilterRiskTypes(item);
        const tmp = [...tempFilters].filter((filter) => filter.column !== 'riskCategory');
        item.map((filterItem) => {
            tmp.push({ column: 'status', value: filterItem as string });
        }),
            setTempFilters(tmp);
    };

    const handleBatchCutoffTypeFilter = (item: React.ReactText[]) => {
        setFilterBatchCutoffTypes(item);
        // const tmp = [...tempFilters];
        // tabs[tab].name.toLowerCase() === 'daily'
        //     ? setTempFilters(tempFilterFunctions(tmp, 'risk', 6, item))
        //     : setTempFilters(tempFilterFunctions(tmp, 'risk', 4, item));
    };

    const getTabs = (item: string) => {
        const temp: ITransactionWithoutGrouping = {
            data: [],
        };
        switch (item) {
            case 'reroute':
                temp.data = rerouted.current;
                break;
            case 'hardcopy':
                temp.data = hardcopy.current;
                break;
            case 'upcoming':
                temp.data = upcoming.current;
                break;
            default:
                temp.data = history.current;
                break;
        }
        return temp;
    };

    const tabs: IDashboardTabs[] = [];
    if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'daily',
            table: (
                <Daily
                    filters={filterInputDaily}
                    submittedOnDropdown={submittedOn}
                    searchInput={searchInput.value}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setFilters={setFilterInputDaily}
                    setSubmittedOnDropdown={setSubmittedOn}
                    setSortData={setSortData}
                    showCheckBox={showCheckBox}
                    sortData={sortData}
                    redirect={fetchData}
                    downloadType={bannerType}
                    pendingTab={pendingTab}
                    dailySummaryState={dailySummaryTrxCount}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={
                        pillFundType === 0
                            ? JSON.stringify(initialDailyFilter) !== JSON.stringify(filterInputDaily)
                            : JSON.stringify(initialDailyFilterNonMoney) !== JSON.stringify(filterInputDaily)
                    }
                    loadingHandler={loadingHandler}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                    selectAllFromBanner={selectAllFromBanner}
                />
            ),
            testId: 'daily-tab',
        });
    }
    if (upcomingTab.isAll === 'true' || upcomingTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'upcoming',
            table: (
                <Upcoming
                    filters={filterInput}
                    submittedOnDropdown={submittedOn}
                    searchInput={searchInput.value}
                    setFilters={setFilterInput}
                    setSubmittedOnDropdown={setSubmittedOn}
                    setSortData={setSortData}
                    sortData={sortData}
                    upcomingTab={upcomingTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                />
            ),
            testId: 'upcoming-tab',
        });
    }
    if (hardCopyTab.isAll === 'true' || hardCopyTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'physical doc',
            table: (
                <WithHardcopy
                    handleReceived={handleReceived}
                    searchInput={searchInput.value}
                    setSortData={setSortData}
                    sortData={sortData}
                    hardcopyTab={hardCopyTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'withhardcopy-tab',
        });
    }
    if (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'rerouted',
            table: (
                <Rerouted
                    filters={filterInput}
                    submittedOnDropdown={submittedOn}
                    searchInput={searchInput.value}
                    setFilters={setFilterInput}
                    setSubmittedOnDropdown={setSubmittedOn}
                    sortData={sortData}
                    rerouteTab={reRoutedTab}
                    dueDateTab={dueDateTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'rerouted-tab',
        });
    }
    if (historyTab.isAll === 'true' || historyTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'history',
            table: (
                <History
                    filters={filterInput}
                    lastUpdatedDropdown={lastUpdated}
                    searchInput={searchInput.value}
                    setFilters={setFilterInput}
                    setLastUpdatedDropdown={setLastUpdated}
                    setSortData={setSortData}
                    sortData={sortData}
                    historyTab={historyTab}
                    loadingHandler={loadingHandler}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'history-tab',
        });
    }

    const SelectAllBannerShortcut = () => {
        return (
            <LinkButton buttonColor="#0089EC" onClick={() => setSelectAllFromBanner(!selectAllFromBanner)}>
                {decoupleGroupedTrx(dailyV2).length === 1
                    ? `Select 1 Order`
                    : `Select All ${decoupleGroupedTrx(dailyV2).length} Orders`}
            </LinkButton>
        );
    };
    //Fn for ExternalLink for Searchbar
    const getExternalLink = (): ExternalLink | undefined => {
        let temp = undefined;
        if (_history.location.pathname === '/hq/dashboard/view-branch')
            temp = { title: 'View Pending Submission Order', link: '/hq/dashboard/view-branch/pending-submission' };
        return temp;
    };
    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setConfirmVerify({ ...confirmVerify, state: !confirmVerify.state, expand: false });
    };
    const handleRemoveFilter = (item: ISelectValue, index: number) => {
        let tempValues = [...searchbarFilterTags];
        tempValues = removeArrayElement(tempValues, tempValues[index]);
        setSearchbarFilterTags(tempValues);
        let arrayIndex = -1;
        switch (item.label) {
            case LABEL.status:
                setFilterSelectStates({ ...filterSelectStates, status: undefined });
                break;
            case LABEL.accountTypes:
                const tempAccountTypes = [...filterAccountTypes];
                arrayIndex = tempAccountTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempAccountTypes.splice(arrayIndex, 1) : null;
                setFilterAccountTypes(tempAccountTypes);
                break;
            case LABEL.productTypes:
                const tempProductTypes = [...filterProductTypes];
                arrayIndex = tempProductTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempProductTypes.splice(arrayIndex, 1) : null;
                setFilterProductTypes(tempProductTypes);
                break;
            case LABEL.fundingOptions:
                const tempFundingOptions = [...filterFundingTypes];
                arrayIndex = tempFundingOptions.indexOf(item.value);
                arrayIndex !== -1 ? tempFundingOptions.splice(arrayIndex, 1) : null;
                setFilterFundingTypes(tempFundingOptions);
                break;
            case LABEL.lastUpdated:
            case LABEL.submittedOn:
            case LABEL.createdOn:
                setTempTarget([null, null]);
                break;
        }
        let _tempFilters = [...tempFilters];
        _tempFilters = removeSearchFilterTagFromFilter(item, tempFilters);
        setTempFilters(_tempFilters);
        tabs[tab].name.toLowerCase() === 'daily' ? setFilterInputDaily(_tempFilters) : setFilterInput(_tempFilters);
    };
    // Main LifeCycle Hooks Resets everything
    useEffect(() => {
        setPreviousPageURL(window.location.pathname);
        if (initialRenderTabs.current) {
            if (locationState.state?.currentTab !== undefined) {
                const name = getTabDisplayName(locationState.state.currentTab);
                tabs.map((ele, index) => {
                    ele.name === name ? setTab(index) : null;
                });
            }
            if (locationState.state?.fromInbox !== undefined && locationState.state.fromInbox.status) {
                setFilterInputDaily(dailyPendingFilter);
            }
            if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
                setFundTypePills({
                    tab: 0,
                    label: 'Fund Types:',
                    tags: [
                        {
                            active:
                                filterInputDaily[0].value.toLowerCase() === LABEL.moneyMarket.toLowerCase() &&
                                filterInputDaily[0].column === 'fundCategory'
                                    ? true
                                    : false,
                            index: 0,
                            title: 'Money Sighted',
                            testId: 'money-sighted',
                        },
                        {
                            active:
                                filterInputDaily[0].value.toLowerCase() === LABEL.moneyMarket.toLowerCase() &&
                                filterInputDaily[0].column === '!fundCategory'
                                    ? true
                                    : false,
                            index: 1,
                            title: 'Non-Money Sighted',
                            testId: 'nonmoney-sighted',
                        },
                    ],
                });
            }
            tabs[tab].name.toLowerCase() === 'history'
                ? setDateFilter(initialDateFilterBranch)
                : setDateFilter({ dateSort: 'submittedOn', range: '' });
            fetchData(tabs[tab].name);
            initialRenderTabs.current = false;
            pendingTab.actions.canDownloadDailySummary === LABEL.maker && tabs[tab].name.toLowerCase() === 'daily'
                ? getDailySummaryCount()
                : null;
        } else {
            initialRenderFilters.current = true;
            reInitializeDashboard();
            pendingTab.actions.canDownloadDailySummary === LABEL.maker && tabs[tab].name.toLowerCase() === 'daily'
                ? getDailySummaryCount()
                : null;
        }
        return function cleanup() {
            if (_history.location.pathname.includes('all-branches')) {
                _history.location.pathname !== '/'
                    ? updateIsSeenDashboard('allSubmittedDashboard', [selectedTab(tabs[tab].name)], idTokenHeader)
                    : null;
                clearContextOnNavigation('allSubmittedDashboard', _history.location.pathname) === false
                    ? clearContext()
                    : null;
            } else {
                _history.location.pathname !== '/'
                    ? updateIsSeenDashboard(
                          'branchDashboardV2',
                          [selectedTab(tabs[tab].name.toLowerCase())],
                          idTokenHeader,
                      )
                    : null;
                clearContextOnNavigation(
                    branchId !== null && branchId !== '' ? 'viewBranch' : 'branchDashboard',
                    _history.location.pathname,
                ) === false
                    ? clearContext()
                    : null;
            }
        };
    }, [tab]);
    //  Fetch data based on Updates in any dependents
    useMemo(() => {
        if (initialRenderFilters.current) {
            initialRenderFilters.current = false;
        } else {
            if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
                setFundTypePills({
                    tab: 0,
                    label: 'Fund Types:',
                    tags: [
                        {
                            active:
                                filterInputDaily[0].value.toLowerCase() === LABEL.moneyMarket.toLowerCase() &&
                                filterInputDaily[0].column === 'fundCategory'
                                    ? true
                                    : false,
                            index: 0,
                            title: 'Money Sighted',
                            testId: 'money-sighted',
                        },
                        {
                            active:
                                filterInputDaily[0].value.toLowerCase() === LABEL.moneyMarket.toLowerCase() &&
                                filterInputDaily[0].column === '!fundCategory'
                                    ? true
                                    : false,
                            index: 1,
                            title: 'Non-Money Sighted',
                            testId: 'nonmoney-sighted',
                        },
                    ],
                });
            }
            fetchData(tabs[tab].name);
            if (bannerType === LABEL.bulkTransactionVerification) {
                renderBulkVerify.current = false;
            }
        }
    }, [page, resultLimit, debouncedSearchTerm, filterInput, filterInputDaily, sortData]);

    return {
        handleAccountTypes,
        handleApplyFilter,
        handleCancelFilter,
        handleConfirmModal,
        handleDate,
        handleDateSorting,
        handleDownload,
        handleFilterTableForExportReport,
        handleFundingOptions,
        handleNext,
        handlePillCutOffTime,
        handlePillFundType,
        handlePlaceHolder,
        handleStatusTypeFilter,
        handlePrevious,
        handleProductTypes,
        handleReceived,
        handleSearchActions,
        handleRemoveFilter,
        getExternalLink,
        searchOptions,
        getSecondaryButtons,
        OpenTrackOrderModal,
        getExportReportPermissionsCheck,
        switchTabs,
        disableBannerButton,
        SelectAllBannerShortcut,
        trackOrder,
        closeTrackModal,
        bulkVerifyTransactions,
        getModalMessage,
        TrackOrderButton,
        tabs,
        expand,
        setExpand,
        showAllTags,
        showCheckBox,
        showBanner,
        selectedRows,
        showModal,
        bulkModal,
        trackModal,
        bannerType,
        downloadLink,
        progressModal,
        progressWidth,
        timeLeft,
        startTime,
        datePickerInvalidMessage,
        confirmVerify,
        disableSearchBarButtons,
        received,
        pendingTab,
        upcomingTab,
        hardCopyTab,
        reRoutedTab,
        historyTab,
        setShowAllTags,
        setShowCheckbox,
        setShowBanner,
        setSelectedRows,
        setShowModal,
        setBulkModal,
        setTrackModal,
        setBannerType,
        setProgressModal,
        setProgressWidth,
        setTimeLeft,
        setDatePickerInvalidMessage,
        setDisableSearchBarButtons,
        handleOrderTypePills,
        handleRiskTypeFilter,
        handleBatchCutoffTypeFilter,
        modalMessages,
    };
};

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;
const CollapseWrapper = styled.div<IOnPropStyles>`
    box-shadow: ${(props) => (props.removeBorder ? `inset 0px 1px 0px #66798e` : `unset`)};
    padding-top: ${(props) => (props.removeBorder ? '1rem' : '0')};
`;
const TransactionRefList = styled.ul`
    margin: 0;
    list-style-position: inside;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333333;
    max-height: 9.5rem;
    overflow: auto;
`;
