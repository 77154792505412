export type filterSelectStatesBranch = {
    accountTypes?: string;
    fundingOption?: string;
    productType?: string;
    transactionType?: string;
    status?: string;
};
export type dateFilterBranch = {
    range: string;
    dateSort: string;
};
export type IFilterBranch = {
    column: string;
    value: string;
};
export const initialFilterSelectStatesBranch: filterSelectStatesBranch = {
    accountTypes: undefined,
    fundingOption: undefined,
    productType: undefined,
    transactionType: 'SALES-AO',
    status: undefined,
};
export const initialDateFilterBranch: dateFilterBranch = {
    dateSort: 'lastUpdated',
    range: '',
};

export type IPermissionsBranchCE = {
    hardCopyTab: {
        actions: {
            canExportList: string;
            canReceiveHardCopy: string;
            canViewDetails: string;
            canViewTransactionList: string;
        };
        isAll: string;
    };
    historyTab: {
        actions: {
            canDownloadTransactionReport: string;
            canExportList: string;
            canViewDetails: string;
            canViewTransactionList: string;
        };
        isAll: string;
    };
    pendingTab: {
        actions: {
            canDownloadDailySummary: string;
            canDownloadTransactionReport: string;
            canExportList: string;
            canReject: string;
            canReroute: string;
            canVerifyTransactions: string;
            canViewDetails: string;
            canViewTransactionList: string;
        };
        reviewApproval: {
            canApproveReRoute: string;
            canApproveReject: string;
            canApproveVerify: string;
        };
        isAll: string;
    };
    reRoutedTab: {
        actions: {
            canDoDueDateExtension: string;
            canExportList: string;
            canViewDetails: string;
            canViewTransactionList: string;
        };
        isAll: string;
    };
    upcomingTab: {
        actions: {
            canExportList: string;
            canViewDetails: string;
            canViewTransactionList: string;
        };
        isAll: string;
    };
};
