import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES, LABEL } from '../../../constants';
import { subTitleStyle, titleStyle } from '../../../styles';
import { useHistory } from 'react-router-dom';
import { hqAdvisers } from '../../../routes';
import { AdvanceTable } from '../../../components/organisms';
import { IAllBranchTab } from '../../../utils/permissionTypes';
import BranchOfficeContext from '../../../context/HQCEContext/branchOffice';

export interface IAllBranchProps {
    allBranchData: ITableData[];
    searchEmpty: boolean;
    searchInput: string;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
    allBranchTab: IAllBranchTab;
}

const AllBranchOffices: React.FC<IAllBranchProps> = ({
    allBranchData,
    allBranchTab,
    searchEmpty,
    searchInput,
    sortData,
    setSortData,
}: IAllBranchProps) => {
    const history = useHistory();
    const { setBranchesId } = useContext(BranchOfficeContext);

    const handleViewBranches = (item: IColumnItemAccordion) => {
        setBranchesId(item.rawData.branchId);
        const data = { item: item, hq: true };
        history.push(hqAdvisers, data);
    };

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };
    const columns: ITableColumn[] = [
        {
            key: [
                { key: 'name', textStyle: { ...titleStyle, fontWeight: sortData[0].column === 'name' ? 700 : 400 } },
                { key: 'code', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '21.1vw',
                paddingLeft: '1.5rem',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'name' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('name'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'name' ? 700 : 400 },
            },
            onPressHeader: () => handleSort('name'),
            title: LABEL.branchName,
            subtitle: LABEL.code,
        },
        {
            key: [{ key: 'state', textStyle: { fontWeight: sortData[0].column === 'state' ? 700 : 400 } }],
            viewStyle: {
                width: '20.56vw',
            },
            title: LABEL.state,
            onPressHeader: () => handleSort('state'),
            titleStyle: {
                fontWeight: sortData[0].column === 'state' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('state'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'state' ? 700 : 400 },
            },
        },
        {
            key: [{ key: 'agentCount', textStyle: { fontWeight: sortData[0].column === 'agentCount' ? 700 : 400 } }],
            viewStyle: {
                width: '16.1vw',
            },
            title: LABEL.numberOfAdvisers,
            onPressHeader: () => handleSort('agentCount'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentCount' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('agentCount'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'agentCount' ? 700 : 400 },
            },
        },
        {
            key: [{ key: 'clientCount', textStyle: { fontWeight: sortData[0].column === 'clientCount' ? 700 : 400 } }],
            viewStyle: {
                width: '14.167vw',
            },
            title: LABEL.numberOfInvestors,
            onPressHeader: () => handleSort('clientCount'),
            titleStyle: {
                fontWeight: sortData[0].column === 'clientCount' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('clientCount'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'clientCount' ? 700 : 400 },
            },
        },
        {
            key: [],
            viewStyle: {
                width: '4.44vw',
                padding: 0,
                justifyContent: 'center',
            },
            itemIcon: allBranchTab.actions.canViewAdvisersInvestors ? { name: 'eye-show', size: '1.5rem' } : undefined,
            onClickItem: allBranchTab.actions.canViewAdvisersInvestors ? handleViewBranches : undefined,
            title: LABEL.view,
            testId: 'allbranches-view-column',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allBranchData}
                columns={columns}
                isSearchResult={searchEmpty}
                searchInput={searchInput}
                onEmptyState={EMPTY_STATES.emptyStateTableOffices}
                testId="hq-allbranches"
            />
        </Fragment>
    );
};

export default AllBranchOffices;
