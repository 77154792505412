import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { IcoMoon } from '../../../icons';
import { CustomSpacer } from '../../atoms';
import { LABEL, TextNavyBlue } from '../../../constants';
import { Tooltip } from '../../molecules';

interface ICustomDropdownProps {
    items: Array<IDropdownItem>;
    children: ReactNode;
    handleReset?: () => void;
    selectIcon?: boolean;
    selected?: string;
    data?: ITableData;
    hideReset?: boolean;
    noMinWidth?: boolean;
    disableDropdown?: boolean;
}

export interface IDropdownItem {
    color?: string;
    icon?: string;
    item: string;
    handleItem: (item: string, rawData: ITableData | undefined) => void;
    danger?: boolean;
    rawData?: ITableData;
    disabled?: boolean;
    tooltipMessage?: string;
    testId?: string;
}

export const CustomDropdown = ({
    data,
    items,
    children,
    handleReset,
    selected,
    hideReset,
    noMinWidth,
    disableDropdown,
}: ICustomDropdownProps): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);
    const node = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (e: any) => {
        if (node.current?.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show]);
    const showReset = hideReset ? false : true;

    const filter = items.filter((item) => item.item === selected);

    const menuItem = ({ icon, item, danger, handleItem, color, disabled, testId }: IDropdownItem) => {
        const iconName = icon !== undefined ? icon : 'check';
        const currentColor = color !== undefined ? color : '#002043';
        return (
            <DropdownItem
                isTrue={disabled ? disabled : false}
                onClick={() => (disabled ? undefined : handleItem(item, data))}
                id={testId}
            >
                {icon !== undefined ? (
                    <IcoMoon
                        style={{ marginRight: '0.5rem' }}
                        name={iconName}
                        size="1rem"
                        color={danger ? '#e84c3d' : currentColor}
                    />
                ) : null}
                <DropdownItemText isTrue={danger !== undefined ? danger : false}>{item}</DropdownItemText>
                <CustomSpacer horizontal={true} space={'1rem'} />
                {selected === item ? (
                    <IcoMoon style={{ marginLeft: '0.5rem' }} name={iconName} size="1rem" color={'#002043'} />
                ) : (
                    ''
                )}
            </DropdownItem>
        );
    };
    const topOffset = node.current?.clientHeight !== undefined ? node.current?.clientHeight : 0;
    return (
        <Fragment>
            <DropdownContainer
                onClick={() => setShow(!show)}
                ref={node}
                disabled={disableDropdown}
                id="setShowBtn"
                data-testid="setShowBtn"
            >
                {children}

                <DropdownMenu
                    isTrue={show}
                    topOffset={topOffset}
                    noMinWidth={noMinWidth ? noMinWidth : false}
                    data-testid="visible-menu"
                    id={`dropdown-menu-${show ? 'visible' : 'hidden'}`}
                >
                    {items.map(({ icon, item, danger, handleItem, color, disabled, tooltipMessage, testId }, index) => {
                        return (
                            <div
                                key={`$item_${index}`}
                                style={{
                                    boxShadow: index !== items.length - 1 ? 'inset 0px -1px 0px #f2f4f6' : '',
                                }}
                            >
                                {tooltipMessage !== undefined ? (
                                    <Tooltip placement="Left" tooTipContent={tooltipMessage} style={{ zIndex: 100 }}>
                                        <div>
                                            {menuItem({
                                                icon,
                                                item,
                                                danger,
                                                handleItem,
                                                color,
                                                disabled,
                                                testId,
                                            })}
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Fragment>
                                        {menuItem({ icon, item, danger, handleItem, color, testId, disabled })}
                                    </Fragment>
                                )}
                            </div>
                        );
                    })}
                    {filter.length > 0 && showReset ? (
                        <DropdownItem
                            isTrue={false}
                            onClick={handleReset}
                            data-testid="handleResetAllBtn"
                            id="handleResetAllBtn"
                        >
                            <TextNavyBlue weight="700" fontSize={'12px'}>
                                {LABEL.resetAll}
                            </TextNavyBlue>
                            <CustomSpacer horizontal={true} space={'.375rem'} />
                            <IcoMoon name={'clear'} size="1rem" color={'#0089EC'} />
                        </DropdownItem>
                    ) : (
                        <HiddenDiv data-testid="handleResetAllBtn-does-not-exist" />
                    )}
                </DropdownMenu>
            </DropdownContainer>
        </Fragment>
    );
};
interface renderStyle {
    isTrue: boolean;
}
interface renderDropDownMenuStyle {
    isTrue: boolean;
    topOffset: number;
    noMinWidth: boolean;
}
const DropdownMenu = styled.div<renderDropDownMenuStyle>`
    visibility: hidden;
    /* transition: all 0.5s ease-in; */
    position: absolute;
    top: ${(props) => `${props.topOffset}px`};
    right: 0;
    z-index: 100;
    border: 1px solid #f2f4f6;
    box-sizing: border-box;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    min-width: ${(props) => (props.noMinWidth ? 'auto' : '10.5rem')};
    max-height: 15rem;
    overflow-y: auto;
    width: max-content;
    padding: 0.5rem 0rem;
    ${(props) =>
        props.isTrue &&
        css`
            visibility: visible;
        `}
`;
const DropdownItem = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    &:hover {
        background: #f4f4f4;
        cursor: pointer;
    }
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            opacity: 0.6;
            &:hover {
                background: #fff;
                cursor: default;
            }
        `}
    height: 2rem;
`;

const DropdownItemText = styled.div`
    color: #002043;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: -0.444444px;
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            color: #e84c3d;
        `}
`;

const DropdownContainer = styled.div<IOnPropStyles>`
    position: relative;
    display: flex;
    cursor: pointer;
    ${(props: IOnPropStyles) =>
        props.disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}
`;
const HiddenDiv = styled.div`
    visibility: hidden;
`;
export default CustomDropdown;
