/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import BranchOfficeContext, { BranchOfficeContextData } from './BranchOfficeContext';
import { useComponentLoader } from '../../../customHooks';
import { IColumnValue } from '../../../pages/Advisers';
import { branchOfficesQuery } from '../../../_graphql/queries/hqCE/branchOffice/branchOffices';
import { API, graphqlOperation } from 'aws-amplify';
import AuthContext from '../../AuthContext';
import ErrorHandlingContext from '../../ErrorHandling/ErrorHandlingContext';

export interface BranchOfficeProviderProps {
    children: React.ReactNode;
}

export const BranchOfficeProvider: React.FC<BranchOfficeProviderProps> = ({ children }: BranchOfficeProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [branchCounter, setBranchCounter] = useState<Array<number>>([]);
    const [allBranchData, setAllBranchData] = useState<ITableData[]>([]);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'name', value: 'ascending' }]);
    const [expand, setExpand] = useState<boolean>(false);
    const [states, setStates] = useState<React.ReactText[]>([]);
    const [stateList, setStateList] = useState<string[]>([]);
    const [tempFilters, setTempFilters] = useState<IColumnValue[]>([{ column: 'state', value: '' }]);
    const [filters, setFilters] = useState<IColumnValue[]>([{ column: 'state', value: '' }]);
    const [branchesId, setBranchesId] = useState<string>('');
    // loader
    const { loading, loadingHandler } = useComponentLoader();

    /**
     * @getAllBranchQuery @APICall @storeTheResults
     */
    const getAllBranchData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(branchOfficesQuery, {
                    input: {
                        page: page,
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );
            const { result } = await response.data.branchOffices.data;

            setStateList(result.stateList);
            setAllBranchData(result.branches);
            setDisableResultLimit(result.branches.length === 0);
            if (page > result.pages) {
                setPage(1);
            }
            setBranchCounter([result.totalResultCount]);
            setMaxPages(result.pages);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Branch Data',
                testId: 'hq-branches-error-modal',
            });
        }
    };

    const ProviderValue: BranchOfficeContextData = {
        allBranchData,
        branchCounter,
        branchesId,
        disableResultLimit,
        expand,
        filters,
        getAllBranchData,
        loading,
        loadingHandler,
        maxPages,
        page,
        resultLimit,
        searchInput,
        setAllBranchData, //branches
        setBranchCounter, //totalResultCount
        setBranchesId,
        setExpand,
        setFilters,
        setMaxPages, // pages
        setPage, //page
        setResultLimit, // setLimit
        setSearchInput,
        setSortData,
        setStateList, // stateList
        setStates,
        setTab,
        setTempFilters,
        sortData,
        stateList,
        states,
        tab,
        tempFilters,
    };

    return <BranchOfficeContext.Provider value={ProviderValue}>{children}</BranchOfficeContext.Provider>;
};

export default BranchOfficeProvider;
