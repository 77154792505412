import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { CustomSpacer } from '../CustomSpacer';

export interface RadioButtonProps {
    label: string;
    selected: boolean;
    setSelected: () => void;
}

export interface CircleProps {
    selected: boolean;
}

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
    label,
    selected,
    setSelected,
}: RadioButtonProps) => {
    return (
        <Radio onClick={setSelected} id="radioBtn" data-testid="radioBtn">
            <Circle selected={selected} data-testid="circle" />
            <CustomSpacer horizontal={true} space={'0.75rem'} />
            <Label selected={selected}>{label}</Label>
        </Radio>
    );
};

const Circle = styled.div`
    border-radius: 100rem;
    height: 1.125rem;
    width: 1.125rem;
    border: 1px solid #002043;
    ${(props: CircleProps) =>
        props.selected === true &&
        css`
            border-color: #c61230;
            border-width: 0.25rem;
        `}
`;

export const Radio = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;
const Label = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #171717;
    ${(props: CircleProps) =>
        props.selected &&
        css`
            font-weight: bold;
        `}
`;
