/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomButton, FlexedDiv, Modal, TextArea, DatePicker, PreviousPage } from '../../../../../components';
import { EDD, LABEL } from '../../../../../constants';
import { sh16, sh56 } from '../../../../../styles';
import { ROUTES } from '../../../../DashboardBranch';

import { useComponentLoader } from '../../../../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';
import { eddReasonListQuery } from '../../../../../_graphql/queries/edd/eddReasonList/eddReasonList';
import { capitalizeFirstLetter } from '../../../../../utils';

import styled from 'styled-components';
import moment from 'moment';
import AuthContext from '../../../../../context/AuthContext/AuthContext';
import EDDContext from '../../../../../context/EDDContext/EDDContext';
import CustomCheckbox from '../../../../../components/atoms/Checkbox';
declare interface IReasonProps {
    reasonId: string;
    category: string;
    subCategory: string[];
}
declare interface IReasonsListProps {
    reasons: IReasonProps[];
    targetDate: string;
}

export const RerouteEddCase: React.FC = () => {
    const history = useHistory();
    const { updateCaseStatus, unsatisfactoryRating, setUnsatisfactoryRating, caseDetails } = useContext(EDDContext);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [reasonsList, setReasonsList] = useState<IReasonsListProps>();
    const [checked, setChecked] = useState<Array<boolean>>([false]);
    const [reason, setReason] = useState<string>('');
    const { loadingHandler } = useComponentLoader();
    const [showModal, setShowModal] = useState<boolean>(false);
    useEffect(() => {
        getReasonsList();
        setUnsatisfactoryRating([]);
    }, []);

    const getReasonsList = async () => {
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(eddReasonListQuery, {
                    input: {
                        actionType: 'reroute',
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.eddReasonList;
            if (error !== null) throw error;
            if (data) {
                setReasonsList(data.result);
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            console.log(error);
        }
    };

    const handleCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const index = parseInt(e.target.id.split('-')[0]);
        const id = e.target.id.split('-')[1];

        let reasonId: string;
        reasonsList?.reasons.map((item) => {
            if (item.category === value) return (reasonId = item.reasonId);
        });
        const check = e.target.checked;
        const tempChecked = [...checked];
        if (check) {
            tempChecked[index] = check;
            setUnsatisfactoryRating((prevState) => {
                const prevData = prevState;
                prevData[index] = { reasonId: reasonId, checked: check, remark: '' };
                return prevData;
            });
        } else {
            tempChecked[index] = false;
            setUnsatisfactoryRating((prevState) => {
                const prevData = prevState;
                const updatedData: any = [];
                prevData.filter((item, i) => {
                    updatedData[i] = item && item.reasonId == id ? undefined : prevData[i];
                    return;
                });
                return updatedData;
            });
        }
        setChecked(tempChecked);
    };
    const handleReason = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const value = e.target.value;
        setReason(value);
        setUnsatisfactoryRating((prevState) => {
            const prevData = prevState;
            prevData[index] = { ...prevData[index], remark: value };
            return prevData;
        });
    };

    return (
        <>
            <FlexCol>
                <PreviousPage title="Reroute EDD Case" subTitle="This case will be rerouted to the adviser." />
            </FlexCol>
            <FlexCol>
                <StyledLabel>Reason for unsatisfactory rating</StyledLabel>
                {reasonsList?.reasons?.map((item, index) => (
                    <Fragment key={index}>
                        <MapItem key={`${index} ${item}`}>
                            <CustomCheckbox
                                value={item.category}
                                checked={checked[index] !== undefined ? checked[index] : false}
                                label={capitalizeFirstLetter(item.category)}
                                handleCheckbox={handleCheck}
                                id={`${index}-${item.reasonId}`}
                                key={item.reasonId}
                                textTransForm="none"
                            />
                        </MapItem>
                        {checked[index] && (
                            <TextArea
                                name={'reason'}
                                label={'Reason (optional)'}
                                handleInput={(e) => handleReason(e, index)}
                                maxLength={100}
                                style={{ marginLeft: '64px', width: '360px' }}
                                value={unsatisfactoryRating[index] ? unsatisfactoryRating[index].remark : ''}
                            />
                        )}
                    </Fragment>
                ))}
            </FlexCol>
            <SeparatorLineV2 />
            <Container>
                <InputWrap>
                    <DatePicker
                        disable={true}
                        targetDate={[moment(parseInt(reasonsList ? reasonsList.targetDate : '')), null]}
                        setTargetDate={() => moment(parseInt(reasonsList ? reasonsList.targetDate : ''))}
                        icon="calendar"
                        iconSize="1.25rem"
                        width="360"
                        label={LABEL.newDueDate}
                    />
                </InputWrap>

                <FlexRow style={{ marginTop: sh56, justifyContent: 'flex-start' }}>
                    <CustomButton
                        onClick={() => history.push(ROUTES.amlaEDDManagement)}
                        style={{ width: '240px', height: '45px' }}
                    >
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary={true}
                        style={{ marginLeft: sh16, width: '240px', height: '45px' }}
                        onClick={async () => {
                            await updateCaseStatus('Not Satisfactory');
                            setShowModal(true);
                        }}
                        disabled={!unsatisfactoryRating.filter((n) => n).length}
                    >
                        {LABEL.submit}
                    </CustomButton>
                </FlexRow>
            </Container>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={EDD.LABEL_EDD_MODAL_UNSATISFACTORY_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            setShowModal(false);
                            history.push(ROUTES.amlaEDDManagement, { tab: 'rerouted', refresh: 'refresh' });
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    icon="cancel-request-done"
                    testId="custom_modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <b>{`${caseDetails.caseSummary.caseDetails.caseNo}`}</b>{' '}
                        {`${EDD.LABEL_EDD_MODAL_UNSATISFACTORY_SUB_TITLE}`}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </>
    );
};
const FlexRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const MapItem = styled.div`
    margin: 0.5rem 0rem 0.5rem 2.5rem;
    padding-left: 0.5rem;
    line-height: 135%;
`;
const StyledLabel = styled.label`
    font-weight: bold;
    font-size: 18px;
    line-height: 135%;
    color: #333333;
    margin: 24px 0px 24px 2.5rem;
`;
const SeparatorLineV2 = styled.div`
    opacity: 0.1;
    border: 1px solid #333333;
    margin: 32px 0px;
    max-width: 100%;
`;
const Container = styled.div`
    margin-left: 2.5rem;
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;
