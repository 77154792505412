import { SYSTEM_ADMIN } from '../../constants';
import { subTitleStyle } from '../../styles';

export interface IAdviserProfile {
    name: string;
    code: string;
    nric: string;
    status: string;
    personalDetails: IPersonalDetails;
    contactDetails: IContactDetails;
    addressInfo: IAddressInfo;
}

export interface IAdviserProfileDetails {
    name: string;
    nric: string;
    code: string;
    status: string;
    details: IAdviserProfileSection[];
}
export interface IAdviserProfileSection {
    title: string;
    sections: ISection[];
}

export interface IPersonalDetails {
    licenseCode: string;
    homeBranch: string;
    omniEnabled: string;
    bdmName: string;
    bdmCode: string;
    bdmEmail: string;
    uplineName: string;
    uplineCode: string;
    region: string; //unavailable
    adviserChannel: string; //unavailable
    adviserRank: string; //unavailable
    agency: string; //unavailable
}

export interface IContactDetails {
    email: string;
    mobileNo: string;
}

export interface IAddressInfo {
    address: string;
    postcode: string; //unavailable
    addrTown: string;
    state: string; //unavailable
    country: string; //unavailable
}

export const handleRestructureData = (data: IAdviserProfile): IAdviserProfileDetails => {
    const personalDetailsUpperArray: IDataWithLabel[] = [];
    const bdmDetailsArray: IDataWithLabel[] = [];
    const contactDetailsArray: IDataWithLabel[] = [];
    const addressInfoDetailsArray: IDataWithLabel[] = [];
    personalDetailsUpperArray.push(
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_NRIC,
            data: data.nric !== null ? data.nric : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_LICENSE_CODE,
            data: data.personalDetails.licenseCode !== null ? data.personalDetails.licenseCode : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_HOME_BRANCH,
            data: data.personalDetails.homeBranch !== null ? data.personalDetails.homeBranch : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_REGION,
            data: data.personalDetails.region !== null ? data.personalDetails.region : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_CHANNEL,
            data: data.personalDetails.adviserChannel !== null ? data.personalDetails.adviserChannel : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_RANK,
            data: data.personalDetails.adviserRank !== null ? data.personalDetails.adviserRank : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_OMNI_ENABLED,
            data: data.personalDetails.omniEnabled !== null ? data.personalDetails.omniEnabled : '-',
        },
    );
    bdmDetailsArray.push(
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_BDM,
            data: data.personalDetails.bdmName !== null ? data.personalDetails.bdmName : '-',
            subText: data.personalDetails.bdmCode !== null ? data.personalDetails.bdmCode : '-',
            subTextStyle: subTitleStyle,
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_BDM_EMAIL,
            data: data.personalDetails.bdmEmail !== null ? data.personalDetails.bdmEmail : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_UPLINE_NAME_CODE,
            data: data.personalDetails.uplineName !== null ? data.personalDetails.uplineName : '-',
            subText: data.personalDetails.uplineCode !== null ? data.personalDetails.uplineCode : '-',
            subTextStyle: subTitleStyle,
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_AGENCY,
            data: data.personalDetails.agency !== null ? data.personalDetails.agency : '-',
        },
    );
    contactDetailsArray.push(
        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_EMAIL, data: data.contactDetails.email },
        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_MOBILE, data: data.contactDetails.mobileNo },
    );
    addressInfoDetailsArray.push(
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_PERMANENT_ADDRESS,
            data: data.addressInfo.address !== null ? data.addressInfo.address : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_POSTCODE,
            data: data.addressInfo.postcode !== null ? data.addressInfo.postcode : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_TOWN,
            data: data.addressInfo.addrTown !== null ? data.addressInfo.addrTown : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_STATE,
            data: data.addressInfo.state !== null ? data.addressInfo.state : '-',
        },
        {
            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_COUNTRY,
            data: data.addressInfo.country !== null ? data.addressInfo.country : '-',
        },
    );
    const structuredData: IAdviserProfileDetails = {
        name: data.name,
        status: data.status,
        code: data.code,
        nric: data.nric,
        details: [
            {
                title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_PERSONAL_DETAILS,
                sections: [{ data: personalDetailsUpperArray }],
            },
            {
                title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_BDM,
                sections: [{ data: bdmDetailsArray }],
            },
            {
                title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_CONTACT_DETAILS,
                sections: [{ data: contactDetailsArray }],
            },
            {
                title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADDRESS_INFO,
                sections: [{ data: addressInfoDetailsArray }],
            },
        ],
    };
    return structuredData;
};
