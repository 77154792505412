import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IAvatarProps {
    size?: number;
    backgroundColor?: string;
    color?: string;
    customStyles?: React.CSSProperties;
    testId?: string;
    children: ReactNode;
}

type AvatarElmProps = {
    backgroundColor?: string;
    size?: number;
};
export const Avatar: React.FC<IAvatarProps> = ({
    customStyles,
    backgroundColor,
    color,
    testId,
    children,
    size,
}: IAvatarProps) => {
    return (
        <AvatarElm
            style={customStyles}
            backgroundColor={backgroundColor}
            id={`${testId}-avatar`}
            size={size}
            color={color}
        >
            {children}
        </AvatarElm>
    );
};

const AvatarElm = styled.div<AvatarElmProps>`
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    min-width: ${(props) => (props.size ? props.size : `48`)}px;
    height: ${(props) => (props.size ? props.size : `48`)}px;
    line-height: 1.5rem;
    color: ${(props) => (props.color ? props.color : `#fff`)};
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    user-select: none;
`;
