import React, { useContext } from 'react';
import { Responses } from './Responses';
import { AssignedAdviser } from './AssignedAdviser';
import { CaseSummary } from './CaseSummary';
import { useHistory } from 'react-router-dom';
import { Tabs } from '../../../../components/organisms';
import { LABEL } from '../../../../constants';
import EDDContext from '../../../../context/EDDContext/EDDContext';
import { ComponentLoader } from '../../../../components';
import * as ROUTES from '../../../../routes';

export const SatisfactoryCase = (): JSX.Element => {
    const history = useHistory();
    const { getCaseDetails, caseDetails, loading } = useContext(EDDContext);
    const tabs = [<Responses key={1} />, <CaseSummary key={2} />, <AssignedAdviser key={3} />];

    const handleBack = () => {
        history.push(ROUTES.amlaEDDManagement, { currentTab: 'New' });
    };
    React.useEffect(() => {
        getCaseDetails(0);
    }, []);
    return (
        <React.Fragment>
            <div style={{ position: 'relative', maxWidth: '1152px' }}>
                <Tabs
                    tabTitles={[LABEL.responses, LABEL.caseSummary, LABEL.assignedAdviser]}
                    title={caseDetails && caseDetails.name}
                    suffixHelper={caseDetails && caseDetails.status}
                    tabs={tabs}
                    handleBackFunction={handleBack}
                    className="overflow_container_edd"
                />
                {loading ? <ComponentLoader /> : null}
            </div>
        </React.Fragment>
    );
};
