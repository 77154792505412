import React, { Fragment } from 'react';
import { Fs12BoldPrimaryBlack5 } from '../../../constants';
interface IGroupByFundCutOffTime extends ITableGroupBy {
    label: string[];
}

export const GroupBy = ({ data, groupBy, label }: IGroupByFundCutOffTime): JSX.Element => {
    const currentLabel = label;
    const branch: string = data[groupBy];
    const showLabel = () => {
        currentLabel.push(branch);
        return true;
    };

    const show = currentLabel.includes(branch) ? false : showLabel();

    return (
        <Fragment>
            {show ? (
                <Fragment>
                    <Fs12BoldPrimaryBlack5>{branch}</Fs12BoldPrimaryBlack5>
                </Fragment>
            ) : null}
        </Fragment>
    );
};
