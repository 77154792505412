/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { DateColumnItem, NAVDateAColumnItem } from '../../../templates';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let outputData: any;
    const generateOutput = () => {
        switch (itemKey[0].key) {
            case 'grossDistribution':
            case 'netDistribution':
            case 'unitSplit':
                outputData = <NAVDateAColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
                break;
            case 'lastUpdated':
                outputData = <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
                break;
            default:
                outputData = '';
        }
        return outputData;
    };

    return (
        <Fragment>
            <div>{generateOutput()}</div>
        </Fragment>
    );
};
export default CustomItem;
