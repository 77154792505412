/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Banner } from '../../../../../components';
import { EDD } from '../../../../../constants';
import { Collapsible } from '../../../../../components/molecules/Collapsible';
import { ROUTES } from '../../../..';
import { useHistory, useLocation } from 'react-router-dom';
import EDDContext from '../../../../../context/EDDContext/EDDContext';
import { QuestionaryTemplate } from '../../../Helpers';
import { ResponseInfo } from '../../../Helpers/common';

export const Responses: React.FC = () => {
    const { getCaseDetails, caseDetails, setResponseId, amlaRemarks, setAmlaRemarks, isSelected, setIsSelected } =
        useContext(EDDContext);
    const history = useHistory();

    const [expands, setExpands] = useState([true]);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [a, setA] = useState<boolean>(false);
    const location = useLocation();
    const state: any = location.state;

    useEffect(() => {
        if (state.callFrom === 'dashboard') {
            setIsSelected([]);
            setAmlaRemarks([]);
            delete state.callFrom;
            history.replace({ ...history.location, ...state });
        }
    }, []);
    useEffect(() => {
        validateAmlaRemarks(amlaRemarks);
    }, [isSelected]);

    /* Function to expand/close collapsable toggle */
    const updateExpands = async (index: number, responseId?: number) => {
        if (responseId) {
            setResponseId(responseId);
            await getCaseDetails(responseId);
        }
        const exp = [...expands];
        if (exp[index] === undefined) {
            exp[index] = true;
        } else {
            exp[index] = !exp[index];
        }
        setExpands(exp);
    };

    /* Function to update the remarks of question */
    const handleRemark = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        item: { questionId: string; title: string },
        index: number,
    ) => {
        const remark = e.target.value;
        setAmlaRemarks((prevValue) => {
            const existingRemarks = [...prevValue];
            existingRemarks[index] =
                item.questionId === null
                    ? { title: item.title, remark: remark, questionId: '' }
                    : { questionId: item.questionId, remark: remark, title: '' };
            validateAmlaRemarks(existingRemarks);

            return existingRemarks;
        });
        return;
    };
    /* To handle Questionary Template height */
    const handleTextBox = () => {
        setA(!a);
    };
    const validateAmlaRemarks = (amlaRemarks: any[]) => {
        const processedData = amlaRemarks.map((item) => {
            if (item && item.remark.trim().length) {
                return true;
            } else {
                return false;
            }
        });
        return setIsValidated(processedData.includes(true));
    };
    return (
        <Fragment>
            <DetailsArea>
                <SpaceDivider />
                {caseDetails &&
                    caseDetails.headings &&
                    caseDetails.headings.map((_heading, i) => {
                        const response = caseDetails.response;
                        return (
                            <Fragment key={i}>
                                <PageHeader>
                                    {`Response ${caseDetails.headings.length - i}`}
                                    <Line />
                                </PageHeader>
                                <ParagraphStyle>
                                    <strong style={{ color: '' }}>{`|`}</strong>&nbsp;&nbsp;
                                    <strong style={{ color: 'black' }}>{EDD.LABEL_AMLA}</strong>
                                </ParagraphStyle>
                                <Collapsible
                                    title="Follow-up Questions"
                                    isCheckable={false}
                                    key={i}
                                    disabled={Object.keys(caseDetails.headings[i]).length === 0 ? true : false}
                                    expanded={expands[i]}
                                    noXMargin={true}
                                    noMargin={true}
                                    showBoxShadow={true}
                                    handleExpand={() => updateExpands(i, caseDetails.headings[i].responseId)}
                                    content={
                                        <QuestionaryTemplate
                                            type="unsatisfactory"
                                            content={response}
                                            handleInput={handleRemark}
                                            key={i}
                                            onlyRemarks={false}
                                            remark={amlaRemarks}
                                            handleTextBox={handleTextBox}
                                            showConclusion={false}
                                        ></QuestionaryTemplate>
                                    }
                                    index={i}
                                />
                                {i + 1 === caseDetails.headings.length ? (
                                    <Fragment>
                                        <ResponseInfo
                                            name={caseDetails.assignedAdviser.personalDetails.agentName}
                                            timestamp={caseDetails.headings[0].submittedOn}
                                            status="Read"
                                            style={{ margin: '12px 12px 10px 28px' }}
                                            badgeStyle={{ color: '#A82252' }}
                                        />
                                    </Fragment>
                                ) : null}
                                <SpaceDivider />
                            </Fragment>
                        );
                    })}

                <SpaceDivider />
            </DetailsArea>
            <FlexCol style={{ paddingBottom: '0rem', margin: '0 -2rem' }}>
                <Banner
                    disabled={!isValidated || !isSelected.includes(true)}
                    toggle={true}
                    title={EDD.LABEL_UNSATISFACTORY_BANNER_TITLE}
                    description={`Add minimum one remark for`}
                    handleClose={() => console.log('handle close')}
                    descriptionEmText={`${caseDetails.name} (${caseDetails.clientIdNum}) to proceed`}
                    maxWidth="1152"
                    primaryButtonObject={{
                        handlePrimaryBtn: () => history.push(ROUTES.rerouteEddCase, { amlaRemarks: amlaRemarks }),
                        label: EDD.BUTTON_CONTINUE,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: () => history.push(ROUTES.amlaEDDManagement),
                        label: EDD.BUTTON_CANCEL,
                    }}
                />
            </FlexCol>
        </Fragment>
    );
};

declare interface ParagraphStyleProps {
    margin?: string;
}
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const DetailsArea = styled.div`
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 94px;
    flex-direction: column;
`;

const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;

const ParagraphStyle = styled.p<ParagraphStyleProps>`
    display: flex;
    font-size: 12px;
    color: #4d4d4d;
    margin: ${(props) => (props.margin ? props.margin : '0px 32px 10px')};
`;

declare interface SubCardProps {
    topSection?: boolean;
    bottomSection?: boolean;
    isCheckList?: boolean;
}

const Line = styled.span`
    width: 90%;
    height: 0px;
    margin: 9px 0px;
    color: gray;
    border: 1px solid #c6cbd4;
`;

const PageHeader = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 16px;
    color: #171717;
    line-height: 148%;
`;
