/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';

import { LABEL, TextDarkGrey6 } from '../../../../constants';
import { IcoMoonSelection } from '../../../../icons';
import { ComponentLoader, CustomSpacer, TextArea, DatePicker } from '../../../../components';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { useComponentLoader } from '../../../../customHooks';

import * as Routes from '../../../../routes';
import styled, { css } from 'styled-components';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

export interface LocationState {
    item: ITableData;
    isDisabled: boolean;
    hq?: boolean;
}

export type Page = 'approve' | 'reject';

export const dateFormat = 'DD/MM/YYYY';

declare interface ExtentTargetDateProps {
    targetDate: IExtendTargetDate;
    setTargetDate: (data: IExtendTargetDate) => void;
    locationState: LocationState;
}

export const DueDateActivity: React.FC<ExtentTargetDateProps> = () => {
    const [targetDate, setTargetDate] = useState<IExtendTargetDate>({ date: [null, null], remarks: '' });
    const history = useHistory();
    const location = useLocation();
    const locationState: any = location.state;
    const tableData = locationState !== undefined ? locationState.id : '';

    const handleBack = () => {
        history.push(Routes.activityLogs);
    };
    // loader
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const getInfo = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: parseInt(tableData, 10),
                    },
                }),
                idTokenHeader,
            );

            const { result } = response.data.getLogData.data;
            if (response.data.getLogData.error !== null) throw result.error;
            if (result.data.data === null) {
                loadingHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: 'Data is null',
                    errorCode: '',
                    title: 'Cannot Fetch Due Date Extension Data',
                    testId: 'activitylogs-duedate-error-modal',
                });
                handleErrorHandler();
            } else {
                const allData = result.data;
                const info = JSON.parse(allData.data);
                const targetDate: IExtendTargetDate = {
                    date: [moment(parseInt(info.targetDate, 10)), null],
                    remarks: info.remark !== null && info.remark !== undefined ? info.remark : '',
                };

                setTargetDate(targetDate);
                loadingHandler();
            }
        } catch (error) {
            loadingHandler();
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Fetch Due Date Extension Data',
                testId: 'activitylogs-duedate-error-modal',
            });
            handleErrorHandler();
        }
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        setTargetDate({ ...targetDate, date: date });
    };
    useEffect(() => {
        getInfo();
    }, []);

    return (
        <Fragment>
            <Header>
                <Icon onClick={handleBack}>
                    <IcomoonReact icon="arrow-left" size="1.5rem" iconSet={IcoMoonSelection} />
                </Icon>
                <CustomSpacer horizontal={true} space={'1.25rem'} />
                <TextDarkGrey6 size="24px" weight="700">
                    {LABEL.extendDueDateRequest}
                </TextDarkGrey6>
            </Header>
            <CustomSpacer space={'3.556vh'} />
            <Container>
                <DisabledView disabled={true}>
                    <InputWrapper>
                        <TextDarkGrey6 size="12px" weight="700">
                            {LABEL.newDueDate}
                        </TextDarkGrey6>
                        <DatePicker
                            targetDate={targetDate.date}
                            setTargetDate={handleDate}
                            iconSize="1.5rem"
                            primaryBorderColor="#CECECE"
                            backgroundColor="#F4F4F4"
                            disable={true}
                        />
                    </InputWrapper>
                    <CustomSpacer space={'2rem'} />
                    <InputWrapper>
                        <TextArea
                            name="rejectRemark"
                            label={LABEL.remarks}
                            value={targetDate.remarks as string}
                            handleInput={() => console.log('hi')}
                            maxLength={255}
                            style={{ width: '38.89vw' }}
                            disabled
                        />
                    </InputWrapper>
                </DisabledView>

                {loading ? <ComponentLoader /> : null}
            </Container>
        </Fragment>
    );
};

interface DisabledProps {
    disabled: boolean;
}
const DisabledView = styled.div`
    ${(props: DisabledProps) =>
        props.disabled &&
        css`
            opacity: 0.8;
            pointer-events: none;
        `}
`;
const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Container = styled.div`
    padding-left: 2.5vw;
    pointer-events: none;
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    width: 360px;
    max-width: 360px;
`;
