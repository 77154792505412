/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { IColumnValue } from '../../pages/Advisers';
import moment from 'moment';

interface IDropdownWithKey {
    value: string;
    keyName: string;
}

export interface HQCEContextData {
    accountType: string;
    currentPage: number;
    disableResultLimit: boolean;
    filters: IColumnValue[];
    fundingOption: React.ReactText[];
    getAllBranchData: () => Promise<void>;
    getDateExtensionData: () => Promise<void>;
    label: string[];
    lastUpdatedDropdown: IDropdownWithKey;
    loading: boolean;
    loadingHandler: () => void;
    maxPages: number;
    offices: ITableData[];
    orderCounter: number[];
    overview: ISummaryCardData[];
    productTypes: React.ReactText[];
    resultLimit: number;
    searchInput: ISearchInput;
    setAccountType: React.Dispatch<React.SetStateAction<string>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setFilters: React.Dispatch<React.SetStateAction<IColumnValue[]>>;
    setFundingOption: React.Dispatch<React.SetStateAction<React.ReactText[]>>;
    setLabel: React.Dispatch<React.SetStateAction<string[]>>;
    setLastUpdatedDropdown: React.Dispatch<React.SetStateAction<IDropdownWithKey>>;
    setMaxPages: React.Dispatch<React.SetStateAction<number>>;
    setOffices: React.Dispatch<React.SetStateAction<ITableData[]>>;
    setOrderCounter: React.Dispatch<React.SetStateAction<number[]>>;
    setOverview: React.Dispatch<React.SetStateAction<ISummaryCardData[]>>;
    setProductTypes: React.Dispatch<React.SetStateAction<React.ReactText[]>>;
    setResultLimit: React.Dispatch<React.SetStateAction<number>>;
    setSearchInput: React.Dispatch<React.SetStateAction<ISearchInput>>;
    setSortData: React.Dispatch<React.SetStateAction<ISort_FilterInput>>;
    setStateList: React.Dispatch<React.SetStateAction<string[]>>;
    setStates: React.Dispatch<React.SetStateAction<React.ReactText[]>>;
    setTab: React.Dispatch<React.SetStateAction<number>>;
    setTargetDate: React.Dispatch<React.SetStateAction<ITableData[]>>;
    setTargetRange: React.Dispatch<React.SetStateAction<[moment.Moment | null, moment.Moment | null]>>;
    setTempFilters: React.Dispatch<React.SetStateAction<IColumnValue[]>>;
    setTransactionType: React.Dispatch<React.SetStateAction<IDropdownWithKey>>;
    sortData: ISort_FilterInput;
    stateList: string[];
    states: React.ReactText[];
    tab: number;
    targetDate: ITableData[];
    targetDateRange: [moment.Moment | null, moment.Moment | null];
    tempFilters: IColumnValue[];
    transactionType: IDropdownWithKey;
    searchbarFilterTags: ISelectValue[];
    setSearchbarFilterTags: React.Dispatch<React.SetStateAction<ISelectValue[]>>;
}

const HQCEContext = React.createContext({} as HQCEContextData);

export default HQCEContext;
