import moment from 'moment';
import {
    IClientProfile,
    IDocumentSummary,
    IInvestment,
    IOrderSummaryProps,
    IOrderSummaryType,
    ITransactionsSummary,
} from '../pages/OrderDetails/orderSummaryTypes';
import { emptyCheck } from './emptyCheck';
import { LABEL } from '../constants';
import { statusBadgeColorOnly, toTitleCase } from '.';

export const handleOrderSummary = (orderSummary: IOrderSummaryType): IOrderSummaryProps => {
    const fundSummaryObj: ISection[] = [];
    const paymentSummaryObj: ISection[] = [];

    const getTotalInvestmentAmt = (value: Array<IInvestment>): string => {
        let _totalInvestmentAmt = '';
        value.forEach((result, index) => {
            _totalInvestmentAmt =
                _totalInvestmentAmt + `${index > 0 ? ' + ' : ''} ${result.fundCurrency} ${result.investmentAmount}`;
        });

        return _totalInvestmentAmt;
    };

    orderSummary.investmentSummary.map((ele) => {
        const tempObj: ISection = {
            // title: emptyCheck(ele.fundName),
            // subTitle: emptyCheck(ele.utmc),
            // fundCurrency: emptyCheck(ele.fundCurrency),
            // amount: emptyCheck(ele.investmentAmount),
            sectionTitle: {
                title: toTitleCase(emptyCheck(ele.fundName)),
                titleIcon: 'fund',
                bottomLabel: toTitleCase(emptyCheck(ele.utmc)),
                rightLabel: emptyCheck(`${ele.fundCurrency} ${ele.investmentAmount}`),
            },
            data: [
                { label: 'Fund Code', data: emptyCheck(ele.fundCode) },
                { label: 'Fund Class', data: emptyCheck(ele.fundClass) },
                { label: 'Sales Charge', data: emptyCheck(ele.salesCharge) },
                { label: 'Product Type', data: emptyCheck(ele.productType) },
                { label: 'Funding Option', data: emptyCheck(ele.accountFund) },
                { label: 'FEA Tag', data: emptyCheck(ele.feaTagged) },
                { label: 'Type', data: emptyCheck(ele.investmentType) },
                { label: 'Mode of Income Distribution', data: emptyCheck(ele.distributionInstructions) },
                { label: 'Recurring', data: emptyCheck(ele.recurring) },
            ],
        };
        fundSummaryObj.push(tempObj);
    });

    orderSummary.paymentSummary.map((ele, index) => {
        const tempObj: ISection = {
            // title: ele.paymentMethod.toLowerCase() === 'recurring' ? `Recurring Details` : `Payment ${index + 1}`,
            sectionTitle: {
                title: ele.paymentMethod.toLowerCase() === 'recurring' ? `Recurring Details` : `Payment ${index + 1}`,
                titleIcon: 'transaction',
                bottomLabel: ele.surplusNote,
                rightLabel:
                    ele.fundCurrency || ele.investmentAmount
                        ? emptyCheck(ele.fundCurrency) + ' ' + emptyCheck(ele.investmentAmount)
                        : '-',
                badgeColor:
                    ele.status !== undefined && ele.status !== null ? statusBadgeColorOnly(ele.status) : undefined,
                badgeLabel: ele.status !== undefined && ele.status !== null ? ele.status : undefined,
            },

            data: [],
        };
        // if (ele.investmentAmount !== undefined && ele.investmentAmount !== null) {
        //     tempObj.data.push({ label: 'Amount', data: emptyCheck(ele.investmentAmount), prefix: ele.fundCurrency });
        // }
        if (
            ele.paymentMethod !== undefined &&
            ele.paymentMethod !== null &&
            ele.paymentMethod.toLowerCase() !== 'recurring'
        ) {
            tempObj.data.push({ label: 'Payment Method', data: emptyCheck(ele.paymentMethod) });
        }
        if (ele.epfAccountNumber !== undefined && ele.epfAccountNumber !== null) {
            tempObj.data.push({ label: 'EPF Account No.', data: emptyCheck(ele.epfAccountNumber) });
        }
        if (ele.epfReferenceNo !== undefined && ele.epfAccountNumber !== null) {
            tempObj.data.push({ label: 'EPF Reference No.', data: emptyCheck(ele.epfReferenceNo) });
        }
        if (ele.kibBankName !== undefined && ele.kibBankName !== null) {
            tempObj.data.push({
                label: 'KIB Bank Name & Account Number',
                data: emptyCheck(ele.kibBankName),
                subText: emptyCheck(ele.kibBankAccountNumber),
            });
        }
        if (ele.bankName !== undefined && ele.bankName !== null) {
            tempObj.data.push({
                label: 'Bank Name',
                data: emptyCheck(ele.bankName),
            });
        }
        if (
            ele.referenceNumber !== undefined &&
            ele.referenceNumber !== null &&
            ele.paymentMethod.toLowerCase() !== 'client trust account (cta)'
        ) {
            tempObj.data.push({ label: 'Payment Reference Number', data: emptyCheck(ele.referenceNumber) });
        }
        if (ele.checkNumber !== undefined && ele.checkNumber !== null) {
            tempObj.data.push({ label: 'Cheque Number', data: emptyCheck(ele.checkNumber) });
        }
        if (ele.clientName !== undefined && ele.clientName !== null) {
            tempObj.data.push({ label: 'Client Name', data: emptyCheck(ele.clientName) });
        }
        if (ele.clientTrustAccountNumber !== undefined && ele.clientTrustAccountNumber !== null) {
            tempObj.data.push({ label: 'Client Trust Account Number', data: emptyCheck(ele.clientTrustAccountNumber) });
        }
        if (ele.recurringType !== undefined && ele.recurringType !== null) {
            tempObj.data.push({ label: 'Recurring Type', data: emptyCheck(ele.recurringType) });
        }
        if (ele.bankAccountName !== undefined && ele.bankAccountName !== null) {
            tempObj.data.push({
                label: 'Account Holder’s Name',
                data: emptyCheck(ele.bankAccountName),
                subText: ele.isCombined ? 'Combined' : '',
            });
        }
        if (ele.bankAccountNumber !== undefined && ele.bankAccountNumber !== null) {
            tempObj.data.push({
                label: 'Bank Account No.',
                data: emptyCheck(ele.bankAccountNumber),
            });
        }
        if (ele.recurringBank !== undefined && ele.recurringBank !== null) {
            tempObj.data.push({ label: 'Recurring Bank', data: emptyCheck(ele.recurringBank) });
        }

        if (ele.transactionTime !== undefined && ele.transactionTime !== null) {
            tempObj.data.push({
                label: 'Transaction Time',
                data: emptyCheck(moment(ele.transactionTime, 'x').format('h:mm A')),
            });
        }
        if (ele.frequency !== undefined && ele.frequency !== null) {
            tempObj.data.push({ label: 'Recurring Type', data: emptyCheck(ele.frequency) });
        }

        if (ele.paymentOn !== null && ele.paymentOn !== undefined) {
            tempObj.data.push({
                label: 'Transaction Date',
                data: emptyCheck(ele.paymentOn !== null ? moment(ele.paymentOn, 'x').format('DD/MM/YYYY') : ''),
            });
        }
        // if (ele.fundCurrency !== undefined && ele.fundCurrency !== null) {
        //     tempObj.data.push({ label: 'Currency', data: emptyCheck(ele.fundCurrency) });
        // }
        tempObj.data.push({ label: 'Remarks', data: emptyCheck(ele.remark) });
        if (ele.proofOfPayment !== null && ele.proofOfPayment !== undefined) {
            tempObj.data.splice(tempObj.data.length - 1, 0, {
                label: 'Proof of Payment ',
                data: ele.proofOfPayment !== null ? emptyCheck(ele.proofOfPayment.name) : '-',
                dataIcon: 'file',
                onClickData: ele.proofOfPayment !== null ? emptyCheck(ele.proofOfPayment.url) : '-',
            });
        }
        paymentSummaryObj.push(tempObj);
    });

    const documentSummary: IDocumentSummary = orderSummary.documentSummary;
    const transactionSummary: ITransactionsSummary = {
        status: orderSummary.status,
        remarks: orderSummary.remarks,
        approval: orderSummary.approval,
        extensionRemarks: orderSummary.extensionRemarks,
        orderNo: orderSummary.orderNo,
        transactionRef: orderSummary.transactionRef,
        totalInvestment: orderSummary.totalInvestment,
        transactionDetails: {
            title: 'Transaction Details',
            sections: [
                {
                    data: [
                        {
                            label: LABEL.transactionNo,
                            data: orderSummary.transactionRef
                                ? emptyCheck(`${orderSummary.orderNo}${orderSummary.transactionRef}`)
                                : emptyCheck(orderSummary.transactionRef),
                        },
                        {
                            label: LABEL.orderNumber,
                            data: emptyCheck(orderSummary.orderNo),
                        },
                        {
                            label: LABEL.transactionDate,
                            data: orderSummary.transactionDetails.registrationDate
                                ? moment(orderSummary.transactionDetails.registrationDate, 'x').format('DD/MM/YYYY')
                                : '-',
                        },
                        {
                            label:
                                orderSummary.paymentSummary[0] &&
                                orderSummary.paymentSummary[0].paymentMethod &&
                                orderSummary.paymentSummary[0].paymentMethod.toLowerCase() === 'recurring'
                                    ? LABEL.totalRecurring
                                    : LABEL.totalInvestment,
                            data: getTotalInvestmentAmt(orderSummary.totalInvestment),
                        },
                        {
                            label: LABEL.transactionTypeLong,
                            data: emptyCheck(orderSummary.transactionType),
                        },

                        {
                            label: 'Designated KIB Branch',
                            data: emptyCheck(orderSummary.transactionDetails.kibProcessingBranch),
                        },
                    ],
                },
            ],
        },
        accountSummary: {
            title: 'Account Summary',
            sections: [
                {
                    data: [
                        {
                            label: 'Registration Date',
                            data: orderSummary.transactionDetails.registrationDate
                                ? moment(orderSummary.transactionDetails.registrationDate, 'x').format('DD/MM/YYYY')
                                : '-',
                        },
                        {
                            label: 'Account Type',
                            data: emptyCheck(orderSummary.transactionDetails.accountType),
                        },
                        {
                            label: 'Account Number',
                            data: emptyCheck(orderSummary.transactionDetails.accountNo),
                        },
                        {
                            label: 'Risk Profile',
                            data: emptyCheck(orderSummary.profile[0].personalDetails.riskProfile),
                        },
                        {
                            label: 'Servicing Adviser Name & Code',
                            data: emptyCheck(orderSummary.transactionDetails.servicingAdviserName),
                            subText: emptyCheck(orderSummary.transactionDetails.servicingAdviserCode),
                            subTextStyle: { fontWeight: 400, fontSize: '14px' },
                        },
                        {
                            label: 'BDM Name',
                            data: emptyCheck(orderSummary.transactionDetails.bdmName),
                        },
                        {
                            label: 'Authorised Signatory',
                            data: emptyCheck(orderSummary.transactionDetails.accountOperationMode),
                        },
                    ],
                },
            ],
        },
        investmentSummary: {
            title: 'Investment Summary',
            sections: fundSummaryObj,
        },
        paymentSummary: {
            title: 'Payment Summary',
            sections: paymentSummaryObj,
        },
    };
    const profile: IClientProfile[] = [];

    orderSummary.profile.map((item) => {
        const localBankObj: ISection[] = [];
        const foreignBankObj: ISection[] = [];
        let bankCounter = 1;
        if (item.bankInformation !== null) {
            if (item.bankInformation.localBank !== null)
                item.bankInformation.localBank.map((bank, index) => {
                    const tmpBank = {
                        title: `Local Bank ${index + 1}`,
                        titleIcon: 'bank',
                        data: [
                            { label: 'Currency', data: emptyCheck(bank.currency[0]) },
                            { label: 'Bank Name', data: emptyCheck(bank.bankName) },
                            { label: 'Bank Account Name', data: emptyCheck(bank.bankAccountName) },
                            { label: 'Bank Account No.', data: emptyCheck(bank.bankAccountNumber) },
                        ],
                    };
                    localBankObj.push(tmpBank);
                    bankCounter = bankCounter + 1;
                });
            if (item.bankInformation.foreignBank !== null)
                item.bankInformation.foreignBank.map((bank) => {
                    const tmpBank = {
                        title: `Foreign Bank ${bankCounter}`,
                        titleIcon: 'bank',
                        data: [
                            { label: 'Currency', data: emptyCheck(bank.currency[0]) },
                            { label: 'Bank Name', data: emptyCheck(bank.bankName) },
                            { label: 'Bank Account Name', data: emptyCheck(bank.bankAccountName) },
                            { label: 'Bank Account No.', data: emptyCheck(bank.bankAccountNumber) },
                        ],
                    };
                    if (bank.bankLocation !== undefined) {
                        tmpBank.data.push({ label: 'Bank Location', data: emptyCheck(bank.bankLocation) });
                    }
                    if (bank.bankSwiftCode !== undefined) {
                        tmpBank.data.push({ label: 'Bank Swift Code', data: emptyCheck(bank.bankSwiftCode) });
                    }
                    foreignBankObj.push(tmpBank);
                    bankCounter = bankCounter + 1;
                });
        }
        const bankInfo: ISection[][] = [];
        bankInfo.push(localBankObj, foreignBankObj);
        const personalDetails = [
            { label: 'Date of birth', data: emptyCheck(item.personalDetails.dateOfBirth) },
            { label: 'Salutation', data: emptyCheck(item.personalDetails.salutation) },
            { label: 'Gender', data: emptyCheck(item.personalDetails.gender) },
            { label: 'Nationality', data: emptyCheck(item.personalDetails.nationality) },
        ];
        if (item.personalDetails.nationality === 'Malaysia') {
            personalDetails.push(
                {
                    label: 'Bumiputera',
                    data:
                        item.personalDetails.bumiputera !== undefined
                            ? emptyCheck(item.personalDetails.bumiputera)
                            : '-',
                },
                {
                    label: 'Race',
                    data: item.personalDetails.race !== undefined ? emptyCheck(item.personalDetails.race) : '-',
                },
            );
        }
        personalDetails.push(
            { label: 'Place of Birth', data: emptyCheck(item.personalDetails.placeOfBirth) },
            { label: 'Country of Birth', data: emptyCheck(item.personalDetails.countryOfBirth) },
            { label: 'Education Level', data: emptyCheck(item.personalDetails.educationLevel) },
            { label: `Mother's Maiden Name`, data: emptyCheck(item.personalDetails.mothersMaidenName) },
            { label: 'Marital Status', data: emptyCheck(item.personalDetails.maritalStatus) },
            { label: 'Risk Profile', data: emptyCheck(item.personalDetails.riskProfile) },
            {
                label: 'Monthly Household Income (MYR)',
                data: emptyCheck(item.personalDetails.monthlyHouseholdIncome),
            },
        );
        if (item.personalDetails.relationship !== null)
            personalDetails.push({
                label: 'Relationship with Joint Holder',
                data: emptyCheck(item.personalDetails.relationship),
            });
        const tempEpf = [];
        const tempFatca: IDataWithLabel[] = [];
        const tempCrs = [];
        const tempFea = [];
        let tempEmploymentInfoSection: ISection = {
            data: [],
        };
        let tempEmploymentInfoAddress: ISection = {
            data: [],
        };
        const tempEmploymentInformation: ISection[] = [];
        if (item.epfDetails !== null && item.epfDetails !== undefined) {
            tempEpf.push(
                {
                    label: 'EPF Member Number',
                    data: item.epfDetails !== null ? emptyCheck(item.epfDetails.epfMemberNumber) : '-',
                },
                {
                    label: 'EPF Account Type',
                    data: item.epfDetails !== null ? emptyCheck(item.epfDetails.epfAccountType) : '-',
                },
            );
        }
        const epfDetails = tempEpf.length > 0 ? tempEpf : [];
        const epfDetailsObj = {
            title: 'EPF Details',
            sections: [
                {
                    data: epfDetails,
                },
            ],
        };
        if (item.fatcaDeclaration !== null) {
            tempFatca.push(
                { label: 'US Citizenship', data: emptyCheck(item.fatcaDeclaration.usCitizen) },
                { label: 'Born in USA', data: emptyCheck(item.fatcaDeclaration.usBorn) },
                {
                    label: 'Residential Place in Malaysia',
                    data: emptyCheck(item.fatcaDeclaration.confirmAddress),
                },
                {
                    label: 'Reason for Certificate Not Available',
                    data: emptyCheck(item.fatcaDeclaration.reason),
                },
                {
                    label: 'Correspondence Declaration',
                    data: emptyCheck(item.fatcaDeclaration.correspondenceDeclaration),
                },
            );
            if (item.fatcaDeclaration.certificate !== null && item.fatcaDeclaration.certificate !== undefined) {
                tempFatca.splice(3, 0, {
                    label: 'Certificate of Loss of Nationality',
                    data: emptyCheck(item.fatcaDeclaration.certificate.name),
                    dataIcon: item.fatcaDeclaration.certificate.url === null ? '' : 'file',
                    onClickData: emptyCheck(item.fatcaDeclaration.certificate.url),
                });
            } else {
                tempFatca.splice(3, 0, {
                    label: 'Certificate of Loss of Nationality',
                    data: '-',
                });
            }
            if (item.fatcaDeclaration.formW9 !== null && item.fatcaDeclaration.formW9 !== undefined) {
                tempFatca.push({
                    label: 'W9 Physical Document',
                    data: emptyCheck(item.fatcaDeclaration.formW9?.name ? item.fatcaDeclaration.formW9.name : ''),
                    dataIcon: item.fatcaDeclaration.formW9?.url === null ? '' : 'file',
                    onClickData: emptyCheck(item.fatcaDeclaration.formW9?.url ? item.fatcaDeclaration.formW9.url : ''),
                });
            } else {
                tempFatca.push({
                    label: 'W9 Physical Document',
                    data: '-',
                });
            }
            if (item.fatcaDeclaration.formW8Ben !== null && item.fatcaDeclaration.formW8Ben !== undefined) {
                tempFatca.push({
                    label: 'W-8BEN Physical Document',
                    data: emptyCheck(item.fatcaDeclaration.formW8Ben?.name ? item.fatcaDeclaration.formW8Ben.name : ''),
                    dataIcon: item.fatcaDeclaration.formW8Ben?.url === null ? '' : 'file',
                    onClickData: emptyCheck(
                        item.fatcaDeclaration.formW8Ben?.url ? item.fatcaDeclaration.formW8Ben.url : '',
                    ),
                });
            } else {
                tempFatca.push({
                    label: 'W-8BEN Physical Document',
                    data: '-',
                });
            }
        }
        const fatca = tempFatca.length > 0 ? tempFatca : [];
        const fatcaObj = {
            title: 'Foreign Account Tax Compliance Act (FATCA) Declaration',
            sections: [
                {
                    data: fatca,
                },
            ],
        };
        if (item.crsDeclaration !== null) {
            const tin: { label: string; data: string }[] = [];
            if (item.crsDeclaration.tin !== null) {
                item.crsDeclaration.tin.map((ele, index) => {
                    tin.push(
                        {
                            label: `TIN Residence Country ${index + 1}`,
                            data: emptyCheck(ele.country),
                        },
                        {
                            label: `Tax Identification Number (TIN) ${index + 1}`,
                            data: emptyCheck(ele.tinNumber),
                        },
                        {
                            label: `TIN Reasons ${index + 1}`,
                            data: emptyCheck(ele.reason),
                        },
                    );
                });
            } else
                tin.push(
                    {
                        label: `TIN Residence Country`,
                        data: '-',
                    },
                    {
                        label: `Tax Identification Number (TIN)`,
                        data: '-',
                    },
                    {
                        label: `TIN Reasons`,
                        data: '-',
                    },
                );
            tempCrs.push(
                {
                    label: 'Country / Jurisdiction of Residence Declaration (CRS)',
                    data: emptyCheck(item.crsDeclaration.taxResident),
                },
                ...tin,
            );
        }
        const crs = tempCrs.length > 0 ? tempCrs : [];
        const crsObj = {
            title: 'Common Reporting Standard (CRS) Declaration',
            sections: [
                {
                    data: crs,
                },
            ],
        };
        if (item.feaDeclaration !== null && item.feaDeclaration !== undefined) {
            tempFea.push(
                {
                    label: 'Malaysian Resident',
                    data: emptyCheck(item.feaDeclaration.resident),
                },
                {
                    label: 'Domestic Ringgit Borrowing Facility',
                    data: emptyCheck(item.feaDeclaration.borrowingFacility),
                },
                {
                    label: 'Remaining Balance for Investment (MYR)',
                    data: emptyCheck(item.feaDeclaration.balance),
                },
            );
        }
        const fea = tempFea.length > 0 ? tempFea : [];
        const feaObj = {
            title: 'FEA Declaration',
            sections: [
                {
                    data: fea,
                },
            ],
        };
        if (item.employmentInformation !== null) {
            tempEmploymentInfoSection = {
                data: [
                    { label: 'Occupation', data: emptyCheck(item.employmentInformation.occupation) },
                    {
                        label: 'Business Field',
                        data: emptyCheck(item.employmentInformation.natureOfBusiness),
                    },
                    {
                        label: 'Gross Annual Income (MYR)',
                        data: emptyCheck(item.employmentInformation.annualIncome),
                    },
                    { label: 'Employer Name', data: emptyCheck(item.employmentInformation.nameOfEmployer) },
                ],
            };
            tempEmploymentInfoAddress = {
                data: [
                    {
                        label: 'Employer’s Address 1',
                        data: emptyCheck(item.employmentInformation.address.address.line1),
                    },
                    {
                        label: 'Employer’s Address 2',
                        data: emptyCheck(item.employmentInformation.address.address.line2),
                    },
                    {
                        label: 'Employer’s Address 3',
                        data: emptyCheck(item.employmentInformation.address.address.line3),
                    },
                    { label: 'Postcode', data: emptyCheck(item.employmentInformation.address.postCode) },
                    { label: 'City / Town', data: emptyCheck(item.employmentInformation.address.city) },
                    { label: 'State', data: emptyCheck(item.employmentInformation.address.state) },
                    { label: 'Country', data: emptyCheck(item.employmentInformation.address.country) },
                ],
            };
        }
        tempEmploymentInformation.push(tempEmploymentInfoSection, tempEmploymentInfoAddress);
        const employmentInformation = tempEmploymentInformation[0].data.length > 0 ? tempEmploymentInformation : [];
        const employmentInformationObj = {
            title: 'Employment Information',
            sections: employmentInformation,
        };
        let tempProfile: IClientProfile = {
            name: item.name,
            id: item.idNumber,
            idType: item.idType,
            idProof: item.idFile,
            personalDetails: {
                title: 'Investor Summary',
                sections: [
                    {
                        data: personalDetails,
                    },
                ],
            },
        };
        if (epfDetails.length > 0) {
            tempProfile = {
                ...tempProfile,
                epfDetails: epfDetailsObj,
            };
        }
        if (employmentInformation.length > 0) {
            tempProfile = {
                ...tempProfile,
                employmentInformation: employmentInformationObj,
            };
        }

        tempProfile = {
            ...tempProfile,
            bankInformation: {
                title: 'Bank Information',
                sections: bankInfo.length !== 0 ? bankInfo : [],
            },
        };

        if (fatca.length > 0) {
            tempProfile = {
                ...tempProfile,
                fatcaDeclaration: fatcaObj,
            };
        }
        if (crs.length > 0) {
            tempProfile = {
                ...tempProfile,
                crsDeclaration: crsObj,
            };
        }
        if (fea.length > 0) {
            tempProfile = {
                ...tempProfile,
                feaDeclaration: feaObj,
            };
        }

        profile.push(tempProfile);
    });
    const orderSummaryRemapped: IOrderSummaryProps = {
        transactionSummary: transactionSummary,
        profile: profile,
        documentSummary: documentSummary,
    };
    return orderSummaryRemapped;
};
