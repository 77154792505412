/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useComponentLoader } from '../../../../../customHooks';
import { approveRejectSettingsMutation } from '../../../../../_graphql/mutations/systemAdmin/systemSettings/approveRejectSettings';
import { API, graphqlOperation } from 'aws-amplify';
import {
    ComponentLoader,
    CustomButton,
    CustomSpacer,
    FlexedDiv,
    Modal,
    PreviousPage,
    TextArea,
} from '../../../../../components';
import { LABEL, TextDarkGrey6, TextWhite } from '../../../../../constants';

import * as Routes from '../../../../../routes';
import styled from 'styled-components';
import AuthContext from '../../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../../context/ErrorHandling/ErrorHandlingContext';

const RejectRemarkSettings = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const location = useLocation();
    const { loading, loadingHandler } = useComponentLoader();
    const routeProps: IRejectEditEPFRouteProps = location.state as IRejectEditEPFRouteProps;

    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [remark, setRemark] = useState('');

    const handleSubmit = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveRejectSettingsMutation, {
                    input: {
                        tab: routeProps.tab,
                        action: 'reject',
                        remarks: remark,
                        requestId: routeProps.requestId,
                    },
                }),
                idTokenHeader,
            );
            const { error } = await response.data.approveRejectSettings;
            if (error != null) throw error;
            loadingHandler();
            handleFeedbackModal();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot reject system setting',
                testId: 'reject-system-setting-error-modal',
            });
        }
    };
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
    };

    return (
        <Fragment>
            <PreviousPage
                title={'Reject Remarks'}
                subTitle={`You’re about to reject this request to ${
                    routeProps.currentStatus === 'disable' ? 'enable' : 'disable'
                } multiple UTMC effective ${routeProps.requestEffectiveDate}`}
                handleBackFunction={() =>
                    history.push({
                        pathname: Routes.systemConfiguration,
                    })
                }
                subTitleColor="#333333"
                subTitleWeight="600"
            />
            <DivWrapper>
                <TextArea
                    name="Remarks"
                    label="Remarks"
                    value={remark}
                    handleInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRemark(e.currentTarget.value)}
                    maxLength={255}
                    style={{ color: 'black', width: '672px' }}
                />
                <FlexedDiv style={{ marginTop: '3.25rem' }}>
                    <CustomButton
                        primary={false}
                        onClick={() => history.push(Routes.systemConfiguration)}
                        style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '240px' }}
                    >
                        <TextDarkGrey6 size="15px" weight="700">
                            Cancel
                        </TextDarkGrey6>
                    </CustomButton>
                    <CustomSpacer horizontal={true} space={'1rem'} />
                    <CustomButton
                        disabled={remark.trim().length < 1}
                        primary={true}
                        onClick={handleSubmit}
                        style={{ width: '240px' }}
                    >
                        <TextWhite size="15px" weight="700">
                            {LABEL.submit}
                        </TextWhite>
                    </CustomButton>
                </FlexedDiv>
            </DivWrapper>
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={
                        <span>{`${
                            routeProps.currentStatus === 'disable' ? 'Enable' : 'Disable'
                        } Multiple UTMC request rejected`}</span>
                    }
                    icon={'settings-request-success'}
                    primaryBtn={{
                        onClick: () => {
                            handleFeedbackModal();
                            history.push(Routes.systemConfiguration);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="epf-feedback"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Subtitle>
                            {`${routeProps.currentStatus === 'disable' ? 'Enable' : 'Disable'}`} Multiple UTMC request
                            has been rejected,
                            <br />
                            maker will be notified.
                        </Subtitle>
                    </FlexedDiv>
                </Modal>
            ) : null}
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const DivWrapper = styled.div`
    margin-left: 3rem;
`;
export default RejectRemarkSettings;
