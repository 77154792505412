import React, { Fragment } from 'react';
import { Thead } from './';
import { StandardRow } from './StandardRow';
import styled from 'styled-components';

interface StandardTableProps {
    columns: IStandardColumns[];
    data: ITableCellData[];
}

export const StandardTable: React.FC<StandardTableProps> = ({ data, columns }: StandardTableProps) => {
    return (
        <Fragment>
            <StyledTable>
                <Thead columns={columns} />
                <StandardRow data={data} />
            </StyledTable>
        </Fragment>
    );
};

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
`;
