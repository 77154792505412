import gql from 'graphql-tag';

export const pendingAllSubmissionExportList = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    allpendingdashboard {
                        grouping {
                            type
                            groupingData {
                                key
                                value
                            }
                            transactionData {
                                grouping {
                                    type
                                    groupingData {
                                        principalName
                                        principalNumber
                                        orderNo
                                        jointName
                                        jointNumber
                                    }
                                    transactionData {
                                        data {
                                            branchName
                                            orderNo
                                            clientName
                                            clientIdNum
                                            accountType
                                            createdOn
                                            lastUpdated
                                            transactionType
                                            fundType
                                            fundAbbr
                                            paymentMethod
                                            totalInvestment {
                                                fundCurrency
                                                investmentAmount
                                            }
                                            agentName
                                            agentCode
                                            status
                                            aging
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
