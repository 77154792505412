import gql from 'graphql-tag';

/**
 * @SystemAdminExport @query System Admin Activity Log Dashboard
 * @description  Returns requested list of report
 */

export const SystemAdminExport = gql`
    query exportList($input: ExportInput) {
        systemAdminExport(input: $input) {
            data {
                result {
                    message
                    link
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
