/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FlexSpacer, { Banner, ComponentLoader, CustomSpacer, FlexedDiv, Modal } from '../../../components';
import { RadioButton } from '../../../components/atoms/RadioButton/RadioButton';
import {
    Fs10RegNeutralGrey4,
    Fs12BoldPrimaryBlack,
    Fs16SemiBoldSecPrimaryBlack,
    Fs18BoldPrimaryBlack,
    Fs24BoldPrimaryBlack,
    SYSTEM_ADMIN,
    Fs16RegSecPrimaryBlack,
    LABEL,
} from '../../../constants';
import { IcoMoon, IcoMoonSelection } from '../../../icons';
import * as Routes from '../../../routes';
import { setInitialData } from '../../SystemAdmin/RoleSettings/utils/functions';
import {
    adviserInitialData,
    customerExpInitialData,
    IAdviserSection,
    ICustomerExpSection,
    ILoginRoleSection,
    loginAccessInitialData,
} from '../../SystemAdmin/RoleSettings/AddRoles/BranchPrivileges/branchRolesInitialData';
import {
    activityLogsInitialData,
    branchOfficesInitialData,
    customerExperienceInitialData,
    eddCaseInitialData,
    financeCasesInitialData,
    financeDashboardInitialData,
    fundUploadsInitialData,
    IActivityLogsSection,
    IBranchOfficeSection,
    ICustomerExperienceSection,
    IEDDCasesSection,
    IFinanceCasesSection,
    IFinanceDashboardSection,
    IFundUploads,
    IOperationCases,
    IOperationDashboard,
    IProductSettings,
    ISystemSettingsSection,
    IUserManagementSection,
    operationCasesInitialData,
    operationDashboardInitialData,
    productSettingsInitialData,
    systemSettingsInitialData,
    userManagementInitialData,
} from '../../SystemAdmin/RoleSettings/AddRoles/HqPrivileges/hqRolesInitialData';
import { RejectRemarks } from '../../SystemAdmin/RoleSettings/AddRoles/RejectRemarks';
import { API, graphqlOperation } from 'aws-amplify';
import approveReject from '../../../_graphql/mutations/systemAdmin/approveReject';
import { IUserManagement } from '../../../utils/permissionTypes';
import {
    BranchPrivileges,
    BranchPrivilegesProps,
    DropdownBase,
} from '../../SystemAdmin/RoleSettings/AddRoles/BranchPrivileges/BranchPrivileges';
import { HqPrivileges, HqPrivilegesProps } from '../../SystemAdmin/RoleSettings/AddRoles/HqPrivileges';
import { useComponentLoader } from '../../../customHooks';
import { getSystemAdminInboxData } from '../../../_graphql/queries/inbox/getSystemAdminInboxData';
import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import IcomoonReact from 'icomoon-react';

export interface CheckBoxState {
    checked: boolean;
    value: string;
}

export interface IApproveReject {
    requestId: string;
    action: string;
    remarks?: string;
}
interface IRolesViewInboxProps {
    id: string;
}
export type Privileges = 'branch' | 'hq';

const RolesViewInbox = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [roleName, setRoleName] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [roleDescription, setRoleDescription] = useState<string>('');
    const [loginAccess, setLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [hqLoginAccess, setHqLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [customerExp, setCustomerExp] = useState<ICustomerExpSection>(customerExpInitialData);
    const [userManagement, setUserManagement] = useState<IUserManagementSection>(userManagementInitialData);
    const [activityLogs, setActivityLogs] = useState<IActivityLogsSection>(activityLogsInitialData);
    const [systemSettings, setSystemSettings] = useState<ISystemSettingsSection>(systemSettingsInitialData);
    const [customerExpDashboard, setCustomerExpDashboard] =
        useState<ICustomerExperienceSection>(customerExperienceInitialData);
    const [branchOffices, setBranchOffices] = useState<IBranchOfficeSection>(branchOfficesInitialData);
    const [eddCase, setEddCase] = useState<IEDDCasesSection>(eddCaseInitialData);
    const [financeDashboard, setFinanceDashboard] = useState<IFinanceDashboardSection>(financeDashboardInitialData);
    const [financeCases, setFinanceCases] = useState<IFinanceCasesSection>(financeCasesInitialData);
    const [productSettings, setProductSettings] = useState<IProductSettings>(productSettingsInitialData);
    const [fundUploads, setFundUploads] = useState<IFundUploads>(fundUploadsInitialData);
    const [operation, setOperation] = useState<IOperationDashboard>(operationDashboardInitialData);
    const [operationCases, setOperationCases] = useState<IOperationCases>(operationCasesInitialData);
    const [advisers, setAdvisers] = useState<IAdviserSection>(adviserInitialData);
    const [privilege, setPrivilege] = useState<Privileges>('branch');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<boolean>(false);
    const [remarks, setRemarks] = useState<string>('');
    const [author, setAuthor] = useState<string>('');
    const history = useHistory();
    const location = useLocation<IRolesViewInboxProps>();
    const locationState = location.state;
    const requestId = locationState.id;
    const approval = true;
    const [source, setSource] = useState<string>('');
    const [approvalStatus, setApprovalStatus] = useState<string>('');
    const [approver, setApprover] = useState<string>('');

    const parsedPermission = JSON.parse(userLoginContext.permission);
    // console.log('parsed', parsedPermission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;

    const { rolesPermissionTab } = userManagementPermission;
    const { canEditUserRoleDetails } = rolesPermissionTab.actions;

    const { loading, loadingHandler } = useComponentLoader();
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const handleRoleName = (event: ChangeEvent<HTMLInputElement>) => {
        setRoleName(event.target.value);
    };

    const handleRoleDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRoleDescription(event.target.value);
    };

    const handleApproveReject = async (action: string) => {
        let inputObj: IApproveReject = { requestId: requestId, action: action };
        if (action === 'reject') {
            inputObj = { ...inputObj, remarks: remarks };
        }
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: inputObj,
                }),
                idTokenHeader,
            );

            if (response.data.approveReject.data !== null) {
                setShowModal(true);
            } else {
                throw response.data.approveReject.error;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Review Request',
                testId: 'notifications-roles-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleApprove = () => {
        handleApproveReject('approve');
    };

    const handleReject = () => {
        setRejectRemarks(true);
    };

    const handleRejectApi = () => {
        handleApproveReject('reject');
    };

    const handleBranchPrivilege = () => {
        if (privilege !== 'branch') {
            setPrivilege('branch');
        }
    };
    const handleHqPrivilege = () => {
        if (privilege !== 'hq') {
            setPrivilege('hq');
        }
    };

    const checkApprove = () => {
        switch (source) {
            case 'createRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveCreateUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveCreateUserRole === 'auto-authorizer'
                );
            case 'editRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveEditUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveEditUserRole === 'auto-authorizer'
                );
            case 'suspendRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveSuspendUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveSuspendUserRole === 'auto-authorizer'
                );
            case 'enableRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveReactivateUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveReactivateUserRole === 'auto-authorizer'
                );
            case 'deleteRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveDeleteUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveDeleteUserRole === 'auto-authorizer'
                );
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const branchProps: BranchPrivilegesProps = {
        approval,
        loginAccess,
        setLoginAccess,
        advisers,
        setAdvisers,
        customerExp,
        setCustomerExp,
    };
    const hqProps: HqPrivilegesProps = {
        approval,
        hqLoginAccess,
        setHqLoginAccess,
        userManagement,
        setUserManagement,
        activityLogs,
        setActivityLogs,
        systemSettings,
        setSystemSettings,
        customerExpDashboard,
        setCustomerExpDashboard,
        branchOffices,
        setBranchOffices,
        eddCase,
        setEddCase,
        financeDashboard,
        setFinanceDashboard,
        financeCases,
        setFinanceCases,
        productSettings,
        setProductSettings,
        fundUploads,
        setFundUploads,
        operationDashBoard: operation,
        setOperationDashBoard: setOperation,
        operationCases,
        setOperationCases,
    };

    const getRoleData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(getSystemAdminInboxData, {
                    input: {
                        id: requestId,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.systemAdminInboxData;
            if (resultCheck.error !== null) throw resultCheck.error;

            const { result } = resultCheck.data;
            const data = JSON.parse(result.data.value);
            const tempPermissionObj =
                typeof data.permission === 'string' ? JSON.parse(data.permission) : data.permission;

            setPrivilege(tempPermissionObj.grant);
            setRoleName(data.name);
            setApprovalStatus(result.data.approvalStatus);
            result.data.approverName !== null ? setApprover(result.data.approverName) : null;
            setSource(result.data.source);
            result.data.approvalStatus.toLowerCase() === 'pending'
                ? setStatus(data.requestedStatus)
                : setStatus(data.requestedStatus);
            setRoleDescription(data.description !== null && data.description !== undefined ? data.description : '');
            setAuthor(result.data.authorName);
            result.data.remarks !== null ? setRemarks(result.data.remarks) : null;
            const { permission } = tempPermissionObj;
            if (tempPermissionObj.grant === 'branch') {
                const newLoginAccess = setInitialData(
                    permission.accountManagement,
                    loginAccess,
                    tempPermissionObj.grant,
                    'accountManagement',
                ) as ILoginRoleSection;
                setLoginAccess(newLoginAccess);
                const newCustomerExp = setInitialData(
                    permission.ceTransaction,
                    customerExp,
                    tempPermissionObj.grant,
                    'ceTransaction',
                ) as ICustomerExpSection;
                setCustomerExp(newCustomerExp);
                const newAdvisers = setInitialData(
                    permission.adviser,
                    advisers,
                    tempPermissionObj.grant,
                    'adviser',
                ) as IAdviserSection;
                setAdvisers(newAdvisers);
            } else {
                const newHqLoginAccess = setInitialData(
                    permission.accountManagement,
                    hqLoginAccess,
                    tempPermissionObj.grant,
                    'accountManagement',
                ) as ILoginRoleSection;
                setHqLoginAccess(newHqLoginAccess);
                const newHqUserManagement = setInitialData(
                    permission.userManagement,
                    userManagement,
                    tempPermissionObj.grant,
                    'userManagement',
                ) as IUserManagementSection;
                setUserManagement(newHqUserManagement);
                const newHqActivityLogs = setInitialData(
                    permission.activityLogs,
                    activityLogs,
                    tempPermissionObj.grant,
                    'activityLogs',
                ) as IActivityLogsSection;
                setActivityLogs(newHqActivityLogs);
                const newHqSystemSettings = setInitialData(
                    permission.systemSettings,
                    systemSettings,
                    tempPermissionObj.grant,
                    'systemSettings',
                ) as ISystemSettingsSection;
                setSystemSettings(newHqSystemSettings);
                const newHqCustomerExpDashboard = setInitialData(
                    permission.ceDashboard,
                    customerExpDashboard,
                    tempPermissionObj.grant,
                    'ceDashboard',
                ) as ICustomerExperienceSection;
                setCustomerExpDashboard(newHqCustomerExpDashboard);
                const newBranchOffices = setInitialData(
                    permission.branchOffices,
                    branchOffices,
                    tempPermissionObj.grant,
                    'branchOffices',
                ) as IBranchOfficeSection;
                setBranchOffices(newBranchOffices);
                const newProductSettings = setInitialData(
                    permission.productSettings,
                    productSettings,
                    tempPermissionObj.grant,
                    'productSettings',
                ) as IProductSettings;
                setProductSettings(newProductSettings);
                const newFundUploads = setInitialData(
                    permission.uploads,
                    fundUploads,
                    tempPermissionObj.grant,
                    'uploads',
                ) as IFundUploads;
                setFundUploads(newFundUploads);
                const newEddCase = setInitialData(
                    permission.eddCase,
                    eddCase,
                    tempPermissionObj.grant,
                    'eddCase',
                ) as IEDDCasesSection;
                setEddCase(newEddCase);
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Roles & Permissions Data',
                testId: 'notifications-roles-error-modal',
            });
            handleErrorHandler();
        }
    };

    useEffect(() => {
        getRoleData();
    }, []);

    const getModalText = () => {
        const textValues = {
            approvedTitle: '',
            approvedSubtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_HAS_BEEN_UPDATED_SUCCESSFULLY,
            rejectedTitle: '',
            rejectedSubtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED,
        };
        switch (source) {
            case 'createRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_REJECTED;
                break;
            case 'editRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_REJECTED;
                break;
            case 'suspendRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_REJECTED;
                break;
            case 'enableRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_REJECTED;
                break;
            case 'deleteRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_REJECTED;
                break;
        }
        return textValues;
    };

    const modalTextValues = getModalText();
    const disabledStyle: React.CSSProperties = approval === true ? { opacity: 0.5, pointerEvents: 'none' } : {};
    // const modalIcon =
    //     approval === true || canEditUserRoleDetails !== 'maker' ? 'user-modal-success' : 'user-modal-processing';
    const modalText =
        approval === true
            ? modalTextValues.approvedTitle
            : canEditUserRoleDetails === 'maker'
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_SUBMITTED
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_UPDATED;
    const modalTextRemarks = rejectRemarks === true ? modalTextValues.rejectedTitle : modalText;
    const approvedModalSubName = roleName;
    const modalSubTextName = approval === true || canEditUserRoleDetails !== 'maker' ? approvedModalSubName : '';
    const modalSubTextRemarksName = rejectRemarks === true ? '' : modalSubTextName;
    const approvedModalSub = modalTextValues.approvedSubtitle;
    const modalSubText =
        approval === true || canEditUserRoleDetails !== 'maker'
            ? approvedModalSub
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
    const modalSubTextRemarks = rejectRemarks === true ? SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED : modalSubText;
    const bannerSecondaryText = `${SYSTEM_ADMIN.LABEL_REQUESTED_BY} ${author}.`;
    const header =
        approval === true
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_EDIT_ROLE_REQUEST
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE;
    const subHeader1 =
        approval === true
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_ROLE_REQUEST_SUB_1
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_1;
    const subHeader2 =
        approval === true
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_ROLE_REQUEST_SUB_2
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_2;

    return (
        <Container>
            {rejectRemarks === false ? (
                <Fragment>
                    <div>
                        <TitleContainer style={{ flexDirection: 'row', display: 'flex' }}>
                            <Icon onClick={handleBack}>
                                <IcomoonReact iconSet={IcoMoonSelection} icon="arrow-left" size="1.5rem" />
                            </Icon>
                            <CustomSpacer horizontal={true} space={'1.5rem'} />
                            <Fs24BoldPrimaryBlack>{header}</Fs24BoldPrimaryBlack>
                        </TitleContainer>
                        <div style={{ paddingLeft: '3rem' }}>
                            <Fs16SemiBoldSecPrimaryBlack>{subHeader1}</Fs16SemiBoldSecPrimaryBlack>
                            <Fs16SemiBoldSecPrimaryBlack>{subHeader2}</Fs16SemiBoldSecPrimaryBlack>
                            <CustomSpacer space={'2rem'} />
                            <div>
                                {approvalStatus.toLowerCase() !== 'pending' ? (
                                    <ApprovalBanner direction="column">
                                        <Title>{`This request was ${approvalStatus.toLowerCase()} by ${approver}`}</Title>

                                        <FlexedDiv
                                            style={{ paddingTop: '0.5rem', width: '100%', whiteSpace: 'break-spaces' }}
                                            justifyContent="space-between"
                                        >
                                            {remarks !== undefined && remarks.length !== 0
                                                ? `${LABEL.rejectedReason}: ${remarks}`
                                                : ''}
                                        </FlexedDiv>
                                    </ApprovalBanner>
                                ) : null}
                            </div>
                            <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DETAILS}</Fs18BoldPrimaryBlack>
                            <CustomSpacer space={'1.5rem'} />
                            <FlexedDiv direction="row">
                                <FlexedDiv direction="column" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_NAME}
                                    </Fs12BoldPrimaryBlack>
                                    <BasicInput disabled value={roleName} maxLength={255} onInput={handleRoleName} />
                                </FlexedDiv>
                                <CustomSpacer horizontal={true} space={'4rem'} />
                                <FlexedDiv direction="column">
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_STATUS}
                                    </Fs12BoldPrimaryBlack>
                                    <DropdownBase>
                                        <BasicInputDropdown value={status} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </FlexedDiv>
                            </FlexedDiv>
                            <CustomSpacer space={'2rem'} />
                            <FlexedDiv direction="column" style={disabledStyle}>
                                <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                    {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DESCRIPTION}
                                </Fs12BoldPrimaryBlack>
                                <BasicTextArea value={roleDescription} onChange={handleRoleDescription} />
                                <FlexedDiv direction="row" style={{ width: '22.5rem' }}>
                                    <FlexSpacer />
                                    <Fs10RegNeutralGrey4>{roleDescription.length}/255</Fs10RegNeutralGrey4>
                                </FlexedDiv>
                            </FlexedDiv>
                            <CustomSpacer space={'0.5rem'} />
                        </div>
                        <div style={{ backgroundColor: '#EAEBEE', height: '1px' }} />
                        <CustomSpacer space={'2rem'} />
                        <div style={{ paddingLeft: '3rem' }}>
                            <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_GRANT_PRIVILEGES}</Fs18BoldPrimaryBlack>
                            <CustomSpacer space={'1.75rem'} />
                            <DisabledView>
                                <RadioButton
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_BACKOFFICE}
                                    selected={privilege === 'branch'}
                                    setSelected={handleBranchPrivilege}
                                />
                                <CustomSpacer space={'1rem'} />
                                <RadioButton
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_HQ_BACKOFFICE}
                                    selected={privilege === 'hq'}
                                    setSelected={handleHqPrivilege}
                                />
                            </DisabledView>
                            {privilege === 'branch' ? (
                                <Fragment>
                                    <CustomSpacer space={'1.75rem'} />
                                    <div style={{ backgroundColor: '#EAEBEE', height: '1px', marginRight: '8.5rem' }} />
                                    <CustomSpacer space={'1.5rem'} />
                                    <BranchPrivileges {...branchProps} />
                                </Fragment>
                            ) : null}
                            {privilege === 'hq' ? (
                                <Fragment>
                                    <CustomSpacer space={'1.75rem'} />
                                    <div style={{ backgroundColor: '#EAEBEE', height: '1px', marginRight: '8.5rem' }} />
                                    <CustomSpacer space={'1.5rem'} />
                                    <HqPrivileges {...hqProps} />
                                </Fragment>
                            ) : null}
                            <CustomSpacer space={'1.5rem'} />
                        </div>
                    </div>
                    {checkApprove() === true && approvalStatus.toLowerCase() === 'pending' ? (
                        <Fragment>
                            <CustomSpacer space={'6.5rem'} />
                            <Banner
                                title={SYSTEM_ADMIN.LABEL_PENDING_APPROVAL}
                                description={bannerSecondaryText}
                                toggle={true}
                                testId="rolesviewinbox-banner"
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                handleClose={() => {}}
                                primaryButtonObject={{
                                    handlePrimaryBtn: handleApprove,
                                    label: LABEL.approve,
                                }}
                                secondaryButtonObject={{
                                    handleSecondaryBtn: handleReject,
                                    label: LABEL.reject,
                                }}
                            />
                        </Fragment>
                    ) : null}
                </Fragment>
            ) : (
                <RejectRemarks
                    setRejectRemarks={setRejectRemarks}
                    remarks={remarks}
                    setRemarks={setRemarks}
                    setShowModal={handleRejectApi}
                    showModal={showModal}
                />
            )}
            {loading ? <ComponentLoader /> : null}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTextRemarks}
                    primaryBtn={{
                        onClick: () => {
                            history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="rolesPermissions-inbox-modal"
                    contentAlignment="center"
                    icon="targetdate-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Fs16SemiBoldSecPrimaryBlack style={{ textAlign: 'center' }}>
                            {modalSubTextRemarksName}
                            <Fs16RegSecPrimaryBlack> {modalSubTextRemarks}</Fs16RegSecPrimaryBlack>
                        </Fs16SemiBoldSecPrimaryBlack>
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    overflow: hidden;
    position: relative;
`;

const TitleContainer = styled.div`
    align-items: center;
`;

const DisabledView = styled.div`
    opacity: 0.5;
    pointer-events: none;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;

const BasicInput = styled.input.attrs({ type: 'input' })`
    background-color: white;
    border-color: #c9c9cd;
    border-width: 0.0625rem;
    border: 1px solid #c9c9cd;
    border-radius: 24px;
    height: 2.5rem;
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
    width: 22.5rem;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

export const BasicInputDropdown = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 18.5rem;
`;

export const BasicTextArea = styled.textarea`
    height: 7.5rem;
    border-radius: 8px;
    width: 22.5rem;
    border: 1px solid #c9c9cd;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

export default RolesViewInbox;
