import React, { Fragment } from 'react';
import { Avatar } from '../../../../../components';
import styled, { css } from 'styled-components';

type CurvedCardProps = {
    disabled?: boolean;
    label?: string;
    link?: string;
    mode?: string;
    onChange?: () => void;
    title?: string;
    titleSize?: number;
    backgroundColor?: string;
    showCustomTitle?: boolean;
    subTitle?: string;
    customTitle?: string;
    width?: number;
    showDashboard?: string | undefined;
    key?: number;
    circleBackGround?: string;
    showArrow: boolean;
    indexValue: number;
    index: number;
};
declare interface CardContainerProps {
    width: number;
    clickable: boolean;
}
declare interface StyledCircleProps {
    backGroundColor?: string;
}
declare interface ICircleProps {
    clickable: boolean;
}

declare interface IStyledFontProps {
    fontWeight: number;
}
export const CurvedCard: React.FC<CurvedCardProps> = ({
    width,
    disabled,
    label,
    title,
    subTitle,
    circleBackGround,
    showArrow,
    indexValue,
    index,
}: CurvedCardProps) => {
    const _width = width !== undefined ? width : 17.5;
    return (
        <Fragment>
            <CardContainer
                width={_width}
                className="curvedcard"
                clickable={disabled == true && indexValue == index + 1}
                data-testid="ccard"
            >
                <Circle clickable={disabled == true && indexValue == index + 1}>
                    <Avatar
                        backgroundColor={circleBackGround}
                        testId="investor-sm"
                        size={48}
                        customStyles={{ marginBottom: '8px' }}
                    >
                        {label}
                    </Avatar>
                </Circle>
                <CardBodyWrapper>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledSubTitle>
                        <StyledFont fontWeight={700}>{subTitle?.split('|')[0]}</StyledFont>
                        {subTitle?.includes('|') && (
                            <Fragment>
                                <VerticalDivider />
                                <StyledFont fontWeight={400}>{subTitle?.split('|')[1]}</StyledFont>
                            </Fragment>
                        )}
                    </StyledSubTitle>
                </CardBodyWrapper>
            </CardContainer>
            {showArrow && <ArrowHead />}
        </Fragment>
    );
};

const StyledFont = styled.div<IStyledFontProps>`
    font-weight: ${(props) => props.fontWeight};
`;
const CardContainer = styled.div<CardContainerProps>`
    position: relative;
    height: 140px;
    background: #ffffff;
    border: 1px solid #eaebee;
    box-sizing: border-box;
    border-radius: 16px;
    // width: ${(props) => props.width}em;
    max-width: 100%;
    &:hover {
        background: #e7ebf1;
        cursor: pointer;
    }
    ${(props: CardContainerProps) =>
        props.clickable &&
        css`
            box-shadow: 2px 2px 8px rgba(0, 32, 67, 0.04), 6px 6px 16px rgba(0, 32, 67, 0.04);
            background-color: #e7ebf1;
            &:hover {
                cursor: pointer;
            }
            border: 1px solid rgb(0, 32, 67);
        `}
`;
const Circle = styled.div<ICircleProps>`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 64px;
    height: 33px;
    position: absolute;
    left: 50%;
    top: -1px;
    transform: translateX(-50%);
    border-bottom: 1px solid #eaebee;
    border-left: 1px solid #eaebee;
    border-right: 1px solid #eaebee;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    background: #fbfbfb;
    ${(props: ICircleProps) =>
        props.clickable &&
        css`
            border-bottom: 1px solid rgb(0, 32, 67);
            border-left: 1px solid rgb(0, 32, 67);
            border-right: 1px solid rgb(0, 32, 67);
        `}
`;
const StyledTitle = styled.label`
    font-weight: bold;
    font-size: 18px;
    display: flex;
    color: #171717;
    flex: 0 0 auto;
    order: 0;
    align-self: stretch;
    padding-bottom: 2%;
    &:hover {
        cursor: pointer;
    }
`;
const StyledSubTitle = styled.label`
    font-size: 12px;
    line-height: 130%;
    color: #4d4d4d;
    &:hover {
        cursor: pointer;
    }
    display: flex;
    flex-direction: row;
`;
const VerticalDivider = styled.span`
    // width: 16px;
    border: 1px solid #4d4d4d;
    margin: 0px 8px;
`;

const ArrowHead = styled.div`
    width: 0;
    height: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    margin-left: 50%;
`;
const CardBodyWrapper = styled.div`
    padding-top: 15%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
`;
