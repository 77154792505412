/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Dashboard,
    SearchBar,
    CustomButton,
    CustomSpacer,
    ComponentLoader,
    SelectDropdown,
    DatePicker,
    Modal,
    FlexedDiv,
} from '../../components';
import { useSearchFilters } from '../../customHooks';
import { IColumnValue } from '../Advisers';
import { LABEL, SearchColumnsUploads } from '../../constants';
import { MasterFund, NAVPerUnit, Distribution, Documents } from '.';
import { API, graphqlOperation } from 'aws-amplify';
import {
    fundsExportList,
    bulkDocumentsExportList,
} from '../../_graphql/queries/fundData/productSettingsDashbaord/fundDocumentDashboardExportList';

import { exportListUploadsDashboard, updateIsSeenDashboard } from '../../utils';
import { DocumentNode } from 'graphql';
import { selectedTabUploads } from './selectedTabUploads';
import AuthContext from '../../context/AuthContext';
import UploadsContext from '../../context/UploadsContext';
import styled from 'styled-components';
import * as Routes from '../../routes';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import moment from 'moment';

/**
 * Uploads Dashboard for Fund Management
 * @category Pages
 * @component
 * @namespace Upload
 * @returns Uploads dashboard component
 */
const UploadsDashboard: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const {
        tab,
        currentPage,
        maxPages,
        dropDownFilters,
        searchInput,
        sortData,
        tableFilterQuery,
        masterFundData,
        navPerUnitData,
        distributionData,
        documentsData,
        initialRender,
        loading,
        resultLimit,
        setTab,
        setResultLimit,
        setCurrentPage,
        setDropDownFilters,
        setTableFilterQuery,
        setSearchInput,
        setSortData,
        getAllMasterFundData,
        getAllNAVPerUnitData,
        getAllDistributionData,
        getAllDocumentsData,
        resetColumnFilters,
        disableResultLimit,
    } = useContext(UploadsContext);
    const history = useHistory();

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { uploads } = parsedPermission.hq.permission;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    // states for dashboard UI components
    const [expand, setExpand] = useState<boolean>(false);
    const [showFundDataModal, setShowFundDataModal] = useState(true);
    const [showBulkDocModal, setShowBulkDocModal] = useState(true);
    const [documnetCount, setDocumnetCount] = useState<number>(0);
    const [modalHeading, setModalHeading] = useState<string>('');
    const [modalSubHeading, setModalSubHeading] = useState<string>('');
    const [initialFilterHookValues, setInitialFilterHookValues] = useState<IColumnValue[]>([]);
    //Tab permission states
    const [tabPrimaryButtons, setTabPrimaryButtons] = useState<IActionButton>();
    const [tabSecondaryButtons, setTabSecondaryButtons] = useState<IActionButton[]>();
    // datepicker error messages state
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');

    //Custom hooks
    const { filterValues, dateRange, handleFilterChange, handleFilterDate } = useSearchFilters(initialFilterHookValues);

    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const resultLimitRef = useRef<number>();

    const tabs: IDashboardTabs[] = [];

    const checkTabSecondaryButtons = (tabIndex: number): IActionButton[] => {
        const _tempSecondary: IActionButton[] = [];

        switch (tabs[tabIndex].dashboardId) {
            case 'masterfund':
                if (
                    uploads.masterFundTab.actions.canExportList === 'maker' ||
                    uploads.masterFundTab.actions.canExportList === 'checker' ||
                    uploads.masterFundTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-masterfund-exportlist-btn',
                    });
                }
                break;
            case 'navperunit':
                if (
                    uploads.navPerUnitTab.actions.canExportList === 'maker' ||
                    uploads.navPerUnitTab.actions.canExportList === 'checker' ||
                    uploads.navPerUnitTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-nav-exportlist-btn',
                    });
                }
                break;
            case 'distribution':
                if (
                    uploads.distributionTab.actions.canExportList === 'maker' ||
                    uploads.distributionTab.actions.canExportList === 'checker' ||
                    uploads.distributionTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-distribution-exportlist-btn',
                    });
                }
                break;
            case 'documents':
                if (
                    uploads.documentsTab.actions.canExportList === 'maker' ||
                    uploads.documentsTab.actions.canExportList === 'checker' ||
                    uploads.documentsTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-documents-exportlist-btn',
                    });
                }
                break;
        }
        return _tempSecondary;
    };
    const checkTabPrimaryBtns = (tabIndex: number): IActionButton | undefined => {
        let _tempPrimary: IActionButton = {
            title: '',
            type: 'primary',
            actionIcon: 'import',
            isDropdown: false,
            testId: '',
        };
        switch (tabs[tabIndex].dashboardId) {
            case 'masterfund':
                if (
                    uploads.masterFundTab.actions.canImportMasterFund === 'maker' ||
                    uploads.masterFundTab.actions.canImportMasterFund === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Import Master Fund`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-masterfund-import-btn',
                    };
                    return _tempPrimary;
                }

                break;
            case 'navperunit':
                if (
                    uploads.navPerUnitTab.actions.canImportNav === 'maker' ||
                    uploads.navPerUnitTab.actions.canImportNav === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Import NAV`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-nav-import-btn',
                    };
                    return _tempPrimary;
                }
                break;
            case 'distribution':
                if (
                    uploads.distributionTab.actions.canImportDistribution === 'maker' ||
                    uploads.distributionTab.actions.canImportDistribution === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Import Distribution`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-distribution-import-btn',
                    };
                    return _tempPrimary;
                }

                break;
            case 'documents':
                if (
                    uploads.documentsTab.actions.canBulkUpload === 'maker' ||
                    uploads.documentsTab.actions.canBulkUpload === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Bulk Upload Documents`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-documents-import-btn',
                    };
                    return _tempPrimary;
                }
                break;
        }
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };
    useEffect(() => {
        initialRender.current = true;
        resultLimitRef.current = 10;
        setResultLimit(10);
        setCurrentPage(1);
        setSearchInput({ value: '', column: 'all' });
        setInitialFilterHookValues([]);
        setDropDownFilters([]);
        setTableFilterQuery([]);
        setSortData({ column: 'fileName', value: 'Ascending' });
        resetColumnFilters();
        //set primary and secondary button permissions for each tab
        if (tab !== undefined) {
            switch (tabs[tab].dashboardId) {
                case 'masterfund':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));
                    break;
                case 'navperunit':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));

                    break;
                case 'distribution':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));

                    break;
                case 'documents':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));

                    break;
                default:
                    setTabSecondaryButtons(checkTabSecondaryButtons(0));
                    setTabPrimaryButtons(checkTabPrimaryBtns(0));

                    break;
            }
        }

        return function cleanup() {
            tab !== undefined
                ? updateIsSeenDashboard(
                      'fundDocumentDashboard',
                      [selectedTabUploads(tabs[tab].dashboardId as string)],
                      idTokenHeader,
                  )
                : null;
        };
    }, [tab]);

    // call export list query
    const getExportList = async (tabName: string, query: DocumentNode) => {
        // loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        tab: tabName,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                        endpoint: 'funddocumentdashboard',
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.dashboardExportList;

            if (!error) {
                exportListUploadsDashboard(tabName, data.result.funddocumentdashboard);
                // loadingHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Generate Export List',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            // loadingHandler();
        }
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };

    // get tab index for dashboards
    const getTabIndex = (): number => {
        let _tabIndex = 0;
        if (tab !== undefined) _tabIndex = tabs.indexOf(tabs[tab]);
        return _tabIndex;
    };
    //fn to show fund data custom modal
    const handleShowConfirmation = (heading: string, subHeading: string) => {
        setModalHeading(heading);
        setModalSubHeading(subHeading);
        setShowFundDataModal(!showFundDataModal);
    };
    //fn to close fund data custom modal and update dashboard
    const handleCloseConfirmation = () => {
        setShowFundDataModal(!showFundDataModal);
        switch (tabs[tab].dashboardId) {
            case 'masterfund':
                getAllMasterFundData();
                break;
            case 'navperunit':
                getAllNAVPerUnitData();
                break;
            case 'distribution':
                getAllDistributionData();
                break;
            case 'documents':
                getAllDocumentsData();
                break;
        }
    };
    //fn to show  bulk documents custom modal
    const handleBulkDocumnetsConfirmation = (documentCount: number) => {
        setDocumnetCount(documentCount);
        setShowBulkDocModal(!showBulkDocModal);
    };
    //fn to close bulk documents custom modal and update dashboard
    const handleCloseBulkDocumentsConfirmation = () => {
        setShowBulkDocModal(!showBulkDocModal);
        switch (tabs[tab].dashboardId) {
            case 'documents':
                getAllDocumentsData();
                break;
        }
    };
    // set dashbaord tabs according to permissions
    if (uploads.masterFundTab.isAll === 'true') {
        const _masterFundTab = {
            name: 'master fund',
            table: (
                <MasterFund
                    allMasterFundData={masterFundData}
                    permissions={parsedPermission && uploads.masterFundTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleShowConfirmation}
                />
            ),
            testId: 'masterfund-tab',
            dashboardId: 'masterfund',
        };
        tabs.push(_masterFundTab);
    }
    if (uploads.navPerUnitTab.isAll === 'true') {
        const _navPerUnitTab = {
            name: 'NAV',
            table: (
                <NAVPerUnit
                    allNAVPerUnit={navPerUnitData}
                    permissions={parsedPermission && uploads.navPerUnitTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleShowConfirmation}
                />
            ),
            testId: 'nav-tab',
            dashboardId: 'navperunit',
        };
        tabs.push(_navPerUnitTab);
    }
    if (uploads.distributionTab.isAll === 'true') {
        const _distributionTab = {
            name: 'distribution',
            table: (
                <Distribution
                    allDistributionData={distributionData}
                    permissions={parsedPermission && uploads.distributionTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleShowConfirmation}
                />
            ),
            testId: 'distribution-tab',
            dashboardId: 'distribution',
        };
        tabs.push(_distributionTab);
    }
    if (uploads.documentsTab.isAll === 'true') {
        const _documentsTab = {
            name: 'documents',
            table: (
                <Documents
                    allDocumentsData={documentsData}
                    permissions={parsedPermission && uploads.documentsTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleBulkDocumnetsConfirmation}
                />
            ),
            testId: 'documents-tab',
            dashboardId: 'documents',
        };
        tabs.push(_documentsTab);
    }

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleImportFile = () => {
        if (tab !== undefined) {
            history.push({
                pathname:
                    tabs[tab].dashboardId === 'documents'
                        ? Routes.financeImportBulkUploader
                        : Routes.financeImportFileUploader,
                state: {
                    uploadType: tabs[tab].name,
                    tabName: tabs[tab].dashboardId,
                    tabIndex: tabs.indexOf(tabs[tab]),
                },
            });
        }
    };
    const handleExportList = (tab: number) => {
        let _tabName = '';
        let _queryName: DocumentNode | null = null;
        switch (tabs[tab].dashboardId) {
            case 'masterfund':
                _tabName = 'masterfund';
                _queryName = fundsExportList;
                break;
            case 'navperunit':
                _tabName = 'navperunit';
                _queryName = fundsExportList;
                break;
            case 'distribution':
                _tabName = 'distribution';
                _queryName = fundsExportList;
                break;
            case 'documents':
                _tabName = 'documents';
                _queryName = bulkDocumentsExportList;
                break;
            default:
        }

        _queryName && getExportList(_tabName, _queryName);
    };
    const searchInputHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const tempValue = event.currentTarget as HTMLInputElement;
        setSearchInput({ ...searchInput, value: tempValue.value });
    };

    const handleFilterApply = () => {
        initialRender.current = false;
        setTableFilterQuery(filterValues);
        setExpand(false);
    };
    //reset searchFilterForm elements
    const handleFilterClose = () => {
        initialRender.current = true;
        setDropDownFilters([]);
        setInitialFilterHookValues([]);
        handleFilterDate([null, null]);
        setTableFilterQuery([]); // set initial upload dashboard filter values to query
        setExpand(false);
    };

    //fn to update the selected value for select elements
    const updateInputValue = (elemId: string): string => {
        if (elemId === 'lastUpdate') {
            const _selectedObj = filterValues.find((obj) => obj.column === 'lastUpdate' || obj.column === 'uploadedOn');

            return _selectedObj !== undefined ? _selectedObj.column : '';
        } else {
            const _selectedObj = filterValues.find((obj) => obj.column === elemId);

            return _selectedObj !== undefined ? _selectedObj.value : '';
        }
    };
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchColumnsUploads.masterFunds.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `uploads-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };
    // hook to listen to user input in search bar, sorting, pagination and filtering
    useMemo(() => {
        if (tab !== undefined) {
            switch (tabs[tab].dashboardId) {
                case 'masterfund':
                    getAllMasterFundData();
                    break;
                case 'navperunit':
                    getAllNAVPerUnitData();
                    break;
                case 'distribution':
                    getAllDistributionData();
                    break;
                case 'documents':
                    getAllDocumentsData();
                    break;
                default:
                    getAllMasterFundData();
                    break;
            }
        }
    }, [tableFilterQuery, searchInput, resultLimit, currentPage, sortData]);
    // console.log('Upload Dashboard render');
    return (
        <TabWrapper>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={LABEL.searchPlaceholderUploads}
                searchTitle="Search"
                dropDownOptions={searchOptions()}
                onSearch={searchInputHandler}
                primaryButton={tabPrimaryButtons && tabPrimaryButtons}
                handlePrimary={handleImportFile}
                handleSecondary={tab !== undefined ? () => handleExportList(tab) : () => handleExportList(0)}
                secondaryButton={tabSecondaryButtons && tabSecondaryButtons}
                searchInput={searchInput}
                testId="uploads"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
            >
                <SearchInner>
                    <SearchInnerTitle>Filter Product & Services by</SearchInnerTitle>
                    {dropDownFilters.map((row: IDropDownFilters[], rowIndex: number) => (
                        <FromRow key={rowIndex}>
                            {row.map((elem: IDropDownFilters) => {
                                if (elem.elementType === 'select') {
                                    return (
                                        <InputWrap>
                                            <SelectDropdown
                                                onChange={(value) =>
                                                    handleFilterChange(
                                                        value,
                                                        elem.label === 'Date' ? elem.label : elem.id,
                                                    )
                                                }
                                                options={elem.options}
                                                testId={`${elem.id}-dropdown-filter`}
                                                selectedValue={updateInputValue(elem.id)}
                                                placeHolder={elem && elem.placeHolder}
                                                label={elem.label}
                                            />
                                        </InputWrap>
                                    );
                                } else if (elem.elementType === 'dateRange') {
                                    return (
                                        <InputWrap style={{ lineHeight: '1rem' }}>
                                            <InputLabel>{elem.label}</InputLabel>

                                            <DatePicker
                                                setTargetDate={(date) => {
                                                    handleFilterDate(
                                                        date as [moment.Moment | null, moment.Moment | null],
                                                    );
                                                }}
                                                targetDate={dateRange}
                                                range={true}
                                                width="360"
                                                handleDate={handleDate}
                                                errorMessage={datePickerInvalidMessage}
                                                setErrorMessage={setDatePickerInvalidMessage}
                                                disable={
                                                    !filterValues.find(
                                                        (obj: IColumnValue) =>
                                                            obj.column === 'lastUpdate' || obj.column === 'uploadedOn',
                                                    )
                                                }
                                            />
                                        </InputWrap>
                                    );
                                }
                            })}
                        </FromRow>
                    ))}
                    <FromRow>
                        <ButtonContainer>
                            <CustomButton
                                onClick={handleFilterClose}
                                style={{ width: '16.67vw' }}
                                id="uploads-cancel-btn"
                            >
                                {LABEL.reset}
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                primary={true}
                                onClick={handleFilterApply}
                                style={{ width: '16.67vw', padding: '14px 0' }}
                                id="uploads-apply-btn"
                            >
                                {LABEL.apply}
                            </CustomButton>
                        </ButtonContainer>
                    </FromRow>
                </SearchInner>
            </SearchBar>
            <Dashboard
                tabs={tabs}
                selectedTab={tab !== undefined ? tab : 0}
                switchTabs={switchTabs}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setResultLimit={setResultLimit}
                page={currentPage}
                maxPage={maxPages}
                testId="uploads"
                resultLimit={resultLimit}
                setCurrentPage={setCurrentPage}
                disableResultLimit={disableResultLimit}
                orderCounter={[]}
            />
            {!showBulkDocModal ? (
                <Modal
                    modalActive={!showBulkDocModal}
                    setModalActive={() => setShowBulkDocModal(!showBulkDocModal)}
                    title="Bulk Upload Documents request approved"
                    primaryBtn={{
                        onClick: () => {
                            handleCloseBulkDocumentsConfirmation();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    icon="bulk-modal-processing"
                    contentAlignment="center"
                    testId="uploads-dasboard-bulk-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${documnetCount} fund documents has been updated.`}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {!showBulkDocModal ? (
                <Modal
                    modalActive={!showBulkDocModal}
                    setModalActive={() => setShowFundDataModal(!showFundDataModal)}
                    title={`Import ${modalHeading} request approved`}
                    primaryBtn={{
                        onClick: () => {
                            handleCloseConfirmation();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    icon="approved-transaction"
                    contentAlignment="center"
                    testId="uploads-dasboard-funddata-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${modalSubHeading} been updated and the maker will be notified.`}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </TabWrapper>
    );
};

export default UploadsDashboard;

//component styles

const TabWrapper = styled.div`
    position: relative;
`;
const SearchInner = styled.div`
    border-top: 1px solid #eaebee;
    padding: 1rem 0px;
`;
const SearchInnerTitle = styled.h2`
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;
const FromRow = styled.div`
    display: flex;
    margin: 1rem 0px;
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;

const InputLabel = styled.label`
    display: block;
    font-weight: 700;
    font-size: 0.8rem;
    color: #333333;
    margin-bottom: 0.4rem;
`;
const ButtonContainer = styled.div`
    display: flex;
    margin: 0 auto;
    padding: 25px 0px;
`;
