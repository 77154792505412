/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { useComponentLoader } from '../../../../../customHooks';
import { Banner, ComponentLoader, PreviousPage } from '../../../../../components/molecules';
import { CustomButton, CustomSpacer, DatePicker, FlexedDiv, Modal } from '../../../../../components';
import {
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    LABEL,
    SYSTEM_ADMIN,
    TextDarkBlack,
    TextDarkGrey5,
    TextDarkGrey6,
} from '../../../../../constants';
import { getIdInfo } from '../../../../../_graphql/queries/systemadmin/getIdInfo';
import { createSettingMutation } from '../../../../../_graphql/mutations/systemAdmin/systemSettings/createSettings';
import { approveRejectSettingsMutation } from '../../../../../_graphql/mutations/systemAdmin/systemSettings/approveRejectSettings';

import * as ROUTES from '../../../../../routes';
import styled from 'styled-components';
import moment from 'moment';
import AuthContext from '../../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../../context/ErrorHandling/ErrorHandlingContext';
import CustomCheckbox from '../../../../../components/atoms/Checkbox';
import TopBanner from '../TopBanner';
import CustomRadioButton from '../../../../DashboardAmla/Helpers/CustomRadioButton';

export const initialSetting: ISettingInfoProps = {
    approver: '',
    author: '',
    effectiveDate: null,
    unit: '',
    value: 'false',
    remarks: '',
    currentStatus: '',
    requestStatus: '',
    updatedOn: '',
};
/**
 *@description - edit EPF setting / Approve EPF setting request
 *
 * @return {*}  {JSX.Element}
 */
const EditEPF = (): JSX.Element => {
    //Permission Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { systemSettings } = parsedPermission.hq.permission;
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const history = useHistory();
    const location = useLocation();

    const routeProps: IEditEPFRouteProps = location.state as IEditEPFRouteProps;
    const { loading, loadingHandler } = useComponentLoader();

    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [isRadioButtonUpdated, setIsRadioButtonUpdated] = useState<boolean>(false);
    const [effectiveDate, setEffectiveDate] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    const [settingInfo, setSettingInfo] = useState<ISettingInfoProps>({ ...initialSetting });
    const [sendAgent, setSendAgent] = useState<boolean>(true);
    const [sendOfficeUser, setSendOfficeUser] = useState<boolean>(true);
    const [disableView, setDisableView] = useState<boolean>(false);

    /* Modal states */
    const [showApproveConfirmationModal, setShowApproveConfirmationModal] = useState<boolean>(false);
    const [showApproveFeedbackModal, setShowApproveFeedbackModal] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);

    const goBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        history.push(ROUTES.systemConfiguration);
    };

    /*** @approveRejectSettingsMutation @APICALL  */

    const callApproveReject = async (key: string) => {
        try {
            handleApproveConfirmModal();
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(approveRejectSettingsMutation, {
                    input: {
                        tab: routeProps.tab,
                        action: key,
                        requestId: routeProps.requestId,
                    },
                }),
                idTokenHeader,
            );
            const { result, error } = await response.data.approveRejectSettings.data;
            if (error != null) throw error;
            if (result.status === true) {
                loadingHandler();
                handleApproveFeedbackModal();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot approve/reject system setting',
                testId: 'edit-system-setting-error-modal',
            });
        }
    };
    /*** @getIdInformation @APICall */
    const getIdInformationCall = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(getIdInfo, {
                    input: {
                        id: routeProps.settingsId,
                        viewType: 'systemSettings',
                        requestId: routeProps.requestId || '0',
                    },
                }),
                idTokenHeader,
            );
            const { result, error } = await response.data.getIdInformation.data;
            if (error != null) throw error;
            const settingInfo = JSON.parse(result.info);
            setSettingInfo(settingInfo);
            // if (settingInfo.requestStatus.toLowerCase() === 'pending') {
            //     setIsSelected(settingInfo.value === 'true' ? true : false); //checker flow
            // } else {
            //     setIsSelected(settingInfo.requestStatus === 'Enabled' ? true : false); // maker flow
            // }
            setIsSelected(settingInfo.value.toString() === 'true' ? true : false);
            setSendOfficeUser(Boolean(settingInfo.sendOfficeUser));
            setSendAgent(Boolean(settingInfo.sendAgent));
            setEffectiveDate([moment(parseInt(settingInfo.effectiveDate)), null]);
            if (systemSettings.epfTab.actions.canChangeSettings === 'maker') {
                setDisableView(
                    settingInfo.requestStatus && settingInfo.requestStatus.toLowerCase() === 'pending' ? true : false,
                );
            } else {
                setDisableView(true);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch System setting data',
                testId: 'edit-utmc-setting-error-modal',
            });
        }
    };
    /*** @createSettings @APICall */
    const createSettingsCall = async () => {
        loadingHandler();
        try {
            handleSubmitRequestConfirmModal();
            const response: any = await API.graphql(
                graphqlOperation(createSettingMutation, {
                    input: {
                        settings: {
                            isMultiUtmc: {
                                effectiveDate: effectiveDate[0] ? effectiveDate[0].valueOf().toString() : null,
                                value: isSelected,
                            },
                        },
                        sendAgent: sendAgent,
                        sendOfficeUser: sendOfficeUser,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.createSettings;
            if (error != null) {
                throw error;
            }
            const { result } = data;
            if (result.status === true) {
                loadingHandler();
                handleFeedbackModal();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot create system setting',
                testId: 'edit-system-setting-error-modal',
            });
        }
    };

    /* function to redirect to reject remark page */
    const handleReject = async () => {
        history.push(ROUTES.systemSettingRemark, {
            ...routeProps,
            isSelected,
            requestEffectiveDate: moment(effectiveDate[0]).format('DD/MM/YYYY'),
        });
    };
    /* function to handle effective setting date
     */
    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const dates = [...date] as [moment.Moment | null, moment.Moment | null];
        setEffectiveDate(dates);
        handleDate(date);
    };

    // Fn to toggle approve feedback modal
    const handleApproveFeedbackModal = () => {
        setShowApproveFeedbackModal((prevState) => !prevState);
    };
    /* Function to toggle setting feedback modal */
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
    };
    /* Function to handle approve confirmation modal */
    const handleApproveConfirmModal = () => {
        setShowApproveConfirmationModal((prev) => !prev);
    };
    /* Function to handle submit setting request modal */
    const handleSubmitRequestConfirmModal = () => {
        setShowConfirmationModal((prev) => !prev);
    };

    /* Function to handle setting disable/enable radio button */
    const handleRadioButton = () => {
        setIsSelected(!isSelected);
        if (settingInfo.value.toString() == isSelected.toString()) {
            setSendAgent(true);
            setSendOfficeUser(true);
        }
        setIsRadioButtonUpdated((prv) => {
            if (!prv) return true;
            else return false;
        });
        const _validFrom = effectiveDate[0]
            ? effectiveDate[0]?.valueOf() >= moment().add(1, 'day').startOf('day').valueOf()
            : false;
        if (!_validFrom)
            setDatePickerInvalidMessage(
                `Selected Date cannot be less than ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
            );
    };

    /* Function to handle email notification checkbox */
    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSendAgent(e.target.checked);
    };
    /* Function to handle inbox notification checkbox */
    const handleInboxCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSendOfficeUser(e.target.checked);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const start: moment.Moment = date?.[0] as moment.Moment;

        if (start) {
            start.set({ h: 0, m: 0, s: 0 });
            const update = [...date] as [moment.Moment, moment.Moment];
            const _timeFrom = update[0] && update[0].valueOf();
            const _validFrom = _timeFrom >= moment().add(1, 'day').startOf('day').valueOf();
            if (_validFrom) {
                datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            } else {
                _validFrom === false
                    ? setDatePickerInvalidMessage(
                          `Selected Date cannot be less than ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                      )
                    : null;
            }
        }
    };

    useEffect(() => {
        getIdInformationCall();
    }, []);

    return (
        <Fragment>
            <PreviousPage
                title={
                    systemSettings.epfTab.reviewApproval.canApproveChange === 'checker' &&
                    settingInfo.requestStatus &&
                    settingInfo.requestStatus.toLowerCase() === 'pending'
                        ? SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_SETTING_REVIEW
                        : SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_SETTING
                }
                subTitle={
                    systemSettings.epfTab.reviewApproval.canApproveChange === 'checker' &&
                    settingInfo.requestStatus &&
                    settingInfo.requestStatus.toLowerCase() === 'pending'
                        ? SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_DESCRIPTION_APPROVE_REJECT
                        : SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_DESCRIPTION
                }
                secondSubtitle={
                    systemSettings.epfTab.reviewApproval.canApproveChange === 'checker' &&
                    settingInfo.requestStatus &&
                    settingInfo.requestStatus.toLowerCase() === 'pending'
                        ? SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_SECOND_DESCRIPTION_APPROVE_REJECT
                        : SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_SECOND_DESCRIPTION
                }
                handleBackFunction={goBack}
            />
            {settingInfo.remarks ? (
                <TopBanner
                    icon="terminate"
                    title={`The request to ${
                        settingInfo.value.toString() === 'true' ? 'disable' : 'enable'
                    } multiple UTMC by ${settingInfo.author} on ${moment(settingInfo.createdOn).format(
                        'DD/MM/YYYY',
                    )} was rejected by ${settingInfo.approver} on ${moment(settingInfo.updatedOn).format(
                        'DD/MM/YYYY',
                    )} due to:`}
                    subTitle={'Remarks:'}
                    text={settingInfo.remarks}
                ></TopBanner>
            ) : null}
            <DisableView value={disableView}>
                <DivWrapper>
                    <TextDarkBlack weight="700" size="1.12rem">
                        {SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_REQUEST_SETTING}
                    </TextDarkBlack>
                    <FlexedDiv direction="row" style={{ marginTop: '1.5rem', alignItems: 'baseline' }}>
                        <CustomRadioButton selected={isSelected} onClick={handleRadioButton} index={0} />
                        <FlexedDiv direction="column" style={{ marginLeft: '0.875rem' }}>
                            <TextDarkBlack>{SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_MULTIPLE_UTMC}</TextDarkBlack>
                            <TextDarkGrey5 size="12px">
                                {`${!isSelected ? 'Enable' : 'Disable'}`} this to request switching from
                                <strong>{` ${isSelected ? 'Multiple to Single' : 'Single to Multiple'} UTMC `}</strong>
                                per application for all EPF funding option investment.
                            </TextDarkGrey5>
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ marginTop: '1.5rem' }}>
                        <TextDarkGrey6 size="12px" weight="700">
                            {SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_EFFECTIVE_DATE}
                        </TextDarkGrey6>
                        <InputWrap>
                            <DatePicker
                                setTargetDate={handleTargetDate}
                                targetDate={effectiveDate}
                                range={false}
                                primaryBorderColor="#002043"
                                handleDate={handleDate}
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                                disable={!isRadioButtonUpdated}
                                disableWeekends={true}
                                disableHolidays={true}
                                disableOldDates={true}
                            />
                        </InputWrap>
                    </FlexedDiv>
                </DivWrapper>
                <Border />
                {isRadioButtonUpdated ||
                (settingInfo.requestStatus && settingInfo.requestStatus.toLowerCase() === 'pending') ||
                systemSettings.epfTab.reviewApproval.canApproveChange === 'checker' ? (
                    <DivWrapper>
                        <CustomCheckbox
                            checked={sendAgent}
                            label={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_SEND_EMAIL_INBOX}
                            handleCheckbox={handleCheckbox}
                            textTransForm="initial"
                        ></CustomCheckbox>
                        <br />
                        <CustomCheckbox
                            checked={sendOfficeUser}
                            label={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_SEND_INBOX}
                            handleCheckbox={handleInboxCheckbox}
                            textTransForm="initial"
                        ></CustomCheckbox>
                    </DivWrapper>
                ) : null}
                <DivWrapper>
                    {systemSettings.epfTab.actions.canChangeSettings === 'maker' &&
                    settingInfo.requestStatus != 'PENDING' ? (
                        <FlexedDiv style={{ marginTop: '3.25rem' }}>
                            <CustomButton
                                primary={false}
                                onClick={handleCancel}
                                style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '240px' }}
                            >
                                <Fs15BoldPrimaryBlack>{'Cancel'}</Fs15BoldPrimaryBlack>
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                disabled={
                                    !effectiveDate[0] || datePickerInvalidMessage.length || !isRadioButtonUpdated
                                        ? // ||!(sendAgent || sendOfficeUser)
                                          true
                                        : false
                                }
                                primary={true}
                                onClick={handleSubmitRequestConfirmModal}
                                style={{ width: '240px' }}
                            >
                                <Fs15BoldNeutralWhite>
                                    {SYSTEM_ADMIN.SYSTEM_SETTING.BUTTON_SUBMIT_REQUEST}
                                </Fs15BoldNeutralWhite>
                            </CustomButton>
                        </FlexedDiv>
                    ) : null}
                </DivWrapper>
            </DisableView>
            {systemSettings.epfTab.reviewApproval.canApproveChange === 'checker' &&
            settingInfo.requestStatus &&
            settingInfo.requestStatus.toLowerCase() === 'pending' ? (
                <Banner
                    toggle={true}
                    title={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_BANNER_REQUEST_PENDING_APPROVAL}
                    isRequestBanner={false}
                    description={`${LABEL.requestedBy}`}
                    descriptionEmText={settingInfo.author}
                    data-testid="snackBar"
                    testId="extendtargetdate-banner"
                    primaryButtonObject={{
                        handlePrimaryBtn: handleApproveConfirmModal,
                        label: LABEL.approve,
                        width: '260px',
                        icon: 'check',
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: handleReject,
                        label: LABEL.reject,
                        width: '260px',
                        icon: 'terminate',
                        color: '#E84C3D',
                    }}
                />
            ) : null}
            {showConfirmationModal ? (
                <Modal
                    title={`Proceed to submit request to '${
                        isSelected ? 'Enable' : 'Disable'
                    } Multiple UTMC effective ${moment(effectiveDate[0]).format('DD/MM/YYYY')}'`}
                    modalActive={showConfirmationModal}
                    setModalActive={handleSubmitRequestConfirmModal}
                    primaryBtn={{
                        onClick: () => createSettingsCall(),
                        label: LABEL.yes,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: handleSubmitRequestConfirmModal,
                        label: LABEL.noNo,
                        width: '212px',
                        size: 'large',
                    }}
                    testId="epf-confirmation"
                >
                    {SYSTEM_ADMIN.SYSTEM_SETTING.YES_OR_NO_TEXT}
                </Modal>
            ) : null}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={
                        <span>
                            {isSelected ? 'Enable' : 'Disable'} Multiple UTMC <br /> request submitted
                        </span>
                    }
                    icon={'settings-request'}
                    primaryBtn={{
                        onClick: () => {
                            handleFeedbackModal();
                            history.push(ROUTES.systemConfiguration);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="epf-feedback"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Subtitle>
                            This request has been submitted and is pending for review
                            <br /> by checker.
                        </Subtitle>
                    </FlexedDiv>
                </Modal>
            ) : null}
            {showApproveConfirmationModal ? (
                <Modal
                    title={`Approve request to ${isSelected ? 'enable' : 'disable'} multiple UTMC effective ${moment(
                        effectiveDate[0],
                    ).format('DD/MM/YYYY')}?`}
                    modalActive={showApproveConfirmationModal}
                    setModalActive={handleApproveConfirmModal}
                    primaryBtn={{
                        onClick: () => callApproveReject('approve'),
                        label: LABEL.yes,
                        primary: true,
                        width: '212px',
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: handleApproveConfirmModal,
                        label: LABEL.noNo,
                        width: '212px',
                        size: 'large',
                    }}
                    testId="epf-confirmation"
                >
                    {SYSTEM_ADMIN.SYSTEM_SETTING.YES_OR_NO_APPROVE_TEXT}
                </Modal>
            ) : null}

            {/* Confirmation Modal for feedback */}
            {showApproveFeedbackModal ? (
                <Modal
                    modalActive={showApproveFeedbackModal}
                    setModalActive={handleApproveFeedbackModal}
                    title={
                        <span>
                            {isSelected ? 'Enable' : 'Disable'} multiple UTMC <br /> request approved
                        </span>
                    }
                    icon={'settings-request-success'}
                    primaryBtn={{
                        onClick: () => {
                            handleApproveFeedbackModal();
                            history.push(ROUTES.systemConfiguration);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="epf-feedback"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Subtitle>
                            {`${isSelected ? 'Enable' : 'Disable'}`} multiple UTMC request has been approved,
                            <br /> maker will be notified.
                        </Subtitle>
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

type DisableViewProps = {
    value?: boolean;
};

const DivWrapper = styled.div`
    margin-left: 3rem;
`;
const DisableView = styled.div<DisableViewProps>`
    opacity: ${(props) => (props.value === true ? 0.6 : undefined)};
    pointer-events: ${(props) => (props.value === true ? 'none' : undefined)};
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;
const Border = styled.div`
    border: 1px solid #c6cbd4;
    margin: 2rem -1.5rem;
`;
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
export default EditEPF;
