/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, ReactText, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import FlexSpacer, {
    FlexedDiv,
    PreviousPage,
    TextArea,
    CustomSpacer,
    ComponentLoader,
    SelectDropdown,
} from '../../../../components';
import { CustomInput } from '../../../../components/atoms/Input';
import { LABEL, TextPureBlack, Fs12BoldPrimaryBlack, Fs12RegPrimaryBlack } from '../../../../constants';
import { TextBold18, TextBold12 } from '../../../../constants/textStyles';
import { IValidation } from '../../../../interfaces/IValidation';
import { sh32, sh24, sh64, sh784 } from '../../../../styles';
import { Validate } from '../../../../validation/validate';
import * as ROUTES from '../../../../routes';
import MultiSelectV2 from '../../../../components/molecules/MultiSelect';
import { editUserGroupStatus } from '../../UserGroup/EditUserGroup/userGroupStatus';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { RejectedBanner } from './CreateUserActivity';
import { useComponentLoader } from '../../../../customHooks';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface addUserGroupProps {
    name: string;
    status: string;
    description: string;
    userRoles: ReactText[];
    branchOffices: ReactText[];
}
interface IEditUserGroupProps {
    mode: string;
    requestId?: string;
    userGroupId: string;
    id?: string;
    maker?: boolean;
}
const initialUserGroup: addUserGroupProps = {
    name: '',
    status: 'Pending',
    description: '',
    userRoles: [''],
    branchOffices: [''],
};

const UserGroupActivity = (): JSX.Element => {
    const [error, setError] = useState<string>('');
    const [userGroupData, setUserGroupData] = useState({ ...initialUserGroup });
    const [operationName, setOperationName] = useState<string>('');
    const [rejectedBy, setRejectedBy] = useState<string>('');
    const [rejectRemarks, setRejectRemarks] = useState<string>('');
    const [operationStatus, setOperationStatus] = useState<string>('Successful');

    const { state: PageProps } = useLocation<IEditUserGroupProps>();
    const { branchOptions, roleOptions } = useContext(SystemAdminContext);

    // loader
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const getSelectedList = (list: Array<ReactText>, id: number) => {
        switch (id) {
            case 1: {
                setUserGroupData({ ...userGroupData, userRoles: list });
                break;
            }
            case 2: {
                setUserGroupData({ ...userGroupData, branchOffices: list });
                break;
            }
            default: {
                break;
            }
        }
    };

    const fetchUserGroupData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: PageProps.id,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = response.data.getLogData;
            if (error !== null) {
                throw error;
            }
            setOperationName(data.result.data.operationName);
            setOperationStatus(data.result.data.operationStatus);
            if (data.result.data.status.toLowerCase() === 'rejected') {
                setRejectedBy(data.result.data.name);
                setRejectRemarks(data.result.data.remark);
            }
            if (data.result.data.status.toLowerCase() === 'failed') {
                const failedUserGroupResponse = JSON.parse(data.result.data.data);
                const temp = initialUserGroup;
                if (
                    data.result.data.operationName.toLowerCase().includes('delete') ||
                    data.result.data.operationName.toLowerCase().includes('suspend') ||
                    data.result.data.operationName.toLowerCase().includes('reactivate')
                ) {
                    temp.name = failedUserGroupResponse.name;
                    temp.status =
                        failedUserGroupResponse.currentStatus.toLowerCase() === 'pending'
                            ? '-'
                            : failedUserGroupResponse.currentStatus;
                    if (failedUserGroupResponse.branch_ids !== undefined)
                        temp.branchOffices = failedUserGroupResponse.branch_ids.ids.map((id: number | string) =>
                            typeof id === 'string' ? id : id.toString(),
                        );
                    if (failedUserGroupResponse.role_ids !== undefined)
                        temp.userRoles = failedUserGroupResponse.role_ids.ids.map((id: number | string) =>
                            typeof id === 'string' ? id : id.toString(),
                        );
                    temp.description =
                        failedUserGroupResponse.description !== undefined ? failedUserGroupResponse.description : '';
                    setUserGroupData({ ...userGroupData, ...temp });
                } else {
                    temp.name = failedUserGroupResponse.groupName;
                    temp.status = '-';
                    if (failedUserGroupResponse.branch !== undefined)
                        temp.branchOffices = failedUserGroupResponse.branch.map((id: number | string) =>
                            typeof id === 'string' ? id : id.toString(),
                        );
                    if (failedUserGroupResponse.roles !== undefined)
                        temp.userRoles = failedUserGroupResponse.roles.map((id: number | string) =>
                            typeof id === 'string' ? id : id.toString(),
                        );
                    temp.description =
                        failedUserGroupResponse.description !== undefined ? failedUserGroupResponse.description : '';
                    setUserGroupData({ ...userGroupData, ...temp });
                }
            } else {
                const userGroupResponse = JSON.parse(data.result.data.data);
                const temp = initialUserGroup;
                temp.name = userGroupResponse.name;
                temp.status =
                    data.result.data.status.toLowerCase() === 'rejected'
                        ? userGroupResponse.currentStatus.toLowerCase() === 'pending'
                            ? '-'
                            : userGroupResponse.currentStatus
                        : userGroupResponse.requestedStatus === null
                        ? '-'
                        : userGroupResponse.requestedStatus;
                temp.branchOffices = userGroupResponse.branch_ids.ids.map((id: number | string) =>
                    typeof id === 'string' ? id : id.toString(),
                );
                temp.userRoles = userGroupResponse.role_ids.ids.map((id: number | string) =>
                    typeof id === 'string' ? id : id.toString(),
                );
                temp.description = userGroupResponse.description;
                setUserGroupData({ ...userGroupData, ...temp });
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();

            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Fetch User Group Data',
                testId: 'activitylogs-usergroup-error-modal',
            });
            handleErrorHandler();
        }
    };

    useEffect(() => {
        fetchUserGroupData();
    }, []);

    const updateUserGroupData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        const att = e.currentTarget.getAttribute('validation');
        let valmsg = e.currentTarget.getAttribute('validationMessage');
        if (valmsg === null) {
            valmsg = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;

        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valmsg);
            if (err.code !== 'NoError') {
                setError(err.message);
                return;
            } else {
                setError('');
            }
        }
        setUserGroupData({ ...userGroupData, [key]: value });
    };

    const updateUserGroupDataSelect = (value: string, field: string) => {
        setUserGroupData({ ...userGroupData, [field]: value });
    };

    const handleTitle = (operationName: string) => {
        switch (operationName) {
            case 'Add New Group':
                if (PageProps.maker === true) {
                    return 'Review New Group Request';
                } else {
                    return operationName;
                }
            case 'Update User Group':
                if (PageProps.maker === true) {
                    return 'Review Edit User Group Request';
                } else {
                    return operationName;
                }
            default:
                return operationName;
        }
    };

    return (
        <Container>
            {/* the page that displayed when the request is rejected */}
            <Fragment>
                <PreviousPage
                    backIcon={true}
                    title={handleTitle(operationName)}
                    handleBack={ROUTES.activityLogs}
                    suffixHelper={operationStatus}
                />
                <div style={{ paddingLeft: sh32 }}>
                    {rejectedBy !== '' && rejectRemarks !== '' ? (
                        <RejectedBanner direction="column">
                            <TextPureBlack>{`${LABEL.thisRequestRejectedBy} ${rejectedBy}.`}</TextPureBlack>
                            <CustomSpacer space={'.5rem'} />
                            <Fs12BoldPrimaryBlack>{LABEL.rejectReason}</Fs12BoldPrimaryBlack>
                            <Fs12RegPrimaryBlack>{rejectRemarks}</Fs12RegPrimaryBlack>
                        </RejectedBanner>
                    ) : null}
                </div>
                <div style={{ paddingLeft: sh32, width: sh784 }}>
                    <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userGroupDetails}</TextBold18>
                    <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                        <CustomInput
                            disabled={true}
                            label={LABEL.groupName}
                            name="name"
                            style={{ width: '25vw', maxWidth: '720px' }}
                            value={userGroupData.name}
                            inputStyle={{ fontSize: '1rem', fontWeight: 700 }}
                            onChange={(e) => updateUserGroupData(e)}
                            onLostFocus={(e) => updateUserGroupData(e)}
                            validation={JSON.stringify({ type: 'alphanumeric' } as IValidation)}
                            validationMessage="Name"
                            errorText={error}
                        />
                        <FlexSpacer />
                        <FlexedDiv direction="column">
                            <TextBold12 style={{ color: '#333333' }}>{LABEL.status}</TextBold12>

                            <SelectWrapper>
                                <SelectDropdown
                                    onChange={(value) => updateUserGroupDataSelect(value.toString(), 'status')}
                                    options={
                                        PageProps.mode !== 'edit'
                                            ? [
                                                  {
                                                      label: userGroupData.status as string,
                                                      value: userGroupData.status as string,
                                                  },
                                              ]
                                            : editUserGroupStatus.map((item) => {
                                                  return { label: item.label, value: item.value };
                                              })
                                    }
                                    testId={`status-dropdown`}
                                    selectedValue={userGroupData.status as string}
                                    isDisabled={true}
                                    fontWeight={700}
                                />
                            </SelectWrapper>
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv>
                        <TextArea
                            disabled={true}
                            name="description"
                            label={LABEL.groupDescription}
                            value={userGroupData.description}
                            handleInput={(e) => updateUserGroupData(e)}
                            maxLength={255}
                            style={{ width: '360px' }}
                        />
                    </FlexedDiv>
                    <TextBold18 style={{ marginBottom: sh24, marginTop: sh64 }}> {LABEL.grantAccessLevel}</TextBold18>
                    <FlexedDiv style={{ marginBottom: sh24 }}>
                        <MultiSelectV2
                            expandable={true}
                            label={LABEL.assignUserRole}
                            options={roleOptions}
                            placeHolder={LABEL.selectRoles}
                            checkedState={userGroupData.userRoles}
                            handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                            selectId={1}
                            noOverlay={false}
                            disabled={true}
                        />
                        <CustomSpacer horizontal={true} space={'2rem'} />
                        <MultiSelectV2
                            expandable={true}
                            label={LABEL.branchOffice}
                            options={branchOptions}
                            placeHolder={LABEL.selectOneOrMultipleBranch}
                            checkedState={userGroupData.branchOffices}
                            handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                            selectId={2}
                            noOverlay={false}
                            disabled={true}
                        />
                    </FlexedDiv>
                </div>
                <CustomSpacer space={'2rem'} />
            </Fragment>
            {loading ? <ComponentLoader /> : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const SelectWrapper = styled.div`
    width: 25vw;
    max-width: 720px;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    margin-top: -7px;
`;
export default UserGroupActivity;
