/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import { useContext, useEffect, useRef } from 'react';
import { TableGroupingTemplates } from '../../../components';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import { getGroupingFromData, getGroupStyling } from '../../../utils';

import { IHistoryTab } from '../../../utils/permissionTypes';
import { downloadPPAQuery } from '../../../_graphql/queries/branchCE/transaction/downloadPPA/downloadPPAQuery';
import { printTransactionReportPdf } from '../../../_graphql/queries/branchCE/transaction/printTransactionReport/printTransactionReport';
import { getGroupedTrx } from '../decoupleGroupedTrx';
import { useHistoryTableColumns } from './useHistoryTableColumn';

interface HistoryProps {
    filters: ISort_FilterInput;
    historyTab: IHistoryTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    lastUpdatedDropdown: string;
    loadingHandler: () => void;
    searchInput: string;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHistoryHandler = ({
    idTokenHeader,
    loadingHandler,
    setErrorHandler,
    setErrorMessage,
    filters,
    setFilters,
    lastUpdatedDropdown,
    setLastUpdatedDropdown,
    historyTab,
}: HistoryProps) => {
    const { history, orderTypePills, historyCr } = useContext(DashboardBranchContext);
    const historyGroupedTable = useRef<(IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]>([]);
    const handlePrintTransactionReport = (item: IColumnItemAccordion) => {
        printReport(item.rawData.transactionRef);
    };
    const handleDownloadPPA = (item: IColumnItemAccordion) => {
        downloadPPA(item.rawData.transactionRef);
    };
    const printReport = async (ref: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(printTransactionReportPdf, {
                    input: {
                        transactionId: ref,
                        tab: 'history',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionPdf;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionPdf.data;

            window.open(result.path, '_blank');
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorHandler();
            setErrorMessage({
                title: 'Cannot Download Transaction Report',
                message: _error.message,
                errorCode: _error.errorCode,
                testId: 'branch-error-modal',
            });
        }
    };
    const downloadPPA = async (ref: string) => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(downloadPPAQuery, {
                    input: {
                        transactionRef: ref,
                        tab: 'history',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.ppaDownload;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.ppaDownload.data;

            loadingHandler();
            window.open(result.link, '_blank');
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            setErrorHandler();
            setErrorMessage({
                title: 'Cannot Download PPA Account Opening Form',
                message: _error.message,
                errorCode: _error.errorCode,
                testId: 'branch-error-modal',
            });
        }
    };

    const { columns } = useHistoryTableColumns({
        filters,
        historyTab,
        lastUpdatedDropdown,
        setFilters,
        setLastUpdatedDropdown,
        changeRequestColumn:
            orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0,
    });
    useEffect(() => {
        const _history = !window.location.pathname.includes('all-branches')
            ? getGroupedTrx(history.current, 'orderNo')
            : historyCr.current;
        let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
        data = getGroupingFromData(_history, TableGroupingTemplates, getGroupStyling);
        historyGroupedTable.current = [...data];
    }, [history.current, historyCr.current]);
    return {
        handlePrintTransactionReport,
        handleDownloadPPA,
        columnsHistory: columns,
        historyGrouped: historyGroupedTable,
        historyNonGrouped: history,
    };
};
