import React, { Fragment, ChangeEvent } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
interface ISearchInputProps {
    placeHolder: string;
    handleSearch: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
    testId?: string;
}
const SearchInput = ({ placeHolder, handleSearch, testId }: ISearchInputProps): JSX.Element => {
    return (
        <Fragment>
            <Div>
                <Span>
                    <IcoMoon name="search" size="1.5rem" />
                </Span>
                <StyledInput
                    maxLength={255}
                    placeholder={placeHolder}
                    onChange={handleSearch}
                    id={`${testId}-search-input`}
                    data-testid={`${testId}-search-input`}
                />
            </Div>
        </Fragment>
    );
};
export default SearchInput;
const Div = styled.div`
    display: flex;
    position: relative;
    border-radius: 24px;
    height: 3rem;
    border: 1px solid #c9c9cd;
    width: 25vw;
    align-items: center;
`;
const Span = styled.span`
    position: absolute;
    align-self: center;
    left: 1rem;
    z-index: 100;
    font-size: 1rem;
`;
const StyledInput = styled.input`
    margin-left: 2.75rem;
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
`;
