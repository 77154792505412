/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';

import { CustomSpacer, FlexedDiv } from '../../../../components';
import Toggle from '../../../../components/atoms/Toggle';
import CustomCheckbox from '../../../../components/atoms/Checkbox';
import { SYSTEM_ADMIN, ISectionCollapsible } from '../../../../constants';
import { IAdviserSection, ICustomerExpSection, ILoginRoleSection } from './BranchPrivileges/branchRolesInitialData';
import {
    IActivityLogsSection,
    IBranchOfficeSection,
    ICustomerExperienceSection,
    IEDDCasesSection,
    IFinanceCasesSection,
    IFinanceDashboardSection,
    IFundUploads,
    IOperationCases,
    IOperationDashboard,
    IProductSettings,
    ISystemSettingsSection,
    IUserManagementSection,
} from './HqPrivileges/hqRolesInitialData';

export type ISectionTypes =
    | ILoginRoleSection
    | ICustomerExpSection
    | IAdviserSection
    | ICustomerExperienceSection
    | IUserManagementSection
    | IActivityLogsSection
    | ISystemSettingsSection
    | IBranchOfficeSection
    | IEDDCasesSection
    | IFinanceDashboardSection
    | IFinanceCasesSection
    | IProductSettings
    | IFundUploads
    | IOperationDashboard
    | IOperationCases;

export type ISetSectionTypes = (data: ISectionTypes) => void;

declare interface CollapsibleContentProps {
    checked: boolean;
    data: ISectionTypes;
    edited?: boolean;
    index?: number;
    isDisabled?: boolean;
    item?: ISectionCollapsible[];
    offsetArray?: number[];
    setData: (data: ISectionTypes) => void;
    setEdited?: (toggle: boolean) => void;
    user?: string;
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
}

export const CollapsibleContent: FunctionComponent<CollapsibleContentProps> = ({
    checked,
    data,
    // edited,
    isDisabled,
    item,
    setData,
    user,
    offsetArray,
}: // setDropdownValue,
// setEdited,
// dropdownValue,
CollapsibleContentProps) => {
    const actions = 'actions';
    // const permissionLevel = 'permissionLevel';
    const itemStyle: React.CSSProperties = {
        paddingLeft: '4rem',
        paddingRight: '14.5rem',
    };
    return (
        <Fragment>
            {item!.map((fixedData, index) => {
                const handleToggle = () => {
                    let temp = { ...data };
                    let newData: any = {};
                    if (checked === true) {
                        const collapsibleData = hasKey(data, fixedData.keyName) ? data[fixedData.keyName] : undefined;
                        const isAllKey = Object.keys(collapsibleData!).filter((keyName) => keyName === 'isAll');
                        const isAll = isAllKey[0];
                        let toggle: boolean;
                        if (hasKey(collapsibleData, isAll)) {
                            toggle = !collapsibleData![isAll] as boolean;
                            Object.keys(temp)
                                .filter((filterKeyName) => filterKeyName === fixedData.keyName)
                                .map((keyName) => {
                                    if (hasKey(temp, keyName)) {
                                        const tempData = temp[keyName];
                                        if (hasKey(tempData, actions)) {
                                            const tempArray: boolean[] = tempData[actions];
                                            tempArray.fill(toggle);
                                            newData = { isAll: toggle, [actions]: tempArray };
                                        }
                                        if (hasKey(collapsibleData, reviewApproval)) {
                                            const tempArray: boolean[] = tempData[reviewApproval];
                                            tempArray.fill(toggle);
                                            newData = {
                                                ...newData,
                                                [reviewApproval]: tempArray,
                                            };
                                        }
                                    }
                                });
                            temp = {
                                ...temp,
                                [fixedData.keyName]: newData,
                                permissionLevel:
                                    temp.permissionLevel.includes('Edited') || temp.permissionLevel === 'General Access'
                                        ? temp.permissionLevel
                                        : `${temp.permissionLevel} (Edited)`,
                            };
                            setData(temp);
                        }
                    }
                };
                const collapsibleData = hasKey(data, fixedData.keyName) ? data[fixedData.keyName] : undefined;

                const isAll = 'isAll';
                const collapsibleDataSwitch: boolean = hasKey(collapsibleData, isAll) ? collapsibleData![isAll] : false;

                const collapsibleDataCheckBox = hasKey(collapsibleData, actions)
                    ? collapsibleData![actions]
                    : undefined;

                const reviewApproval = 'reviewApproval';

                const collapsibleDataReview = hasKey(collapsibleData, reviewApproval)
                    ? collapsibleData![reviewApproval]
                    : undefined;

                const reviewTitle =
                    fixedData.reviewApprovalTitle !== undefined
                        ? fixedData.reviewApprovalTitle
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL;

                return (
                    <Fragment key={index}>
                        <FlexedDiv
                            direction="row"
                            style={{ backgroundColor: '#FBFBFB', ...itemStyle, height: '3.5rem', alignItems: 'center' }}
                        >
                            <Toggle
                                disabled={isDisabled}
                                selected={collapsibleDataSwitch}
                                onClick={handleToggle}
                                id="add-role"
                            />
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <HeadingText>{fixedData.title}</HeadingText>
                        </FlexedDiv>
                        <FlexedDiv
                            direction="column"
                            style={{
                                ...itemStyle,
                                paddingLeft: '6.5rem',
                                justifyContent: 'center',
                                overflowY: 'hidden',
                            }}
                        >
                            {fixedData.actions.length > 0 ? (
                                <Fragment>
                                    <CustomSpacer space={'1rem'} />
                                    <FlexedDiv>
                                        <SectionHeadingText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ALLOW}</SectionHeadingText>
                                        <CustomSpacer horizontal={true} space={'1rem'} />
                                        <SectionHeadingText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ACTIONS}</SectionHeadingText>
                                    </FlexedDiv>
                                    <CustomSpacer space={'0.5rem'} />
                                </Fragment>
                            ) : null}
                            {fixedData.actions.map((action: string, actionIndex: number) => {
                                const handleCheckbox = () => {
                                    let temp = { ...data };
                                    let newData: any = {};
                                    if (collapsibleDataSwitch && actionIndex !== 0) {
                                        if (hasKey(temp, fixedData.keyName)) {
                                            const tempData = temp[fixedData.keyName];
                                            newData = tempData;
                                            if (hasKey(tempData, actions)) {
                                                const tempArray: boolean[] = tempData[actions];
                                                tempArray[actionIndex] = !tempArray[actionIndex];
                                                if (
                                                    user === 'autoAuthorizer' &&
                                                    hasKey(tempData, reviewApproval) &&
                                                    offsetArray !== undefined
                                                ) {
                                                    const tempArrayReview: boolean[] = tempData[reviewApproval];
                                                    const reviewIndex = actionIndex - offsetArray[index];
                                                    if (reviewIndex >= 0) {
                                                        tempArrayReview[reviewIndex] = !tempArrayReview[reviewIndex];
                                                        newData = { ...newData, reviewApproval: tempArrayReview };
                                                    }
                                                }
                                                newData = {
                                                    ...newData,
                                                    actions: tempArray,
                                                };
                                            }
                                        }
                                        temp = {
                                            ...temp,
                                            [fixedData.keyName]: newData,
                                            permissionLevel:
                                                temp.permissionLevel.includes('Edited') ||
                                                temp.permissionLevel === 'General Access'
                                                    ? temp.permissionLevel
                                                    : `${temp.permissionLevel} (Edited)`,
                                        };
                                        setData(temp);
                                    }
                                };

                                const disabled = actionIndex === 0 ? true : isDisabled;

                                return (
                                    <Fragment key={actionIndex}>
                                        {actionIndex !== 0 ? <CustomSpacer space={'.5rem'} /> : null}
                                        <FlexedDiv direction="row" alignItems="center">
                                            <CustomCheckbox
                                                checked={collapsibleDataCheckBox![actionIndex]}
                                                disabled={disabled}
                                                handleCheckbox={handleCheckbox}
                                            />
                                            <CustomSpacer horizontal={true} space={'1.5rem'} />
                                            <ContentHeadingText checked={collapsibleDataCheckBox![actionIndex]}>
                                                {action}
                                            </ContentHeadingText>
                                        </FlexedDiv>
                                    </Fragment>
                                );
                            })}
                            {fixedData.reviewApproval !== undefined ? (
                                <Fragment>
                                    <CustomSpacer space={'1rem'} />
                                    <FlexedDiv>
                                        <SectionHeadingText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ALLOW}</SectionHeadingText>
                                        <CustomSpacer horizontal={true} space={'1rem'} />
                                        <SectionHeadingText>{reviewTitle}</SectionHeadingText>
                                    </FlexedDiv>
                                    <CustomSpacer space={'0.5rem'} />
                                    {fixedData.reviewApproval.map((reviewApproval, reviewIndex) => {
                                        const handleReviewCheckBox = () => {
                                            let temp = { ...data };
                                            let newData: any = {};
                                            if (collapsibleDataSwitch && reviewIndex !== 0) {
                                                if (hasKey(temp, fixedData.keyName)) {
                                                    const tempData = temp[fixedData.keyName];
                                                    const reviewApproval = 'reviewApproval';
                                                    if (hasKey(tempData, reviewApproval)) {
                                                        const tempArray: boolean[] = tempData[reviewApproval];
                                                        tempArray[reviewIndex] = !tempArray[reviewIndex];
                                                        newData = tempData;
                                                        if (
                                                            user === 'autoAuthorizer' &&
                                                            hasKey(tempData, actions) &&
                                                            offsetArray !== undefined
                                                        ) {
                                                            const tempArray: boolean[] = tempData[actions];
                                                            const actionIndex = reviewIndex + offsetArray[index];
                                                            if (reviewIndex >= 0) {
                                                                tempArray[actionIndex] = !tempArray[actionIndex];
                                                                newData = { ...newData, actions: tempArray };
                                                            }
                                                        }
                                                        newData = {
                                                            ...newData,
                                                            [reviewApproval]: tempArray,
                                                        };
                                                    }
                                                }
                                                temp = {
                                                    ...temp,
                                                    [fixedData.keyName]: newData,
                                                    permissionLevel:
                                                        temp.permissionLevel.includes('Edited') ||
                                                        temp.permissionLevel === 'General Access'
                                                            ? temp.permissionLevel
                                                            : `${temp.permissionLevel} (Edited)`,
                                                };
                                            }
                                            setData(temp);
                                        };
                                        return (
                                            <Fragment key={reviewIndex}>
                                                {reviewIndex !== 0 ? <CustomSpacer space={'.5rem'} /> : null}
                                                <FlexedDiv direction="row" alignItems="center">
                                                    <CustomCheckbox
                                                        checked={
                                                            collapsibleDataReview !== undefined
                                                                ? collapsibleDataReview![reviewIndex]
                                                                : false
                                                        }
                                                        disabled={isDisabled}
                                                        handleCheckbox={handleReviewCheckBox}
                                                    />
                                                    <CustomSpacer horizontal={true} space={'1.5rem'} />
                                                    <ContentHeadingText
                                                        checked={
                                                            collapsibleDataReview !== undefined
                                                                ? collapsibleDataReview![reviewIndex]
                                                                : false
                                                        }
                                                    >
                                                        {reviewApproval}
                                                    </ContentHeadingText>
                                                </FlexedDiv>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            ) : null}
                            <CustomSpacer space={'1.5rem'} />
                        </FlexedDiv>
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

const HeadingText = styled.div`
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #333333;
`;

const SectionHeadingText = styled.div`
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    color: #a3a3a3;
`;

const ContentHeadingText = styled.div<IOnPropStyles>`
    font-size: 0.75rem;
    font-weight: ${(props) => (props.checked ? 700 : 400)};
    line-height: 1rem;
    width: 25rem;
    color: #333333;
`;
