/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { signOutFn } from '../../utils/authServices';
import WebSocketContext from '../../context/WebSocketContext';
import moment from 'moment';
interface IWebsocketRouteContainerProps {
    children?: React.ReactNode;
}
export const WebsocketRouteContainer: React.FC = ({ children }: IWebsocketRouteContainerProps) => {
    const { ws, setPath, setUrl, setIsLoggedOut } = useContext(WebSocketContext);
    const socketEvent = () => {
        if (ws !== undefined) {
            ws.onopen = () => {
                console.log('connected to websocket', moment().toString());
                handlePing();
            };
            ws.onmessage = (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);

                    if (data.errorCode === 'BO104') {
                        signOutFn();
                        setIsLoggedOut(true);
                        ws.close();
                    }
                }
            };
            ws.addEventListener('error', () => {
                // console.log('Websocket error->', event);
            });
            ws.onclose = () => {
                if (sessionStorage.getItem('url') !== null) {
                    if (!setIsLoggedOut) handleRefresh();
                } else {
                    setUrl('');
                    setPath('');
                }
            };
        }
    };
    const handleRefresh = () => {
        const url = sessionStorage.getItem('url') as string;
        const path = sessionStorage.getItem('path') as string;

        setUrl(url !== null ? url : '');
        setPath(path !== null ? path : '');
    };

    const handlePing = () => {
        if (ws !== undefined) {
            let intervalId: NodeJS.Timeout;
            return new Promise((resolve) => {
                const check = () => {
                    if (ws.readyState !== ws?.OPEN) {
                        clearInterval(intervalId);
                        resolve(null);
                    } else {
                        ws.send('PING');
                    }
                };
                check();
                intervalId = setInterval(check, 10000);
            });
        }
    };

    ws !== undefined ? socketEvent() : handleRefresh();

    return <>{children}</>;
};

export default WebsocketRouteContainer;
