/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import styled, { css } from 'styled-components';

import { CustomSpacer, Banner, FlexedDiv, TextArea, ComponentLoader, Modal, DatePicker } from '../../../components';
import { LABEL, TextDarkGrey6 } from '../../../constants';
import { IcoMoonSelection } from '../../../icons';

import { ICeDashboard } from '../../../utils/permissionTypes';
import { dueDateExtensionMutation } from '../../../_graphql/mutations/hq/dueDateExtension/dueDateExtension';
import { ceCheckerViewRequest } from '../../../_graphql/queries/branchCE/checkerViewRequest/checkerViewRequest';
import { useComponentLoader } from '../../../customHooks';
import { RejectRemarks } from '../../../templates';
import { useContext } from 'react';
import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

export const dateFormat = 'DD/MM/YYYY';
type IApprovalObjectDuedateRequest = {
    eventType: string;
    name: string;
    remarks: string;
    status: string;
};
const initialApprovalObject: IApprovalObjectDuedateRequest = {
    eventType: 'dueDateExtension',
    name: '',
    remarks: '',
    status: '',
};
interface DisabledProps {
    disabled: boolean;
}
const modalMessages = {
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};
export const CEExtendDueDateRequest: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    const [targetDate, setTargetDate] = useState<IExtendTargetDate>({ date: [null, null], remarks: '' });
    const [requestedBy, setRequestedBy] = useState<string>('');
    const [approvalObject, setApprovalObject] = useState<IApprovalObjectDuedateRequest>(initialApprovalObject);
    const location = useLocation();
    const locationState: any = location.state;
    const tableData = locationState.item;

    const [showModal, setShowModal] = useState<boolean>(false);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { loading, loadingHandler } = useComponentLoader();
    const history = useHistory();
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // get permission object from session storage
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const ceDashboard: ICeDashboard =
        parsedPermission.hq.grant === 'hq' ? parsedPermission.hq.permission.ceDashboard : undefined;
    const dueDateTab = ceDashboard !== undefined ? ceDashboard.dueDateTab : undefined;
    const handleRemarks = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTargetDate({ ...targetDate, remarks: e.target.value });
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        setTargetDate({ ...targetDate, date: date });
    };
    const [rejectReq, setRejectReq] = useState<boolean>(false);

    // Fn to call dueDateExtensionMutation
    const handleApprove = async () => {
        const _targetDate = targetDate.date && moment(targetDate.date[0]).valueOf();
        initFeedBackModal();
        try {
            const response: any = await API.graphql(
                graphqlOperation(dueDateExtensionMutation, {
                    input: {
                        requestId: tableData.requestId,
                        action: 'Approve',
                        targetDate: _targetDate,
                    },
                }),
                idTokenHeader,
            );
            if (response.data.targetDateResponse.data !== null) {
                setShowModal(true);
            } else {
                throw response.data.targetDateResponse.error;
            }
        } catch (error) {
            handleErrorHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Approve Due Date eExtension',
                testId: 'notifications-ce-dueDate-extension-error-modal',
            });
        }
    };

    // Fn to call ceCheckerViewRequest query
    const getRequestInfo = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerViewRequest, {
                    input: {
                        requestId: tableData.requestId,
                    },
                }),
                idTokenHeader,
            );
            if (response.data.transactionRequest.error !== null) throw response.data.transactionRequest.error;
            const { data } = response.data.transactionRequest;
            if (data.result.approval !== null) {
                const tempObj = { ...approvalObject };
                const { approval } = data.result;
                tempObj.name = approval.name;
                tempObj.remarks = approval.remarks;
                tempObj.status = approval.status;
                setApprovalObject(tempObj);
            }
            const dateInt = parseInt(data.result.targetDate, 10);
            const targetDate: IExtendTargetDate = {
                date: [moment(dateInt), null],
                remarks: data.result.reasons[0].category,
            };
            setTargetDate(targetDate);
            setRequestedBy(data.result.requestorName);
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Request data',
                testId: 'notifications-ce-dueDate-extension-error-modal',
            });
        }
    };
    const handleBack = () => {
        history.goBack();
    };
    //Fn to handle feedback modal messages
    const initFeedBackModal = () => {
        modalMessages.feedback.heading = LABEL.dueDateExtensionApprovedTitle;

        modalMessages.feedback.content = (
            <span>
                {LABEL.dueDateExtensionApprovedSubtitle1}
                {targetDate.date && moment(targetDate.date[0]).format('DD/MM/YYYY')}
                {LABEL.dueDateExtensionApprovedSubtitle2}
            </span>
        );

        modalMessages.feedback.icon = 'targetdate-modal';
    };
    const handleReject = () => {
        setRejectReq(true);
    };
    useEffect(() => {
        if (locationState !== undefined) {
            getRequestInfo();
        }
    }, []);

    return rejectReq ? (
        <RejectRemarks locationState={locationState} />
    ) : (
        <Fragment>
            <Header>
                <Icon onClick={handleBack}>
                    <IcomoonReact icon="arrow-left" size="1.5rem" iconSet={IcoMoonSelection} />
                </Icon>
                <CustomSpacer horizontal={true} space={'1.25rem'} />
                <TextDarkGrey6 size="24px" weight="700">
                    {LABEL.extendDueDateRequest}
                </TextDarkGrey6>
            </Header>
            <CustomSpacer space={'3.556vh'} />
            <Container>
                {approvalObject.status.toLowerCase() !== 'pending' && approvalObject.status.length !== 0 ? (
                    <ApprovalBanner direction="column">
                        <Title>{`This request was ${approvalObject.status.toLowerCase()} by ${
                            approvalObject.name
                        }`}</Title>

                        <FlexedDiv
                            style={{ paddingTop: '0.5rem', width: '100%', whiteSpace: 'break-spaces' }}
                            justifyContent="space-between"
                        >
                            {approvalObject.remarks !== undefined &&
                            approvalObject.remarks !== null &&
                            approvalObject.remarks.length !== 0 &&
                            approvalObject.remarks !== '-'
                                ? `${LABEL.rejectedReason}: ${approvalObject.remarks}`
                                : ''}
                        </FlexedDiv>
                    </ApprovalBanner>
                ) : null}
                <DisabledView disabled={locationState.isDisabled}>
                    <InputWrapper>
                        <TextDarkGrey6 size="12px" weight="700">
                            {LABEL.newDueDate}
                        </TextDarkGrey6>
                        <DatePicker
                            targetDate={targetDate.date}
                            setTargetDate={handleDate}
                            iconSize="1.5rem"
                            primaryBorderColor="#CECECE"
                            backgroundColor="#F4F4F4"
                        />
                    </InputWrapper>
                    <CustomSpacer space={'2rem'} />
                    <InputWrapper>
                        <TextDarkGrey6 size="12px" weight="700">
                            {LABEL.remarks}
                        </TextDarkGrey6>
                        <TextArea
                            name="rejectRemark"
                            label={LABEL.remarks}
                            value={targetDate.remarks as string}
                            handleInput={handleRemarks}
                            disabled
                            maxLength={255}
                            style={{ width: '38.89vw' }}
                        />
                    </InputWrapper>
                </DisabledView>
            </Container>
            {dueDateTab !== undefined &&
            dueDateTab.reviewApproval.canApproveDueDateExtension &&
            approvalObject.name === '' ? (
                <Banner
                    toggle={true}
                    title={`Request Pending Approval`}
                    isRequestBanner
                    description={`${LABEL.requestedBy}`}
                    descriptionEmText={requestedBy}
                    data-testid="snackBar"
                    testId="ce-extenddue-banner"
                    primaryButtonObject={{
                        handlePrimaryBtn: handleApprove,
                        label: LABEL.approve,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: handleReject,
                        label: LABEL.reject,
                        width: '268px',
                        icon: 'terminate',
                        color: '#E84C3D',
                    }}
                />
            ) : null}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalMessages.feedback.heading}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.dashboard);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="ce-Extend-duedate-req-modal"
                    contentAlignment="center"
                    icon="targetdate-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalMessages.feedback.content}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Container = styled.div`
    padding-left: 2.5vw;
`;

const DisabledView = styled.div`
    ${(props: DisabledProps) =>
        props.disabled &&
        css`
            opacity: 0.8;
            pointer-events: none;
        `}
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    width: 360px;
    max-width: 360px;
`;
