/* eslint-disable @typescript-eslint/no-explicit-any */
import { Moment } from 'moment';
import React, { FC, ReactNode, useState, useContext, useEffect, ReactText } from 'react';
import EDDContext from './EDDContext';
import { EDDContextProps } from './EDDContext';
import { useComponentLoader } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';
import { eddDashboardQuery } from '../../_graphql/queries/edd/eddDashboard/eddDashboard';
import { eddModuleListQuery } from '../../_graphql/queries/edd/eddModuleList/eddMouleList';
import { EddReasonProps, IEddTriggerProps } from '../../pages/DashboardAmla/DashboardEDD/eddInitialData';
import { eddClientListQuery } from '../../_graphql/queries/edd/eddClientList/eddClientList';
import { eddClientAccountsQuery } from '../../_graphql/queries/edd/eddClientAccounts/eddClientAccounts';
import { IClientList, IClientAccounts } from '../../pages/DashboardAmla/DashboardEDD/dashboardEDDTypes';
import { getEddModuleQuery } from '../../_graphql/queries/edd/eddModuleList/eddModule';
import { buildJSON } from '../../pages/DashboardAmla/DashboardEDD/AddNewCase/QuestionTemplate/helper';
import { caseDetailsQuery } from '../../_graphql/queries/edd/caseDetails/caseDetails';
import { IEddCaseDetailsProps, IFilterEDDInvestorProps, IPill } from './EddTypes';
import { initialCaseDetails } from './Dummy';
import { useLocation } from 'react-router-dom';
import { caseStatusMutation } from '../../_graphql/mutations/edd/caseStatus/caseStatus';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import { ICheckBoxData } from '../../pages/DashboardAmla/DashboardEDD/EddTypes';
import { statusDropDownQuery } from '../../_graphql/queries/common/statusDropDown';
import AuthContext from '../AuthContext';
import { dateFilterBranch } from '../../pages/DashboardBranch/dashboardBranchTypes';
import moment from 'moment';
import { IMultiSelectLabel } from '../../components';

export interface EDDProviderProps {
    children: ReactNode;
}

type momentType = [moment.Moment | null, moment.Moment | null];

export interface IEddModuleDropDownProps {
    moduleId: string;
    name: string;
}
export interface IEddModuleListProps {
    module: Array<IEddModuleDropDownProps>;
}
export interface ICaseDetailsInputProps {
    caseId: string;
    responseId: string;
}
const initialFilterInputEdd: ISort_FilterInput = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];
const initialDateFilterEdd: dateFilterBranch = {
    dateSort: 'createdOn',
    range: '',
};
const initialSortInputEdd: ISort_FilterInput = [
    {
        column: 'createdOn',
        value: 'descending',
    },
];

export const EDDProvider: FC<EDDProviderProps> = ({ children }: EDDProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [page, setPage] = useState<number>(1);
    const [pageMax, setPageMax] = useState(1);
    const [pagesDropDown, setPagesDropDown] = useState<number[]>([]);
    const [totalResultCount, setTotalResultCount] = useState<number>(0);
    const [reasonValue, setReasonValue] = useState<string | undefined>();
    const [triggerValue, setTriggerValue] = useState<string | undefined>();
    const [targetDate, setTargetDate] = useState<Moment | null>(null);
    const [otherText, setOtherText] = useState('');
    const [eddCaseStatusGroup, setEddCaseStatusGroup] = useState<IMultiSelectLabel[]>([]);
    // Filter states
    const [filterInput, setFilterInput] = useState<ISort_FilterInput>(initialFilterInputEdd);
    const [dateFilter, setDateFilter] = useState<dateFilterBranch>(initialDateFilterEdd); //Data range + type filter
    const [tempFilters, setTempFilters] = useState<ISort_FilterInput>(initialFilterInputEdd); // Temp Filters
    const [statusFilter, setStatusFilter] = useState<ReactText[]>([]);
    const [tempTargetDate, setTempTarget] = useState<momentType>([null, null]);
    //sort states
    const [sortInput, setSortInput] = useState<ISort_FilterInput>(initialSortInputEdd);

    // search states
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [searchInvestorInput, setSearchInvestorInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [investorsPage, setInvestorsPage] = useState<number>(1);
    const [investorsPages, setInvestorsPages] = useState<number>(1);
    const [investorsPageMax, setInvestorsPageMax] = useState(1);
    const [investorsTotalResultCount, setInvestorsTotalResultCount] = useState<number>(0);
    const [investorsResultLimit, setInvestorsResultLimit] = useState<number>(12);

    const [clientsList, setClientsList] = useState<IClientList[]>([]);
    const [clientsAccounts, setClientsAccounts] = useState<IClientAccounts[]>([]);
    const [newCases, setNewCases] = useState<ITableData[]>([]);
    const [reroutedCases, setReroutedCases] = useState<ITableData[]>([]);
    const [closedCases, setClosedCases] = useState<ITableData[]>([]);
    const [eddReason, setEddReason] = useState<EddReasonProps>({ reasonId: '', category: '', subCategory: [] });
    const [eddTrigger, setEddTrigger] = useState<IEddTriggerProps>({ reasonId: '', category: '' });
    const [clientUtaId, setClientUtaId] = useState('');
    const [moduleList, setModuleList] = useState<IEddModuleDropDownProps[]>([{ moduleId: '', name: '' }]);
    const [eddReasonJ, setEddReasonJ] = useState<EddReasonProps>({ reasonId: '', category: '', subCategory: [] });
    const [eddTriggerJ, setEddTriggerJ] = useState<IEddTriggerProps>({ reasonId: '', category: '' });
    const [targetDateJ, setTargetDateJ] = useState<Moment | null | undefined>();
    const [reasonValueJ, setReasonValueJ] = useState<string | undefined>();
    const [triggerValueJ, setTriggerValueJ] = useState<string | undefined>();
    const [selectedQuestion, setSelectedQuestion] = useState<any[]>([]);
    const [otherTextJ, setOtherTextJ] = useState<string>('');
    const [moduleName, setModuleName] = useState<string>('');
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [tabsCount, setTabsCount] = useState<number[]>([]);

    const [caseDetails, setCaseDetails] = useState<IEddCaseDetailsProps>(initialCaseDetails);
    const [responseId, setResponseId] = React.useState<number | null>(null);
    const [unsatisfactoryRating, setUnsatisfactoryRating] = useState<Array<ICheckBoxData>>([]);
    const [amlaRemarks, setAmlaRemarks] = useState<any[]>([]);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [pills, setPills] = useState<IPill[]>([]);
    const [clientIdNum, setClientIdNum] = useState<string>('');
    const [clientAccountSearchInput, setClientAccountSearchInput] = useState<IFilterEDDInvestorProps>({
        column: 'all',
        value: '',
    });
    const [sortClientList, setSortClientList] = useState<IFilterEDDInvestorProps>({
        column: 'clientName',
        value: 'Descending',
    });
    const [isSelected, setIsSelected] = useState<boolean[]>([]);

    const { loadingHandler, loading } = useComponentLoader();
    const { state } = useLocation();
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    useEffect(() => {
        if (clientIdNum.length) {
            getClientAccounts();
        }
    }, [clientIdNum, clientAccountSearchInput, sortClientList]);

    /**@async query to fetch New tab data */
    const getEddDashboardNew = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddDashboardQuery, {
                    input: {
                        tab: 'new',
                        page: page,
                        resultLimit: resultLimit,
                        sort: sortInput,
                        search: searchInput,
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.eddDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.eddDashboard.data;
            setNewCases(result.cases);
            result.cases.length === 0 ? setDisableResultLimit(true) : setDisableResultLimit(false);
            setTabsCount([result.newCount, result.rerouteCount, result.closeCount]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPageMax(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'edd-error-modal',
            });
        }
    };

    const getEddDashboardRerouted = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddDashboardQuery, {
                    input: {
                        tab: 'reroute',
                        page: page,
                        resultLimit: resultLimit,
                        sort: sortInput,
                        search: searchInput,
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.eddDashboard;

            if (resultCheck.error !== null) throw resultCheck.error;

            const { result } = response.data.eddDashboard.data;

            setReroutedCases(result.cases);
            result.cases.length === 0 ? setDisableResultLimit(true) : setDisableResultLimit(false);
            setTabsCount([result.newCount, result.rerouteCount, result.closeCount]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPage(result.page);
            setPageMax(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'edd-error-modal',
            });
        }
    };

    const getEddDashboardHistory = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddDashboardQuery, {
                    input: {
                        tab: 'close',
                        page: page,
                        resultLimit: resultLimit,
                        sort: sortInput,
                        search: searchInput,
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.eddDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;

            const { result } = response.data.eddDashboard.data;

            setClosedCases(result.cases);
            result.cases.length === 0 ? setDisableResultLimit(true) : setDisableResultLimit(false);
            setTabsCount([result.newCount, result.rerouteCount, result.closeCount]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPageMax(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard data',
                testId: 'edd-error-modal',
            });
        }
    };

    /**@async query to fetch client list data */
    const getEDDClientList = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddClientListQuery, {
                    input: {
                        page: investorsPage,
                        resultLimit: investorsResultLimit,
                        search: {
                            column: searchInvestorInput.column,
                            value: searchInvestorInput.value,
                        },
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.eddClientList;
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.eddClientList.data;

            loadingHandler();
            setTotalResultCount(result.totalResultCount);
            setClientsList(result.clients);
            setPagesDropDown(Array.from({ length: parseInt(result.pages) }, (_, i) => i + 1));
            if (page > result.pages) {
                setInvestorsPage(1);
            }
            setInvestorsPageMax(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Client List data',
                testId: 'edd-client-list-error-modal',
            });
        }
    };

    /**@async query to fetch client account data */
    const getClientAccounts = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddClientAccountsQuery, {
                    input: {
                        clientIdNum: clientIdNum,
                        page: '1',
                        resultLimit: '100',
                        search: clientAccountSearchInput,
                        sort: sortClientList,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.eddClientAccounts;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.eddClientAccounts.data;
            setPills(result.accounts);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Client Accounts',
                testId: 'edd-client-accounts-error-modal',
            });
        }
    };
    const getDropdownData = async (tab?: string) => {
        const _input = tab === undefined ? { endpoint: 'edddashboard' } : { endpoint: 'edddashboard', tab: tab };
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(statusDropDownQuery, {
                    input: _input,
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.statusDropDown;
            if (error !== null) throw error;
            if (data) {
                const statusList = data.result.statusList;
                const newListArray: IMultiSelectLabel[] = [];
                for (let i = 0; i < statusList.length; i++) {
                    newListArray.push({
                        key: statusList[i],
                        value: statusList[i],
                        label: statusList[i],
                    });
                }
                setEddCaseStatusGroup(newListArray);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dropdown List',
                testId: 'edd-dropdown-error-modal',
            });
        }
    };

    const getEddModuleList = async (item: { reasonId: string }) => {
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(eddModuleListQuery, {
                    input: {
                        reasonId: parseInt(item.reasonId),
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.eddModuleList;
            if (error !== null) throw error;
            if (data) {
                sessionStorage.setItem('moduleName', data.result.module[0].name || '');
                await setModuleList(data.result.module);
                await setModuleName(data.result.module[0].moduleName);
                await runEddModule(data.result.module[0].moduleId);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Module List',
                testId: 'edd-module-error-modal',
            });
        }
    };
    const runEddModule = async (value: string) => {
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(getEddModuleQuery, {
                    input: {
                        moduleId: value,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.getEddModule;
            if (error !== null) throw error;
            if (data) {
                setSelectedQuestion(buildJSON(data.result));
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Module Data',
                testId: 'edd-module-error-modal',
            });
        }
    };
    const getCaseDetails = async (responseId: number | null) => {
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(caseDetailsQuery, {
                    input: {
                        caseId: sessionStorage.getItem('caseId'),
                        responseId: responseId ? responseId.toString() : null,
                        tab: state.tab,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.caseDetails;
            if (error !== null) throw error;
            if (data) {
                setCaseDetails(data.result);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Case details',
                testId: 'edd-case-details-error-modal',
            });
        }
    };
    const updateCaseStatus = async (action: string) => {
        let inputData;
        if (action === 'Satisfactory') {
            inputData = {
                action: 'approve',
                caseId: sessionStorage.getItem('caseId'),
            };
        } else {
            inputData = {
                action: 'reroute',
                caseId: sessionStorage.getItem('caseId'),
                amlaRemark: state.amlaRemarks.filter((n: any) => n),
                reason: unsatisfactoryRating.filter((n: any) => delete n.checked),
            };
        }
        // console.log(inputData, 'Case status inputData');
        try {
            loadingHandler();
            const response: any = await API.graphql(
                graphqlOperation(caseStatusMutation, {
                    input: inputData,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.caseStatus;
            if (error !== null) throw error;
            sessionStorage.removeItem('caseId');
            setAmlaRemarks([]);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            sessionStorage.removeItem('caseId');
            setAmlaRemarks([]);
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Update Case Status',
                testId: 'edd-update-status-error-modal',
            });
        }
    };

    const providerValue: EDDContextProps = {
        page,
        setPage,
        pageMax,
        tabsCount,
        setTabsCount,
        setPageMax,
        resultLimit,
        setResultLimit,
        reasonValue,
        setReasonValue,
        triggerValue,
        setTriggerValue,
        targetDate,
        setTargetDate,
        otherText,
        setOtherText,
        getDropdownData,
        getEddDashboardNew,
        moduleList,
        eddTrigger,
        setEddTrigger,
        eddReason,
        setEddReason,
        getEddModuleList,
        getEDDClientList,
        searchInput,
        setSearchInput,
        totalResultCount,
        setTotalResultCount,
        clientsList,
        setClientsAccounts,
        clientsAccounts,
        setClientsList,
        newCases,
        reroutedCases,
        setReroutedCases,
        setClosedCases,
        closedCases,
        runEddModule,
        selectedQuestion,
        setSelectedQuestion,
        setClientUtaId,
        clientUtaId,
        setModuleList,
        eddTriggerJ,
        setEddTriggerJ,
        eddReasonJ,
        setEddReasonJ,
        targetDateJ,
        setTargetDateJ,
        reasonValueJ,
        setReasonValueJ,
        triggerValueJ,
        setTriggerValueJ,
        otherTextJ,
        setOtherTextJ,
        moduleName,
        setModuleName,
        getCaseDetails,
        caseDetails,
        setCaseDetails,
        responseId,
        setResponseId,
        updateCaseStatus,
        unsatisfactoryRating,
        setUnsatisfactoryRating,
        amlaRemarks,
        setAmlaRemarks,
        showModal,
        setShowModal,
        searchInvestorInput,
        setSearchInvestorInput,
        investorsPage,
        setInvestorsPage,
        investorsPages,
        setInvestorsPages,
        investorsPageMax,
        setInvestorsPageMax,
        investorsTotalResultCount,
        setInvestorsTotalResultCount,
        investorsResultLimit,
        setInvestorsResultLimit,
        pagesDropDown,
        setPagesDropDown,
        getClientAccounts,
        setPills,
        pills,
        clientIdNum,
        setClientIdNum,
        setClientAccountSearchInput,
        clientAccountSearchInput,
        sortClientList,
        setSortClientList,
        loading,
        loadingHandler,
        getEddDashboardRerouted,
        getEddDashboardHistory,
        eddCaseStatusGroup,
        sortInput,
        setSortInput,
        dateFilter,
        filterInput,
        setDateFilter,
        setFilterInput,
        setTempFilters,
        tempFilters,
        setStatusFilter,
        statusFilter,
        setTempTarget,
        tempTargetDate,
        disableResultLimit,
        isSelected,
        setIsSelected,
    };
    return <EDDContext.Provider value={providerValue}>{children}</EDDContext.Provider>;
};

export default EDDProvider;
