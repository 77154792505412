/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { amlaEDDManagement } from '../../../../../routes';
import {
    CustomSpacer,
    FlexedDiv,
    Card,
    Banner,
    TextArea,
    CustomButton,
    SelectDropdown,
    Modal,
} from '../../../../../components';

import { EDD, errorHandlerInitial, Fs16SemiBoldSecPrimaryBlack, LABEL } from '../../../../../constants';
import { createCaseMutation } from '../../../../../_graphql/mutations/edd/createCase/createCase';
import { ROUTES } from '../../../..';
import { GenericInput } from '../../../Helpers';

import AuthContext from '../../../../../context/AuthContext/AuthContext';
import EDDContext from '../../../../../context/EDDContext/EDDContext';
import Toggle from '../../../../../components/atoms/Toggle';

declare interface InewQuestionProps {
    id: string;
    question: string;
}
declare interface ICasesProps {
    reasonId: string;
    triggerId: string;
    moduleId: string;
    addQuestion: string[];
    targetDate: string;
}
declare interface ISaveNewEddCaseProps {
    cases: ICasesProps[];
    utaId: string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EddReason = () => {
    const { moduleList, getEddModuleList, runEddModule, selectedQuestion, moduleName, setModuleName } =
        useContext(EDDContext);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [errorMessage, setErrorMessage] = useState<IErrorHandling>(errorHandlerInitial);
    const [errorHandling, setErrorHandling] = useState<boolean>(false);
    const handleErrorHandler = () => {
        setErrorHandling(!errorHandling);
    };
    const [bannerHeight, setBannerHeight] = useState<number>(0);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [newEddCaseData, setNewEddCaseData] = useState<InewQuestionProps[]>([]);
    const [showTextBox, setShowTextBox] = useState<boolean>(false);
    const [stateValue, setStateValue] = useState(false);
    const [additionalQuestion, setAdditionalQuestion] = useState<string>('');
    const [moduleId, setModuleId] = useState<string>();
    const history = useHistory();
    const location = useLocation();
    const state: any = location.state;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await getEddModuleList({
            reasonId: state.eddReasonId, //|| sessionStorage.getItem('reasonId') || '',
        });
    };

    const handleModuleId = async (key: any) => {
        const newId = moduleList.filter((item) => item.name === key);
        sessionStorage.setItem('moduleName', newId[0].name);
        setModuleId(newId[0].moduleId);
        setModuleName(newId[0].name);
        runEddModule(newId[0].moduleId);
        return;
    };

    const newCaseCreate = async () => {
        const questions = newEddCaseData.map((item) => {
            return item.question;
        });
        const cases = [];
        if (state.isJointAccount) {
            cases.push(
                {
                    reasonId: state.eddReasonId != '0' ? state.eddReasonId : undefined,
                    triggerId: state.eddTrigger ? state.eddTrigger.reasonId : undefined,
                    moduleId: moduleList[0].moduleId.toString(),
                    addQuestion: questions,
                    targetDate: state.targetDate,
                    remark: state.eddReasonId != '0' ? undefined : state.otherText,
                },
                {
                    reasonId: state.eddReasonIdJ != '0' ? state.eddReasonIdJ : undefined,
                    triggerId: state.eddTriggerJ ? state.eddTriggerJ.reasonId : undefined,
                    moduleId: moduleList[0].moduleId.toString(),
                    addQuestion: questions,
                    targetDate: state.targetDateJ ? state.targetDateJ : '',
                    remark: state.eddReasonIdJ != '0' ? undefined : state.otherTextJ,
                },
            );
        } else {
            cases.push({
                reasonId: state.eddReasonId != '0' ? state.eddReasonId : undefined,
                triggerId: state.eddTrigger ? state.eddTrigger.reasonId : undefined,
                moduleId: moduleList[0].moduleId.toString(),
                addQuestion: questions,
                targetDate: state.targetDate,
                remark: state.eddReasonId != '0' ? undefined : state.otherText,
            });
        }
        saveNewEddCase({ cases: cases, utaId: state.utaId });
        return;
    };
    const saveNewEddCase = async (_data: ISaveNewEddCaseProps) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(createCaseMutation, {
                    input: _data,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.createCase;
            if (!error) {
                setConfirmModal(true);
                setStateValue(true);
                sessionStorage.removeItem('moduleName');
            } else {
                throw error;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Create Case',
            });
            handleErrorHandler();
        }
    };
    const addQuestion = () => {
        setShowTextBox(!showTextBox);
        setAdditionalQuestion('');
    };
    const saveNewQuestion = (question: string, key: string) => {
        const value = question;
        setNewEddCaseData((prevState) => {
            prevState.push({ id: key, question: value });
            return prevState;
        });
        addQuestion();
    };
    const handleConfirm = () => {
        history.push(amlaEDDManagement);
    };
    const handleClick = () => {
        return;
    };
    const newQuestionInput = () => {
        return ['Add Remark', 'Add Document'].map((title, i) => {
            return (
                <Fragment key={i}>
                    <CustomSpacer space={'1rem'} />
                    <div key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Toggle selected={false} onClick={handleClick} id="question-toggle" />
                        <label style={{ margin: '-8px 0px 0px 13px' }}>{title}</label>
                    </div>
                </Fragment>
            );
        });
    };

    return (
        <Fragment>
            <div>
                <CustomSpacer horizontal={true} space={'2.778vw'} />

                <StyledContentContainer marginBottom={bannerHeight}>
                    <LabelBold>{EDD.LABEL_QUESTION_TEMPLATE}</LabelBold>
                    <SelectWrapper>
                        <SelectDropdown
                            onChange={(key) => handleModuleId(key)}
                            options={
                                moduleList &&
                                moduleList.map((item) => {
                                    return { label: item.name, value: item.name };
                                })
                            }
                            testId={`eddquestiontemplate-dropdown`}
                            selectedValue={EDD.DEFAULT_VALUE}
                            isDisabled={true}
                        />
                    </SelectWrapper>
                    <SpaceDivider marginBottom="2rem" />
                    <H2Title>{EDD.DEFAULT_VALUE}</H2Title>
                    <div>
                        <label>{moduleName}</label>
                        <SpaceDivider />
                        {selectedQuestion && selectedQuestion.length
                            ? selectedQuestion.map((item, i) => {
                                  if (i < selectedQuestion.length - 1) {
                                      return (
                                          <CardStyle key={i} id={`question-card-${i}`}>
                                              <Card
                                                  disabled={false}
                                                  key={i}
                                                  title={item?.title}
                                                  subTitle={item?.description}
                                                  customTitle={`Q ${i + 1}`}
                                                  slideMargin={0}
                                                  visibleSlides={1}
                                                  showCustomTitle={true}
                                                  titleSize={1.15}
                                                  backgroundColor="#E7EBF1"
                                              ></Card>
                                              <CardBodyStyle key={i}>
                                                  <SpaceDivider />
                                                  <GenericInput
                                                      item={item}
                                                      isConclusion={false}
                                                      additionalQuestion={additionalQuestion}
                                                  />
                                              </CardBodyStyle>
                                              <SpaceDivider />
                                          </CardStyle>
                                      );
                                  }
                              })
                            : 'No questions found'}
                        {newEddCaseData && newEddCaseData.length
                            ? newEddCaseData.map((item, i) => {
                                  return (
                                      <CardStyle key={i}>
                                          <Card
                                              disabled={false}
                                              key={i}
                                              title={item.question}
                                              customTitle={item.id}
                                              slideMargin={0}
                                              visibleSlides={3}
                                              showCustomTitle={true}
                                              titleSize={1}
                                              backgroundColor="#E7EBF1"
                                          ></Card>
                                          <CardBodyStyle key={i} opacity={0.6}>
                                              <SpaceDivider />
                                              {newEddCaseData.length ? newQuestionInput() : ''}
                                          </CardBodyStyle>
                                          <SpaceDivider />
                                      </CardStyle>
                                  );
                              })
                            : ''}
                        {showTextBox && (
                            <TextBoxWrapper>
                                <CardStyle>
                                    <Card
                                        customTitle={LABEL.eddAdditionalQuestion}
                                        isTitleBadge={true}
                                        showCustomTitle={true}
                                        titleSize={1}
                                        backgroundColor="#E7EBF1"
                                        letterSpacing={0.5}
                                    ></Card>
                                    <CardBodyStyle>
                                        <br />
                                        <TextArea
                                            name="additionalQuestion"
                                            label={`Question ${selectedQuestion.length + newEddCaseData.length}`}
                                            value={additionalQuestion}
                                            handleInput={(e) => setAdditionalQuestion(e.currentTarget.value)}
                                            maxLength={255}
                                            style={{ color: 'black', width: '672px' }}
                                            placeHolder={LABEL.eddPlaceHolder}
                                        />
                                        <CustomButton
                                            prefixIcon="trash"
                                            primary={false}
                                            secondary={true}
                                            onClick={addQuestion}
                                        >
                                            {EDD.BUTTON_DELETE}
                                        </CustomButton>
                                        &nbsp;&nbsp;&nbsp;
                                        <CustomButton
                                            prefixIcon="check"
                                            primary={true}
                                            secondary={false}
                                            disabled={additionalQuestion.length ? false : true}
                                            onClick={() =>
                                                saveNewQuestion(
                                                    additionalQuestion,
                                                    `Q ${
                                                        selectedQuestion &&
                                                        selectedQuestion.length + newEddCaseData.length
                                                    }`,
                                                )
                                            }
                                        >
                                            {EDD.BUTTON_SAVE_CLOSE}
                                        </CustomButton>
                                    </CardBodyStyle>
                                </CardStyle>
                            </TextBoxWrapper>
                        )}
                        {selectedQuestion && selectedQuestion.length ? (
                            <Fragment>
                                <StyledButton disabled={showTextBox ? true : false} onClick={addQuestion}>{`+ ${' '} ${
                                    EDD.BUTTON_ADDITIONAL_QUESTION
                                }`}</StyledButton>
                                <CardStyle>
                                    <Card
                                        disabled={false}
                                        title={selectedQuestion[selectedQuestion.length - 1].title}
                                        customTitle={`Conclusion`}
                                        slideMargin={0}
                                        isTitleBadge={true}
                                        visibleSlides={3}
                                        showCustomTitle={true}
                                        titleSize={1}
                                        backgroundColor="#E7EBF1"
                                    ></Card>
                                    <CardBodyStyle>
                                        <SpaceDivider />
                                        <GenericInput
                                            item={selectedQuestion[selectedQuestion.length - 1]}
                                            isConclusion={true}
                                            additionalQuestion={additionalQuestion}
                                        />
                                        &nbsp;
                                    </CardBodyStyle>
                                </CardStyle>
                            </Fragment>
                        ) : null}
                    </div>

                    <BannerWrapper>
                        <Banner
                            toggle={true}
                            maxWidth="1152"
                            setBannerHeight={setBannerHeight}
                            title={`${state.newCaseDefinition.profile.length} ${
                                state.newCaseDefinition.profile.length > 1
                                    ? EDD.LABEL_INVESTORS_SELECTED
                                    : EDD.LABEL_INVESTOR_SELECTED
                            }`}
                            description={
                                state.newCaseDefinition.profile.length > 1
                                    ? state.newCaseDefinition.profile.map(
                                          (item: any) => `${item.name} (${item.idNumber})`,
                                      )[0] +
                                      state.newCaseDefinition.profile.map(
                                          (item: any) => `\n${item.name} (${item.idNumber})`,
                                      )[1]
                                    : state.newCaseDefinition.profile.map(
                                          (item: any) => `${item.name} (${item.idNumber})`,
                                      )[0]
                            }
                            handleClose={() => console.log('handle close')}
                            primaryButtonObject={{ handlePrimaryBtn: () => newCaseCreate(), label: EDD.BUTTON_SUBMIT }}
                            secondaryButtonObject={{
                                handleSecondaryBtn: () => history.push(ROUTES.amlaEDDManagement),
                                label: EDD.BUTTON_CANCEL,
                            }}
                        />
                    </BannerWrapper>
                </StyledContentContainer>
            </div>

            {stateValue ? (
                <Modal
                    modalActive={stateValue}
                    setModalActive={setStateValue}
                    title={EDD.LABEL_EDD_CASE_TITLE}
                    icon="cancel-request-done"
                    primaryBtn={{
                        onClick: () => {
                            handleConfirm();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId={'create-new-case'}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Subtitle>{EDD.LABEL_EDD_CASE_SUB_TITLE}</Subtitle>
                    </FlexedDiv>
                </Modal>
            ) : null}
            {errorHandling && (
                <Modal
                    title={errorMessage.title}
                    modalActive={errorHandling}
                    setModalActive={setErrorHandling}
                    primaryBtn={{
                        onClick: () => {
                            setErrorHandling(false);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon={errorMessage.icon !== undefined ? errorMessage.icon : 'warning'}
                    contentAlignment="center"
                    testId="eddreason-error-modal"
                >
                    <FlexedDiv
                        key={`${errorMessage.testId}-error-list`}
                        direction="column"
                        style={{ textAlign: 'center' }}
                    >
                        {`${errorMessage.errorCode !== undefined ? errorMessage.errorCode : ''} ${
                            errorMessage.message
                        }`}
                    </FlexedDiv>
                    {errorMessage.errorList !== undefined
                        ? errorMessage.errorList.map((item: string, i) => {
                              return (
                                  <Fragment key={`${errorMessage.testId}-error-list`}>
                                      <CustomSpacer space={'0.5rem'} />
                                      <Fs16SemiBoldSecPrimaryBlack style={{ textAlign: 'center' }}>
                                          {item}
                                      </Fs16SemiBoldSecPrimaryBlack>
                                  </Fragment>
                              );
                          })
                        : null}
                </Modal>
            )}
        </Fragment>
    );
};

declare interface CardBodyStyleProps {
    opacity?: number;
}
declare interface IStyledButtonProps {
    disabled: boolean;
}
declare interface SpaceDividerProps {
    marginBottom?: string;
}
declare interface StyledInnerProps {
    marginBottom: number;
}
const LabelBold = styled.label`
    font-weight: 700;
    font-size: 0.8rem;
`;
const H2Title = styled.h2`
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 2rem;
`;
const SpaceDivider = styled.div<SpaceDividerProps>`
    margin-bottom: ${(props) => props.marginBottom || '16px'};
`;
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const CardStyle = styled.div`
    padding: 0px 0px 16px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(236, 236, 236);
    border-radius: 16px;
    margin: 16 px 0px;
    width: 100%;
    max-width: 960px;
    margin-bottom: 2rem;
`;
const CardBodyStyle = styled.div<CardBodyStyleProps>`
    color: #171717;
    opacity: ${(props) => props.opacity};
    margin: 0px 42px;
`;
const StyledContentContainer = styled.div<StyledInnerProps>`
    width: 100%;
    max-width: 1152px;
    padding: 3rem 5rem;
    background: rgb(251, 251, 251);
    border: 1px solid rgb(234, 235, 238);
    margin-top: 32px;
    border-radius: 24px;
    margin-bottom: ${(props) => props.marginBottom}px;
`;

const StyledButton = styled.button`
    display: flex;
    outline: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 32px;
    border: 1px dashed #002043;
    box-sizing: border-box;
    border-radius: 24px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    margin: 2rem 8px;
    ${(props: IStyledButtonProps) =>
        props.disabled &&
        css`
            opacity: 0.6;
        `};
`;
const BannerWrapper = styled.div`
    margin: 0 -5rem;
`;
const TextBoxWrapper = styled.div`
    margin: 14px 15px 0px 10px;
`;
const SelectWrapper = styled.div`
    margin-right: 1vw;
    width: 19.48vw;
    height: 3rem;
    opacity: 0.6;
`;
