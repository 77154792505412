export const getEditGroupModalMessage = (source: string, isRejectedConfirm: boolean): string => {
    let message = '';
    switch (source) {
        case 'createGroup':
            message = isRejectedConfirm
                ? 'The maker will be notified.'
                : ` has been created and the maker will be notified.`;
            break;
        case 'suspendGroup':
            message = isRejectedConfirm
                ? 'The maker will be notified.'
                : ` has been suspended and maker will be notified`;
            break;
        case 'enableGroup':
            message = isRejectedConfirm
                ? 'The maker will be notified.'
                : `has been reactivated and maker will be notified`;
            break;
        case 'deleteGroup':
            message = isRejectedConfirm ? 'The maker will be notified.' : `has been deleted and maker will be notified`;
            break;
        default:
            message = isRejectedConfirm
                ? 'The maker will be notified.'
                : ` has been updated and the maker will be notified.`;
    }
    return message;
};
export const pageTitle = (source: string): string => {
    switch (source) {
        case 'createGroup':
            return 'Review Add New User Group Request';
        case 'suspendGroup':
            return 'Review Suspend User Group Request';
        case 'enableGroup':
            return 'Review Reactivate User Group Request';
        case 'deleteGroup':
            return 'Review Delete User Group Request';
        case 'editGroup':
            return 'Review Edit User Group Request';
        case 'createUser':
            return 'Review Add New User Request';
        case 'editUser':
            return 'Review Edit User Request';
        case 'enableUser':
            return 'Review Reactivate User Request';
        case 'suspendUser':
            return 'Review Suspend User Request';
        case 'terminateUser':
            return 'Review Terminate User Request';
        default:
            return 'Edit User Group';
    }
};
