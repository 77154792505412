/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
    Fragment,
    useEffect,
    useState,
    FunctionComponent,
    ReactText,
    ChangeEvent,
    useContext,
    useMemo,
} from 'react';
import {
    FlexedDiv,
    SearchBar,
    Dashboard,
    CustomSpacer,
    CustomButton,
    ComponentLoader,
    DatePicker,
    SelectDropdown,
} from '../../../components';
import { LABEL, SYSTEM_ADMIN, TextPureBlack, Fs12BoldPrimaryBlack } from '../../../constants';

import { Reports } from './Reports';
import { API, graphqlOperation } from 'aws-amplify';
import { IColumnValue } from '../../Advisers';
import { UserActivity } from './UserActivity/UserActivity';
import { SearchOptionsActivityLogs } from './searchOptionsAcivityLogs';
import { ActivityLogsExportList } from '../../../_graphql/queries/systemadmin/activityLogsExportList';
import { SystemAdminExport } from '../../../_graphql/queries/systemadmin/exportReport/systemAdminExport';
import { exportList } from '../../../utils/exportListBranchDashboard';
import { useDebounce } from '../../../customHooks';
import { IActivityLogs } from '../../../utils/permissionTypes';

import styled from 'styled-components';
import moment from 'moment';
import AuthContext from '../../../context/AuthContext';
import SystemAdminContext from '../../../context/SystemAdminContext/SAContext';
import ActivityLogsContext from '../../../context/SystemAdminContext/activityLogs';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import MultiSelect from '../../../components/molecules/MultiSelect/MultiSelect';
import { clearContextOnNavigation } from '../../../utils';
import { useHistory } from 'react-router-dom';

export declare interface IDropdownWithId {
    id: string;
    value: string;
}

const initialActivityFilter = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];
const statusOptions: Array<ISelectOption> = [
    { value: '', label: LABEL.all },
    { value: 'successful', label: LABEL.successful },
    { value: 'failed', label: LABEL.failed },
];
/**
 * Activity Logs Dashboard for System Admin
 * @category Pages
 * @component
 * @namespace ActivityLogsDashboard
 * @returns Activity logs dashboard component
 */

export const ActivityLogsDashboard: FunctionComponent = (): JSX.Element => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const activityLogs: IActivityLogs = parsedPermission.hq.permission.activityLogs;
    const { userActivityTab, reportsTab } = activityLogs;
    const { rolesList, eventTypeList, getDropdown } = useContext(SystemAdminContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const {
        tab,
        resultLimit,
        currentPage,
        maxPages,
        logCounter,
        searchInput,
        filters,
        sortData,
        loading,
        setTab,
        setResultLimit,
        setCurrentPage,
        setSearchInput,
        setFilters,
        setSortData,
        getActivityLogData,
        loadingHandler,
        disableResultLimit,
    } = useContext(ActivityLogsContext);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const history = useHistory();

    const [expand, setExpand] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(true);
    const [roles, setRoles] = useState<ReactText[]>([]);
    const [eventTypes, setEventTypes] = useState<ReactText[]>([]);
    const [status, setStatus] = useState<string>('');
    const [tempFilters, setTempFilters] = useState<IColumnValue[]>(initialActivityFilter);

    const [targetDateRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>(''); // datepicker error messages state

    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const handleReset = () => {
        setStatus('');
        setTargetRange([null, null]);
        setRoles([]);
        setEventTypes([]);
        setTempFilters(initialActivityFilter);
    };

    const handleApply = () => {
        setExpand(false);
        setFilters(tempFilters);
    };

    const handleSearchActions = (action: IActionButton) => {
        switch (action.title) {
            case 'Export Report':
                return getExportReport();
            case 'Export List':
                return getExportList();
            default:
                break;
        }
        // getExportList();
    };

    const switchTabs = (index: number) => {
        setTab(index);
    };

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const update = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetRange(update);
        const temp = [...tempFilters];
        temp[0] = {
            column: tempFilters[0].column,
            value: `${moment(update[0]).valueOf()}~${moment(update[1]).valueOf()}`,
        };

        setTempFilters(temp);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            const obj: ISearchInput = {
                column: tempFilters[0].column,
                value: range,
            };
            const tmp = [...tempFilters];
            tmp[0] = obj;
            setTempFilters(tmp);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };
    const handleRoles = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item) => item.column !== 'roles');
        data.map((item) => {
            newFilter.push({ column: 'roles', value: item as string });
        });
        setRoles(data);
        setTempFilters(newFilter);
    };

    const handleEventTypes = (data: ReactText[]) => {
        const temp = [...tempFilters];
        const newFilter = temp.filter((item) => item.column !== 'eventType');
        data.map((item) => {
            newFilter.push({ column: 'eventType', value: item as string });
        });
        setEventTypes(data);
        setTempFilters(newFilter);
    };

    const handleStatus = (text: string | number) => {
        setStatus(text.toString());
        const temp = [...tempFilters];
        temp[1] = { column: 'status', value: text.toString() };
        setTempFilters(temp);
    };

    const getExportReport = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(SystemAdminExport, {
                    input: {
                        toExport: 'exportLogReport', // userlist, useractivitylist, agentlist, exportreport,exportLogReport
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.systemAdminExport.data;
            if (resultCheck.error !== undefined) throw resultCheck.error;
            const { result } = response.data.systemAdminExport.data;

            if (result.link !== null) {
                location.href = result.link;
                // window.open(result.path, '_blank');
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Audit Report',
                testId: 'activitylogs-error-modal',
            });
            handleErrorHandler();
        }
    };

    const getExportList = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsExportList, {
                    input: {
                        endpoint: 'activitylogdashboard',
                        tab: tabs[tab].name === SYSTEM_ADMIN.LABEL_USER_ACTIVITY ? 'userActivity' : 'reports',
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: [
                            {
                                column: sortData.column,
                                value: sortData.value,
                            },
                        ],
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;
            if (result.activitylogdashboard !== null) {
                const tabName = tab === 0 ? 'userActivity' : 'reports';

                exportList('SystemAdmin', tabName, result.activitylogdashboard);
            } else {
                throw response.data.dashboardExportList.error;
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export List',
                testId: 'activitylogs-error-modal',
            });
            handleErrorHandler();
        }
    };
    //Fn to clear activity logs context
    const clearContext = () => {
        setSearchInput({ value: '', column: 'all' });
        setSortData({ column: 'createdOn', value: 'descending' });
        setTempFilters(initialActivityFilter);
        setFilters(initialActivityFilter);
        setResultLimit(10);
    };
    useMemo(() => {
        getActivityLogData();
    }, [currentPage, resultLimit, debouncedSearchTerm, sortData, filters]);

    useEffect(() => {
        if (initial === false) {
            setSearchInput({ value: '', column: 'all' });
            setSortData({ column: 'createdOn', value: 'descending' });
            setTempFilters(initialActivityFilter);
            setFilters(initialActivityFilter);
            setResultLimit(10);
        }
        return function cleanup() {
            clearContextOnNavigation('activityLogs', history.location.pathname) === false ? clearContext() : null;
        };
    }, [tab]);

    useEffect(() => {
        rolesList.length === 0 && eventTypeList.length === 0 ? getDropdown() : null, setInitial(false);
    }, []);

    const sameFilter = JSON.stringify(initialActivityFilter) !== JSON.stringify(filters);
    const searchOrFiltered = sameFilter || searchInput.value !== '';

    const tabs: IDashboardTabs[] = [];
    if (userActivityTab.isAll === 'true') {
        tabs.push({
            name: SYSTEM_ADMIN.LABEL_USER_ACTIVITY,
            table: <UserActivity searchEmpty={searchOrFiltered} />,
        });
    }
    if (reportsTab.isAll === 'true') {
        tabs.push({
            name: SYSTEM_ADMIN.LABEL_REPORTS,
            table: <Reports searchEmpty={searchOrFiltered} />,
        });
    }
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsActivityLogs.activityLogs.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `activity-logs-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };
    return (
        <Fragment>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={
                    tabs[tab] && tabs[tab].name === SYSTEM_ADMIN.LABEL_USER_ACTIVITY
                        ? LABEL.searchPlaceholderActivityLogs
                        : LABEL.searchPlaceholderReports
                }
                searchTitle={SYSTEM_ADMIN.LABEL_ACTIVITY_LOGS}
                dropDownOptions={searchOptions()}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                primaryButton={
                    tabs[tab] &&
                    tabs[tab].name === SYSTEM_ADMIN.LABEL_USER_ACTIVITY &&
                    userActivityTab.actions.canExportReport === 'maker'
                        ? { type: 'primary', title: SYSTEM_ADMIN.LABEL_EXPORT_REPORT, actionIcon: 'export' }
                        : undefined
                }
                secondaryButton={
                    (tabs[tab] &&
                        tabs[tab].name === SYSTEM_ADMIN.LABEL_USER_ACTIVITY &&
                        userActivityTab.actions.canExportList === 'maker') ||
                    (tabs[tab] &&
                        tabs[tab].name === SYSTEM_ADMIN.LABEL_REPORTS &&
                        reportsTab.actions.canExportList === 'maker')
                        ? [{ type: 'secondary', title: 'Export List', actionIcon: 'export' }]
                        : undefined
                }
                handlePrimary={handleSearchActions}
                searchInput={searchInput}
                handleSecondary={handleSearchActions}
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                testId="activity-logs"
            >
                <TextPureBlack>{LABEL.filterActivityLogsBy}</TextPureBlack>
                <CustomSpacer space={'0.5rem'} />
                <FlexedDiv direction="row" wrap="wrap">
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>{LABEL.dateRange}</Fs12BoldPrimaryBlack>

                        <InputWrap>
                            <DatePicker
                                setTargetDate={(date) => {
                                    handleTargetDate(date as [moment.Moment | null, moment.Moment | null]);
                                }}
                                targetDate={targetDateRange}
                                range={true}
                                width="360"
                                handleDate={handleDate}
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                            />
                        </InputWrap>
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <MultiSelect
                            checkedState={roles}
                            handleMultiSelect={handleRoles}
                            hasSelectAll={true}
                            label={LABEL.role}
                            noOverlay={false}
                            options={rolesList}
                            placeHolder={LABEL.select}
                        />
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <MultiSelect
                            checkedState={eventTypes}
                            handleMultiSelect={handleEventTypes}
                            hasSelectAll={true}
                            label={LABEL.eventType}
                            noOverlay={false}
                            options={eventTypeList}
                            placeHolder={LABEL.select}
                        />
                    </FlexedDiv>
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack>{LABEL.status}</Fs12BoldPrimaryBlack>
                        <SelectWrapper>
                            <SelectDropdown
                                onChange={handleStatus}
                                options={statusOptions}
                                testId={`status-sorting`}
                                selectedValue={status}
                                placeHolder={LABEL.selectOne}
                            />
                        </SelectWrapper>
                    </FlexedDiv>
                </FlexedDiv>
                <FlexedDiv style={{ marginTop: '2rem' }} justifyContent="center">
                    <CustomButton onClick={handleReset} style={{ width: '16.67vw' }}>
                        <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                    </CustomButton>
                    <CustomSpacer horizontal={true} space={'1rem'} />
                    <CustomButton primary={true} onClick={handleApply} style={{ width: '16.67vw', padding: '14px 0' }}>
                        {LABEL.apply}
                    </CustomButton>
                </FlexedDiv>
            </SearchBar>
            <TabWrapper>
                <Dashboard
                    tabs={tabs}
                    selectedTab={tab}
                    orderCounter={logCounter}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    page={currentPage}
                    maxPage={maxPages}
                    resultLimit={resultLimit}
                    setResultLimit={setResultLimit}
                    setCurrentPage={setCurrentPage}
                    disableResultLimit={disableResultLimit}
                />
                {loading ? <ComponentLoader /> : null}
            </TabWrapper>
        </Fragment>
    );
};

const TabWrapper = styled.div`
    position: relative;
`;
const InputWrap = styled.div`
    width: 18.33vw;
    min-width: 18.33vw;
    margin-right: 1rem;
    height: 2.5rem;
`;
const SelectWrapper = styled.div`
    min-width: 250px;
    max-width: 600px;
    width: 18.33vw;
`;
