/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdvanceTable, CustomDropdownV2, TableGroupingTemplates } from '../../components';
import { EMPTY_STATES, LABEL } from '../../constants';
import { getGroupingFromData, getGroupStyling, statusStyle, stringToCamelCase } from '../../utils';
import { sh112, sh128, sh152, sh208, sh56, subTitleStyle } from '../../styles';
import { IPendingTab } from '../../utils/permissionTypes';
import { IcoMoon } from '../../icons';
import * as ROUTES from '../../routes';
import CustomItemIncomplete from './CustomItemIncomplete';
import PendingSubmissionContext from '../../context/PendingSubmissionContext/PendingSubmissionContext';

interface IIncompleteProps {
    filters: ISort_FilterInput;
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    submittedOnDropdown: string;
    loadingHandler: () => void;
    pendingTab: IPendingTab;
    searchInput?: string;
    setFilters: (data: ISort_FilterInput) => void;
    setSubmittedOnDropdown: (value: string) => void;
    setSortData: (data: ISearchInput) => void;
    sortData: ISearchInput;
}

interface IncompleteDateHeaderProps extends IHeaderMenu {
    dropdownData: string[];
    setData: (item: string, value: string) => void;
}

const Incomplete: React.FC<IIncompleteProps> = ({
    filters,
    isFilterApplied,
    isSearchResult,
    submittedOnDropdown,
    pendingTab,
    searchInput,
    setFilters,
    setSubmittedOnDropdown,
    setSortData,
    sortData,
}: IIncompleteProps) => {
    //Context
    const { pendingSubmission, dateFilter, setDateFilter } = useContext(PendingSubmissionContext);
    const history = useHistory();
    const [incompleteTableData, setIncompleteTableData] = useState<
        (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]
    >([]);
    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data = {
            orderNo: item.rawData.orderNo,
            tab: 'daily',
            endpoint: 'branchDashboard',
            verifyOnly: item.rawData.isVerified,
            url: window.location.pathname,
        };
        redirectViewDetails(history.location.pathname, data, item);
    };
    const redirectViewDetails = (path: string, data: any, item: IColumnItemAccordion) => {
        const trxType = item.rawData.transactionType as string;
        const result = trxType.match(/(Change Request)|(CR)/);
        result && result[0] === null
            ? history.push(`${path}/${ROUTES.orderSummary}`, data)
            : history.push(`${path}/${ROUTES.orderSummaryChangeRequest}`, data);
    };
    // Fn to handle table column sorting
    const handleSort = (keyName: string) => {
        const tempSort = { ...sortData };
        const sortType = sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        setSortData(tempSort);
    };
    // Fn to handle sort arrow icon
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortData.column === keyName) {
            const icon = sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    // Fn to handle date filter
    const handleDateFilter = (item: string, value: string) => {
        setSubmittedOnDropdown(item);
        const temp = [...filters];
        let currentFilter = 'submittedOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        temp[0] = tempObj;
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData({ column: currentFilter, value: value });
        setFilters(temp);
    };

    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    submittedOnDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData.column === stringToCamelCase(submittedOnDropdown) ? sortData.value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const columnsIncomplete: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: sh112,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData.column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData.column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: 16,
                style: { opacity: '0.2' },
            },
        },
        {
            icon: {
                name: handleSortIcon('fundType'),
                size: '1rem',
            },
            key: [{ key: 'fundType', textStyle: { fontWeight: sortData.column === 'fundType' ? 700 : 400 } }],
            viewStyle: {
                width: sh128,
            },
            onPressHeader: () => handleSort('fundType'),
            title: LABEL.products,
            titleStyle: {
                fontWeight: sortData.column === 'fundType' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('paymentMethod'),
                size: '1rem',
            },
            key: [{ key: 'paymentMethod', textStyle: { fontWeight: sortData.column === 'paymentMethod' ? 700 : 400 } }],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.funding,
            onPressHeader: () => handleSort('paymentMethod'),
            titleStyle: {
                fontWeight: sortData.column === 'paymentMethod' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalInvestment' }],
            viewStyle: {
                width: sh152,
            },
            title: LABEL.totalInvestments,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData.column === 'totalInvestment' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData.column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh208,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData.column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh208,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData.column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: sh56,
            },
            itemIcon: {
                name: pendingTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                pendingTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'daily-view-column',
        },
    ];
    useEffect(() => {
        let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
        data = getGroupingFromData(pendingSubmission, TableGroupingTemplates, getGroupStyling);
        setIncompleteTableData([...data]);
    }, [pendingSubmission]);
    return (
        <Fragment>
            <AdvanceTable
                columns={columnsIncomplete}
                data={incompleteTableData}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableDaily
                }
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItemIncomplete isSortedColumn={sortData.column} {...props} />
                )}
                groupedRow={true}
                isSearchResult={isSearchResult}
                searchInput={searchInput ?? ''}
                testId="pending-incomplete"
            />
        </Fragment>
    );
};

export default Incomplete;

const DateHeader = ({ dropdownData, setData }: IncompleteDateHeaderProps): JSX.Element => {
    const handleAction = (item: string, _rawData: ITableData | undefined, value: string | undefined) => {
        value && setData(item, value);
    };
    const handleIcon = (item: string) => {
        if (item === LABEL.lastUpdated && dropdownData[0] === LABEL.lastUpdated)
            return dropdownData[1] === 'descending'
                ? 'arrow-down'
                : dropdownData[1] === 'ascending'
                ? 'arrow-up'
                : undefined;
        else if (item === LABEL.submittedOn && dropdownData[0] === LABEL.submittedOn)
            return dropdownData[1] === 'descending'
                ? 'arrow-down'
                : dropdownData[1] === 'ascending'
                ? 'arrow-up'
                : undefined;
        else if (item === LABEL.createdOn && dropdownData[0] === LABEL.createdOn)
            return dropdownData[1] === 'descending'
                ? 'arrow-down'
                : dropdownData[1] === 'ascending'
                ? 'arrow-up'
                : undefined;
        else return undefined;
    };
    const handleValue = (value: string) => {
        return value === 'descending' ? 'ascending' : 'descending';
    };
    const items: IDropdownItemV2[] = [
        {
            item: LABEL.lastUpdated,
            handleItem: handleAction,
            icon: handleIcon(LABEL.lastUpdated),
            value: handleValue(dropdownData[1]),
        },
        {
            item: LABEL.createdOn,
            handleItem: handleAction,
            icon: handleIcon(LABEL.createdOn),
            value: handleValue(dropdownData[1]),
        },
    ];
    return (
        <CustomDropdownV2 items={items} selectIcon={true} showDivider={false} iconPosition="after" noMinWidth>
            <IcoMoon name="caret-down" size="1rem" />
        </CustomDropdownV2>
    );
};
