import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';

interface StandardRowProps {
    data: ITableCellData[];
}
type TdCellProps = {
    value: number;
};
export const StandardRow: React.FC<StandardRowProps> = ({ data }: StandardRowProps) => {
    return (
        <StyledTbody>
            {data.map((cell, index) => {
                return (
                    <Tr key={index}>
                        {Object.keys(cell).map((key) => {
                            // _tempId = getTdElement(cell, key);
                            if (key === 'closingDate') {
                                return (
                                    <Td key={key} value={cell[key]}>
                                        {cell[key] !== null && moment(cell[key], 'x').format('DD/MM/YYYY')}
                                    </Td>
                                );
                            } else if (key === 'change' || key === 'changePercentage') {
                                return (
                                    <TdColor key={key} value={cell[key]}>
                                        {cell[key] !== null ? cell[key] : '-'}
                                    </TdColor>
                                );
                            } else {
                                return (
                                    <Td key={key} value={cell[key]}>
                                        {cell[key] !== null ? cell[key] : '-'}
                                    </Td>
                                );
                            }
                        })}
                    </Tr>
                );
            })}
        </StyledTbody>
    );
};

const StyledTbody = styled.tbody`
    width: 100%;
`;
const Tr = styled.tr`
    width: auto;
`;

const Td = styled.td<TdCellProps>`
    width: auto;
    padding: 0.5rem;
    font-size: 0.875rem;
    background-color: #fff;
    border: 1px solid #eaebee;
`;
const TdColor = styled(Td)<TdCellProps>`
    ${(props) =>
        !isNaN(props.value) &&
        props.value > 0 &&
        css`
            color: #2ecc82;
        `}
    ${(props) =>
        !isNaN(props.value) &&
        props.value < 0 &&
        css`
            color: #e84c3d;
        `}
`;
