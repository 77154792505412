import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import fileCheckQuery from '../../_graphql/queries/fundData/documentDashboard/fileNameCheck';
import { LABEL } from '../../constants';
import { CustomButton, FlexedDiv, PreviousPage, DocumentUploader } from '../../components';
import * as Routes from '../../routes';
import { IcoMoon } from '../../icons';
import { reasonsPageButtons } from '../../styles';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    uploadType: string;
    tabName: string;
    tabIndex: number;
}

const Import: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const [uploadedFile, setUploadedFile] = useState<File>();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFileNameExist, setIsFileNameExist] = useState<boolean>(false);
    const [subTitle, setSubTitle] = useState('');
    const [uploadReset, setUploadReset] = useState<boolean>(false);
    const { state: propsData } = useLocation<IRouteProps>();
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //call query to check if master fund file exists
    const checkFileNameQuery = async (fileName: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(fileCheckQuery, {
                    input: {
                        fileName: fileName,
                        tab: propsData.tabName,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fileCheck;
            if (!error) {
                data.result.status ? setIsFileNameExist(true) : setIsFileNameExist(false);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Check for File Name',
                    testId: 'bulkupload-documents-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };
    const uploadedFileHandler = (file: File) => {
        setUploadedFile(file);
        setIsValid(true);
        checkFileNameQuery(file.name);
    };

    const goBacktoUploadDashboard = () => {
        history.push({
            pathname: Routes.financeUploads,
            state: {
                activeTab: propsData.tabIndex,
            },
        });
    };
    const goViewImportFile = () => {
        history.push({
            pathname: Routes.financeViewImportFile,
            state: {
                file: uploadedFile,
                uploadType: propsData.uploadType,
                tabIndex: propsData.tabIndex,
                tabName: propsData.tabName,
            },
        });
    };
    useEffect(() => {
        if (propsData) {
            let _subTitle = '';
            switch (propsData.uploadType) {
                case 'NAV':
                    _subTitle =
                        'You can use spreadsheets to make changes to the funds NAV data, then upload your changes all at once.';
                    break;
                case 'distribution':
                    _subTitle =
                        'You can use spreadsheets to make changes to the funds distribution data, then upload your changes all at once.';
                    break;
                default:
                    _subTitle =
                        'You can use spreadsheets to make changes to the funds data, then upload your changes all at once.';
            }

            setSubTitle(_subTitle);
        }
    }, [propsData]);
    return (
        <Fragment>
            <PreviousPage title={`Import ${propsData.uploadType}`} handleBackFunction={goBacktoUploadDashboard} />
            <StyledInner>
                <PageDescription>{subTitle}</PageDescription>
                <DocumentUploader
                    uploadedFileHandle={uploadedFileHandler}
                    isMultiple={false}
                    isFileNameExist={isFileNameExist}
                    handleUploadReset={setUploadReset}
                />
                {isFileNameExist && !uploadReset && (
                    <Error>
                        <IcoMoon name="clear" size="21px" color="#E84C3D" data-testid="icon-icon" />
                        <ErrorMessage>
                            A file with same name already exists. Would you like to replace the existing one?
                        </ErrorMessage>
                        <FlexedDiv>
                            <RetryBtn onClick={goViewImportFile} id="replace-file-btn">
                                Replace Existing <IcoMoon name="arrow-right" size="1rem" />
                            </RetryBtn>
                        </FlexedDiv>
                    </Error>
                )}

                <ButtonDiv>
                    <CustomButton
                        style={reasonsPageButtons}
                        onClick={() => goBacktoUploadDashboard()}
                        id="import-cancel-btn"
                    >
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary
                        style={reasonsPageButtons}
                        onClick={() => goViewImportFile()}
                        disabled={!isValid}
                        id="import-submit-btn"
                    >
                        {LABEL.continue}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
        </Fragment>
    );
};
const StyledInner = styled.div`
    margin-left: 2.5rem;
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;
const ErrorMessage = styled.span`
    color: #e84c3d;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
`;
const Error = styled.div`
    width: 100%;
    padding: 8px 0px;
`;

const RetryBtn = styled.button`
    border: 0px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 700;
    margin-top: 15px;
    &:focus {
        outline: none;
    }
`;
export default Import;
