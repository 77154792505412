/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment, ReactText, SetStateAction } from 'react';

import MultiSelectV2, { IMultiSelectLabel } from '../../molecules/MultiSelect';

import styled from 'styled-components';
import Toggle from '../../atoms/Toggle';

interface ICheckboxWithMultiSelectPropsV2 extends ICustomCheckboxProps {
    data: IMultiSelectLabel[];
    disabled?: boolean;
    handleData?: (index: SetStateAction<ReactText[]>) => void;
    placeholder: string;
    hasSelectAll?: boolean;
    selectLabel: string;
    multiSelectState: Array<ReactText>;
    handleMultiSelectDropdown: (item: Array<ReactText>, fn: string, id: number) => void;
    testId?: string;
}

const CheckboxWithMultiSelectV2 = ({
    checked,
    data,
    disabled,
    id,
    label,
    placeholder,
    testId,
    handleCheckbox,
    hasSelectAll,
    selectLabel,
    multiSelectState,
    handleMultiSelectDropdown,
    value,
}: ICheckboxWithMultiSelectPropsV2): JSX.Element => {
    return (
        <Fragment>
            <ToggleWrap>
                <Toggle
                    handleToggle={handleCheckbox}
                    selected={checked}
                    onClick={() => null}
                    id={id ?? ''}
                    label={label}
                    disabled={disabled}
                />
                {/* <CustomCheckbox
                    checked={checked}
                    label={label}
                    id={id}
                    disabled={disabled}
                    value={value}
                    handleCheckbox={handleCheckbox}
                /> */}
            </ToggleWrap>

            {checked ? (
                <MultiSelectV2
                    label={selectLabel}
                    placeHolder={placeholder}
                    options={data !== undefined ? data : []}
                    noOverlay
                    disabled={disabled}
                    expandable={disabled}
                    selectId={parseInt(id ?? '0')}
                    hasSelectAll={hasSelectAll}
                    handleMultiSelect={handleMultiSelectDropdown}
                    checkedState={multiSelectState}
                    testId={testId}
                />
            ) : null}
        </Fragment>
    );
};

const ToggleWrap = styled.div`
    background-color: #e7ebf1;
    margin-bottom: 1rem;
`;

export default CheckboxWithMultiSelectV2;
