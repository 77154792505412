/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dashboard, SearchBar, ComponentLoader } from '../../../components';
import { LABEL } from '../../../constants';

import { EpfTab } from '.';
import { API, graphqlOperation } from 'aws-amplify';
import { systemSettingsDashboardQuery } from '../../../_graphql/queries/systemadmin/systemSettings/systemSettingsDashboard';

import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';
import { updateIsSeenDashboard } from '../../../utils';
/**
 * System Settings Dashboard for System Admin
 * @category Pages
 * @component
 * @namespace SystemSettingsDashboard
 * @returns System Settings dashboard component
 */
const SystemSettingsDashboard: React.FC = (): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);

    const { systemSettings } = parsedPermission.hq.permission;

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    // Dashboard tab data states
    const [allEpfData, setAllEpfData] = useState<EPFTableData[]>([]);

    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    // Filter & sorting states
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });

    // utility states

    const initialRender = useRef<boolean>(true);

    //Custom hooks
    // const { loading, loadingHandler } = useComponentLoader();

    const tabs: IDashboardTabs[] = [];

    //call query to get current EPF settings
    const getEpfSettings = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(systemSettingsDashboardQuery, {
                    input: {
                        tab: 'epf',
                        page: currentPage,
                        resultLimit: '10',
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.systemSettingsDashboard;
            if (!error) {
                const { settings } = data.result;

                setAllEpfData(settings);

                loadingHandler();
            } else {
                loadingHandler();
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch EPF data',
                    testId: 'system-setting-epf-error-modal',
                });
            }
        } catch (error) {
            loadingHandler();
        }
    };
    // //Fn to handle loading
    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };

    // get tab index for dashboards
    const getTabIndex = (): number => {
        let _tabIndex = 0;
        if (tab !== undefined) _tabIndex = tabs.indexOf(tabs[tab]);
        return _tabIndex;
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };

    // Fn to handle search bar input
    const searchInputHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const tempValue = event.currentTarget as HTMLInputElement;
        setSearchInput({ ...searchInput, value: tempValue.value });
    };
    // Fn to call get getEpfSettings
    const handleRefresh = () => {
        getEpfSettings();
    };

    // set dashbaord tabs according to permissions
    if (systemSettings.epfTab.isAll === 'true') {
        // console.log('set dashbaord tabs');
        const _epfTab = {
            name: 'EPF',
            table: (
                <EpfTab
                    epfTabData={allEpfData}
                    getTabIndex={getTabIndex}
                    handleLoading={loadingHandler}
                    handleRefresh={handleRefresh}
                />
            ),
            testId: 'epfTab-tab',
            dashboardId: 'epf',
        };
        tabs.push(_epfTab);
    }
    useMemo(() => {
        // initialRender.current ? (initialRender.current = false) : (initialRender.current = true);

        switch (tabs[tab].dashboardId) {
            case 'epf':
                getEpfSettings();
                break;
        }
    }, [searchInput]);

    useEffect(() => {
        initialRender.current = true;

        setCurrentPage(1);
        setSearchInput({ value: '', column: 'all' });

        return function cleanup() {
            tab !== undefined
                ? updateIsSeenDashboard(
                      'systemSettingsDashboard',
                      [tabs[0].dashboardId ? tabs[0].dashboardId : 'epf'],
                      idTokenHeader,
                  )
                : null;
        };
    }, [tab]);
    return (
        <TabWrapper>
            <SearchBar
                filter={false}
                placeHolder={LABEL.searchSearchSettings}
                searchTitle="Settings"
                onSearch={searchInputHandler}
                searchInput={searchInput}
                testId="system-settings-searchbar"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                disabled={true}
            />
            <Dashboard
                tabs={tabs}
                selectedTab={tab !== undefined ? tab : 0}
                orderCounter={[]}
                switchTabs={switchTabs}
                handleNext={() => null}
                handlePrevious={() => null}
                setResultLimit={() => null}
                testId="system-settings-dashboard"
                setCurrentPage={() => null}
                disableResultLimit={true}
                disablePagination={true}
            />
            {loading ? <ComponentLoader /> : null}
        </TabWrapper>
    );
};
export default SystemSettingsDashboard;
//component styles
const TabWrapper = styled.div`
    position: relative;
`;
