/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { cancelCaseMutation } from '../../../../_graphql/mutations/edd/cancelCase/cancelCase';
import { CustomButton, CustomSpacer, FlexedDiv, TextArea, Modal } from '../../../../components';
import * as Routes from '../../../../routes';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

import {
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    Fs16SemiBoldSecPrimaryBlack,
    Fs24BoldPrimaryBlack,
    LABEL,
    EDD,
} from '../../../../constants';
import { LocationState } from '../../../ExtendTargetDate/ExtendTargetDate';
import { IcoMoonSelection } from '../../../../icons';
import IcomoonReact from 'icomoon-react';
import styled from 'styled-components';
import AuthContext from '../../../../context/AuthContext';

export interface CancelRequestProps {
    locationState?: LocationState;
}

export const CancelRequest: React.FC<CancelRequestProps> = () => {
    const { userLoginContext } = React.useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = React.useContext(ErrorHandlingContext);
    const [cancelRemarks, setCancelRemarks] = React.useState<string>('');
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [isRejectedConfirm, setIsRejectedConfirm] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const state: any = location.state;
    const PassedCaseId: LocationState = state.caseId;

    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const handleApproveCancel = (action: string) => {
        approveCancelCase(action);
    };
    const approveCancelCase = async (action: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(cancelCaseMutation, {
                    input: {
                        caseId: PassedCaseId,
                        action: action,
                        remark: cancelRemarks,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.caseStatus;
            if (error) throw error;
            if (data) {
                if (data.result.status) {
                    // console.log('THIS IS CANCELLED', data);
                    setShowModal(true);
                }
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Case Review Cancelled',
                testId: 'notifications-groups-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const modalTitle = EDD.CASE_CANCELLED;
    const modalSubtitle = EDD.CASE_CANCELLED_SUB;

    return (
        <Fragment>
            <FlexedDiv direction="row">
                <CustomSpacer horizontal={true} space={'2.778vw'} />
                <FlexedDiv direction="column">
                    <TitleContainer style={{ flexDirection: 'row', display: 'flex' }}>
                        <Icon onClick={handleBack}>
                            <IcomoonReact iconSet={IcoMoonSelection} icon="arrow-left" size="1.5rem" />
                        </Icon>
                        <CustomSpacer horizontal={true} space={'1.5rem'} />
                        <Fs24BoldPrimaryBlack>{EDD.CANCEL_REQUEST}</Fs24BoldPrimaryBlack>
                    </TitleContainer>
                    <div style={{ paddingLeft: '3rem' }}>
                        <CustomSpacer space={'0.5rem'} />
                        <Fs16SemiBoldSecPrimaryBlack>{EDD.CANCEL_REQUEST_SUB}</Fs16SemiBoldSecPrimaryBlack>
                        <CustomSpacer space={'2rem'} />
                        <TextArea
                            name="cancelRemarks"
                            label={LABEL.remarks}
                            value={cancelRemarks}
                            handleInput={(e) => setCancelRemarks(e.currentTarget.value)}
                            maxLength={255}
                            style={{ width: '38.89vw' }}
                        />
                        <CustomSpacer space={'3.5rem'} />
                        <FlexedDiv>
                            <CustomButton
                                primary={false}
                                onClick={() => {
                                    setIsRejected(false);
                                    setIsRejectedConfirm(false);
                                    setCancelRemarks('');
                                    history.push(Routes.amlaEDDManagement);
                                }}
                                style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                            >
                                <Fs15BoldPrimaryBlack>{EDD.BUTTON_CANCEL}</Fs15BoldPrimaryBlack>
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                disabled={cancelRemarks.trim().length === 0}
                                primary={true}
                                onClick={() => {
                                    handleApproveCancel('cancel');
                                }}
                                style={{ width: '16.667vw' }}
                            >
                                <Fs15BoldNeutralWhite>{EDD.BUTTON_SUBMIT}</Fs15BoldNeutralWhite>
                            </CustomButton>
                        </FlexedDiv>
                    </div>
                </FlexedDiv>
            </FlexedDiv>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTitle}
                    icon={'settings-request-success'}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.amlaEDDManagement);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="edd-cancel-request"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <Subtitle>
                            <b>{state.caseNo}</b> &nbsp;{modalSubtitle}
                        </Subtitle>
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

const TitleContainer = styled.div`
    align-items: center;
`;

const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
