/* eslint-disable @typescript-eslint/no-explicit-any */
// import './InvestorDashboard.less';
import React, { Fragment, useContext, useState } from 'react';
import styled from 'styled-components';
import { CardWithBody } from './CardWithBody';
import { IcoMoon } from '../../../../../icons';
import { ComponentLoader, CustomDropdownV2, IDropdownItemV2 } from '../../../../../components';
import { useHistory } from 'react-router-dom';
import FlexSpacer, { FlexedDiv } from '../../../../../components/atoms';
import { LABEL } from '../../../../../constants';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { eddCaseHistoryQuery } from '../../../../../_graphql/queries/edd/eddClientAccounts/eddCaseHistory';
import { useComponentLoader } from '../../../../../customHooks';
import { toTitleCase } from '../../../../../utils';
import * as Routes from '../../../../../routes';
import moment from 'moment';
import API from '@aws-amplify/api';
import ErrorHandlingContext from '../../../../../context/ErrorHandling/ErrorHandlingContext';
import EDDContext from '../../../../../context/EDDContext/EDDContext';
import AuthContext from '../../../../../context/AuthContext/AuthContext';

declare interface IPillProps {
    utaId: string;
    accountNumber: string | null;
    clientId: string;
    clientName: string;
    clientIdNum: string;
    jointId: string | null;
    jointName: string | null;
    jointIdNum: string | null;
    registrationDate: string;
    servicingAdviserName: string;
    servicingAdviserCode: string;
    origin: string;
    hasEddCase: boolean;
}
declare interface IColumnProps {
    column: string;
    value: string;
    subColumn?: string;
}
declare interface IInvestorDashboardProps {
    columns: Array<IColumnProps>;
    pills: Array<IPillProps>;
}
declare interface StyledTableProps {
    background?: string;
    color?: string;
    padding?: string;
    borderRadius?: string;
    opacity?: string;
    width?: string;
    boxShadow?: string;
}
declare interface StyledSpanProps {
    backGround: string;
}
declare interface ICaseHistoryInputProps {
    utaId: string;
    clientIdNum: string;
    index?: number;
}

const caseHistorySortOptions = ['eddCreatedOn', 'caseNo'];
export const InvestorDashboard: React.FC<IInvestorDashboardProps> = ({ columns, pills }: IInvestorDashboardProps) => {
    const [expand, setExpand] = useState<boolean[]>([]);
    const [expandIndex, setExpandIndex] = useState<number>();

    const [caseHistory, setCaseHistory] = useState<any>({ principal: [], joint: [] });
    const [showDownArrow, setShowDownArrow] = useState<boolean>(true);

    const { loading, loadingHandler } = useComponentLoader();
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const history = useHistory();
    const {
        setClientUtaId,
        setClientAccountSearchInput,
        setSortClientList,
        sortClientList,
        // loading,
    } = useContext(EDDContext);

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const handleExpand = (data: ICaseHistoryInputProps, flag?: boolean) => {
        const index = data.index;
        if (index != undefined) {
            delete data['index'];
            if (flag) {
                setExpand([true]);
            } else {
                setExpand((prevState) => {
                    const latestState = prevState.filter(() => undefined);
                    latestState[index] = prevState[index] ? (flag ? prevState[index] : !prevState[index]) : true;
                    return latestState;
                });
            }

            getCaseHistory(data);
        }
    };
    const getCaseHistory = async (input: ICaseHistoryInputProps) => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddCaseHistoryQuery, {
                    input: { ...input },
                }),
                idTokenHeader,
            );
            const { data, error } = response.data.eddCaseHistory;
            if (error !== null) throw error;
            else {
                setCaseHistory(data.result);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot get case history data',
                testId: 'branch-error-modal',
            });
        }
    };
    const selectQuestionTemplate = (data: any) => {
        setClientUtaId(data.utaId);
        history.push(Routes.newCaseForm, { ...data });
    };
    const handleSort = () => {
        setShowDownArrow(!showDownArrow);
        setSortClientList({ ...sortClientList, value: !showDownArrow ? 'Descending' : 'Ascending' });
        if (caseHistorySortOptions.includes(sortClientList.column?.toString())) {
            setExpandIndex(0);
            const data = {
                utaId: pills[0].utaId,
                clientIdNum: pills[0].clientIdNum,
                index: 0,
                sort: { value: !showDownArrow ? 'Descending' : 'Ascending', column: sortClientList.column?.toString() },
            };
            if (pills[0].hasEddCase) handleExpand(data, true);
        } else {
            setExpand((prevState) => {
                const latestState = prevState.filter(() => undefined);
                latestState[0] = false;
                return latestState;
            });
        }
    };
    const caseHistoryFormatter = (eddCase: any) => {
        const history = eddCase;
        const pArray: any = [];

        const check = ['eddTrigger', 'eddReason', 'createdOn', 'closedOn', 'remarks'];
        const dates = ['createdOn', 'closedOn'];
        for (const key in history) {
            if (dates.includes(key) && !history[key].includes('/')) {
                history[key] =
                    history[key] != '' && history[key] != '-' ? moment(history[key], 'x').format('DD/MM/YYYY') : '-';
            }
            if (check.includes(key)) {
                pArray.push({
                    title: key,
                    subTitle: history[key]
                        ? typeof history[key] === 'string'
                            ? history[key]
                            : history[key][0].title
                        : '-',
                    description: history[key]
                        ? typeof history[key] === 'string'
                            ? null
                            : history[key][0].content
                            ? history[key][0].content[0]
                            : null
                        : null,
                });
            }
        }
        return pArray;
    };

    const handleInvestorCardSort = (_item: string, _rawData: ITableData | undefined, value: string | undefined) => {
        value !== undefined && setSortClientList({ ...sortClientList, column: value.toString() });
        if (value !== undefined && caseHistorySortOptions.includes(value?.toString())) {
            setExpandIndex(0);
            const data = {
                utaId: pills[0].utaId,
                clientIdNum: pills[0].clientIdNum,
                index: 0,
                sort: { value: showDownArrow ? 'Descending' : 'Ascending', column: value?.toString() },
            };
            if (pills[0].hasEddCase) handleExpand(data, true);
        } else {
            setExpand((prevState) => {
                const latestState = prevState.filter(() => undefined);
                latestState[0] = false;
                return latestState;
            });
        }
    };
    const getInvestorCardSortValue = (items: IDropdownItemV2[]): string => {
        const _selected = items.find((result) => result.value === sortClientList.column);
        if (_selected !== undefined) {
            return _selected.item;
        } else {
            return '';
        }
    };
    const investorDashboardSortItems: IDropdownItemV2[] = [
        {
            item: 'Account Number',
            value: 'accountNumber',
            handleItem: handleInvestorCardSort,
            testId: 'account-no-option',
        },
        {
            item: 'Investor Name',
            value: 'clientName',
            handleItem: handleInvestorCardSort,
            testId: 'investor-name-option',
        },
        {
            item: 'Adviser Name',
            value: 'servicingAdviserName',
            handleItem: handleInvestorCardSort,
            testId: 'adviser-name-option',
        },
        {
            item: 'Account Opened On',
            value: 'registrationDate',
            handleItem: handleInvestorCardSort,
            testId: 'account-opened-option',
        },
        {
            item: 'Edd Created On',
            value: 'eddCreatedOn',
            handleItem: handleInvestorCardSort,
            testId: 'edd-createdon-option',
        },
        { item: 'EDD Case ID', value: 'caseNo', handleItem: handleInvestorCardSort, testId: 'eddcase-id-option' },
    ];
    return (
        <InvestorDashboardWrap>
            <StyledRow background="#002043" color="white" padding="21px 21px 21px 113px">
                {columns.map((item, index) => {
                    return (
                        <StyledColumn background="#002043" color="white" key={index} padding="0px 0px 0px 8px">
                            {item.column}
                            {item.subColumn && <StyledSubTitle color="#dbdbdb">{item.subColumn}</StyledSubTitle>}
                        </StyledColumn>
                    );
                })}
            </StyledRow>

            <StyledContainer>
                <FlexedDiv
                    style={{
                        position: 'relative',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        marginBottom: '1.6rem',
                    }}
                >
                    <StyledSearchBar>
                        <SearchBarIcon data-testid="icon_span">
                            <IcoMoon name="search-dashboard" size="1.5rem" data-testid="custom-element" />
                        </SearchBarIcon>
                        <input
                            onChange={(e) =>
                                setClientAccountSearchInput({
                                    value: e.currentTarget.value,
                                    column: 'all',
                                })
                            }
                            placeholder={LABEL.searchPlaceHolderEDDClientAccounts}
                        />
                    </StyledSearchBar>
                    <FlexSpacer />
                    <DropDownPill data-testid="investor-dashboard-sort-dropdown" id="investor-dashboard-sort-dropdown">
                        <CustomDropdownV2
                            items={investorDashboardSortItems}
                            selectIcon={true}
                            selected={[getInvestorCardSortValue(investorDashboardSortItems), sortClientList.column]}
                            showDivider={false}
                        >
                            <DropDownInner>
                                <DropDownSpan>{LABEL.sortBy}</DropDownSpan>
                                <DropDownSpan>{getInvestorCardSortValue(investorDashboardSortItems)}</DropDownSpan>

                                <IcoMoon name="caret-down" size="1.5rem" />
                            </DropDownInner>
                        </CustomDropdownV2>
                    </DropDownPill>

                    <StyledCircle onClick={() => handleSort()}>
                        <IcoMoon name={showDownArrow ? 'arrow-down' : 'arrow-up'} size={'1.5rem'} color="#002043" />
                    </StyledCircle>
                </FlexedDiv>
                {pills && pills.length <= 0 ? (
                    <p>Data not found</p>
                ) : (
                    pills.map((item, i) => {
                        return (
                            <Fragment key={i}>
                                <div key={i} style={{ marginLeft: '65px', alignSelf: 'stretch' }}>
                                    <StyledSpan
                                        backGround={item.origin === 'OMNI' ? '#FFFFFF' : '#838383'}
                                        onClick={() =>
                                            item.origin === 'OMNI'
                                                ? selectQuestionTemplate({
                                                      utaId: item.utaId,
                                                      isJoinAccount: item.jointIdNum ? true : false,
                                                      clientIdNum: item.clientIdNum,
                                                      jointIdNum: item.jointIdNum,
                                                  })
                                                : undefined
                                        }
                                    >
                                        <IcoMoon
                                            name="check"
                                            size="1.5rem"
                                            color="#002043"
                                            style={{ margin: '7px' }}
                                        ></IcoMoon>
                                    </StyledSpan>

                                    <StyledRow
                                        key={i}
                                        borderRadius="8px"
                                        padding="0px"
                                        opacity={item.origin === 'OMNI' ? '1' : '0.6'}
                                    >
                                        <StyledColumn boxShadow="none">
                                            <span>
                                                <IcoMoon
                                                    style={{
                                                        display: 'flex',
                                                        padding: '4px',
                                                        width: '24px',
                                                        height: '24px',
                                                        background: ' #F4F4F4',
                                                        borderRadius: '88px',
                                                        margin: '0px 0px -23px -21px',
                                                    }}
                                                    name={item.jointIdNum ? 'joint' : 'avatar'}
                                                    size={'1rem'}
                                                    color="#002043"
                                                />
                                                &nbsp;&nbsp;&nbsp;
                                                {item.accountNumber || '-'}
                                            </span>
                                        </StyledColumn>
                                        <StyledColumn>
                                            <b>{item.clientName || '-'}</b>
                                            <StyledSubTitle color="#4D4D4D">{item.clientIdNum}</StyledSubTitle>
                                        </StyledColumn>
                                        <StyledColumn>
                                            {item.jointName || '-'}
                                            <StyledSubTitle color="#4D4D4D">{item.jointIdNum}</StyledSubTitle>
                                        </StyledColumn>
                                        <StyledColumn>
                                            {item.servicingAdviserName || '-'}
                                            <StyledSubTitle color="#4D4D4D">{item.servicingAdviserCode}</StyledSubTitle>
                                        </StyledColumn>
                                        <StyledColumn>
                                            {item.registrationDate
                                                ? moment(item.registrationDate, 'x').format('DD/MM/YYYY')
                                                : '-'}
                                        </StyledColumn>
                                        <StyledColumn>{item.origin}</StyledColumn>
                                        <StyledColumn
                                            // width="10px"
                                            onClick={() => {
                                                setExpandIndex(i);
                                                handleExpand({
                                                    clientIdNum: item.clientIdNum,
                                                    utaId: item.utaId,
                                                    index: i,
                                                });
                                            }}
                                        >
                                            {item.hasEddCase && (
                                                <IcoMoon
                                                    style={{ cursor: 'pointer' }}
                                                    name={expand[i] && i === expandIndex ? 'shrink2' : 'maximize'}
                                                    size={'1.2rem'}
                                                    color="#002043"
                                                />
                                            )}
                                        </StyledColumn>
                                    </StyledRow>
                                    {expand[i] && i === expandIndex && (
                                        <Fragment>
                                            <StyledGrid>
                                                {Object.keys(caseHistory).map((item, i) => {
                                                    return caseHistory[item].map((eddCase: any, index: number) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                {index == 0 && (
                                                                    <Fragment>
                                                                        {item.toLowerCase() === 'joint' && <br />}
                                                                        <PrimaryTitle>
                                                                            <span>
                                                                                {toTitleCase(item)} Account Holder
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 'bold',
                                                                                }}
                                                                            >{` : ${caseHistory[item][0].name}(${caseHistory[item][0].clientIdNumber})`}</span>
                                                                        </PrimaryTitle>
                                                                        <br />
                                                                    </Fragment>
                                                                )}
                                                                <div key={index}>
                                                                    <CardWithBody
                                                                        key={i}
                                                                        label={eddCase.status}
                                                                        title={'EDD Case ID'}
                                                                        titleSize={1}
                                                                        subTitle={eddCase.caseNo}
                                                                        data={caseHistoryFormatter(eddCase)}
                                                                    ></CardWithBody>
                                                                </div>
                                                            </Fragment>
                                                        );
                                                    });
                                                })}
                                            </StyledGrid>
                                            <div style={{ padding: '10px' }}></div>
                                        </Fragment>
                                    )}
                                </div>
                            </Fragment>
                        );
                    })
                )}
            </StyledContainer>
            {loading ? <ComponentLoader /> : null}
        </InvestorDashboardWrap>
    );
};

declare interface StyledSubTitleProps {
    color: string;
}
const InvestorDashboardWrap = styled.div`
    max-width: 100%;
    margin-top: -10px;
`;

const DropDownPill = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 32px;
    background-color: #fff;
    margin-left: 1rem;
`;
const DropDownInner = styled.div`
    cursor: pointer;
    padding: 10px 12px;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    color: #4d4d4d;
    margin-right: 5px;
    :nth-child(2) {
        font-weight: bold;
    }
`;
const StyledSubTitle = styled.div<StyledSubTitleProps>`
    font-size: 10px;
    line-height: 120%;
    color: ${(props) => props.color};
    min-height: 10px;
`;
const StyledCircle = styled.div`
    height: 47px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #c6cbd4;
    border-radius: 24px;
    margin: 0px 8px;
    cursor: pointer;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    background: #edf0f4;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 0px 0px 16px 16px;
    align-self: stretch;
    margin: 0px 0px;
    padding: 40px 39px 40px 20px;
`;
const StyledGrid = styled.div`
    display: grid;
    grid-gap: 20px;
    padding: 2.5rem 1rem;
    background: #fbfbfb;
    color: black;
    border: 1px solid rgb(244, 244, 244);
    box-sizing: border-box;
    border-radius: 0px 0px 16px 16px;
    grid-template-columns: repeat(2, 1fr);
`;
const StyledRow = styled.div<StyledTableProps>`
    display: flex;
    flex-direction: row;
    // align-items: center;
    padding: ${(props) => (props.padding ? props.padding : '22px 28px')};
    position: static;
    background: ${(props) => (props.background ? props.background : '#ffffff')};
    color: ${(props) => (props.color ? props.color : 'black')};
    border: 1px solid #f4f4f4;
    box-sizing: border-box;
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '16px 16px 0px 0px')};
    opacity: ${(props) => props.opacity};
`;
const StyledColumn = styled.div<StyledTableProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding: ${(props) => (props.padding ? props.padding : '22px 28px')};
    width: ${(props) => (props.width ? props.width : '149px')};
    background: ${(props) => (props.background ? props.background : '#ffffff')};
    color: ${(props) => (props.color ? props.color : 'black')};
    box-shadow: ${(props) => (props.color != 'white' && !props.boxShadow ? 'inset 1px 0px 0px #f4f4f4' : 'none')};
    margin: 0px 14px;
`;

const StyledSearchBar = styled.div`
    width: 60%;
    position: relative;
    input {
        padding: 12px 50px;
        outline: none;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        height: 42px;
        border-radius: 32px;
        width: 100%;
        max-width: 731 px;
    }
`;
const SearchBarIcon = styled.span`
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
`;
const StyledSpan = styled.div<StyledSpanProps>`
    background: ${(props) => props.backGround};
    // padding: 8px 24px 8px 14px;
    border: 1px solid rgb(0, 32, 67);
    box-sizing: border-box;
    // border-radius: 19px;
    margin: 20px 12px -23px -51px;
    // width: 0px;
    position: absolute;
    cursor: pointer;
    min-width: 40px;
    height: 40px;
    border-radius: 21px;
`;
const PrimaryTitle = styled.div`
    font-size: 12px;
    padding-left: 1.4rem;
`;

export default InvestorDashboard;
