import React, { Fragment } from 'react';
import { Pointer } from '../../../components';
import { CustomDropdown } from '../../../components/molecules';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { IReroutedTab, IDueDateTab } from '../../../utils/permissionTypes';

export interface IReroutedActionsProps extends ITableOptions {
    handleExtendTargetDate: (item: IColumnItemAccordion) => void;
    handleApproveTargetDate: (item: IColumnItemAccordion) => void;
    handleRejectTargetDate: (item: IColumnItemAccordion) => void;
    dueDateTab?: IDueDateTab;
    rerouteTab: IReroutedTab;
    setTargetDate: (date: number) => void;
}

const ReroutedActions = ({
    data,
    handleExtendTargetDate,
    handleApproveTargetDate,
    handleRejectTargetDate,
    dueDateTab,
    rerouteTab,
    setTargetDate,
}: IReroutedActionsProps): JSX.Element => {
    const status: string = data.rawData.status.toLowerCase();

    const handleExtend = () => {
        handleExtendTargetDate(data);
    };

    const handleApprove = () => {
        setTargetDate(parseInt(data.rawData.extendTargetDate, 10));
        handleApproveTargetDate(data);
    };

    const handleReject = () => {
        handleRejectTargetDate(data);
    };
    const rerouteMenu = [
        { item: LABEL.extendDueDate, icon: 'clock', handleItem: handleExtend, testId: `rerouted-extend-date-btn` },
    ];
    const requestMenu = [
        {
            item: LABEL.approveRequest,
            icon: 'check',
            handleItem: handleApprove,
            testId: `rerouted-approve-request-btn`,
        },
        {
            item: LABEL.rejectRequest,
            icon: 'terminate',
            danger: true,
            handleItem: handleReject,
            testId: `rerouted-reject-request-btn`,
        },
    ];

    return (
        <Fragment>
            {(rerouteTab.actions.canDoDueDateExtension === 'maker' ||
                rerouteTab.actions.canDoDueDateExtension === 'auto-authorizer') &&
            (status === 'br - rerouted' || status === 'hq - rerouted') ? (
                <CustomDropdown items={rerouteMenu}>
                    <Pointer>
                        <IcoMoon name="action-menu" size="1.5rem" />
                    </Pointer>
                </CustomDropdown>
            ) : (
                <Fragment>
                    {(dueDateTab?.reviewApproval.canApproveDueDateExtension === 'checker' ||
                        dueDateTab?.reviewApproval.canApproveDueDateExtension === 'auto-authorizer') &&
                    status === 'pending due date' ? (
                        <CustomDropdown items={requestMenu}>
                            <Pointer>
                                <IcoMoon name="action-menu" size="1.5rem" />
                            </Pointer>
                        </CustomDropdown>
                    ) : null}
                </Fragment>
            )}
        </Fragment>
    );
};
export default ReroutedActions;
