/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { RegulartTooltipTemplate, Tooltip } from '../../components';
import { IcoMoon } from '../../icons';
import { DateColumnItem, InvestorColumnItem, TotalInvestmentColumnItem } from '../../templates';

const CustomItemIncomplete = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const Remarks = () => {
        return data && data.remarks.length !== 0 ? (
            <Tooltip tooTipContent={<RegulartTooltipTemplate message={data.reasons} />} style={{ padding: '1rem' }}>
                <div>
                    <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                </div>
            </Tooltip>
        ) : (
            <div>--</div>
        );
    };
    const status = data.status as string;
    const renderItems = (key: string) => {
        switch (key) {
            case 'remarks':
                return <Remarks />;
            case 'totalInvestment':
                return <TotalInvestmentColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
            case 'lastUpdated':
            case 'createdOn':
            case 'submittedOn':
            case 'targetDate':
                return (
                    <DateColumnItem
                        tableBadge={
                            data.isVerified && !status.toLowerCase().includes('completed')
                                ? { color: '#243547', text: 'Verify Only' }
                                : undefined
                        }
                        data={data}
                        itemKey={itemKey}
                        isSortedColumn={isSortedColumn}
                    />
                );
        }
    };
    return (
        <Fragment>
            {itemKey.length === 1 ? (
                renderItems(itemKey[0].key)
            ) : (
                <Fragment>
                    <InvestorColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />
                </Fragment>
            )}
        </Fragment>
    );
};
export default CustomItemIncomplete;
