import React, { Fragment, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { CustomButton, FlexedDiv, PreviousPage, DocumentUploader } from '../../components';
import { reasonsPageButtons } from '../../styles';
import { LABEL } from '../../constants';

import fileCheckQuery from '../../_graphql/queries/fundData/documentDashboard/fileNameCheck';
import * as Routes from '../../routes';
import AuthContext from '../../context/AuthContext';
import styled from 'styled-components';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    uploadType: string;
    tabIndex: number;
}

type fundData = {
    fundAbbr: string;
    fundName: string;
};

const BulkUploadDocuments: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const history = useHistory();
    const { state: propsData } = useLocation<IRouteProps>();
    const [uploadedBulkFiles, setUploadedBulkFiles] = useState<File[]>([]);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [fundNames, setFundNames] = useState<fundData[]>([]);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //function to call fileCheck mutation
    const uploadBulkFileHandler = async (files: File[]) => {
        const _fundAbbr: string[] = [];
        Object.values(files).map((file) => {
            _fundAbbr.push(file.name.split(' ')[0]);
        });

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(fileCheckQuery, {
                    input: {
                        tab: 'documents',
                        fundAbbr: _fundAbbr,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fileCheck;
            if (!error) {
                setFundNames(data.result.fundData);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Check for Fund Abbreviations',
                    testId: 'bulkupload-documents-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
        setUploadedBulkFiles(files);
        setIsValid(true);
    };
    const submitBulkFiles = () => {
        history.push({
            pathname: Routes.financeViewBulkUpload,
            state: {
                files: uploadedBulkFiles,
                funddata: fundNames,
                tabIndex: propsData.tabIndex,
            },
        });
    };
    const goBacktoUploadDashboard = () => {
        history.push({
            pathname: Routes.financeUploads,
            state: {
                activeTab: propsData.tabIndex,
            },
        });
    };
    return (
        <Fragment>
            <PreviousPage title="Bulk Upload Documents" handleBackFunction={goBacktoUploadDashboard} />
            <StyledInner>
                <PageDescription>
                    You can upload <strong>up to 30 pdf files</strong> at a time. <br /> The maximum file size for each
                    file is <strong>5 MB</strong> & the maximum total upload file size is <strong>150 MB</strong>.
                </PageDescription>

                <DocumentUploader uploadBulkFileHandle={uploadBulkFileHandler} isMultiple={true} />
                <ButtonDiv>
                    <CustomButton style={reasonsPageButtons} onClick={() => history.goBack()} id="import-cancel-btn">
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        disabled={!isValid}
                        primary
                        style={reasonsPageButtons}
                        onClick={submitBulkFiles}
                        id="import-submit-btn"
                    >
                        {LABEL.continue}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
        </Fragment>
    );
};
const StyledInner = styled.div`
    margin-left: 2.5rem;
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;

export default BulkUploadDocuments;
