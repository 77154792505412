/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { LABEL } from '../../../../../constants';
import { IcoMoon } from '../../../../../icons';
import { FlexedDiv } from '../../../../../components/atoms';
import { CustomDropdown, IDropdownItem } from '../../../../../components/molecules';

interface IListLayoutProps {
    tabs?: Array<IDashboardTabs>;
    selectedTab?: number;
    selectedPill?: number;
    pills?: IDashboardFilterTagsGroup;
    onClickPills?: (item: IDashboardFilterTags) => void;
    page?: number;
    handlePage: (page: number) => void;
    maxPage?: number;
    handleNext: () => void;
    nextStyle?: CSSProperties;
    previousStyle?: CSSProperties;
    handlePrevious: () => void;
    setResultLimit: (limit: number) => void;
    testId?: string;
    children?: any;
    count: number;
    setPagesDropDown: number[];
    resultLimit?: number;
    setCurrentPage: (page: number) => void;
}

export const ListLayout: React.FC<IListLayoutProps> = ({
    handleNext,
    nextStyle,
    previousStyle,
    page,
    maxPage,
    handlePrevious,
    setResultLimit,
    setCurrentPage,
    testId,
    children,
    count,
    resultLimit,
}: IListLayoutProps) => {
    const limits = [10, 20, 50];
    /**
     * Function to handle page limit selection
     *@method handlePageLimit
     *@param value type of SelectValue
     */
    const handlePageLimit = (value: string | number) => {
        value && setResultLimit(parseInt(value.toString(), 10));
    };
    /**
     * Function to handle page number selection
     *@method handlePageNumber
     *@param value type of SelectValue
     */
    const handlePageNumber = (value: string | number) => {
        value && setCurrentPage(parseInt(value.toString()));
    };
    /**
     * Function to generate page limits
     *@method generatePageLimits
     *@returns IDropdownItem[]
     */
    const generatePageLimits = (): IDropdownItem[] => {
        const _items: IDropdownItem[] = [];
        limits.map((result) =>
            _items.push({ item: result.toString(), handleItem: handlePageLimit, testId: `${result}-option` }),
        );

        return _items;
    };
    /**
     * Function to generate page numbers
     *@method generatePageNumbers
     *@returns IDropdownItem[]
     */
    const generatePageNumbers = (maxpages: number): IDropdownItem[] => {
        const _items: IDropdownItem[] = [];
        if (maxpages !== 0) {
            for (let i = 1; i <= maxpages; i++) {
                _items.push({ item: i.toString(), handleItem: handlePageNumber, testId: `${i}-option` });
            }
        }

        return _items;
    };
    return (
        <Fragment>
            <DashboardContainer data-testid="container_dashboard">
                <DashboardTop>
                    <TabContainer>
                        <DashboardLeft>
                            <DashboardTitle>{`${count} Investor(s) Found`}</DashboardTitle>
                        </DashboardLeft>

                        <PaginationContainer>
                            <DropDownPill data-testid="page-limit-dropdown" id={`${testId}-items-dropdown`}>
                                <CustomDropdown items={generatePageLimits()} noMinWidth={true}>
                                    <DropDownInner>
                                        <DropDownSpan>{LABEL.items}</DropDownSpan>
                                        <DropDownSpan>{resultLimit}</DropDownSpan>

                                        <IcoMoon name="caret-down" size="1.5rem" />
                                    </DropDownInner>
                                </CustomDropdown>
                            </DropDownPill>
                            <DropDownPill data-testid="page-number-dropdown" id={`${testId}-items-dropdown`}>
                                <CustomDropdown items={generatePageNumbers(maxPage ? maxPage : 0)} noMinWidth={true}>
                                    <DropDownInner>
                                        <DropDownSpan>{LABEL.page}</DropDownSpan>
                                        <DropDownSpan>{page}</DropDownSpan>

                                        <IcoMoon name="caret-down" size="1.5rem" />
                                    </DropDownInner>
                                </CustomDropdown>
                            </DropDownPill>
                            <MaxPageSpan>of {maxPage}</MaxPageSpan>

                            <FlexedDiv alignItems="center" style={{ opacity: page === 1 && maxPage === 1 ? 0.5 : 1 }}>
                                <CirclePrevBtn
                                    className="card_buttons_back"
                                    style={{
                                        cursor: page === 1 ? 'default' : 'pointer',
                                        opacity: page === 1 ? 0.5 : 1,
                                        ...previousStyle,
                                    }}
                                    onClick={() => handlePrevious()}
                                    id={`${testId}-prev-btn`}
                                >
                                    <IcoMoon name="caret-left" size="1.5rem" />
                                </CirclePrevBtn>

                                <CircleNextBtn
                                    className="card_buttons_next"
                                    style={{
                                        cursor: maxPage === page ? 'default' : 'pointer',
                                        opacity: maxPage === page ? 0.5 : 1,
                                        ...nextStyle,
                                    }}
                                    onClick={() => handleNext()}
                                    id={`${testId}-next-btn`}
                                >
                                    <IcoMoon name="caret-right" size="1.5rem" />
                                </CircleNextBtn>
                            </FlexedDiv>
                        </PaginationContainer>
                    </TabContainer>
                </DashboardTop>
                <DashboardTable data-testid="card-container">
                    <StyledPills>{children}</StyledPills>
                </DashboardTable>
            </DashboardContainer>
        </Fragment>
    );
};
const DashboardContainer = styled.div`
    background-color: #fbfbfb;
    border-radius: 22px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 24%);
    margin-bottom: 8.5rem;
    min-height: 50vh;
`;
const TabContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
`;
const DashboardLeft = styled.div`
    display: flex;
`;
const DashboardTitle = styled.h1`
    font-size: 1.1rem;
    margin: 0;
    font-weight: 700;
`;
const DashboardTop = styled.div`
    padding-top: 10rem;

    display: flex;
    justify-content: space-between;
`;
const DropDownPill = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 32px;
    // background-color: #fff;
    margin-left: 1rem;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
`;
const DropDownInner = styled.div`
    cursor: pointer;
    padding: 10px 12px;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    margin-right: 5px;
    :nth-child(2) {
        font-weight: bold;
    }
`;
const MaxPageSpan = styled.span`
    margin-left: 5px;
    color: #878787;
`;
const CircleBtn = styled.div`
    margin: 0px 0.5rem 0 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 1px solid #eaebee;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const CircleNextBtn = styled(CircleBtn)`
    margin: 0px 1.736vw 0px 4px;
`;
const CirclePrevBtn = styled(CircleBtn)`
    margin: 0px 4px 0px 1rem;
`;

const DashboardTable = styled.div`
    width: 100%;
    padding: 1.5rem 1.736vw;
`;
const StyledPills = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    flex: 1 1 80px;
`;

export default ListLayout;
