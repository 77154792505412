/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';

import {
    Banner,
    CustomButton,
    CustomSpacer,
    FlexedDiv,
    Modal,
    PreviousPage,
    SelectDropdown,
    TextArea,
} from '../../../../components';
import { RadioButton } from '../../../../components/atoms/RadioButton/RadioButton';
import {
    Fs12BoldPrimaryBlack,
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    Fs18BoldPrimaryBlack,
    SYSTEM_ADMIN,
    LABEL,
} from '../../../../constants';
import { IcoMoon } from '../../../../icons';
import { setInitialData, submitData } from '../utils/functions';
import {
    adviserInitialData,
    customerExpInitialData,
    IAdviserSection,
    ICustomerExpSection,
    ILoginRoleSection,
    loginAccessInitialData,
} from '../AddRoles/BranchPrivileges/branchRolesInitialData';
import {
    activityLogsInitialData,
    branchOfficesInitialData,
    customerExperienceInitialData,
    eddCaseInitialData,
    financeCasesInitialData,
    financeDashboardInitialData,
    fundUploadsInitialData,
    IActivityLogsSection,
    IBranchOfficeSection,
    ICustomerExperienceSection,
    IEDDCasesSection,
    IFinanceCasesSection,
    IFinanceDashboardSection,
    IFundUploads,
    IOperationCases,
    IOperationDashboard,
    IProductSettings,
    ISystemSettingsSection,
    IUserManagementSection,
    operationCasesInitialData,
    operationDashboardInitialData,
    productSettingsInitialData,
    systemSettingsInitialData,
    userManagementInitialData,
} from '../AddRoles/HqPrivileges/hqRolesInitialData';
import { RejectRemarks } from '../AddRoles/RejectRemarks';
import { getIdInfo } from '../../../../_graphql/queries/systemadmin/getIdInfo';
import { editRoleMutation } from '../../../../_graphql/mutations/systemAdmin/editRole';
import { IUserManagement } from '../../../../utils/permissionTypes';
import { BranchPrivilegesProps, DropdownBase, BranchPrivileges } from '..';
import { HqPrivilegesProps, HqPrivileges } from '../AddRoles/HqPrivileges';
import { deepCompare } from '../../../../utils';

import * as Routes from '../../../../routes';
import styled from 'styled-components';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import Loader from '../../../../components/atoms/Loader';
import approveReject from '../../../../_graphql/mutations/systemAdmin/approveReject';

export interface CheckBoxState {
    checked: boolean;
    value: string;
}

export interface IApproveReject {
    requestId: string;
    action: string;
    remarks?: string;
}

export type Privileges = 'branch' | 'hq';

export const EditRoles = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const [roleName, setRoleName] = useState<string>('');
    const [status, setStatus] = useState<string>('Pending');
    const [roleDescription, setRoleDescription] = useState<string>('');
    const [loginAccess, setLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [hqLoginAccess, setHqLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [customerExp, setCustomerExp] = useState<ICustomerExpSection>(customerExpInitialData);
    const [userManagement, setUserManagement] = useState<IUserManagementSection>(userManagementInitialData);
    const [activityLogs, setActivityLogs] = useState<IActivityLogsSection>(activityLogsInitialData);
    const [systemSettings, setSystemSettings] = useState<ISystemSettingsSection>(systemSettingsInitialData);
    const [customerExpDashboard, setCustomerExpDashboard] =
        useState<ICustomerExperienceSection>(customerExperienceInitialData);
    const [branchOffices, setBranchOffices] = useState<IBranchOfficeSection>(branchOfficesInitialData);
    const [eddCase, setEddCase] = useState<IEDDCasesSection>(eddCaseInitialData);
    const [financeDashboard, setFinanceDashboard] = useState<IFinanceDashboardSection>(financeDashboardInitialData);
    const [financeCases, setFinanceCases] = useState<IFinanceCasesSection>(financeCasesInitialData);
    const [productSettings, setProductSettings] = useState<IProductSettings>(productSettingsInitialData);
    const [fundUploads, setFundUploads] = useState<IFundUploads>(fundUploadsInitialData);
    const [operation, setOperation] = useState<IOperationDashboard>(operationDashboardInitialData);
    const [operationCases, setOperationCases] = useState<IOperationCases>(operationCasesInitialData);
    const [advisers, setAdvisers] = useState<IAdviserSection>(adviserInitialData);
    const [privilege, setPrivilege] = useState<Privileges>('branch');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<boolean>(false);
    const [remarks, setRemarks] = useState<string>('');
    const [editModal, setEditModal] = useState<boolean>(false);
    const [author, setAuthor] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const locationState: any = location.state;
    const roleRequestId = locationState.item;
    const requestId = locationState.requestId;
    const approval = locationState.approval || locationState.view;
    const source = locationState.source;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;
    const { rolesPermissionTab } = userManagementPermission;
    const { canEditUserRoleDetails } = rolesPermissionTab.actions;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [modified, setModified] = useState(-1);

    // states to store initial values to detect changes have happened or not
    const [oldStatus, setOldStatus] = useState<string>('Pending');
    const [oldRoleDescription, setOldRoleDescription] = useState<string>('');
    const [oldPrivilege, setOldPrivilege] = useState<Privileges>('branch');
    const [oldLoginAccess, setOldLoginAccess] = useState({ ...loginAccessInitialData });
    const [oldCustomerExp, setOldCustomerExp] = useState({ ...customerExpInitialData });
    const [oldHqLoginAccess, setOldHqLoginAccess] = useState({ ...loginAccessInitialData });
    const [oldAdvisers, setOldAdvisers] = useState({ ...adviserInitialData });
    const [oldUserManagement, setOldUserManagement] = useState({ ...userManagementInitialData });
    const [oldActivityLogs, setOldActivityLogs] = useState({ ...activityLogsInitialData });
    const [oldSystemSettings, setOldSystemSettings] = useState({ ...systemSettingsInitialData });
    const [oldCustomerExpDashboard, setOldCustomerExpDashboard] = useState({ ...customerExperienceInitialData });
    const [oldBranchOffices, setOldBranchOffices] = useState({ ...branchOfficesInitialData });
    const [oldProductSettings, setOldProductSettings] = useState({ ...productSettingsInitialData });
    const [oldFundUploads, setOldFundUploads] = useState({ ...fundUploadsInitialData });
    const [oldEddCase, setOldEddCase] = useState({ ...eddCaseInitialData });

    const handleRoleName = (event: ChangeEvent<HTMLInputElement>) => {
        setRoleName(event.target.value);
    };

    const handleCancel = () => {
        history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
    };

    const handleSave = () => {
        setEditModal(true);
    };

    const handleEditRole = async () => {
        setModified(2);
        let branchData = {};
        if (loginAccess.isAll === true) {
            branchData = { ...branchData, accountManagement: loginAccess };
        }
        if (customerExp.isAll === true) {
            branchData = { ...branchData, ceTransaction: customerExp };
        }
        if (advisers.isAll === true) {
            branchData = { ...branchData, adviser: advisers };
        }
        let hqData = {};
        if (hqLoginAccess.isAll === true) {
            hqData = { ...hqData, accountManagement: hqLoginAccess };
        }
        if (customerExpDashboard.isAll === true) {
            hqData = { ...hqData, ceDashboard: customerExpDashboard };
        }
        if (userManagement.isAll === true) {
            hqData = { ...hqData, userManagement };
        }
        if (activityLogs.isAll === true) {
            hqData = { ...hqData, activityLogs };
        }
        if (systemSettings.isAll === true) {
            hqData = { ...hqData, systemSettings };
        }
        if (branchOffices.isAll === true) {
            hqData = { ...hqData, branchOffices };
        }
        if (productSettings.isAll === true) {
            hqData = { ...hqData, productSettings };
        }
        if (fundUploads.isAll === true) {
            hqData = { ...hqData, uploads: fundUploads };
        }
        if (eddCase.isAll === true) {
            hqData = { ...hqData, eddCase: eddCase };
        }

        const data = privilege === 'branch' ? branchData : hqData;
        const dataToSend = submitData(data, privilege);
        try {
            const response: any = await API.graphql(
                graphqlOperation(editRoleMutation, {
                    input: {
                        roleId: roleRequestId,
                        description: roleDescription,
                        grant: privilege,
                        permission: dataToSend,
                        status: getStatus(status),
                    },
                }),
                idTokenHeader,
            );
            if (response.data.editRole.data !== null) {
                setShowModal(true);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: response.data.editRole.error.message,
                    errorCode: response.data.editRole.error.errorCode,
                    title: 'Cannot Edit Roles and Permissions',
                    testId: 'edit-roles-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const handleRoleDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRoleDescription(event.target.value);
        setModified(2);
    };

    const handleApproveReject = async (action: string) => {
        let inputObj: IApproveReject = { requestId: requestId, action: action };
        if (action === 'reject') {
            inputObj = { ...inputObj, remarks: remarks };
        }
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: inputObj,
                }),
                idTokenHeader,
            );
            if (response.data.approveReject.data !== null) {
                setShowModal(true);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: response.data.approveReject.error.message,
                    errorCode: response.data.approveReject.error.errorCode,
                    title: 'Cannot Review Request',
                    testId: 'edit-roles-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const handleApprove = () => {
        handleApproveReject('approve');
    };

    const handleReject = () => {
        setRejectRemarks(true);
    };

    const handleRejectApi = () => {
        handleApproveReject('reject');
    };

    const handleBranchPrivilege = () => {
        if (privilege !== 'branch') {
            setModified(2);
            setPrivilege('branch');
        }
    };
    const handleHqPrivilege = () => {
        if (privilege !== 'hq') {
            setModified(2);
            setPrivilege('hq');
        }
    };

    const handleStatus = (value: string | number) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setStatus(value!.toString());
        setModified(2);
    };

    const checkApprove = () => {
        switch (source) {
            case 'createRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveCreateUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveCreateUserRole === 'auto-authorizer'
                );
            case 'editRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveEditUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveEditUserRole === 'auto-authorizer'
                );
            case 'suspendRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveSuspendUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveSuspendUserRole === 'auto-authorizer'
                );
            case 'enableRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveReactivateUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveReactivateUserRole === 'auto-authorizer'
                );
            case 'deleteRole':
                return (
                    rolesPermissionTab.reviewApproval.canApproveDeleteUserRole === 'checker' ||
                    rolesPermissionTab.reviewApproval.canApproveDeleteUserRole === 'auto-authorizer'
                );
        }
    };

    const handleBack = () => {
        const data = { tab: 'Roles & Permissions' };
        history.push(Routes.dashboardSystemAdmin, data);
    };

    const getStatus = (status: string) => {
        switch (status) {
            case 'Pending':
                return status;
            default:
                return status.toLowerCase();
        }
    };

    const branchProps: BranchPrivilegesProps = {
        approval,
        loginAccess,
        setLoginAccess,
        advisers,
        setAdvisers,
        customerExp,
        setCustomerExp,
    };
    const hasDataChanged = () => {
        if (status.toLowerCase() !== oldStatus.toLowerCase()) return true;
        if (!deepCompare(oldRoleDescription, roleDescription)) return true;
        if (!deepCompare(oldPrivilege, privilege)) return true;
        if (!deepCompare(oldLoginAccess, loginAccess)) return true;
        if (!deepCompare(oldCustomerExp, customerExp)) return true;
        if (!deepCompare(oldHqLoginAccess, hqLoginAccess)) return true;
        if (!deepCompare(oldAdvisers, advisers)) return true;
        if (!deepCompare(oldUserManagement, userManagement)) return true;
        if (!deepCompare(oldActivityLogs, activityLogs)) return true;
        if (!deepCompare(oldSystemSettings, systemSettings)) return true;
        if (!deepCompare(oldCustomerExpDashboard, customerExpDashboard)) return true;
        if (!deepCompare(oldBranchOffices, branchOffices)) return true;
        if (!deepCompare(oldProductSettings, productSettings)) {
            return true;
        }
        if (!deepCompare(oldFundUploads, fundUploads)) return true;
        if (!deepCompare(oldEddCase, eddCase)) return true;
        return false;
    };
    const disabled = roleName === '' || status === '' || modified !== 2 || !hasDataChanged();
    const hqProps: HqPrivilegesProps = {
        approval,
        hqLoginAccess,
        setHqLoginAccess,
        userManagement,
        setUserManagement,
        activityLogs,
        setActivityLogs,
        systemSettings,
        setSystemSettings,
        customerExpDashboard,
        setCustomerExpDashboard,
        branchOffices,
        setBranchOffices,
        eddCase,
        setEddCase,
        financeDashboard,
        setFinanceDashboard,
        financeCases,
        setFinanceCases,
        productSettings,
        setProductSettings,
        fundUploads,
        setFundUploads,
        operationDashBoard: operation,
        setOperationDashBoard: setOperation,
        operationCases,
        setOperationCases,
    };
    // this useEffect is to detect first time loading the page to set Save button as disable
    useEffect(() => {
        setModified(2);
        // console.log(fundUploads.permissionLevel, oldFundUploads.permissionLevel);
    }, [
        hqLoginAccess,
        customerExp,
        userManagement,
        activityLogs,
        // financeDashboard,
        financeCases,
        // userManagementPermission,
        privilege,
        branchOffices,
        eddCase,
        roleName,
        roleDescription,
        customerExpDashboard,
        loginAccess,
        advisers,
        operationCases,
        // operation,
        fundUploads,
        productSettings,
        systemSettings,
    ]);

    const getRoleData = async () => {
        setLoading(true);
        try {
            const response: any = await API.graphql(
                graphqlOperation(getIdInfo, {
                    input: {
                        id: roleRequestId,
                        viewType: 'roles',
                    },
                }),
                idTokenHeader,
            );
            const result = response.data.getIdInformation.data.result;
            const info = JSON.parse(result.info);

            setPrivilege(info.permission.grant);
            setOldPrivilege(info.permission.grant);
            setRoleName(info.roleName);
            info.status.toLowerCase() === 'pending'
                ? setStatus(info.requestedStatus !== null ? info.requestedStatus : 'Pending')
                : setStatus(info.status);
            setOldStatus(info.status);
            setRoleDescription(info.description !== null ? info.description : '');
            setOldRoleDescription(info.description !== null ? info.description : '');
            setAuthor(info.author);
            const { permission } = info.permission;
            if (info.grant === 'branch') {
                const newLoginAccess = setInitialData(
                    permission.accountManagement,
                    loginAccess,
                    info.grant,
                    'accountManagement',
                ) as ILoginRoleSection;
                setLoginAccess(newLoginAccess);
                setOldLoginAccess({
                    permissionLevel: newLoginAccess.permissionLevel,
                    isAll: newLoginAccess.isAll,
                    login: {
                        ...newLoginAccess.login,
                        actions: [...newLoginAccess.login.actions],
                    },
                    inbox: {
                        ...newLoginAccess.inbox,
                        actions: [...newLoginAccess.inbox.actions],
                    },
                    profile: {
                        ...newLoginAccess.profile,
                        actions: [...newLoginAccess.profile.actions],
                    },
                });

                const newCustomerExp = setInitialData(
                    permission.ceTransaction,
                    customerExp,
                    info.grant,
                    'ceTransaction',
                ) as ICustomerExpSection;
                setCustomerExp(newCustomerExp);
                setOldCustomerExp({
                    permissionLevel: newCustomerExp.permissionLevel,
                    isAll: newCustomerExp.isAll,
                    pendingTab: {
                        ...newCustomerExp.pendingTab,
                        actions: [...newCustomerExp.pendingTab.actions],
                    },
                    upcomingTab: {
                        ...newCustomerExp.upcomingTab,
                        actions: [...newCustomerExp.upcomingTab.actions],
                    },
                    hardCopyTab: {
                        ...newCustomerExp.hardCopyTab,
                        actions: [...newCustomerExp.hardCopyTab.actions],
                    },
                    reRoutedTab: {
                        ...newCustomerExp.reRoutedTab,
                        actions: [...newCustomerExp.reRoutedTab.actions],
                    },
                    historyTab: {
                        ...newCustomerExp.historyTab,
                        actions: [...newCustomerExp.historyTab.actions],
                    },
                });

                const newAdvisers = setInitialData(
                    permission.adviser,
                    advisers,
                    info.grant,
                    'adviser',
                ) as IAdviserSection;
                setAdvisers(newAdvisers);
                setOldAdvisers({
                    permissionLevel: newAdvisers.permissionLevel,
                    isAll: newAdvisers.isAll,
                    advisersTab: {
                        ...newAdvisers.advisersTab,
                        actions: [...newAdvisers.advisersTab.actions],
                    },
                    investorsTab: {
                        ...newAdvisers.investorsTab,
                        actions: [...newAdvisers.investorsTab.actions],
                    },
                });
            } else {
                const newHqLoginAccess = setInitialData(
                    permission.accountManagement,
                    hqLoginAccess,
                    info.grant,
                    'accountManagement',
                ) as ILoginRoleSection;
                setHqLoginAccess(newHqLoginAccess);
                setOldHqLoginAccess({
                    permissionLevel: newHqLoginAccess.permissionLevel,
                    isAll: newHqLoginAccess.isAll,
                    inbox: {
                        ...newHqLoginAccess.inbox,
                        actions: [...newHqLoginAccess.inbox.actions],
                    },
                    login: {
                        ...newHqLoginAccess.login,
                        actions: [...newHqLoginAccess.login.actions],
                    },
                    profile: {
                        ...newHqLoginAccess.profile,
                        actions: [...newHqLoginAccess.profile.actions],
                    },
                });

                const newHqUserManagement = setInitialData(
                    permission.userManagement,
                    userManagement,
                    info.grant,
                    'userManagement',
                ) as IUserManagementSection;
                setUserManagement(newHqUserManagement);
                setOldUserManagement({
                    permissionLevel: newHqUserManagement.permissionLevel,
                    isAll: newHqUserManagement.isAll,
                    rolesPermissionTab: {
                        ...newHqUserManagement.rolesPermissionTab,
                        actions: [...newHqUserManagement.rolesPermissionTab.actions],
                    },
                    userGroupTab: {
                        ...newHqUserManagement.userGroupTab,
                        actions: [...newHqUserManagement.userGroupTab.actions],
                    },
                    userTab: {
                        ...newHqUserManagement.userTab,
                        actions: [...newHqUserManagement.userTab.actions],
                    },
                });

                const newHqActivityLogs = setInitialData(
                    permission.activityLogs,
                    activityLogs,
                    info.grant,
                    'activityLogs',
                ) as IActivityLogsSection;
                setActivityLogs(newHqActivityLogs);
                setOldActivityLogs({
                    permissionLevel: newHqActivityLogs.permissionLevel,
                    isAll: newHqActivityLogs.isAll,
                    reportsTab: {
                        ...newHqActivityLogs.reportsTab,
                        actions: [...newHqActivityLogs.reportsTab.actions],
                    },
                    userActivityTab: {
                        ...newHqActivityLogs.userActivityTab,
                        actions: [...newHqActivityLogs.userActivityTab.actions],
                    },
                });

                const newSystemSettings = setInitialData(
                    permission.systemSettings,
                    systemSettings,
                    info.grant,
                    'systemSettings',
                ) as ISystemSettingsSection;
                setSystemSettings(newSystemSettings);
                setOldSystemSettings({
                    permissionLevel: newSystemSettings.permissionLevel,
                    isAll: newSystemSettings.isAll,
                    epfTab: {
                        ...newSystemSettings.epfTab,
                        actions: [...newSystemSettings.epfTab.actions],
                    },
                });

                const newHqCustomerExpDashboard = setInitialData(
                    permission.ceDashboard,
                    customerExpDashboard,
                    info.grant,
                    'ceDashboard',
                ) as ICustomerExperienceSection;
                setCustomerExpDashboard(newHqCustomerExpDashboard);
                setOldCustomerExpDashboard({
                    permissionLevel: newHqCustomerExpDashboard.permissionLevel,
                    isAll: newHqCustomerExpDashboard.isAll,
                    allBranch: {
                        ...newHqCustomerExpDashboard.allBranch,
                        actions: [...newHqCustomerExpDashboard.allBranch.actions],
                    },
                    dueDateTab: {
                        ...newHqCustomerExpDashboard.dueDateTab,
                        actions: [...newHqCustomerExpDashboard.dueDateTab.actions],
                    },
                    hardCopyTab: {
                        ...newHqCustomerExpDashboard.hardCopyTab,
                        actions: [...newHqCustomerExpDashboard.hardCopyTab.actions],
                    },
                    historyTab: {
                        ...newHqCustomerExpDashboard.historyTab,
                        actions: [...newHqCustomerExpDashboard.historyTab.actions],
                    },
                    pendingTab: {
                        ...newHqCustomerExpDashboard.pendingTab,
                        actions: [...newHqCustomerExpDashboard.pendingTab.actions],
                    },
                    overview: {
                        ...newHqCustomerExpDashboard.overview,
                        actions: [...newHqCustomerExpDashboard.overview.actions],
                    },
                    reRoutedTab: {
                        ...newHqCustomerExpDashboard.reRoutedTab,
                        actions: [...newHqCustomerExpDashboard.reRoutedTab.actions],
                    },
                    upcomingTab: {
                        ...newHqCustomerExpDashboard.upcomingTab,
                        actions: [...newHqCustomerExpDashboard.upcomingTab.actions],
                    },
                });

                const newBranchOffices = setInitialData(
                    permission.branchOffices,
                    branchOffices,
                    info.grant,
                    'branchOffices',
                ) as IBranchOfficeSection;
                setBranchOffices(newBranchOffices);
                setOldBranchOffices({
                    permissionLevel: newBranchOffices.permissionLevel,
                    isAll: newBranchOffices.isAll,
                    advisersTab: {
                        ...newBranchOffices.advisersTab,
                        actions: [...newBranchOffices.advisersTab.actions],
                    },
                    allBranchTab: {
                        ...newBranchOffices.allBranchTab,
                        actions: [...newBranchOffices.allBranchTab.actions],
                    },
                    investorsTab: {
                        ...newBranchOffices.investorsTab,
                        actions: [...newBranchOffices.investorsTab.actions],
                    },
                });
                const newProductSettings = setInitialData(
                    permission.productSettings,
                    productSettings,
                    info.grant,
                    'productSettings',
                ) as IProductSettings;
                setProductSettings(newProductSettings);
                setOldProductSettings({
                    permissionLevel: newProductSettings.permissionLevel,
                    isAll: newProductSettings.isAll,
                    allFundsTab: {
                        ...newProductSettings.allFundsTab,
                        actions: [...newProductSettings.allFundsTab.actions],
                    },
                    allAmpTab: {
                        ...newProductSettings.allAmpTab,
                        actions: [...newProductSettings.allAmpTab.actions],
                    },
                    navPerUnitTab: {
                        ...newProductSettings.navPerUnitTab,
                        actions: [...newProductSettings.navPerUnitTab.actions],
                    },
                    distributionTab: {
                        ...newProductSettings.distributionTab,
                        actions: [...newProductSettings.distributionTab.actions],
                    },
                    documentsTab: {
                        ...newProductSettings.documentsTab,
                        actions: [...newProductSettings.documentsTab.actions],
                    },
                });
                const newFundUploads = setInitialData(
                    permission.uploads,
                    fundUploads,
                    info.grant,
                    'uploads',
                ) as IFundUploads;
                setFundUploads(newFundUploads);
                setOldFundUploads({
                    permissionLevel: newFundUploads.permissionLevel,
                    isAll: newFundUploads.isAll,
                    masterFundTab: {
                        ...newFundUploads.masterFundTab,
                        actions: [...newFundUploads.masterFundTab.actions],
                    },
                    navPerUnitTab: {
                        ...newFundUploads.navPerUnitTab,
                        actions: [...newFundUploads.navPerUnitTab.actions],
                    },
                    distributionTab: {
                        ...newFundUploads.distributionTab,
                        actions: [...newFundUploads.distributionTab.actions],
                    },
                    documentsTab: {
                        ...newFundUploads.documentsTab,
                        actions: [...newFundUploads.documentsTab.actions],
                    },
                });
                const newEddCase = setInitialData(
                    permission.eddCase,
                    eddCase,
                    info.grant,
                    'eddCase',
                ) as IEDDCasesSection;
                setEddCase(newEddCase);
                setOldEddCase({
                    permissionLevel: newEddCase.permissionLevel,
                    isAll: newEddCase.isAll,
                    historyTab: {
                        ...newEddCase.historyTab,
                        actions: [...newEddCase.historyTab.actions],
                    },
                    newTab: {
                        ...newEddCase.newTab,
                        actions: [...newEddCase.newTab.actions],
                        reviewApproval: [...newEddCase.newTab.reviewApproval],
                    },
                    reRoutedTab: {
                        ...newEddCase.newTab,
                        actions: [...newEddCase.reRoutedTab.actions],
                        reviewApproval: [...newEddCase.reRoutedTab.reviewApproval],
                    },
                });
            }
            setLoading(false);
            setModified(1);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Roles and Permissions Data',
                testId: 'edit-roles-error-modal',
            });
            handleErrorHandler();
        }
    };

    useEffect(() => {
        if (locationState.reject === true) {
            setRejectRemarks(true);
        }
        getRoleData();
    }, []);

    const getModalText = () => {
        const textValues = {
            approvedTitle: '',
            approvedSubtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_HAS_BEEN_UPDATED_SUCCESSFULLY,
            rejectedTitle: '',
            rejectedSubtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED,
        };
        switch (source) {
            case 'createRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_REJECTED;
                break;
            case 'editRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_REJECTED;
                break;
            case 'suspendRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_REJECTED;
                break;
            case 'enableRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_REJECTED;
                break;
            case 'deleteRole':
                textValues.approvedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_APPROVED;
                textValues.approvedSubtitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_APPROVED_SUB_UPDATED;
                textValues.rejectedTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_REJECTED;
                break;
        }
        return textValues;
    };

    const modalTextValues = getModalText();
    const modalIcon =
        approval === true || canEditUserRoleDetails !== 'maker' ? 'user-modal-success' : 'user-modal-processing';
    const modalText =
        approval === true
            ? modalTextValues.approvedTitle
            : canEditUserRoleDetails === 'maker'
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_SUBMITTED
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_UPDATED;
    const modalTextRemarks = rejectRemarks === true ? modalTextValues.rejectedTitle : modalText;

    const approvedModalSub = modalTextValues.approvedSubtitle;
    const modalSubText =
        approval === true || canEditUserRoleDetails !== 'maker'
            ? approvedModalSub
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
    const modalSubTextRemarks = rejectRemarks === true ? SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED : modalSubText;
    const bannerSecondaryText = `${SYSTEM_ADMIN.LABEL_REQUESTED_BY} ${author}.`;
    const header =
        approval === true
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_EDIT_ROLE_REQUEST
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE;
    const subHeader1 =
        approval === true
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_ROLE_REQUEST_SUB_1
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_1;
    const subHeader2 =
        approval === true
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_ROLE_REQUEST_SUB_2
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_2;

    const editModalText = `${SYSTEM_ADMIN.LABEL_EDIT} ${roleName}?`;

    return (
        <Container>
            <Loader loading={loading}>
                {rejectRemarks === false ? (
                    <Fragment>
                        <div>
                            <PreviousPage
                                backIcon={true}
                                title={header}
                                handleBackFunction={handleBack}
                                subTitle={subHeader1}
                                secondSubtitle={subHeader2}
                            />

                            <div style={{ paddingLeft: '3rem' }}>
                                <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DETAILS}</Fs18BoldPrimaryBlack>
                                <CustomSpacer space={'1.5rem'} />
                                <FlexedDiv direction="row">
                                    <FlexedDiv direction="column" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                            {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_NAME}
                                        </Fs12BoldPrimaryBlack>
                                        <BasicInput
                                            disabled
                                            value={roleName}
                                            maxLength={255}
                                            onInput={handleRoleName}
                                        />
                                    </FlexedDiv>
                                    <CustomSpacer horizontal={true} space={'4rem'} />
                                    <FlexedDiv direction="column">
                                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                            {SYSTEM_ADMIN.ADD_ROLE.LABEL_STATUS}
                                        </Fs12BoldPrimaryBlack>
                                        {approval === true ? (
                                            <DropdownBase>
                                                <BasicInputDropdown value={status} />
                                                <IcoMoon name="caret-down" size={'1.5rem'} />
                                            </DropdownBase>
                                        ) : (
                                            <>
                                                <SelectWrapper>
                                                    <SelectDropdown
                                                        onChange={handleStatus}
                                                        options={[
                                                            { label: LABEL.active, value: 'Active' },
                                                            { label: LABEL.suspended, value: 'Suspended' },
                                                        ]}
                                                        testId={`department-dropdown`}
                                                        selectedValue={status}
                                                        fontWeight={700}
                                                    />
                                                </SelectWrapper>
                                            </>
                                        )}
                                    </FlexedDiv>
                                </FlexedDiv>
                                <CustomSpacer space={'2rem'} />

                                <TextArea
                                    handleInput={handleRoleDescription}
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DESCRIPTION}
                                    maxLength={255}
                                    style={{ width: '784px' }}
                                    value={roleDescription}
                                    disabled={approval}
                                />
                                <CustomSpacer space={'0.5rem'} />
                            </div>
                            <div style={{ backgroundColor: '#EAEBEE', height: '1px' }} />
                            <CustomSpacer space={'2rem'} />
                            <div style={{ paddingLeft: '3rem' }}>
                                <Fs18BoldPrimaryBlack>
                                    {SYSTEM_ADMIN.ADD_ROLE.LABEL_GRANT_PRIVILEGES}
                                </Fs18BoldPrimaryBlack>
                                <CustomSpacer space={'1.75rem'} />
                                <DisabledView>
                                    <RadioButton
                                        label={SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_BACKOFFICE}
                                        selected={privilege === 'branch'}
                                        setSelected={handleBranchPrivilege}
                                    />
                                    <CustomSpacer horizontal space={'18rem'} />
                                    <RadioButton
                                        label={SYSTEM_ADMIN.ADD_ROLE.LABEL_HQ_BACKOFFICE}
                                        selected={privilege === 'hq'}
                                        setSelected={handleHqPrivilege}
                                    />
                                </DisabledView>
                                {privilege === 'branch' ? (
                                    <Fragment>
                                        <CustomSpacer space={'1.75rem'} />
                                        <div
                                            style={{ backgroundColor: '#EAEBEE', height: '1px', marginRight: '8.5rem' }}
                                        />
                                        <CustomSpacer space={'1.5rem'} />
                                        <BranchPrivileges {...branchProps} />
                                    </Fragment>
                                ) : null}
                                {privilege === 'hq' ? (
                                    <Fragment>
                                        <CustomSpacer space={'1.75rem'} />
                                        <div
                                            style={{ backgroundColor: '#EAEBEE', height: '1px', marginRight: '8.5rem' }}
                                        />
                                        <CustomSpacer space={'1.5rem'} />
                                        <HqPrivileges {...hqProps} />
                                    </Fragment>
                                ) : null}
                                {approval === false ? (
                                    <Fragment>
                                        <CustomSpacer space={'3.5rem'} />
                                        <FlexedDiv>
                                            <CustomButton
                                                primary={false}
                                                onClick={handleCancel}
                                                style={{ width: '16.67vw', padding: '14px 0' }}
                                            >
                                                <Fs15BoldPrimaryBlack>
                                                    {SYSTEM_ADMIN.BUTTON_CANCEL}
                                                </Fs15BoldPrimaryBlack>
                                            </CustomButton>
                                            <CustomSpacer horizontal={true} space={'1rem'} />
                                            <CustomButton
                                                disabled={disabled}
                                                primary={true}
                                                onClick={handleSave}
                                                style={{ width: '16.67vw', padding: '14px 0' }}
                                            >
                                                <Fs15BoldNeutralWhite>{SYSTEM_ADMIN.BUTTON_SAVE}</Fs15BoldNeutralWhite>
                                            </CustomButton>
                                        </FlexedDiv>
                                    </Fragment>
                                ) : null}
                                <CustomSpacer space={'1.5rem'} />
                            </div>
                        </div>
                        {locationState.approval === true && checkApprove() === true ? (
                            <Fragment>
                                <CustomSpacer space={'6.5rem'} />
                                <Banner
                                    title={SYSTEM_ADMIN.LABEL_PENDING_APPROVAL}
                                    description={bannerSecondaryText}
                                    toggle={true}
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    handleClose={() => {}}
                                    testId="editroles-banner"
                                    primaryButtonObject={{
                                        handlePrimaryBtn: handleApprove,
                                        label: LABEL.approve,
                                    }}
                                    secondaryButtonObject={{
                                        handleSecondaryBtn: handleReject,
                                        label: LABEL.reject,
                                    }}
                                />
                            </Fragment>
                        ) : null}
                    </Fragment>
                ) : (
                    <RejectRemarks
                        setRejectRemarks={setRejectRemarks}
                        remarks={remarks}
                        setRemarks={setRemarks}
                        setShowModal={handleRejectApi}
                        showModal={showModal}
                    />
                )}
            </Loader>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTextRemarks}
                    primaryBtn={{
                        onClick: () => {
                            history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="add-roles-modal"
                    icon={modalIcon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubTextRemarks}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {editModal ? (
                <Modal
                    modalActive={editModal}
                    setModalActive={setEditModal}
                    title={editModalText}
                    primaryBtn={{
                        onClick: () => {
                            handleEditRole();
                        },
                        label: SYSTEM_ADMIN.BUTTON_YES,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            setEditModal(false);
                            history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
                        },
                        label: SYSTEM_ADMIN.BUTTON_CANCEL,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="left"
                    testId="edit-roles-confirmation-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'left' }}>
                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_MODAL}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    overflow: hidden;
    position: relative;
`;

const DisabledView = styled.div`
    opacity: 0.5;
    pointer-events: none;
    display: flex;
`;

const BasicInput = styled.input.attrs({ type: 'input' })`
    background-color: white;
    border-color: #c9c9cd;
    border-width: 0.0625rem;
    border: 1px solid #c9c9cd;
    border-radius: 24px;
    height: 2.5rem;
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
    width: 22.5rem;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

export const BasicInputDropdown = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 18.5rem;
`;

export const BasicTextArea = styled.textarea`
    height: 7.5rem;
    border-radius: 8px;
    width: 22.5rem;
    border: 1px solid #c9c9cd;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;
const SelectWrapper = styled.div`
    margin-right: 1vw;
    width: 360px;
    height: 4.44vh;
    margin-top: -7px;
`;
export default EditRoles;
