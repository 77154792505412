import React, { Fragment, useState } from 'react';
import { CustomSpacer, FlexedDiv, Modal } from '../../components';
import { Fs16SemiBoldSecPrimaryBlack, LABEL } from '../../constants';
import ErrorHandlingContext from './ErrorHandlingContext';

interface ErrorHandlingProviderProps {
    children: React.ReactNode;
}

export const ErrorHandlingProvider: React.FC<ErrorHandlingProviderProps> = ({
    children,
}: ErrorHandlingProviderProps) => {
    // Error handling
    const errorHandlerInitial: IErrorHandling = {
        errorCode: '',
        title: '',
        message: '',
    };
    const [errorHandling, setErrorHandling] = useState<boolean>(false);
    const handleErrorHandler = () => {
        setErrorHandling(!errorHandling);
    };
    const [errorMessage, setErrorMessage] = useState<IErrorHandling>(errorHandlerInitial);

    const ProviderValue: IErrorHandlingContext = {
        errorMessage,
        setErrorMessage,
        errorHandling,
        handleErrorHandler,
    };

    return (
        <ErrorHandlingContext.Provider value={ProviderValue}>
            {children}
            {/* Modal for Error Handling */}
            {errorHandling && (
                <Modal
                    title={errorMessage.title}
                    modalActive={errorHandling}
                    setModalActive={setErrorHandling}
                    primaryBtn={{
                        onClick: () => {
                            setErrorHandling(false);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon={errorMessage.icon !== undefined ? errorMessage.icon : 'warning'}
                    contentAlignment="center"
                    testId={errorMessage.testId || ''}
                >
                    <FlexedDiv
                        key={`${errorMessage.testId}-error-list`}
                        direction="column"
                        style={{ textAlign: 'center' }}
                    >
                        {`${errorMessage.errorCode !== undefined ? errorMessage.errorCode : ''} ${
                            errorMessage.message
                        }`}
                    </FlexedDiv>
                    {errorMessage.errorList !== undefined
                        ? errorMessage.errorList.map((item: string) => {
                              return (
                                  <Fragment key={`${errorMessage.testId}-error-list`}>
                                      <CustomSpacer space={'0.5rem'} />
                                      <Fs16SemiBoldSecPrimaryBlack style={{ textAlign: 'center' }}>
                                          {item}
                                      </Fs16SemiBoldSecPrimaryBlack>
                                  </Fragment>
                              );
                          })
                        : null}
                </Modal>
            )}
        </ErrorHandlingContext.Provider>
    );
};
