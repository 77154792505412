import React, { ChangeEvent, Fragment, FunctionComponent } from 'react';

import { CustomButton, CustomSpacer, FlexedDiv, TextArea } from '../../../../components';
import {
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    Fs16SemiBoldSecPrimaryBlack,
    Fs24BoldPrimaryBlack,
    SYSTEM_ADMIN,
    LABEL,
} from '../../../../constants';

export interface RejectRemarksProps {
    remarks: string;
    setRejectRemarks?: (toggle: boolean) => void;
    setRemarks: (text: string) => void;
    setShowModal: (toggle: boolean) => void;
    showModal: boolean;
}

export const RejectRemarks: FunctionComponent<RejectRemarksProps> = ({
    remarks,
    setRejectRemarks,
    setRemarks,
    setShowModal,
}: RejectRemarksProps) => {
    const handleRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };

    const handleSave = () => {
        setShowModal(true);
    };

    const clearStateOnReject = () => {
        if (remarks !== null) {
            setRemarks('');
        }
        if (setRejectRemarks !== undefined) {
            setRejectRemarks(false);
        }
        // history.goBack()
    };

    const disabled = remarks.trim().length === 0;
    return (
        <Fragment>
            <FlexedDiv direction="row">
                <CustomSpacer horizontal={true} space={'3rem'} />
                <FlexedDiv direction="column">
                    <Fs24BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS}</Fs24BoldPrimaryBlack>
                    <CustomSpacer space={'0.5rem'} />
                    <Fs16SemiBoldSecPrimaryBlack>
                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS_SUB}
                    </Fs16SemiBoldSecPrimaryBlack>
                    <CustomSpacer space={'2rem'} />
                    <TextArea
                        handleInput={handleRemarks}
                        label={LABEL.remarks}
                        maxLength={255}
                        name="rejectRemark"
                        style={{ width: '38.89vw' }}
                        value={remarks}
                    />
                    <CustomSpacer space={'3.5rem'} />
                    <FlexedDiv>
                        <CustomButton
                            primary={false}
                            onClick={() => clearStateOnReject()}
                            style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                        >
                            <Fs15BoldPrimaryBlack>{SYSTEM_ADMIN.BUTTON_CANCEL}</Fs15BoldPrimaryBlack>
                        </CustomButton>
                        <CustomSpacer horizontal={true} space={'1rem'} />
                        <CustomButton
                            disabled={disabled}
                            primary={true}
                            onClick={handleSave}
                            style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                        >
                            <Fs15BoldNeutralWhite>{SYSTEM_ADMIN.BUTTON_SAVE}</Fs15BoldNeutralWhite>
                        </CustomButton>
                    </FlexedDiv>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};
