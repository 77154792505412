/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL, EMPTY_STATES, EDD } from '../../../../constants';
import { statusStyle } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { HistoryActions } from '.';
import { AdvanceTable, Modal } from '../../../../components/organisms';
import { ROUTES } from '../../../DashboardBranch';
import { eddReportsPdfMutation } from '../../../../_graphql/mutations/edd/reportPdf/reportPdf';

import { IEddHistoryTab } from '../../../../utils/permissionTypes';
import { HistoryDateHeader } from './HistoryDateHeader';

import * as STYLES from '../../../../styles';
import WebSocketContext from '../../../../context/WebSocketContext';
import AuthContext from '../../../../context/AuthContext/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import CustomItem from './CustomItem';
import eddDashboard from '../../../../context/EDDContext/EDDContext';
interface IHistoryProps {
    filters: ISort_FilterInput;
    historyTab: IEddHistoryTab;
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    lastUpdatedDropdown: string;
    searchInput?: string;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    setSortInput: (data: ISort_FilterInput) => void;
    sortInput: ISort_FilterInput;
    setDownloadDelay: (index: boolean) => void;
}
export const HistoryEDD: React.FC<IHistoryProps> = ({
    filters,
    historyTab,
    isFilterApplied,
    isSearchResult,
    lastUpdatedDropdown,
    searchInput,
    setFilters,
    setLastUpdatedDropdown,
    setSortInput,
    sortInput,
    setDownloadDelay,
}: IHistoryProps) => {
    const { closedCases, dateFilter, setDateFilter } = useContext(eddDashboard);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const { ws } = useContext(WebSocketContext);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [downloadLink, setDownloadLink] = React.useState<any>();
    const history = useHistory();

    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortInput.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    /*** @websocketFn to query BE for the download link */
    const eddReportWebsocket = async (caseId: string) => {
        setDownloadDelay(true);
        if (ws !== undefined) {
            downloadReportPdf(caseId);
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);
                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;
                        if (result.link !== null) {
                            setDownloadLink(result.link);
                            setTimeout(() => {
                                setDownloadDelay(false);
                                setShowModal(!showModal);
                            }, 500);
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;
                        setDownloadDelay(false);
                        handleErrorHandler();
                        setErrorMessage({
                            ...errorMessage,
                            message: error.message,
                            errorCode: error.errorCode,
                            title: 'Cannot download a closed report',
                            testId: 'edd-error-modal',
                        });
                    }
                }
            });
        }
    };

    const downloadReportPdf = async (caseId: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(eddReportsPdfMutation, {
                    input: {
                        caseId: caseId,
                        tab: 'close',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.eddReportsPdf;
            if (resultCheck.error !== null) throw resultCheck.error;

            const { result } = response.data.eddReportsPdf.data;
            if (result.status === true) {
                // console.log('api');
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot download a closed report',
                testId: 'edd-error-modal',
            });
        }
    };
    const handleDownloadReportPDF = (item: string) => {
        ws !== undefined ? ws.send('Ping') : null;
        eddReportWebsocket(item);
    };
    const getDateKey = (label: string) => {
        let currentKey = 'lastUpdated';
        switch (label) {
            case LABEL.closedOn:
                currentKey = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentKey = 'createdOn';
                break;
            case LABEL.submittedOn:
                currentKey = 'submittedOn';
                break;
        }
        return currentKey;
    };
    const columnsAllCases: ITableColumn[] = [
        {
            key: [{ key: 'caseNo', textStyle: { fontWeight: sortInput[0].column === 'caseNo' ? 700 : 400 } }],

            viewStyle: {
                width: STYLES.sh112,
                justifyContent: 'center',
            },
            title: LABEL.eddCaseID,
            icon: {
                name: handleSortIcon('caseNo'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('caseNo'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'caseNo' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'clientName' }, { key: 'clientIdNum', textStyle: STYLES.subTitleStyle }],
            viewStyle: {
                width: STYLES.sh184,
            },
            title: LABEL.investorName,
            subtitle: LABEL.idNumber,
            icon: {
                name: handleSortIcon('clientName'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('clientName'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'clientName' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'accountNo', textStyle: { fontWeight: sortInput[0].column === 'accountNo' ? 700 : 400 } }],
            viewStyle: {
                width: STYLES.sh104,
            },
            title: LABEL.accountNumber,
            icon: {
                name: handleSortIcon('accountNo'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('accountNo'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'accountNo' ? 700 : 400,
            },
        },
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortInput[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: STYLES.subTitleStyle },
            ],
            viewStyle: {
                width: STYLES.sh128,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('bdmName'),
                size: '1rem',
            },
            key: [
                { key: 'bdmName', textStyle: { fontWeight: sortInput[0].column === 'bdmName' ? 700 : 400 } },
                { key: 'bdmCode', textStyle: STYLES.subTitleStyle },
            ],
            viewStyle: {
                width: STYLES.sh128,
            },
            title: LABEL.bdmName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('bdmName'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'bdmName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: getDateKey(lastUpdatedDropdown) }],
            customItem: true,
            viewStyle: {
                width: STYLES.sh104,
            },
            title: lastUpdatedDropdown,
            titleStyle: {
                fontWeight: sortInput[0].column === getDateKey(lastUpdatedDropdown) ? 700 : 400,
            },
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: 16,
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: STYLES.sh176,
            },
            title: LABEL.status,
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'status' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: {
                width: STYLES.sh64,
                justifyContent: 'center',
                paddingLeft: 0,
            },
            title: LABEL.remarks,
            // subtitle: LABEL.code,
            customItem: true,
        },
        {
            itemIcon: {
                name: historyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            viewStyle: {
                width: STYLES.sh48,
            },
            title: LABEL.view,
            key: [],
            onClickItem:
                historyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => {
                          sessionStorage.setItem('caseId', item.rawData.caseId);
                          history.push(ROUTES.cancelledSatisfactoryCase, { tab: 'close' });
                      }
                    : undefined,
        },
    ];
    const handleSort = (keyName: string) => {
        const tempSort = [...sortInput];
        sortInput.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortInput(tempSort);
    };
    const handleDateFilter = (item: string, value: string) => {
        setLastUpdatedDropdown(item);
        const temp = [...filters];
        let currentFilter = 'lastUpdated';
        currentFilter = getDateKey(item);
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        temp[0] = tempObj;
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortInput([{ column: currentFilter, value: value }]);
        setFilters(temp);
    };
    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <HistoryDateHeader
                {...menu}
                dropdownData={[
                    lastUpdatedDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortInput[0].column === getDateKey(lastUpdatedDropdown) ? sortInput[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };
    return (
        <Fragment>
            <AdvanceTable
                data={closedCases}
                columns={columnsAllCases}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortInput[0].column} {...props} />}
                RenderOptions={
                    historyTab.actions.canDownloadEddReport === LABEL.maker
                        ? (props: ITableOptions) => (
                              <HistoryActions {...props} handleDownloadReportPDF={handleDownloadReportPDF} />
                          )
                        : undefined
                }
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableAllCases
                }
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                actionsLabel={LABEL.download}
            />
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={EDD.LABEL_EDD_MODAL_DOWNLOAD_SUCCESS}
                    icon={'Illustration'}
                    primaryBtn={{
                        onClick: () => {
                            window.open(downloadLink, '_blank');
                            setShowModal(false);
                            setDownloadDelay(false);
                            history.push(ROUTES.amlaEDDManagement, { tab: 'History', refresh: 'refresh' });
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="edd-monthly-report"
                    contentAlignment="center"
                ></Modal>
            ) : null}
        </Fragment>
    );
};

export default HistoryEDD;
