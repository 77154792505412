import React from 'react';
import { TextWhite } from '../../../constants';
import { FlexedDiv } from '../..';

import styled from 'styled-components';

interface IRegularTooltipTemplateProps {
    message: ITooltip[];
    remarksPrefix?: boolean;
}

export const RegulartTooltipTemplate = ({ message, remarksPrefix }: IRegularTooltipTemplateProps): JSX.Element => {
    return (
        <div>
            {message &&
                message.map((item, index) => (
                    <FlexedDiv
                        key={index}
                        data-testid="parent_container"
                        style={{ lineHeight: '1.5rem', flexDirection: 'column' }}
                    >
                        <div data-testid="message">
                            <TextWhite size="12px" style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}>
                                {item.label}
                            </TextWhite>
                        </div>
                        {item.remarks !== undefined ? (
                            <div data-testid="remarks">
                                {remarksPrefix === true ? <div>Remarks:&nbsp;</div> : null}
                                <TextWhite size="12px" style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}>
                                    {item.remarks}
                                </TextWhite>
                            </div>
                        ) : null}

                        {item.content !== undefined ? (
                            <UL>
                                <CustomSpace />
                                {item.content.map((content) => {
                                    return (
                                        <li
                                            key={`remarks${index}_${item.label}${content}`}
                                            style={{ listStyle: 'disc' }}
                                        >
                                            <Content>{content}</Content>
                                        </li>
                                    );
                                })}
                            </UL>
                        ) : null}
                    </FlexedDiv>
                ))}
        </div>
    );
};

const Content = styled.div`
    flex-grow: 1;
    font-weight: normal;
    font-size: 0.625rem;
    line-height: 0.8rem;
    word-break: break-word;
    white-space: break-spaces;
    margin-top: -10px;
`;

const UL = styled.ul`
    padding-left: 1rem;
`;
const CustomSpace = styled.div`
    margin-bottom: 3px;
`;
