/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, ReactText, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import MultiSelectV2 from '../../../../components/molecules/MultiSelect';
import createGroup from '../../../../_graphql/mutations/systemAdmin/createGroup';
import { validateRolesDropdown } from '../../../../_graphql/mutations/systemAdmin/validateRolesDropdown';
import {
    isAdminLevel,
    isBranchLevel,
    isHqLevel,
    isOnlyAccountManagement,
    isOnlyFund,
    permissionKeyLabels,
} from '../permissionKeys';
import FlexSpacer, {
    CustomButton,
    FlexedDiv,
    TextArea,
    PreviousPage,
    SelectDropdown,
    Modal,
} from '../../../../components';
import { CustomInput } from '../../../../components/atoms/Input';
import { LABEL, Fs16RegSecPrimaryBlack, Fs16SemiBoldSecPrimaryBlack } from '../../../../constants';
import { TextBold18, TextBold12 } from '../../../../constants/textStyles';
import { IValidation } from '../../../../interfaces/IValidation';
import { sh16, sh32, sh240, sh56, sh24, sh64, sh784 } from '../../../../styles';
import { Validate } from '../../../../validation/validate';
import { IUserManagementUserGroupTab } from '../../../../utils/permissionTypes';

import styled from 'styled-components';
import * as ROUTES from '../../../../routes';
import AuthContext from '../../../../context/AuthContext';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface addUserGroupProps {
    name: string;
    status: string;
    description: string;
    userRoles: string[];
    branchOffices: string[];
}

const initialUserGroup: addUserGroupProps = {
    name: '',
    status: 'Pending',
    description: '',
    userRoles: [],
    branchOffices: [],
};
export type IBranchDropDownBehavior = {
    type: string;
    disabled: boolean;
};
const initialBehavior: IBranchDropDownBehavior = {
    type: permissionKeyLabels.normalHQ,
    disabled: false,
};
const AddUserGroup = (): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { getDropdown, branchOptions, roleOptions } = useContext(SystemAdminContext);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission = parsedPermission.hq.permission.userManagement
        .userGroupTab as IUserManagementUserGroupTab;

    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string>('');
    const [userGroupData, setUserGroupData] = useState({ ...initialUserGroup });
    const [selectedRoles, setSelectedRoles] = useState<ReactText[]>([]);
    const [selectedBranches, setSelectedBranches] = useState<ReactText[]>([]);
    const [branchDropdownBehavior, setBranchDropdownBehavior] = useState<IBranchDropDownBehavior>(initialBehavior);
    const [errorMessages, setErrorMessages] = useState<string>('');
    const [validationModal, setValidationModal] = useState(false);
    const [validationMsg, setValidationMsg] = useState<string>('');

    const getSelectedList = (list: Array<ReactText>, id: number) => {
        switch (id) {
            case 1: {
                validateRoles(list);
                break;
            }
            case 2: {
                setSelectedBranches(list);
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleModal = () => {
        const data = { tab: 'User Groups' };
        history.push(ROUTES.dashboardSystemAdmin, data);
        setShowModal(false);
    };

    const validateRoles = async (roles: Array<ReactText>) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(validateRolesDropdown, {
                    input: {
                        roles: roles,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.validateDropdown;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.validateDropdown.data;

            setSelectedRoles(roles);
            if (isBranchLevel(result.roles) && result.roles.length <= 3) {
                setBranchDropdownBehavior({ ...branchDropdownBehavior, type: permissionKeyLabels.branch });
                setErrorMessages('');
            } else if (isAdminLevel(result.roles) && result.roles.length <= 4) {
                setBranchDropdownBehavior({
                    ...branchDropdownBehavior,
                    type: permissionKeyLabels.admin,
                    disabled: true,
                });
                const temp: string[] = [];
                branchOptions.map((item) => {
                    item.key && temp.push(item.key);
                });
                setSelectedBranches(temp);
                setErrorMessages('');
            } else if (isOnlyFund(result.roles) && result.roles.length <= 3) {
                setBranchDropdownBehavior({
                    ...branchDropdownBehavior,
                    type: permissionKeyLabels.pureFund,
                    disabled: true,
                });
                const temp: string[] = [];
                branchOptions.map((item) => {
                    item.key && temp.push(item.key);
                });
                setSelectedBranches(temp);
                setErrorMessages('');
            } else if (isHqLevel(result.roles)) {
                setBranchDropdownBehavior(initialBehavior);
                setErrorMessages('');
            } else if (isOnlyAccountManagement(result.roles)) {
                setBranchDropdownBehavior(initialBehavior);
                setErrorMessages('');
            } else {
                setErrorMessages('Cannot combine the selected Roles');
                // setSelectedRoles([]);
                setSelectedBranches([]);
                setBranchDropdownBehavior(initialBehavior);
            }
        } catch (error) {
            setSelectedRoles(roles);
            setSelectedBranches([]);
        }
    };

    const runCreateGroup = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(createGroup, {
                    input: {
                        groupName: userGroupData.name.trim(),
                        description: userGroupData.description,
                        roles: selectedRoles,
                        branch: selectedBranches,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.createGroup;

            if (data !== null) {
                if (data.result.status) {
                    setShowModal(true);
                    getDropdown();
                }
            }
            if (error !== null) {
                switch (error.errorCode) {
                    case 'BO489':
                    case 'BO490':
                    case 'BO491':
                    case 'BO492':
                    case 'BO493':
                    case 'BO612':
                        setValidationModal(true);
                        setValidationMsg(error.message);
                        break;
                    case 'BO477':
                        setError(error.message);
                        break;
                    default:
                        setErrorMessage({
                            ...errorMessage,
                            message: error.message,
                            errorCode: error.errorCode,
                            title: 'Cannot Create User Group',
                            testId: 'add-usergroup-other-error-modal',
                        });
                        handleErrorHandler();

                        break;
                }
            }
        } catch (error) {}
    };

    const handleBack = () => {
        const data = { tab: 'User Groups' };
        history.push(ROUTES.dashboardSystemAdmin, data);
    };

    const updateUserGroupData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        const att = e.currentTarget.getAttribute('accept');
        let valmsg = e.currentTarget.getAttribute('itemProp');
        if (valmsg === null) {
            valmsg = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;

        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valmsg);
            if (err.code !== 'NoError') {
                setError(err.message);
                return;
            } else {
                setError('');
            }
        }
        setUserGroupData({ ...userGroupData, [key]: value });
    };
    const updateUserGroupDataSelect = (value: string, field: string) => {
        // field === 'branch'
        //     ? branchOptions.find((item) => {
        //           if (item.key == value) setBranchValue(item.label);
        //       })
        //     : null;
        field === 'branch'
            ? setSelectedBranches([value!.toString()])
            : setUserGroupData({ ...userGroupData, [field]: value });
    };
    useEffect(() => {
        if (branchOptions.length === 0 && roleOptions.length === 0) getDropdown();
    }, []);
    return (
        <Container>
            <Fragment>
                <PreviousPage
                    backIcon={true}
                    title={LABEL.addNewUserGroup}
                    handleBackFunction={handleBack}
                    subTitle={LABEL.addNewUserGroupSubHeading}
                />

                <div style={{ paddingLeft: sh32, width: sh784 }}>
                    <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userGroupDetails}</TextBold18>
                    <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                        <CustomInput
                            label={LABEL.groupName}
                            name="name"
                            style={{ width: '25vw', maxWidth: '720px' }}
                            value={userGroupData.name}
                            inputStyle={{ fontSize: '1rem', fontWeight: 700 }}
                            onChange={(e) => updateUserGroupData(e)}
                            onLostFocus={(e) => updateUserGroupData(e)}
                            validation={JSON.stringify({
                                type: 'alphanumeric',
                                minChars: 1,
                                maxLength: 30,
                            } as IValidation)}
                            validationMessage="User Group Name"
                            errorText={error}
                            canClearContents
                            handleClearContents={() => setUserGroupData({ ...userGroupData, name: '' })}
                        />
                        <FlexSpacer />
                        <FlexedDiv direction="column">
                            <TextBold12 style={{ color: '#333333', lineHeight: '0.5rem' }}>{LABEL.status}</TextBold12>
                            <SelectWrapper>
                                <SelectDropdown
                                    onChange={(value) => updateUserGroupDataSelect(value.toString(), 'status')}
                                    options={[
                                        {
                                            label: userGroupData.status as string,
                                            value: userGroupData.status as string,
                                        },
                                    ]}
                                    testId={`status-dropdown`}
                                    selectedValue={userGroupData.status as string}
                                    isDisabled={true}
                                    placeHolder={LABEL.selectOneBranch}
                                />
                            </SelectWrapper>
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv>
                        <TextArea
                            name="description"
                            label={LABEL.groupDescription}
                            value={userGroupData.description}
                            handleInput={(e) => updateUserGroupData(e)}
                            maxLength={255}
                            style={{ width: '25vw', maxWidth: '720px' }}
                        />
                    </FlexedDiv>
                    <TextBold18 style={{ marginBottom: sh24, marginTop: sh64 }}> {LABEL.grantAccessLevel}</TextBold18>
                    <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                        <MultiSelectV2
                            label={LABEL.assignUserRole}
                            options={roleOptions}
                            placeHolder={LABEL.selectRoles}
                            checkedState={selectedRoles}
                            handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                            selectId={1}
                            noOverlay={false}
                            style={{ width: '25vw', maxWidth: '720px' }}
                            errorMessage={errorMessages}
                        />
                        <FlexSpacer />
                        {branchDropdownBehavior.type === permissionKeyLabels.branch && selectedRoles.length !== 0 ? (
                            <FlexedDiv direction="column">
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.branchOffice}</TextBold12>
                                <SelectWrapper>
                                    <SelectDropdown
                                        onChange={(value) => updateUserGroupDataSelect(value.toString(), 'branch')}
                                        options={branchOptions.map((item) => {
                                            return { label: item.label, value: item.key as string };
                                        })}
                                        testId={`branch-dropdown`}
                                        selectedValue={userGroupData.branchOffices[0] as string}
                                        fontWeight={userGroupData.branchOffices[0] ? 700 : 400}
                                        placeHolder={LABEL.selectOneBranch}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        ) : (
                            <MultiSelectV2
                                label={LABEL.branchOffice}
                                options={branchOptions}
                                placeHolder={LABEL.selectOneOrMultipleBranch}
                                checkedState={selectedBranches}
                                handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                                selectId={2}
                                hasSelectAll
                                noOverlay={false}
                                disabled={branchDropdownBehavior.disabled || selectedRoles.length === 0}
                                selectAllLabel={LABEL.kibAllBranch}
                                style={{ width: '25vw', maxWidth: '720px' }}
                            />
                        )}
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ paddingLeft: sh32 }}>
                        <FlexedDiv alignItems="center" style={{ marginTop: sh56, justifyContent: 'flex-start' }}>
                            <CustomButton
                                onClick={() => history.push(ROUTES.dashboardSystemAdmin)}
                                style={{ width: sh240, height: '40px' }}
                            >
                                {LABEL.cancel}
                            </CustomButton>
                            <CustomButton
                                primary={true}
                                style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                onClick={() => {
                                    runCreateGroup();
                                }}
                                disabled={
                                    selectedRoles.length === 0 ||
                                    selectedBranches.length === 0 ||
                                    userGroupData.name.length === 0 ||
                                    errorMessages !== '' ||
                                    error !== ''
                                }
                            >
                                {LABEL.save}
                            </CustomButton>
                        </FlexedDiv>
                        <div style={{ height: sh56 }} />
                    </FlexedDiv>
                </div>
            </Fragment>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={
                        userManagementPermission.actions.canCreateNewUserGroup === LABEL.maker
                            ? LABEL.newUserGroupSubmitted
                            : LABEL.newUserGroupCreatedAA
                    }
                    primaryBtn={{
                        onClick: () => {
                            handleModal();
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="add-usergroup-modal"
                    icon={
                        userManagementPermission.actions.canCreateNewUserGroup === LABEL.maker
                            ? 'user-modal-processing'
                            : 'user-modal-success'
                    }
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {userManagementPermission.actions.canCreateNewUserGroup === LABEL.maker ? (
                            <Fs16RegSecPrimaryBlack>{LABEL.submittedMsg}</Fs16RegSecPrimaryBlack>
                        ) : (
                            <FlexedDiv>
                                <Fs16SemiBoldSecPrimaryBlack>
                                    {userGroupData.name.trim()}&nbsp;
                                </Fs16SemiBoldSecPrimaryBlack>
                                <Fs16RegSecPrimaryBlack>{` has been created.`}</Fs16RegSecPrimaryBlack>
                            </FlexedDiv>
                        )}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {validationModal ? (
                <Modal
                    modalActive={validationModal}
                    setModalActive={setValidationModal}
                    title={'Unable to create user group'}
                    primaryBtn={{
                        onClick: () => {
                            setValidationModal(false);
                            history.push(ROUTES.dashboardSystemAdmin, { tab: 'User Groups' });
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="add-usergroup-error-modal"
                    icon="suspend-failed"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {validationMsg}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const SelectWrapper = styled.div`
    width: 25vw;
    max-width: 720px;
`;
export default AddUserGroup;
