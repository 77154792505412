import React, { useContext } from 'react';
import { ComponentLoader, PreviousPage, FlexedDiv } from '../../../../../components';
import { EDD, LABEL } from '../../../../../constants';
import EDDContext from '../../../../../context/EDDContext/EDDContext';
import { EddReason } from './EddReason';

export const QuestionTemplate = (): JSX.Element => {
    const { loading } = useContext(EDDContext);
    return (
        <FlexedDiv direction="column">
            <PreviousPage title={EDD.LABEL_ADD_EDD} subTitle={LABEL.eddAddNewCaseSubtitle} />
            <EddReason />
            {loading ? <ComponentLoader /> : null}
        </FlexedDiv>
    );
};

export default QuestionTemplate;
