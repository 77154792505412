import React, { useState, useRef, useContext, useMemo, useCallback } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
//Context
import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

//graphQL
import investorDashboardQuery from '../../_graphql/queries/investors/investorDashboard/investorDashboard';
import InvestorsContext from './InvestorsContext';
import { handleDashbordResults } from '../../utils/handleInvestorData';
import { useDebounce } from '../../customHooks';
import { buildSearchFiltersConfig, IMultiSelectElements } from '../../pages/Investors/dashboardFilterConfig';

interface IInvestorsProviderProps {
    children: React.ReactNode;
}

const InvestorsProvider: React.FC<IInvestorsProviderProps> = ({ children }: IInvestorsProviderProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    //Data states
    const [investorsData, setInvestorsData] = useState<ITableData[]>([]);
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [totalCount, setTotalCount] = useState(0);
    //Filters, sort and search states
    const [filterConfig, setFilterConfig] = useState<IMultiSelectElements[][]>([]);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [filters, setFilters] = useState<IColumnValue[]>([]);
    const [sortData, setSortData] = useState<IColumnValue[]>([{ column: 'clientName', value: 'ascending' }]);

    // custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    //Utility states
    const [loading, setLoading] = useState<boolean>(false);
    const initialRender = useRef<boolean>(true);

    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };
    const memoizedDashboardInput: MemoizedInvestorDashboardInput = useMemo(() => {
        return {
            tab,
            currentPage,
            resultLimit,
            filters,
            sortData,
            debouncedSearchTerm,
        };
    }, [tab, resultLimit, currentPage, filters, sortData, debouncedSearchTerm]);

    //Fn to get investor dashboard data
    const getDashboardCallBack = useCallback(() => {
        console.log('CALL getDashboardCallBack');
        getInvestorDashboardData();
    }, [memoizedDashboardInput]);

    const getInvestorDashboardData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(investorDashboardQuery, {
                    input: {
                        tab: 'daily',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: debouncedSearchTerm,
                        sort: sortData,
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.investorDashboard;
            if (!error) {
                // console.log(data, 'RESPONSE');
                const { pages, investorsDetails, page, investorsCount, filters } = data.result;
                console.log(investorsDetails, 'RESPONSE');
                // currentPage > pages && setCurrentPage(1);
                // const _filters = stylizeText(filters);
                setInvestorsData(handleDashbordResults(investorsDetails));

                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(filters);
                    console.log('BUILD FILTER CONFIG');
                    initialRender.current = false;
                    setFilterConfig(formElements);
                }

                setCurrentPage(page);
                setTotalCount(investorsCount);
                setMaxPages(pages);
                setLoading(false);
            } else {
                setLoading(false);
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Fetch Investors Data',
                    testId: 'investors-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleResultLimit = (limit: number) => {
        setResultLimit(limit);
    };
    const handleSetCurrentPage = (page: number) => {
        setCurrentPage(page);
    };

    const ProviderValue: IInvestorsContext = {
        maxPages,
        memoizedDashboardInput,
        investorsData,
        totalCount,
        searchInput,
        initialRender,
        filterConfig,
        setSearchInput,
        setTab,
        handleResultLimit,
        handleSetCurrentPage,
        getDashboardCallBack,
        setSortData,
        setFilters,
    };

    return <InvestorsContext.Provider value={ProviderValue}>{children}</InvestorsContext.Provider>;
};
export default InvestorsProvider;
