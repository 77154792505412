import React from 'react';
import styled from 'styled-components';
import { FlexedDiv, IndicatorIcon } from '../..';
import { Fs12BoldPrimaryBlack, TextDarkBlack, TextDarkGrey4 } from '../../../constants';

/**
 * Tracking stepper which can have both vertical and horizontal alignment shows a stepper which takes in array of steps
 * 
 * @example
 * const steps =  [
        {
            stepName: 'Pending Due Date',
            finished: false,
            subText: '1/2/3',
        },
        {
            stepName: 'BR-Rerouted',
            finished: true,
            subText: '1/2/3',
        },
        {
            stepName: 'Pending Approval',
            finished: true,
            subText: '1/2/3',
        },
    ]
 * return <TrackingStepper title={'title'} steps={steps}/>
 * "returns stepper of vertical nature with title as title and steps as above, only status for 'Pending Due Date' will be shown"
 * 
 * return <TrackingStepper title={'title'} steps={steps} expanded/>
 * "returns stepper of vertical nature with title as title and steps as above, All statuses will be shown"
 * 
 * return <TrackingStepper title={'title'} steps={steps} horizontal/>
 * "returns stepper of horizontal nature with title as title and steps as above, only status for 'Pending Due Date' will be shown"
 * 
 * return <TrackingStepper title={'title'} steps={steps} horizontal expanded/>
 * "returns stepper of horizontal nature with title as title and steps as above, All statuses will be shown"
 */

export const TrackingStepper = (props: IStepperTimelineProps): JSX.Element => {
    return (
        <StepperContainerDiv>
            {props.title && (
                <TextDarkBlack weight="bold" size="10px" lineHeight={'130%'}>
                    {props.title}
                </TextDarkBlack>
            )}
            <StepperWrapper horizontal={props.horizontal}>
                {props.steps &&
                    props.steps.map((step, index) => {
                        return (
                            (props.expanded || index === 0) && (
                                <StepWrapper key={step.stepName + step.subText + index} horizontal={props.horizontal}>
                                    <StepIndicator horizontal={props.horizontal}>
                                        <IndicatorIcon finished={step.finished} />
                                    </StepIndicator>
                                    <FlexedDiv>
                                        <Fs12BoldPrimaryBlack>{step.stepName}</Fs12BoldPrimaryBlack>
                                    </FlexedDiv>
                                    <TextDarkGrey4 size="10px" lineHeight={'130%'} marginTop={'4px'}>
                                        {step.subText}
                                    </TextDarkGrey4>
                                </StepWrapper>
                            )
                        );
                    })}
            </StepperWrapper>
        </StepperContainerDiv>
    );
};

const StepperContainerDiv = styled.div`
    width: 100%;
    background: #f2f4f6;
    border-radius: 8px;
    padding: 8px 16px;
`;

const StepIndicator = styled.div<IStepperOrientation>`
    position: absolute;
    left: 0.25px;
    top: -0.5px;
    ${(props) => (props.horizontal ? 'transform: translateY(-50%);display:flex;' : '    transform: translateX(-50%);')}
`;

const StepWrapper = styled.div<IStepperOrientation>`
    display: block;
    position: relative;
    ${(props) =>
        props.horizontal
            ? 'border-top: 0.5px dashed #878787;padding-top: 16px;'
            : 'border-left: 0.5px dashed #878787;padding-left: 16px;'}
`;

const StepperWrapper = styled.div<IStepperOrientation>`
    display: flex;
    flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
    margin-left: 8px;
    padding-top: 8px;
    word-break: break-word;
    & ${StepWrapper}:last-child {
        ${(props) => (props.horizontal ? 'border-top: none;' : 'border-left: none;')}
    }
    & ${StepWrapper}:only-child {
        ${(props) => (props.horizontal ? 'border-top: none;' : 'border-left: none;')}
    }
    & ${StepWrapper}:not(last-child) {
        ${(props) => (props.horizontal ? 'padding-right: 16px;' : 'padding-bottom: 16px;')}
    }
    & ${StepWrapper}:only-child ${StepIndicator} {
        left: 0;
        top: 0;
        padding-bottom: 0;
    }
    & ${StepWrapper}:last-child ${StepIndicator} {
        left: 0;
        top: 0;
    }
`;
