import { IClientProfile } from '../OrderDetails/orderSummaryTypes';

export const initialProfile: IClientProfile[] = [
    {
        name: '',
        id: '',
        idProof: '',
        idType: '',
        bankInformation: {
            title: 'Bank Information',
            sections: [
                [
                    {
                        title: ``,
                        titleIcon: 'bank',
                        data: [
                            { label: 'Currency', data: '' },
                            { label: 'Bank Name', data: '' },
                            { label: 'Bank Account Name', data: '' },
                            { label: 'Bank Account No.', data: '' },
                        ],
                    },
                ],
            ],
        },
        crsDeclaration: {
            title: 'Crs Declaration',
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        employmentInformation: {
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        epfDetails: {
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        fatcaDeclaration: {
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        feaDeclaration: {
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        personalDetails: {
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
    },
];
