/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, ReactText, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { approveReject } from '../../../_graphql/mutations/systemAdmin/approveReject';
import { getDropDownList } from '../../../_graphql/queries/common/getDropDownList';
import { getSystemAdminInboxData } from '../../../_graphql/queries/inbox/getSystemAdminInboxData';
import FlexSpacer, {
    Banner,
    ComponentLoader,
    CustomButton,
    FlexedDiv,
    Modal,
    PreviousPage,
    SelectDropdown,
    TextArea,
} from '../../../components';
import { CustomInput } from '../../../components/atoms/Input';
import MultiSelectV2, { IMultiSelectLabel } from '../../../components/molecules/MultiSelect';
import { Fs16RegSecPrimaryBlack, Fs16SemiBoldSecPrimaryBlack, LABEL } from '../../../constants';
import { PageDescription, TextBold12, TextBold18, TextBold24, TextNormal16 } from '../../../constants/textStyles';
import { useComponentLoader } from '../../../customHooks';

import { sh16, sh24, sh240, sh32, sh56, sh64, sh784, sh80 } from '../../../styles';
import { emptyCheck, toTitleCase } from '../../../utils';
import { IUserManagementUserGroupTab } from '../../../utils/permissionTypes';
import { Validate } from '../../../validation/validate';
import { getEditGroupModalMessage, pageTitle } from '../../SystemAdmin/UserGroup/EditUserGroup/pageLabels';

import styled from 'styled-components';
import * as ROUTES from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

interface addUserGroupProps {
    name: string;
    status: string;
    author: string;
    description: string;
    userRoles: ReactText[];
    branchOffices: ReactText[];
    approver?: string;
    remarks?: string;
}
interface IUserGroupViewInboxProps {
    id: string;
}
const initialUserGroup: addUserGroupProps = {
    name: '',
    status: 'Pending',
    author: '',
    description: '',
    userRoles: [],
    branchOffices: [],
    approver: '',
    remarks: '',
};

const UserGroupViewInbox = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [userGroupData, setUserGroupData] = useState({ ...initialUserGroup });
    const [isRejected, setIsRejected] = useState(false);
    const [isRejectedConfirm, setIsRejectedConfirm] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState<string>('');
    const [rejectRemark, setRejectRemark] = useState('');
    const [source, setSource] = useState<string>('');

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission = parsedPermission.hq.permission.userManagement
        .userGroupTab as IUserManagementUserGroupTab;
    const { state } = useLocation<IUserGroupViewInboxProps>();
    const [userType, setUserType] = useState('maker');
    const { loading, loadingHandler } = useComponentLoader();
    const [branchOptions, setBranchOptions] = useState<IMultiSelectLabel[]>([]);
    const [roleOptions, setRoleOptions] = useState<IMultiSelectLabel[]>([]);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const isChecker = (action: string) => {
        switch (action) {
            case 'createGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveCreateNewUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveCreateNewUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveCreateNewUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'editGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveEditUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveEditUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveEditUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'suspendGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveSuspendUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveSuspendUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveSuspendUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'deleteGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveDeleteUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveDeleteUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveDeleteUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'enableGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveReactivateUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveReactivateUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveReactivateUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            default:
                setUserType('maker');
                return false;
        }
    };
    const runApproveRejectGroup = async (action: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: {
                        requestId: state.id,
                        action: action,
                        remarks: rejectRemark,
                    },
                }),
                idTokenHeader,
            );

            const { data } = await response.data.approveReject;

            if (data !== null) {
                if (data.result.status) {
                    setShowModal(true);
                }
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Review Request',
                testId: 'notifications-groups-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleApproveReject = (action: string) => {
        runApproveRejectGroup(action);
    };

    const getSelectedList = (list: Array<ReactText>, id: number) => {
        switch (id) {
            case 1: {
                setUserGroupData({ ...userGroupData, userRoles: list });
                break;
            }
            case 2: {
                setUserGroupData({ ...userGroupData, branchOffices: list });
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleModal = () => {
        const data = { tab: 'User Groups' };
        history.push(ROUTES.dashboardSystemAdmin, data);
        setShowModal(false);
    };
    const fetchData = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getSystemAdminInboxData, {
                    input: {
                        id: state.id,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.systemAdminInboxData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;

            const data = JSON.parse(result.data.value);

            const tempGroup = initialUserGroup;
            tempGroup.name = data.name !== undefined ? data.name : '';
            setSource(result.data.source);
            setApprovalStatus(result.data.approvalStatus);
            result.data.approvalStatus.toLowerCase() === 'pending' ? isChecker(result.data.source) : null;
            tempGroup.status =
                result.data.approvalStatus.toLowerCase() === 'pending' ? data.requestedStatus : data.currentStatus;
            tempGroup.author = result.data.authorName;
            tempGroup.branchOffices = data.branch_ids.ids.map(String);
            tempGroup.userRoles = data.role_ids.ids.map(String);
            tempGroup.description = data.description;
            tempGroup.approver = emptyCheck(result.data.approverName);
            tempGroup.remarks = emptyCheck(result.data.remarks);
            setUserGroupData(tempGroup);
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch User Group Data',
                testId: 'notifications-groups-error-modal',
            });
            handleErrorHandler();
        }
    };
    const getDropdown = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(graphqlOperation(getDropDownList, {}), idTokenHeader);
            const { data } = response.data.getDropDownList;
            if (data !== null) {
                interface temp {
                    id: string;
                    value: string;
                }
                let tempData: temp[] = data.result.branches;
                let temp: IMultiSelectLabel[] = [];
                for (let i = 0; i < tempData.length; i++) {
                    temp.push({ key: tempData[i].id, value: tempData[i].value, label: tempData[i].value });
                }
                setBranchOptions(temp);
                tempData = data.result.roles;
                temp = [];
                for (let i = 0; i < tempData.length; i++) {
                    temp.push({
                        key: tempData[i].id,
                        value: tempData[i].value,
                        label: tempData[i].value,
                    });
                }
                setRoleOptions(temp);
            }
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch User Group Data',
            });
            handleErrorHandler();
        }
    };
    useEffect(() => {
        const fetchGroupData = async () => {
            await getDropdown();
            fetchData();
        };
        fetchGroupData();
    }, []);

    const updateUserGroupData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        const att = e.currentTarget.getAttribute('validation');
        let valmsg = e.currentTarget.getAttribute('validationMessage');
        if (valmsg === null) {
            valmsg = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;

        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valmsg);
            if (err.code !== 'NoError') {
                // setError(err.message);
                return;
            } else {
                // setError('');
            }
        }
        setUserGroupData({ ...userGroupData, [key]: value });
    };
    const updateUserGroupDataSelect = (value: string, field: string) => {
        setUserGroupData({ ...userGroupData, [field]: value });
    };

    let rejectApproveText = isRejectedConfirm ? 'Edit User Group request rejected' : 'Edit User Group request approved';
    switch (source) {
        case 'createGroup':
            rejectApproveText = isRejectedConfirm ? 'User Group request rejected' : 'User Group request approved';
            break;
        case 'suspendGroup':
            rejectApproveText = isRejectedConfirm
                ? 'Suspend User Group request rejected'
                : 'Suspend User Group request approved';
            break;
        case 'enableGroup':
            rejectApproveText = isRejectedConfirm
                ? 'Reactivation User Group request rejected'
                : 'Reactivation User Group request approved';
            break;
        case 'deleteGroup':
            rejectApproveText = isRejectedConfirm
                ? 'Delete User Group request rejected'
                : 'Delete User Group request approved';
            break;
    }
    return (
        <Container>
            {/* the page that displayed when the request is rejected */}
            {isRejected ? (
                <div style={{ marginLeft: sh16 }}>
                    <TextBold24 style={{ display: 'block' }}>{LABEL.rejectRemark}</TextBold24>
                    <TextNormal16 style={{ marginBottom: sh32, display: 'block' }}>
                        {LABEL.rejectRemarkDescription}
                    </TextNormal16>
                    <TextArea
                        name="rejectRemark"
                        label={LABEL.remarks}
                        value={rejectRemark}
                        handleInput={(e) => setRejectRemark(e.currentTarget.value)}
                        maxLength={255}
                        style={{ width: '520px' }}
                    />
                    <FlexedDiv direction="column">
                        <FlexedDiv style={{ marginTop: sh80, justifyContent: 'flex-start' }}>
                            <CustomButton
                                onClick={() => {
                                    setIsRejected(false);
                                    setIsRejectedConfirm(false);
                                    setRejectRemark('');
                                }}
                                style={{ width: sh240, height: '40px' }}
                            >
                                {LABEL.cancel}
                            </CustomButton>
                            <CustomButton
                                disabled={rejectRemark.trim() === ''}
                                primary={true}
                                style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                onClick={() => {
                                    setIsRejectedConfirm(true);
                                    handleApproveReject('reject');
                                }}
                            >
                                {LABEL.submit}
                            </CustomButton>
                        </FlexedDiv>
                        <div style={{ height: sh56 }} />
                    </FlexedDiv>
                </div>
            ) : (
                <Fragment>
                    <PreviousPage title={pageTitle(source)} />
                    <div style={{ paddingLeft: sh32, width: sh784 }}>
                        <PageDescription>{LABEL.reviewUserDescription}</PageDescription>
                        <div>
                            {approvalStatus.toLowerCase() !== 'pending' ? (
                                <ApprovalBanner direction="column">
                                    <Title>{`This request was ${approvalStatus.toLowerCase()} by ${
                                        userGroupData.approver
                                    }`}</Title>

                                    <FlexedDiv
                                        style={{ paddingTop: '0.5rem', width: '100%', whiteSpace: 'break-spaces' }}
                                        justifyContent="space-between"
                                    >
                                        {userGroupData.remarks !== undefined &&
                                        userGroupData.remarks.length !== 0 &&
                                        userGroupData.remarks !== '-'
                                            ? `${LABEL.rejectedReason}: ${userGroupData.remarks}`
                                            : ''}
                                    </FlexedDiv>
                                </ApprovalBanner>
                            ) : null}
                        </div>
                        <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userGroupDetails}</TextBold18>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                            <CustomInput
                                label={LABEL.groupName}
                                name="name"
                                style={{ width: '25vw', maxWidth: '720px' }}
                                value={userGroupData.name}
                                inputStyle={{ fontSize: '1rem', fontWeight: 700 }}
                                onChange={(e) => updateUserGroupData(e)}
                                disabled
                            />
                            <FlexSpacer />
                            <FlexedDiv direction="column">
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.status}</TextBold12>

                                <SelectWrapper>
                                    <SelectDropdown
                                        onChange={(value) => updateUserGroupDataSelect(value.toString(), 'status')}
                                        options={[
                                            {
                                                label: toTitleCase(userGroupData.status!.toString()),
                                                value: toTitleCase(userGroupData.status!.toString()),
                                            },
                                        ]}
                                        testId={`groupstatus-dropdown`}
                                        selectedValue={toTitleCase(userGroupData.status!.toString())}
                                        isDisabled={true}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv>
                            <TextArea
                                name="description"
                                label={LABEL.groupDescription}
                                value={userGroupData.description}
                                handleInput={(e) => updateUserGroupData(e)}
                                maxLength={255}
                                disabled
                                style={{ width: '25vw', maxWidth: '720px' }}
                            />
                        </FlexedDiv>
                        <TextBold18 style={{ marginBottom: sh24, marginTop: sh64 }}>
                            {LABEL.grantAccessLevel}
                        </TextBold18>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                            <MultiSelectV2
                                label={LABEL.assignUserRole}
                                options={roleOptions}
                                placeHolder={LABEL.selectRoles}
                                checkedState={userGroupData.userRoles}
                                handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                                selectId={1}
                                noOverlay={false}
                                expandable
                                style={{ width: '25vw', maxWidth: '720px' }}
                                disabled
                            />
                            <FlexSpacer />
                            <MultiSelectV2
                                label={LABEL.branchOffice}
                                options={branchOptions}
                                placeHolder={LABEL.selectOneOrMultipleBranch}
                                checkedState={userGroupData.branchOffices}
                                handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                                selectId={2}
                                hasSelectAll
                                selectAllLabel={LABEL.kibAllBranch}
                                expandable
                                noOverlay={false}
                                disabled
                                style={{ width: '25vw', maxWidth: '720px' }}
                            />
                        </FlexedDiv>

                        <div style={{ marginBottom: '180px' }}>
                            {approvalStatus.toLowerCase() === LABEL.pending.toLowerCase() && showBanner ? (
                                <Banner
                                    toggle={true}
                                    title="Pending Approval"
                                    descriptionEmText={userGroupData.author}
                                    description="Requested by"
                                    testId="usergroupviewinbox-banner"
                                    primaryButtonObject={{
                                        handlePrimaryBtn: () => handleApproveReject('approve'),
                                        label: LABEL.approve,
                                    }}
                                    secondaryButtonObject={{
                                        handleSecondaryBtn: () => setIsRejected(true),
                                        label: LABEL.reject,
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </Fragment>
            )}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={userType === LABEL.maker ? `Edit User Group request submitted` : rejectApproveText}
                    primaryBtn={{
                        onClick: () => {
                            handleModal();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="usergroup-inbox-modal"
                    contentAlignment="center"
                    icon="user-modal-success"
                >
                    <FlexedDiv
                        direction="column"
                        style={{ padding: '0rem 2rem', textAlign: 'center', display: 'block' }}
                    >
                        {!isRejectedConfirm ? (
                            <Fs16SemiBoldSecPrimaryBlack>
                                {toTitleCase(userGroupData.name.trim())}&nbsp;
                            </Fs16SemiBoldSecPrimaryBlack>
                        ) : null}
                        <Fs16RegSecPrimaryBlack>
                            {getEditGroupModalMessage(source, isRejectedConfirm)}
                        </Fs16RegSecPrimaryBlack>
                    </FlexedDiv>
                </Modal>
            ) : null}
            {loading ? <ComponentLoader /> : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const SelectWrapper = styled.div`
    width: 25vw;
    max-width: 720px;
    opacity: 0.5;
    margin-top: -7px;
`;
export default UserGroupViewInbox;
