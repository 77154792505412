import gql from 'graphql-tag';
/**
 * @branchDashboard @query - Transaction Dashboard
 * @description: Returns all transactions as list filtered by last updated on in a dashboard with selected tab ,when end point "branchDashboard" is passed as input
 */

export const branchDashboardExportListQueryLastUpdated = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    branchdashboardv2Grouping {
                        grouping {
                            type
                            groupingData {
                                key
                                value
                            }
                            transactionData {
                                grouping {
                                    type
                                    groupingData {
                                        principalName
                                        principalNumber
                                        orderNo
                                        jointName
                                        jointNumber
                                    }
                                    transactionData {
                                        data {
                                            orderNo
                                            transactionRef
                                            clientName
                                            clientIdNum
                                            accountType
                                            lastUpdated
                                            transactionType
                                            fundCode
                                            fundType
                                            paymentMethod
                                            fundCategory
                                            utmc
                                            # isVerified
                                            totalInvestment {
                                                fundCurrency
                                                investmentAmount
                                            }
                                            # requestId
                                            agentName
                                            agentCode
                                            bdmName
                                            status
                                            remarks {
                                                title
                                                content
                                            }
                                            cutOffTime
                                            batchTime
                                            targetDate
                                            aging
                                            # approvalType
                                            # isSeen
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
