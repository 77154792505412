import React from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';

export const FileNameColumnItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    return (
        <FlexedDiv style={{ position: 'relative' }}>
            {itemKey[0].key === 'fileName' ? (
                <div
                    style={{
                        ...itemKey[0].textStyle,
                        fontWeight: isSortedColumn === 'fileName' ? 700 : 'normal',
                    }}
                >
                    {data.fileName}
                </div>
            ) : (
                <div style={itemKey[0].textStyle}>.{data.fileType}</div>
            )}
            {data.isSeen ? null : itemKey[0].key === 'fileName' && <Badge />}
        </FlexedDiv>
    );
};
const Badge = styled.div`
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    right: 0.25rem;
`;
