import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { BasicInput, CollapsibleTooltipMessage, DropdownBase, InputText } from '..';
import { CustomDropdownV2, CustomSpacer, FlexedDiv, Tooltip } from '../../../../../components';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { IcoMoon } from '../../../../../icons';
import { handleChangeData, handleUserChange, hasKey } from '../../utils/functions';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import { IFundUploads, IProductSettings } from '../../AddRoles/HqPrivileges/hqRolesInitialData';
import { PermissionLevelInfoTemplate } from '../../../../../components/atoms/TooltipTemplates';

export interface FundPrivilegesProps {
    approval: boolean;
    expanded: boolean[];
    productSettings: IProductSettings;
    handleExpand: (index: number) => void;
    setProductSettings: (data: IProductSettings) => void;
    fundUploads: IFundUploads;
    setFundUploads: (data: IFundUploads) => void;
}

export const FundPrivileges: FunctionComponent<FundPrivilegesProps> = ({
    approval,
    expanded,
    productSettings,
    setProductSettings,
    fundUploads,
    handleExpand,
    setFundUploads,
}: FundPrivilegesProps) => {
    const setInitialValue = () => {
        let accessLevelObject = { label: '', value: '' };
        switch (fundUploads.permissionLevel.toLowerCase()) {
            case 'maker':
                accessLevelObject = { label: 'Maker', value: 'maker' };
                break;
            case 'maker (edited)':
                accessLevelObject = { label: 'Maker (Edited)', value: 'maker' };
                break;
            case 'checker':
                accessLevelObject = { label: 'Checker', value: 'checker' };
                break;
            case 'checker (edited)':
                accessLevelObject = { label: 'Checker (Edited)', value: 'checker' };
                break;
            case 'auto-authorizer':
                accessLevelObject = { label: 'Auto-Authorizer', value: 'autoAuthorizer' };
                break;
            case 'auto-authorizer (edited)':
                accessLevelObject = { label: 'Auto-Authorizer (Edited)', value: 'autoAuthorizer' };
                break;
            default:
                accessLevelObject = { label: 'Maker', value: 'maker' };
        }
        return accessLevelObject;
    };
    const [fundUploadsDropdown, setFundUploadsDropdown] = useState<ISelectValue>(setInitialValue());
    const [initial, setInitial] = useState<boolean>(true);

    const handleProductSettings = () => {
        if (approval === false) {
            const temp = {
                ...productSettings,
                permissionLevel: productSettings.isAll ? 'General Access' : 'General Access',
            };
            const newData = handleChangeData(temp);
            setProductSettings(newData as IProductSettings);
        }
    };

    const handleFundUploads = () => {
        if (approval === false) {
            const temp = { ...fundUploads };
            const newData = handleChangeData(temp);
            setFundUploads(newData as IFundUploads);
        }
    };

    const handleFundUploadsDropdown = (item: string) => {
        let temp: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item) {
            case 'Maker':
                temp = { label: item, value: 'maker' };
                break;
            case 'Checker':
                temp = { label: item, value: 'checker' };
                break;
            case 'Auto-Authorizer':
                temp = { label: item, value: 'autoAuthorizer' };
                break;
        }
        setFundUploadsDropdown(temp);
    };

    useEffect(() => {
        if (initial === false) {
            const temp = fundData;
            const stateData = { ...fundUploads };
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const newData: IFundUploads = handleUserChange(temp!, stateData, fundUploadsDropdown) as IFundUploads;
            setFundUploads(newData);
        }
    }, [fundUploadsDropdown]);

    useEffect(() => {
        setInitial(false);
    }, []);

    const fundData = hasKey(HQ_ROLE.uploads.data, fundUploadsDropdown.value)
        ? HQ_ROLE.uploads.data[fundUploadsDropdown.value]
        : undefined;
    const options = [
        { item: 'Maker', value: 'maker', handleItem: handleFundUploadsDropdown },
        { item: 'Checker', value: 'checker', handleItem: handleFundUploadsDropdown },
        { item: 'Auto-Authorizer', value: 'autoAuthorizer', handleItem: handleFundUploadsDropdown },
    ];
    return (
        <Fragment>
            <Collapsible
                checked={productSettings.isAll}
                checkboxDisabled={approval}
                expanded={expanded[0]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput
                                        style={{ backgroundColor: 'transparent' }}
                                        disabled={true}
                                        value={productSettings.permissionLevel}
                                    />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={productSettings.isAll}
                            isDisabled={approval}
                            item={HQ_ROLE.productSettings.data.general}
                            data={productSettings}
                            setData={setProductSettings as ISetSectionTypes}
                        />
                    </Fragment>
                }
                handleChecked={handleProductSettings}
                handleExpand={handleExpand}
                index={5}
                isCheckable={true}
                title={HQ_ROLE.productSettings.title}
                subtitle={HQ_ROLE.productSettings.subtitle}
                testId="productsettings-dashboard-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'1rem'} />
            <Collapsible
                checked={fundUploads.isAll}
                checkboxDisabled={approval}
                expanded={expanded[1]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <CustomDropdownV2
                                    items={options}
                                    noMinWidth={false}
                                    showDivider={false}
                                    maxWidth={'22.5rem'}
                                    disableDropdown={approval}
                                >
                                    <DropdownBase>
                                        <BasicInput value={fundUploads.permissionLevel} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </CustomDropdownV2>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={fundUploads.isAll}
                            isDisabled={approval}
                            item={fundData}
                            data={fundUploads}
                            setData={setFundUploads as ISetSectionTypes}
                            offsetArray={[3, 3, 3, 3]}
                            user={fundUploadsDropdown.value}
                        />
                    </Fragment>
                }
                handleChecked={handleFundUploads}
                handleExpand={handleExpand}
                index={6}
                isCheckable={true}
                title={HQ_ROLE.uploads.title}
                subtitle={HQ_ROLE.uploads.subtitle}
                testId="uploads-dashboard-accordion"
                noXMargin={true}
                noMargin={true}
            />
        </Fragment>
    );
};
