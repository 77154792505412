import gql from 'graphql-tag';

/**
 * @createUser @mutation System admin Dashboard
 * @description mutation for creating new user
 */

export const ActivityLogsExportList = gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    activitylogdashboard {
                        operationName
                        name
                        roles
                        eventType
                        createdOn
                        status
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
