import React from 'react';
import { FlexedDiv } from '../components';
import { TextBlue6, TextNavyBlue } from '../constants';

export const InitiatedByColumnItem = ({ data }: ITableCustomItem): JSX.Element => {
    return (
        <FlexedDiv style={{ position: 'relative' }}>
            <FlexedDiv direction="column">
                <TextNavyBlue size="14px" style={{ lineHeight: '1.2' }}>
                    {data.name}
                </TextNavyBlue>
                <TextBlue6 size="12px" style={{ lineHeight: '1.2' }}>
                    {data.userName}
                </TextBlue6>
            </FlexedDiv>
        </FlexedDiv>
    );
};
