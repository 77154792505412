import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv, RegulartTooltipTemplate, Tooltip } from '../../../../components';
import { LABEL } from '../../../../constants';

export const Roles = ({ data }: ITableCustomItem): JSX.Element => {
    const { roles } = data;

    const investor: string[] = roles !== null ? [...roles] : [];

    const amount: Array<string> = [];

    for (let index = 0; index < investor.length; index++) {
        const temp = investor[index];
        amount.push(temp);
    }

    const tooltipMessage = () => {
        const tempMessage: Array<ITooltip> = [];
        amount.map((item) => {
            const tempObject = { label: '' };
            tempObject.label = item;
            tempMessage.push(tempObject);
        });
        return tempMessage;
    };

    return (
        <Fragment>
            <FlexedDiv direction="column" justifyContent="center" style={{ padding: '0.5rem' }}>
                {investor.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {index <= 2 ? (
                                <FlexedDiv>
                                    <Role>{item}</Role>
                                </FlexedDiv>
                            ) : null}
                        </Fragment>
                    );
                })}
                {investor.length > 3 ? (
                    <Fragment>
                        <Tooltip tooTipContent={<RegulartTooltipTemplate message={tooltipMessage()} />}>
                            <ShowAll>{LABEL.showAll}</ShowAll>
                        </Tooltip>
                    </Fragment>
                ) : null}
            </FlexedDiv>
        </Fragment>
    );
};

const Role = styled.div`
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: -0.388889px;
    color: #253052;
`;
const ShowAll = styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #0089ec;
`;
