import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { FlexedDiv } from '../FlexDiv';

const CustomCheckbox = ({
    checked,
    className,
    disabled,
    handleCheckbox,
    id,
    indeterminate,
    label,
    textTransForm,
    value,
}: ICustomCheckboxProps): JSX.Element => {
    const _textTransForm = textTransForm || undefined;
    const disabledStyle: React.CSSProperties = disabled === true ? { opacity: 0.5, pointerEvents: 'none' } : {};
    return (
        <Fragment>
            <FlexedDiv>
                <label>
                    <FlexedDiv alignItems="center" className={className} style={disabledStyle}>
                        <HiddenInput
                            checked={indeterminate ? indeterminate : checked}
                            onChange={disabled ? () => undefined : handleCheckbox}
                            value={value}
                            id={id}
                            data-testid={id}
                        />
                        <StyledCheckbox checked={indeterminate ? indeterminate : checked} disabled={disabled}>
                            <Icon viewBox="0 0 24 24">
                                {indeterminate ? (
                                    <polyline points="20 12 20 12 5 12" />
                                ) : (
                                    <polyline points="20 6 9 17 4 12" />
                                )}
                            </Icon>
                        </StyledCheckbox>
                        {label !== undefined ? (
                            <StyledLabel disabled={disabled} textTransForm={_textTransForm} checked={checked}>
                                {label}
                            </StyledLabel>
                        ) : null}
                    </FlexedDiv>
                </label>
            </FlexedDiv>
        </Fragment>
    );
};
interface StyledLabelProps {
    textTransForm?: string;
    checked: boolean;
    disabled?: boolean;
}
const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
const Icon = styled.svg`
    stroke-width: 2px;
    stroke: white;
    fill: none;
`;

const StyledCheckbox = styled.div<IOnPropStyles>`
    display: inline-block;
    min-width: 1.125rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 4px;
    border: 1px solid #002043;
    box-sizing: border-box;
    background: ${(props: IOnPropStyles) => (props.disabled ? '#878787' : 'inherit')};
    transition: all 150ms;
    vertical-align: middle;
    &:hover {
        cursor: pointer;
        background: #c6cbd4;
    }
    ${(props) =>
        props.checked &&
        css`
            background: #c61230;
            border: none;
            opacity: ${(props: IOnPropStyles) => (props.disabled ? 0.6 : 1)};
            &:hover {
                background: #c61230;
            }
        `}
    ${HiddenInput}:focus + & {
        box-shadow: 0 0 0 4px rgba(198, 18, 48, 0.1);
    }
    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;

const StyledLabel = styled.span<StyledLabelProps>`
    margin-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: ${(props) => (props.checked ? 700 : 400)};
    vertical-align: middle;
    color: #171717;
    text-transform: ${(props) => (props.textTransForm ? props.textTransForm : 'capitalize')};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    margin-top: -0.1rem;
    cursor: pointer;
`;
export default CustomCheckbox;
