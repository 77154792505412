import { ReactText } from 'react';

const permissionKeysBranch = ['accountManagement', 'ceTransaction', 'adviser'];
const permissionKeysUserManagement = ['accountManagement', 'userManagement', 'activityLogs', 'systemSettings'];
const permissionsKeysOnlyFundManagement = ['accountManagement', 'productSettings', 'uploads'];
export const permissionKeysHq = [
    'accountManagement',
    'ceDashboard',
    'branchOffices',
    'eddCase',
    'financeDashboard',
    'financeCases',
    'productSettings',
    'uploads',
    'operationDashboard',
    'operationCases',
];
export const permissionKeyLabels = {
    branch: 'branchLevel',
    pureFund: 'pureFinance',
    admin: 'userManagement',
    normalHQ: 'normal',
    user: 'accountManagementOnly',
};

const grantHqKeys = [
    'accountManagement',
    'ceDashboard',
    'branchOffices',
    'eddCase',
    'financeDashboard',
    'financeCases',
    'productSettings',
    'uploads',
    'operationDashboard',
    'operationCases',
    'userManagement',
    'activityLogs',
    'systemSettings',
];

const checkLevel = (roles: string[], permissions: string[]) => {
    let c = 0;
    roles.length === 1 && roles[0] === 'accountManagement' ? (c = -1) : (c = 0);
    if (c !== -1)
        for (let i = 0; i < roles.length; i++) {
            if (c !== -1) permissions.includes(roles[i]) ? c++ : (c = -1);
            else break;
        }

    return c !== -1 ? true : false;
};
export const isBranchLevel = (roles: string[]): boolean => {
    return checkLevel(roles, permissionKeysBranch);
};

export const isAdminLevel = (roles: string[]): boolean => {
    return checkLevel(roles, permissionKeysUserManagement);
};

export const isOnlyFund = (roles: string[]): boolean => {
    return checkLevel(roles, permissionsKeysOnlyFundManagement);
};
export const isHqLevel = (roles: string[]): boolean => {
    return checkLevel(roles, permissionKeysHq);
};
export const isOnlyAccountManagement = (roles: string[]): boolean => {
    return roles.length === 1 && roles[0] === 'accountManagement' ? true : false;
};

export const canCombineGrant = (incomingGrant: string, incomingPermissionKeys: ReactText[]): string => {
    let c = 0;
    if (incomingGrant === 'hq') {
        incomingPermissionKeys.map((key) => {
            if (c !== -1) grantHqKeys.includes(key.toString()) ? c++ : (c = -1);
            return;
        });
        return c === -1 ? 'Please select roles assigned to HQ Backoffice only.' : 'hq';
    } else {
        incomingPermissionKeys.map((key) => {
            if (c !== -1) permissionKeysBranch.includes(key.toString()) ? c++ : (c = -1);
            return;
        });
        return c === -1 ? 'Please select roles assigned to Branch Backoffice only.' : 'branch';
    }
};
