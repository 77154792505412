/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { IColumnValue } from '../pages/Advisers';
import moment from 'moment';

interface useSearchFiltersCb {
    filterValues: IColumnValue[];
    columnFilterValues: IColumnValue[];
    dateRange: [moment.Moment | null, moment.Moment | null];
    handleFilterChange: (selectedValue: any, elemName: string) => void;
    handleColumnFilterChange: (selectedValue: any, elemName: string) => void;
    handleFilterDate: (dateRange: [moment.Moment | null, moment.Moment | null]) => void;

    setFilterValues: (values: IColumnValue[]) => void;
}
/**
 * User search filters custom hook to handle search filter values for fund management dashboards.
 * @category Custom Hooks
 * @component
 * @namespace useSearchFilters
 * @returns Product Settings dashboard component
 */
export const useSearchFilters = (intialFilterValues: IColumnValue[]): useSearchFiltersCb => {
    const [filterValues, setFilterValues] = useState<IColumnValue[]>(intialFilterValues);
    const [columnFilterValues, setColumnFilterValues] = useState<IColumnValue[]>([]);
    const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);

    const handleFilterChange = (value: string, elemName: string) => {
        const _tempArr = [...filterValues];

        if (elemName === 'Date') {
            const updated = _tempArr.find(
                (obj) =>
                    obj.column === 'lastUpdated' ||
                    obj.column === 'lastUpdate' ||
                    obj.column === 'submittedOn' ||
                    obj.column === 'createdOn' ||
                    obj.column === 'uploadedOn',
            );

            updated ? (updated.column = value) : _tempArr.push({ column: value, value: '' });
        } else if (_tempArr.find((obj) => obj.column === elemName)) {
            const updated = _tempArr.find((obj) => obj.column === elemName);
            updated ? (updated.value = value) : '';
        } else {
            _tempArr.push({ column: elemName, value: value });
        }

        setFilterValues(_tempArr);
    };
    const handleFilterDate = (dateRange: [moment.Moment | null, moment.Moment | null]) => {
        const _tempArr = [...filterValues];

        if (
            _tempArr.find(
                (obj) =>
                    obj.column === 'lastUpdated' ||
                    obj.column === 'lastUpdate' ||
                    obj.column === 'uploadedOn' ||
                    obj.column === 'fundStartDate',
            )
        ) {
            const updated = _tempArr.find(
                (obj) =>
                    obj.column === 'lastUpdated' ||
                    obj.column === 'lastUpdate' ||
                    obj.column === 'uploadedOn' ||
                    obj.column === 'fundStartDate',
            );
            dateRange[0] === null && updated !== undefined
                ? (updated.value = ``)
                : updated && (updated.value = `${moment(dateRange[0]).valueOf()}~${moment(dateRange[1]).valueOf()}`);
            // updated && (updated.value = `${moment(dateRange[0]).valueOf()}~${moment(dateRange[1]).valueOf()}`);
        }

        setDateRange(dateRange);
        setFilterValues(_tempArr);
    };
    const handleColumnFilterChange = (value: string, elemName: string) => {
        const _tempArr = [...columnFilterValues];
        if (_tempArr.find((obj) => obj.column === elemName)) {
            const updated = _tempArr.find((obj) => obj.column === elemName);
            updated ? (updated.value = value) : '';
        } else {
            _tempArr.push({ column: elemName, value: value });
        }

        setColumnFilterValues(_tempArr);
    };

    useEffect(() => {
        setFilterValues(intialFilterValues);
    }, [intialFilterValues]);
    return {
        filterValues,
        dateRange,
        columnFilterValues,
        handleFilterChange,
        handleFilterDate,
        handleColumnFilterChange,
        setFilterValues,
    };
};
