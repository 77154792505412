/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdvanceTable, FlexedDiv, Modal } from '../../../../components';
import { EMPTY_STATES, SYSTEM_ADMIN, LABEL } from '../../../../constants';
import { subTitleStyle } from '../../../../styles';
import { SystemAdminAllUsersActions } from './SystemAdminAllUsersActions';
import { statusStyle, stringToCamelCase } from '../../../../utils';
import { API, graphqlOperation } from 'aws-amplify';
import { IUserManagement, IUserTab } from '../../../../utils/permissionTypes';
import { DateHeaderSA } from './DateHeaderSA';
import { IApiData } from '../RolesPermissions/RolesPermissions';

import * as ROUTES from '../../../../routes';
import userUpdateStatus from '../../../../_graphql/mutations/systemAdmin/userUpdateStatus';
import approveReject from '../../../../_graphql/mutations/systemAdmin/approveReject';
import AuthContext from '../../../../context/AuthContext';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import getIdInformation from '../../../../_graphql/mutations/systemAdmin/getIdInformation';
import CustomItem from './CustomItem';

export interface SystemAdminAllUsersProps {
    isSearchResult?: boolean;
    isFilterApplied?: boolean;
}

export const SystemAdminAllUsers: React.FC<SystemAdminAllUsersProps> = ({
    isSearchResult,
    isFilterApplied,
}: SystemAdminAllUsersProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [apiData, setApiData] = useState<IApiData>({
        id: '',
        action: '',
        name: '',
        status: '',
        icon: 'user-modal-success',
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;
    const { userTab } = userManagementPermission;
    const {
        allUsers,
        createdOnDropdown,
        dateFilter,
        fetchAllUsers,
        searchInput,
        setCreatedOnDropdown,
        setDateFilter,
        setSortInput,
        setTarget,
        sortInput,
    } = useContext(SystemAdminContext);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const handleItemIcon = (item: IColumnItemAccordion) => {
        const itemIcon: ITableIcon = {
            name: 'edit',
            size: '1.75rem',
        };
        if (userTab.actions.canEditUserDetails === '') {
            itemIcon.name = 'eye-show';
        }
        if (item.rawData.status === 'Pending Approval') {
            itemIcon.name = 'eye-show';
        }
        if (item.rawData.status === 'Terminated') {
            itemIcon.name = 'eye-show';
        }
        return itemIcon;
    };

    const runUserUpdateStatus = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(userUpdateStatus, {
                    input: {
                        id: apiData.id,
                        action: apiData.action,
                        viewType: 'users',
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.userUpdateStatus;

            if (data) {
                setConfirmModal(false);
                setShowModal(true);
                fetchAllUsers();
            }

            if (error) {
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Perform Action',
                    testId: 'allusers-actions-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    const handleUserAction = async (action: string, rawData?: ITableData) => {
        const userAction = action.toLowerCase();
        let apiAction = '';
        switch (userAction) {
            case 'suspend user':
                apiAction = 'suspend';
                break;
            case 'terminate user':
                apiAction = 'terminate';
                break;
            case 'reactivate user':
                apiAction = 'enable';
                break;
            default:
                apiAction = userAction;
                break;
        }

        if (rawData) {
            const id = rawData ? rawData.apiCode : '';

            try {
                const response: any = await API.graphql(
                    graphqlOperation(getIdInformation, {
                        input: {
                            id: id,
                            viewType: 'users',
                        },
                    }),
                    idTokenHeader,
                );
                const { data, error } = response.data.getIdInformation;

                const info = JSON.parse(data.result.info);
                setApiData({
                    ...apiData,
                    action: apiAction,
                    id: rawData !== undefined ? rawData.apiCode : '',
                    status: rawData !== undefined ? rawData.status : '',
                    name: rawData !== undefined ? info.name : '',
                    requestId: rawData !== undefined ? rawData.requestId : '',
                    source: rawData !== undefined ? rawData.source : '',
                });

                if (data !== null) {
                    if (info !== null) {
                        switch (apiAction) {
                            case 'suspend':
                            case 'terminate':
                            case 'enable':
                                setConfirmModal(true);
                                break;
                            case 'approve': {
                                try {
                                    const response: any = await API.graphql(
                                        graphqlOperation(approveReject, {
                                            input: {
                                                requestId: rawData !== undefined ? rawData.requestId : undefined,
                                                action: apiAction,
                                            },
                                        }),
                                        idTokenHeader,
                                    );

                                    if (response.data.approveReject.data !== null) {
                                        setShowModal(true);
                                    } else {
                                        setErrorMessage({
                                            ...errorMessage,
                                            message: response.data.approveReject.error.message,
                                            errorCode: response.data.approveReject.error.errorCode,
                                            title: 'Cannot Review Request',
                                            testId: 'allusers-actions-error-modal',
                                        });
                                        handleErrorHandler();
                                    }
                                } catch (error) {}
                            }
                        }
                    }
                }
                if (error) {
                    setErrorMessage({
                        ...errorMessage,
                        message: error.message,
                        errorCode: error.errorCode,
                        title: 'Cannot Fetch User Data',
                        testId: 'allusers-actions-error-modal',
                    });
                    handleErrorHandler();
                }
            } catch (error) {}
        } else {
        }
    };
    // Fn to handle table column sorting
    const handleSort = (keyName: string) => {
        const tempSort = { ...sortInput };
        const type = sortInput.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = type;
        tempSort.column = keyName;
        setSortInput(tempSort);
    };
    // Fn to handle sort arrow icon
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortInput.column === keyName) {
            sortIcon = sortInput.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    const handleCreatedOn = () => {
        return (
            <DateHeaderSA
                dropdownData={[
                    createdOnDropdown,
                    sortInput.column === stringToCamelCase(createdOnDropdown) ? sortInput.value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };
    const handleDateFilter = (item: string, value: string) => {
        setCreatedOnDropdown(item);
        let currentFilter = 'createdOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setTarget(tempObj);
        setSortInput({ column: currentFilter, value: value });
    };
    const canApprove = (source: string, permissions: IUserTab) => {
        if (source) {
            if (
                source === 'createUser' &&
                (permissions.reviewApproval.canApproveCreateNewUser === 'checker' ||
                    permissions.reviewApproval.canApproveCreateNewUser === 'auto-authorizer')
            )
                return true;
            if (
                source === 'userBulkUpload' &&
                (permissions.reviewApproval.canApproveBulkImport === 'checker' ||
                    permissions.reviewApproval.canApproveBulkImport === 'auto-authorizer')
            )
                return true;
            if (
                source === 'editUser' &&
                (permissions.reviewApproval.canApproveEditUserDetails === 'checker' ||
                    permissions.reviewApproval.canApproveEditUserDetails === 'auto-authorizer')
            )
                return true;
            if (
                source === 'suspendUser' &&
                (permissions.reviewApproval.canApproveSuspendUser === 'checker' ||
                    permissions.reviewApproval.canApproveSuspendUser === 'auto-authorizer')
            )
                return true;
            if (
                source === 'enableUser' &&
                (permissions.reviewApproval.canApproveReactivateUser === 'checker' ||
                    permissions.reviewApproval.canApproveReactivateUser === 'auto-authorizer')
            )
                return true;
            if (
                source === 'terminateUser' &&
                (permissions.reviewApproval.canApproveTerminateUser === 'checker' ||
                    permissions.reviewApproval.canApproveTerminateUser === 'auto-authorizer')
            )
                return true;
        }
        return false;
    };

    const handlePendingText = () => {
        const modalContent = {
            title: '',
            subTitle: '',
        };
        switch (apiData.source) {
            case 'createUser':
                modalContent.title = apiData.action === 'approve' ? LABEL.newUserApproved : LABEL.newUserRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.newUserApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'editUser':
                modalContent.title = apiData.action === 'approve' ? LABEL.userEditApproved : LABEL.editUserRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.userEditedApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'suspendUser':
                modalContent.title =
                    apiData.action === 'approve' ? LABEL.userSuspendApproved : LABEL.suspendUserRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.userSuspendApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'enableUser':
                modalContent.title = apiData.action === 'approve' ? LABEL.enableUserApproved : LABEL.enableUserRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.enableUserApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'terminateUser':
                modalContent.title =
                    apiData.action === 'approve' ? LABEL.userTerminationApproved : LABEL.terminateUserRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.userTerminationApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
        }
        return modalContent;
    };

    const handleModalText = () => {
        const modalContent = {
            title: '',
            subTitle: '',
            confirmModalTitle: '',
            confirmModalSubTitle: '',
            icon: 'user-modal-success',
        };
        switch (apiData.action) {
            case 'suspend': {
                if (userTab.actions.canSuspendUser === 'maker') {
                    modalContent.title = LABEL.userSuspendRequest;
                    modalContent.subTitle = LABEL.submittedMsg;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_CONFIRMATION_MODAL;
                    modalContent.icon = 'user-modal-processing';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_SUSPEND_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_ACCOUNT_HAS_BEEN_SUSPENDED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_CONFIRMATION_MODAL;
                }
                break;
            }
            case 'terminate': {
                if (userTab.actions.canTerminateUser === 'maker') {
                    modalContent.title = LABEL.userTerminateRequest;
                    modalContent.subTitle = LABEL.submittedMsg;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_TERMINATE_USER_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_TERMINATE_USER_MODAL_SUB;
                    modalContent.icon = 'user-modal-processing';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_TERMINATED_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_ACCOUNT_HAS_BEEN_TERMINATED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_TERMINATE_USER_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_TERMINATE_USER_MODAL_SUB;
                }
                break;
            }
            case 'enable': {
                if (userTab.actions.canReactivateUser === 'maker') {
                    modalContent.title = LABEL.userReactivateRequest;
                    modalContent.subTitle = LABEL.submittedMsg;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_MODAL_SUB;
                    modalContent.icon = 'user-modal-processing';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_REACTIVATED_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_ACCOUNT_HAS_BEEN_ACTIVATED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_MODAL_SUB;
                }
                break;
            }
            case 'approve': {
                const pendingText = handlePendingText();
                modalContent.title = pendingText.title;
                modalContent.subTitle = pendingText.subTitle;
                break;
            }
        }
        return modalContent;
    };
    const columns: ITableColumn[] = [
        {
            key: [
                { key: 'name', textStyle: { fontWeight: sortInput.column === 'name' ? 700 : 400, color: 'green' } },
                { key: 'code' },
            ],

            viewStyle: {
                width: '13.89vw',
            },
            icon: {
                name: handleSortIcon('name'),
                size: '1rem',
            },
            title: SYSTEM_ADMIN.LABEL_STAFF_NAME,
            titleStyle: { fontWeight: sortInput.column === 'name' ? 700 : 400 },
            subtitle: SYSTEM_ADMIN.LABEL_CODE,
            customItem: true,
            onPressHeader: () => handleSort('name'),
        },
        {
            key: [{ key: 'department', textStyle: { fontWeight: sortInput.column === 'department' ? 700 : 400 } }],
            viewStyle: {
                width: '12.8vw',
            },
            title: SYSTEM_ADMIN.LABEL_DEPARTMENTS,
            titleStyle: { fontWeight: sortInput.column === 'department' ? 700 : 400 },
            icon: {
                name: handleSortIcon('department'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('department'),
        },
        {
            key: [{ key: 'userGroup' }],
            viewStyle: {
                width: '16.1vw',
            },
            title: SYSTEM_ADMIN.LABEL_ASSIGNED_USER_GROUPS,
            customItem: true,
        },
        {
            key: [
                {
                    key: 'createdOn',
                    textStyle: { fontWeight: sortInput.column === stringToCamelCase(createdOnDropdown) ? 700 : 400 },
                },
                { key: 'createdOnTime', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '8.472vw',
            },
            title: createdOnDropdown,
            titleStyle: { fontWeight: sortInput.column === stringToCamelCase(createdOnDropdown) ? 700 : 400 },
            icon: {
                name: 'caret-down',
                size: '1rem',
            },
            RenderHeaderMenu: () => handleCreatedOn(),
        },
        {
            key: [
                { key: 'lastLogin', textStyle: { fontWeight: sortInput.column === 'lastLogin' ? 700 : 400 } },
                { key: 'lastLoginTime', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '8.472vw',
            },
            title: SYSTEM_ADMIN.LABEL_LAST_LOGIN,
            titleStyle: { fontWeight: sortInput.column === 'lastLogin' ? 700 : 400 },
            icon: {
                name: handleSortIcon('lastLogin'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('lastLogin'),
        },

        {
            key: [{ key: 'status', textStyle: { fontWeight: sortInput.column === 'status' ? 700 : 400 } }],
            viewStyle: {
                width: '9.3vw',
            },
            title: SYSTEM_ADMIN.LABEL_STATUS,
            titleStyle: { fontWeight: sortInput.column === 'status' ? 700 : 400 },
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [],
            getItem: handleItemIcon,
            viewStyle: {
                width: '3.19vw',
            },
            title: SYSTEM_ADMIN.LABEL_EDIT_VIEW,
            onClickItem: (item: IColumnItemAccordion, mode: string) => {
                if (handleItemIcon(item).name !== '') {
                    history.push(ROUTES.addUser, {
                        mode: mode,
                        data: item.rawData,
                        canApprove:
                            item.rawData.status.toLowerCase() === 'terminated' ||
                            item.rawData.status.toLowerCase() === 'suspended'
                                ? false
                                : canApprove(item.rawData.source, userTab),
                        isAA: userTab.actions.canCreateNewUser === 'auto-authorizer',
                    });
                } else {
                    undefined;
                }
            },
            testId: 'allusers-action-btn',
        },
    ];

    const modalText = handleModalText();

    return (
        <Fragment>
            <AdvanceTable
                data={allUsers}
                columns={columns}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortInput.column} {...props} />}
                RenderOptions={(props: ITableOptions) => (
                    <SystemAdminAllUsersActions
                        data={props.data}
                        handleAction={handleUserAction}
                        permissions={userTab}
                    />
                )}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateFilterNoResultUsers : EMPTY_STATES.emptyStateTableAllUsers
                }
                isSearchResult={isSearchResult}
                searchInput={searchInput.value}
                testId="systemadmin"
            />

            {confirmModal ? (
                <Modal
                    modalActive={confirmModal}
                    setModalActive={setConfirmModal}
                    title={modalText.confirmModalTitle}
                    primaryBtn={{
                        onClick: () => {
                            runUserUpdateStatus();
                        },
                        label: SYSTEM_ADMIN.BUTTON_YES,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            setConfirmModal(false);
                        },
                        label: SYSTEM_ADMIN.BUTTON_NO,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="left"
                    testId="allusers-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'left' }}>
                        {modalText.confirmModalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalText.title}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(ROUTES.dashboardSystemAdmin, { tab: 'All Users', refresh: 'refresh' });
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="allusers-success-modal"
                    icon={modalText.icon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalText.subTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
