/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
const removeNull = (data: any): any => {
    const q = {};
    const a = [];
    if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            const d = data[i];
            if (typeof d === 'object') {
                a.push(removeNull(data[i]));
            } else {
                a.push(data[i]);
            }
        }
        return a;
    } else {
        // eslint-disable-next-line prefer-const
        // for (con [key, val] of Object.entries(data)) {

        // eslint-disable-next-line prefer-const
        for (let [key, val] of Object.entries(data)) {
            if (val !== null) {
                val = typeof val === 'object' ? removeNull(val) : val;
                q[key] = val;
            }
        }
    }
    return q;
};

const copyToChildren = (data: any, id: any, option: any) => {
    if (data.id === id) {
        // copy the option under options key
        if ('options' in data) {
            data = { ...data, options: [...data.options, option] };
        } else {
            data = { ...data, options: [option] };
        }
    } else if ('options' in data) {
        for (let i = 0; i < data.options.length; i++) {
            data.options[i] = copyToChildren(data.options[i], id, option);
        }
    }
    return data;
};
declare interface IShiftElementProps {
    id: string;
    parent: string[];
    title: string;
    type: string;
}

const shiftElement = (data: any[]) => {
    let d = {};
    const finalOptions: any[] = [];
    let id = '';
    for (let i = 0; i < data.length; i++) {
        d = data[i];
        if ('parent' in d) {
            for (let k = 0; k < d.parent.length; k++) {
                //copy d under finalOptions.options key
                id = d.parent[k];
                for (let z = 0; z < finalOptions.length; z++) {
                    finalOptions[z] = copyToChildren(finalOptions[z], id, d);
                }
            }
        } else {
            finalOptions.push(d);
        }
    }
    return finalOptions;
};

declare interface IOptionsProps {
    id: string;
    position: string;
    parent: string;
    title: string;
    description: string;
    type: string;
    hasRemark: string;
    multiSelection: string;
    autoHide: string;
    values: string;
    valuesDescription: string;
}

declare interface IBuildJsonProps {
    title: string;
    description: string;
    options: IOptionsProps;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildJSON = (data: string | any[]) => {
    let converted = [];
    // remove all keys with null value
    let i;
    for (i = 0; i < data.length; i++) {
        const q = {};
        // eslint-disable-next-line prefer-const
        for (let [key, val] of Object.entries(data[i])) {
            if (val !== null) {
                val = typeof val === 'object' ? removeNull(val) : val;
                q[key] = val;
            }
        }
        converted.push(q);
    }
    // restructure the flattened hierarchy
    data = converted;
    converted = [];
    for (i = 0; i < data.length; i++) {
        const q = {};
        // eslint-disable-next-line prefer-const
        for (let [key, val] of Object.entries(data[i])) {
            if (Array.isArray(val)) {
                val = shiftElement(val);
            }
            q[key] = val;
        }
        converted.push(q);
    }

    return converted;
};
