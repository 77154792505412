/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { ceCheckerReviewRequest } from '../../_graphql/mutations/branchCE/ceCheckerReviewRequest/ceCheckerReviewRequest';
import { useComponentLoader } from '../../customHooks';
import { AccountDetails } from '../AccountDetails';
import { Tabs, Modal, ComponentLoader, Banner, CustomSpacer, FlexedDiv, TrackingStepper } from '../../components';
import {
    dummyTransactionsSummary,
    LABEL,
    CE_DASHBOARD_LABELS,
    dummyDocumentSummary,
    TextDarkBlack,
    TextNavyBlue,
} from '../../constants';
import { handleOrderSummary, toTitleCase } from '../../utils';

import { IOrderSummaryProps, IOrderSummaryRouteProps, IOrderSummaryType } from './orderSummaryTypes';
import { initialProfile } from '../AccountDetails/Dummy';
import { dashboardBranch, viewBranch } from '../../routes';
import { ICeDashboard } from '../../utils/permissionTypes';

import * as ROUTES from '../../routes';
import styled from 'styled-components';
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import TransactionsSummary from './TransactionSummary';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import RejectRemarks from '../RejectRemarks/RejectRemarks';
import { DocumentSummary } from './DocumentSummary';
import { TrackingSummary } from './TrackingSummary';
import { IcoMoon } from '../../icons';
import { getOrderSummaryV2 } from '../../_graphql/queries/common/getOrderSummaryv2';
import { getTransactionStatusOverview } from '../../_graphql/queries/common/getStatusOverview';
import moment from 'moment';

const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};

const ORDER_SUMMARY_LINKS = ['', '/profile', '/document', '/track'];

const OrderDetails: React.FC = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { state } = useLocation<IOrderSummaryRouteProps>();
    const locationState = state;
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const branchId = sessionStorage.getItem('branchId');
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;

    const { pendingTab } = parsedPermissionPrivilege;
    const previousPage = { currentTab: locationState ? locationState.tab ?? 'daily' : 'daily' };

    const transRef =
        locationState && locationState.transactionRef !== undefined
            ? locationState.transactionRef
            : locationState && locationState.orderNo && locationState.orderNo;
    const isRejectRequest: boolean =
        locationState && locationState.isRejectRequest !== undefined ? locationState.isRejectRequest : false;
    const previousUrl = locationState && locationState.url;
    const [orderSummary, setOrderSummary] = useState<IOrderSummaryType>();
    const [summaryObjects, setSummaryObject] = useState<IOrderSummaryProps>({
        profile: initialProfile,
        transactionSummary: dummyTransactionsSummary,
        documentSummary: dummyDocumentSummary,
    });
    const [statusOverView, setStatusOverview] = useState<IStepperTimelineProps[]>([]);
    const [toggle, setToggle] = useState(true);
    const [rejectRemarks, setRejectRemarks] = useState<string>('');
    const [rejectReq, setRejectReq] = useState<boolean>(false);
    const [redirectState, setRedirectState] = useState<ITableData>();
    const [disableVerify, setDisableVerify] = useState<boolean>(false);
    const [fromInbox, setFromInbox] = useState<boolean>(false);

    // Modal UI states
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    // Custom hooks
    const { loading, loadingHandler } = useComponentLoader();

    const fetchOrderSummary = async () => {
        loadingHandler();
        try {
            let inputObj = {};
            if (
                fromInbox === true &&
                locationState.isRequestFromInbox === true &&
                locationState.requestIdFromInbox !== undefined
            ) {
                inputObj = {
                    transactionRef: transRef,
                    requestId: locationState.requestIdFromInbox,
                    tab: locationState.tab,
                    endpoint: locationState.endpoint,
                };
            } else {
                if (locationState.transactionRef !== undefined)
                    inputObj = { transactionRef: transRef, tab: locationState.tab, endpoint: locationState.endpoint };
                else inputObj = { orderNumber: transRef, tab: locationState.tab, endpoint: locationState.endpoint };
            }
            const response: any = await API.graphql(
                graphqlOperation(getOrderSummaryV2, {
                    input: {
                        ...inputObj,
                    },
                }),
                idTokenHeader,
            );
            const orderSummaryCheck = response.data.getOrderSummaryV2;
            if (orderSummaryCheck.error !== null) throw orderSummaryCheck.error;
            const { result } = response.data.getOrderSummaryV2.data;
            setOrderSummary(result);
            const tempObj = {
                orderNo: result.orderNo,
                transactionRef: result.transactionRef,
                status: result.status,
            };
            setRedirectState(tempObj);
            setSummaryObject(handleOrderSummary(result));
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Order Summary',
                testId: 'ordersummary-error-modal',
            });
        }
    };

    // Fn to call transactionStatus mutation
    const verifyTransactions = async (ref: string) => {
        handleConfirmModal();
        setDisableVerify(true);

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: {
                        transactionRef: ref,
                        action: 'Approve',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionStatus.data;
            if (result.status === true) {
                setDisableVerify(false);
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setDisableVerify(false);
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Verify Transaction',
                testId: 'ordersummary-error-modal',
            });
        }
    };

    // Fn to call transactionCheckerStatus mutation
    const reviewRequest = async (requestId: string, action: string, remarks?: string) => {
        setDisableVerify(true);
        handleConfirmModal();
        const payload =
            remarks !== undefined
                ? {
                      requestId: requestId,
                      action: action,
                      remarks: remarks,
                  }
                : {
                      requestId: requestId,
                      action: action,
                  };

        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerReviewRequest, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionCheckerStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.transactionCheckerStatus.data;
            if (result.status) {
                setDisableVerify(false);
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setDisableVerify(false);
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Review Request',
                testId: 'ordersummary-error-modal',
            });
        }
    };

    const fetchStatusOverView = async () => {
        loadingHandler();
        const _input = {
            orderNo: locationState.orderNo || locationState.orderNumber,
            transactionRef: locationState.transactionRef,
        };
        try {
            const response: any = await API.graphql(
                graphqlOperation(getTransactionStatusOverview, {
                    input: _input,
                }),
            );
            const resultCheck = response.data.getTransactionStatusOverview;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            const _overview: IStepperTimelineProps[] = result.statusOverview.map((overview: any) => {
                return {
                    ...overview,
                    steps: overview.steps.map((step: any) => {
                        return {
                            ...step,
                            finished: !step.current,
                            subText: step.subText !== null ? moment(step.subText, 'x').format('DD/MM/YYYY') : '-',
                        };
                    }),
                };
            });
            setStatusOverview(_overview);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Status Overview',
                testId: 'statusoverview-error-modal',
            });
        }
    };

    const tabs = [
        <TransactionsSummary
            branch={orderSummary?.transactionDetails.kibProcessingBranch}
            transactions={summaryObjects.transactionSummary}
            key={1}
            fromInbox={fromInbox}
        />,
        <AccountDetails profile={summaryObjects.profile} key={2} />,
        <DocumentSummary
            key={3}
            softCopy={summaryObjects.documentSummary.softcopy.documents.map((doc): ISection => {
                return {
                    data: doc.documents.map((docs): IDataWithLabel => {
                        return {
                            label: docs.title,
                            onClickData: docs.url,
                            data: docs.name,
                            dataIcon: docs.label == 'form' ? 'file' : docs.url != '-' ? 'Vector' : '',
                        };
                    }),
                };
            })}
            physicalCopy={summaryObjects.documentSummary.hardcopy.utmcDocs?.map((doc): ISection => {
                return {
                    sectionTitle: { title: toTitleCase(doc?.mainHeader) },
                    data: doc?.documents.map((docs: any) => {
                        return {
                            label: docs.title,
                            onClickData: docs.url,
                            data: docs.name,
                            dataIcon: docs.label == 'form' ? 'file' : docs.url != '-' ? 'Vector' : '',
                        };
                    }),
                };
            })}
            accDocs={summaryObjects.documentSummary.hardcopy.accDocs.map((acc) => {
                return {
                    mainHeader: acc.mainHeader,
                    subHeader: acc.subHeader,
                    documents: acc.documents.map((docs) => {
                        return {
                            label: docs.title,
                            onClickData: docs.url,
                            data: docs.name,
                            dataIcon: docs.label == 'form' ? 'file' : docs.url != '-' ? 'Vector' : '',
                        };
                    }),
                };
            })}
            noPhysical={!summaryObjects.documentSummary.hardcopy.required}
            jointAcc={summaryObjects.documentSummary.accountType !== 'Individual'}
            noInfoImage="Empty_document_info"
        />,
        <TrackingSummary key={4} />,
    ];
    const handleAlert = () => {
        setToggle(!toggle);
    };

    // Fn to handle verify order action and set modal values
    const handleVerifyOrder = () => {
        // set confirmation modal data
        modalMessages.confirmation.heading = (
            <span>
                {CE_DASHBOARD_LABELS.DAILY.verifyThisTransaction} <br></br> {orderSummary?.orderNo}
                {orderSummary?.transactionRef}
            </span>
        ) as unknown as string;

        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>
                    {locationState.verifyOnly
                        ? CE_DASHBOARD_LABELS.DAILY.verifyOnlyThisTransactionMessage
                        : CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage}
                </Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoMessage}</Subtitle>
            </FlexedDiv>
        );
        modalMessages.confirmation.type = 'verify';

        if (pendingTab.reviewApproval.canApproveVerify === LABEL.autoAuthorizer) {
            // set feedback modal data
            modalMessages.feedback.heading = 'Transactions verified successfully';
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>
                        {orderSummary?.orderNo}
                        {orderSummary?.transactionRef}
                    </Bold>
                    &nbsp; has been verified and <br /> adviser will be notified.
                </p>
            );
            modalMessages.feedback.icon = 'trx-verification-success';
        } else {
            // set feedback modal data
            modalMessages.feedback.heading = CE_DASHBOARD_LABELS.DAILY.submitTransactionRequest;
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                    This request has been submitted and pending for review.
                </p>
            );
            modalMessages.feedback.icon = 'trx-verification-request-submit';
        }

        setShowConfirmationModal((prevState) => !prevState);
    };

    // Fn to handle order verification requests and set modal values
    const handleApproveRequest = () => {
        // set confirmation modal data
        modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveTransactionRequestOrderSummary}`;
        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
            </FlexedDiv>
        );
        modalMessages.confirmation.type = 'approve request';
        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.transactionRequestApproved}`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <Subtitle>
                    Request for&nbsp;
                    <Bold>
                        {orderSummary?.orderNo}
                        {orderSummary?.transactionRef}
                    </Bold>
                    &nbsp;has been approved.
                    <br /> Maker will be notified. Adviser will be notified once all transactions in this order has been
                    verified.
                </Subtitle>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        handleConfirmModal();
        // orderSummary?.requestId !== undefined ? reviewRequest(orderSummary?.requestId.toString(), 'approve') : null;
    };
    // Fn to handle reject order verification requests and set modal values
    const rejectRequest = () => {
        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.transactionRequestReject}`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <Subtitle>
                    Request for&nbsp;
                    <Bold>
                        {orderSummary?.orderNo}
                        {orderSummary?.transactionRef}
                    </Bold>
                    &nbsp;has been rejected, maker will be notified.
                </Subtitle>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        orderSummary?.requestId !== undefined
            ? reviewRequest(orderSummary?.requestId.toString(), 'reject', rejectRemarks)
            : null;
    };
    const rerouteOrder = () => {
        const data: ITableData = {
            ['orderNo']: orderSummary?.orderNo,
            ['transactionRef']: orderSummary?.transactionRef,
        };
        const innerRoutes = ['profile', 'document', 'track'];
        const innerRouteFound = innerRoutes.map((_route) => {
            return history.location.pathname.includes(_route);
        });
        if (innerRouteFound.filter(Boolean).length !== 0) {
            const tempArray = history.location.pathname.split('/');
            tempArray.splice(tempArray.length - 2, 2);
            const redirectLocation = tempArray.join('/');
            history.push({ pathname: `${redirectLocation}/${ROUTES.rerouteTransaction}`, state: { data: data } });
        } else history.push({ pathname: ROUTES.rerouteTransaction, state: { data: data } });
    };
    const rejectOrder = () => {
        const innerRoutes = ['profile', 'document', 'track'];
        const innerRouteFound = innerRoutes.map((_route) => {
            return history.location.pathname.includes(_route);
        });
        if (innerRouteFound.filter(Boolean).length !== 0) {
            const tempArray = history.location.pathname.split('/');
            tempArray.splice(tempArray.length - 2, 2);
            const redirectLocation = tempArray.join('/');
            history.push({
                pathname: `${redirectLocation}/${ROUTES.rejectTransaction}`,
                state: { data: redirectState },
            });
        } else history.push({ pathname: ROUTES.rejectTransaction, state: { data: redirectState } });
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };

    const handleModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        if (history.location.pathname.includes(ROUTES.hqAllSubmissions)) {
            previousUrl && history.push(previousUrl, previousPage);
        } else if (locationState.fromInbox) {
            history.location.pathname.includes('/hq/') ? history.push(ROUTES.hqAllSubmissions) : null;
            history.location.pathname.includes('/branch/') ? history.push(ROUTES.dashboardBranch) : null;
        } else {
            if (branchId !== null) {
                history.push(viewBranch, previousPage);
            } else {
                history.push(dashboardBranch, previousPage);
            }
        }
    };

    const handleBack = () => {
        if (fromInbox === true) {
            history.goBack();
        } else if (previousUrl) {
            history.push(previousUrl, previousPage);
        } else {
            if (branchId !== null) {
                history.push(viewBranch, previousPage);
            } else {
                history.push(dashboardBranch, previousPage);
            }
        }
    };

    const handleViewStatusOverview = () => {
        history.push({ pathname: path + ORDER_SUMMARY_LINKS[3], state: locationState });
    };

    //fn to hanlde reject cancel
    const handleRejectCancel = () => {
        setRejectReq(false);
        setRejectRemarks('');
        handleBack();
    };
    useEffect(() => {
        fetchOrderSummary();
        fetchStatusOverView();
        locationState && locationState.fromInbox !== undefined && locationState.fromInbox !== null
            ? setFromInbox(locationState.fromInbox)
            : setFromInbox(false);
        if (isRejectRequest) setRejectReq(true);
    }, []);

    return (
        <Fragment>
            {rejectReq ? (
                <Fragment>
                    <RejectRemarks
                        handleCancel={handleRejectCancel}
                        handleSubmit={rejectRequest}
                        handleRejectRemarks={(e) => {
                            setRejectRemarks(e.target.value);
                        }}
                        rejectRemarks={rejectRemarks}
                    />
                </Fragment>
            ) : (
                <div style={{ position: 'relative' }}>
                    <FlexedDiv style={{ alignItems: 'flex-start' }}>
                        <Tabs
                            tabTitles={[LABEL.transaction, LABEL.profile, LABEL.document, LABEL.tracking]}
                            title={`${orderSummary?.orderNo}${orderSummary?.transactionRef ?? ''}`}
                            tabs={tabs}
                            handleBackFunction={handleBack}
                            testId="order-summary"
                            primary
                            wrapperStyle={{ flex: 1 }}
                            links={ORDER_SUMMARY_LINKS}
                            linkState={locationState}
                        />
                        <TrackingStatusWrapper id="status-overview">
                            <TextDarkBlack weight="700" size="1.125rem">
                                {LABEL.statusOverview}
                            </TextDarkBlack>
                            {ORDER_SUMMARY_LINKS[3] !== `/${window.location.pathname.split('/').at(-1)}` && (
                                <FlexedDiv
                                    alignItems={'center'}
                                    style={{ marginTop: '10px', columnGap: '4px', cursor: 'pointer' }}
                                    onClick={handleViewStatusOverview}
                                >
                                    <TextNavyBlue size="12px" weight="700">
                                        {LABEL.viewDetails}
                                    </TextNavyBlue>
                                    <IcoMoon name="arrow-right" size={'12px'} />
                                </FlexedDiv>
                            )}
                            <TrackEventsWrapper>
                                {statusOverView.map((track, index) => {
                                    return (
                                        <TrackingStepper
                                            steps={track.steps}
                                            title={track.title}
                                            key={index + (track.title ?? 'title')}
                                            expanded={
                                                `/${window.location.pathname.split('/').at(-1)}` ===
                                                ORDER_SUMMARY_LINKS[3]
                                            }
                                        />
                                    );
                                })}
                            </TrackEventsWrapper>
                        </TrackingStatusWrapper>
                    </FlexedDiv>
                    {orderSummary?.status.toLowerCase() === 'in review' &&
                    (pendingTab.actions.canVerifyTransactions === LABEL.maker ||
                        pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer) ? (
                        <Fragment>
                            <Banner
                                title="Transaction Review"
                                description="Transaction Number"
                                descriptionEmText={`${orderSummary?.orderNo}${orderSummary?.transactionRef}`}
                                toggle={true}
                                handleClose={handleAlert}
                                disabled={disableVerify}
                                testId="ordersummary-approval-banner"
                                primaryButtonObject={{
                                    label: LABEL.verify,
                                    icon: 'check',
                                    handlePrimaryBtn: handleVerifyOrder,
                                    width: '168px',
                                }}
                                secondaryButtonObject={[
                                    {
                                        label: 'Reroute',
                                        handleSecondaryBtn: rerouteOrder,
                                        icon: 'reroute',
                                        width: '168px',
                                        isDisabled: locationState.verifyOnly,
                                    },
                                    {
                                        label: 'Reject',
                                        handleSecondaryBtn: rejectOrder,
                                        icon: 'terminate',
                                        color: '#E84C3D',
                                        width: '168px',
                                        isDisabled: locationState.verifyOnly,
                                    },
                                ]}
                            />
                        </Fragment>
                    ) : null}
                    {orderSummary?.status.toLowerCase() === 'pending approval' &&
                    (pendingTab.reviewApproval.canApproveVerify === LABEL.checker ||
                        pendingTab.reviewApproval.canApproveVerify === LABEL.autoAuthorizer) ? (
                        <Fragment>
                            <Banner
                                title={`Request Pending Approval`}
                                isRequestBanner
                                toggle={toggle}
                                primaryButtonObject={{
                                    label: LABEL.approve,
                                    icon: 'check',
                                    handlePrimaryBtn: handleApproveRequest,
                                    width: '260px',
                                }}
                                secondaryButtonObject={{
                                    label: LABEL.reject,
                                    icon: 'terminate',
                                    color: '#E84C3D',
                                    handleSecondaryBtn: () => setRejectReq(!rejectReq),
                                    width: '260px',
                                }}
                                description={`${LABEL.requestedBy}`}
                                descriptionEmText={orderSummary?.requesterName}
                                testId="ordersummary-approval-banner"
                            />
                        </Fragment>
                    ) : null}
                    {showConfirmationModal ? (
                        <Modal
                            title={modalMessages.confirmation.heading}
                            modalActive={showConfirmationModal}
                            setModalActive={handleConfirmModal}
                            primaryBtn={{
                                onClick:
                                    modalMessages.confirmation.type === 'verify'
                                        ? () =>
                                              orderSummary?.transactionRef !== undefined &&
                                              verifyTransactions(orderSummary?.transactionRef)
                                        : () =>
                                              orderSummary?.requestId !== undefined
                                                  ? reviewRequest(orderSummary?.requestId.toString(), 'approve')
                                                  : null,
                                label: LABEL.yes,
                                primary: true,
                            }}
                            secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo }}
                            testId="order-confirmation"
                        >
                            {modalMessages.confirmation.content}
                        </Modal>
                    ) : null}

                    {loading ? <ComponentLoader /> : null}
                </div>
            )}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => handleModal(),
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="order-feedback"
                    contentAlignment="center"
                >
                    {modalMessages.feedback.content}
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default OrderDetails;

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;

const TrackingStatusWrapper = styled.div`
    width: 15vw;
    min-width: 216px;
    background: #fbfbfb;
    margin: 64px 0px 0px 24px;
    padding: 1rem 0.5rem;
    border-radius: 8px;
    height: fit-content;
    transition: all 0.3s;
`;

const TrackEventsWrapper = styled.div`
    width: 100%;
    padding-top: 16px;
    & > *:not(:last-child) {
        margin-bottom: 16px;
    }
`;
