/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { PreviousPage, Banner, FlexedDiv } from '../../components';
import { Modal, TableSheet } from '../../components/organisms';
import { LABEL } from '../../constants';

import fundUploadDataQuery from '../../_graphql/queries/fundData/documentDashboard/fundUploadData';
import bulkUploadStatusMutation from '../../_graphql/mutations/fundData/bulkUploadStatus';
import fileCheckQuery from '../../_graphql/queries/fundData/documentDashboard/fileNameCheck';
import styled from 'styled-components';
import * as Routes from '../../routes';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    fundDocumentId: number;
    title: string;
    status: string;
    fromInbox?: boolean;
    tabIndex?: number;
}

type BulkDocument = {
    fileName: string;
    fileSize: number;
    fundAbbr: string;
    documentType: string;
    signedUrl: string;
};
interface StyledInnerProps {
    paddingBottom: number;
}
type fundData = {
    fundAbbr: string;
    fundName: string;
};
// bulk table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 110,
    secondColWidth: 280,
    thirdColWidth: 62,
};
const ReviewImport: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const { uploads } = parsedPermission.hq.permission;
    const { state: propsData } = useLocation<IRouteProps>();
    const history = useHistory();
    // states for user permissions
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    // states for modals, title and description
    const [pageTitle, setPageTitle] = useState<string>('');
    const [showModal, setShowModal] = useState(true);
    const [requestedBy, setRequestedBy] = useState<string>('');
    // states to generate table
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [externalLinks, setExternalLinks] = useState<string[]>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [fundNames, setFundNames] = useState<fundData[]>([]);
    const [fundAbbr, setfundAbbr] = useState<string[]>([]);
    const [bulkDocumentsTableData, setBulkDocumentsTableData] = useState<BulkDocument[]>([]);
    const [approvedFundDetails, setApprovedFundDetails] = useState({
        status: propsData.status,
        remark: '',
        user: '',
    });
    const [bannerHeight, setBannerHeight] = useState<number>(0);

    // check page permissions
    const checkPermissions = () => {
        if (
            uploads.documentsTab.reviewApproval.canApproveBulkUpload === 'auto-authorizer' ||
            uploads.documentsTab.reviewApproval.canApproveBulkUpload === 'checker'
        ) {
            setIsAuthorized(true);
        }
        propsData && propsData.status === 'Pending Approval'
            ? setPageTitle('Review Bulk Upload Documents Request')
            : setPageTitle('Bulk Upload Documents');
    };
    //call query to get bulkupload documents by  id
    const getFundUploadDataQuery = async (id: number) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundUploadDataQuery, {
                    input: {
                        fundDocumentId: id,
                    },
                }),
                idTokenHeader,
            );
            const { error } = await response.data.fundUploadData;
            if (!error) {
                const { result } = await response.data.fundUploadData.data;
                const rawData: BulkDocument[] = JSON.parse(result.data);
                // process to bind resposnse data to  table
                // processBulkDocumentData(rawData);
                const _fundAbbr: string[] = [];
                Object.values(rawData).map((data) => {
                    _fundAbbr.push(data.fundAbbr);
                });
                setfundAbbr(_fundAbbr);
                setBulkDocumentsTableData(rawData);
                //to check whether request is approved or not
                setApprovedFundDetails(result.transaction);
                setRequestedBy(result.user);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving ${propsData.title} request failed`,
                    testId: 'review-bulkimport-error-modal',
                });
                handleErrorHandler();
            }
            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {}
    };

    //function to get fund names from fund abbreviations
    const uploadBulkFileHandler = async (fundAbbr: string[]) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fileCheckQuery, {
                    input: {
                        tab: 'documents',
                        fundAbbr: fundAbbr,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fileCheck;
            if (!error) {
                setFundNames(data.result.fundData);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving ${propsData.title} request failed`,
                    testId: 'review-bulkimport-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };
    //mutation to approve bulkupload docments
    const approveBulkUploadStatusMutation = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(bulkUploadStatusMutation, {
                    input: {
                        fundDocumentId: propsData.fundDocumentId,
                        status: 'approve',
                        remark: '',
                    },
                }),
                idTokenHeader,
            );
            const { error } = await response.data.bulkUploadStatus;
            if (!error) {
                handleShowConfirmation();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving ${propsData.title} request failed`,
                    testId: 'review-bulkimport-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };
    // fn to process the response data and generate the sheet table
    const processBulkDocumentData = (rawData: BulkDocument[]) => {
        //build column objects for sheet table
        const tempCols: string[] = ['Fund Documents (.pdf)', 'File Size', 'Fund Name', 'Documents'];
        const columnsOutput: ISheetColumn[] = [];
        let columnObj: any = {};

        try {
            tempCols.map((column: string) => {
                columnObj = {
                    colName: column,
                    type: 'string',
                };
                columnsOutput.push(columnObj);
            });

            //build cell objects for sheet table
            let cellObj: any = {};
            const cellRow: ISheetCell[][] = [];

            const tempCells: BulkDocument[] = rawData.map((document: BulkDocument) => document);
            const externalLinkCells: string[] = []; //to separate the external links
            //make tempCells to objects and then push to an array
            tempCells.map((row: BulkDocument) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, columnIndex) => {
                    if (columnIndex === 0) {
                        externalLinkCells.push(row.signedUrl);
                    }
                    switch (column.colName) {
                        case 'Fund Documents (.pdf)':
                            cellObj = {
                                cellValue: row.fileName,
                                isValid: true,
                            };
                            break;
                        case 'File Size':
                            cellObj = {
                                cellValue: row.fileSize,
                                isValid: true,
                                isFileSize: true,
                            };
                            break;
                        case 'Fund Name':
                            cellObj = {
                                cellValue: getFundName(row.fundAbbr),
                                isValid: true,
                            };
                            break;
                        case 'Documents':
                            cellObj = {
                                cellValue: row.documentType,
                                isValid: true,
                            };
                            break;
                        default:
                    }

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setExternalLinks(externalLinkCells);
            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };
    //function to get fund name from state
    const getFundName = (fundAbbr: string): string => {
        let _fundName = '';
        if (fundNames) {
            const fundObj = fundNames.find((item) => item.fundAbbr === fundAbbr);
            fundObj ? (_fundName = fundObj.fundName) : (_fundName = 'FUND NAME NOT IN SYSTEM');
        }

        return _fundName;
    };
    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    const handleReject = () => {
        history.push({
            pathname: Routes.financeRejectImport,
            state: {
                fundDocumentId: propsData.fundDocumentId,
                type: 'Bulk Upload Documents',
                tab: 'documents',
                tabIndex: propsData.tabIndex,
            },
        });
    };
    const goBacktoUploadDashboard = () => {
        if (propsData.fromInbox) {
            history.push({
                pathname: Routes.hqInbox,
            });
        } else {
            history.push({
                pathname: Routes.financeUploads,
                state: {
                    activeTab: propsData.tabIndex,
                },
            });
        }
    };
    useEffect(() => {
        checkPermissions();
        setIsProcessing(true);
        getFundUploadDataQuery(propsData.fundDocumentId);
    }, []);
    useEffect(() => {
        fundAbbr.length !== 0 && uploadBulkFileHandler(fundAbbr);
    }, [fundAbbr]);
    useEffect(() => {
        processBulkDocumentData(bulkDocumentsTableData);
    }, [fundNames]);
    return (
        <Fragment>
            <PreviousPage title={pageTitle} handleBackFunction={goBacktoUploadDashboard} />
            <StyledInner paddingBottom={bannerHeight}>
                {propsData && propsData.status === 'Pending Approval' && (
                    <PageDescription>
                        Please review the following request. You can either approve it or reject it using the buttons in
                        the bottom right corner. <br /> You’ll also be able to leave additional remark if you reject it.
                    </PageDescription>
                )}
                {propsData && propsData.fromInbox && propsData.status !== LABEL.pendingApproval ? (
                    <ApprovalBanner direction="column">
                        <Title>{`This request was ${
                            propsData.status.toLowerCase() === 'active' ? 'approved' : propsData.status.toLowerCase()
                        } by ${approvedFundDetails.user}`}</Title>

                        <FlexedDiv
                            style={{ paddingTop: '0.5rem', width: '100%', whiteSpace: 'break-spaces' }}
                            justifyContent="space-between"
                        >
                            {approvedFundDetails.remark !== undefined && approvedFundDetails.remark !== null
                                ? `${LABEL.rejectedReason}: ${approvedFundDetails.remark}`
                                : ''}
                        </FlexedDiv>
                    </ApprovalBanner>
                ) : null}
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <TableSheet
                        columns={columns}
                        rows={rows}
                        readyOnly={true}
                        externalLinks={externalLinks}
                        hasExternalLinks={true}
                        columnWidths={fixedCoLumnlWidth}
                    />
                )}
            </StyledInner>
            {isAuthorized && propsData.status === 'Pending Approval' && (
                <Banner
                    title="Pending Approval"
                    description={`Requested by`}
                    descriptionEmText={requestedBy}
                    toggle={true}
                    setBannerHeight={setBannerHeight}
                    testId="review-bulkimport-banner"
                    primaryButtonObject={{
                        handlePrimaryBtn: () => approveBulkUploadStatusMutation(),
                        label: LABEL.approve,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: () => handleReject(),
                        label: LABEL.reject,
                    }}
                />
            )}
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={handleShowConfirmation}
                    title={`Bulk Upload Documents request approved`}
                    primaryBtn={{
                        onClick: () => {
                            goBacktoUploadDashboard();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="review-bulkimport-modal"
                    contentAlignment="center"
                    icon="bulk-modal-processing"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${rows.length} fund documents has been updated.`}{' '}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 2.6rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;

const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
export default ReviewImport;
