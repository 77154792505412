import gql from 'graphql-tag';

export const EnableOmniMutation = gql`
    mutation enableOmni($input: IdActionInput) {
        enableOmni(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
