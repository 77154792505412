import gql from 'graphql-tag';

export const ceCheckerViewRequest = gql`
    query transactionRequest($input: TransactionRequestInput) {
        transactionRequest(input: $input) {
            data {
                result {
                    orderNo
                    requestId
                    requestorName
                    reasons {
                        reasonId
                        category
                        subCategory
                    }
                    eventType
                    targetDate
                    approval {
                        name
                        eventType
                        status
                        remarks
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
