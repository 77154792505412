/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, ReactText, useContext, useEffect, useMemo, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { IReasons } from '../../../RejectTransaction/RejectTransaction';
import { IRerouteRequest } from '../../../RerouteTransaction/reasonsToReroute';
import { PreviousPage, CustomSpacer, ComponentLoader, IMultiSelectLabel } from '../../../../components';
import { removeArrayElement } from '../../../../utils';
import { LABEL } from '../../../../constants';
import CheckboxWithMultiSelectV2, { DatePicker } from '../../../../components/organisms';
import CheckboxWithInput from '../../../../components/organisms/CheckboxWithInput';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { useComponentLoader } from '../../../../customHooks';
import styled from 'styled-components';
import moment from 'moment';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

const sampleState = [[], [], []];

export const RerouteActivity: React.FC = () => {
    const [rerouteData, setRerouteData] = useState<IReasons[]>([]);
    const [checkedReasons, setCheckedReasons] = useState<Array<boolean>>([false]);
    const [checkedValues, setCheckedValues] = useState<Array<ReactText>>([]);
    const [remark, setRemark] = useState<string>('');
    const [dueDate, setDueDate] = useState<string>('');
    const [isRequest] = useState<boolean>(true);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [other, setOthers] = useState({
        checked: false,
        value: '',
    });
    const [requestData, setRequestData] = useState<IRerouteRequest>({
        eventType: '',
        requestorName: '',
        reasons: [],
        requestId: '',
    });
    const location = useLocation();
    const transaction = location.state as ITableData;
    //loading
    const { loading, loadingHandler } = useComponentLoader();

    /** @requestDetails */
    const getRequestDetails = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: transaction.id,
                    },
                }),
                idTokenHeader,
            );

            const result = response.data.getLogData.data.result;
            if (response.data.getLogData.error !== null) throw result.error;
            if (result.data.data === null) {
                setErrorMessage({
                    ...errorMessage,
                    message: 'Data is null',
                    errorCode: '',
                    title: 'Cannot Fetch Reroute Data',
                    testId: 'activitylogs-reroute-error-modal',
                });
                handleErrorHandler();
            } else {
                const allData = JSON.parse(result.data.data);

                const { reasons } = allData.transactionRequestData;
                const update: IReasons[] = [];
                let subs: IMultiSelectLabel[] = [];

                reasons.map((reason: any) => {
                    subs = [];
                    if (reason.category !== 'Others') {
                        if (reason.subCategory !== null) {
                            reason.subCategory.map((sub: any) => {
                                subs.push({
                                    key: sub[0],
                                    value: sub[1],
                                    label: sub[1],
                                });
                            });
                            update.push({ title: reason.category, reasons: subs });
                        } else update.push({ title: reason.category, reasons: [] });
                    }
                });
                setRerouteData(update);
                const requestDataFromApi = {
                    eventType: '',
                    requestorName: '',
                    reasons: reasons,
                    requestId: '',
                };
                setDueDate(allData.targetDate);
                setRequestData(requestDataFromApi);
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Fetch Reroute Data',
                testId: 'activitylogs-reroute-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleCheckboxMultiSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const id = parseInt(e.target.id);
        const check = e.target.checked;
        let tempValues = [...checkedValues];
        const tempChecked = [...checkedReasons];
        if (check) {
            tempValues.push(value);
            tempChecked[id] = check;
        } else {
            tempValues = removeArrayElement(tempValues, value);
            tempChecked[id] = false;
        }

        setCheckedReasons(tempChecked);
        setCheckedValues(tempValues);
    };
    const handleCheckboxOthers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const check = e.target.checked;
        if (check) {
            setOthers({ checked: check, value: value });
        } else {
            setOthers({ checked: false, value: '' });
        }
    };

    const handleRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemark(event.target.value);
    };
    const [multiSelectDropdownState, setMultiSelectDropdownState] = useState<Array<Array<ReactText>>>(sampleState);
    const handleMultiSelectDropdown = (item: Array<ReactText>, fn: string, id: number) => {
        const tempState = [...multiSelectDropdownState];
        tempState[id] = item;
        setMultiSelectDropdownState(tempState);
    };

    const getRequestReasons = () => {
        const tempState = [...multiSelectDropdownState];
        let tempSub = '';
        let tempRemarks = remark;

        requestData.reasons.map((item, index) => {
            const subs: ReactText[] = [];
            if (item.subCategory !== null && item.category !== 'Others') {
                item.subCategory.map((sub: any) => {
                    subs.push(sub[1]);
                });
                tempState[index] = subs;
            } else if (item.subCategory !== null && item.category === 'Others') {
                item.subCategory.map((sub: any) => {
                    tempSub = sub[1];
                });
                tempRemarks = tempSub;
            }
        });
        setMultiSelectDropdownState(tempState);
        setRemark(tempRemarks);
    };

    const checkboxStatus = (index: number): boolean => {
        let temp = false;
        if (isRequest === false) {
            temp = checkedReasons[index] !== undefined ? checkedReasons[index] : false;
        } else {
            if (requestData.reasons.length !== 0) temp = requestData.reasons[index].subCategory !== null;
        }
        return temp;
    };
    const otherStatus = (): boolean => {
        let temp = false;
        if (isRequest === false) temp = other.checked;
        else {
            if (requestData.reasons.length !== 0) {
                requestData.reasons.map((item) => {
                    if (item.subCategory !== null && item.category === 'Others') {
                        temp = true;
                    }
                });
            }
        }
        return temp;
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    useMemo(() => {
        requestData.reasons.length !== 0 ? getRequestReasons() : null;
    }, [requestData]);

    return (
        <Fragment>
            <PreviousPage title={isRequest ? LABEL.reviewRerouteRequest : LABEL.rerouteTransaction} />
            <TabWrapper>
                {loading ? <ComponentLoader /> : null}
                <div style={{ marginLeft: '3.5rem' }}>
                    <ReasonsLabel>{LABEL.reasonsToReroute}</ReasonsLabel>
                    <WrapperDiv>
                        {rerouteData.map(({ title, reasons }, index) => {
                            return (
                                <div key={`reason_${index}`} style={{ marginBottom: '1.5rem' }}>
                                    <CheckboxWithMultiSelectV2
                                        label={title}
                                        value={title}
                                        id={index.toString()}
                                        checked={checkboxStatus(index)}
                                        handleCheckbox={handleCheckboxMultiSelect}
                                        data={reasons}
                                        placeholder={LABEL.dropdownPlaceholderReason}
                                        selectLabel={LABEL.reasons}
                                        hasSelectAll
                                        disabled={true}
                                        multiSelectState={multiSelectDropdownState[index]}
                                        handleMultiSelectDropdown={handleMultiSelectDropdown}
                                    />
                                </div>
                            );
                        })}
                        <CheckboxWithInput
                            label={LABEL.others}
                            value={LABEL.others}
                            id={LABEL.others}
                            text={remark}
                            handleText={handleRemarks}
                            checked={otherStatus()}
                            disabled={true}
                            handleCheckbox={handleCheckboxOthers}
                        />
                    </WrapperDiv>
                    <Fragment>
                        <StyledDivider />
                        <DateLabel className="reject_transaction_datePicker_label">{LABEL.dueDate}</DateLabel>
                        <InputWrap>
                            <DatePicker
                                setTargetDate={() => moment(parseInt(dueDate, 10))}
                                targetDate={[moment(parseInt(dueDate, 10)), null]}
                                range={false}
                                width="360"
                                primaryBorderColor="#002043"
                                disable={true}
                            />
                        </InputWrap>
                    </Fragment>
                </div>
                <CustomSpacer space={'2rem'} />
            </TabWrapper>
        </Fragment>
    );
};

const TabWrapper = styled.div`
    position: relative;
    pointer-events: none;
`;
const ReasonsLabel = styled.div`
    font-size: 1.125rem;
    letter-spacing: -0.2px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.25rem;
`;
const WrapperDiv = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 2.25rem;
    max-width: 25vw;
`;
const DateLabel = styled.div`
    margin-left: 0.5rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #333333;
    opacity: 0.5;
`;
const StyledDivider = styled.div`
    height: 1px;
    mix-blend-mode: normal;
    opacity: 0.1;
    border: 1px solid #333333;
    margin: 2rem -1.9vw 2rem -5.11vw;
    width: auto !important;
`;
const InputWrap = styled.div`
    width: 18.33vw;
    min-width: 18.33vw;
    margin-right: 1rem;
    height: 2.5rem;
`;
