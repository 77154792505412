import React from 'react';
import styled from 'styled-components';

interface TheadProps {
    columns: IStandardColumns[];
}
export const Thead: React.FC<TheadProps> = ({ columns }: TheadProps) => {
    return (
        <StyledThead>
            {columns.map((th: IStandardColumns) => (
                <Th key={th.thTitle}>{th.thTitle}</Th>
            ))}
        </StyledThead>
    );
};

const StyledThead = styled.thead`
    width: 100%;
`;
const Th = styled.th`
    width: auto;
    padding: 0.5rem;
    font-size: 0.6rem;
    font-weight: 700;
`;
