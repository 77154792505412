import React, { Fragment } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import { FlexedDiv } from '../../atoms';
import { DataWithLabel } from '../../molecules';
import { TextDarkBlack, TextDarkGrey6, TextDarkGrey5 } from '../../../constants';

type GridDivProps = {
    disableGrid?: boolean;
};

export const GridData = ({ data, sectionTitle }: ISection): JSX.Element => {
    return (
        <GridDiv
            disableGrid={data.length !== 0 && data[0].label === 'Fund Objective' ? true : false}
            id={`grid--${
                sectionTitle?.title !== undefined
                    ? sectionTitle.title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()
                    : 'section'
            }`}
        >
            {data.map(
                ({ data, label, labelIcon, dataIcon, direction, onClickData, prefix, subText, multiLinks }, index) => {
                    return (
                        <Fragment key={`data${index}`}>
                            <DataWithLabel
                                data={data}
                                dataIcon={dataIcon}
                                direction={direction !== undefined ? direction : 'column'}
                                label={label}
                                labelIcon={labelIcon}
                                onClickData={onClickData}
                                prefix={prefix}
                                subText={subText}
                                multiLinks={multiLinks}
                            />
                            <div />
                        </Fragment>
                    );
                },
            )}
        </GridDiv>
    );
};

export const Section: React.FC<ISection | ISectionWithSubSections> = ({
    sectionTitle,
    data,
    subSection,
}: ISection | ISectionWithSubSections) => {
    return (
        <SectionElm>
            {sectionTitle?.title !== undefined ? (
                <>
                    <SectionTitle>
                        <SectionTitleLeft id="section-title-left">
                            {sectionTitle.titleIcon !== undefined ? (
                                <IcoMoon
                                    name={sectionTitle.titleIcon}
                                    size="1.5rem"
                                    style={{ marginRight: '.625rem' }}
                                    color="002043"
                                />
                            ) : null}

                            <TextDarkBlack weight="700">{sectionTitle.title}</TextDarkBlack>

                            {sectionTitle.badgeColor !== undefined && sectionTitle.badgeLabel !== undefined ? (
                                <StatusIndicator id="section-title-left-badge" color={sectionTitle.badgeColor}>
                                    {sectionTitle.badgeLabel}
                                </StatusIndicator>
                            ) : (
                                <div />
                            )}
                        </SectionTitleLeft>
                        <LineBlock />
                        <SectionTitleRight id="section-title-right">
                            <TextDarkBlack weight="700">{sectionTitle.rightLabel}</TextDarkBlack>
                        </SectionTitleRight>
                    </SectionTitle>
                    {sectionTitle.bottomLabel && (
                        <SectionBottomLabel>
                            <TextDarkGrey5 size="12px">{sectionTitle.bottomLabel}</TextDarkGrey5>
                        </SectionBottomLabel>
                    )}
                </>
            ) : null}
            {subSection
                ? subSection.map((sub, index) => {
                      return (
                          <SubSectionWrapper key={sub.sectionTitle?.title ?? 'subsection' + index}>
                              <FlexedDiv direction="row" justifyContent={'space-between'} alignItems="center">
                                  <TextDarkBlack weight="bold" size="12px">
                                      {sub.sectionTitle?.title}
                                  </TextDarkBlack>
                                  <TextDarkGrey6 size="10px">{sub.sectionTitle?.rightLabel}</TextDarkGrey6>
                              </FlexedDiv>
                              {/* <SubSectionBorder /> */}
                              <GridData data={sub.data} sectionTitle={sectionTitle} key={index} />
                          </SubSectionWrapper>
                      );
                  })
                : data && <GridData data={data} sectionTitle={sectionTitle} />}
        </SectionElm>
    );
};
const SectionElm = styled.div`
    display: flex;
    flex-direction: column;
    // padding: 1.5rem;
`;
const SectionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;
const SectionTitleLeft = styled.div`
    display: flex;
    align-items: flex-start;
`;
const SectionBottomLabel = styled.div`
    margin-left: 2.125rem;
`;
const SectionTitleRight = styled.div`
    display: flex;
    align-items: center;
    border-left: '2px solid #c61230';
    margin: '24px 1.5rem 0px';
`;

const SubSectionBorder = styled.div`
    margin-top: 8px;
    width: 100%;
    border: 1px solid #c61230;
`;
const SubSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const LineBlock = styled.div`
    border: 1px solid #c6cbd4;
    margin: 0px 1rem 0px 1rem;
    flex: 1 0 auto;
`;
const GridDiv = styled.div<GridDivProps>`
    display: grid;
    grid-template-columns: ${(props) => (props.disableGrid ? 'none' : '1.25fr 0.5fr 1.25fr 0.5fr 2fr 0.25fr')};
    // margin-top: 1rem;
    /* grid-template-columns: 1.25fr 0.5fr 1.25fr 0.5fr 1.25fr 0.5fr; */
`;
const StatusIndicator = styled.div`
    height: 1rem;
    padding: 2px 4px;
    line-height: 0.75rem;
    font-size: 0.625rem;
    text-transform: capitalize;
    color: ${(props: { color: string }) => props.color};
    letter-spacing: 0.01em;
    border: ${(props: { color: string }) => `0.5px solid ${props.color}`};
    border-radius: 4px;
    margin-top: 3px;
    margin-left: 8px;
`;
