import gql from 'graphql-tag';
/**
 * @branchDashboard @query - Transaction Dashboard
 * @description: Returns all tabs in a dashboard with default ‘Daily’ tab
 */

export const allDailyQueryV2 = /* GraphQL */ gql`
    query allSubmittedDashboard($input: BranchDashboardInput) {
        allSubmittedDashboard(input: $input) {
            data {
                result {
                    filters {
                        transactionType
                        fundType
                        paymentMethod
                        accountType
                        branchStatus
                    }
                    groupedTransactions {
                        grouping {
                            type
                            groupingData {
                                key
                                value
                            }
                            transactionData {
                                grouping {
                                    type
                                    groupingData {
                                        key
                                        value
                                    }
                                    transactionData {
                                        grouping {
                                            type
                                            groupingData {
                                                orderNo
                                                principalName
                                                principalNumber
                                                jointName
                                                jointNumber
                                                sameBranch: viewable
                                            }
                                            transactionData {
                                                data {
                                                    requestId
                                                    isVerified
                                                    fundCode
                                                    lastUpdated
                                                    submittedOn
                                                    createdOn
                                                    orderNo
                                                    transactionRef
                                                    accountType
                                                    clientName
                                                    clientIdNum
                                                    transactionType
                                                    fundType
                                                    paymentMethod
                                                    fundCategory
                                                    totalInvestment {
                                                        fundCurrency
                                                        investmentAmount
                                                    }
                                                    agentName
                                                    agentCode
                                                    status
                                                    remarks {
                                                        title
                                                        content
                                                    }
                                                    cutOffTime
                                                    batchTime
                                                    targetDate
                                                    aging
                                                    approvalType
                                                    utmc
                                                    isSeen
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    dailyCount
                    upcomingCount
                    hardcopyCount
                    rerouteCount
                    historyCount
                    page
                    pages
                    enableBulkVerify
                    changeRequestsCount
                    transactionsCount
                    batchCount {
                        batchTime
                        count
                    }
                    typeCount
                    typeSeen
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
