import React, { Fragment } from 'react';
import Pill from '.';
import { FlexedDiv } from '../../atoms';

interface IPillButtonGroupProps {
    selectedPill: number;
    pillGroup: ITags[];
    label: string;
    handlePill: (item: ITags) => void;
    pillStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    testId?: string;
}
export const PillButtonGroup = ({
    pillGroup,
    selectedPill,
    label,
    handlePill,
    pillStyle,
    style,
    testId,
}: IPillButtonGroupProps): JSX.Element => {
    return (
        <Fragment>
            <FlexedDiv style={{ ...style, marginLeft: '1rem' }} alignItems="baseline">
                <div style={{ color: '#4D4D4D', fontSize: '.75rem', marginRight: '.5rem' }}>{label}</div>
                {pillGroup.map((item, index) => (
                    <Pill
                        active={selectedPill === index}
                        key={index + 1}
                        onClick={() => {
                            handlePill(item);
                        }}
                        style={pillStyle}
                        id={`${testId}-${
                            item.title !== undefined
                                ? `${item.title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}-pill`
                                : 'pillGroupBtn'
                        }`}
                    >
                        <FlexedDiv>
                            <div>{item.title}</div>&nbsp;
                            <div>{item.count !== undefined ? ` (${item.count}) ` : null}</div>
                        </FlexedDiv>
                    </Pill>
                ))}
            </FlexedDiv>
        </Fragment>
    );
};
