/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { useState } from 'react';
import { useComponentLoader } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';

import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

import InboxContext, { InboxContextProps } from './InboxContext';
import { IFilterBranch } from '../../pages/DashboardBranch/dashboardBranchTypes';
import { inboxQuery } from '../../_graphql/queries/inbox/inboxQuery';
import { makeNotificationObject } from '../../pages/Inbox/makeNotificationObject';

interface InboxProviderProps {
    children: React.ReactNode;
}
const initialSearchState: IFilterBranch = {
    value: '',
    column: 'all',
};
export const InboxProvider: React.FC<InboxProviderProps> = ({ children }: InboxProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [tab, setTab] = useState(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(1);
    const [message, setMessage] = useState<Array<INotificationObject>>([]);
    const [newMessageCount, setNewMessageCount] = useState<number>(0);
    const [unreadIds, setUnreadIds] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState(initialSearchState);

    const { loadingHandler, loading } = useComponentLoader();

    const parsedPermission = JSON.parse(userLoginContext.permission);

    const getInboxData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(inboxQuery, {
                    input: {
                        page: page,
                        resultLimit: resultLimit.toString(),
                        search: searchInput.value,
                        source: getSource(),
                    },
                }),
                idTokenHeader,
            );
            /*** @errorCheck */
            const resultCheck = response.data.inboxDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;
            /*** @passData */
            const { result } = response.data.inboxDashboard.data;
            setMessage(makeNotificationObject(result.inbox));
            const ids: string[] = [];
            result.inbox.map((item: any) => {
                if (item.isRead === false) {
                    ids.push(item.notificationId);
                }
            });
            setUnreadIds(ids);
            if (page > result.pages) {
                setPage(1);
            }
            setPages(parseInt(result.pages));
            setNewMessageCount(result.newMessageCount);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Inbox Notifications',
                testId: 'inbox-error-modal',
            });
            handleErrorHandler();
        }
    };

    const getSource = () => {
        let temp = ['ce'];
        if (parsedPermission.branch.grant !== undefined) {
            temp = ['ce'];
        } else {
            const permissionParsed = JSON.parse(userLoginContext.permission);
            // console.log('pp', permissionParsed);
            if (
                permissionParsed.hq.permission.userManagement !== undefined &&
                permissionParsed.hq.permission.userManagement.isAll === 'true'
            ) {
                temp = ['systemadmin'];
            }
            if (
                permissionParsed.hq.permission.activityLogs !== undefined &&
                permissionParsed.hq.permission.activityLogs.isAll
            ) {
                temp = ['systemadmin'];
            }
            if (
                permissionParsed.hq.permission.systemSettings !== undefined &&
                permissionParsed.hq.permission.systemSettings.isAll
            ) {
                temp = ['systemadmin'];
            }
            if (
                permissionParsed.hq.permission.productSettings !== undefined &&
                permissionParsed.hq.permission.productSettings.isAll
            ) {
                temp = ['finance'];
            }
            if (permissionParsed.hq.permission.uploads !== undefined && permissionParsed.hq.permission.uploads.isAll) {
                temp = ['finance'];
            }
            if (
                permissionParsed.hq.permission.financeDashboard !== undefined &&
                permissionParsed.hq.permission.financeDashboard.isAll
            ) {
                temp = ['finance'];
            }
            if (
                permissionParsed.hq.permission.financeCases !== undefined &&
                permissionParsed.hq.permission.financeCases.isAll
            ) {
                temp = ['finance'];
            }
            if (permissionParsed.hq.permission.eddCase !== undefined && permissionParsed.hq.permission.eddCase.isAll) {
                temp = ['amla'];
            }
        }
        return temp;
    };
    const ProviderValue: InboxContextProps = {
        getInboxData,
        getSource,
        loading,
        loadingHandler,
        message,
        newMessageCount,
        page,
        pages,
        resultLimit,
        searchInput,
        setMessage,
        setNewMessageCount,
        setPage,
        setPages,
        setResultLimit,
        setSearchInput,
        setTab,
        setUnreadIds,
        tab,
        unreadIds,
    };
    return <InboxContext.Provider value={ProviderValue}>{children}</InboxContext.Provider>;
};

export default InboxProvider;
