import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FlexedDiv, Avatar } from '../../components';
import { TextDarkBlack } from '../../constants';

import { BankInformationSection, MultiSection } from '../../templates';
import { getInitials, userColorPicker } from '../../utils';
import { IBankInformation, IClientProfile } from '../OrderDetails/orderSummaryTypes';

import styled from 'styled-components';

interface accountDetailsProps {
    profile: IClientProfile[];
}

/**
 * Profile details tab in order summary page.
 * @category Pages
 * @component
 * @namespace AccountDetails
 * @returns Profile tab in order summary page.
 */
export const AccountDetails: React.FC<accountDetailsProps> = ({ profile }: accountDetailsProps) => {
    const [holder, setHolder] = useState<IClientProfile>(profile[0]);
    const [accountSections, setAccountSections] = useState<Array<IBankInformation | IProfileSection>>([]);

    const color = userColorPicker('user');
    useMemo(() => {
        setHolder(profile[0]);
    }, [profile]);
    useEffect(() => {
        const _tempSections: Array<IBankInformation | IProfileSection> = [];
        const _expands: boolean[] = [];
        Object.keys(holder as IClientProfile).forEach((key) => {
            switch (key) {
                case 'personalDetails':
                case 'employmentInformation':
                case 'fatcaDeclaration':
                case 'crsDeclaration':
                case 'epfDetails':
                case 'feaDeclaration':
                case 'contactDetails':
                case 'addressInformation':
                    if (holder[key] !== undefined) {
                        _tempSections.push(holder[key] as IProfileSection);
                    }
                    break;
                case 'bankInformation':
                    if (holder[key] !== undefined) {
                        holder.bankInformation &&
                            holder.bankInformation.sections[0].length > 0 &&
                            _tempSections.push(holder[key] as IBankInformation);
                    }
                    break;
            }
        });

        _tempSections.forEach((_result, index) => {
            if (index === 0) {
                _expands.push(true);
            } else {
                _expands.push(false);
            }
        });

        // setExpands(_expands);
        setAccountSections(_tempSections);
    }, [profile, holder]);
    return (
        <Fragment>
            {profile.length > 0 ? (
                <Fragment>
                    <Profile>
                        <FlexedDiv alignItems="center">
                            <Avatar
                                size={80}
                                customStyles={{
                                    color: '#fff',
                                    backgroundColor: color,
                                    boxShadow:
                                        '3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06);',
                                    border: '1px solid #fff',
                                    fontWeight: 700,
                                    fontSize: '1.5rem',
                                }}
                                testId="account"
                            >
                                {profile.length > 0 ? getInitials(holder.name) : null}
                            </Avatar>

                            <FlexedDiv direction="column" style={{ marginLeft: '1.5rem', width: '16.667vw' }}>
                                <TextDarkBlack weight="700" size="1.5rem">
                                    {holder.name}
                                </TextDarkBlack>
                                <TextDarkBlack style={{ color: 'rgba(42, 54, 90, 0.8)' }}>{holder.id}</TextDarkBlack>
                            </FlexedDiv>
                        </FlexedDiv>
                    </Profile>
                    {accountSections.map((result, index) => (
                        <BlockContainer
                            key={`container${index + 1}`}
                            id={`${
                                result.title && result.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                            }-container`}
                        >
                            <BlockContainerHeader>
                                <TextDarkBlack weight="700" size="18px">
                                    {result.title}
                                </TextDarkBlack>
                            </BlockContainerHeader>
                            <BlockContainerContent>
                                {result.title?.toLowerCase() === 'bank information' ? (
                                    <BankInformationSection sections={result.sections as ISection[][]} />
                                ) : (
                                    <MultiSection sections={result.sections as ISection[]} />
                                )}
                            </BlockContainerContent>
                        </BlockContainer>
                    ))}
                </Fragment>
            ) : (
                <div>Data not available</div>
            )}
        </Fragment>
    );
};

const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;

const Profile = styled.div`
    margin: 0rem 1.5rem 1.5rem;
`;
