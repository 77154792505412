import React, { Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker, FlexedDiv, PreviousPage } from '../../../../components';
import { SYSTEM_ADMIN, TextDarkBlack, TextDarkGrey5, TextDarkGrey6 } from '../../../../constants';
import { ROUTES } from '../../..';
import { useComponentLoader } from '../../../../customHooks';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import styled from 'styled-components';
import CustomRadioButton from '../../../DashboardAmla/Helpers/CustomRadioButton';
import CustomCheckbox from '../../../../components/atoms/Checkbox';
import moment from 'moment';
import TopBanner from '../../SystemSettings/EPF/TopBanner';

/**
 * Edit EPF activity page
 * @category Pages
 * @component
 * @namespace ActivityLogs
 * @returns Edit EPF activity component
 */

interface IEditEPFActivityRouteProps {
    id: string;
    status?: string;
}

type DisableViewProps = {
    value?: boolean;
};

type RejectRemarksEPF = {
    remarks: string;
    user: string;
};
export const EditEPFActivity: React.FC = (): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const history = useHistory();
    const { state: routeData } = useLocation<IEditEPFActivityRouteProps>();
    // Data states
    const [userActivity, setUserActivity] = useState<IUserActivity>();
    const [editEPFActivity, setEditEPFActivity] = useState<IEditEPFActivityLog>();
    const [remarks, setRemarks] = useState<string>('');
    //Form states
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [isRadioButtonUpdated, setIsRadioButtonUpdated] = useState<boolean>(false);
    const [effectiveDate, setEffectiveDate] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    const [disableView, setDisableView] = useState<boolean>(true);
    //Custom hooks
    const { loading, loadingHandler } = useComponentLoader();

    //Fn to route back to previous page
    const goBack = () => {
        history.push({
            pathname: ROUTES.activityLogs,
        });
    };
    //Fn to call activitylog summary
    const getActivityLogQuery = async (id: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = response.data.getLogData;

            if (!error) {
                setUserActivity(data.result.data);
                const _epfData: IEditEPFActivityLog = JSON.parse(data.result.data.data);
                // console.log(_epfData, data.result.data);
                if (_epfData) {
                    setEffectiveDate([moment(parseInt(_epfData.effectiveDate)), null]);
                    setEditEPFActivity(_epfData);
                }
            } else throw error;

            // setTimeout(() => {
            //     setIsProcessing(false);
            // }, 0);
        } catch (error) {
            setErrorMessage({
                ...errorMessage,
                message: error.message,
                errorCode: error.errorCode,
                title: 'Cannot Fetch Edit EPF Data',
                testId: 'activitylogs-edit-epf-error-modal',
            });
            handleErrorHandler();
        }
    };

    // Fn to handle setting disable/enable radio button
    const handleRadioButton = () => {
        setIsSelected(!isSelected);
        setIsRadioButtonUpdated((prv) => {
            if (!prv) return true;
            else return false;
        });
    };
    // Fn to handle effective setting date

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const dates = [...date] as [moment.Moment | null, moment.Moment | null];
        setEffectiveDate(dates);
    };
    // Fn  to handle email notification checkbox
    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setSendAgent(e.target.checked);
    };
    // Fn Function to handle inbox notification checkbox
    const handleInboxCheckbox = (/* e: React.ChangeEvent<HTMLInputElement> */) => {
        // setSendOfficeUser(true /* e.target.checked */);
    };
    useEffect(() => {
        // console.log(routeData, 'routeData');
        getActivityLogQuery(routeData.id);
    }, []);
    return (
        <Fragment>
            <PreviousPage
                title={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_SETTING}
                subTitle={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_DESCRIPTION}
                secondSubtitle={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_UTMC_SECOND_DESCRIPTION}
                handleBackFunction={goBack}
            />

            <DisableView value={disableView}>
                {routeData?.status !== 'cron-job' && userActivity?.status.toLowerCase() === 'rejected' ? (
                    <TopBanner
                        icon="terminate"
                        title={`The request to ${
                            editEPFActivity?.value === 'true' ? 'enable' : 'disable'
                        } multiple UTMC by ${userActivity.authorName} on ${moment(userActivity.createdOn, 'x').format(
                            'DD/MM/YYYY',
                        )} was rejected by ${userActivity.approverName} on ${moment(userActivity.timestamp, 'x').format(
                            'DD/MM/YYYY',
                        )} due to:`}
                        subTitle={'Remarks:'}
                        text={userActivity.remark}
                    ></TopBanner>
                ) : null}
                <DivWrapper>
                    <TextDarkBlack weight="700" size="1.12rem">
                        {SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_REQUEST_SETTING}
                    </TextDarkBlack>
                    <FlexedDiv direction="row" style={{ marginTop: '1.5rem', alignItems: 'baseline' }}>
                        <CustomRadioButton
                            selected={editEPFActivity?.value ? true : false}
                            onClick={handleRadioButton}
                            index={0}
                        />
                        <FlexedDiv direction="column" style={{ marginLeft: '0.875rem' }}>
                            <TextDarkBlack>{SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_MULTIPLE_UTMC}</TextDarkBlack>
                            <TextDarkGrey5 size="12px">
                                {`${editEPFActivity?.value ? 'Disable' : 'Enable'}`} this to request switching from
                                <strong> Multiple to Single</strong> UTMC per application for all EPF funding option
                                investment.
                            </TextDarkGrey5>
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv direction="column" style={{ marginTop: '1.5rem' }}>
                        <TextDarkGrey6 size="12px" weight="700">
                            {SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_EFFECTIVE_DATE}
                        </TextDarkGrey6>
                        <InputWrap>
                            <DatePicker
                                setTargetDate={handleTargetDate}
                                targetDate={effectiveDate}
                                range={false}
                                primaryBorderColor="#002043"
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                                disable={true}
                            />
                        </InputWrap>
                    </FlexedDiv>
                </DivWrapper>
                <Border />
                <DivWrapper>
                    <CustomCheckbox
                        checked={editEPFActivity?.sendAgent === 'true' ? true : false}
                        label={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_SEND_EMAIL_INBOX}
                        handleCheckbox={handleCheckbox}
                        textTransForm="initial"
                    ></CustomCheckbox>
                    <br />
                    <CustomCheckbox
                        checked={editEPFActivity?.sendOfficeUser === 'true' ? true : false}
                        label={SYSTEM_ADMIN.SYSTEM_SETTING.LABEL_SEND_INBOX}
                        handleCheckbox={handleInboxCheckbox}
                        textTransForm="initial"
                    ></CustomCheckbox>
                </DivWrapper>
            </DisableView>
        </Fragment>
    );
};

const DivWrapper = styled.div`
    margin-left: 3rem;
`;
const DisableView = styled.div<DisableViewProps>`
    opacity: ${(props) => (props.value === true ? 0.6 : undefined)};
    pointer-events: ${(props) => (props.value === true ? 'none' : undefined)};
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;
const Border = styled.div`
    border: 1px solid #c6cbd4;
    margin: 2rem -1.5rem;
`;
