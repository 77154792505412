import { DocumentNode } from 'graphql';
import { branchDashboardExportListQueryAllDailyCreatedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListCreatedOnAllDaily';
import { branchDashboardExportListQueryAllOthersCreatedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListCreatedOnAllOthers';
import { branchDashboardExportListQueryAllDailyLastUpdated } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListLastUpdatedAllDaily';
import { branchDashboardExportListQueryALLOthersLastUpdated } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListLastUpdatedAllOthers';
import { branchDashboardExportListQueryAllDailySubmittedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListSubmittedOnAllDaily';
import { branchDashboardExportListQueryALLOthersSubmittedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListSubmittedOnAllOthers';

export const allExportListQueryPicker = (dateType: string, isDaily: boolean): DocumentNode => {
    let temp = branchDashboardExportListQueryAllDailyLastUpdated;
    switch (dateType.toLowerCase()) {
        case 'created on':
            temp = isDaily
                ? branchDashboardExportListQueryAllDailyCreatedOn
                : branchDashboardExportListQueryAllOthersCreatedOn;
            break;
        case 'submitted on':
            temp = isDaily
                ? branchDashboardExportListQueryAllDailySubmittedOn
                : branchDashboardExportListQueryALLOthersSubmittedOn;
            break;
        default:
            temp = isDaily
                ? branchDashboardExportListQueryAllDailyLastUpdated
                : branchDashboardExportListQueryALLOthersLastUpdated;
            break;
    }
    return temp;
};
