/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, ReactText, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import FlexSpacer, {
    Banner,
    ComponentLoader,
    CustomButton,
    CustomSpacer,
    FlexedDiv,
    Modal,
    PreviousPage,
    SelectDropdown,
    TextArea,
} from '../../../components';
import { CustomInput } from '../../../components/atoms/Input';
import { PhoneInput } from '../../../components/atoms/Input/PhoneInput';
import MultiSelectV2, { IMultiSelectLabel } from '../../../components/molecules/MultiSelect';
import { Fs16RegSecPrimaryBlack, Fs24BoldSecNavyBlueModalTitle, LABEL } from '../../../constants';
import { PageDescription, TextBold12, TextBold18, TextBold24, TextNormal16 } from '../../../constants/textStyles';
import { sh16, sh24, sh240, sh32, sh56, sh64, sh80 } from '../../../styles';
import { approveReject } from '../../../_graphql/mutations/systemAdmin/approveReject';
import { getSystemAdminInboxData } from '../../../_graphql/queries/inbox/getSystemAdminInboxData';
import { pageTitle } from '../../SystemAdmin/UserGroup/EditUserGroup/pageLabels';
import { IUserTab } from '../../../utils/permissionTypes';
import { useComponentLoader } from '../../../customHooks';
import { getDropDownList } from '../../../_graphql/queries/common/getDropDownList';

import styled from 'styled-components';
import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

interface IUserViewInboxProps {
    loginID: string;
    author: string;
    name: string;
    ssoID: string;
    verified: boolean;
    status: string;
    homeBranch: string;
    department: string;
    email: string;
    mobile: string;
    description: string;
    userGroups: ReactText[];
    rejectRemark: string;
    userID: string;
    requestId: string;
    source: string;
    approver?: string;
    remarks?: string;
}

const initialUser: IUserViewInboxProps = {
    loginID: '',
    author: '',
    name: '',
    ssoID: '',
    verified: false,
    status: 'Pending',
    homeBranch: '',
    department: '',
    email: '',
    mobile: '',
    description: '',
    userGroups: [],
    rejectRemark: '',
    userID: '',
    requestId: '',
    source: '',
    approver: '',
    remarks: '',
};
export interface locationData {
    id: string;
}

const UserViewInbox = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [isRejectedConfirm, setIsRejectedConfirm] = useState(false);
    const [userData, setUserData] = useState({ ...initialUser });
    const [approvalStatus, setApprovalStatus] = useState<string>('');
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const { state } = useLocation<locationData>();

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission = parsedPermission.hq.permission.userManagement.userTab as IUserTab;
    const { loading, loadingHandler } = useComponentLoader();
    const [branchOptions, setBranchOptions] = useState<IMultiSelectLabel[]>([]);
    const [departmentOptions, setDepartmentOptions] = useState<IMultiSelectLabel[]>([]);
    const [groupsOptions, setGroupsOptions] = useState<IMultiSelectLabel[]>([]);

    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const getMode = async () => {
        const tempUser = { ...initialUser };
        tempUser.requestId = state.id;

        try {
            const response: any = await API.graphql(
                graphqlOperation(getSystemAdminInboxData, {
                    input: {
                        id: state.id,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.systemAdminInboxData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;

            const data = JSON.parse(result.data.value);

            setApprovalStatus(result.data.approvalStatus);
            result.data.approvalStatus.toLowerCase() === 'pending' ? isChecker(result.data.source) : null;
            if (data) {
                tempUser.loginID = data.username;
                tempUser.author = result.data.authorName ? result.data.authorName : '';
                tempUser.name = data.name ? data.name : '';
                tempUser.email = data.email;
                tempUser.description = data.description ? data.description : '';
                tempUser.mobile = data.mobile_no ? data.mobile_no : '';
                tempUser.verified = false;
                tempUser.homeBranch = data.branch_id;
                tempUser.status = data.requestedStatus;
                tempUser.department = data.department_id;
                tempUser.userGroups = data.groupIds.ids;
                tempUser.userID = data.user_id;
                tempUser.source = result.data.source;
                tempUser.approver = result.data.approverName !== null ? result.data.approverName : '';
                tempUser.remarks = result.data.remarks !== null ? result.data.remarks : '';
                setUserData({ ...tempUser });
                loadingHandler();
            } else {
            }
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch User Data',
                testId: 'notifications-user-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleModal = () => {
        setShowModal(false);
        history.push(Routes.dashboardSystemAdmin);
    };
    const isChecker = (action: string) => {
        switch (action) {
            case 'createUser':
                if (
                    userManagementPermission.reviewApproval.canApproveCreateNewUser === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveCreateNewUser === 'checker'
                ) {
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'editUser':
                if (
                    userManagementPermission.reviewApproval.canApproveEditUserDetails === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveEditUserDetails === 'checker'
                ) {
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'suspendUser':
                if (
                    userManagementPermission.reviewApproval.canApproveSuspendUser === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveSuspendUser === 'checker'
                ) {
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'terminateUser':
                if (
                    userManagementPermission.reviewApproval.canApproveTerminateUser === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveTerminateUser === 'checker'
                ) {
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'enableUser':
                if (
                    userManagementPermission.reviewApproval.canApproveReactivateUser === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveReactivateUser === 'checker'
                ) {
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            default:
                return false;
        }
    };
    const getDropdown = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(graphqlOperation(getDropDownList, {}), idTokenHeader);

            const { data } = await response.data.getDropDownList;
            if (data !== null) {
                interface temp {
                    id: string;
                    value: string;
                }
                let tempData: temp[] = data.result.branches;
                let temp: IMultiSelectLabel[] = [];
                for (let i = 0; i < tempData.length; i++) {
                    temp.push({ key: tempData[i].id, value: tempData[i].value, label: tempData[i].value });
                }
                setBranchOptions(temp);
                tempData = data.result.departments;
                temp = [];
                for (let i = 0; i < tempData.length; i++) {
                    temp.push({
                        key: tempData[i].id,
                        value: tempData[i].value,
                        label: tempData[i].value,
                    });
                }
                setDepartmentOptions(temp);
                tempData = data.result.groups;
                temp = [];
                for (let i = 0; i < tempData.length; i++) {
                    temp.push({
                        key: tempData[i].id,
                        value: tempData[i].value,
                        label: tempData[i].value,
                    });
                }
                setGroupsOptions(temp);
            }
        } catch (error) {
            loadingHandler();
        }
    };
    useEffect(() => {
        getDropdown();
        getMode();
    }, []);

    const runApproveRejectUser = async (action: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: {
                        requestId: userData.requestId,
                        action: action,
                        remarks: userData.rejectRemark,
                    },
                }),
                idTokenHeader,
            );

            const { data } = await response.data.approveReject;

            if (data !== null) {
                if (data.result.status) {
                    if (action === 'approve') setShowModal(true);
                    if (action === 'reject') {
                        setIsRejectedConfirm(true);
                        setShowModal(true);
                    }
                }
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Review Request',
                testId: 'notifications-user-error-modal',
            });
            handleErrorHandler();
        }
    };

    const updateUserData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        let valueMessage = e.currentTarget.getAttribute('validationMessage');
        if (valueMessage === null) {
            valueMessage = '';
        }

        if (key === 'verify') {
            setUserData({ ...userData, verified: !userData.verified });
        } else {
            setUserData({ ...userData, [key]: value });
        }
    };
    const updateUserDataSelect = (value: string, field: string) => {
        if (field === 'status') {
            switch (value) {
                case 'Suspended':
                    value = 'suspend';
                    break;
                case 'Terminated':
                    value = 'terminate';
                    break;
                default:
                    break;
            }
        }
        setUserData({ ...userData, [field]: value });
    };

    const getSelectedList = (list: Array<ReactText>) => {
        setUserData({ ...userData, userGroups: list });
    };

    const handleApproveReject = (action: string) => {
        runApproveRejectUser(action);
    };

    const getHomeBranch = () => {
        for (let i = 0; i < branchOptions.length - 1; i++) {
            if (branchOptions[i].key === userData.homeBranch?.toString()) {
                return branchOptions[i].value as string;
            }
        }
    };

    const getDepartment = () => {
        for (let i = 0; i < departmentOptions.length; i++) {
            if (departmentOptions[i].key === userData.department.toString()) {
                return departmentOptions[i].value as string;
            }
        }
    };
    const statusOptions = ['Active', 'Suspended', 'Terminated'];

    let approvedTile = '';
    let approvedMessage = '';
    let rejectMessage = '';

    switch (userData.source) {
        case 'editUser':
            approvedTile = LABEL.newUserEditApproved;
            approvedMessage = LABEL.newUserEdited;
            rejectMessage = LABEL.editUserRejected;
            break;
        case 'enableUser':
            approvedTile = LABEL.enableUserApproved;
            approvedMessage = LABEL.userEnabled;
            rejectMessage = LABEL.enableUserRejected;
            break;
        case 'suspendUser':
            approvedTile = LABEL.newUserSuspendApproved;
            approvedMessage = LABEL.newUserSuspended;
            rejectMessage = LABEL.suspendUserRejected;
            break;
        case 'terminateUser':
            approvedTile = LABEL.newUserTerminationApproved;
            approvedMessage = LABEL.newUserTerminated;
            rejectMessage = LABEL.terminateUserRejected;
            break;
        default:
            approvedTile = LABEL.newUserApproved;
            approvedMessage = LABEL.newUserCreated;
            rejectMessage = LABEL.newUserRejected;
    }

    const clearStateOnReject = () => {
        if (userData.rejectRemark !== null) {
            userData.rejectRemark = '';
        }
        setIsRejected(false);
    };

    return (
        <Container>
            {isRejected ? (
                <div style={{ marginLeft: sh16 }}>
                    <TextBold24 style={{ display: 'block' }}>{LABEL.rejectRemark}</TextBold24>
                    <TextNormal16 style={{ marginBottom: sh32, display: 'block' }}>
                        {LABEL.rejectRemarkDescription}
                    </TextNormal16>
                    <TextArea
                        name="rejectRemark"
                        label={LABEL.remarks}
                        value={userData.rejectRemark}
                        handleInput={(e) => updateUserData(e)}
                        maxLength={255}
                        style={{ width: '520px' }}
                    />
                    <FlexedDiv direction="column">
                        <FlexedDiv style={{ marginTop: sh80, justifyContent: 'flex-start' }}>
                            <CustomButton onClick={() => clearStateOnReject()} style={{ width: sh240, height: '40px' }}>
                                {LABEL.cancel}
                            </CustomButton>
                            <CustomButton
                                disabled={userData.rejectRemark.trim().length === 0}
                                primary={true}
                                style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                onClick={() => {
                                    handleApproveReject('reject');
                                }}
                            >
                                {LABEL.submit}
                            </CustomButton>
                        </FlexedDiv>
                        <div style={{ height: sh56 }} />
                    </FlexedDiv>
                </div>
            ) : (
                <Fragment>
                    <PreviousPage title={pageTitle(userData.source)} />
                    <div style={{ paddingLeft: sh32 }}>
                        <PageDescription>{LABEL.reviewUserDescription}</PageDescription>
                    </div>
                    <div>
                        {approvalStatus.toLowerCase() !== 'pending' ? (
                            <ApprovalBanner direction="column">
                                <Title>{`This request was ${approvalStatus.toLowerCase()} by ${
                                    userData.approver
                                }`}</Title>

                                <FlexedDiv
                                    style={{ paddingTop: '0.5rem', width: '100%', whiteSpace: 'break-spaces' }}
                                    justifyContent="space-between"
                                >
                                    {userData.remarks !== undefined && userData.remarks.length !== 0
                                        ? `${LABEL.rejectedReason}: ${userData.remarks}`
                                        : ''}
                                </FlexedDiv>
                            </ApprovalBanner>
                        ) : null}
                    </div>
                    <div style={{ paddingLeft: sh32 }}>
                        <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userProfile}</TextBold18>
                        <FlexedDiv style={{ marginBottom: sh24 }}>
                            <CustomInput
                                label={LABEL.loginID}
                                name="loginID"
                                disabled
                                style={{ marginRight: '64px' }}
                                value={userData.loginID}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                onChange={(e) => updateUserData(e)}
                                onLostFocus={(e) => updateUserData(e)}
                            />
                        </FlexedDiv>
                        <FlexedDiv style={{ marginBottom: sh24, display: 'flex' }}>
                            <CustomInput
                                label={LABEL.name}
                                name="name"
                                disabled
                                onChange={(e) => updateUserData(e)}
                                style={{ marginRight: '64px' }}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                value={userData.name}
                            />
                            <FlexedDiv direction="column">
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.status}</TextBold12>

                                <SelectWrapper>
                                    <SelectDropdown
                                        onChange={(value) => updateUserDataSelect(value.toString(), 'status')}
                                        options={
                                            statusOptions &&
                                            statusOptions.map((option) => {
                                                return {
                                                    label: option as string,
                                                    value: option as string,
                                                };
                                            })
                                        }
                                        testId={`status-dropdown`}
                                        selectedValue={userData.status as string}
                                        isDisabled={true}
                                        fontWeight={700}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv style={{ display: 'flex' }}>
                            <FlexedDiv direction="column" style={{ marginRight: '64px' }}>
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.homeBranch}</TextBold12>

                                <SelectWrapper>
                                    <SelectDropdown
                                        onChange={(value) => updateUserDataSelect(value.toString(), 'homeBranch')}
                                        options={
                                            branchOptions &&
                                            branchOptions.map((branch) => {
                                                return {
                                                    label: branch.value as string,
                                                    value: branch.value as string,
                                                };
                                            })
                                        }
                                        testId={`homebranch-dropdown`}
                                        selectedValue={getHomeBranch() || ''}
                                        isDisabled={true}
                                        placeHolder={LABEL.selectOne}
                                        fontWeight={700}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                            <FlexedDiv direction="column">
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.departments}</TextBold12>

                                <SelectWrapper>
                                    <SelectDropdown
                                        onChange={(value) => updateUserDataSelect(value.toString(), 'department')}
                                        options={
                                            departmentOptions &&
                                            departmentOptions.map((department) => {
                                                return {
                                                    label: department.value as string,
                                                    value: department.value as string,
                                                };
                                            })
                                        }
                                        testId={`department-dropdown`}
                                        selectedValue={getDepartment() || ''}
                                        isDisabled={true}
                                        placeHolder={LABEL.selectOne}
                                        fontWeight={700}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv style={{ marginBottom: sh24, marginTop: sh24, display: 'flex' }}>
                            <CustomInput
                                label={LABEL.emailAddress}
                                name="email"
                                disabled
                                onChange={(e) => updateUserData(e)}
                                style={{ marginRight: '64px' }}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                value={userData.email}
                            />
                            <PhoneInput
                                label={LABEL.mobileNo}
                                name="mobile"
                                onChange={(e) => updateUserData(e)}
                                prefix="+60"
                                disabled
                                style={{ marginRight: '64px' }}
                                value={userData.mobile.trim()}
                            />
                        </FlexedDiv>
                        <TextArea
                            name="description"
                            label={LABEL.description}
                            value={userData.description}
                            handleInput={(e) => updateUserData(e)}
                            maxLength={255}
                            disabled
                            style={{ width: '360px' }}
                        />
                        <TextBold18 style={{ marginBottom: sh24, marginTop: sh64 }}>{LABEL.assignUserGroup}</TextBold18>
                        <MultiSelectV2
                            label={LABEL.userGroup}
                            options={groupsOptions}
                            placeHolder={LABEL.selectOneOrMultiple}
                            checkedState={userData.userGroups}
                            noOverlay={false}
                            expandable
                            disabled
                            handleMultiSelect={(item) => getSelectedList(item)}
                            selectId={1}
                            style={{ width: '240px' }}
                        />

                        <FlexSpacer />
                    </div>
                    {approvalStatus.toLowerCase() === 'pending' && showBanner ? (
                        <div style={{ marginBottom: '180px' }}>
                            <Banner
                                toggle={true}
                                title="Pending Approval"
                                descriptionEmText={userData.author}
                                description="Requested by"
                                testId="userviewinbox-banner"
                                primaryButtonObject={{
                                    handlePrimaryBtn: () => handleApproveReject('approve'),
                                    label: LABEL.approve,
                                }}
                                secondaryButtonObject={{
                                    handleSecondaryBtn: () => setIsRejected(true),
                                    label: LABEL.reject,
                                }}
                            />
                        </div>
                    ) : null}
                </Fragment>
            )}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={''}
                    primaryBtn={{
                        onClick: () => {
                            handleModal();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="user-inbox-modal"
                    icon={'user-modal-success'}
                >
                    <FlexedDiv
                        direction="column"
                        style={{ padding: '0rem 2rem', textAlign: 'center', display: 'block' }}
                    >
                        <Fragment>
                            {isRejectedConfirm ? (
                                <Fragment>
                                    <Fs24BoldSecNavyBlueModalTitle>{rejectMessage}</Fs24BoldSecNavyBlueModalTitle>
                                    <CustomSpacer space={'1rem'} />
                                    <Fs16RegSecPrimaryBlack>{`The maker will be notified.`}</Fs16RegSecPrimaryBlack>
                                    <CustomSpacer space={'2rem'} />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <TextBold24 style={{ color: '#002043', textAlign: 'center' }}>
                                        {approvedTile}
                                    </TextBold24>
                                    <TextBold18
                                        style={{
                                            textAlign: 'center',
                                            marginTop: sh16,
                                            marginBottom: sh56,
                                            padding: '0rem 2rem',
                                        }}
                                    >
                                        {userData.name}
                                        <TextNormal16>{approvedMessage}</TextNormal16>
                                    </TextBold18>
                                </Fragment>
                            )}
                        </Fragment>
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const SelectWrapper = styled.div`
    width: 360px;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
`;
export default UserViewInbox;
