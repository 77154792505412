import React from 'react';
import IcomoonReact from 'icomoon-react';
import { IcoMoonSelection } from './IcoMoonSelection';
interface IconProps {
    color?: string;
    name: string;
    size?: string | number;
    style?: React.CSSProperties | undefined;
    id?: string;
}
export const IcoMoon: React.FC<IconProps> = (icon: IconProps) => {
    return (
        <IcomoonReact
            style={icon.style}
            iconSet={IcoMoonSelection}
            icon={icon.name}
            size={icon.size}
            color={icon.color}
            id={icon.id}
        />
    );
};
