import React from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../../../../../components';
import { camelCaseToTitleCase, statusBadgeStyle } from '../../../../../utils';

interface IStatusIndicatorProps {
    background: string;
    color: string;
}
interface IDataProps {
    title: string;
    subTitle: string;
    description: string;
}
interface CardWithBodyProps {
    label: string;
    title: string;
    titleSize: number;
    subTitle?: string;
    data: IDataProps[];
}

export const CardWithBody: React.FC<CardWithBodyProps> = ({
    label,
    title,
    titleSize,
    subTitle,
    data,
}: CardWithBodyProps) => {
    const _titleSize = titleSize !== undefined ? titleSize : 3.8;
    const status: IStatusIndicatorProps = label !== undefined ? statusBadgeStyle(label) : { background: '', color: '' };
    const _subTitle = subTitle ? subTitle : '-';
    const _title = title ? title : '-';
    const _label = label ? label : '-';
    return (
        <CardWrapper id="cardWrapper">
            <CardTopSectionWrapper>
                <FlexedDiv direction="column">
                    <CardTitle>{_title}</CardTitle>
                    <CardSubTitle titleSize={_titleSize}>{_subTitle}</CardSubTitle>
                </FlexedDiv>
                <StatusIndicator background={status.background} color={status.color}>
                    {_label}
                </StatusIndicator>
            </CardTopSectionWrapper>
            <DrawLine />
            <GridStyle>
                {data &&
                    data.map((item: IDataProps, i: number) => {
                        return (
                            <div key={i} style={{ marginBottom: '1rem', wordBreak: 'break-all' }}>
                                <CardTitle>{camelCaseToTitleCase(item.title)}</CardTitle>
                                <CardSubTitle titleSize={_titleSize}>{item.subTitle}</CardSubTitle>
                                <CardTitle>{item.description}</CardTitle>
                            </div>
                        );
                    })}
            </GridStyle>
        </CardWrapper>
    );
};

const GridStyle = styled.div`
    align-self: stretch;
    padding: 0rem 1.5rem;
    display: grid;
    gap: unset;
    grid-template-columns: 50% 50%;
`;
const StatusIndicator = styled.div`
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    text-transform: capitalize;
    border-radius: 16px;
    line-height: 1rem;
    align-self: center;
    letter-spacing: 0.01em;
    color: ${(props: IStatusIndicatorProps) => props.color};
    background: ${(props: IStatusIndicatorProps) => props.background};
`;
const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: static;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`;
declare interface CardSizeProps {
    titleSize: number;
}
const CardSubTitle = styled.h1<CardSizeProps>`
    font-size: ${(props) => props.titleSize}em;
    font-weight: 700;
    letter-spacing: -0.666667px;
    display: flex;
    word-break: break-all;
    white-space: break-spaces;
`;

const DrawLine = styled.span`
    max-width: 100%;
    border: 1px solid #dbdbdb;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 1rem;
`;
const CardTitle = styled.div`
    font-size: 0.625rem;
    color: rgb(77, 77, 77);
    align-self: stretch;
    white-space: break-spaces;
`;
const CardTopSectionWrapper = styled.div`
    padding: 1rem 1.5rem;
    display: flex;
    align-self: stretch;
    justify-content: space-between;
`;
