import gql from 'graphql-tag';

export const ActivityLogsSummaryQuery = gql`
    query getLogData($input: GetLogDataInput) {
        getLogData(input: $input) {
            data {
                result {
                    message
                    data {
                        code
                        operationName
                        fileSize
                        roles
                        createdOn
                        name
                        eventType
                        operationStatus
                        data
                        fundData
                        type
                        timestamp
                        remark
                        status
                        authorName
                        approverName
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
