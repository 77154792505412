import gql from 'graphql-tag';

/**
 * @BulkUploadMutation @Mutation - Bulk Upload Documents
 * @description:  Mutation to bulk uplaod documents
 */

const bulkUploadMutation = /* GraphQL */ gql`
    mutation bulkUpload($input: BulkUploadInput) {
        bulkUpload(input: $input) {
            data {
                result {
                    message
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default bulkUploadMutation;
