import React from 'react';
import { LABEL } from '../../../../constants';
import { IcoMoon } from '../../../../icons';
import { ROUTES } from '../../../DashboardBranch';
import { useHistory } from 'react-router-dom';
import { INewTab } from '../../../../utils/permissionTypes';
import { CustomDropdown, IDropdownItem, Pointer } from '../../../../components';

interface NewActions extends ITableOptions {
    newTab: INewTab;
}
export const NewActions = ({ data, newTab }: NewActions): JSX.Element => {
    const history = useHistory();

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const status: string = data.rawData.status.toString().toLowerCase();
    const caseId: string = data.rawData.caseId;
    const caseNo: string = data.rawData.caseNo;

    const handleCancel = () => {
        history.push(ROUTES.cancelRequest, { caseId, caseNo });
    };
    const handleSatisfactory = () => {
        sessionStorage.setItem('caseId', caseId);
        history.push(ROUTES.satisfactoryCase, { tab: 'new', callFrom: 'dashboard' });
    };
    const handleUnsatisfactory = () => {
        sessionStorage.setItem('caseId', caseId);
        history.push(ROUTES.unsatisfactoryCase, { tab: 'new', callFrom: 'dashboard' });
    };
    const menu = (status: string) => {
        switch (status) {
            case 'submitted':
                return defaultMenu;
            default:
                return pendingMenu;
        }
    };

    const pendingMenu: IDropdownItem[] = [];
    if (newTab.actions.canCancelCase === LABEL.maker || newTab.actions.canCancelCase === LABEL.autoAuthorizer) {
        pendingMenu.push({ item: LABEL.cancelCase, icon: 'terminate', handleItem: handleCancel, danger: true });
    }
    const defaultMenu: IDropdownItem[] = [];
    if (newTab.actions.canCaseReview === LABEL.maker || newTab.actions.canCaseReview === LABEL.autoAuthorizer) {
        defaultMenu.push(
            { item: LABEL.satisfactory, icon: 'check', handleItem: handleSatisfactory },
            { item: LABEL.unsatisfactory, icon: 'reroute', handleItem: handleUnsatisfactory },
        );
    }

    let items: IDropdownItem[] = [];
    let showAction = false;
    const item = menu(status);

    if (item !== undefined && item.length !== 0) {
        items = item;
        showAction = true;
    }
    return (
        <>
            {showAction ? (
                <CustomDropdown items={items}>
                    <Pointer>
                        <IcoMoon name="action-menu" size="1.5rem" />
                    </Pointer>
                </CustomDropdown>
            ) : null}
        </>
    );
};
