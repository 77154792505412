/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { PreviousPage, Banner, FlexedDiv, ComponentLoader } from '../../components';
import { Modal, TableSheet } from '../../components/organisms';
import { expandMasterFundCols, moveItemInArray, expandAMPFundCols } from '../../utils';
import { useComponentLoader } from '../../customHooks';
import { LABEL } from '../../constants';

import * as Routes from '../../routes';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import WebSocketContext from '../../context/WebSocketContext';
import fundUploadDataQuery from '../../_graphql/queries/fundData/documentDashboard/fundUploadData';
import fundCheckerApproveMutation from '../../_graphql/mutations/fundData/checkerApprove';
import styled from 'styled-components';

interface IRouteProps {
    fundDocumentId: number;
    title: string;
    status: string;
    fromInbox?: boolean;
    tabIndex?: number;
}
interface StyledInnerProps {
    paddingBottom: number;
}
type FundData = {
    [key: string]: string;
};

// initial obj for fundStatus mutation
const initialCheckerModal = {
    fundDocumentId: 0,
    tab: '',
    status: '',
    remark: '',
};
// masterdata table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 260,
    secondColWidth: 156,
    thirdColWidth: 42,
};
const ReviewImport: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { ws } = useContext(WebSocketContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { uploads } = parsedPermission.hq.permission;
    const { state: propsData } = useLocation<IRouteProps>();
    const history = useHistory();
    // states for user permissions
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    // states for modals, title and description
    const [pageTitle, setPageTitle] = useState<string>('');
    const [showModal, setShowModal] = useState(true);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [requestedBy, setRequestedBy] = useState<string>('');
    // states to generate table
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    // state for approval request body
    const [checkerModal, setCheckerModal] = useState<IFundApproval>(initialCheckerModal);
    // states for UI utility components
    const [isProcessing, setIsProcessing] = useState(false);
    const [approvedFundDetails, setApprovedFundDetails] = useState({
        status: propsData.status,
        remark: '',
        user: '',
    });
    const [bannerHeight, setBannerHeight] = useState<number>(0);
    // custom hooks
    const { loading, loadingHandler } = useComponentLoader();
    // check page permissions
    const checkPermissions = () => {
        switch (propsData.title) {
            case 'Master Fund':
                if (
                    uploads.masterFundTab.reviewApproval &&
                    (uploads.masterFundTab.reviewApproval.canApproveMasterFund === 'auto-authorizer' ||
                        uploads.masterFundTab.reviewApproval.canApproveMasterFund === 'checker')
                ) {
                    setIsAuthorized(true);
                }
                propsData && propsData.status === 'Pending Approval'
                    ? setPageTitle('Review Import Master Fund Request')
                    : setPageTitle('Import Master Fund Request');
                break;
            case 'NAV':
                if (
                    uploads.navPerUnitTab.reviewApproval.canApproveNav === 'auto-authorizer' ||
                    uploads.navPerUnitTab.reviewApproval.canApproveNav === 'checker'
                ) {
                    setIsAuthorized(true);
                }
                propsData && propsData.status === 'Pending Approval'
                    ? setPageTitle('Review Import NAV Request')
                    : setPageTitle('Import NAV Request');
                break;
            case 'Distribution':
                if (
                    uploads.distributionTab.reviewApproval.canApproveDistribution === 'auto-authorizer' ||
                    uploads.distributionTab.reviewApproval.canApproveDistribution === 'checker'
                ) {
                    setIsAuthorized(true);
                }
                propsData && propsData.status === 'Pending Approval'
                    ? setPageTitle('Review Import Distribution Request')
                    : setPageTitle('Import Distribution Request');
                break;
            default:
                break;
        }
    };
    //call query to get master fund doc by id
    const getFundUploadDataQuery = async (id: number) => {
        // console.log(id, 'getFundUploadDataQuery');
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundUploadDataQuery, {
                    input: {
                        fundDocumentId: id,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundUploadData;
            // console.log(data, 'getFundUploadDataQuery');
            if (!error) {
                const rawData: FundData[] = JSON.parse(data.result.data);

                // process to bind resposnse data to  table
                switch (propsData.title) {
                    case 'Master Fund':
                        processMasterFund(rawData);
                        break;
                    case 'NAV':
                        processNavdata(rawData);
                        break;
                    case 'Distribution':
                        processDistributionData(rawData);
                        break;
                    default:
                }
                setApprovedFundDetails(data.result.transaction);
                setRequestedBy(data.result.user);
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving ${propsData.title} request failed`,
                    testId: 'review-import-error-modal',
                });
                handleErrorHandler();
            }

            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {}
    };
    //mutation function to approve fund document data
    const approveFundMutation = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundCheckerApproveMutation, {
                    input: checkerModal,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.fundStatus;

            if (error) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving ${propsData.title} request failed`,
                    testId: 'review-import-error-modal',
                });
                loadingHandler();
                handleErrorHandler();
            } else {
            }
        } catch (error) {}
    };
    //Fn to handle websocket listener
    const handleWebsocket = async () => {
        if (ws !== undefined) {
            approveFundMutation();
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);

                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;
                        if (result.status) {
                            loadingHandler();
                            handleShowConfirmation();
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;

                        setErrorMessage({
                            ...errorMessage,
                            errorCode: error.errorCode,
                            message: error.message,
                            title: `Approving ${propsData.title} request failed`,
                            testId: 'review-import-error-modal',
                        });
                        loadingHandler();
                        handleErrorHandler();
                    }
                }
            });
        }
    };
    //Fn to initiate websocket connection
    const initiateWebsocket = (): boolean => {
        if (ws !== undefined) {
            loadingHandler();
            ws.send('Ping');
            return true;
        } else {
            return false;
        }
    };
    //code to build columns and cells
    const processMasterFund = (rawData: FundData[]) => {
        // console.log(rawData, 'rawData');
        //code to build columns and cells
        let tempCols: string[] = [];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};
        let isAmp = false;
        try {
            isAmp = rawData.some(
                (data) => data['tomsFundAbbreviation'] === '' || data['tomsFundAbbreviation'] === null,
            );

            tempCols = Object.keys(rawData[0]);

            // Get masterdata or AMP column names
            isAmp ? (tempCols = expandAMPFundCols(tempCols)) : (tempCols = expandMasterFundCols(tempCols));

            //separate data to header columns
            const columnsOutput: ISheetColumn[] = [];
            if (isAmp) {
                tempCols.map((column: string) => {
                    //dont include fund code and AMP Abbreviation
                    //fixed values from incoming data
                    if (column !== 'Fund Code' && column !== 'AMP Abbreviation') {
                        columnObj = {
                            colName: column,
                            type: 'string',
                        };
                        columnsOutput.push(columnObj);
                    }
                });
            } else {
                tempCols.map((column: string) => {
                    //dont include fund code and TOMS Fund Abbreviation
                    //fixed values from incoming data
                    if (column !== 'Fund Code' && column !== 'TOMS Fund Abbreviation') {
                        columnObj = {
                            colName: column,
                            type: 'string',
                        };
                        columnsOutput.push(columnObj);
                    }
                });
            }

            //separaete data to rows
            const cellRow: ISheetCell[][] = [];
            tempCells = rawData.map((row: FundData) => Object.values(row));

            //remove first two columns from each row and keep them for submission
            tempCells.map((row: any[]) => row.splice(0, 2));

            //make tempCells to objects and then push to an array
            tempCells.map((row: any[]) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, index) => {
                    cellObj = {
                        cellValue: row[index],
                        isValid: true,
                    };

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };

    const processDistributionData = (rawData: FundData[]) => {
        const tempCols: string[] = [
            'Fund Code',
            'Date',
            'Fund Name',
            'Gross Distribution',
            'Net Distribution',
            'Unit Split / Cancellation',
        ];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};

        try {
            //separate data to header columns
            const columnsOutput: ISheetColumn[] = [];
            tempCols.map((column: string) => {
                columnObj = {
                    colName: column,
                    type: 'string',
                };
                columnsOutput.push(columnObj);
            });

            //separaete data to rows
            const cellRow: ISheetCell[][] = []; // final row of cell objects
            const cellRowMerged: string[][] = [];
            tempCells = rawData.map((row: FundData) => Object.values(row));

            //extract gross, net and unit values from rawdata obj
            tempCells.map((row: Array<any>) => {
                const tempCellValues: any[] = [];
                const innerObjectArray = Object.values(row[4]);
                innerObjectArray.map((elm: any, elmIndex) => {
                    for (const [key, value] of Object.entries(elm)) {
                        //build row with all string values
                        if (elmIndex === 0) {
                            tempCellValues.push(row[0], key, row[1], value);
                        } else {
                            tempCellValues.push(value);
                        }
                    }
                });
                cellRowMerged.push(tempCellValues);
            });
            //make tempCells to objects and then push to an array
            cellRowMerged.map((row: any[]) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, index) => {
                    cellObj = {
                        cellValue: row[index],
                        isValid: true,
                    };

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });
            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };
    const processNavdata = (rawData: FundData[]) => {
        //code to build columns and cells
        let tempCols: string[] = [];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};
        try {
            tempCols = Object.keys(rawData[0]);
            tempCols.push('date');
            tempCols = expandMasterFundCols(tempCols);

            //separate data to header columns
            let columnsOutput: ISheetColumn[] = [];
            tempCols.map((column: string) => {
                columnObj = {
                    colName: column,
                    type: 'string',
                };
                columnsOutput.push(columnObj);
            });

            //reshufle the columns
            columnsOutput = moveItemInArray(5, 1, columnsOutput);

            //separaete data to rows
            const cellRow: ISheetCell[][] = [];
            tempCells = rawData.map((row: FundData) => Object.values(row));

            //make tempCells to objects and then push to an array
            tempCells.map((row: any[]) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column) => {
                    switch (column.colName) {
                        case 'TOMS Fund Abbreviation':
                            cellObj = {
                                cellValue: row[0],
                                isValid: true,
                            };
                            break;
                        case 'Date':
                            cellObj = {
                                cellValue: Object.keys(row[4])[0],
                                isValid: true,
                            };
                            break;
                        case 'Fund Name':
                            cellObj = {
                                cellValue: row[1],
                                isValid: true,
                            };
                            break;
                        case 'Fund Class':
                            cellObj = {
                                cellValue: row[2],
                                isValid: true,
                            };
                            break;
                        case 'Fund Class Currency':
                            cellObj = {
                                cellValue: row[3],
                                isValid: true,
                            };
                            break;
                        case 'NAV Per Unit':
                            cellObj = {
                                cellValue: Object.values(row[4])[0],
                                isValid: true,
                            };
                            break;

                        default:
                    }

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };

    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    const handleReject = () => {
        const tab = propsData.title === 'NAV' ? 'navperunit' : propsData.title.replace(/\s+/g, '').toLocaleLowerCase();
        history.push({
            pathname: Routes.financeRejectImport,
            state: {
                fundDocumentId: propsData.fundDocumentId,
                type: propsData.title,
                tab: tab,
                tabIndex: propsData.tabIndex,
            },
        });
    };

    const goBacktoUploadDashboard = () => {
        if (propsData.fromInbox) {
            history.push({
                pathname: Routes.hqInbox,
            });
        } else {
            history.push({
                pathname: Routes.financeUploads,
                state: {
                    activeTab: propsData.tabIndex,
                },
            });
        }
    };
    useEffect(() => {
        checkPermissions();
        setIsProcessing(true);
        getFundUploadDataQuery(propsData.fundDocumentId);

        //handle fund approve
        const tab = propsData.title === 'NAV' ? 'navperunit' : propsData.title.replace(/\s+/g, '').toLocaleLowerCase();

        setCheckerModal({
            ...checkerModal,
            fundDocumentId: propsData.fundDocumentId,
            tab: tab,
            status: 'approve',
        });
    }, []);

    return (
        <Fragment>
            <PreviousPage title={pageTitle} handleBackFunction={goBacktoUploadDashboard} />
            <StyledInner paddingBottom={bannerHeight}>
                {propsData && propsData.status === 'Pending Approval' && (
                    <PageDescription>
                        Please review the following request. You can either approve it or reject it using the buttons in
                        the bottom right corner.
                        <br />
                        You’ll also be able to leave additional remark if you reject it.
                    </PageDescription>
                )}
                {propsData && propsData.fromInbox && propsData.status !== LABEL.pendingApproval ? (
                    <ApprovalBanner direction="column">
                        <Title>{`This request was ${
                            propsData.status.toLowerCase() === 'active' ? 'approved' : propsData.status.toLowerCase()
                        } by ${approvedFundDetails.user}`}</Title>

                        <FlexedDiv
                            style={{ paddingTop: '0.5rem', width: '100%', whiteSpace: 'break-spaces' }}
                            justifyContent="space-between"
                        >
                            {approvedFundDetails.remark !== undefined && approvedFundDetails.remark !== null
                                ? `${LABEL.rejectedReason}: ${approvedFundDetails.remark}`
                                : ''}
                        </FlexedDiv>
                    </ApprovalBanner>
                ) : null}
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <TableSheet columns={columns} rows={rows} readyOnly={true} columnWidths={fixedCoLumnlWidth} />
                )}
            </StyledInner>
            {isAuthorized && propsData.status === 'Pending Approval' && (
                <Banner
                    title="Pending Approval"
                    description={`Requested by`}
                    descriptionEmText={requestedBy}
                    toggle={true}
                    setBannerHeight={setBannerHeight}
                    testId="review-import-banner"
                    primaryButtonObject={{
                        handlePrimaryBtn: () =>
                            initiateWebsocket() ? handleWebsocket() : console.log('Unable to establish WS'),
                        label: LABEL.approve,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: handleReject,
                        label: LABEL.reject,
                    }}
                />
            )}
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={setShowModal}
                    title={`Import ${propsData.title} request approved`}
                    primaryBtn={{
                        onClick: () => {
                            goBacktoUploadDashboard();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    testId="review-import-modal"
                    contentAlignment="center"
                    icon="approved-transaction"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${propsData.title} has been updated.`}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader position="fixed" backgroundColor="#0000001a" /> : null}
        </Fragment>
    );
};

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 2.6rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;

const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
export default ReviewImport;
