import gql from 'graphql-tag';
/**
 * @caseCheckQuery @query - EDD Case Check api
 * @description:  checks client has already a case with same trigger
 */

export const caseCheckQuery = gql`
    query caseCheck($input: CaseCheckInput) {
        caseCheck(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
