import React, { Fragment, useContext, useState } from 'react';
import styled from 'styled-components';

import { Banner, FlexedDiv, Modal } from '../../../../../components';
import { EDD, LABEL } from '../../../../../constants';
import { Collapsible } from '../../../../../components/molecules';
import { ROUTES } from '../../../..';
import { useHistory } from 'react-router-dom';
import { sh174 } from '../../../../../styles';
import { QuestionaryTemplate } from '../../../Helpers';
import { ResponseInfo } from '../../../Helpers/common';
import { IcoMoonSelection } from '../../../../../icons';

import EDDContext from '../../../../../context/EDDContext/EDDContext';
import IcomoonReact from 'icomoon-react';

export const Responses: React.FC = () => {
    const { getCaseDetails, caseDetails, setResponseId, updateCaseStatus, setShowModal } = useContext(EDDContext);
    const history = useHistory();

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [expands, setExpands] = useState([true]);

    /* Function to expand/close collapsable toggle */
    const updateExpands = async (index: number, responseId?: number | null) => {
        setResponseId(responseId ? responseId : null);
        await getCaseDetails(responseId ? responseId : null);

        const exp = [...expands];
        if (exp[index] === undefined) {
            exp.map((prevExp, i) => {
                if (prevExp === true) exp[i] = false;
            });
            exp[index] = true;
        } else {
            exp.map((prevExp, i) => {
                // console.log(i === index, i, index);
                if (i === index) exp[i] = !exp[index];
                else if (prevExp === true) exp[i] = false;
            });
        }
        setExpands(exp);
    };
    return (
        <Fragment>
            <DetailsArea>
                <div style={{ marginBottom: '16px' }} />
                {caseDetails.headings && caseDetails.response ? (
                    caseDetails.headings.map((heading, i) => {
                        const response = caseDetails.response;
                        return (
                            <Fragment key={i}>
                                <PageHeader>
                                    {`Response ${caseDetails.headings.length - i}`}
                                    <Line />
                                </PageHeader>
                                {caseDetails.status === 'Pending' && i == 0 ? (
                                    <ResponseInfo
                                        style={{ margin: '0px 12px 10px' }}
                                        name={EDD.LABEL_AMLA}
                                        timestamp={heading.remarkOn}
                                        status={'Submitted'}
                                        badgeStyle={{
                                            color: 'black',
                                            fontSize: '20px',
                                            marginTop: '-8px',
                                        }}
                                    />
                                ) : (
                                    <ResponseInfo
                                        name={caseDetails.assignedAdviser.personalDetails.agentName}
                                        timestamp={heading.submittedOn}
                                        status={'Read'}
                                        badgeStyle={{
                                            color: '#A82252',
                                            fontSize: '12px',
                                        }}
                                    />
                                )}
                                <Collapsible
                                    title={
                                        caseDetails.status === 'Pending' && i == 0
                                            ? 'Follow-up Questions'
                                            : caseDetails.status === 'Submitted' && i == 0
                                            ? 'Follow-up Answers'
                                            : 'Answers'
                                    }
                                    isCheckable={false}
                                    key={i}
                                    expanded={expands[i]}
                                    noXMargin={true}
                                    noMargin={true}
                                    showBoxShadow={true}
                                    handleExpand={() => updateExpands(i, caseDetails.headings[i].responseId)}
                                    content={
                                        <QuestionaryTemplate
                                            type="satisfactory"
                                            content={response}
                                            key={i}
                                            onlyRemarks={caseDetails.headings.length - 1 !== i}
                                            status={i == 0 ? caseDetails.status : 'Submitted'}
                                            showConclusion={false}
                                        ></QuestionaryTemplate>
                                    }
                                    index={i}
                                />
                                {heading.submittedOn ? (
                                    <ResponseInfo
                                        style={{ margin: '12px 12px 10px 28px' }}
                                        name={EDD.LABEL_AMLA}
                                        timestamp={heading.remarkOn}
                                        status="Submitted"
                                        badgeStyle={{ background: '#132B53' }}
                                    />
                                ) : null}
                                <SpaceDivider />
                            </Fragment>
                        );
                    })
                ) : (
                    <DivWrapper>
                        <IcomoonReact
                            data-testid="iconset"
                            iconSet={IcoMoonSelection}
                            icon={'empty-state'}
                            size={sh174}
                        />
                        <EmptyLabel>{EDD.PENDING_RESPONSE_TITLE}</EmptyLabel>
                        <EmptySubLabel>{EDD.PENDING_RESPONSE_DESCRIPTION}</EmptySubLabel>
                    </DivWrapper>
                )}
                <SpaceDivider />
            </DetailsArea>
            {caseDetails.headings && caseDetails.response && caseDetails.status.toLowerCase() === 'submitted' ? (
                <FlexCol style={{ margin: '0 -2rem' }}>
                    <Banner
                        toggle={true}
                        title={EDD.LABEL_SATISFACTORY_BANNER_TITLE}
                        description={`${caseDetails.name} (${caseDetails.clientIdNum})`}
                        handleClose={() => console.log('handle close')}
                        maxWidth="1152"
                        primaryButtonObject={{
                            handlePrimaryBtn: () => setShowConfirmModal(true),
                            label: EDD.LABEL_SATISFACTORY,
                            icon: 'check',
                        }}
                        secondaryButtonObject={{
                            handleSecondaryBtn: () =>
                                history.push(ROUTES.reroutedEddCaseUnsatisfactory, {
                                    tab: 'reroute',
                                    callFrom: 'rerouteSatisfactory',
                                }),
                            label: EDD.LABEL_NOT_SATISFACTORY,
                            icon: 'Icon_reroute',
                        }}
                    />
                </FlexCol>
            ) : null}
            {showConfirmModal ? (
                <Modal
                    modalActive={showConfirmModal}
                    setModalActive={setShowConfirmModal}
                    title={EDD.LABEL_EDD_MODAL_CONFIRM_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            updateCaseStatus('Satisfactory');
                            setShowConfirmModal(false);
                            setShowModal(true);
                        },
                        label: EDD.BUTTON_YES,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            setShowConfirmModal(false);
                            history.push(ROUTES.amlaEDDManagement);
                        },
                        label: EDD.BUTTON_NO,
                        primary: false,
                        size: 'large',
                    }}
                    testId="custom_modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {EDD.LABEL_EDD_MODAL_CONFIRM_TITLE_SUB}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {showConfirmModal ? (
                <Modal
                    modalActive={showConfirmModal}
                    setModalActive={setShowConfirmModal}
                    title={EDD.LABEL_EDD_MODAL_SATISFACTORY_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            setShowModal(false);
                            history.push(ROUTES.amlaEDDManagement);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    icon="cancel-request-done"
                    testId="custom_modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${caseDetails && caseDetails.caseSummary.caseDetails.caseNo} ${EDD.LABEL_EDD_MODAL_SUB}`}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

const DetailsArea = styled.div`
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 94px;
    flex-direction: column;
`;

const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;

declare interface SubCardProps {
    topSection?: boolean;
    bottomSection?: boolean;
    isCheckList?: boolean;
}
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const Line = styled.span`
    width: 90%;
    height: 0px;
    margin: 9px 0px;
    border: 1px solid #c6cbd4;
`;
const EmptyLabel = styled.div`
    font-weight: bold;
    font-size: 16px;
    color: #002043;
`;
const DivWrapper = styled.div`
    align-items: center;
    text-align: center;
`;
const EmptySubLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #4d637b;
`;
const PageHeader = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 16px;
    color: #171717;
    line-height: 148%;
`;
