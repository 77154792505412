import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomDropdown, IDropdownItem } from '../../../../components';
import { LABEL, SYSTEM_ADMIN } from '../../../../constants';
import { IcoMoon } from '../../../../icons';
import { IRoles } from '../../../../utils/permissionTypes';
import * as Routes from '../../../../routes';

interface RoleActionsProps extends ITableOptions {
    handleAction: (action: string, rawData: ITableData | undefined) => void;
    permissions: IRoles;
}
export const RoleActions = ({ data, handleAction, permissions }: RoleActionsProps): JSX.Element => {
    const status: string = data.rawData.status.toLowerCase();
    const history = useHistory();

    const apiData = {
        item: data.rawData.roleId,
        approval: data.rawData.status === 'Pending Approval' || data.rawData.status === 'Suspended',
        source: data.rawData.source,
        requestId: data.rawData.requestId,
    };

    const activeMenu: IDropdownItem[] = [];
    if (
        permissions.actions.canSuspendUserRole === 'maker' ||
        permissions.actions.canSuspendUserRole === 'auto-authorizer'
    ) {
        activeMenu.push({
            item: SYSTEM_ADMIN.LABEL_SUSPEND_ROLE,
            icon: 'lock',
            handleItem: handleAction,
            testId: `rolespermissions-suspend-btn`,
        });
    }
    if (
        permissions.actions.canDeleteUserRole === 'maker' ||
        permissions.actions.canDeleteUserRole === 'auto-authorizer'
    ) {
        activeMenu.push({
            item: SYSTEM_ADMIN.LABEL_DELETE_ROLE,
            icon: 'terminate',
            danger: true,
            handleItem: handleAction,
            testId: `rolespermissions-delete-btn`,
        });
    }

    const suspendedMenu: IDropdownItem[] = [];
    if (
        permissions.actions.canReactivateUserRole === 'maker' ||
        permissions.actions.canReactivateUserRole === 'auto-authorizer'
    ) {
        suspendedMenu.push({
            item: SYSTEM_ADMIN.LABEL_REACTIVATE_USER_ROLE,
            icon: 'unlock',
            handleItem: handleAction,
            testId: `rolespermissions-reactivate-btn`,
        });
    }
    if (
        permissions.actions.canDeleteUserRole === 'maker' ||
        permissions.actions.canDeleteUserRole === 'auto-authorizer'
    ) {
        suspendedMenu.push({
            item: SYSTEM_ADMIN.LABEL_DELETE_ROLE,
            icon: 'terminate',
            danger: true,
            handleItem: handleAction,
            testId: `rolespermissions-delete-btn`,
        });
    }

    const pendingMenu: IDropdownItem[] = [];

    const canApprove = () => {
        const source = data.rawData.source;

        if (source) {
            if (
                source === 'createRole' &&
                (permissions.reviewApproval.canApproveCreateUserRole === 'checker' ||
                    permissions.reviewApproval.canApproveCreateUserRole === 'auto-authorizer')
            )
                return true;
            if (
                source === 'editRole' &&
                (permissions.reviewApproval.canApproveEditUserRole === 'checker' ||
                    permissions.reviewApproval.canApproveEditUserRole === 'auto-authorizer')
            )
                return true;
            if (
                (source === 'suspendRole' && permissions.reviewApproval.canApproveSuspendUserRole === 'checker') ||
                permissions.reviewApproval.canApproveSuspendUserRole === 'auto-authorizer'
            )
                return true;
            if (
                source === 'enableRole' &&
                (permissions.reviewApproval.canApproveReactivateUserRole === 'checker' ||
                    permissions.reviewApproval.canApproveReactivateUserRole === 'auto-authorizer')
            )
                return true;
            if (
                source === 'deleteRole' &&
                (permissions.reviewApproval.canApproveDeleteUserRole === 'checker' ||
                    permissions.reviewApproval.canApproveDeleteUserRole === 'auto-authorizer')
            )
                return true;
        }
        return false;
    };

    if (status === 'pending approval' && canApprove()) {
        pendingMenu.push({
            item: LABEL.approveRequest,
            icon: 'check',
            handleItem: () => handleAction('approve', data.rawData),
            rawData: data.rawData,
            testId: `rolespermissions-approve-btn`,
        });

        pendingMenu.push({
            item: LABEL.rejectRequest,
            icon: 'reject',
            danger: true,
            handleItem: () => history.push(Routes.editRoles, { ...apiData, reject: true }),
            rawData: data.rawData,
            testId: `rolespermissions-reject-btn`,
        });
    }

    const menu = (status: string) => {
        switch (status) {
            case 'active':
                return activeMenu.length > 0 ? activeMenu : undefined;
            case 'suspended':
                return suspendedMenu.length > 0 ? suspendedMenu : undefined;
            case 'pending approval':
                return pendingMenu.length > 0 ? pendingMenu : undefined;
        }
    };
    let items: IDropdownItem[] = [];
    let showAction = false;
    const item = menu(status);

    if (item !== undefined) {
        items = item;
        showAction = true;
    }

    return (
        <Fragment>
            {showAction ? (
                <CustomDropdown items={items} data={data.rawData}>
                    <IcoMoon name="action-menu" size="1.5rem" />
                </CustomDropdown>
            ) : null}
        </Fragment>
    );
};
