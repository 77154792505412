export const statusSplice = (filterArray: ISort_FilterInput, status: string): ISort_FilterInput => {
    const tempObj = {
        column: 'status',
        value: status,
    };
    filterArray.push(tempObj);
    const statusIndex: number[] = [];
    filterArray.map((item, index) => {
        item.column === 'status' ? statusIndex.push(index) : null;
    });
    statusIndex.map((ele, index) => {
        index !== statusIndex.length - 1 ? filterArray.splice(ele, 1) : null;
    });
    return filterArray;
};
