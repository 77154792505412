/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, FunctionComponent, useContext } from 'react';

import { AdvanceTable, FlexedDiv, Modal } from '../../../../components';
import { EMPTY_STATES, SYSTEM_ADMIN, LABEL } from '../../../../constants';
import { subTitleStyle } from '../../../../styles';
import { RoleActions } from './RoleActions';
import { useHistory } from 'react-router-dom';
import { statusStyle, stringToCamelCase } from '../../../../utils';
import { API, graphqlOperation } from 'aws-amplify';
import userUpdateStatus from '../../../../_graphql/mutations/systemAdmin/userUpdateStatus';
import { IUserManagement } from '../../../../utils/permissionTypes';
import { DateHeaderSA } from '../AllUsers/DateHeaderSA';

import * as Routes from '../../../../routes';
import CustomItem from '../UserGroups/CustomItem';
import approveReject from '../../../../_graphql/mutations/systemAdmin/approveReject';
import AuthContext from '../../../../context/AuthContext';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
interface SystemAdminRolesPermissionsProps {
    setRefresh: (toggle: boolean) => void;
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
}

export interface IApiData {
    id: string;
    action: string;
    name: string;
    requestId?: string;
    source?: string;
    status: string;
    icon: string;
}

export const SystemAdminRolesPermissions: FunctionComponent<SystemAdminRolesPermissionsProps> = ({
    setRefresh,
    isFilterApplied,
    isSearchResult,
}: SystemAdminRolesPermissionsProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const [apiData, setApiData] = useState<IApiData>({
        id: '',
        action: '',
        name: '',
        status: '',
        icon: 'user-modal-success',
    });
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [validationModal, setValidationModal] = useState<boolean>(false);
    const history = useHistory();

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;
    const { rolesPermissionTab } = userManagementPermission;

    const {
        allRoles,
        createdOnDropdown,
        dateFilter,
        searchInput,
        setCreatedOnDropdown,
        setDateFilter,
        setSortInput,
        setTarget,
        sortInput,
    } = useContext(SystemAdminContext);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const handleUserAction = async (action: string, rawData: ITableData | undefined) => {
        const userAction = action.toLowerCase();
        let apiAction = '';
        switch (userAction) {
            case 'suspend role':
                apiAction = 'suspend';
                break;
            case 'delete role':
                apiAction = 'delete';
                break;
            case 'reactivate role':
                apiAction = 'enable';
                break;
            default:
                apiAction = userAction;
                break;
        }
        setApiData({
            ...apiData,
            action: apiAction,
            id: rawData !== undefined ? rawData.roleId : '',
            status: rawData !== undefined ? rawData.status : '',
            name: rawData !== undefined ? rawData.roleName : '',
            requestId: rawData !== undefined ? rawData.requestId : '',
            source: rawData !== undefined ? rawData.source : '',
        });
        switch (apiAction) {
            case 'suspend':
            case 'delete':
            case 'enable':
                setConfirmModal(true);
                break;
            case 'approve': {
                try {
                    const response: any = await API.graphql(
                        graphqlOperation(approveReject, {
                            input: {
                                requestId: rawData !== undefined ? rawData.requestId : undefined,
                                action: apiAction,
                            },
                        }),
                        idTokenHeader,
                    );

                    if (response.data.approveReject.data !== null) {
                        setShowModal(true);
                    } else {
                        setErrorMessage({
                            ...errorMessage,
                            message: response.data.approveReject.error.message,
                            errorCode: response.data.approveReject.error.errorCode,
                            title: 'Cannot Review Request',
                            testId: 'roles-actions-error-modal',
                        });
                        handleErrorHandler();
                    }
                } catch (error) {}
            }
        }
    };
    const validationModalTitle =
        apiData.action === 'suspend' ? LABEL.unableToSuspendUserRole : LABEL.unableToTerminateUserRole;
    const validationModalSubTitle =
        apiData.action === 'suspend' ? LABEL.unableToSuspendUserRoleSubTitle : LABEL.unableToTerminateUserRoleSubTitle;
    const confirmAction = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(userUpdateStatus, {
                    input: {
                        id: apiData.id,
                        action: apiData.action,
                        viewType: 'roles',
                    },
                }),
                idTokenHeader,
            );

            if (response.data.userUpdateStatus.data !== null) {
                setShowModal(true);
            } else throw response.data.userUpdateStatus.error;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (_error.errorCode === 'BO479') setValidationModal(true);
            else {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Perform Action',
                    testId: 'roles-actions-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const handleItemIcon = (item: IColumnItemAccordion) => {
        const itemIcon: ITableIcon = {
            name: 'edit',
            size: '1.75rem',
        };
        if (
            rolesPermissionTab.reviewApproval.canApproveSuspendUserRole === 'checker' &&
            item.rawData.status === 'Suspended'
        ) {
            itemIcon.name = 'eye-show';
        }
        if (
            rolesPermissionTab.reviewApproval.canApproveEditUserRole === 'checker' &&
            item.rawData.status === 'Active'
        ) {
            itemIcon.name = 'eye-show';
        }
        if (rolesPermissionTab.actions.canViewRolesList === 'checker') {
            itemIcon.name = 'eye-show';
        }
        if (item.rawData.status === 'Pending Approval') {
            itemIcon.name = 'eye-show';
        }
        return itemIcon;
    };

    const handleEditRole = (item: IColumnItemAccordion, icon: ITableIcon) => {
        const data = {
            item: item.rawData.roleId,
            approval: item.rawData.status === 'Pending Approval',
            view: icon.name === 'eye-show',
            requestId: item.rawData.requestId,
            source: item.rawData.source,
        };
        history.push(Routes.editRoles, data);
    };

    const handlePendingText = () => {
        const modalContent = {
            title: '',
            subTitle: '',
        };
        switch (apiData.source) {
            case 'createRole':
                modalContent.title =
                    apiData.action === 'approve'
                        ? SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_APPROVED
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_REJECTED;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_APPROVED_SUB_UPDATED}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'editRole':
                modalContent.title =
                    apiData.action === 'approve'
                        ? SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_APPROVED
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_REJECTED;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLE_REQUEST_APPROVED_SUB_UPDATED}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'suspendRole':
                modalContent.title =
                    apiData.action === 'approve'
                        ? SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_APPROVED
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_REJECTED;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLE_REQUEST_APPROVED_SUB_UPDATED}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'enableRole':
                modalContent.title =
                    apiData.action === 'approve'
                        ? SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_APPROVED
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_REJECTED;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLE_REQUEST_APPROVED_SUB_UPDATED}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'deleteRole':
                modalContent.title =
                    apiData.action === 'approve'
                        ? SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_APPROVED
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_REJECTED;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLE_REQUEST_APPROVED_SUB_UPDATED}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
        }
        return modalContent;
    };

    const handleModalText = () => {
        const modalContent = {
            title: '',
            subTitle: '',
            confirmModalTitle: '',
            confirmModalSubTitle: '',
            icon: 'user-modal-success',
        };
        switch (apiData.action) {
            case 'suspend': {
                if (rolesPermissionTab.actions.canSuspendUserRole === 'maker') {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_ROLE_SUSPEND_REQUEST;
                    modalContent.subTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_ROLE_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_ROLE_MODAL_SUB;
                    modalContent.icon = 'user-modal-processing';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_ROLE_SUSPEND_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_ROLE_HAS_BEEN_SUSPENDED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_ROLE_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_ROLE_MODAL_SUB;
                }
                break;
            }
            case 'delete': {
                if (rolesPermissionTab.actions.canSuspendUserRole === 'maker') {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_ROLE_DELETE_REQUEST;
                    modalContent.subTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_ROLE_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_ROLE_MODAL_SUB;
                    modalContent.icon = 'user-modal-processing';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_ROLE_DELETE_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_ROLE_HAS_BEEN_DELETED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_ROLE_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_ROLE_MODAL_SUB;
                }
                break;
            }
            case 'enable': {
                if (rolesPermissionTab.actions.canSuspendUserRole === 'maker') {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_ROLE_REACTIVATE_REQUEST;
                    modalContent.subTitle = SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_ROLE_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_ROLE_MODAL_SUB;
                    modalContent.icon = 'user-modal-processing';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_ROLE_REACTIVATED_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_ROLE_HAS_BEEN_ACTIVATED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_ROLE_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_ROLE_MODAL_SUB;
                }
                break;
            }
            case 'approve': {
                const pendingText = handlePendingText();
                modalContent.title = pendingText.title;
                modalContent.subTitle = pendingText.subTitle;
                break;
            }
        }
        return modalContent;
    };

    // Fn to handle table column sorting
    const handleSort = (keyName: string) => {
        const tempSort = { ...sortInput };
        const type = sortInput.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = type;
        tempSort.column = keyName;
        setSortInput(tempSort);
    };
    // Fn to handle sort arrow icon
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortInput.column === keyName) {
            sortIcon = sortInput.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    const columns: ITableColumn[] = [
        {
            key: [{ key: 'roleName' }],

            viewStyle: {
                width: '15.5vw',
            },
            icon: {
                name: handleSortIcon('roleName'),
                size: '1rem',
            },
            title: SYSTEM_ADMIN.LABEL_ROLE_NAME,
            titleStyle: { fontWeight: sortInput.column === 'roleName' ? 700 : 400 },
            customItem: true,
            onPressHeader: () => handleSort('roleName'),
        },
        {
            key: [{ key: 'roleDescription' }],
            viewStyle: {
                width: '21.67vw',
                wordBreak: 'break-all',
                padding: '0.55vw',
            },
            title: SYSTEM_ADMIN.LABEL_ROLE_DESCRIPTION,
        },
        {
            key: [{ key: 'accessLevel', textStyle: { fontWeight: sortInput.column === 'accessLevel' ? 700 : 400 } }],
            viewStyle: {
                width: '11.11vw',
            },
            title: SYSTEM_ADMIN.LABEL_ACCESS_LEVEL,
            titleStyle: { fontWeight: sortInput.column === 'accessLevel' ? 700 : 400 },
            icon: {
                name: handleSortIcon('accessLevel'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('accessLevel'),
        },
        {
            key: [
                {
                    key: 'createdOn',
                    textStyle: { fontWeight: sortInput.column === stringToCamelCase(createdOnDropdown) ? 700 : 400 },
                },
                { key: 'createdOnTime', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '8.472vw',
            },
            title: createdOnDropdown,
            titleStyle: { fontWeight: sortInput.column === stringToCamelCase(createdOnDropdown) ? 700 : 400 },
            icon: {
                name: 'caret-down',
                size: '1rem',
            },
            RenderHeaderMenu: () => handleCreatedOn(),
        },
        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: '9.65vw',
            },
            title: SYSTEM_ADMIN.LABEL_STATUS,
            titleStyle: { fontWeight: sortInput.column === 'status' ? 700 : 400 },
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [],
            getItem: handleItemIcon,
            viewStyle: {
                width: '3.19vw',
            },
            title: SYSTEM_ADMIN.LABEL_EDIT_VIEW,
            onClickItem: (item: IColumnItemAccordion) => {
                if (handleItemIcon(item).name !== '') {
                    handleEditRole(item, handleItemIcon(item));
                } else {
                    undefined;
                }
            },
            testId: 'rolespermissions-action-btn',
        },
    ];

    const modalText = handleModalText();
    const handleCreatedOn = () => {
        return (
            <DateHeaderSA
                dropdownData={[
                    createdOnDropdown,
                    sortInput.column === stringToCamelCase(createdOnDropdown) ? sortInput.value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };
    const handleDateFilter = (item: string, value: string) => {
        setCreatedOnDropdown(item);
        let currentFilter = 'createdOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setTarget(tempObj);
        setSortInput({ column: currentFilter, value: value });
    };
    return (
        <Fragment>
            <AdvanceTable
                data={allRoles}
                columns={columns}
                RenderOptions={(props: ITableOptions) => (
                    <RoleActions data={props.data} handleAction={handleUserAction} permissions={rolesPermissionTab} />
                )}
                onEmptyState={
                    isFilterApplied
                        ? EMPTY_STATES.emptyStateFilterNoResultRoles
                        : EMPTY_STATES.emptyStateTableRolesPermissions
                }
                // searchEmpty={searchValue.length > 0}
                isSearchResult={isSearchResult}
                searchInput={searchInput.value}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortInput.column} {...props} />}
                testId="systemadmin"
            />
            {confirmModal ? (
                <Modal
                    modalActive={confirmModal}
                    setModalActive={setConfirmModal}
                    title={modalText.confirmModalTitle}
                    primaryBtn={{
                        onClick: () => {
                            setConfirmModal(false);
                            confirmAction();
                        },
                        label: SYSTEM_ADMIN.BUTTON_YES,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            setConfirmModal(false);
                        },
                        label: SYSTEM_ADMIN.BUTTON_NO,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="left"
                    testId="roles-permissions-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'left' }}>
                        {modalText.confirmModalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalText.title}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.dashboardSystemAdmin);
                            setRefresh(true);
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="roles-permissions-success-modal"
                    icon={modalText.icon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalText.subTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {validationModal ? (
                <Modal
                    modalActive={validationModal}
                    setModalActive={setValidationModal}
                    title={validationModalTitle}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            setValidationModal(false);
                            history.push(Routes.dashboardSystemAdmin, { tab: 'User Groups' });
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="validation-modal"
                    icon="suspend-failed"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {validationModalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
