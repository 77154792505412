/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, ReactText, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IRerouteRequest } from '../../../RerouteTransaction/reasonsToReroute';
import { useComponentLoader } from '../../../../customHooks';
import { removeArrayElement } from '../../../../utils';
import { PreviousPage, ComponentLoader, CustomSpacer, IMultiSelectLabel } from '../../../../components';
import { LABEL } from '../../../../constants';
import CheckboxWithMultiSelectV2 from '../../../../components/organisms';
import CheckboxWithInput from '../../../../components/organisms/CheckboxWithInput';
import { RejectTransaction } from '../../..';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

export interface IReasons {
    title: string;
    reasons: IMultiSelectLabel[];
}

const sampleState = [[], [], []];
export const RejectActivity: React.FC = () => {
    const [rejectData, setRejectData] = useState<IReasons[]>([]);
    const [checkedReasons, setCheckedReasons] = useState<Array<boolean>>([false]);
    const [checkedValues, setCheckedValues] = useState<Array<ReactText>>([]);
    const [other, setOthers] = useState({
        checked: false,
        value: '',
    });
    const location = useLocation();
    const transaction = location.state as ITableData;
    const [remark, setRemark] = useState<string>('');
    const [isRequest] = useState<boolean>(true);
    const [requestData, setRequestData] = useState<IRerouteRequest>({
        eventType: '',
        requestorName: '',
        reasons: [],
        requestId: '',
    });
    // Component Loading
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    /** @requestDetails */
    const getRequestDetails = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: transaction.id,
                    },
                }),
                idTokenHeader,
            );

            const result = response.data.getLogData.data.result;
            if (response.data.getLogData.error !== null) throw result.error;
            if (result.data.data === null) {
                setErrorMessage({
                    ...errorMessage,
                    message: 'Data is null',
                    errorCode: '',
                    title: 'Cannot Fetch Reject Data',
                    testId: 'activitylogs-reject-error-modal',
                });
                handleErrorHandler();
                loadingHandler();
            } else {
                const allData = JSON.parse(result.data.data);

                const { reasons } = allData.transactionRequestData;
                const update: IReasons[] = [];
                let subs: IMultiSelectLabel[] = [];

                reasons.map((reason: any) => {
                    subs = [];
                    if (reason.category !== 'Others') {
                        if (reason.subCategory !== null) {
                            reason.subCategory.map((sub: any) => {
                                subs.push({
                                    key: sub[0],
                                    value: sub[1],
                                    label: sub[1],
                                });
                            });
                            update.push({ title: reason.category, reasons: subs });
                        } else update.push({ title: reason.category, reasons: [] });
                    }
                });
                loadingHandler();

                setRejectData(update);
                const requestDataFromApi = {
                    eventType: '',
                    requestorName: '',
                    reasons: reasons,
                    requestId: '',
                };
                setRequestData(requestDataFromApi);
            }
        } catch (error) {
            loadingHandler();

            setErrorMessage({
                ...errorMessage,
                message: error.message,
                errorCode: error.errorCode,
                title: 'Cannot Fetch Reject Data',
                testId: 'activitylogs-reject-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleCheckboxMultiSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const id = parseInt(e.target.id);
        const check = e.target.checked;
        let tempValues = [...checkedValues];
        const tempChecked = [...checkedReasons];
        if (check) {
            tempValues.push(value);
            tempChecked[id] = check;
        } else {
            tempValues = removeArrayElement(tempValues, value);
            tempChecked[id] = false;
        }

        setCheckedReasons(tempChecked);
        setCheckedValues(tempValues);
    };
    const handleCheckboxOthers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const check = e.target.checked;
        if (check) {
            setOthers({ checked: check, value: value });
        } else {
            setOthers({ checked: false, value: '' });
        }
    };
    const handleRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemark(event.target.value);
    };

    const [multiSelectDropdownState, setMultiSelectDropdownState] = useState<Array<Array<ReactText>>>(sampleState);
    const handleMultiSelectDropdown = (item: Array<ReactText>, fn: string, id: number) => {
        const tempState = [...multiSelectDropdownState];

        tempState[id] = item;
        setMultiSelectDropdownState(tempState);
    };

    const getRequestReasons = () => {
        loadingHandler();
        const tempState = [...multiSelectDropdownState];
        let tempSub = '';
        let tempRemarks = remark;
        requestData.reasons.map((item, index) => {
            const subs: ReactText[] = [];
            if (item.subCategory !== null && item.category !== 'Others') {
                item.subCategory.map((sub: any) => {
                    subs.push(sub[1]);
                });
                tempState[index] = subs;
            } else if (item.subCategory !== null && item.category === 'Others') {
                item.subCategory.map((sub: any) => {
                    tempSub = sub[1];
                });
                tempRemarks = tempSub;
            }
        });
        setMultiSelectDropdownState(tempState);
        setRemark(tempRemarks);
        loadingHandler();
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    // const approveRequest = () => {
    //     requestData?.requestId !== undefined ? reviewRequest(requestData?.requestId, 'approve') : null;
    // };
    // const handleRejectRequest = () => {
    //     requestData.requestId !== undefined ? reviewRequest(requestData.requestId, 'reject', rejectRemarks) : null;
    // };
    const checkboxStatus = (index: number): boolean => {
        let temp = false;
        if (isRequest === false) {
            temp = checkedReasons[index] !== undefined ? checkedReasons[index] : false;
        } else {
            if (requestData.reasons.length !== 0) temp = requestData.reasons[index].subCategory !== null;
        }
        return temp;
    };
    const otherStatus = (): boolean => {
        let temp = false;
        if (isRequest === false) temp = other.checked;
        else {
            if (requestData.reasons.length !== 0) {
                requestData.reasons.map((item) => {
                    if (item.subCategory !== null && item.category === 'Others') {
                        temp = true;
                    }
                });
            }
        }
        return temp;
    };
    useMemo(() => {
        requestData.reasons.length !== 0 ? getRequestReasons() : null;
    }, [requestData]);
    return (
        <Fragment>
            <PreviousPage title={isRequest ? LABEL.reviewRejectRequest : LABEL.rejectTransaction} />
            {/* <CustomSpacer space={'1rem'} /> */}
            <div style={{ marginLeft: '3.5rem' }}>
                <ReasonsLabel>{LABEL.reasonsToReject}</ReasonsLabel>
                <WrapperDiv>
                    {rejectData.map(({ title, reasons }, index) => {
                        return (
                            <div key={`reason_${index}`} style={{ marginBottom: '1.5rem' }}>
                                <CheckboxWithMultiSelectV2
                                    label={title}
                                    value={title}
                                    id={index.toString()}
                                    checked={checkboxStatus(index)}
                                    handleCheckbox={handleCheckboxMultiSelect}
                                    data={reasons}
                                    placeholder={LABEL.dropdownPlaceholderReason}
                                    selectLabel={LABEL.reasons}
                                    disabled={true}
                                    multiSelectState={multiSelectDropdownState[index]}
                                    handleMultiSelectDropdown={handleMultiSelectDropdown}
                                />
                            </div>
                        );
                    })}
                    <CheckboxWithInput
                        label={LABEL.others}
                        value={LABEL.others}
                        id={LABEL.others}
                        text={remark}
                        handleText={handleRemarks}
                        checked={otherStatus()}
                        disabled={true}
                        handleCheckbox={handleCheckboxOthers}
                    />
                </WrapperDiv>
            </div>
            <CustomSpacer space={'2rem'} />
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};
const ReasonsLabel = styled.div`
    font-size: 1.125rem;
    letter-spacing: -0.2px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.25rem;
`;
const WrapperDiv = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 2.25rem;
    max-width: 25vw;
`;

export default RejectTransaction;
