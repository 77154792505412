/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { SYSTEM_ADMIN, LABEL } from '../../../constants';
import { Profile, IAdviserProfileDetails } from './Profile';

import { Tabs, Modal } from '../../../components/organisms';
import { Banner, ComponentLoader, FlexedDiv } from '../../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { AgentProfileQuery } from '../../../_graphql/queries/systemadmin/adviserProfile';
import { handleRestructureData } from '../../AdvisorProfile/function';
import { EnableOmniMutation } from '../../../_graphql/mutations/systemAdmin/enableOmni';

import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import Loader from '../../../components/atoms/Loader';
import { useComponentLoader } from '../../../customHooks';

const { ADVISER_PROFILE } = SYSTEM_ADMIN;

const INITIAL_PROFILE = {
    name: 'Edgar Constantine',
    status: 'Active',
    code: '73465256208562060',
    nric: '880808088888',
    details: [
        {
            title: ADVISER_PROFILE.LABEL_PERSONAL_DETAILS,
            sections: [
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_HOME_BRANCH, data: 'KIB HQ Office' },
                        { label: ADVISER_PROFILE.LABEL_REGION, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_ADVISER_CHANNEL, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_OMNI_ENABLED, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_ADVISER_RANK, data: 'Customer Experience' },
                    ],
                },
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_ADVISER_BDM, data: 'KIB HQ Office' },
                        { label: ADVISER_PROFILE.LABEL_ADVISER_BDM_EMAIL, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_UPLINE_NAME_CODE, data: 'Customer Experience' },
                        { label: ADVISER_PROFILE.LABEL_AGENCY, data: 'Customer Experience' },
                    ],
                },
            ],
        },
        {
            title: ADVISER_PROFILE.LABEL_CONTACT_DETAILS,
            sections: [
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_EMAIL, data: 'christine.r@kenanga.com' },
                        { label: ADVISER_PROFILE.LABEL_MOBILE, data: '+60 121231234' },
                    ],
                },
            ],
        },
        {
            title: ADVISER_PROFILE.LABEL_ADDRESS_INFO,
            sections: [
                {
                    data: [
                        { label: ADVISER_PROFILE.LABEL_PERMANENT_ADDRESS, data: 'christine.r@kenanga.com' },
                        { label: ADVISER_PROFILE.LABEL_POSTCODE, data: '+60 121231234' },
                        { label: ADVISER_PROFILE.LABEL_TOWN, data: '+60 121231234' },
                        { label: ADVISER_PROFILE.LABEL_STATE, data: '+60 121231234' },
                        { label: ADVISER_PROFILE.LABEL_COUNTRY, data: '+60 121231234' },
                    ],
                },
            ],
        },
    ],
};
export const SystemAdminAdviserProfile: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [profile, setProfile] = useState<IAdviserProfileDetails>(INITIAL_PROFILE);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [omniEnabled, setOmniEnabled] = useState<boolean>(false);
    // const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const locationState: any = location.state;
    //Custom hooks
    const { loading, loadingHandler } = useComponentLoader();
    const handleOmniEnable = async () => {
        handleOmni('enable');
    };

    const handleOmni = async (action: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(EnableOmniMutation, {
                    input: {
                        id: locationState.userId,
                        action: action,
                        // filter: filters,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.enableOmni.data !== null) {
                setOmniEnabled(action === 'enable' ? true : false);
                setShowModal(true);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Perform Action',
                testId: 'adviserProfile-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleOmniDisable = () => {
        handleOmni('disable');
    };

    const getProfile = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(AgentProfileQuery, {
                    input: {
                        userId: locationState.userId,
                        // filter: filters,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.agentProfile.data !== null) {
                const restructuredData: IAdviserProfileDetails = handleRestructureData(
                    response.data.agentProfile.data.result,
                );

                restructuredData.name = locationState.name;
                restructuredData.code = locationState.code;
                restructuredData.status = locationState.status;
                setProfile(restructuredData);
                loadingHandler();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Profile Data',
                testId: 'adviserProfile-error-modal',
            });
            loadingHandler();
            handleErrorHandler();
        }
    };

    useEffect(() => {
        getProfile();
        loadingHandler();
    }, []);

    const modalTitle =
        omniEnabled === true ? SYSTEM_ADMIN.LABEL_OMNI_ENABLED_SUCCESS : SYSTEM_ADMIN.LABEL_OMNI_DISABLED_SUCCESS;
    const modalSubTitle =
        omniEnabled === true
            ? `${locationState.name} ${SYSTEM_ADMIN.LABEL_HAS_BEEN_GRANTED}`
            : `${locationState.name} ${SYSTEM_ADMIN.LABEL_HAS_BEEN_DENIED}`;

    const tabs = [<Profile profile={profile} key={1} />];
    return (
        <Fragment>
            <Loader loading={loading}>
                <Tabs
                    title={locationState.name}
                    tabTitles={[SYSTEM_ADMIN.LABEL_PROFILE]}
                    suffixHelper={locationState.status}
                    tabs={tabs}
                />
                <Banner
                    title={SYSTEM_ADMIN.LABEL_OMNI_ENABLE_FOR_THIS_ADVISER}
                    toggle={true}
                    data-testid="snackBar"
                    testId="adviserprofile-banner"
                    primaryButtonObject={{
                        handlePrimaryBtn: handleOmniEnable,
                        label: SYSTEM_ADMIN.BUTTON_YES,
                    }}
                    secondaryButtonObject={{
                        handleSecondaryBtn: handleOmniDisable,
                        label: SYSTEM_ADMIN.BUTTON_NO,
                    }}
                />
            </Loader>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTitle}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            // api call
                            history.push(Routes.adviserAccess);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="adviserprofile-modal"
                    icon="user-modal-success"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

export default SystemAdminAdviserProfile;
