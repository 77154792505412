/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getDropDownList } from '../../../../_graphql/queries/common/getDropDownList';
import styled from 'styled-components';

import { expandBulkUserCols } from '../../../../utils';
import { PreviousPage, TableSheet } from '../../../../components';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    id: string;
    maker: boolean;
}
interface StyledInnerProps {
    paddingBottom: number;
}
type BulkUserData = {
    [key: string]: string;
};

interface BranchGroupObj {
    id: string;
    value: string;
}
// bulk users table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 180,
    secondColWidth: 200,
    thirdColWidth: 42,
};
export const BulkImportActivity: React.FC = () => {
    // states to generate table
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [branches, setBranches] = useState<BranchGroupObj[]>([]);
    const [departments, setDepartments] = useState<BranchGroupObj[]>([]);
    const [groups, setGroups] = useState<BranchGroupObj[]>([]);
    // states for UI utility components
    const [isProcessing, setIsProcessing] = useState(false);
    const [operationName, setOperationName] = useState<string>('');
    const { state: propsData } = useLocation<IRouteProps>();

    const [bannerHeight] = useState<number>(0);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //call query to get master fund doc by id
    const getBulkUserDataQuery = async (id: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = response.data.getLogData;

            if (!error) {
                if (data.result.data.status === 'FAILED') {
                    setOperationName(data.result.data.operationName);
                    const _bulkImportUsers = JSON.parse(data.result.data.data);
                    if (_bulkImportUsers) {
                        const rawData: BulkUserData[] = _bulkImportUsers.OfficeUser;

                        // process to bind resposnse data to  table
                        rawData !== null && processBulkUsersData(rawData);
                    }
                } else {
                    setOperationName(data.result.data.operationName);
                    const _bulkImportUsers = JSON.parse(data.result.data.data);
                    if (_bulkImportUsers) {
                        const rawData: BulkUserData[] = _bulkImportUsers.OfficeUser;

                        // process to bind resposnse data to  table
                        rawData !== null && processBulkUsersData(rawData);
                    }
                }
            } else throw error;

            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Bulk Users Data',
                testId: 'activitylogs-bulkusers-error-modal',
            });
            handleErrorHandler();
        }
    };
    //code to build columns and cells
    const processBulkUsersData = (rawData: BulkUserData[]) => {
        //code to build columns and cells
        let tempCols: string[] = [];
        let tempCells: any = [];
        let columnObj: any = {};
        let cellObj: any = {};

        try {
            tempCols = Object.keys(rawData[0]);

            // setCamelCasecolumns(tempCols);
            tempCols = expandBulkUserCols(tempCols);

            //separate data to header columns
            const columnsOutput: ISheetColumn[] = [];
            tempCols.map((column: string) => {
                //fixed values from incoming data
                switch (column.toLowerCase().trim()) {
                    case 'group id':
                        columnObj = {
                            colName: 'Group Name',
                            type: 'string',
                        };
                        break;
                    case 'branch id':
                        columnObj = {
                            colName: 'Branch Name',
                            type: 'string',
                        };
                        break;
                    case 'department id':
                        columnObj = {
                            colName: 'Department Name',
                            type: 'string',
                        };
                        break;
                    default:
                        columnObj = {
                            colName: column,
                            type: 'string',
                        };
                }

                columnsOutput.push(columnObj);
            });

            //separaete data to rows
            const cellRow: ISheetCell[][] = [];
            tempCells = rawData.map((row: BulkUserData) => Object.values(row));

            //make tempCells to objects and then push to an array
            tempCells.map((row: any[], _index: number) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, index) => {
                    switch (column.colName.toLowerCase()) {
                        case 'group name':
                            cellObj = {
                                cellValue: getGroupNames(column.colName, row[index]),
                                isValid: true,
                            };
                            break;
                        default:
                            cellObj = {
                                cellValue: getBranchDeptName(column.colName, row[index]),
                                isValid: true,
                            };
                    }

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };
    //function to get user group names and banches
    const getUserGroupBranchDepartData = async () => {
        try {
            const response: any = await API.graphql(graphqlOperation(getDropDownList, {}), idTokenHeader);
            const { data } = response.data.getDropDownList;
            if (data !== null) {
                setBranches(data.result.branches);
                setDepartments(data.result.departments);
                setGroups(data.result.groups);
            }
        } catch (error) {}
    };

    const getGroupNames = (_colName: string, value: string[]): string => {
        const _tempArray: string[] = [];

        value.map((item) => {
            groups.map((group) => {
                if (item === group.id) _tempArray.push(group.value);
            });
        });

        return _tempArray.join();
    };
    const getBranchDeptName = (colName: string, value: string): string => {
        let _temp = value;
        switch (colName.toLowerCase()) {
            case 'department name':
                departments.map((deaprtment) => {
                    if (_temp === deaprtment.id) _temp = deaprtment.value;
                });
                break;
            case 'branch name':
                branches.map((branch) => {
                    if (_temp === branch.id) _temp = branch.value;
                });
                break;
        }

        return _temp;
    };
    useEffect(() => {
        setIsProcessing(true);
        getUserGroupBranchDepartData();
    }, []);
    useEffect(() => {
        if (propsData) {
            getBulkUserDataQuery(propsData.id);
        }
    }, [groups]);
    // const handleTitle = (operationName: string) => {
    //     if (propsData.maker === true) {
    //         return 'Review Bulk Import Users Request';
    //     }
    //     return operationName;
    // };

    return (
        <Fragment>
            <PreviousPage title={`Review Bulk Import Users Request`} />
            <StyledInner paddingBottom={bannerHeight}>
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <TableSheet columns={columns} rows={rows} readyOnly={true} columnWidths={fixedCoLumnlWidth} />
                )}
            </StyledInner>
        </Fragment>
    );
};

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 3.5rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;
