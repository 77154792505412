import React, { Fragment, useEffect, useState } from 'react';
import FlexSpacer, { Collapsible, DataWithLabel, FlexedDiv } from '../../../components';
import { LABEL, TextNavyBlue } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { MultiSection } from '../../../templates';
import { makeArray } from '../../../utils';
import styled from 'styled-components';
interface IFundDetailsTabProps {
    tabFundData: IFundDetails;
}
export const FundDetails: React.FC<IFundDetailsTabProps> = ({ tabFundData }: IFundDetailsTabProps) => {
    const [main, setMain] = useState<IFundDetails>();
    const [array, setArray] = useState<Array<IProfileSection>>([]);
    //Accordion states
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const [expands, setExpands] = useState<boolean[]>([
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);
    // Fn to handle accordion collapse
    const toggleCollapse = (index: number) => {
        const exp = [...expands];
        if (index === -1) {
            if (!expandAll) {
                const _tempExpandAll: boolean[] = [];
                exp.forEach(() => _tempExpandAll.push(true));
                setExpandAll(true);
                setExpands(_tempExpandAll);
            } else {
                const _tempHideAll: boolean[] = [];
                exp.forEach(() => _tempHideAll.push(false));
                setExpandAll(false);
                setExpands(_tempHideAll);
            }

            return;
        }
        exp[index] = !exp[index];
        setExpands(exp);
    };

    useEffect(() => {
        const temp = makeArray([
            tabFundData.fundSummary,
            tabFundData.investmentSummary,
            tabFundData.feesSummary,
            tabFundData.epfSummary,
            tabFundData.redemptionSummary,
            tabFundData.switchingSummary,
            tabFundData.salesSummary,
            tabFundData.topUpSummary,
            tabFundData.transferSummary,
            tabFundData.description,
        ]);
        setMain(tabFundData);
        setArray(temp);
    }, [tabFundData]);

    return (
        <Fragment>
            <FlexedDiv alignItems="flex-start">
                <FlexedDiv direction="column" style={{ margin: '0 2rem' }}>
                    <DataWithLabel
                        direction="column"
                        label={LABEL.fundName}
                        data={main !== undefined ? main.fundName : ''}
                        testId="fund-name"
                    />
                    <DataWithLabel
                        direction="column"
                        label={LABEL.fundUtmc}
                        data={main !== undefined ? main.utmc : ''}
                        testId="fund-utmc"
                    />
                </FlexedDiv>
                <FlexSpacer grow={2} />
                <FlexedDiv
                    direction="column"
                    grow={1}
                    alignItems="flex-end"
                    justifyContent="space-between"
                    style={{ height: 'inherit', margin: '0 2rem' }}
                >
                    <div style={{ height: '6.5rem' }} />
                    <FlexedDiv
                        style={{ cursor: 'pointer', alignItems: 'center' }}
                        onClick={() => toggleCollapse(-1)}
                        id="accordion-expand-toggle"
                    >
                        <TextNavyBlue size="12px" weight="700" style={{ marginRight: '4px' }}>
                            {expandAll ? LABEL.collapseAll : LABEL.expandAll}
                        </TextNavyBlue>
                        <IcoMoon name={expandAll ? LABEL.collapse : LABEL.expand} size="18px" />
                    </FlexedDiv>
                </FlexedDiv>
            </FlexedDiv>

            {array !== undefined
                ? array.map((item, index) => {
                      return (
                          <AccordionContainer
                              key={`Collapse${index + 1}`}
                              id={`${
                                  item.title && item.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                              }-accordion`}
                          >
                              <Collapsible
                                  title={item.title ? item.title : ''}
                                  isCheckable={false}
                                  expanded={expands[index]}
                                  noMargin={true}
                                  noXMargin={true}
                                  showBoxShadow={true}
                                  content={<MultiSection sections={item.sections} />}
                                  handleExpand={() => toggleCollapse(index)}
                                  testId={
                                      item.title && item.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                                  }
                              />
                          </AccordionContainer>
                      );
                  })
                : null}
        </Fragment>
    );
};

const AccordionContainer = styled.div`
    margin: 2rem;
`;
