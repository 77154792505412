import gql from 'graphql-tag';

export const userBulkUpload = gql`
    mutation userBulkUpload($input: UserBulkUploadInput) {
        userBulkUpload(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
