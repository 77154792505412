import gql from 'graphql-tag';

/**
 * @getOrderSummaryV2 @query - HQ Dashboard
 * @description: Returns order summary
 */

export const getOrderSummaryV2 = /* GraphQL */ gql`
    query getOrderSummaryV2($input: OrderSummaryInputV2) {
        getOrderSummaryV2(input: $input) {
            data {
                result {
                    approval {
                        name
                        eventType
                        status
                        remarks
                    }
                    requestId
                    requesterName
                    status
                    orderNo
                    transactionRef
                    transactionType
                    remarks {
                        title
                        content
                    }
                    extensionRemarks {
                        date
                        remarks
                    }
                    totalInvestment {
                        fundCurrency
                        investmentAmount
                    }
                    transactionDetails {
                        registrationDate
                        servicingAdviserName
                        servicingAdviserCode
                        kibProcessingBranch
                        accountType
                        accountNo
                        accountOperationMode
                        bdmName
                    }
                    investmentSummary {
                        fundName
                        utmc
                        fundCurrency
                        investmentAmount
                        fundCode
                        fundClass
                        salesCharge
                        accountFund
                        productType
                        investmentType
                        feaTagged
                        distributionInstructions
                        recurring
                    }
                    paymentSummary {
                        # isCombined
                        fundCurrency
                        investmentAmount
                        paymentMethod
                        paymentOn
                        remark
                        # surplusNote
                        proofOfPayment {
                            name
                            url
                            type
                        }
                        kibBankName
                        kibBankAccountNumber
                        bankName
                        checkNumber
                        referenceNumber
                        clientName
                        clientTrustAccountNumber
                        epfReferenceNo
                        epfAccountNumber
                        bankAccountName
                        bankAccountNumber
                        recurringType
                        recurringBank
                        frequency
                        # status
                    }
                    profile {
                        name
                        idNumber
                        idType
                        idFile
                        personalDetails {
                            dateOfBirth
                            salutation
                            gender
                            nationality
                            bumiputera
                            race
                            placeOfBirth
                            countryOfBirth
                            educationLevel
                            mothersMaidenName
                            maritalStatus
                            riskProfile
                            relationship
                            monthlyHouseholdIncome
                        }
                        epfDetails {
                            epfMemberNumber
                            epfAccountType
                        }
                        employmentInformation {
                            occupation
                            natureOfBusiness
                            annualIncome
                            nameOfEmployer
                            address {
                                address {
                                    line1
                                    line2
                                    line3
                                }
                                city
                                country
                                postCode
                                state
                            }
                        }
                        addressInformation {
                            mailingAddress {
                                address {
                                    line1
                                    line2
                                    line3
                                }
                                city
                                country
                                postCode
                                state
                            }
                            permanentAddress {
                                address {
                                    line1
                                    line2
                                    line3
                                }
                                city
                                country
                                postCode
                                state
                            }
                        }
                        contactDetails {
                            officeNumber
                            homeNumber
                            mobileNumber
                            faxNumber
                            email
                        }
                        bankInformation {
                            localBank {
                                currency
                                bankName
                                bankAccountName
                                bankAccountNumber
                            }
                            foreignBank {
                                currency
                                bankName
                                bankAccountName
                                bankAccountNumber
                                bankLocation
                                bankSwiftCode
                            }
                        }
                        fatcaDeclaration {
                            usCitizen
                            usBorn
                            confirmAddress
                            certificate {
                                name
                                url
                                type
                            }
                            formW9 {
                                name
                                url
                                type
                            }
                            formW8Ben {
                                name
                                url
                                type
                            }
                            reason
                            correspondenceDeclaration
                        }
                        crsDeclaration {
                            taxResident
                            tin {
                                country
                                tinNumber
                                reason
                            }
                        }
                        feaDeclaration {
                            resident
                            borrowingFacility
                            balance
                        }
                    }
                    documentSummary {
                        accountType
                        softcopy {
                            required
                            documents {
                                mainHeader
                                subHeader
                                documents {
                                    title
                                    name
                                    url
                                    label
                                    type
                                }
                            }
                        }
                        hardcopy {
                            required
                            utmcDocs {
                                mainHeader
                                subHeader
                                documents {
                                    title
                                    name
                                    url
                                    label
                                    type
                                }
                            }
                            accDocs {
                                mainHeader
                                subHeader
                                documents {
                                    url
                                    title
                                    name
                                    label
                                    type
                                }
                            }
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
