import React, { Fragment, useContext } from 'react';

import { AdvanceTable } from '../../../../components';
import { EMPTY_STATES, SYSTEM_ADMIN } from '../../../../constants';
import { subTitleStyle } from '../../../../styles';
import { statusStyle } from '../../../../utils';
import ActivityLogsContext from '../../../../context/SystemAdminContext/activityLogs';
import CustomItem from '../UserActivity/CustomItem';

declare interface IReports {
    searchEmpty: boolean;
}

export const Reports: React.FC<IReports> = ({ searchEmpty }: IReports) => {
    // Context
    const { allReportsData, searchInput, sortData, setSortData } = useContext(ActivityLogsContext);

    const handleSort = (keyName: string) => {
        const tempSort = { ...sortData };
        const sortType = sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortData.column === keyName) {
            const icon = sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };

    const columns: ITableColumn[] = [
        {
            key: [{ key: 'operationName', textStyle: { fontWeight: sortData.column === 'operationName' ? 700 : 400 } }],

            viewStyle: {
                width: '19.4vw',
            },
            icon: {
                name: handleSortIcon('operationName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('operationName'),
            title: SYSTEM_ADMIN.LABEL_OPERATION_NAME,
            titleStyle: {
                fontWeight: sortData.column === 'operationName' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'fileSize' }],
            viewStyle: {
                width: '6.667vw',
            },
            title: SYSTEM_ADMIN.LABEL_FILE_SIZE,
            titleStyle: {
                fontWeight: sortData.column === 'fileSize' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('fileSize'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('fileSize'),
            customItem: true,
        },
        {
            key: [
                { key: 'name', textStyle: { fontWeight: sortData.column === 'name' ? 700 : 400 } },
                { key: 'userName', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '13.33vw',
            },
            title: SYSTEM_ADMIN.LABEL_INITIATED_BY,
            titleStyle: {
                fontWeight: sortData.column === 'name' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('name'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('name'),
        },
        {
            customItem: true,
            key: [{ key: 'roles' }],
            viewStyle: {
                width: '12.22vw',
            },
            title: SYSTEM_ADMIN.LABEL_ROLES,
            // icon: {
            //     name: 'caret-down',
            //     size: '1rem',
            // },
        },
        {
            key: [{ key: 'eventType', textStyle: { fontWeight: sortData.column === 'eventType' ? 700 : 400 } }],
            viewStyle: {
                width: '7.43vw',
            },
            title: SYSTEM_ADMIN.LABEL_EVENT_TYPE,
            titleStyle: {
                fontWeight: sortData.column === 'eventType' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('eventType'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('eventType'),
        },
        {
            customItem: true,
            key: [{ key: 'createdOn' }],
            viewStyle: {
                width: '8.472vw',
            },
            title: SYSTEM_ADMIN.LABEL_CREATED_ON,
            titleStyle: {
                fontWeight: sortData.column === 'createdOn' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('createdOn'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('createdOn'),
        },
        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: '8.68vw',
            },
            title: SYSTEM_ADMIN.LABEL_STATUS,
            titleStyle: {
                fontWeight: sortData.column === 'status' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('status'),
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allReportsData}
                columns={columns}
                onEmptyState={EMPTY_STATES.emptyStateReports}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData.column} {...props} />}
                isSearchResult={searchEmpty}
                searchInput={searchInput.value}
            />
        </Fragment>
    );
};
