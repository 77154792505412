/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { adviserAccessDashboard } from '../../../_graphql/queries/systemadmin/adviserDashboard';
import { LABEL } from '../../../constants';
import { IDropdownWithKey } from '../../../pages';
import AdviserAccessContext from '.';
import AuthContext from '../../AuthContext';
import ErrorHandlingContext from '../../ErrorHandling/ErrorHandlingContext';

interface IAdviserAccessProviderProps {
    children: React.ReactNode;
}
const initialAdviserFilters = [
    { column: 'createdOn', value: '' },
    { column: 'branch', value: '' },
    { column: 'channel', value: '' },
    { column: 'agency', value: '' },
    { column: 'status', value: '' },
    { column: 'omni', value: 'yes' },
    { column: 'omni', value: 'no' },
];
export const AdviserAccessProvider: React.FC<IAdviserAccessProviderProps> = ({
    children,
}: IAdviserAccessProviderProps) => {
    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'agentName', value: 'ascending' }]);
    const [filters, setFilters] = useState<IColumnValue[]>(initialAdviserFilters);
    const [statusFilter, setStatusFilter] = useState<string>(LABEL.agentStatus);
    const [counter, setCounter] = useState<Array<number>>([]);
    const [label, setLabel] = useState<string[]>([]);
    // Dashboard tab data states
    const [allAdvisers, setAllAdvisers] = useState<ITableData[]>([]);
    // UI states
    const [loading, setLoading] = useState<boolean>(false);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const [createdOnDropdown, setCreatedOnDropdown] = useState<IDropdownWithKey>({
        value: 'Created On',
        keyName: 'createdOn',
    });

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //fn to handle loading
    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };
    //call query to get all adviser
    const getAllAdvisers = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(adviserAccessDashboard, {
                    input: {
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );

            const { result } = await response.data.systemAdminAdviserDashboard.data;
            // console.log(result, 'getAllAdvisers');
            setCounter([result.adviserCount]);

            const allAdvisers: ITableData[] = [];
            if (response.data.systemAdminAdviserDashboard.data !== null) {
                result.advisers.map((location: any) => {
                    location.advisers.map((adviser: ITableData) => {
                        const adviserObj = { ...adviser, branch: location.branch };
                        allAdvisers.push(adviserObj);
                    });
                });

                setAllAdvisers(allAdvisers);
                setDisableResultLimit(allAdvisers.length === 0);
                if (currentPage > result.pages) {
                    setCurrentPage(1);
                }
                setMaxPages(result.pages);
            } else {
                throw response.data.systemAdminAdviserDashboard.error;
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard Data',
                testId: 'adviseraccess-dashboard-error-modal',
            });
            handleErrorHandler();
        }
    };

    const ProviderValue: IAdviserAccessContext = {
        allAdvisers,
        counter,
        createdOnDropdown,
        currentPage,
        disableResultLimit,
        filters,
        getAllAdvisers,
        label,
        loading,
        maxPages,
        resultLimit,
        searchInput,
        setCounter,
        setCreatedOnDropdown,
        setCurrentPage,
        setFilters,
        setLabel,
        setResultLimit,
        setSearchInput,
        setShouldUpdate,
        setSortData,
        setStatusFilter,
        setTab,
        loadingHandler,
        shouldUpdate,
        sortData,
        statusFilter,
        tab,
    };
    return <AdviserAccessContext.Provider value={ProviderValue}>{children}</AdviserAccessContext.Provider>;
};
