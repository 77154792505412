import moment from 'moment';
import { LABEL } from '../../../constants';
import { toTitleCase } from '../../../utils';

export const getFilterTagLabels = (tags: Array<ISelectValue>, filters: ISort_FilterInput): Array<ISelectValue> => {
    const temp = [...tags];
    filters.map((item) => {
        const tempObj: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item.column) {
            case 'lastUpdated':
                if (item.value !== '') {
                    tempObj.label = LABEL.lastUpdated;
                    tempObj.value = item.value
                        .split('~')
                        .map((time) => moment(time, 'x').format('DD/MM/YYYY'))
                        .join('-');
                }
                break;
            case 'createdOn':
                if (item.value !== '') {
                    tempObj.label = LABEL.createdOn;
                    tempObj.value = item.value
                        .split('~')
                        .map((time) => moment(time, 'x').format('DD/MM/YYYY'))
                        .join('-');
                }
                break;
            case 'submittedOn':
                if (item.value !== '') {
                    tempObj.label = LABEL.submittedOn;
                    tempObj.value = item.value
                        .split('~')
                        .map((time) => moment(time, 'x').format('DD/MM/YYYY'))
                        .join('-');
                }
                break;
            case 'status':
                if (item.value !== '') {
                    tempObj.label = LABEL.status;
                    tempObj.value = toTitleCase(item.value);
                }
                break;
            case 'transactionType':
                if (item.value !== '') {
                    tempObj.label = LABEL.transTypes;
                    tempObj.value = item.value;
                }
                break;
            case 'fundType':
                if (item.value !== '') {
                    tempObj.label = LABEL.productTypes;
                    tempObj.value = item.value.toUpperCase();
                }
                break;
            case 'paymentMethod':
                if (item.value !== '') {
                    tempObj.label = LABEL.fundingOption;
                    tempObj.value = item.value;
                }
                break;
            case 'accountType':
                if (item.value !== '') {
                    tempObj.label = LABEL.accountTypes;
                    tempObj.value = toTitleCase(item.value);
                }
                break;
            case 'riskCategory':
                if (item.value !== '') {
                    tempObj.label = LABEL.risk;
                    tempObj.value = toTitleCase(item.value);
                }
                break;
            case 'tab':
            case 'fundCategory':
            case '!fundCategory':
            case 'batchTime':
            case 'orderType':
                break;
            default:
                if (item.value !== '') {
                    tempObj.label = toTitleCase(item.column);
                    tempObj.value = toTitleCase(item.value);
                }
                break;
        }
        tempObj.label !== '' ? temp.push(tempObj) : null;
    });
    return temp;
};
export const removeSearchFilterTagFromFilter = (tag: ISelectValue, filters: ISort_FilterInput): ISort_FilterInput => {
    const temp = [...filters];
    const tempSelectValue = { ...tag };
    switch (tag.label) {
        case LABEL.lastUpdated:
            tempSelectValue.label = 'lastUpdated';
            break;
        case LABEL.createdOn:
            tempSelectValue.label = 'createdOn';
            break;
        case LABEL.submittedOn:
            tempSelectValue.label = 'submittedOn';
            break;
        case LABEL.status:
            tempSelectValue.label = 'status';
            break;
        case LABEL.transTypes:
            tempSelectValue.label = 'transactionType';
            break;
        case LABEL.productTypes:
            tempSelectValue.label = 'fundType';
            break;
        case LABEL.fundingOption:
            tempSelectValue.label = 'paymentMethod';
            break;
        case LABEL.accountTypes:
            tempSelectValue.label = 'accountType';
            break;
        default:
            tempSelectValue.label = tag.label.toLowerCase();
            break;
    }
    const dateColumns = ['lastUpdated', 'createdOn', 'submittedOn'];
    let _index = 0;
    filters.map((item, index) => {
        // remove transactionType check when etb
        if (item.column === tempSelectValue.label && item.column !== 'transactionType') {
            const dateFound = dateColumns.map((_item) => {
                return item.column.includes(_item);
            });
            if (dateFound.filter(Boolean).length !== 0) {
                temp[index].value = '';
            } else {
                if (tempSelectValue.value.toLowerCase() === item.value.toLowerCase()) _index = index;
            }
        }
    });
    temp.splice(_index, 1);
    return temp;
};
