/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL } from '../../constants';
import { ComponentLoader, CustomButton, FlexedDiv, PreviousPage } from '../../components';
import { reasonsPageButtons } from '../../styles';
import { Modal, TableSheet } from '../../components/organisms';
import { IcoMoon } from '../../icons';
import { useComponentLoader } from '../../customHooks';
import styled, { css } from 'styled-components';
import { checkValid, convertToLowerCamelCase, checkFileType } from '../../utils';

import * as Routes from '../../routes';
import moment from 'moment';
import XLSX from 'xlsx';
import fundUploadMutation from '../../_graphql/mutations/fundData/fundUpload';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import WebSocketContext from '../../context/WebSocketContext';

// Types & interfaces

interface IViewImportProps {
    file: File;
    uploadType: string;
    tabIndex: number;
    tabName: string;
}

const modalMessages = {
    approved: {
        heading: '',
        subHeading: '',
        icon: 'approved-transaction',
    },
    pending: {
        heading: '',
        subHeading: 'This request has been submitted and pending for review',
        icon: 'approved-extension',
    },
};
// masterdata table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 260,
    secondColWidth: 156,
    thirdColWidth: 42,
};

const ViewImport: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { ws } = useContext(WebSocketContext);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { uploads } = parsedPermission.hq.permission;

    const history = useHistory();
    // states for user permissions
    const [isAutoAuth, setIsAutoAuth] = useState<boolean>(false);

    // states for modals, title and description
    const [showModal, setShowModal] = useState(true);
    const [subTitle, setSubTitle] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    // states to generate table
    const [fileType, setFileType] = useState<string>('');
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [rowsMarked, setRowsMarked] = useState(false); // track if single row is marked or not
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFileValid, setIsFileValid] = useState(false);
    const [hiddenRows, setHiddenRows] = useState<string[][]>([]);
    const { state: propsData } = useLocation<IViewImportProps>();

    // custom hooks
    const { loading, loadingHandler } = useComponentLoader();
    // check page permissions
    const checkPermissions = () => {
        // set modal heading and sub heading depending on upload type
        modalMessages.approved.heading = `Import ${propsData.uploadType} request approved`;
        modalMessages.approved.subHeading = `${propsData.uploadType} has been updated.`;
        modalMessages.pending.heading = `Import ${propsData.uploadType} request submitted`;
        switch (propsData.uploadType.toLowerCase()) {
            case 'master fund':
                if (
                    uploads.masterFundTab.reviewApproval &&
                    uploads.masterFundTab.reviewApproval.canApproveMasterFund === 'auto-authorizer'
                ) {
                    setIsAutoAuth(true);
                }

                break;
            case 'nav':
                if (
                    uploads.navPerUnitTab.reviewApproval &&
                    uploads.navPerUnitTab.reviewApproval.canApproveNav === 'auto-authorizer'
                ) {
                    setIsAutoAuth(true);
                }

                break;
            case 'distribution':
                if (
                    uploads.distributionTab.reviewApproval &&
                    uploads.distributionTab.reviewApproval.canApproveDistribution === 'auto-authorizer'
                ) {
                    setIsAutoAuth(true);
                }

                break;
            default:
                break;
        }
    };

    /**
     * Fn to hanlde fund data from sheet js
     * @param rawColumns array of strings.
     * @param rawRows array of array SheetRawData
     * @method processFundData
     */
    const processFundData = (rawColumns: string[], rawRows: SheetRawData[][]) => {
        const columnsOutput: ISheetColumn[] = [];
        const cellRowOutput: ISheetCell[][] = [];
        let _isAMP = false;

        let tempCols: string[] = [];
        let tempCells: SheetRawData[][] = [];

        tempCols = rawColumns; //get first header row
        tempCells = rawRows; //get rest of the rows

        //remove first two columns (TOMS Fund Code & Abbreviation) from each row and keep them for submission
        const firstTwoCols = tempCells.map((row: SheetRawData[]) => {
            const _array = row.splice(0, 2).map((result) => result.toString().trim());

            return _array;
        });

        setHiddenRows(firstTwoCols);
        //separaete data to header columns
        tempCols.map((column: string) => {
            const columnObj: ISheetColumn = { colName: '', type: 'string' };
            //dont include fund code and TOMS Fund Abbreviation for UT funds
            if (
                column.toLowerCase() !== 'toms fund code' &&
                column.toLowerCase() !== 'toms fund abbreviation' &&
                column.toLowerCase() !== 'amp abbreviation' &&
                column.toLowerCase() !== 'fund code'
            ) {
                //trim tabs, newlines, symbols, (Y/N) from each column name
                const cleanColummn = column.replace('(Y/N)', '').replace(/\n|\r/g, ' ').replace(/\s\s+/g, ' ');

                columnObj.colName = cleanColummn;
                columnsOutput.push(columnObj);
            }
            // Check if the file is of AMP
            if (column.toLowerCase() === 'amp abbreviation') {
                _isAMP = true;
            }
        });

        //build table rows
        tempCells.map((row: any[]) => {
            const tempRow: ISheetCell[] = [];

            columnsOutput.map((column, index) => {
                const cellObj: ISheetCell = {
                    cellValue: '',
                    isValid: false,
                    isMarked: false,
                    isDelete: false,
                    isFileSize: false,
                };
                if (index == 0) {
                    cellObj.cellValue = row[index].toString().trim();
                    cellObj.isValid = checkValid(column.colName, row[index].toString().trim());
                } else if (row[index] instanceof Date) {
                    const tempDateVal = moment(row[index]).add(1, 'hours').format('DD/MM/YYYY');
                    cellObj.cellValue = tempDateVal;
                    cellObj.isValid = checkValid(column.colName, tempDateVal);
                } else if (typeof row[index] == 'undefined') {
                    cellObj.isValid = checkValid(column.colName, row[index]);
                } else {
                    switch (column.colName.toLowerCase()) {
                        //convert values to uppercase
                        case 'fund name':
                        case 'fund category':
                            cellObj.cellValue = row[index].toString().trim().toUpperCase();
                            cellObj.isValid = checkValid(column.colName, row[index].toString().trim());
                            break;
                        case 'fund start date':
                        case 'iop end date':
                        case 'non business day from date':
                        case 'non business day to date':
                        case 'value date':
                            cellObj.cellValue = moment(row[index].toString().trim())
                                .add(1, 'hours')
                                .format('DD/MM/YYYY');
                            cellObj.isValid = checkValid(column.colName, cellObj.cellValue);
                            break;
                        default:
                            cellObj.cellValue = row[index].toString().trim();
                            cellObj.isValid = checkValid(column.colName, row[index].toString().trim());
                    }
                }

                tempRow.push(cellObj);
            });

            cellRowOutput.push(tempRow);
        });

        setColumns(columnsOutput);
        setRows(cellRowOutput);
        setIsFileValid(true);
    };

    /**
     * Fn to hanlde NAv and distribution data from sheet js
     * @param rawColumns array of strings.
     * @param rawRows array of array SheetRawData
     * @method processNavDistributionData
     */
    const processNavDistributionData = (rawColumns: string[], rawRows: SheetRawData[][]) => {
        const columnsOutput: ISheetColumn[] = [];
        const cellRow: ISheetCell[][] = [];

        let tempCols: any = [];
        let tempCells: any = [];
        tempCols = rawColumns; //get first header row
        tempCells = rawRows; //get rest of the rows

        //separate data to header columns
        tempCols.map((column: string) => {
            const columnObj: ISheetColumn = { colName: '', type: 'string' };
            //trim tabs, newlines, symbols, (Y/N) from each column name
            const cleanColummn = column.replace(/\n|\r/g, ' ').replace(/\s\s+/g, ' ');
            columnObj.colName = cleanColummn;
            columnsOutput.push(columnObj);
        });
        //separate data to rows

        tempCells.map((row: any[]) => {
            const tempRow: ISheetCell[] = [];
            columnsOutput.map((column, index) => {
                const cellObj: ISheetCell = {
                    cellValue: '',
                    isValid: false,
                    isMarked: false,
                    isDelete: false,
                    isFileSize: false,
                };
                if (index == 0) {
                    //for AMP fund fund abbr is empty string
                    cellObj.cellValue = typeof row[index] !== 'undefined' ? row[index].toString().trim() : '';
                    cellObj.isValid = checkValid(column.colName, row[index].toString().trim());
                } else if (row[index] instanceof Date) {
                    const tempDateVal = moment(row[index]).add(1, 'hours').format('DD/MM/YYYY');
                    cellObj.cellValue = tempDateVal;
                    cellObj.isValid = checkValid(column.colName, tempDateVal);
                } else if (typeof row[index] == 'undefined') {
                    cellObj.isValid = checkValid(column.colName, row[index]);
                } else {
                    cellObj.cellValue = row[index].toString().trim();
                    cellObj.isValid = checkValid(column.colName, row[index].toString().trim());
                }

                tempRow.push(cellObj);
            });
            cellRow.push(tempRow);
        });

        setColumns(columnsOutput);
        setRows(cellRow);
        setIsFileValid(true);
    };
    // processs sheet data according to tab
    const checkSheetData = async (rawColumns: string[], rawRows: SheetRawData[][]) => {
        switch (propsData.uploadType) {
            case 'NAV':
                rawColumns.length == 6
                    ? processNavDistributionData(rawColumns, rawRows)
                    : alert(`This is not a valid ${propsData.uploadType} list`);
                break;
            case 'distribution':
                rawColumns.length == 8
                    ? processNavDistributionData(rawColumns, rawRows)
                    : alert(`This is not a valid ${propsData.uploadType} list`);
                break;
            default:
                rawColumns.length == 88
                    ? processFundData(rawColumns, rawRows)
                    : alert('This is not a valid master fund list');
        }

        setTimeout(() => {
            setIsProcessing(false);
        }, 0);
    };
    //function to handle marking row as selected
    const markRowOkHandler = (rowIndex: number) => {
        const tempRow = [...rows];

        if (!tempRow[rowIndex][0].isDelete && !tempRow[rowIndex][0].isMarked) {
            tempRow[rowIndex][0].isMarked = true;
        } else {
            tempRow[rowIndex][0].isMarked = false;
        }
        setRows(tempRow);
    };
    //function to handle marking row as deselected
    const markRowDeleteHandler = (rowIndex: number) => {
        const tempRow = [...rows];

        if (tempRow[rowIndex][0].isDelete) {
            tempRow[rowIndex][0].isDelete = false;
            tempRow[rowIndex][0].isMarked = false;
        } else {
            tempRow[rowIndex][0].isDelete = true;
        }

        setRows(tempRow);
    };
    //function to handle marking all rows as selected
    const markAllRowHandler = () => {
        const tempRow = [...rows];

        tempRow.map((row, rowIndex) => {
            // tempRow[rowIndex][0].isMarked = false;
            if (row.some((e) => e.isValid === false)) {
                tempRow[rowIndex][0].isMarked = false;
            } else {
                tempRow[rowIndex][0].isMarked = true;
            }
        });

        setRows(tempRow); //set valid rows to marked
    };
    //function to handle marking all rows as deselected
    const undoAllHandler = () => {
        const tempRow = [...rows];
        tempRow.map((row, rowIndex) => {
            // tempRow[rowIndex][0].isMarked = false;
            if (row.some((e) => e.isValid === false)) {
                tempRow[rowIndex][0].isMarked = false;
            } else {
                tempRow[rowIndex][0].isMarked = false;
                tempRow[rowIndex][0].isDelete = false;
            }
        });
        setRows(tempRow);
    };

    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    const goBacktoUploads = () => {
        history.push({
            pathname: Routes.financeImportFileUploader,
            state: {
                uploadType: propsData.uploadType,
                tabName: propsData.tabName,
                tabIndex: propsData.tabIndex,
            },
        });
    };
    const goBacktoUploadDashboard = () => {
        history.push({
            pathname: Routes.financeUploads,
            state: {
                activeTab: propsData.tabIndex,
            },
        });
    };
    //call mutation to upload fund data
    const uploadFundData = async (masterFundPayLoad: IFundUploadPayload) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundUploadMutation, {
                    input: masterFundPayLoad,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.fundUpload;
            if (error) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Import ${propsData && propsData.uploadType} request failed`,
                    testId: 'view-import-error-modal',
                });
                loadingHandler();
                handleErrorHandler();
            }
        } catch (error) {}
    };
    //convert file to base64
    const toBase64 = (file: File): Promise<any | string> =>
        new Promise<any | string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    //Fn to handle websocket listener
    const handleWebsocket = async (masterFundPayLoad: IFundUploadPayload) => {
        if (ws !== undefined) {
            uploadFundData(masterFundPayLoad);
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);

                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;

                        if (result.status) {
                            loadingHandler();
                            handleShowConfirmation();
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;

                        setErrorMessage({
                            ...errorMessage,
                            errorCode: error.errorCode,
                            message: error.message,
                            title: `Import ${propsData && propsData.uploadType} request failed`,
                            testId: 'view-import-error-modal',
                        });
                        loadingHandler();
                        handleErrorHandler();
                    }
                }
            });
        }
    };

    //Fn to initiate websocket connection
    const initiateWebsocket = (): boolean => {
        if (ws !== undefined) {
            loadingHandler();
            ws.send('Ping');
            return true;
        } else {
            return false;
        }
    };
    /**
     * Fn to handle submit
     * @method onSubmitHandler
     */
    const onSubmitHandler = async () => {
        setIsSubmitting(true);
        setSubmitDisabled(true);
        const masterFundData: IUploadFundData[] = [];
        const NAVData: IUploadNAVData[] = [];
        const DistributionData: IUploadDistributionData[] = [];
        const base64File: any | string = await toBase64(propsData.file);
        const tab =
            propsData.uploadType === 'NAV' ? 'navperunit' : propsData.uploadType.replace(/\s+/g, '').toLowerCase();

        const masterFundPayLoad: IFundUploadPayload = {
            data: '',
            tab: tab,
            document: {
                fileName: propsData.file.name,
                file: base64File.split('base64,').pop(),
                fileSize: propsData.file.size,
                type: fileType,
            },
        };

        // covert column text to camelCase
        let cleanedCols: string[] = [];
        cleanedCols = convertToLowerCamelCase(columns);

        //build NAV, Masterfund or Distribution payload
        if (tab === 'navperunit') {
            rows.map((row) => {
                const navObj: IUploadNAVData = {
                    tomsFundAbbreviation: '',
                    fundName: '',
                    fundClass: '',
                    fundClassCurrency: '',
                    navPerUnit: {},
                };
                if (row[0].isMarked) {
                    cleanedCols.map((colName, colIndex) => {
                        switch (colName) {
                            case 'navPerUnit':
                                navObj.navPerUnit = { [row[5].cellValue]: row[colIndex].cellValue };
                                break;
                            case 'tomsFundAbbreviation':
                            case 'fundName':
                            case 'fundClass':
                            case 'fundClassCurrency':
                                navObj[colName] = row[colIndex].cellValue;
                                break;
                        }
                    });

                    NAVData.push(navObj);
                }
            });
            //check to make sure at least 1 record is marked before calling mutation
            if (NAVData.length !== 0) {
                masterFundPayLoad.data = JSON.stringify(NAVData);
                initiateWebsocket() ? handleWebsocket(masterFundPayLoad) : console.log('Unable to establlish WS');
            } else {
                setSubmitDisabled(false);
            }
        } else if (tab === 'masterfund') {
            //build fund data payload
            rows.map((row, rowIndex) => {
                const fundObj: IUploadFundData = {
                    fundCode: '',
                    tomsFundAbbreviation: '',
                    omniFundAbbreviation: '',
                    fundName: '',
                    fundStatus: '',
                    fundCategory: '',
                    fundProcessingGroup: '',
                    retailFundWholesaleFund: '',
                    closedEnd: '',
                    scFundType: '',
                    conventionalSyariah: '',
                    fundClass: '',
                    utmc: '',
                    trustee: '',
                    scProcessingGroup: '',
                    epfApproved: '',
                    epfStatus: '',
                    fundStartDate: '',
                    iopEndDate: '',
                    maturityDate: '',
                    commercialStartDate: '',
                    allow: '',
                    newSales: '',
                    topUpSales: '',
                    redemption: '',
                    redemptionCoolOff: '',
                    switchIn: '',
                    switchOut: '',
                    transfer: '',
                    incomeDistributionInstruction: '',
                    dividendTiming: '',
                    minDividendAmountPayout: '',
                    dailyIncomeDistribution: '',
                    fundBaseCurrency: '',
                    fundClassCurrency: '',
                    issuePrice: '',
                    annualManagementFee: '',
                    annualTrusteeFee: '',
                    minSalesFeeEpf: '',
                    minSalesFeeNonEpf: '',
                    maxSalesFeeEpf: '',
                    maxSalesFeeNonEpf: '',
                    aipAllowed: '',
                    minNewSalesAmtEpf: '',
                    maxNewSalesAmtEpf: '',
                    minTopupAmtEpf: '',
                    maxTopupAmtEpf: '',
                    minNewSalesAmtNonEpfIndividual: '',
                    maxNewSalesAmtNonEpfIndividual: '',
                    minTopUpAmtNonEpfIndividual: '',
                    maxTopUpAmtNonEpfIndividual: '',
                    noOfCoolOfDays: '',
                    exitFeesChargeEpfPercentage: '',
                    exitFeesChargeEpfAmount: '',
                    exitFeesChargeNonEpfPercentage: '',
                    exitFeesChargeNonEpfAmount: '',
                    redemptionType: '',
                    minRedemptionUnits: '',
                    switchingType: '',
                    minSwitchOutUnits: '',
                    switchingFee: '',
                    minTransferOutUnits: '',
                    minInitialTransferInUnits: '',
                    minTopupTransferInUnits: '',
                    transferFeePrsProvider: '',
                    minBalanceHoldingsUnits: '',
                    salesCutOffTime: '',
                    redemptionCutOffTime: '',
                    batchCutOffTimeSales: '',
                    batchCutOffTimeRedemption: '',
                    gstParameter: '',
                    riskCategory: '',
                    nonBusinessDayFromDate: '',
                    nonBusinessDayToDate: '',
                    differenceFundPrice: '',
                    salesSettlementDay: '',
                    redemptionSettleDate: '',
                    switchingSettleDate: '',
                    switchingGroupSettlementDay: '',
                    fundObjective: '',
                    dedicatedFund: '',
                    ampFee: '',
                    moneySightedFund: '',
                    felSharing: '',
                    defaultAssetAllocation: '',
                    lipperCode: '',
                    distributed: '',
                    feaTagging: '',
                };
                if (row[0].isMarked) {
                    // add fundCode and tomsFundAbbrreviation to marked rows

                    fundObj['fundCode'] = hiddenRows[rowIndex][0];
                    fundObj['tomsFundAbbreviation'] = hiddenRows[rowIndex][1];
                    cleanedCols.map((colName: string, colIndex) => {
                        switch (colName) {
                            case 'aipAllowed':
                            case 'allow':
                            case 'ampFee':
                            case 'annualManagementFee':
                            case 'annualTrusteeFee':
                            case 'batchCutOffTimeRedemption':
                            case 'batchCutOffTimeSales':
                            case 'closedEnd':
                            case 'commercialStartDate':
                            case 'conventionalSyariah':
                            case 'dailyIncomeDistribution':
                            case 'dedicatedFund':
                            case 'defaultAssetAllocation':
                            case 'differenceFundPrice':
                            case 'distributed':
                            case 'dividendTiming':
                            case 'epfApproved':
                            case 'epfStatus':
                            case 'exitFeesChargeEpfAmount':
                            case 'exitFeesChargeEpfPercentage':
                            case 'exitFeesChargeNonEpfAmount':
                            case 'exitFeesChargeNonEpfPercentage':
                            case 'feaTagging':
                            case 'felSharing':
                            case 'fundBaseCurrency':
                            case 'fundCategory':
                            case 'fundClass':
                            case 'fundClassCurrency':
                            case 'fundName':
                            case 'fundObjective':
                            case 'fundProcessingGroup':
                            case 'fundStartDate':
                            case 'fundStatus':
                            case 'gstParameter':
                            case 'incomeDistributionInstruction':
                            case 'iopEndDate':
                            case 'issuePrice':
                            case 'lipperCode':
                            case 'maturityDate':
                            case 'maxNewSalesAmtEpf':
                            case 'maxNewSalesAmtNonEpfIndividual':
                            case 'maxSalesFeeEpf':
                            case 'maxSalesFeeNonEpf':
                            case 'maxTopUpAmtNonEpfIndividual':
                            case 'maxTopupAmtEpf':
                            case 'minBalanceHoldingsUnits':
                            case 'minDividendAmountPayout':
                            case 'minInitialTransferInUnits':
                            case 'minNewSalesAmtEpf':
                            case 'minNewSalesAmtNonEpfIndividual':
                            case 'minRedemptionUnits':
                            case 'minSalesFeeEpf':
                            case 'minSalesFeeNonEpf':
                            case 'minSwitchOutUnits':
                            case 'minTopUpAmtNonEpfIndividual':
                            case 'minTopupAmtEpf':
                            case 'minTopupTransferInUnits':
                            case 'minTransferOutUnits':
                            case 'moneySightedFund':
                            case 'newSales':
                            case 'noOfCoolOfDays':
                            case 'nonBusinessDayFromDate':
                            case 'nonBusinessDayToDate':
                            case 'omniFundAbbreviation':
                            case 'redemption':
                            case 'redemptionCoolOff':
                            case 'redemptionCutOffTime':
                            case 'redemptionSettleDate':
                            case 'redemptionType':
                            case 'retailFundWholesaleFund':
                            case 'riskCategory':
                            case 'salesCutOffTime':
                            case 'salesSettlementDay':
                            case 'scFundType':
                            case 'scProcessingGroup':
                            case 'switchIn':
                            case 'switchOut':
                            case 'switchingFee':
                            case 'switchingGroupSettlementDay':
                            case 'switchingSettleDate':
                            case 'switchingType':
                            case 'topUpSales':
                            case 'transfer':
                            case 'transferFeePrsProvider':
                            case 'trustee':
                            case 'utmc':
                                fundObj[colName] = row[colIndex].cellValue;
                                break;
                        }
                        // fundObj[colName] = row[colIndex].cellValue;
                    });
                    masterFundData.push(fundObj);
                }
            });
            //check to make sure at least 1 record is marked before calling mutation
            if (masterFundData.length !== 0) {
                masterFundPayLoad.data = JSON.stringify(masterFundData);
                initiateWebsocket() ? handleWebsocket(masterFundPayLoad) : console.log('Unable to establlish WS');
            } else {
                setSubmitDisabled(false);
            }
        } else {
            //build distribution data payload
            rows.map((row) => {
                const distributionObj: IUploadDistributionData = {
                    tomsFundAbbreviation: '',
                    fundName: '',
                    fundClass: '',
                    fundClassCurrency: '',
                    distribution: { gross: {}, net: {}, unit: {} },
                };
                if (row[0].isMarked) {
                    cleanedCols.map((colName, colIndex) => {
                        switch (colName) {
                            case 'gross':
                                distributionObj.distribution = {
                                    ...distributionObj.distribution,
                                    gross: { [row[7].cellValue]: row[colIndex].cellValue },
                                };
                                break;
                            case 'net':
                                distributionObj.distribution = {
                                    ...distributionObj.distribution,
                                    net: { [row[7].cellValue]: row[colIndex].cellValue },
                                };
                                break;
                            case 'unit':
                                distributionObj.distribution = {
                                    ...distributionObj.distribution,
                                    unit: { [row[7].cellValue]: row[colIndex].cellValue },
                                };
                                break;
                            case 'tomsFundAbbreviation':
                            case 'fundName':
                            case 'fundClass':
                            case 'fundClassCurrency':
                                distributionObj[colName] = row[colIndex].cellValue;
                                break;
                        }
                    });

                    DistributionData.push(distributionObj);
                }
            }); //check to make sure at least 1 record is marked before calling mutation
            if (DistributionData.length !== 0) {
                masterFundPayLoad.data = JSON.stringify(DistributionData);
                initiateWebsocket() ? handleWebsocket(masterFundPayLoad) : console.log('Unable to establlish WS');
                // uploadFundData(masterFundPayLoad);
            } else {
                setSubmitDisabled(false);
            }
        }
    };
    //fread file using Sheetjs
    const readFile = (file: File) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            const rABS = !!fileReader.readAsBinaryString;

            fileReader.onload = () => {
                const wb = XLSX.read(fileReader.result, {
                    type: rABS ? 'binary' : 'array',
                    raw: true,
                    cellDates: true,
                    // dateNF: 'dd.mm.yyyy',
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: '' });

                resolve(data);
            };
            if (rABS) fileReader.readAsBinaryString(file);
            else fileReader.readAsArrayBuffer(file);
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((data: any) => {
            let tempCols: string[] = [];
            let tempRows: SheetRawData[][] = [];
            tempCols = data.shift(); //get first header row
            tempRows = data.slice(0); //get rest of the rows

            setIsProcessing(true);
            checkSheetData(tempCols, tempRows);
        });
    };
    //function to check if one or more rows are marked
    useEffect(() => {
        const areRowsMarked = rows.filter((row) => row[0].isMarked);
        const areRowsEdited = rows.filter((row) => row[0].isDelete || row[0].isMarked);
        areRowsMarked.length > 0 ? setSubmitDisabled(false) : setSubmitDisabled(true);

        areRowsEdited.length > 0 ? setRowsMarked(true) : setRowsMarked(false);
    }, [rows]);
    useEffect(() => {
        if (propsData) {
            checkPermissions();
            let _subTitle = '';
            switch (propsData.uploadType) {
                case 'NAV':
                    _subTitle =
                        'You can use spreadsheets to make changes to the funds NAV data, then upload your changes all at once.';
                    break;
                case 'distribution':
                    _subTitle =
                        'You can use spreadsheets to make changes to the funds distribution data, then upload your changes all at once.';
                    break;
                default:
                    _subTitle =
                        'You can use spreadsheets to make changes to the funds data, then upload your changes all at once.';
            }
            const fType = checkFileType(propsData.file.type);
            readFile(propsData.file);
            setSubTitle(_subTitle);
            setFileType(fType);
        }
    }, [propsData]);

    return (
        <Fragment>
            <PreviousPage title={`Import ${propsData && propsData.uploadType}`} />
            <StyledInner>
                <PageDescription>{subTitle}</PageDescription>
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <Fragment>
                        {isFileValid && (
                            <TableSheet
                                columns={columns}
                                rows={rows}
                                isRowMarked={rowsMarked}
                                handleMarkDelete={markRowDeleteHandler}
                                handleMarkOkay={markRowOkHandler}
                                handleMarkAll={markAllRowHandler}
                                handleUndoAll={undoAllHandler}
                                readyOnly={isSubmitting}
                                columnWidths={fixedCoLumnlWidth}
                            />
                        )}

                        <FlexedDiv>
                            <IcoMoon name="upload" size="1rem" />
                            <ReuploadBtn onClick={goBacktoUploads} id="import-reupload-btn">
                                Reupload CSV
                            </ReuploadBtn>
                        </FlexedDiv>
                    </Fragment>
                )}

                <ButtonDiv>
                    <CustomButton style={reasonsPageButtons} onClick={goBacktoUploadDashboard} id="import-cancel-btn">
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary
                        style={reasonsPageButtons}
                        onClick={onSubmitHandler}
                        disabled={submitDisabled || isSubmitting}
                        id="import-submit-btn"
                    >
                        {LABEL.submit}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={handleShowConfirmation}
                    title={isAutoAuth ? modalMessages.approved.heading : modalMessages.pending.heading}
                    primaryBtn={{
                        onClick: () => {
                            goBacktoUploadDashboard();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    testId="view-import-modal"
                    contentAlignment="center"
                    icon={isAutoAuth ? modalMessages.approved.icon : modalMessages.pending.icon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {isAutoAuth ? modalMessages.approved.subHeading : modalMessages.pending.subHeading}{' '}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader position="fixed" backgroundColor="#0000001a" /> : null}
        </Fragment>
    );
};

const StyledInner = styled.div`
    margin-left: 2.6rem;
`;

const SharedBtnStyles = css`
    border: 0px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 700;
    &:focus {
        outline: none;
    }
`;

const ReuploadBtn = styled.button`
    ${SharedBtnStyles}/* margin-top: 1.2rem; */
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;

export default ViewImport;
