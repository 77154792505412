import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { FlexedDiv } from '../../components/atoms/FlexDiv';
import { LABEL, TextDarkBlack } from '../../constants';

import { MultiSection } from '../../templates';
import { ITransactionsSummary } from './orderSummaryTypes';
import styled from 'styled-components';

interface ITransactionsSummaryProps {
    transactions: ITransactionsSummary;
    branch?: string;
    fromInbox?: boolean;
}

/**
 * Transaction summary tab in order summary page
 * @category Pages
 * @component
 * @namespace TransactionsSummary
 * @returns Profile tab in order summary page.
 */

const TransactionsSummary: React.FC<ITransactionsSummaryProps> = ({
    transactions,
    branch,
    fromInbox,
}: ITransactionsSummaryProps) => {
    const [transactionSections, setTransactionSections] = useState<Array<IProfileSection>>([]);
    useEffect(() => {
        const _tempSections: IProfileSection[] = [];
        const _expands: boolean[] = [];
        Object.keys(transactions).forEach((key) => {
            switch (key) {
                case 'transactionDetails':
                case 'accountSummary':
                case 'investmentSummary':
                case 'paymentSummary':
                    if (transactions[key] !== undefined) {
                        _tempSections.push(transactions[key]);
                    }
                    break;
            }
        });

        _tempSections.forEach(() => {
            _expands.push(true);
        });

        setTransactionSections(_tempSections);
    }, [transactions]);
    return (
        <Fragment>
            <FlexedDiv direction="column">
                {transactions.approval !== undefined && transactions.approval !== null && fromInbox === true ? (
                    <ApprovalBanner direction="column">
                        <Title>{`This request was ${transactions.approval.status.toLowerCase()} by ${
                            transactions.approval.name
                        }`}</Title>

                        <FlexedDiv
                            style={{
                                paddingTop: '0.5rem',
                                width: '100%',
                                wordBreak: 'break-all',
                                whiteSpace: 'break-spaces',
                            }}
                            justifyContent="space-between"
                        >
                            {transactions.approval.remarks !== undefined && transactions.approval.remarks !== null
                                ? `${LABEL.rejectedReason}: ${transactions.approval.remarks}`
                                : ''}
                        </FlexedDiv>
                    </ApprovalBanner>
                ) : null}
                {transactions.extensionRemarks !== undefined && transactions.extensionRemarks !== null ? (
                    <ExtensionsBanner direction="column">
                        <Title>{`${LABEL.dueDateExtension} (${moment(transactions.extensionRemarks?.date, 'x').format(
                            'DD/MM/YYYY',
                        )}) ${LABEL.rejected.toLowerCase()} ${LABEL.by} ${LABEL.hq}`}</Title>
                        <FlexedDiv
                            style={{ paddingTop: '0.5rem', width: '100%', wordBreak: 'break-all' }}
                            justifyContent="space-between"
                        >
                            {`${LABEL.remarks}: ${transactions.extensionRemarks?.remarks}`}
                        </FlexedDiv>
                    </ExtensionsBanner>
                ) : null}
                {transactions.remarks !== undefined && transactions.remarks !== null ? (
                    <RerouteRemarksBanner status={transactions.status.toLowerCase()} direction="column">
                        <Title>{`${
                            transactions.status === LABEL.rejected
                                ? LABEL.orderSummaryRejectRemarks
                                : LABEL.orderSummaryRerouteRemarks
                        } ${branch}.`}</Title>
                        <FlexedDiv
                            style={{ paddingTop: '0.5rem', width: '100%' }}
                            direction="column"
                            justifyContent="space-between"
                        >
                            {transactions.remarks && transactions.remarks !== undefined
                                ? transactions.remarks.map((item, index) => {
                                      return (
                                          <Fragment
                                              key={`${
                                                  transactions.status === LABEL.rejected
                                                      ? LABEL.rejected
                                                      : LABEL.rerouted
                                              }reason_${index}`}
                                          >
                                              <FlexedDiv direction="column">
                                                  <Reason>{`${
                                                      transactions.status === LABEL.rejected
                                                          ? LABEL.rejectedReason
                                                          : LABEL.reroutedReason
                                                  }:  ${item.title}`}</Reason>
                                                  <UL>
                                                      {item.content.map((ele, index) => (
                                                          <LI key={`list_${index}`}>{ele}</LI>
                                                      ))}
                                                  </UL>
                                              </FlexedDiv>
                                          </Fragment>
                                      );
                                  })
                                : null}
                        </FlexedDiv>
                    </RerouteRemarksBanner>
                ) : null}
            </FlexedDiv>
            {transactionSections.length !== 0 ? (
                transactionSections.map((elm, index) => {
                    return (
                        <BlockContainer
                            key={`container${index + 1}`}
                            id={`${elm.title?.toLocaleLowerCase()}-container`}
                        >
                            <BlockContainerHeader>
                                <TextDarkBlack weight="700" size="18px">
                                    {elm.title}
                                </TextDarkBlack>
                            </BlockContainerHeader>
                            <BlockContainerContent>
                                <MultiSection sections={elm.sections} noSectionImage="empty-payment-info" />
                            </BlockContainerContent>
                        </BlockContainer>
                    );
                })
            ) : (
                <div>Data not available</div>
            )}
        </Fragment>
    );
};

type IRemarksStatus = {
    status: string;
};

const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;

const RerouteRemarksBanner = styled((props) => <FlexedDiv {...props} />)<IRemarksStatus>`
    background: ${(props: IRemarksStatus) =>
        props.status === 'br - rerouted' || props.status === 'hq - rerouted' || props.status === 'pending due date'
            ? '#faf6ee'
            : props.status === 'rejected'
            ? '#faf2f1'
            : '#f5f0fa'};
    border: ${(props: IRemarksStatus) =>
        props.status === 'br - rerouted' || props.status === 'hq - rerouted' || props.status === 'pending due date'
            ? '1px solid #e89700'
            : props.status === 'rejected'
            ? '1px solid #e84c3d'
            : '1px solid #e82c97'};
    box-sizing: border-box;
    border-radius: 16px;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
`;
const ExtensionsBanner = styled((props) => <FlexedDiv {...props} />)`
    background: rgba(232, 76, 61, 0.08);
    border: 1px solid #e84c3d;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const Reason = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #333333;
`;
const UL = styled.ul`
    padding-left: 1rem;
`;
const LI = styled.li`
    font-size: 0.75rem;
    line-height: 1rem;
    color: #333333;
    font-weight: 400;
    padding-top: 0.25rem;
    word-break: break-all;
    white-space: break-spaces;
`;

export default TransactionsSummary;
