import gql from 'graphql-tag';

/**
 * @eddCaseHistoryQuery @query - EDD Case History API
 * @description: Amla able to see the case history of client
 */
export const eddCaseHistoryQuery = /* GraphQL */ gql`
    query eddCaseHistory($input: CaseHistoryInput) {
        eddCaseHistory(input: $input) {
            data {
                result {
                    principal {
                        caseId
                        caseNo
                        status
                        name
                        clientIdNumber
                        eddTrigger {
                            title
                            content
                        }
                        eddReason {
                            title
                            content
                        }
                        createdOn
                        closedOn
                        remarks {
                            title
                            content
                        }
                    }
                    joint {
                        caseId
                        caseNo
                        status
                        name
                        clientIdNumber
                        eddTrigger {
                            title
                            content
                        }
                        eddReason {
                            title
                            content
                        }
                        createdOn
                        closedOn
                        remarks {
                            title
                            content
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
