/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { PublicRoute } from './helpers';
import { RouteLoader } from './components';
import { AuthProvider } from './context/AuthContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { BranchRoute, CallBackRedirect, HqRoute } from './auth';

import * as ROUTES from './routes';
import { ErrorHandlingProvider } from './context/ErrorHandling/ErrorHandlingProvider';
import Error404 from './pages/ExceptionHandling/Error404';

const WebsocketRoutes = lazy<any>(() => import('./auth/WebsocketContainer'));

// SignIn Route
const Signin = lazy<any>(() => import('./pages/Signin'));

// Routes/Pages
function App(): JSX.Element {
    const history = useHistory();
    const currentPathname = useRef<string>('');
    currentPathname.current = window.location.pathname;

    useEffect(() => {
        return history.listen((location, action) => {
            // currentPathname.current = window.location.href
            if (action === 'POP') {
                if (
                    currentPathname.current !== location.pathname &&
                    location.pathname !== '/' &&
                    location.pathname !== '/home/signin'
                ) {
                    currentPathname.current = location.pathname;
                } else {
                    history.push({
                        pathname: currentPathname.current,
                        state: location.state,
                    });
                }
            } else {
                currentPathname.current = location.pathname;
            }
        });
    }, [history]);
    return (
        <Suspense fallback={<RouteLoader />}>
            <WebSocketProvider>
                <ErrorHandlingProvider>
                    <AuthProvider>
                        <WebsocketRoutes>
                            <Switch>
                                <BranchRoute path="/branch" />
                                <HqRoute path={'/hq'} />
                                <PublicRoute path="/sso" component={Signin} />
                                <Route exact path={ROUTES.signIn} component={Signin} />
                                <Route exact path={ROUTES.signInHome} component={Signin} />
                                <Route exact path={ROUTES.callBackRedirect} component={CallBackRedirect} />
                                <Route path="*" component={Error404} />
                            </Switch>
                        </WebsocketRoutes>
                    </AuthProvider>
                </ErrorHandlingProvider>
            </WebSocketProvider>
        </Suspense>
    );
}

export default App;
