import gql from 'graphql-tag';
/**
 * @createCaseMutation @Mutation - Create Case
 * @description: mutation to create edd case
 */
export const createCaseMutation = gql`
    mutation createCase($input: NewCaseInput) {
        createCase(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
