import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Banner, Collapsible } from '../../../../../components';
import { LabeledTitle } from '../../../../../components/molecules/LabeledTitle';
import { EDD, LABEL, SYSTEM_ADMIN, TextNavyBlue } from '../../../../../constants';
import { IcoMoon } from '../../../../../icons';
import { MultiSection } from '../../../../../templates';
import { getInitialsByFirstTwo } from '../../../../../utils';
import { ContentContainer } from '../../../../SystemAdmin/AdviserProfile/Profile';
import { timestampToDate } from '../../../../../utils/timestampToDate';

import styled from 'styled-components';
import * as ROUTES from '../../../../../routes';
import EDDContext from '../../../../../context/EDDContext/EDDContext';

export const CaseSummary = (): JSX.Element => {
    const { caseDetails, amlaRemarks } = useContext(EDDContext);

    const [expands, setExpands] = useState([true, false, false]);

    const history = useHistory();
    const updateExpands = (index: number) => {
        const exp = [...expands];
        if (index === -1) {
            if (!exp[1]) {
                setExpands([true, true, true]);
            } else {
                setExpands([false, false, false]);
            }

            return;
        }
        exp[index] = !exp[index];
        setExpands(exp);
    };

    const expandCheck = !expands[0] || !expands[1] || !expands[2];
    return (
        <Fragment>
            <DetailsArea>
                <FlexRow style={{ marginBottom: '32px' }}>
                    <Avatar
                        customStyles={{
                            fontSize: '40px',
                            marginRight: '40px',
                        }}
                        backgroundColor={'#A85846'}
                        size={120}
                    >
                        {getInitialsByFirstTwo(caseDetails.name)}
                    </Avatar>
                    <FlexCol>
                        <LabeledTitle
                            label="Name"
                            title={caseDetails.name}
                            style={{ marginBottom: '16px', fontWeight: 'bold' }}
                        />
                        <FlexRow>
                            <LabeledTitle
                                label={caseDetails.idType}
                                title={caseDetails.clientIdNum}
                                style={{ fontWeight: 'bold' }}
                            />
                            <ProfileLink href={caseDetails.idFile} rel="noreferrer" target="_blank">
                                <IcoMoon name={'profile'} size="0.8rem" id="dataIconBtn" />
                            </ProfileLink>
                        </FlexRow>
                    </FlexCol>
                    <FlexRow
                        style={{ marginLeft: 'auto', marginTop: 'auto', cursor: 'pointer', userSelect: 'none' }}
                        onClick={() => updateExpands(-1)}
                    >
                        <TextNavyBlue size="12px" weight="700" style={{ marginRight: '4px' }}>
                            {expandCheck ? LABEL.expandAll : LABEL.collapseAll}
                        </TextNavyBlue>
                        <IcoMoon name={expandCheck ? LABEL.expand : LABEL.collapse} size="18px" />
                    </FlexRow>
                </FlexRow>
                <Collapsible
                    title={EDD.LABEL_CASE_DETAILS}
                    isCheckable={false}
                    expanded={expands[0]}
                    noMargin={true}
                    noXMargin={true}
                    showBoxShadow={true}
                    content={
                        <ContentContainer>
                            <MultiSection
                                sections={[
                                    {
                                        data: [
                                            {
                                                label: EDD.LABEL_CASE_ID,
                                                data: caseDetails.caseSummary.caseDetails.caseNo || '-',
                                            },
                                            {
                                                label: EDD.LABEL_CREATED_DATE,
                                                data:
                                                    timestampToDate(caseDetails.caseSummary.caseDetails.createdOn) ||
                                                    '-',
                                            },
                                            {
                                                label: EDD.LABEL_CLOSED_DATE,
                                                data:
                                                    timestampToDate(caseDetails.caseSummary.caseDetails.closedDate) ||
                                                    '-',
                                            },
                                            {
                                                label: EDD.LABEL_EDD_TRIGGER,
                                                data: caseDetails.caseSummary.caseDetails.eddTrigger[0].title || '-',
                                                subText: caseDetails.caseSummary.caseDetails.eddTrigger[0].content[0],
                                            },
                                            {
                                                label: EDD.LABEL_EDD_REASON,
                                                data: caseDetails.caseSummary.caseDetails.eddReason[0].title || '-',
                                                subText: caseDetails.caseSummary.caseDetails.eddReason[0].content[0],
                                            },
                                            {
                                                label: EDD.LABEL_SATISFACTORY_STATUS,
                                                data: caseDetails.caseSummary.caseDetails.satisfactoryStatus || '-',
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </ContentContainer>
                    }
                    handleExpand={() => updateExpands(0)}
                />
                <SpaceDivider />
                <Collapsible
                    title={SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_PERSONAL_DETAILS}
                    isCheckable={false}
                    expanded={expands[1]}
                    noMargin={true}
                    noXMargin={true}
                    showBoxShadow={true}
                    content={
                        <ContentContainer>
                            <MultiSection
                                sections={[
                                    {
                                        data: [
                                            {
                                                label: EDD.LABEL_Nationality,
                                                data: caseDetails.caseSummary.personalDetails.nationality || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Email,
                                                data: caseDetails.caseSummary.personalDetails.email || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Phone,
                                                data: caseDetails.caseSummary.personalDetails.phone || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Occupation,
                                                data: caseDetails.caseSummary.personalDetails.occupation || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Employer_Name,
                                                data: caseDetails.caseSummary.personalDetails.employerName || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Annual_Income,
                                                data: caseDetails.caseSummary.personalDetails.grossIncome || '-',
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </ContentContainer>
                    }
                    handleExpand={() => updateExpands(1)}
                />
                <SpaceDivider />
                <Collapsible
                    title={EDD.LABEL_ACCOUNT_SUMMARY}
                    isCheckable={false}
                    expanded={expands[2]}
                    noMargin={true}
                    noXMargin={true}
                    showBoxShadow={true}
                    content={
                        <ContentContainer>
                            <MultiSection
                                sections={[
                                    {
                                        data: [
                                            {
                                                label: EDD.LABEL_Account_Number,
                                                data: caseDetails.caseSummary.accountSummary.accountNo || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Account_Created_On,
                                                data:
                                                    timestampToDate(
                                                        caseDetails.caseSummary.accountSummary.accountCreatedOn,
                                                    ) || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Type_Investor,
                                                data: caseDetails.caseSummary.accountSummary.investorType || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Account_Holder,
                                                data: caseDetails.caseSummary.accountSummary.accountHolder || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Assigned,
                                                data: caseDetails.assignedAdviser.personalDetails.agentName || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Onboarding_Receipts,
                                                data: caseDetails.caseSummary.accountSummary.receipt.name || '-',
                                                dataIcon: 'file',
                                                onClickData: caseDetails.caseSummary.accountSummary.receipt.url,
                                            },
                                            {
                                                label: EDD.LABEL_Product_Type,
                                                data: caseDetails.caseSummary.accountSummary.productType || '-',
                                            },
                                            {
                                                label: EDD.LABEL_Funding_Option,
                                                data: caseDetails.caseSummary.accountSummary.fundOption || '-',
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </ContentContainer>
                    }
                    handleExpand={() => updateExpands(2)}
                />
            </DetailsArea>
            {caseDetails.headings && caseDetails.headings.length > 0 && caseDetails.response ? (
                <FlexCol style={{ margin: '0 -2rem' }}>
                    <Banner
                        disabled={amlaRemarks.filter((n) => n && n.remark.trim()).length > 0 ? false : true}
                        toggle={true}
                        title={EDD.LABEL_UNSATISFACTORY_BANNER_TITLE}
                        description={`Add minimum one remark for ${caseDetails.name} (${caseDetails.clientIdNum}) to proceed`}
                        handleClose={() => console.log('handle close')}
                        maxWidth="1152"
                        primaryButtonObject={{
                            handlePrimaryBtn: () => history.push(ROUTES.rerouteEddCase, { amlaRemarks: amlaRemarks }),
                            label: EDD.BUTTON_CONTINUE,
                        }}
                        secondaryButtonObject={{
                            handleSecondaryBtn: () => history.push(ROUTES.amlaEDDManagement),
                            label: EDD.BUTTON_CANCEL,
                        }}
                    />
                </FlexCol>
            ) : null}
        </Fragment>
    );
};
const FlexRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const DetailsArea = styled.div`
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 72px;
    flex-direction: column;
`;
const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;
const ProfileLink = styled.a`
    display: inline-block;
    margin-left: 10px;
    fill: #002043;
    &:hover {
        fill: #1890ff;
    }
`;
