import gql from 'graphql-tag';

export const pendingSubmissionExportList = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    pendingsubmissiondashboard {
                        grouping {
                            type
                            groupingData {
                                principalName
                                principalNumber
                                orderNo
                                jointName
                                jointNumber
                            }
                            transactionData {
                                data {
                                    orderNo
                                    clientName
                                    clientIdNum
                                    accountType
                                    createdOn
                                    lastUpdated
                                    transactionType
                                    fundType
                                    fundAbbr
                                    paymentMethod
                                    # submittedOn
                                    totalInvestment {
                                        fundCurrency
                                        investmentAmount
                                    }
                                    agentName
                                    agentCode
                                    status
                                    # fundCategory
                                    # targetDate
                                    # branchName
                                }
                            }
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
