/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { SideNavLogo } from '../../../assets';
import { LABEL, TextNavyBlue, TextDarkGrey5 } from '../../../constants';
import { getInitialsByFirstTwo, userColorPicker } from '../../../utils';
import { NavMenu } from './NavMenu';
import { IPermissionObject } from '../../../utils/permissionTypes';
import { Avatar } from '../../atoms';
import { LogOutModal } from '../LogoutModal';

import moment from 'moment';
import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';
import { IcoMoon } from '../../../icons/Icomoon';

interface SideNavProps {
    navData: ISideNavMenuItemSection[];
}

export const NavBar: React.FC<SideNavProps> = ({ navData }: SideNavProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission: IPermissionObject = JSON.parse(userLoginContext.permission);
    const location = useLocation();
    const [hideCounter, setHideCounter] = useState<boolean>(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [avatarColor, setAvatarColor] = useState<string>('');

    // Fn to initialize permissions
    const initPermission = (permission: IPermissionObject) => {
        // parsedPermission = JSON.parse(permission);
        const tempColor = Object.keys(permission.hq.permission).length === 0 ? 'branch' : 'hq';
        const _color = userColorPicker(tempColor);
        setAvatarColor(_color);
    };

    const handleLogout = () => {
        setShowLogoutModal(true);
    };
    interface ErrorFallbackProps {
        error: string | any;
    }
    function ErrorFallback({ error }: ErrorFallbackProps) {
        return (
            <div role="alert">
                <p>An Error/Something Occurred during runtime that caused the app to fail load successfully: </p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
            </div>
        );
    }
    useEffect(() => {
        initPermission(parsedPermission);
    }, []);
    return (
        <Fragment>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Sidebar id="sidebar">
                    <SideBarTop id="sidebar-top">
                        <SideBarLogo id="side-logo">
                            <SideNavLogo />
                        </SideBarLogo>

                        <SideProfile id="side-profile">
                            <Avatar
                                backgroundColor={avatarColor}
                                testId="side-profile-avatar"
                                size={48}
                                customStyles={{ marginRight: '0.75rem' }}
                            >
                                {getInitialsByFirstTwo(
                                    userLoginContext.username !== null ? userLoginContext.username : '',
                                )}
                            </Avatar>
                            <SideBarProfileInfo id="side-profile-info">
                                <TextNavyBlue weight="700" id="side-profile-name">
                                    {userLoginContext.username}
                                </TextNavyBlue>
                                <ProfileRole id="side-profile-role">
                                    {userLoginContext.department !== 'undefined' ? userLoginContext.department : ''}
                                </ProfileRole>
                                <TextNavyBlue size="12px" id="side-profile-username">
                                    {userLoginContext.username}
                                </TextNavyBlue>
                            </SideBarProfileInfo>
                        </SideProfile>

                        {/* <Divider /> */}
                        <SideLoginInfo id="side-logininfo">
                            <TextDarkGrey5 size="12px" id="side-logininfo-branch">
                                {userLoginContext.branchName !== 'undefined' ? userLoginContext.branchName : ''}
                            </TextDarkGrey5>
                            <TextDarkGrey5 size="12px" id="side-logininfo-date">
                                {`${moment().format('ddd')}, ${moment().format('DD MMM YYYY')}`}
                            </TextDarkGrey5>
                        </SideLoginInfo>
                        {/* <Divider style={{ marginBottom: '0' }} /> */}
                        <NavMenu
                            handleLogout={handleLogout}
                            navData={navData}
                            path={location.pathname}
                            hideCounter={hideCounter}
                            setHideCounter={setHideCounter}
                            parsedPermission={parsedPermission}
                        />
                    </SideBarTop>
                    <div id="sidebar-bottom">
                        <a
                            style={{ color: 'inherit', display: 'block' }}
                            onClick={handleLogout}
                            // isOnProp={showLogoutModal}
                        >
                            <MenuItemWrapper style={{ cursor: 'pointer' }}>
                                <IcoMoon name="log-out-new" size="20px" />
                                {/* color={showLogoutModal ? '#fff' : 'inherit'} */}
                                <TextNavyBlue size="14px" weight="700" style={{ marginLeft: '10px' }}>
                                    Logout
                                </TextNavyBlue>
                                {/* color: showLogoutModal ? '#fff' : 'inherit'  */}
                            </MenuItemWrapper>
                        </a>
                        <SideBarBottom id="sidebar-bottom-copyright-container">
                            <TextDarkGrey5 size="12px" id="side-logininfo-date">
                                {LABEL.footerText}
                            </TextDarkGrey5>
                        </SideBarBottom>
                    </div>
                </Sidebar>
                <LogOutModal toggleModal={showLogoutModal} setToggleModal={setShowLogoutModal} />
            </ErrorBoundary>
        </Fragment>
    );
};

export default NavBar;

const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 24px 24px 0;
    box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    min-width: 224px;
    max-width: 15.556vw;
    width: 15.556vw;
    position: fixed;
    height: 100%;
    margin-right: 1.5rem;
    overflow: auto;
`;
const SideProfile = styled.div`
    display: flex;
    padding: 1.5rem;
    border-bottom: 1px solid #ececec;
`;
const SideBarLogo = styled.div`
    padding: 1.5rem;
    border-bottom: 1px solid #ececec;
`;
const SideBarProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    word-break: break-all;
`;
const SideLoginInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: -0.333333px;
`;

const ProfileRole = styled.span`
    letter-spacing: -0.388889px;
    font-size: 0.875rem;
    line-height: 1rem;
`;

const SideBarTop = styled.div``;
const SideBarBottom = styled.div`
    border-top: 1px solid #f4f4f4;
    padding: 1rem;
    margin-bottom: 1.5rem;
`;
const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    height: 48px;
    line-height: 48px;
`;
// const ActiveLink = styled.a<IOnPropStyles>`
//     background: ${(props: IOnPropStyles) => (props.isOnProp ? ' rgb(198, 18, 48)' : 'inherit')};
//     color: ${(props: IOnPropStyles) => (props.isOnProp ? ' #FFFFFF' : 'inherit')};
// `;
