/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { dummyTransactionsSummary, LABEL } from '../../../../constants';
import { handleOrderSummary } from '../../../../utils';
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { IOrderSummaryType, IOrderSummaryProps } from '../../../OrderDetails/orderSummaryTypes';
import { initialProfile } from '../../../AccountDetails/Dummy';
import TransactionsSummary from '../../../OrderDetails/TransactionSummary';
import { AccountDetails } from '../../..';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { ComponentLoader, Tabs } from '../../../../components';
import { useComponentLoader } from '../../../../customHooks';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

const OrderSummaryActivity: React.FC = () => {
    const location = useLocation();
    const locationState: any = location.state;
    const id = locationState.id;

    const [orderSummary, setOrderSummary] = useState<IOrderSummaryType>();
    const [summaryObjects, setSummaryObject] = useState<IOrderSummaryProps>({
        profile: initialProfile,
        transactionSummary: dummyTransactionsSummary,
    });
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const fetchOrderSummary = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );
            if (response.data.getLogData.error !== null) throw response.data.getLogData.error;
            const result = response.data.getLogData.data.result;

            if (result.data.data === null) {
                setErrorMessage({
                    ...errorMessage,
                    message: 'Data is null',
                    errorCode: '',
                    title: 'Cannot Fetch Transaction Data',
                    testId: 'activitylogs-order-error-modal',
                });
                handleErrorHandler();
            } else {
                const allData = JSON.parse(result.data.data);
                setOrderSummary(allData.orderSummaryData);
                setSummaryObject(handleOrderSummary(allData.orderSummaryData));
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Transaction Data',
                testId: 'activitylogs-order-error-modal',
            });
            handleErrorHandler();
        }
    };

    const tabs = [
        <TransactionsSummary
            branch={orderSummary?.transactionDetails.kibProcessingBranch}
            transactions={summaryObjects.transactionSummary}
            key={1}
        />,
        <AccountDetails profile={summaryObjects.profile} key={2} />,
    ];

    useEffect(() => {
        fetchOrderSummary();
        loadingHandler();
    }, []);
    return (
        <Fragment>
            <Tabs
                tabTitles={[LABEL.transaction, LABEL.profile]}
                title={LABEL.orderSummary}
                tabs={tabs}
                suffixHelper={orderSummary?.status}
            />
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};
export default OrderSummaryActivity;
