/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, createContext, SetStateAction, ReactText } from 'react';
import { Moment } from 'moment';
import { EddReasonProps, IEddTriggerProps } from '../../pages/DashboardAmla/DashboardEDD/eddInitialData';
import { IEddModuleDropDownProps } from './EDDProvider';
import { IClientList, IClientAccounts } from '../../pages/DashboardAmla/DashboardEDD/dashboardEDDTypes';
import { IEddCaseDetailsProps, IFilterEDDInvestorProps, IPill } from './EddTypes';
import { ICheckBoxData } from '../../pages/DashboardAmla/DashboardEDD/EddTypes';
import { IMultiSelectLabel } from '../../components';
import { dateFilterBranch } from '../../pages/DashboardBranch/dashboardBranchTypes';

export interface EDDContextProps {
    page: number;
    pageMax: number;
    resultLimit: number;
    tabsCount: number[];
    setTabsCount: Dispatch<SetStateAction<number[]>>;
    setPage: Dispatch<SetStateAction<number>>;
    setPageMax: Dispatch<SetStateAction<number>>;
    pagesDropDown: number[];
    setPagesDropDown: Dispatch<SetStateAction<number[]>>;
    setResultLimit: Dispatch<SetStateAction<number>>;
    totalResultCount: number;
    setTotalResultCount: Dispatch<SetStateAction<number>>;
    investorsResultLimit: number;
    setInvestorsResultLimit: Dispatch<SetStateAction<number>>;
    investorsPage: number;
    setInvestorsPage: Dispatch<SetStateAction<number>>;
    investorsPages: number;
    setInvestorsPages: Dispatch<SetStateAction<number>>;
    investorsPageMax: number;
    setInvestorsPageMax: Dispatch<SetStateAction<number>>;
    investorsTotalResultCount: number;
    setInvestorsTotalResultCount: Dispatch<SetStateAction<number>>;
    reasonValue: string | undefined;
    setReasonValue: Dispatch<SetStateAction<string | undefined>>;
    triggerValue: string | undefined;
    setTriggerValue: Dispatch<SetStateAction<string | undefined>>;
    targetDate: Moment | null;
    setTargetDate: Dispatch<SetStateAction<Moment | null>>;
    otherText: string;
    setOtherText: Dispatch<SetStateAction<string>>;
    eddCaseStatusGroup: IMultiSelectLabel[];
    searchInput: ISearchInput;
    setSearchInput: React.Dispatch<React.SetStateAction<ISearchInput>>;
    getDropdownData: (tab?: string) => Promise<void>;
    getEddDashboardNew: () => Promise<void>;
    setEddReason: Dispatch<SetStateAction<EddReasonProps>>;
    eddReason: EddReasonProps;
    setEddTrigger: Dispatch<SetStateAction<IEddTriggerProps>>;
    eddTrigger: IEddTriggerProps;
    moduleList: IEddModuleDropDownProps[];
    setModuleList: Dispatch<SetStateAction<IEddModuleDropDownProps[]>>;
    getEddModuleList: (item: { reasonId: string }) => Promise<void>;
    runEddModule: (value: string) => Promise<void>;
    getEDDClientList: () => Promise<void>;
    // getEDDClientAccount: () => Promise<void>;
    setClientsList: React.Dispatch<React.SetStateAction<IClientList[]>>;
    clientsList: IClientList[];
    newCases: ITableData[];
    clientsAccounts: IClientAccounts[];
    setClientsAccounts: React.Dispatch<React.SetStateAction<IClientAccounts[]>>;
    reroutedCases: ITableData[];
    closedCases: ITableData[];
    setReroutedCases: React.Dispatch<React.SetStateAction<ITableData[]>>;
    setClosedCases: React.Dispatch<React.SetStateAction<ITableData[]>>;
    setSelectedQuestion: Dispatch<SetStateAction<any[]>>;
    selectedQuestion: any[];
    setClientUtaId: Dispatch<SetStateAction<string>>;
    clientUtaId: string;
    eddTriggerJ: IEddTriggerProps;
    setEddTriggerJ: Dispatch<SetStateAction<IEddTriggerProps>>;
    eddReasonJ: EddReasonProps;
    setEddReasonJ: Dispatch<SetStateAction<EddReasonProps>>;
    targetDateJ: Moment | null | undefined;
    setTargetDateJ: Dispatch<SetStateAction<Moment | null | undefined>>;
    reasonValueJ: string | undefined;
    setReasonValueJ: Dispatch<SetStateAction<string | undefined>>;
    triggerValueJ: string | undefined;
    setTriggerValueJ: Dispatch<SetStateAction<string | undefined>>;
    otherTextJ: string;
    setOtherTextJ: Dispatch<SetStateAction<string>>;
    moduleName: string;
    setModuleName: Dispatch<SetStateAction<string>>;
    getCaseDetails: (responseId: number | null) => Promise<void>;
    caseDetails: IEddCaseDetailsProps;
    setCaseDetails: Dispatch<SetStateAction<IEddCaseDetailsProps>>;
    responseId: number | null;
    setResponseId: Dispatch<SetStateAction<number | null>>;
    updateCaseStatus: (action: string) => Promise<void>;
    unsatisfactoryRating: ICheckBoxData[];
    setUnsatisfactoryRating: Dispatch<SetStateAction<ICheckBoxData[]>>;
    amlaRemarks: any[];
    setAmlaRemarks: Dispatch<SetStateAction<any[]>>;
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setSearchInvestorInput: React.Dispatch<React.SetStateAction<ISearchInput>>;
    searchInvestorInput: ISearchInput;
    getClientAccounts: () => Promise<void>;
    setPills: Dispatch<SetStateAction<IPill[]>>;
    pills: IPill[];
    clientIdNum: string;
    setClientIdNum: Dispatch<SetStateAction<string>>;
    clientAccountSearchInput: IFilterEDDInvestorProps;
    setClientAccountSearchInput: Dispatch<SetStateAction<IFilterEDDInvestorProps>>;
    sortClientList: IFilterEDDInvestorProps;
    setSortClientList: Dispatch<SetStateAction<IFilterEDDInvestorProps>>;
    loading: boolean;
    loadingHandler: () => void;
    getEddDashboardRerouted: () => Promise<void>;
    getEddDashboardHistory: () => Promise<void>;
    sortInput: ISort_FilterInput;
    setSortInput: Dispatch<SetStateAction<ISort_FilterInput>>;
    tempFilters: ISort_FilterInput;
    filterInput: ISort_FilterInput;
    setDateFilter: Dispatch<SetStateAction<dateFilterBranch>>;
    dateFilter: dateFilterBranch;
    setTempFilters: Dispatch<SetStateAction<ISort_FilterInput>>;
    setFilterInput: Dispatch<SetStateAction<ISort_FilterInput>>;
    statusFilter: ReactText[];
    setStatusFilter: Dispatch<SetStateAction<ReactText[]>>;
    tempTargetDate: [moment.Moment | null, moment.Moment | null];
    setTempTarget: Dispatch<SetStateAction<[moment.Moment | null, moment.Moment | null]>>;
    disableResultLimit: boolean;
    isSelected: boolean[];
    setIsSelected: Dispatch<SetStateAction<boolean[]>>;
}

const EDDContext = createContext({} as EDDContextProps);

export default EDDContext;
