import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LABEL } from '../../../constants';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import { dueDateExtensionMutation } from '../../../_graphql/mutations/hq/dueDateExtension/dueDateExtension';
import { ReroutedProps } from './Rerouted';
import * as ROUTES from '../../../routes';
import { IDueDateTab } from '../../../utils/permissionTypes';
import { useDashboardTransactionReroutedTableColumn } from './useReroutedTableColumn';
import { getGroupingFromData, getGroupStyling } from '../../../utils';
import { TableGroupingTemplates } from '../../../components';
import { getGroupedTrx } from '../decoupleGroupedTrx';

interface IRerouteHandler {
    columnsRerouted: ITableColumn[];
    dueDateTab: IDueDateTab | undefined;
    handleApproveTargetDate: (item: IColumnItemAccordion) => void;
    handleExtendTargetDate: (item: IColumnItemAccordion) => void;
    handleRejectTargetDate: (item: IColumnItemAccordion) => void;
    handleViewDetails: (item: IColumnItemAccordion) => void;
    isFilterApplied: boolean | undefined;
    isSearchResult: boolean | undefined;
    modalSubtitle: string;
    modalTitle: string;
    reroutedNonGrouped: React.MutableRefObject<ITableData[]>;
    reroutedGrouped: React.MutableRefObject<(IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]>;
    searchInput: string;
    setTargetDate: React.Dispatch<React.SetStateAction<number>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const useReroutedHandler = ({
    filters,
    rerouteTab,
    searchInput,
    setErrorHandler,
    setErrorMessage,
    setFilters,
    setSubmittedOnDropdown,
    submittedOnDropdown,
    dueDateTab,
    idTokenHeader,
    isFilterApplied,
    isSearchResult,
}: ReroutedProps): IRerouteHandler => {
    const { rerouted, orderTypePills, reroutedCr } = useContext(DashboardBranchContext);
    const reroutedTableData = useRef<(IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [targetDate, setTargetDate] = useState<number>(0);
    const history = useHistory();
    const handleExtendTargetDate = (item: IColumnItemAccordion) => {
        let data = {};
        switch (history.location.pathname) {
            case '/hq/dashboard/view-branch':
                data = { item: item.rawData, hq: true };
                history.push(`${history.location.pathname}/${ROUTES.extendTargetDate}`, data);
                break;
            default:
                data = { item: item.rawData, hq: false };
                history.push(`${history.location.pathname}/${ROUTES.extendTargetDate}`, data);
                break;
        }
    };

    const handleApproveTargetDate = async (item: IColumnItemAccordion) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(dueDateExtensionMutation, {
                    input: {
                        requestId: item.rawData.requestId,
                        action: 'Approve',
                        targetDate: item.rawData.extendTargetDate,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.targetDateResponse.data !== null) {
                setShowModal(true);
            } else throw response.data.targetDateResponse.error;
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorHandler();
            setErrorMessage({
                title: 'Cannot Approve Due Date Extension',
                message: _error.message,
                errorCode: _error.errorCode,
                testId: 'branch-error-modal',
            });
        }
    };

    const handleRejectTargetDate = (item: IColumnItemAccordion) => {
        const data = { item: item.rawData };
        history.push(`${history.location.pathname}/${ROUTES.rejectTargetDate}`, data);
    };

    const handleViewDetails = (item: IColumnItemAccordion) => {
        let data = {};
        if (item.rawData.status === 'Pending Due Date') {
            const itemData = { ...item.rawData, targetDate: item.rawData.extendTargetDate };
            if (history.location.pathname === ROUTES.viewBranch) {
                data = { item: itemData, hq: true, isDisabled: true };
                history.push(`${history.location.pathname}/${ROUTES.extendTargetDateRequest}`, data);
            } else {
                data = { item: itemData, hq: false, isDisabled: true };
                history.push(`${history.location.pathname}/${ROUTES.extendTargetDateRequest}`, data);
            }
        } else {
            const _orderType = orderTypePills.tags.find((item) => item.active === true);
            data = {
                transactionRef: item.rawData.transactionRef,
                tab: 'reroute',
                endpoint: 'branchDashboard',
                orderNumber: item.rawData.orderNo,
                url: history.location.pathname,
            };
            _orderType?.title.toLowerCase() === 'transactions'
                ? history.push(`${history.location.pathname}/${ROUTES.orderSummary}`, data)
                : history.push(`${history.location.pathname}/${ROUTES.orderSummaryChangeRequest}`, data);
        }
    };

    const { columns } = useDashboardTransactionReroutedTableColumn({
        handleViewDetails,
        filters,
        setFilters,
        setSubmittedOnDropdown,
        submittedOnDropdown,
        rerouteTab,
        changeRequestColumn:
            orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0,
    });

    const modalTitle = LABEL.dueDateExtensionApprovedTitle;
    const modalSubtitle = `${LABEL.dueDateExtensionApprovedSubtitle1} ${moment(targetDate).format('DD/MM/YYYY')} ${
        LABEL.dueDateExtensionApprovedSubtitle2
    }`;
    useEffect(() => {
        const _rerouted = !window.location.pathname.includes('all-branches')
            ? getGroupedTrx(rerouted.current, 'orderNo')
            : reroutedCr.current;
        let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
        data = getGroupingFromData(_rerouted, TableGroupingTemplates, getGroupStyling);
        reroutedTableData.current = [...data];
    }, [rerouted.current]);
    return {
        columnsRerouted: columns,
        dueDateTab,
        handleApproveTargetDate,
        handleExtendTargetDate,
        handleRejectTargetDate,
        handleViewDetails,
        isFilterApplied,
        isSearchResult,
        modalSubtitle,
        modalTitle,
        reroutedGrouped: reroutedTableData,
        reroutedNonGrouped: rerouted,
        searchInput,
        setShowModal,
        setTargetDate,
        showModal,
    };
};
