import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LABEL } from '../../../constants';
import { CustomButton, FlexedDiv, PreviousPage, DocumentUploader } from '../../../components';
import * as ROUTES from '../../../routes';
import { reasonsPageButtons } from '../../../styles';
import styled from 'styled-components';

const ImportUsers: React.FC = () => {
    const history = useHistory();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [uploadedFile, setUploadedFile] = useState<File>();
    const uploadedFileHandler = (file: File) => {
        setUploadedFile(file);
        setDisabled(false);
    };

    return (
        <Fragment>
            <PreviousPage title={LABEL.bulkImportUsers} />
            <StyledInner>
                <PageDescription>{LABEL.bulkImportUsersDescription}</PageDescription>
                <DocumentUploader isMultiple={false} uploadedFileHandle={uploadedFileHandler} />
                <ButtonDiv>
                    <CustomButton style={reasonsPageButtons} onClick={() => history.goBack()}>
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        disabled={disabled}
                        primary
                        style={reasonsPageButtons}
                        onClick={() =>
                            history.push({
                                pathname: ROUTES.viewImportUsers,
                                state: {
                                    file: uploadedFile,
                                    uploadType: 'users',
                                },
                            })
                        }
                    >
                        {LABEL.continue}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
        </Fragment>
    );
};
const StyledInner = styled.div`
    margin-left: 3.5rem;
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;

export default ImportUsers;
