import React, { useContext, useState } from 'react';
import { Avatar, CustomSpacer, Collapsible, FlexedDiv } from '../../../../../../components';
import { LabeledTitle } from '../../../../../../components/molecules/LabeledTitle';
import { EDD, LABEL, TextDarkBlack, TextDarkGrey5, TextDarkGrey6, TextNavyBlue } from '../../../../../../constants';
import { IcoMoon } from '../../../../../../icons';
import { MultiSection } from '../../../../../../templates';
import { getInitialsByFirstTwo } from '../../../../../../utils';
import { ContentContainer } from '../../../../../SystemAdmin/AdviserProfile/Profile';

import styled from 'styled-components';
import EDDContext from '../../../../../../context/EDDContext/EDDContext';

export const AssignedAdviser: React.FC = () => {
    const { caseDetails } = useContext(EDDContext);
    const [expands, setExpands] = useState([true, false, false]);

    const updateExpands = (index: number) => {
        const exp = [...expands];
        if (index === -1) {
            if (!exp[1]) {
                setExpands([true, true, true]);
            } else {
                setExpands([false, false, false]);
            }

            return;
        }
        exp[index] = !exp[index];
        setExpands(exp);
    };

    const expandCheck = !expands[0] || !expands[1] || !expands[2];

    return (
        <DetailsArea>
            <FlexedDiv alignItems="center">
                {caseDetails.status === 'Cancelled' ? (
                    <TopBanner>
                        <FlexedDiv>
                            <IcoMoon name="terminate" size="1.4rem" color="#E84C3D" />

                            <TextDarkBlack size="0.875rem" weight="700" style={{ marginLeft: '8px' }}>
                                {`This case was ${caseDetails.status} by ${caseDetails.officerName}.`}
                            </TextDarkBlack>
                        </FlexedDiv>

                        <CustomSpacer space={'8px'} />
                        <FlexedDiv style={{ paddingLeft: '1.7rem' }}>
                            <TextDarkGrey6 size="12px" weight="700" style={{ display: 'inline-block' }}>
                                {EDD.LABEL_REMARKS}:
                            </TextDarkGrey6>
                            <TextDarkGrey5
                                size="12px"
                                style={{
                                    display: 'inline-block',
                                    wordBreak: 'break-word',
                                    marginLeft: '8px',
                                    whiteSpace: 'break-spaces',
                                }}
                            >
                                {caseDetails.remark[0].title}.
                            </TextDarkGrey5>
                        </FlexedDiv>
                    </TopBanner>
                ) : null}
            </FlexedDiv>
            <FlexedDiv alignItems="center" style={{ marginBottom: '32px' }}>
                <Avatar
                    customStyles={{
                        fontSize: '40px',
                        marginRight: '40px',
                    }}
                    size={120}
                    backgroundColor="#A82252"
                >
                    {getInitialsByFirstTwo(caseDetails.assignedAdviser.personalDetails.agentName)}
                </Avatar>
                <FlexCol>
                    <LabeledTitle
                        label={EDD.LABEL_ADVISER_NAME_CODE}
                        title={caseDetails.assignedAdviser.personalDetails.agentName}
                        style={{ marginBottom: '1px', fontWeight: 'bold' }}
                    />
                    <LabeledTitle
                        label={caseDetails.assignedAdviser.personalDetails.agentCode}
                        title=""
                        style={{ marginBottom: '16px' }}
                    />
                    <LabeledTitle
                        label="NRIC"
                        title={caseDetails.assignedAdviser.personalDetails.nric}
                        style={{ fontWeight: 'bold' }}
                    />
                </FlexCol>
                <FlexedDiv
                    alignItems="center"
                    style={{ marginLeft: 'auto', marginTop: 'auto', cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => updateExpands(-1)}
                >
                    <TextNavyBlue size="12px" weight="700" style={{ marginRight: '4px' }}>
                        {expandCheck ? LABEL.expandAll : LABEL.collapseAll}
                    </TextNavyBlue>
                    <IcoMoon name={expandCheck ? LABEL.expand : LABEL.collapse} size="18px" />
                </FlexedDiv>
            </FlexedDiv>
            <Collapsible
                title={EDD.LABEL_PERSONAL_DETAILS}
                isCheckable={false}
                expanded={expands[0]}
                noXMargin={true}
                noMargin={true}
                showBoxShadow={true}
                content={
                    <ContentContainer>
                        <MultiSection
                            sections={[
                                {
                                    data: [
                                        {
                                            label: EDD.LABEL_LICENSE_CODE,
                                            data: caseDetails.assignedAdviser.personalDetails.licenseCode || '-',
                                        },
                                        {
                                            label: EDD.LABEL_HOME_BRANCH,
                                            data: caseDetails.assignedAdviser.personalDetails.branch || '-',
                                        },
                                        {
                                            label: EDD.LABEL_REGION,
                                            data: caseDetails.assignedAdviser.personalDetails.region || '-',
                                        },
                                        {
                                            label: EDD.LABEL_ADVISER_CHANNEL,
                                            data: caseDetails.assignedAdviser.personalDetails.channel || '-',
                                        },
                                        {
                                            label: EDD.LABEL_OMNI_ENABLED,
                                            data: caseDetails.assignedAdviser.personalDetails.omniEnabled
                                                ? 'Yes'
                                                : 'No' || '-',
                                        },
                                        {
                                            label: EDD.LABEL_ADVISER_RANK,
                                            data: caseDetails.assignedAdviser.personalDetails.rank || '-',
                                        },
                                    ],
                                },
                            ]}
                        />
                        <StyledDivider />
                        <MultiSection
                            sections={[
                                {
                                    data: [
                                        {
                                            label: EDD.LABEL_ADVISERS_BDM_NAME_CODE,
                                            data: caseDetails.assignedAdviser.personalDetails.bdmName || '-',
                                            subText: caseDetails.assignedAdviser.personalDetails.bdmCode || '-',
                                        },
                                        {
                                            label: EDD.LABEL_ADVISERS_BDM_EMAIL,
                                            data: caseDetails.assignedAdviser.personalDetails.bdmEmail || '-',
                                        },
                                        {
                                            label: EDD.LABEL_UPLINE_NAME_CODE,
                                            data: caseDetails.assignedAdviser.personalDetails.uplineName || '-',
                                            subText: caseDetails.assignedAdviser.personalDetails.uplineCode || '-',
                                        },
                                        {
                                            label: EDD.LABEL_AGENCY,
                                            data: caseDetails.assignedAdviser.personalDetails.agency || '-',
                                        },
                                    ],
                                },
                            ]}
                        />
                    </ContentContainer>
                }
                handleExpand={() => updateExpands(0)}
            />
            <div style={{ marginBottom: '16px' }} />
            <Collapsible
                title={EDD.LABEL_CONTACT_DETAIL}
                isCheckable={false}
                expanded={expands[1]}
                noMargin={true}
                noXMargin={true}
                showBoxShadow={true}
                content={
                    <ContentContainer>
                        <MultiSection
                            sections={[
                                {
                                    data: [
                                        {
                                            label: EDD.LABEL_EMAIL,
                                            data: caseDetails.assignedAdviser.contactDetails.email || '-',
                                        },
                                        {
                                            label: EDD.LABEL_MOBILE_NO,
                                            data: caseDetails.assignedAdviser.contactDetails.mobileNo || '-',
                                        },
                                    ],
                                },
                            ]}
                        />
                    </ContentContainer>
                }
                handleExpand={() => updateExpands(1)}
            />
            <div style={{ marginBottom: '16px' }} />
            <Collapsible
                title={EDD.LABEL_ADDRESS_INFO}
                isCheckable={false}
                expanded={expands[2]}
                noMargin={true}
                noXMargin={true}
                showBoxShadow={true}
                content={
                    <ContentContainer>
                        <MultiSection
                            sections={[
                                {
                                    data: [
                                        {
                                            label: EDD.LABEL_PERMANENT_ADDRESS_1,
                                            data: caseDetails.assignedAdviser.addressInformation.address || '-',
                                        },
                                        {
                                            label: EDD.LABEL_PERMANENT_ADDRESS_2,
                                            data: caseDetails.assignedAdviser.addressInformation.address2 || '-',
                                        },
                                        {
                                            label: EDD.LABEL_PERMANENT_ADDRESS_3,
                                            data: caseDetails.assignedAdviser.addressInformation.address3 || '-',
                                        },
                                        {
                                            label: EDD.LABEL_POSTCODE,
                                            data: caseDetails.assignedAdviser.addressInformation.postcode || '-',
                                        },
                                        {
                                            label: EDD.LABEL_CITY_TOWN,
                                            data: caseDetails.assignedAdviser.addressInformation.addrTown || '-',
                                        },
                                        {
                                            label: EDD.LABEL_STATE,
                                            data: caseDetails.assignedAdviser.addressInformation.state || '-',
                                        },
                                        {
                                            label: EDD.LABEL_COUNTRY,
                                            data: caseDetails.assignedAdviser.addressInformation.country || '-',
                                        },
                                    ],
                                },
                            ]}
                        />
                    </ContentContainer>
                }
                handleExpand={() => updateExpands(2)}
            />
        </DetailsArea>
    );
};

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledDivider = styled.div`
    border: 1px dashed #eaebee;
`;

const DetailsArea = styled.div`
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 72px;
    flex-direction: column;
`;

const TopBanner = styled.div`
    padding: 16px 24px;
    background: #faf2f1;
    border: 1px solid #e84c3d;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 0px 0px 32px 0px;
    width: 100%;
`;
// const ProfileLink = styled.a`
//     display: inline-block;
//     margin-left: 10px;
//     fill: #002043;
//     &:hover {
//         fill: #1890ff;
//     }
// `;
