import React, { Fragment, useEffect } from 'react';
import { FlexedDiv } from '../../components';
import { Fs12RegSecNavyBlueLh16 } from '../../constants';
import { IcoMoon } from '../../icons';
import { sh176 } from '../../styles';
import styled from 'styled-components';
import { Modal } from '../../components/organisms/Modal/Modal';
interface IProgressModalProps {
    numberOfReports: number;
    progressWidth: number;
    setProgressWidth: React.Dispatch<React.SetStateAction<number>>;
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
    setToggleModal: (index: boolean) => void;
    startTime: number;
    testId?: string;
    timeLeft: number;
    toggleModal: boolean;
}
type ProgressProps = {
    width: number;
};

const ProgressModal = ({
    numberOfReports,
    progressWidth,
    setProgressWidth,
    setTimeLeft,
    setToggleModal,
    startTime,
    timeLeft,
    toggleModal,
    testId,
}: IProgressModalProps): JSX.Element => {
    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) {
            // timerFunc();
            setProgressWidth(99);
            return;
        }
        // save intervalId to clear the interval when the component re-renders
        const intervalId = setInterval(() => {
            setProgressWidth(progressWidth + 100 / startTime);
            setTimeLeft(timeLeft - 1);
        }, 1000);
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect when we update it
    }, [timeLeft]);

    const displayTimeLeft = (seconds: number) => {
        const minutesLeft = Math.floor(seconds / 60).toString();
        let secondsLeft = (seconds % 60).toString();
        secondsLeft = secondsLeft.length === 1 ? '0' + secondsLeft : secondsLeft;
        seconds--;
        return minutesLeft === '00'
            ? ` ${secondsLeft} seconds left`
            : `${minutesLeft} minutes ${secondsLeft} seconds left`;
    };

    return (
        <Fragment>
            {toggleModal ? (
                <Modal
                    modalActive={toggleModal}
                    setModalActive={setToggleModal}
                    data-testid="modal"
                    testId={testId ?? ''}
                    title={''}
                >
                    <FlexedDiv justifyContent="center" direction="column" style={{ padding: '2rem' }}>
                        <Icon>
                            <IcoMoon name={'report-processing'} size={sh176} />
                        </Icon>
                        <ProcessingText>{`Processing ${numberOfReports} reports…`}</ProcessingText>
                        <FlexedDiv justifyContent="space-between">
                            <ElapsedTime>{displayTimeLeft(timeLeft)}</ElapsedTime>
                            <Fs12RegSecNavyBlueLh16>{`${Math.trunc(progressWidth)}%`}</Fs12RegSecNavyBlueLh16>
                        </FlexedDiv>
                        <ProgressBarStyles id="progress">
                            <ProgressBar>
                                <Progress width={progressWidth}></Progress>
                            </ProgressBar>
                        </ProgressBarStyles>
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default ProgressModal;

const ProcessingText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.05px;
    color: #002043;
`;
const ElapsedTime = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(42, 54, 90, 0.8);
`;
const Icon = styled.div`
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
`;

const ProgressBarStyles = styled.div`
    width: 100%;
`;
const ProgressBar = styled.div`
    border-radius: 10px;
    background-color: #eaebee;
    margin-top: 12px;
`;
const Progress = styled.div<ProgressProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 10px;
    border-radius: 10px;
    background-color: #2ecc82;
    transition: width 0.3s ease;
    width: ${(props) => props.width}%;
`;
