/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { LABEL } from '../../constants';
import { CustomButton, FlexedDiv, PreviousPage } from '../../components';
import { reasonsPageButtons } from '../../styles';
import { Modal, TableSheet } from '../../components/organisms';
import { IcoMoon } from '../../icons';

import { getDocumentType } from '../../utils';
import styled, { css } from 'styled-components';
import moment from 'moment';
import * as Routes from '../../routes';
import bulkUploadMutation from '../../_graphql/mutations/fundData/bulkUpload';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

// Types & interfaces
type fundData = {
    fundAbbr: string;
    fundName: string;
};
interface IBulkUploadProps {
    files: File[];
    funddata: fundData[];
    tabIndex: number;
}
interface IBulkUpload {
    numberOfDocuments: number;
    date: string;
    documents: BulkDocument[];
}

type BulkDocument = {
    fileName: string;
    fileSize: number;
    fundAbbr: string;
    documentType: string;
    path: string;
};
const modalMessages = {
    approved: {
        heading: 'Bulk Upload Documents request approved',
        subHeading: '',
        icon: 'bulk-modal-processing',
    },
    pending: {
        heading: 'Bulk Upload Documents request submitted',
        subHeading: '',
        icon: 'bulk-modal-approve',
    },
};
// bulk table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 120,
    secondColWidth: 280,
    thirdColWidth: 42,
};
const ViewBulkUpload: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const { uploads } = parsedPermission.hq.permission;
    const { state: propsData } = useLocation<IBulkUploadProps>();
    const history = useHistory();
    // states for user permissions
    const [isAutiAuth, setIsAutiAuth] = useState<boolean>(false);
    // states for modals, title and description
    const [showModal, setShowModal] = useState(true);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    // states to generate table
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [externalLinks, setExternalLinks] = useState<string[]>();
    const [rowsMarked, setRowsMarked] = useState(false); // track if single row is marked or not
    const [isProcessing, setIsProcessing] = useState(false);

    // check page permissions
    const checkPermissions = () => {
        if (
            uploads.documentsTab.reviewApproval &&
            uploads.documentsTab.reviewApproval.canApproveBulkUpload === 'auto-authorizer'
        ) {
            setIsAutiAuth(true);
        }
    };
    // set bulkupload data to rows and columns states
    const processBulkUploadData = (files: File[]) => {
        const tempCols: any = ['Fund Documents (.pdf)', 'File Size', 'Fund Name', 'Documents'];
        const columnsOutput: ISheetColumn[] = [];
        let columnObj: any = {};
        //build sheet columns objects
        tempCols.map((column: string) => {
            columnObj = {
                colName: column,
                type: 'string',
            };
            columnsOutput.push(columnObj);
        });

        //build sheet cell objects
        let cellObj: any = {};
        const cellRow: ISheetCell[][] = [];
        const externalLinkCells: string[] = []; //to separate the external links
        try {
            Object.values(files).map((file) => {
                const tempRow: ISheetCell[] = [];

                columnsOutput.map((column, index) => {
                    if (index === 0) {
                        const objectURL = URL.createObjectURL(file);
                        externalLinkCells.push(objectURL);
                        cellObj = {
                            cellValue: file.name,
                            isValid:
                                (getFundName(file.name.split(' ')[0]) === 'FUND NAME NOT IN SYSTEM' ? false : true) &&
                                (getDocumentType(file.name.split(' ')[1].split('.pdf')[0]) ===
                                'DOCUMENT TYPE NOT IN SYSTEM'
                                    ? false
                                    : true),
                            isMarked: false,
                            isDelete: false,
                        };
                    } else {
                        switch (column.colName) {
                            case 'File Size':
                                cellObj = {
                                    cellValue: file.size,
                                    isValid: true,
                                    isFileSize: true,
                                };
                                break;
                            case 'Fund Name':
                                cellObj = {
                                    cellValue: getFundName(file.name.split(' ')[0]),
                                    isValid:
                                        getFundName(file.name.split(' ')[0]) === 'FUND NAME NOT IN SYSTEM'
                                            ? false
                                            : true,
                                };
                                break;
                            case 'Documents':
                                cellObj = {
                                    cellValue: getDocumentType(file.name.split(' ')[1].split('.pdf')[0]),
                                    isValid:
                                        getDocumentType(file.name.split(' ')[1].split('.pdf')[0]) ===
                                        'DOCUMENT TYPE NOT IN SYSTEM'
                                            ? false
                                            : true,
                                };
                                break;

                            default:
                        }
                    }
                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });
        } catch {
            alert('Please upload a valid fund pdf');
        }

        setColumns(columnsOutput);
        setRows(cellRow);
        setExternalLinks(externalLinkCells);
        setIsProcessing(false);
    };

    //function to get fund name from props
    const getFundName = (fundAbbr: string): string => {
        let fundName = '';
        if (propsData) {
            const fundObj = propsData.funddata.find((item) => item.fundAbbr === fundAbbr);
            fundObj ? (fundName = fundObj.fundName) : (fundName = 'FUND NAME NOT IN SYSTEM');
        }

        return fundName;
    };

    //function to handle marking row as selected
    const markRowOkHandler = (rowIndex: number) => {
        const tempRow = [...rows];

        if (!tempRow[rowIndex][0].isDelete && !tempRow[rowIndex][0].isMarked) {
            tempRow[rowIndex][0].isMarked = true;
        } else {
            tempRow[rowIndex][0].isMarked = false;
        }
        setRows(tempRow);
    };
    //function to handle marking row as deselected
    const markRowDeleteHandler = (rowIndex: number) => {
        const tempRow = [...rows];

        if (tempRow[rowIndex][0].isDelete) {
            tempRow[rowIndex][0].isDelete = false;
            tempRow[rowIndex][0].isMarked = false;
        } else {
            tempRow[rowIndex][0].isDelete = true;
        }

        setRows(tempRow);
    };
    //function to handle marking all rows as selected
    const markAllRowHandler = () => {
        const tempRow = [...rows];

        tempRow.map((row, rowIndex) => {
            // tempRow[rowIndex][0].isMarked = false;
            if (row.some((e) => e.isValid === false)) {
                tempRow[rowIndex][0].isMarked = false;
            } else {
                tempRow[rowIndex][0].isMarked = true;
            }
        });

        setRows(tempRow); //set valid rows to marked
    };
    //function to handle marking all rows as deselected
    const undoAllHandler = () => {
        const tempRow = [...rows];
        tempRow.map((row, rowIndex) => {
            // tempRow[rowIndex][0].isMarked = false;
            if (row.some((e) => e.isValid === false)) {
                tempRow[rowIndex][0].isMarked = false;
            } else {
                tempRow[rowIndex][0].isMarked = false;
                tempRow[rowIndex][0].isDelete = false;
            }
        });
        setRows(tempRow);
    };
    const onSubmitHandler = async () => {
        setIsSubmitting(true);
        setSubmitDisabled(true);

        //init final object to build
        const bulkUploadPayLoad: IBulkUpload = {
            numberOfDocuments: 0,
            date: '',
            documents: [],
        };
        const documentsPayload: BulkDocument[] = [];
        const documentsIds: number[] = [];

        //map rows to build documents arrary
        rows.map((file, fileIndex) => {
            const _fileObj: BulkDocument = { fileName: '', fileSize: 0, fundAbbr: '', documentType: '', path: '' };
            if (file[0].isMarked) {
                columns.map((col, colIndex) => {
                    switch (col.colName) {
                        case 'Fund Documents (.pdf)':
                            _fileObj['fileName'] = file[colIndex].cellValue;
                            _fileObj['fundAbbr'] = propsData.files[fileIndex].name.split(' ')[0];
                            break;
                        case 'File Size':
                            _fileObj['fileSize'] = parseInt(file[colIndex].cellValue);
                            break;
                        default:
                            _fileObj['documentType'] = `${
                                propsData.files[fileIndex].name.split(' ')[1].split('.pdf')[0]
                            }`;
                            _fileObj['path'] = `${_fileObj['fundAbbr']}/${moment().unix()}/${_fileObj['fileName']}`;
                    }
                });
                documentsIds.push(fileIndex);
                documentsPayload.push(_fileObj);
            }
        });

        //check if at least 1 document is marked for submission
        if (documentsIds.length !== 0) {
            bulkUploadPayLoad.documents = documentsPayload;
            bulkUploadPayLoad.date = moment().unix().toString();
            bulkUploadPayLoad.numberOfDocuments = documentsIds.length;

            // set modal heading and sub heading depending on upload type
            modalMessages.approved.subHeading = `${documentsIds.length} files of fund documents has been approved.`;
            modalMessages.pending.subHeading = `${documentsIds.length} files of fund documents has been submitted 
        and pending for review.`;

            await mapDocumentsToS3(documentsIds, documentsPayload);
            uploadBulkDocumentsMutation(bulkUploadPayLoad);
        } else {
            setSubmitDisabled(false);
        }
    };

    const mapDocumentsToS3 = async (documentsIds: number[], documentsPayload: BulkDocument[]) => {
        // map the actual file id from filelist to upload S3
        for (let index = 0; index < documentsIds.length; index++) {
            if (documentsPayload[index]) {
                await uploadToCloudStroage(documentsPayload[index].path, propsData.files[documentsIds[index]]);
            }
        }
    };
    // function to upload to S3 bucket
    const uploadToCloudStroage = async (path: string, file: File) => {
        const params = {
            contentType: file.type,
            customPrefix: {
                public: 'products/',
            },
        };

        await Storage.put(path, file, params).then().catch();
    };

    //function to call bulkupload mutation
    const uploadBulkDocumentsMutation = async (bulkUploadPayLoad: IBulkUpload) => {
        bulkUploadPayLoad.documents.map((doc) => (doc.path = `products/${doc.path}`)); // append Products to path

        try {
            const response: any = await API.graphql(
                graphqlOperation(bulkUploadMutation, {
                    input: bulkUploadPayLoad,
                }),
                idTokenHeader,
            );

            const { error } = await response.data.bulkUpload;
            if (!error) {
                handleShowConfirmation();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Bulk Upload Documents request failed',
                    testId: 'bulk-import-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };
    const goBacktoBulkUploader = () => {
        history.push({
            pathname: Routes.financeImportBulkUploader,
            state: {
                activeTab: propsData.tabIndex,
            },
        });
    };
    const goBacktoUploadDashboard = () => {
        history.push({
            pathname: Routes.financeUploads,
            state: {
                activeTab: propsData.tabIndex,
            },
        });
    };
    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };

    //function to check if one or more rows are marked
    useEffect(() => {
        const areRowsMarked = rows.filter((row) => row[0].isMarked);
        const areRowsEdited = rows.filter((row) => row[0].isDelete || row[0].isMarked);
        areRowsMarked.length > 0 ? setSubmitDisabled(false) : setSubmitDisabled(true);

        areRowsEdited.length > 0 ? setRowsMarked(true) : setRowsMarked(false);
    }, [rows]);

    useEffect(() => {
        if (propsData) {
            checkPermissions();
            setIsProcessing(true);
            processBulkUploadData(propsData.files);
        }
    }, [propsData]);
    return (
        <Fragment>
            <PreviousPage title={LABEL.bulkUploadDocuments} />
            <StyledInner>
                <PageDescription>You can upload multiple pdf file all at once.</PageDescription>
                {isProcessing ? (
                    <p>Processing data, hold on!</p>
                ) : (
                    <Fragment>
                        <TableSheet
                            columns={columns}
                            rows={rows}
                            isRowMarked={rowsMarked}
                            handleMarkDelete={markRowDeleteHandler}
                            handleMarkOkay={markRowOkHandler}
                            handleMarkAll={markAllRowHandler}
                            handleUndoAll={undoAllHandler}
                            readyOnly={false}
                            columnWidths={fixedCoLumnlWidth}
                            externalLinks={externalLinks}
                            hasExternalLinks={true}
                        />
                        <FlexedDiv>
                            <IcoMoon name="upload" size="1rem" />
                            <ReuploadBtn onClick={goBacktoBulkUploader} id="bulk-import-reupload-btn">
                                Reupload PDF
                            </ReuploadBtn>
                        </FlexedDiv>
                    </Fragment>
                )}
                <ButtonDiv>
                    <CustomButton
                        style={reasonsPageButtons}
                        onClick={goBacktoUploadDashboard}
                        id="bulk-import-cancel-btn"
                    >
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary
                        style={reasonsPageButtons}
                        onClick={onSubmitHandler}
                        disabled={submitDisabled || isSubmitting}
                        id="bulk-import-submit-btn"
                    >
                        {LABEL.submit}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={handleShowConfirmation}
                    title={isAutiAuth ? modalMessages.approved.heading : modalMessages.pending.heading}
                    primaryBtn={{
                        onClick: () => {
                            goBacktoUploadDashboard();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    testId="bulk-import-modal"
                    contentAlignment="center"
                    icon={isAutiAuth ? modalMessages.approved.icon : modalMessages.pending.icon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {isAutiAuth ? modalMessages.approved.subHeading : modalMessages.pending.subHeading}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

const StyledInner = styled.div`
    margin-left: 2.6rem;
`;

const SharedBtnStyles = css`
    border: 0px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 700;
    &:focus {
        outline: none;
    }
`;

const ReuploadBtn = styled.button`
    ${SharedBtnStyles}
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;
export default ViewBulkUpload;
