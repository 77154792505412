/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom'; //Only needed when routing/navigating

import { API, graphqlOperation } from 'aws-amplify';
import { newNotificationCount } from '../../../_graphql/queries/inbox/newNotificationCount';
import { IPermissionObject } from '../../../utils/permissionTypes';
import { checkSubMenuLinkStatus, getInboxSource } from '../../../utils';
import { useComponentLoader } from '../../../customHooks';
import { IcoMoon } from '../../../icons';
import { TextDarkGrey4, TextNavyBlue } from '../../../constants';

import AuthContext from '../../../context/AuthContext';
import styled, { css, keyframes } from 'styled-components';
export interface ISideNavRegularMenuProps {
    handleLogout: () => void;
    hideCounter: boolean;
    navData: ISideNavMenuItemSection[];
    path: string;
    section?: boolean;
    setHideCounter: React.Dispatch<React.SetStateAction<boolean>>;
    parsedPermission: IPermissionObject;
}
interface renderStyle {
    isTrue: boolean;
}
export const NavMenu: React.FC<ISideNavRegularMenuProps> = ({
    hideCounter,
    navData,
    setHideCounter,
    parsedPermission,
}: ISideNavRegularMenuProps) => {
    const data = navData;
    const subMenuRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);
    const [counter, setCounter] = useState<number>(0);
    const [expand, setExpand] = useState<boolean>(false);
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const checkLinkActiveStatus = (route: string): boolean => {
        if (location.pathname.includes(route)) {
            return true;
        } else {
            return false;
        }
    };
    const getNewNotifications = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(newNotificationCount, {
                    input: {
                        source: getInboxSource(parsedPermission),
                        page: 1,
                        resultLimit: 1,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.inboxDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;
            /*** @passData */
            const { result } = response.data.inboxDashboard.data;
            setCounter(parseInt(result.newMessageCount));
            loadingHandler();
        } catch (error) {
            loadingHandler();
        }
    };

    const MenuItem = (
        title: string,
        route: string,
        _icon?: () => JSX.Element,
        iconName?: string,
        subMenu?: ISideNavSubMenuItem[],
    ) => {
        return (
            <Fragment key={route}>
                {title !== 'Inbox' ? (
                    <Fragment>
                        <NavLink
                            to={route}
                            style={{ color: 'inherit', display: 'block' }}
                            onClick={() => {
                                setHideCounter(false);
                                subMenu !== undefined && subMenu.length !== 0 ? setExpand(true) : setExpand(false);
                            }}
                            id={`${title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}-menu-item`}
                            isActive={() => {
                                return checkLinkActiveStatus(route);
                            }}
                            activeStyle={{
                                backgroundColor: '#C61230',
                            }}
                        >
                            <MenuItemWrapper>
                                {iconName !== undefined ? (
                                    <IcoMoon
                                        name={iconName}
                                        size="24px"
                                        color={checkLinkActiveStatus(route) ? '#fff' : '#002043'}
                                    />
                                ) : null}
                                <TextNavyBlue
                                    size="14px"
                                    weight="700"
                                    style={{
                                        marginLeft: '10px',
                                        color: checkLinkActiveStatus(route) ? '#fff' : '#002043',
                                    }}
                                >
                                    {title}
                                </TextNavyBlue>
                                {subMenu !== undefined && subMenu.length !== 0 ? (
                                    <div onClick={() => setExpand(!expand)}>
                                        <IcoMoon
                                            name={expand ? 'caret-up' : 'caret-down'}
                                            size="16px"
                                            style={{ marginLeft: '8px' }}
                                            color={checkLinkActiveStatus(route) ? '#fff' : '#002043'}
                                        />
                                    </div>
                                ) : null}
                            </MenuItemWrapper>
                        </NavLink>
                        {subMenu !== undefined && subMenu.length !== 0 ? (
                            <WrapperDiv isOnProp={expand} height={`${height}px`}>
                                <SubMenuWrapper isOnProp={expand} ref={subMenuRef}>
                                    {subMenu.map((item) => {
                                        return (
                                            <NavLink
                                                key={`submenu-item-${item.menuTitle}`}
                                                to={item.route}
                                                style={{ color: 'inherit', display: 'block' }}
                                                id={`${item.menuTitle
                                                    .replace(/^[, ]+|[, ]+$|[, ]+/g, '-')
                                                    .toLowerCase()}-menu-item`}
                                                isActive={() => {
                                                    return checkSubMenuLinkStatus(item.route);
                                                }}
                                                activeStyle={{
                                                    background: '#F2F4F6',
                                                }}
                                            >
                                                <SubMenuItem>
                                                    {item.iconName !== undefined ? (
                                                        <IcoMoon
                                                            name={item.iconName}
                                                            size="24px"
                                                            color={
                                                                checkSubMenuLinkStatus(item.route) ? '#fff' : '#002043'
                                                            }
                                                        />
                                                    ) : null}
                                                    <TextNavyBlue
                                                        size="12px"
                                                        weight="700"
                                                        style={{
                                                            marginLeft: item.iconName !== undefined ? '10px' : '34px',
                                                        }}
                                                    >
                                                        {item.menuTitle}
                                                    </TextNavyBlue>
                                                </SubMenuItem>
                                            </NavLink>
                                        );
                                    })}
                                </SubMenuWrapper>
                            </WrapperDiv>
                        ) : null}
                    </Fragment>
                ) : counter !== undefined && counter !== 0 ? (
                    <NavLink
                        to={route}
                        style={{
                            color: 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingRight: '1.5rem',
                        }}
                        id={`${title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}-menu-item`}
                        isActive={() => {
                            return checkLinkActiveStatus(route);
                        }}
                        activeStyle={{
                            backgroundColor: '#C61230',
                        }}
                        onClick={() => {
                            setHideCounter(true);
                            setExpand(false);
                        }}
                    >
                        <MenuItemWrapper>
                            {iconName !== undefined ? (
                                <IcoMoon
                                    name={iconName}
                                    size="24px"
                                    color={checkLinkActiveStatus(route) ? '#fff' : '#002043'}
                                />
                            ) : null}
                            <TextNavyBlue
                                size="14px"
                                weight="700"
                                style={{
                                    marginLeft: '10px',
                                    color: checkLinkActiveStatus(route) ? '#fff' : '#002043',
                                }}
                            >
                                {title}
                            </TextNavyBlue>
                        </MenuItemWrapper>
                        <Counter isTrue={hideCounter}>
                            {loading ? <Spinner className="spinner" /> : hideCounter ? '' : counter}
                        </Counter>
                    </NavLink>
                ) : (
                    <NavLink
                        to={route}
                        style={{ color: 'inherit', display: 'block' }}
                        id={`${title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}-menu-item`}
                        isActive={() => {
                            return checkLinkActiveStatus(route);
                        }}
                        activeStyle={{
                            backgroundColor: '#C61230',
                        }}
                    >
                        <MenuItemWrapper>
                            {iconName !== undefined ? (
                                <IcoMoon
                                    name={iconName}
                                    size="24px"
                                    color={checkLinkActiveStatus(route) ? '#fff' : '#002043'}
                                />
                            ) : null}
                            <TextNavyBlue
                                size="14px"
                                weight="700"
                                style={{ marginLeft: '10px', color: checkLinkActiveStatus(route) ? '#fff' : '#002043' }}
                            >
                                {title}
                            </TextNavyBlue>
                        </MenuItemWrapper>
                    </NavLink>
                )}
            </Fragment>
        );
    };

    useEffect(() => {
        if (!hideCounter) {
            getNewNotifications();
        }
    }, [hideCounter]);
    useEffect(() => {
        subMenuRef.current !== null ? setHeight(subMenuRef.current.clientHeight) : null;
    }, [subMenuRef, expand]);
    // Memo function to have subMenu expanded on render if the loading route is same as first submenu route
    useMemo(() => {
        data.map(({ menu }) => {
            menu.map(({ route, subMenu }) => {
                if (checkLinkActiveStatus(route) && subMenu !== undefined && subMenu.length !== 0) {
                    subMenu[0].route === route ? setExpand(!expand) : null;
                }
            });
        });
    }, []);
    return (
        <MenuElm id="menu-element">
            {data &&
                data.map(({ menu, section }, index) => {
                    return (
                        <MenuGroup key={index} id={`menu-group-${index}`}>
                            {section && section.length !== 0 ? (
                                <MenuGroupName>
                                    <TextDarkGrey4 size="10px">{section}</TextDarkGrey4>
                                </MenuGroupName>
                            ) : null}

                            {menu &&
                                menu.map(({ title, route, icon, iconName, subMenu }) => {
                                    return MenuItem(title, route, icon, iconName, subMenu);
                                })}
                        </MenuGroup>
                    );
                })}
        </MenuElm>
    );
};
const MenuGroup = styled.li`
    margin-bottom: 1rem;
`;
const MenuGroupName = styled.div`
    padding: 4px 1.5rem 8px;
`;
const MenuElm = styled.ul`
    padding: 0;
    list-style: none;
`;

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    height: 48px;
    line-height: 48px;
`;

const Counter = styled.div`
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            visibility: hidden;
        `}
    background: #c61230;
    border-radius: 4px;
    height: 1.5rem;
    max-width: 2rem;
    display: flex;
    align-items: inherit;
    justify-content: center;
    color: white;
    font-size: 0.625rem;
    line-height: 13px;
    padding: 0px 0.5rem;
`;

const spin = keyframes`
 0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
const Spinner = styled.div`
    font-size: 1em;
    margin: 50px auto;
    text-indent: -9999em;
    width: 1em;
    height: 1em;
    position: absolute;
    border-radius: 50%;
    transform: translateZ(0);
    animation: ${spin} 1.4s infinite linear;
    background: -webkit-linear-gradient(#e4d7d7 0%, rgb(193 10 39 / 0%) 50%);
    &:before {
        content: '';
        width: 50%;
        height: 50%;
        background: #e4d7d7;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    &:after {
        background: #c10a27;
        width: 85%;
        height: 85%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`;
const SubMenuWrapper = styled.div<IOnPropStyles>`
    padding: 0.5rem 0rem;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    ${(props) =>
        props.isOnProp &&
        css`
            border-bottom: 1px solid #e7ebf1;
            opacity: 1;
            visibility: visible;
            overflow: inherit;
        `}
    transition: opacity 0.5s ease 0.1s;
`;
const SubMenuItem = styled.div`
    padding: 0 1.5rem;
    height: 32px;
    display: flex;
    align-items: center;
`;
const WrapperDiv = styled.div<IOnPropStyles>`
    height: ${(props) => (props.isOnProp ? props.height : '0px')};
    transition: all 0.6s ease 0s;
`;
