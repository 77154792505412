/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    CustomButton,
    CustomSpacer,
    FlexedDiv,
    Modal,
    PreviousPage,
    RadioButton,
    TextArea,
} from '../../../../components';
import {
    Fs12BoldPrimaryBlack,
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    Fs18BoldPrimaryBlack,
    SYSTEM_ADMIN,
} from '../../../../constants';
import { IcoMoon } from '../../../../icons';
import { submitData } from '../utils/functions';
import { BranchPrivileges, DropdownBase } from './BranchPrivileges/BranchPrivileges';
import {
    adviserInitialData,
    customerExpInitialData,
    IAdviserSection,
    ICustomerExpSection,
    ILoginRoleSection,
    loginAccessInitialData,
} from './BranchPrivileges/branchRolesInitialData';
import { HqPrivileges } from './HqPrivileges';
import {
    activityLogsInitialData,
    branchOfficesInitialData,
    customerExperienceInitialData,
    eddCaseInitialData,
    financeCasesInitialData,
    financeDashboardInitialData,
    fundUploadsInitialData,
    IActivityLogsSection,
    IBranchOfficeSection,
    ICustomerExperienceSection,
    IEDDCasesSection,
    IFinanceCasesSection,
    IFinanceDashboardSection,
    IFundUploads,
    IOperationCases,
    IOperationDashboard,
    IProductSettings,
    IUserManagementSection,
    operationCasesInitialData,
    operationDashboardInitialData,
    productSettingsInitialData,
    userManagementInitialData,
    hqLoginAccessInitialData,
    ISystemSettingsSection,
    systemSettingsInitialData,
} from './HqPrivileges/hqRolesInitialData';
import { RejectRemarks } from './RejectRemarks';
import { API, graphqlOperation } from 'aws-amplify';
import { createRoleMutation } from '../../../../_graphql/mutations/systemAdmin/createRole';
import { IUserManagement } from '../../../../utils/permissionTypes';
import { Validate } from '../../../../validation/validate';
import { IValidation } from '../../../../interfaces/IValidation';
import { CustomInput } from '../../../../components/atoms/Input';

import styled from 'styled-components';
import * as Routes from '../../../../routes';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

export interface CheckBoxState {
    checked: boolean;
    value: string;
}

export type Privileges = 'branch' | 'hq';

export const AddRoles = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const [roleName, setRoleName] = useState<string>('');
    const [status] = useState<string>('Pending');
    const [roleDescription, setRoleDescription] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loginAccess, setLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [hqLoginAccess, setHqLoginAccess] = useState<ILoginRoleSection>(hqLoginAccessInitialData);
    const [customerExp, setCustomerExp] = useState<ICustomerExpSection>(customerExpInitialData);
    const [userManagement, setUserManagement] = useState<IUserManagementSection>(userManagementInitialData);
    const [activityLogs, setActivityLogs] = useState<IActivityLogsSection>(activityLogsInitialData);
    const [systemSettings, setSystemSettings] = useState<ISystemSettingsSection>(systemSettingsInitialData);
    const [customerExpDashboard, setCustomerExpDashboard] =
        useState<ICustomerExperienceSection>(customerExperienceInitialData);
    const [branchOffices, setBranchOffices] = useState<IBranchOfficeSection>(branchOfficesInitialData);
    const [eddCase, setEddCase] = useState<IEDDCasesSection>(eddCaseInitialData);
    const [financeDashboard, setFinanceDashboard] = useState<IFinanceDashboardSection>(financeDashboardInitialData);
    const [financeCases, setFinanceCases] = useState<IFinanceCasesSection>(financeCasesInitialData);
    const [productSettings, setProductSettings] = useState<IProductSettings>(productSettingsInitialData);
    const [fundUploads, setFundUploads] = useState<IFundUploads>(fundUploadsInitialData);
    const [operation, setOperation] = useState<IOperationDashboard>(operationDashboardInitialData);
    const [operationCases, setOperationCases] = useState<IOperationCases>(operationCasesInitialData);
    const [advisers, setAdvisers] = useState<IAdviserSection>(adviserInitialData);
    const [privilege, setPrivilege] = useState<Privileges>('branch');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<boolean>(false);
    const [remarks, setRemarks] = useState<string>('');
    const history = useHistory();

    const parsedPermission = JSON.parse(userLoginContext.permission);
    // console.log('parsed', parsedPermission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;

    const { rolesPermissionTab } = userManagementPermission;
    const { canCreateUserRole } = rolesPermissionTab.actions;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const handleRoleName = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        const att = e.currentTarget.getAttribute('accept');
        let valueMessage = e.currentTarget.getAttribute('itemProp');
        if (valueMessage === null) {
            valueMessage = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;

        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valueMessage);
            let errVal = '';
            if (err.code !== 'NoError') {
                errVal = err.message;
            }
            setError(errVal);
            setRoleName(e.target.value);
        }
    };

    const handleCancel = () => {
        history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
    };

    const handleSave = async () => {
        let branchData = {};
        if (loginAccess.isAll === true) {
            branchData = { ...branchData, accountManagement: loginAccess };
        }
        if (customerExp.isAll === true) {
            branchData = { ...branchData, ceTransaction: customerExp };
        }
        if (advisers.isAll === true) {
            branchData = { ...branchData, adviser: advisers };
        }
        let hqData = {};
        if (hqLoginAccess.isAll === true) {
            hqData = { ...hqData, accountManagement: hqLoginAccess };
        }
        if (customerExpDashboard.isAll === true) {
            hqData = { ...hqData, ceDashboard: customerExpDashboard };
        }
        if (userManagement.isAll === true) {
            hqData = { ...hqData, userManagement };
        }
        if (activityLogs.isAll === true) {
            hqData = { ...hqData, activityLogs };
        }
        if (systemSettings.isAll === true) {
            hqData = { ...hqData, systemSettings };
        }
        if (eddCase.isAll === true) {
            hqData = { ...hqData, eddCase };
        }
        if (branchOffices.isAll === true) {
            hqData = { ...hqData, branchOffices };
        }
        if (financeDashboard.isAll === true) {
            hqData = { ...hqData, financeDashboard };
        }
        if (productSettings.isAll === true) {
            hqData = { ...hqData, productSettings };
        }
        if (fundUploads.isAll === true) {
            hqData = { ...hqData, uploads: fundUploads };
        }
        if (operation.isAll === true) {
            hqData = { ...hqData, operation };
        }
        if (operationCases.isAll === true) {
            hqData = { ...hqData, operationCases };
        }
        const data = privilege === 'branch' ? branchData : hqData;

        const dataToSend = submitData(data, privilege);
        // console.log('payload', {
        //     roleName: roleName,
        //     description: roleDescription,
        //     grant: privilege,
        //     permission: dataToSend,
        // });
        try {
            const response: any = await API.graphql(
                graphqlOperation(createRoleMutation, {
                    input: {
                        roleName: roleName,
                        description: roleDescription,
                        grant: privilege,
                        permission: dataToSend,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = response.data.createRole;

            if (data !== null) {
                setShowModal(true);
            } else {
                if (error.message === 'Role name already exists.') {
                    setError(error.message.toString());
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: error.message,
                        errorCode: error.errorCode,
                        title: 'Cannot Create Roles and Permissions',
                        testId: 'add-roles-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        } catch (error) {}
    };

    const handleRoleDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRoleDescription(event.target.value);
    };

    const handleBranchPrivilege = () => {
        if (privilege !== 'branch') {
            setPrivilege('branch');
        }
    };

    const handleHqPrivilege = () => {
        if (privilege !== 'hq') {
            setPrivilege('hq');
        }
    };

    const handleBack = () => {
        const data = { tab: 'Roles & Permissions' };
        history.push(Routes.dashboardSystemAdmin, data);
    };

    const branchProps = {
        approval: false,
        loginAccess,
        setLoginAccess,
        advisers,
        setAdvisers,
        customerExp,
        setCustomerExp,
    };
    const hqProps = {
        approval: false,
        hqLoginAccess,
        setHqLoginAccess,
        userManagement,
        setUserManagement,
        activityLogs,
        setActivityLogs,
        systemSettings,
        setSystemSettings,
        customerExpDashboard,
        setCustomerExpDashboard,
        branchOffices,
        setBranchOffices,
        eddCase,
        setEddCase,
        financeDashboard,
        setFinanceDashboard,
        financeCases,
        setFinanceCases,
        productSettings,
        setProductSettings,
        fundUploads,
        setFundUploads,
        operationDashBoard: operation,
        setOperationDashBoard: setOperation,
        operationCases,
        setOperationCases,
    };

    const disabled = roleName === '' || status === '' || error !== '';

    const modalIcon = canCreateUserRole === 'auto-authorizer' ? 'user-modal-success' : 'user-modal-processing';
    const modalText =
        canCreateUserRole === 'auto-authorizer'
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_CREATED
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST;
    const modalTextRemarks = rejectRemarks === true ? SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_REJECTED : modalText;
    const approvedModalSub = `${roleName} ${SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_CREATED_SUB}`;
    const modalSubText =
        canCreateUserRole === 'auto-authorizer' ? approvedModalSub : SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
    const modalSubTextRemarks = rejectRemarks === true ? '' : modalSubText;
    const header = SYSTEM_ADMIN.ADD_ROLE.LABEL_ADD_ROLE;
    const subHeader1 = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_1;
    const subHeader2 = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_2;

    return (
        <Container>
            {rejectRemarks === false ? (
                <Fragment>
                    <div>
                        <PreviousPage
                            backIcon={true}
                            title={header}
                            handleBackFunction={handleBack}
                            subTitle={subHeader1}
                            secondSubtitle={subHeader2}
                        />

                        <div style={{ paddingLeft: '3rem' }}>
                            <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DETAILS}</Fs18BoldPrimaryBlack>
                            <CustomSpacer space={'1.5rem'} />
                            <FlexedDiv direction="row">
                                <FlexedDiv direction="column">
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_NAME}
                                    </Fs12BoldPrimaryBlack>
                                    <CustomInput
                                        value={roleName}
                                        onChange={(e) => handleRoleName(e)}
                                        onLostFocus={(e) => handleRoleName(e)}
                                        validation={JSON.stringify({
                                            type: 'alphanumeric',
                                            minChars: 1,
                                            minLength: 3,
                                            maxLength: 30,
                                            required: true,
                                        } as IValidation)}
                                        validationMessage="Role Name"
                                        errorText={error}
                                        canClearContents
                                        handleClearContents={() => setRoleName('')}
                                    />
                                </FlexedDiv>
                                <CustomSpacer horizontal={true} space={'4rem'} />
                                <FlexedDiv direction="column" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_STATUS}
                                    </Fs12BoldPrimaryBlack>
                                    <DropdownBase>
                                        <BasicInputDropdown disabled value={status} />
                                        <IcoMoon name="caret-down" size={'14px'} />
                                    </DropdownBase>
                                </FlexedDiv>
                            </FlexedDiv>
                            <CustomSpacer space={'2rem'} />
                            <TextArea
                                handleInput={handleRoleDescription}
                                label={SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DESCRIPTION}
                                maxLength={255}
                                style={{ width: '784px' }}
                                value={roleDescription}
                            />
                            <CustomSpacer space={'0.5rem'} />
                        </div>
                        <div style={{ backgroundColor: '#EAEBEE', height: '1px' }} />
                        <CustomSpacer space={'2rem'} />
                        <div style={{ paddingLeft: '3rem' }}>
                            <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_GRANT_PRIVILEGES}</Fs18BoldPrimaryBlack>
                            <CustomSpacer space={'1.75rem'} />
                            <FlexedDiv>
                                <RadioButton
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_BACKOFFICE}
                                    selected={privilege === 'branch'}
                                    setSelected={handleBranchPrivilege}
                                />
                                <CustomSpacer horizontal space={'18rem'} />
                                <RadioButton
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_HQ_BACKOFFICE}
                                    selected={privilege === 'hq'}
                                    setSelected={handleHqPrivilege}
                                />
                            </FlexedDiv>
                            {privilege === 'branch' ? (
                                <Fragment>
                                    <CustomSpacer space={'1.75rem'} />
                                    <div style={{ backgroundColor: '#EAEBEE', height: '1px', marginRight: '8.5rem' }} />
                                    <CustomSpacer space={'1.5rem'} />
                                    <BranchPrivileges {...branchProps} />
                                </Fragment>
                            ) : null}
                            {privilege === 'hq' ? (
                                <Fragment>
                                    <CustomSpacer space={'1.75rem'} />
                                    <div style={{ backgroundColor: '#EAEBEE', height: '1px', marginRight: '8.5rem' }} />
                                    <CustomSpacer space={'1.5rem'} />
                                    <HqPrivileges {...hqProps} />
                                </Fragment>
                            ) : null}
                            <Fragment>
                                <CustomSpacer space={'3.5rem'} />
                                <FlexedDiv>
                                    <CustomButton primary={false} onClick={handleCancel} style={{ width: '16.67vw' }}>
                                        <Fs15BoldPrimaryBlack>{SYSTEM_ADMIN.BUTTON_CANCEL}</Fs15BoldPrimaryBlack>
                                    </CustomButton>
                                    <CustomSpacer horizontal={true} space={'1rem'} />
                                    <CustomButton
                                        disabled={disabled}
                                        primary={true}
                                        onClick={handleSave}
                                        style={{ width: '16.67vw', padding: '14px 0' }}
                                    >
                                        <Fs15BoldNeutralWhite>{SYSTEM_ADMIN.BUTTON_SAVE}</Fs15BoldNeutralWhite>
                                    </CustomButton>
                                </FlexedDiv>
                            </Fragment>
                            <CustomSpacer space={'1.5rem'} />
                        </div>
                    </div>
                </Fragment>
            ) : (
                <RejectRemarks
                    remarks={remarks}
                    setRemarks={setRemarks}
                    setShowModal={setShowModal}
                    showModal={showModal}
                />
            )}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTextRemarks}
                    primaryBtn={{
                        onClick: () => {
                            history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="add-roles-modal"
                    icon={modalIcon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubTextRemarks}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    overflow: hidden;
    position: relative;
`;

export const BasicInputDropdown = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 19.25rem;
`;

export const BasicTextArea = styled.textarea`
    height: 7.5rem;
    border-radius: 8px;
    width: 22.5rem;
    border: 1px solid #c9c9cd;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

export default AddRoles;
