import React, { Fragment } from 'react';
import { EMPTY_STATES, LABEL } from '../../constants';
import { subTitleStyle } from '../../styles';
import { statusStyle } from '../../utils';
import * as ROUTES from '../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import { AdvanceTable } from '../../components/organisms';
import { IColumnValue } from './AdviserBranch';
import { DateColumnItem } from '../../templates';
import { CreatedOn } from './CreatedOn';
import { IDropdownWithKey } from '../DashboardHQCE';
import { IAdviserTab, IAdviserPermission } from '../../utils/permissionTypes';
import AuthContext from '../../context/AuthContext';
import { useContext } from 'react';

export interface IAdvisersProps {
    adviserData: ITableData[];
    adviserTab: IAdviserTab | IAdviserPermission;
    createdOnDropdown: IDropdownWithKey;
    filters: IColumnValue[];
    hq: boolean;
    searchEmpty: boolean;
    searchInput: string;
    setCreatedOnDropdown: (value: IDropdownWithKey) => void;
    setFilters: (data: IColumnValue[]) => void;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
    testId?: string;
}

export interface IIconStyle {
    name: string;
    keyName: string;
}

export const Advisers: React.FC<IAdvisersProps> = ({
    adviserData,
    createdOnDropdown,
    filters,
    searchEmpty,
    searchInput,
    setCreatedOnDropdown,
    setFilters,
    setSortData,
    sortData,
    hq,
    testId,
}: IAdvisersProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const handleViewDetails = (item: IColumnItemAccordion) => {
        if (location.pathname === ROUTES.advisers)
            history.push({ pathname: ROUTES.branchAdvisorProfile, state: { details: item, hq: false } });
        else history.push({ pathname: ROUTES.hqAdviserProfile, state: { details: item, hq: true } });
    };

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleLastUpdatedFilter = (item: string, value: string) => {
        const temp = [...filters];
        let currentFilter = 'lastUpdated';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        setCreatedOnDropdown({ value: item, keyName: currentFilter });
        temp[0] = {
            column: currentFilter,
            value: '',
        };
        setSortData([{ column: currentFilter, value: value }]);
        setFilters(temp);
    };

    const handleCreatedOn = (menu: IHeaderMenu) => {
        // eslint-disable-next-line react/prop-types
        return (
            <CreatedOn
                {...menu}
                dropdownData={[
                    // eslint-disable-next-line react/prop-types
                    createdOnDropdown.value,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === createdOnDropdown.keyName ? sortData[0].value : '',
                ]}
                setData={handleLastUpdatedFilter}
            />
        );
    };

    let viewDetails = false;
    const parsedPermission = JSON.parse(userLoginContext.permission);
    viewDetails =
        hq === true
            ? parsedPermission.hq.permission.branchOffices.advisersTab.actions.canViewAdvisersProfile === 'maker'
            : parsedPermission.branch.permission.adviser.advisersTab.actions.canViewAdvisersProfile === 'maker';

    const columns: ITableColumn[] = [
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: '16.67vw',
            },
            onPressHeader: () => handleSort('agentName'),
            title: LABEL.advisorName,
            subtitle: LABEL.code,
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 },
            },
        },
        {
            key: [
                { key: 'bdmName', textStyle: { fontWeight: sortData[0].column === 'bdmName' ? 700 : 400 } },
                { key: 'bdmCode', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '16.1vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'bdmName' ? 700 : 400,
            },
            title: LABEL.bdmName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('bdmName'),
            icon: {
                name: handleSortIcon('bdmName'),
                size: '1rem',
            },
        },
        {
            key: [
                { key: 'uplineName', textStyle: { fontWeight: sortData[0].column === 'uplineCode' ? 700 : 400 } },
                { key: 'uplineCode', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '16.1vw',
            },
            title: LABEL.uplineName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('uplineCode'),
            titleStyle: {
                fontWeight: sortData[0].column === 'uplineCode' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('uplineCode'),
                size: '1rem',
            },
        },
        {
            customItem: true,
            key: [{ key: createdOnDropdown.keyName }],
            viewStyle: {
                width: '12vw',
            },
            title: createdOnDropdown.value,
            icon: {
                name: 'caret-down',
                size: '1rem',
            },
            RenderHeaderMenu: (props: IHeaderMenu) => handleCreatedOn(props),
        },
        {
            key: [{ key: 'status', textStyle: { fontWeight: sortData[0].column === 'status' ? 700 : 400 } }],
            viewStyle: {
                width: '9.652vw',
            },
            title: LABEL.status,
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [],
            viewStyle: {
                width: '4.44vw',
                padding: 0,
                justifyContent: 'center',
            },
            itemIcon: viewDetails
                ? {
                      name: 'eye-show',
                      size: '1.5rem',
                  }
                : undefined,
            title: LABEL.view,
            onClickItem: viewDetails ? (item: IColumnItemAccordion) => handleViewDetails(item) : undefined,
            testId: 'adviser-view-column',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={adviserData}
                columns={columns}
                onEmptyState={EMPTY_STATES.emptyStateAdviserList}
                RenderItem={(props: ITableCustomItem) => (
                    <DateColumnItem isSortedColumn={sortData[0].column} {...props} />
                )}
                isSearchResult={searchEmpty}
                searchInput={searchInput}
                testId={testId}
            />
        </Fragment>
    );
};

export default Advisers;
