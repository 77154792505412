import React from 'react';
import styled from 'styled-components';
import { LABEL } from '../../../constants';
import { FlexedDiv } from '../FlexDiv';
import { TableGroupingByOrder } from './TableGroupingByOrder';

const BranchClassification = (props: IBranchClassification): JSX.Element => {
    return <BranchWrapper>{props.branch}</BranchWrapper>;
};
const FundCutoffClassification = (props: IFundCutOffClassification): JSX.Element => {
    const groupByLabelStyle: React.CSSProperties = {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 400,
        letterSpacing: '-0.33px',
        color: '#002043',
    };
    return (
        <FlexedDiv>
            <div data-testid="group-by" style={{ ...groupByLabelStyle }} id="fund-cutoff-time-label">
                {LABEL.fundCutOffTime}
            </div>
            &nbsp;
            <div style={{ ...groupByLabelStyle, fontWeight: 700 }} id="fund-cutoff-time-value">
                {props.value}
            </div>
        </FlexedDiv>
    );
};

/**
 *
 * @param props - {@link ITableGroupingProps props required to complete grouping component based on type of grouping}
 * @returns - UI component for grouping in a table based on type of grouping
 */
export const TableGroupingTemplates = (props: ITableGroupingProps): JSX.Element => {
    let template: JSX.Element = <></>;
    switch (props.type) {
        case 'branchName':
            template = <BranchClassification {...(props.data as IBranchClassification)} />;
            break;
        case 'cutOffTime':
            template = <FundCutoffClassification {...(props.data as IFundCutOffClassification)} />;
            break;
        case 'orderNo':
            template = <TableGroupingByOrder {...(props.data as IOrderClassification)} />;
            break;
    }
    return <div style={{ ...props.style }}>{template}</div>;
};

const BranchWrapper = styled.div`
    background: #ececec;
    border-left: 4px solid #5a204a;
    padding: 6px 36px;
    color: #171717;
    font-size: 10px;
    line-height: 12px;
`;
