import gql from 'graphql-tag';

/**
 * @createUser @mutation System admin Dashboard
 * @description mutation for creating new user
 */

export const AdviserAccessExportList = gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    systemadminadviserdashboard {
                        branch
                        advisers {
                            agentId
                            agentName
                            bdmCode
                            bdmName
                            channel
                            createdOn
                            status
                            omniEnabled
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
