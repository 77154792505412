import React from 'react';
import styled from 'styled-components';

interface flexDiv extends React.HTMLAttributes<HTMLDivElement> {
    direction?: React.CSSProperties['flexDirection'];
    justifyContent?: React.CSSProperties['justifyContent'];
    alignItems?: React.CSSProperties['alignItems'];
    grow?: React.CSSProperties['flexGrow'];
    wrap?: React.CSSProperties['flexWrap'];
    margin?: React.CSSProperties['margin'];
    padding?: React.CSSProperties['padding'];
}
const FlexDiv = styled.div`
    display: flex;
    flex-direction: ${(p: flexDiv) => p.direction};
    align-items: ${(p: flexDiv) => p.alignItems};
    justify-content: ${(p: flexDiv) => p.justifyContent};
    flex-grow: ${(p: flexDiv) => p.grow};
    flex-wrap: ${(p: flexDiv) => p.wrap};
    margin: ${(p: flexDiv) => p.margin};
    padding: ${(p: flexDiv) => p.padding};
`;

export const FlexedDiv = (props: flexDiv): JSX.Element => {
    return <FlexDiv {...props} />;
};
