import React from 'react';
import { IcoMoon } from '../icons';
import styled from 'styled-components';
import { CustomSpacer, FlexedDiv } from '../components';
import { TextNavyBlue } from '../constants/styles/text';
import { LABEL } from '../constants/labels';

declare interface IEmptyStateProps {
    iconName: string;
    searchInput?: string;
    subtitle?: string;
    title?: string;
}

export const EmptyState = ({ searchInput }: IEmptyStateProps): JSX.Element => {
    return (
        <StyledDiv>
            <IcoMoon name={'empty-results'} size={'9rem'} />
            <CustomSpacer space={'1rem'} />
            <TextNavyBlue weight="700">{LABEL.noResultsFound}</TextNavyBlue>
            {searchInput !== '' ? (
                <FlexedDiv direction="row">
                    <TextNavyBlue size="12px">{LABEL.searchEmptyWithInput}</TextNavyBlue>
                    <CustomSpacer horizontal={true} space={'.25rem'} />
                    <TextNavyBlue size="12px" weight="bold">{` '${searchInput}'`}</TextNavyBlue>
                </FlexedDiv>
            ) : (
                <TextNavyBlue size="12px">{LABEL.searchEmptyWithoutInput}</TextNavyBlue>
            )}
            <CustomSpacer space={'1rem'} />
            <TextNavyBlue size="12px">{LABEL.tryAdjustingFilter}</TextNavyBlue>
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    height: 55vh;
    align-items: center;
    flex-direction: column;
`;
