import React from 'react';
import { Responses } from './Responses';
import { AssignedAdviser } from './AssignedAdviser';
import { CaseSummary } from './CaseSummary';
import { useHistory } from 'react-router-dom';
import { Tabs } from '../../../../components/organisms';
import { LABEL } from '../../../../constants';
import EDDContext from '../../../../context/EDDContext/EDDContext';
import { ComponentLoader } from '../../../../components';

export const ReroutedUnsatisfactory = (): JSX.Element => {
    const { caseDetails, getCaseDetails, loading } = React.useContext(EDDContext);

    const history = useHistory();

    const tabs = [<Responses key={1} />, <CaseSummary key={2} />, <AssignedAdviser key={3} />];

    const handleBack = () => {
        // history.push(ROUTES.reroutedEddCaseSatisfactory);
        history.goBack();
        // history.push(ROUTES.amlaEDDManagement, { currentTab: 'Rerouted' });
    };

    React.useEffect(() => {
        getCaseDetails(0);
    }, []);

    return (
        <React.Fragment>
            <div style={{ position: 'relative', maxWidth: '1152px' }}>
                <Tabs
                    tabTitles={[LABEL.responses, LABEL.caseSummary, LABEL.assignedAdviser]}
                    title={caseDetails.name}
                    tabs={tabs}
                    suffixHelper={caseDetails.status}
                    handleBackFunction={handleBack}
                />
                {loading ? <ComponentLoader /> : null}
            </div>
        </React.Fragment>
    );
};
