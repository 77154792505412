/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { useComponentLoader } from '../../../customHooks';
import { IDropdownWithKey } from '../../../pages';
import { API, graphqlOperation } from 'aws-amplify';
import AuthContext from '../../AuthContext';
import AdvisersContext, { AdvisersContextProps } from './AdvisersContext';
import { adviserDashboardQuery } from '../../../_graphql/queries/branchCE/adviserDashboard/adviserDashboard';
import { formatTextCase } from '../../../utils';
import ErrorHandlingContext from '../../ErrorHandling/ErrorHandlingContext';
import BranchOfficeContext from '../../HQCEContext/branchOffice';

export interface AdvisersProviderProps {
    children: React.ReactNode;
}

const initialAdviserFilters = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];
export const AdvisersProvider: React.FC<AdvisersProviderProps> = ({ children }: AdvisersProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { branchesId } = useContext(BranchOfficeContext);
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [adviserCount, setAdviserCount] = useState<number>(0);
    const [adviserData, setAdviserData] = useState<ITableData[]>([]);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'agentName', value: 'ascending' }]);
    const [tempFilters, setTempFilters] = useState<IColumnValue[]>(initialAdviserFilters);
    const [filters, setFilters] = useState<IColumnValue[]>(initialAdviserFilters);
    const [dateSorting, setDateSorting] = useState<string>('createdOn');
    const [status, setStatus] = useState<string>('All');
    const [targetDateRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [createdOnDropdown, setCreatedOnDropdown] = useState<IDropdownWithKey>({
        value: 'Created On',
        keyName: 'createdOn',
    });
    //loader
    const { loading, loadingHandler } = useComponentLoader();
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const getAdviserBranchData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(adviserDashboardQuery, {
                    input: {
                        page: currentPage,
                        resultLimit: resultLimit,
                        branchId: branchesId !== undefined && branchesId !== '' ? branchesId : '',
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: [...filters],
                    },
                }),
                idTokenHeader,
            );

            const { advisers, adviserCount, page, pages } = await response.data.adviserDashboard.data.result;

            const formattedResult = formatTextCase(advisers, ['uplineName']);
            setAdviserData(formattedResult);
            setAdviserCount(adviserCount);
            setCurrentPage(page);
            setMaxPages(pages);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Dashboard Data',
                testId: 'adviserbranch-modal',
            });
        }
    };
    const ProviderValue: AdvisersContextProps = {
        adviserCount,
        adviserData,
        createdOnDropdown,
        currentPage,
        dateSorting,
        filters,
        getAdviserBranchData,
        loading,
        loadingHandler,
        maxPages,
        resultLimit,
        searchInput,
        setAdviserCount,
        setAdviserData,
        setCreatedOnDropdown,
        setCurrentPage,
        setDateSorting,
        setFilters,
        setMaxPages,
        setResultLimit,
        setSearchInput,
        setSortData,
        setStatus,
        setTab,
        setTargetRange,
        setTempFilters,
        sortData,
        status,
        tab,
        targetDateRange,
        tempFilters,
    };
    return <AdvisersContext.Provider value={ProviderValue}>{children}</AdvisersContext.Provider>;
};
