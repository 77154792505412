/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, Fragment, useRef, useContext } from 'react';
import { LABEL } from '../../constants';
import { Notifications } from './Notifications';
import { SearchBar, Dashboard } from '../../components/organisms';
import { API, graphqlOperation } from 'aws-amplify';
import { IcoMoon } from '../../icons';
import { useDebounce } from '../../customHooks';
import { IFilterBranch } from '../DashboardBranch/dashboardBranchTypes';
import { EmptyState } from '../../templates/EmptyState';
import { IAccountManagement } from '../../utils/permissionTypes';
import { updateSeen } from '../../_graphql/mutations/updateSeen/updateSeen';
import { ComponentLoader } from '../../components';
import { updateInbox } from '../../_graphql/mutations/common/inbox/updateInbox';
import { updateIsSeenDashboard } from '../../utils';

import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import InboxContext from '../../context/InboxContext/InboxContext';

const initialSearchState: IFilterBranch = {
    value: '',
    column: 'all',
};
export const Inbox: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const {
        getInboxData,
        getSource,
        loading,
        message,
        newMessageCount,
        page,
        pages,
        resultLimit,
        searchInput,
        setPage,
        setResultLimit,
        setSearchInput,
        setTab,
        setUnreadIds,
        tab,
        unreadIds,
    } = useContext(InboxContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const debouncedSearchTerm = useDebounce(searchInput, 700);
    const initialRenderInbox = useRef(true);
    const initialRenderPage = useRef(true);

    const sameFilter = JSON.stringify(initialSearchState) !== JSON.stringify(searchInput);

    const searchOrFiltered = sameFilter || searchInput.value !== '';

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: IAccountManagement =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.accountManagement
            : parsedPermission.hq.permission.accountManagement;
    const { inbox } = parsedPermissionPrivilege;

    /**@mutation */
    const updateRead = async (notificationIds: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(updateSeen, {
                    input: {
                        dashboard: 'inboxDashboard',
                        tab: ['notification'],
                        referenceKey: notificationIds,
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.updateSeen;
            if (resultCheck.error !== null) throw resultCheck.error;
        } catch (error) {}
    };
    const updateAllRead = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(updateInbox, {
                    input: {
                        source: getSource(),
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.updateInbox;
            if (resultCheck.error !== null) throw resultCheck.error;
            setUnreadIds([]);
            getInboxData();
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Mark All as Read',
                testId: 'inbox-error-modal',
            });
            handleErrorHandler();
        }
    };

    const inboxContainer = (
        <InboxContainer>
            {message.length !== 0 ? (
                <Fragment>
                    <LinkBtn
                        disabled={unreadIds.length === 0}
                        onClick={() => handleMarkAllRead()}
                        id="inbox-mark-all-read-btn"
                    >
                        {LABEL.readALL + `${' '}` + `(${newMessageCount})`}
                    </LinkBtn>
                    {message.map((messageItem, index) => {
                        return (
                            <Notifications
                                key={index}
                                notification={messageItem}
                                updateRead={updateRead}
                                testId={index + 1}
                            />
                        );
                    })}
                </Fragment>
            ) : searchOrFiltered ? (
                <EmptyState iconName={'search'} searchInput={searchInput.value} />
            ) : (
                <div className="empty_table" data-testid="empty_table">
                    <IcoMoon name="empty_orders" size="9.5rem" />
                    <p style={{ fontSize: '1rem', fontWeight: 700, marginTop: '2.5rem', color: '#c6cbd4' }}>
                        {`No notifications yet.`}
                    </p>
                    <p style={{ fontSize: '0.875rem', color: '#c6cbd4', textAlign: 'center' }}>
                        {`You’re all caught up! Check back later for new notifications.`}
                    </p>
                </div>
            )}
        </InboxContainer>
    );
    const tabs: IDashboardTabs[] =
        inbox.isAll || inbox.actions.canReadNotifications === LABEL.maker
            ? [
                  {
                      name: 'Notifications',
                      table: inboxContainer,
                      testId: 'notifications-tab',
                  },
              ]
            : [];
    // Fn to clear inbox context
    const clearContext = () => {
        setSearchInput({ value: '', column: 'all' });
        setPage(1);
        setTab(0);
        setResultLimit(10);
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };

    const handleNext = () => {
        if (page < pages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleMarkAllRead = () => {
        updateAllRead();
    };
    /** * @start */
    useEffect(() => {
        if (initialRenderPage.current) {
            initialRenderPage.current = false;
        } else {
            getInboxData();
        }
    }, [page, resultLimit, pages, debouncedSearchTerm.value]);
    useEffect(() => {
        if (initialRenderInbox.current) {
            getInboxData();
            initialRenderInbox.current = false;
        }
        return function cleanup() {
            updateIsSeenDashboard('inboxDashboard', ['notification'], idTokenHeader);
            clearContext();
        };
    }, []);
    return (
        <div>
            <SearchBar
                placeHolder={LABEL.searchPlaceholderInbox}
                filter={false}
                searchTitle={LABEL.inbox}
                searchInput={searchInput}
                onSearch={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                testId="inbox"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                isSimpleSearch
            />
            <div style={{ position: 'relative' }}>
                <Dashboard
                    tabs={tabs}
                    selectedTab={tab}
                    orderCounter={[newMessageCount]}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    page={page}
                    maxPage={pages}
                    setResultLimit={setResultLimit}
                    testId="inbox"
                    setCurrentPage={setPage}
                    resultLimit={resultLimit}
                    disableResultLimit={message.length === 0}
                />
                {loading ? <ComponentLoader /> : null}
            </div>
        </div>
    );
};

const InboxContainer = styled.div`
    width: 100%;
    min-height: 62.3vh;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
`;
const LinkBtn = styled.button`
    display: inline-block;
    position: absolute;
    right: 1.5rem;
    top: 0;
    font-weight: 700;
    font-size: 0.75rem;
    border: none;
    background-color: transparent;
    outline: none;
    color: #002043;
    line-height: 0.85rem;
    &:hover {
        color: #c61230;
    }
    &:disabled {
        opacity: 0.5;
        &:hover {
            color: #002043;
            cursor: default;
        }
    }
`;

export default Inbox;
