import { DocumentNode } from 'graphql';
import { branchDashboardExportListQueryCreatedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListCreatedOn';
import { branchDashboardExportListQueryOthersCreatedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListCreatedOnOthers';
import { branchDashboardExportListQueryLastUpdated } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListLastUpdated';
import { branchDashboardExportListQueryOthersLastUpdated } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListLastUpdatedOthers';
import { branchDashboardExportListQuerySubmittedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListSubmittedOn';
import { branchDashboardExportListQueryOthersSubmittedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListSubmittedOnOthers';

export const exportListQueryPicker = (dateType: string, isDaily: boolean): DocumentNode => {
    let temp = branchDashboardExportListQueryLastUpdated;
    switch (dateType.toLowerCase()) {
        case 'created on':
            temp = isDaily ? branchDashboardExportListQueryCreatedOn : branchDashboardExportListQueryOthersCreatedOn;
            break;
        case 'submitted on':
            temp = isDaily
                ? branchDashboardExportListQuerySubmittedOn
                : branchDashboardExportListQueryOthersSubmittedOn;
            break;
        default:
            temp = isDaily
                ? branchDashboardExportListQueryLastUpdated
                : branchDashboardExportListQueryOthersLastUpdated;
            break;
    }
    return temp;
};
