/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { fundDataDashboardQuery } from '../../_graphql/queries/fundData/productSettingsDashbaord/fundDataDashboard';
import { useDebounce } from '../../customHooks';
import { buildSearchFiltersConfig } from '../../pages/ProductSettings/searchFilterConfig';
import { toTitleCase } from '../../utils';
import ProductSettingsContext from './';
import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

export interface ProductSettingsProviderProps {
    children: React.ReactNode;
}

const initialColumnFilters: IProductSettingsColumnFilters = {
    fundStatus: { column: 'fundStatus', value: '', columnLabel: 'Status' },
    lastUpdated: { column: 'lastUpdated', value: '', columnLabel: 'Last Updated' },
    fundCurrency: { column: 'fundCurrency', value: '', columnLabel: 'Currency' },
    isSyariah: { column: 'isSyariah', value: '', columnLabel: 'Type' },
    fundType: { column: 'fundType', value: '', columnLabel: 'Type' },
    fundCategory: { column: 'fundCategory', value: '', columnLabel: 'AMP Category' },
    riskCategory: { column: 'riskCategory', value: '', columnLabel: 'Risk Category' },
};

type Filters = {
    [key: string]: string[];
};
export const ProductSettingsProvider: React.FC<ProductSettingsProviderProps> = ({
    children,
}: ProductSettingsProviderProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [orderCounter, setOrderCounter] = useState<Array<number>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // Dashboard tab data states
    const [allFundsData, setAllFundsData] = useState<ITableData[]>([]);
    const [allAMPData, setAllAMPData] = useState<ITableData[]>([]);
    const [allNAVData, setAllNAVData] = useState<ITableData[]>([]);
    const [allDistributionData, setAllDistributionData] = useState<ITableData[]>([]);
    const [allDocumentsData, setAllDocumentsData] = useState<ITableData[]>([]);
    const [filterData, setFilterData] = useState<IProductSettingsFilterData>();
    // Filter & sorting states
    const [dropDownFilters, setDropDownFilters] = useState<IDropDownFilters[][]>([]);
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [tableFilterQuery, setTableFilterQuery] = useState<IColumnValue[]>([]);
    const [columnFilterValues, setColumnFilterValues] = useState<IProductSettingsColumnFilters>(initialColumnFilters);
    const [sortData, setSortData] = useState<ISortSettingValues>({ column: 'fundAbbr', value: 'Ascending' });
    // utility states

    const initialRender = useRef<boolean>(true);

    // custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };
    // Text transform filter values
    const stylizeText = (filters: Filters): Filters => {
        const _filters: Filters = { ...filters };

        Object.keys(_filters).map((filterKey) => {
            switch (filterKey) {
                case 'fundStatus':
                case 'fundCategory':
                case 'riskCategory':
                    if (_filters[filterKey]) {
                        const _newArrary = _filters[filterKey].map((value) => toTitleCase(value));
                        _filters[filterKey] = _newArrary;
                    }

                    break;
            }
        });

        return _filters;
    };
    //call query to get all funds
    const getAllFundsData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundDataDashboardQuery, {
                    input: {
                        tab: 'allfunds',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDataDashboard;

            if (!error) {
                const { transactions, allFundsCount, ampFundCount, pages, filters } = data.result;

                const tempTabCount = [allFundsCount, ampFundCount];
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters, 'allFunds');
                    setDropDownFilters(formElements);
                }
                setAllFundsData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setFilterData(_filters);
                setOrderCounter(tempTabCount);
                setMaxPages(pages);
                loadingHandler();
            } else {
                loadingHandler();
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch All Funds Data',
                    testId: 'productsettings-error-modal',
                });
            }
        } catch (error) {
            loadingHandler();
        }
    };
    //call query to get all AMP
    const getAllAMPData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundDataDashboardQuery, {
                    input: {
                        tab: 'allamp',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDataDashboard;

            if (!error) {
                const { pages, transactions, filters } = data.result;

                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters, 'amp');
                    setDropDownFilters(formElements);
                }

                setFilterData(_filters);
                setAllAMPData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                loadingHandler();
            } else {
                loadingHandler();
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch All AMP Data',
                    testId: 'productsettings-error-modal',
                });
            }
        } catch (error) {
            loadingHandler();
        }
    };
    //call query to get all NAV
    const getAllNAVData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundDataDashboardQuery, {
                    input: {
                        tab: 'navperunit',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDataDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(filters, 'navDistribution');
                    setDropDownFilters(formElements);
                }

                setAllNAVData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                loadingHandler();
            } else {
                loadingHandler();
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch NAV Data',
                    testId: 'productsettings-error-modal',
                });
            }
        } catch (error) {
            loadingHandler();
        }
    };
    //call query to get all Distribution
    const getAllDistributionData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundDataDashboardQuery, {
                    input: {
                        tab: 'distribution',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDataDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(filters, 'navDistribution');
                    setDropDownFilters(formElements);
                }
                setMaxPages(pages);
                setAllDistributionData(transactions);
                setDisableResultLimit(transactions.length === 0);
                loadingHandler();
            } else {
                loadingHandler();
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch Distribution Data',
                    testId: 'productsettings-error-modal',
                });
            }
        } catch (error) {
            loadingHandler();
        }
    };
    //call query to get all Documents
    const getAllDocumentsData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundDataDashboardQuery, {
                    input: {
                        tab: 'documents',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDataDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(filters, 'documentsConfig');
                    setDropDownFilters(formElements);
                }

                setMaxPages(pages);
                setAllDocumentsData(transactions);
                setDisableResultLimit(transactions.length === 0);
                loadingHandler();
            } else {
                loadingHandler();
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: error.message,
                    errorCode: error.errorCode,
                    title: 'Cannot Fetch Documents Data',
                    testId: 'productsettings-error-modal',
                });
            }
        } catch (error) {
            loadingHandler();
        }
    };
    //Fn to handle column filtering
    const handleColumnFilterChange = (value: string, elemName: string, columnLabel: string) => {
        const _tempArr = [...tableFilterQuery];
        const _tempLabels = { ...columnFilterValues };
        if (_tempArr.find((obj) => obj.column === elemName)) {
            const updated = _tempArr.find((obj) => obj.column === elemName);
            updated ? (updated.value = value) : '';
            if (elemName === 'lastUpdated') {
                _tempLabels[elemName].value = value;
                _tempLabels[elemName].columnLabel = columnLabel;
            } else {
                if (value !== '') {
                    _tempLabels[elemName].columnLabel = value;
                    _tempLabels[elemName].value = value;
                } else {
                    _tempLabels[elemName].columnLabel = columnLabel;
                    _tempLabels[elemName].value = value;
                }
            }
        } else {
            _tempArr.push({ column: elemName, value: value });

            Object.keys(_tempLabels).map((key: string) => {
                if (key == elemName) {
                    if (elemName == 'lastUpdated') {
                        _tempLabels[elemName].value = value;
                        _tempLabels[elemName].columnLabel = columnLabel;
                    } else {
                        _tempLabels[elemName].value = value;
                        _tempLabels[elemName].columnLabel = value;
                    }
                }
            });
        }

        setColumnFilterValues(_tempLabels);
        setTableFilterQuery(_tempArr);
    };

    //Fn to reset column filters during tab change
    const resetColumnFilters = () => {
        const _initialColumnFilters: IProductSettingsColumnFilters = {
            fundStatus: { column: 'fundStatus', value: '', columnLabel: 'Status' },
            lastUpdated: { column: 'lastUpdated', value: '', columnLabel: 'Last Updated' },
            fundCurrency: { column: 'fundCurrency', value: '', columnLabel: 'Currency' },
            isSyariah: { column: 'isSyariah', value: '', columnLabel: 'Type' },
            fundType: { column: 'fundType', value: '', columnLabel: 'Type' },
            fundCategory: { column: 'fundCategory', value: '', columnLabel: 'AMP Category' },
            riskCategory: { column: 'riskCategory', value: '', columnLabel: 'Risk Category' },
        };
        const _columnFilters = { ...columnFilterValues };

        Object.entries(_columnFilters).map(([key]) => {
            _columnFilters[key].value = _initialColumnFilters[key].value;
            _columnFilters[key].columnLabel = _initialColumnFilters[key].columnLabel;
        });
        setColumnFilterValues(_columnFilters);
    };

    const ProviderValue: IProductSettingsContext = {
        tab,
        resultLimit,
        orderCounter,
        currentPage,
        maxPages,
        dropDownFilters,
        tableFilterQuery,
        columnFilterValues,
        sortData,
        allFundsData,
        allAMPData,
        allNAVData,
        allDistributionData,
        allDocumentsData,
        filterData,
        searchInput,
        initialRender,
        loading,
        debouncedSearchTerm,
        disableResultLimit,
        setTab,
        setSearchInput,
        setResultLimit,
        setCurrentPage,
        setDropDownFilters,
        setTableFilterQuery,
        setSortData,
        handleColumnFilterChange,
        getAllFundsData,
        getAllAMPData,
        getAllNAVData,
        getAllDistributionData,
        getAllDocumentsData,
        resetColumnFilters,
    };

    return <ProductSettingsContext.Provider value={ProviderValue}>{children}</ProductSettingsContext.Provider>;
};
