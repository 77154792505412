/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import styled from 'styled-components';

export const Fs10RegNeutralGrey4 = styled.text`
    font-size: 10px;
    line-height: 16px;
    color: #a3a3a3;
`;

export const Fs10RegNeutralBlack = styled.text`
    font-size: 10px;
    line-height: 16px;
    color: #333333;
`;

export const Fs10RegDarkGrey = styled.text`
    font-size: 10px;
    line-height: 120%;
    color: #878787;
`;

export const Fs12RegLightBlue8 = styled.text`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.333333px;
    color: rgba(42, 54, 90, 0.8);
`;

export const Fs12BoldPrimaryBlack5 = styled.text`
    font-weight: 700;
    letter-spacing: -0.333333px;
    font-size: 12px;
    line-height: 16px;
    color: #002043;
    opacity: 0.5;
`;

export const Fs12BoldPrimaryBlack = styled.text`
    font-weight: 700;
    letter-spacing: -0.333333px;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
`;

export const Fs12BoldPrimaryBlue = styled.text`
    font-weight: 700;
    letter-spacing: -0.444444px;
    font-size: 12px;
    line-height: 16px;
    color: #0089ec;
`;

export const Fs12RegPrimaryBlack = styled.text`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
`;
export const Fs12RegSecBlack = styled.text`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
`;

export const Fs12RegSecNavyBlue = styled.text`
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #002043;
`;
export const Fs12RegSecNavyBlueLh16 = styled.text`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #002043;
`;

export const Fs12RegBlack = styled.text`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000;
`;

export const Fs15BoldPrimaryBlack = styled.text`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
`;

interface TextStyleProps extends React.CSSProperties {
    size?: string;
    weight?: string;
}
//White
export const TextWhite = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    line-height: ${(props: TextStyleProps) => props.lineHeight ?? 'inherit'};
    color: #fff;
`;
//Dark
export const TextDarkBlack = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    color: #171717;
`;
export const TextPureBlack = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    line-height: 24px;
    color: #000000;
`;
//Grey
export const TextDarkGrey4 = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    color: #878787;
`;
export const TextDarkGrey5 = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    color: #4d4d4d;
`;
export const TextDarkGrey6 = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    color: #333333;
`;
//Secondary
export const TextNavyBlue = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    color: #002043;
`;
//Accent
export const TextBlue6 = styled.div<TextStyleProps>`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: ${(props) => (props.size ? props.size : '16px')};
    color: #4d637b;
`;
export const Fs15BoldNeutralWhite = styled.text`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
`;

export const Fs16BoldSecondaryNavy = styled.text`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #002043;
`;

export const Fs16RegSecNavyBlue = styled.text`
    font-size: 16px;
    line-height: 24px;
    color: #002043;
`;

export const Fs16RegNeutralBlack = styled.text`
    font-size: 16px;
    line-height: 24px;
    color: #000000;
`;

export const Fs16SemiBoldSecPrimaryBlack = styled.text`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.05px;
    color: #333333;
`;

export const Fs16RegSecPrimaryBlack = styled.text`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.05px;
    color: #333333;
`;

export const Fs18BoldPrimaryBlack = styled.text`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #333333;
`;

export const Fs18BoldDarkGrey = styled.text`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #171717;
`;
export const Fs20BoldPrimaryBlack = styled.text`
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    color: #333333;
`;

export const Fs24BoldPrimaryBlack = styled.text`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #333333;
`;

export const Fs24BoldSecNavyBlue = styled.text`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #002043;
`;
export const Fs24BoldSecNavyBlueModalTitle = styled.text`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #002043;
    text-align: center;
`;

export const Fs24RegSecNavyBlue = styled.text`
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #002043;
`;
export const InputValidationErrorText = styled.text`
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.2px;
    margin-left: 0.5rem;
    color: #e84c3d;
`;
export const ModalSubtitleContainer = styled.div`
    padding: 0rem 2rem;
    text-align: center;
`;
