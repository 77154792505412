import gql from 'graphql-tag';

export const AgentProfileQuery = /* GraphQL */ gql`
    query agentProfile($input: AgentProfileInput) {
        agentProfile(input: $input) {
            data {
                result {
                    userId
                    nric
                    personalDetails {
                        licenseCode
                        homeBranch
                        omniEnabled
                        bdmName
                        bdmCode
                        bdmEmail
                        uplineName
                        uplineCode
                        region
                        adviserChannel
                        adviserRank
                        agency
                    }
                    contactDetails {
                        email
                        mobileNo
                    }
                    addressInfo {
                        address
                        postcode
                        addrTown
                        state
                        country
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
            }
        }
    }
`;
