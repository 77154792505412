/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, FunctionComponent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { dueDateExtensionMutation } from '../_graphql/mutations/hq/dueDateExtension/dueDateExtension';

import { CustomButton, CustomSpacer, FlexedDiv, TextArea, Modal } from '../components';
import {
    Fs15BoldNeutralWhite,
    Fs15BoldPrimaryBlack,
    Fs16SemiBoldSecPrimaryBlack,
    Fs24BoldPrimaryBlack,
    SYSTEM_ADMIN,
    LABEL,
} from '../constants';
import * as Routes from '../routes';
import { LocationState } from '../pages/ExtendTargetDate/ExtendTargetDate';

import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import ErrorHandlingContext from '../context/ErrorHandling/ErrorHandlingContext';

export interface RejectRemarksProps {
    locationState?: LocationState;
}

export const RejectRemarks: FunctionComponent<RejectRemarksProps> = ({ locationState }: RejectRemarksProps) => {
    const [remarks, setRemarks] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const location = useLocation();
    const state: any = location.state;
    const locationData: LocationState = state;
    const tableData = locationState !== undefined ? locationState.item : locationData.item;
    const handleRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const handleSubmit = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(dueDateExtensionMutation, {
                    input: {
                        requestId: tableData.requestId,
                        action: 'Reject',
                        remark: remarks,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.targetDateResponse.data !== null) {
                setShowModal(true);
            } else {
                throw response.data.targetDateResponse.error.message;
            }
        } catch (error) {
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Review Request',
                testId: 'branch-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleCancel = () => {
        history.push(Routes.dashboard);
    };

    const modalTitle = LABEL.dueDateExtensionRejectedTitle;
    const modalSubtitle = LABEL.dueDateExtensionRejectedSubtitle;
    return (
        <Fragment>
            <FlexedDiv direction="row">
                <CustomSpacer horizontal={true} space={'2.778vw'} />
                <FlexedDiv direction="column">
                    <Fs24BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS}</Fs24BoldPrimaryBlack>
                    <CustomSpacer space={'0.5rem'} />
                    <Fs16SemiBoldSecPrimaryBlack>
                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS_SUB}
                    </Fs16SemiBoldSecPrimaryBlack>
                    <CustomSpacer space={'2rem'} />
                    <TextArea
                        name="rejectRemark"
                        label={LABEL.remarks}
                        value={remarks}
                        handleInput={handleRemarks}
                        maxLength={255}
                        style={{ width: '38.89vw' }}
                        testId="reject-remarks-textarea"
                    />
                    <CustomSpacer space={'3.5rem'} />
                    <FlexedDiv>
                        <CustomButton
                            primary={false}
                            onClick={handleCancel}
                            style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                            id="reject-remarks-cancel-btn"
                        >
                            <Fs15BoldPrimaryBlack>{SYSTEM_ADMIN.BUTTON_CANCEL}</Fs15BoldPrimaryBlack>
                        </CustomButton>
                        <CustomSpacer horizontal={true} space={'1rem'} />
                        <CustomButton
                            disabled={remarks.trim().length === 0}
                            primary={true}
                            onClick={handleSubmit}
                            style={{ width: '16.667vw' }}
                            id="reject-remarks-submit-btn"
                        >
                            <Fs15BoldNeutralWhite>{LABEL.submit}</Fs15BoldNeutralWhite>
                        </CustomButton>
                    </FlexedDiv>
                </FlexedDiv>
            </FlexedDiv>

            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTitle}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.dashboard);
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    icon="targetdate-modal"
                    contentAlignment="center"
                    testId="reject-remarks-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubtitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
