/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import { fundSummaryQuery } from '../../../_graphql/queries/fundData/summary/summary';
import { Tabs } from '../../../components/organisms/Tabs/Tabs';
import { FundDetails } from '../AllFunds';
import { AmpDetails } from '../Amp';
import { NavUnitHistoryDetails } from '../NavPerUnit';
import { GrossHistoryDetails, UnitHistoryDetails, NetHistoryDetails } from '../Distribution';
import { handleFundSummary, handleAmpSummary } from '../../../utils';

import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

interface IDetailsPageProps {
    type: string;
    code: number;
    name?: string;
    tabIndex: number;
}

const DetailsPage: React.FC = () => {
    const history = useHistory();
    const [allFundsSummary, setAllFundsSummary] = useState<IFundDetails>();
    const { state: propsData } = useLocation<IDetailsPageProps>();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //methond to fetch data only for all funds or all AMP
    const fetchData = () => {
        if (propsData.type) {
            switch (propsData.type) {
                case 'allfunds':
                case 'allamp':
                    getFundSummary();
                    break;
                default:
            }
        }
    };
    //call query to get fund summary
    const getFundSummary = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundSummaryQuery, {
                    input: {
                        fundId: propsData.code,
                        tab: propsData.type,
                    },
                }),
                idTokenHeader,
            );

            const _payload: IFundSummaryPayload = await response.data.fundSummary.data.result;

            if (_payload) {
                const fundSummary =
                    propsData.type === 'allfunds' ? handleFundSummary(_payload) : handleAmpSummary(_payload);

                setAllFundsSummary(fundSummary);
            }
        } catch (error) {
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Fetch Fund Data',
                testId: 'fundSummary-error-modal',
            });
        }
    };
    // only all funds, AMP and filter values are queried at this level
    useEffect(() => {
        fetchData();
    }, []);

    let tabs: JSX.Element[] = [];
    let tabTitles: string[] = [];

    //switch method to assign correct tabs
    switch (propsData.type) {
        case 'allfunds':
            if (typeof allFundsSummary !== 'undefined') {
                tabs = [<FundDetails tabFundData={allFundsSummary} key={1} />];
                tabTitles = ['Details'];
            }

            break;
        case 'allamp':
            if (typeof allFundsSummary !== 'undefined') {
                tabs = [<AmpDetails ampTabData={allFundsSummary} key={1} />];
                tabTitles = ['Details'];
            }
            break;
        case 'NAV per unit History':
            tabs = [
                <NavUnitHistoryDetails
                    fundId={propsData.code}
                    fundName={propsData.name !== undefined ? propsData.name : ''}
                    key={1}
                />,
            ];
            tabTitles = ['NAV per unit'];
            break;
        case 'Distribution':
            tabs = [
                <GrossHistoryDetails
                    fundId={propsData.code}
                    fundName={propsData.name !== undefined ? propsData.name : ''}
                    key={1}
                />,
                <NetHistoryDetails
                    fundId={propsData.code}
                    fundName={propsData.name !== undefined ? propsData.name : ''}
                    key={2}
                />,
                <UnitHistoryDetails
                    fundId={propsData.code}
                    fundName={propsData.name !== undefined ? propsData.name : ''}
                    key={3}
                />,
            ];
            tabTitles = ['Gross Distribution', 'Net Distribution', 'Unit Split / Cancellation'];
            break;
        default:
    }

    const goBacktoDashboard = () => {
        history.push({
            pathname: Routes.financeProductSetingsDashboard,
            state: {
                activeTab: propsData.tabIndex,
            },
        });
    };
    return (
        <Fragment>
            <Tabs
                tabTitles={tabTitles}
                title={propsData.type}
                customTitle={allFundsSummary && allFundsSummary.fundName}
                tabs={tabs}
                testId="productsettings-details"
                handleBackFunction={goBacktoDashboard}
            />
        </Fragment>
    );
};

export default DetailsPage;
