/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { allFundDocumentDashboardQuery } from '../../_graphql/queries/fundData/documentDashboard/allFundDocument';
import { buildSearchFiltersConfig } from '../../pages/Upload';
import { useDebounce } from '../../customHooks';
import { toTitleCase } from '../../utils';

import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import UploadsContext from './';
import fundCheckerApproveMutation from '../../_graphql/mutations/fundData/checkerApprove';
import bulkUploadStatusMutation from '../../_graphql/mutations/fundData/bulkUploadStatus';

export interface UploadsProviderProps {
    children: React.ReactNode;
}
const initialColumnFilters = {
    status: { column: 'status', value: '', columnLabel: 'Status' },
    fileType: { column: 'fileType', value: '', columnLabel: 'Type' },
};
type Filters = {
    [key: string]: string[];
};
export const UploadsProvider: React.FC<UploadsProviderProps> = ({ children }: UploadsProviderProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // Dashboard tab data states
    const [masterFundData, setMasterFundData] = useState<ITableData[]>([]);
    const [navPerUnitData, setNavPerUnitData] = useState<ITableData[]>([]);
    const [distributionData, setDistributionData] = useState<ITableData[]>([]);
    const [documentsData, setDocumentsData] = useState<ITableData[]>([]);
    const [filterData, setFilterData] = useState<IProductSettingsFilterData>();
    // Filter & sorting states
    const [dropDownFilters, setDropDownFilters] = useState<IDropDownFilters[][]>([]);
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISortSettingValues>({ column: 'fileName', value: 'Ascending' });
    const [tableFilterQuery, setTableFilterQuery] = useState<IColumnValue[]>([]);
    const [columnFilterValues, setColumnFilterValues] = useState<IProductSettingsColumnFilters>(initialColumnFilters);
    // custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const initialRender = useRef<boolean>(true);

    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };
    // Text transform filter values
    const stylizeText = (filters: Filters): Filters => {
        const _filters: Filters = { ...filters };

        Object.keys(_filters).map((filterKey) => {
            switch (filterKey) {
                case 'status':
                    if (_filters[filterKey]) {
                        const _newArrary = _filters[filterKey].map((value) => toTitleCase(value));
                        _filters[filterKey] = _newArrary;
                    }

                    break;
            }
        });

        return _filters;
    };
    //call query to get all master fund
    const getAllMasterFundData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'masterfund',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDocumentDashboard;

            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);

                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }

                setFilterData(_filters);
                setMasterFundData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else {
                setLoading(false);
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Fetch Masterfund Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            loadingHandler();
        }
    };
    //call query to get all NAV
    const getAllNAVPerUnitData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'navperunit',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundDocumentDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }
                setNavPerUnitData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else {
                setLoading(false);
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Fetch NAV Per Unit Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            setLoading(false);
        }
    };
    //call query to get all distribution
    const getAllDistributionData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'distribution',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundDocumentDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }
                setDistributionData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else {
                setLoading(false);
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Fetch Distribution Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            setLoading(false);
        }
    };
    //call query to get all documents
    const getAllDocumentsData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'documents',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: searchInput,
                        sort: [sortData],
                        filter: tableFilterQuery,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundDocumentDashboard;

            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }
                setDocumentsData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else {
                setLoading(false);
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: 'Cannot Fetch Documents Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            setLoading(false);
        }
    };
    //Fn to handle column filtering
    const handleColumnFilterChange = (value: string, elemName: string, columnLabel: string) => {
        const _tempArr = [...tableFilterQuery];
        const _tempLabels = { ...columnFilterValues };
        if (_tempArr.find((obj) => obj.column === elemName)) {
            const updated = _tempArr.find((obj) => obj.column === elemName);
            updated ? (updated.value = value) : '';
            if (elemName === 'lastUpdated') {
                _tempLabels[elemName].value = value;
                _tempLabels[elemName].columnLabel = columnLabel;
            } else {
                if (value !== '') {
                    _tempLabels[elemName].columnLabel = value;
                    _tempLabels[elemName].value = value;
                } else {
                    _tempLabels[elemName].columnLabel = columnLabel;
                    _tempLabels[elemName].value = value;
                }
            }
        } else {
            _tempArr.push({ column: elemName, value: value });

            Object.keys(_tempLabels).map((key: string) => {
                if (key == elemName) {
                    if (elemName == 'lastUpdated') {
                        _tempLabels[elemName].value = value;
                        _tempLabels[elemName].columnLabel = columnLabel;
                    } else {
                        _tempLabels[elemName].value = value;
                        _tempLabels[elemName].columnLabel = value;
                    }
                }
            });
        }

        setColumnFilterValues(_tempLabels);
        setTableFilterQuery(_tempArr);
    };
    //Fn to reset column filters during tab change
    const resetColumnFilters = () => {
        const _initialColumnFilters: IProductSettingsColumnFilters = {
            status: { column: 'status', value: '', columnLabel: 'Status' },
            fileType: { column: 'fileType', value: '', columnLabel: 'Type' },
        };
        const _columnFilters = { ...columnFilterValues };

        Object.entries(_columnFilters).map(([key]) => {
            _columnFilters[key].value = _initialColumnFilters[key].value;
            _columnFilters[key].columnLabel = _initialColumnFilters[key].columnLabel;
        });
        setColumnFilterValues(_columnFilters);
    };

    //mutation function to approve or reject fund document data
    const fundStatusMutation = async (checkerModal: IFundApproval): Promise<boolean> => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundCheckerApproveMutation, {
                    input: checkerModal,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.fundStatus;
            if (error) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving ${checkerModal.tab} request failed`,
                    testId: 'review-import-error-modal',
                });
                setLoading(false);
                handleErrorHandler();
                return true;
            } else {
                setLoading(false);
                return false;
            }
        } catch (error) {
            setLoading(false);
            return false;
        }
    };

    //mutation function to approve or reject bulk documents
    const bulkDocumentsStatusMutation = async (checkerModal: IBulkDocumentsApproval): Promise<boolean> => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(bulkUploadStatusMutation, {
                    input: checkerModal,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.bulkUploadStatus;
            if (!error) {
                setLoading(false);
                return true;
            } else {
                setLoading(false);
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Approving bulk documents request failed`,
                    testId: 'approve-bulkimport-error-modal',
                });
                return false;
            }
        } catch (error) {
            setLoading(false);
            return false;
        }
    };

    const ProviderValue: IUplaodsContext = {
        tab,
        resultLimit,
        currentPage,
        maxPages,
        dropDownFilters,
        tableFilterQuery,
        columnFilterValues,
        searchInput,
        sortData,
        masterFundData,
        navPerUnitData,
        distributionData,
        documentsData,
        filterData,
        initialRender,
        loading,
        debouncedSearchTerm,
        setTab,
        setResultLimit,
        setCurrentPage,
        setDropDownFilters,
        setSearchInput,
        setSortData,
        setTableFilterQuery,
        handleColumnFilterChange,
        getAllMasterFundData,
        getAllNAVPerUnitData,
        getAllDistributionData,
        getAllDocumentsData,
        resetColumnFilters,
        fundStatusMutation,
        bulkDocumentsStatusMutation,
        loadingHandler,
        disableResultLimit,
    };

    return <UploadsContext.Provider value={ProviderValue}>{children}</UploadsContext.Provider>;
};
