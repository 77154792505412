/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, ReactText, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { transactionReasonList } from '../../_graphql/queries/common/transactionReasonList';
import { CustomButton, CustomSpacer, FlexedDiv } from '../../components/atoms';
import {
    Banner,
    PreviousPage,
    ComponentLoader,
    IMultiSelectLabel,
    CollapsibleDisplay,
} from '../../components/molecules';

import { CE_DASHBOARD_LABELS, LABEL } from '../../constants';

import { reasonsPageButtons } from '../../styles';
import { removeArrayElement } from '../../utils';

import { IApproval, IRerouteRequest } from '../RerouteTransaction/reasonsToReroute';
import { ceCheckerViewRequest } from '../../_graphql/queries/branchCE/checkerViewRequest/checkerViewRequest';
import { ceCheckerReviewRequest } from '../../_graphql/mutations/branchCE/ceCheckerReviewRequest/ceCheckerReviewRequest';
import { ICeDashboard } from '../../utils/permissionTypes';

import { useComponentLoader } from '../../customHooks';

import * as ROUTES from '../../routes';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import RejectRemarks from '../RejectRemarks/RejectRemarks';
import CheckboxWithMultiSelectV2 from '../../components/organisms/CheckboxWithMultiSelect';
import CheckboxWithInput from '../../components/organisms/CheckboxWithInput';
import { Modal } from '../../components';

export interface IReasons {
    title: string;
    reasons: IMultiSelectLabel[];
}
interface IUpdateTransactionStatusProps {
    remark: string;
    transactionRef: any | string;
    action: string;
    reasonId: Array<ReactText>;
}

interface IRejectTransactionPageProps {
    data: ITableData;
    isReject?: boolean;
}

const sampleState = [[], [], []];
const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};
type listTransactionRefs = {
    transactions: string[];
    showTransactions: boolean;
};
export const RejectTransaction: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const branchId = sessionStorage.getItem('branchId');
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;
    //Permission obj
    const { pendingTab } = parsedPermissionPrivilege;

    const [rejectData, setRejectData] = useState<IReasons[]>([]);
    const [checkedReasons, setCheckedReasons] = useState<Array<boolean>>([false]);
    const [checkedValues, setCheckedValues] = useState<Array<ReactText>>([]);
    const [other, setOthers] = useState({
        checked: false,
        value: '',
    });

    const [remark, setRemark] = useState<string>('');
    const [isRequest, setIsRequest] = useState<boolean>(false);
    const [requestData, setRequestData] = useState<IRerouteRequest>({
        eventType: '',
        requestorName: '',
        reasons: [],
        requestId: '',
    });
    const [approval, setApproval] = useState<IApproval>({
        eventType: 'reroute',
        name: '',
        remarks: null,
        status: '',
    });
    const [rejectReq, setRejectReq] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<string>('');

    // Modal and form UI states
    const [multiSelectDropdownState, setMultiSelectDropdownState] = useState<Array<Array<ReactText>>>(sampleState);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showTransactionRefs, setShowTransactionRefs] = useState<listTransactionRefs>({
        transactions: [],
        showTransactions: false,
    });
    const history = useHistory();
    const location = useLocation<IRejectTransactionPageProps>();
    const transaction = location.state.data as ITableData;
    const isRejectRequest = location.state?.isReject;

    // Custom hooks
    const { loading, loadingHandler } = useComponentLoader();

    /** @function checks the mode of the page for review,if true initiate the hook to call Request Api else calls the reasons list Api */
    const checkMode = () => {
        transaction.approvalType === 'reject' && transaction.status.toLowerCase() === 'pending approval'
            ? setIsRequest(true)
            : getTransactionReasonList();
    };
    /** * @maintainableListEndpoint */
    const getTransactionReasonList = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionReasonList, {
                    input: {
                        actionType: 'Reject',
                        transactionType: 'Sales',
                        source: 'ce',
                    },
                }),
                idTokenHeader,
            );

            const reasons: any = response.data.transactionReasonList.data.result.reasons;

            const update: IReasons[] = [];
            let subs: IMultiSelectLabel[] = [];
            reasons.map((reason: any) => {
                subs = [];
                reason.subCategory.map((sub: any) => {
                    subs.push({
                        key: sub[0],
                        value: sub[1],
                        label: sub[1],
                    });
                });
                update.push({ title: reason.category, reasons: subs });
            });
            setRejectData(update);

            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Reject Reasons',
                testId: 'reject-transaction-error-modal',
            });
        }
    };
    /** @requestDetails */
    const getRequestDetails = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerViewRequest, {
                    input: {
                        requestId: transaction.requestId,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionRequest;
            if (resultCheck.error !== null) throw resultCheck.error;
            const result = response.data.transactionRequest.data.result;
            const { reasons } = result;
            const update: IReasons[] = [];
            let subs: IMultiSelectLabel[] = [];
            transaction.orderNo = result.orderNo;
            reasons.map((reason: any) => {
                subs = [];
                if (reason.category !== 'Others') {
                    if (reason.subCategory !== null) {
                        reason.subCategory.map((sub: any) => {
                            subs.push({
                                key: sub[0],
                                value: sub[1],
                                label: sub[1],
                            });
                        });
                        update.push({ title: reason.category, reasons: subs });
                    } else update.push({ title: reason.category, reasons: [] });
                }
            });
            if (result.approval !== null) {
                setApproval(result.approval);
            }
            loadingHandler();
            setRejectData(update);
            setRequestData(result);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Request Details',
                testId: 'reject-transaction-error-modal',
            });
        }
    };
    const getRequestReasons = () => {
        loadingHandler();
        const tempState = [...multiSelectDropdownState];
        let tempSub = '';
        let tempRemarks = remark;
        requestData.reasons.map((item, index) => {
            const subs: ReactText[] = [];
            if (item.subCategory !== null && item.category !== 'Others') {
                item.subCategory.map((sub: any) => {
                    subs.push(sub[1]);
                });
                tempState[index] = subs;
            } else if (item.subCategory !== null && item.category === 'Others') {
                item.subCategory.map((sub: any) => {
                    tempSub = sub[1];
                });
                tempRemarks = tempSub;
            }
        });
        setMultiSelectDropdownState(tempState);
        setRemark(tempRemarks);
        loadingHandler();
    };
    // Fn to call transactionStatus mutation
    const updateTransactionStatus = async (payload: IUpdateTransactionStatusProps) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            if (resultCheck.data.result && resultCheck.data.result.status === true) {
                setShowTransactionRefs({
                    ...showTransactionRefs,
                    transactions: resultCheck.data.result.transactionAffected,
                });
                handleFeedbackModal();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Update Transaction Status',
                testId: 'reject-transaction-error-modal',
            });
        }
    };

    // Fn to call transactionCheckerStatus mutation
    const reviewRequest = async (requestId: string, action: string, remarks?: string) => {
        if (action === 'approve request') handleConfirmModal();
        const payload =
            remarks !== undefined
                ? {
                      requestId: requestId,
                      action: action,
                      remarks: remarks,
                  }
                : {
                      requestId: requestId,
                      action: action,
                  };

        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerReviewRequest, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionCheckerStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.transactionCheckerStatus.data;
            if (result.status) {
                setShowTransactionRefs({
                    ...showTransactionRefs,
                    transactions: resultCheck.data.result.transactionAffected,
                });
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Review Request',
                testId: 'reject-transaction-error-modal',
            });
        }
    };
    const handleCheckboxMultiSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const id = parseInt(e.target.id);
        const check = e.target.checked;
        let tempValues = [...checkedValues];
        const tempChecked = [...checkedReasons];
        const tempState = [...multiSelectDropdownState];
        if (check) {
            tempValues.push(value);
            tempChecked[id] = check;
        } else {
            tempValues = removeArrayElement(tempValues, value);
            tempChecked[id] = false;
            tempState[id] = [];
            setMultiSelectDropdownState(tempState);
        }

        setCheckedReasons(tempChecked);
        setCheckedValues(tempValues);
    };
    const handleCheckboxOthers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const check = e.target.checked;
        if (check) {
            setOthers({ checked: check, value: value });
        } else {
            setOthers({ checked: false, value: '' });
            setRemark('');
        }
    };
    const handleRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemark(event.target.value);
    };

    const handleMultiSelectDropdown = (item: Array<ReactText>, _fn: string, id: number) => {
        const tempState = [...multiSelectDropdownState];

        tempState[id] = item;
        setMultiSelectDropdownState(tempState);
    };

    // Fn to handle route back from feedback modal
    const handleDone = () => {
        if (parsedPermission.hq.grant !== undefined) {
            if (window.location.pathname.includes(ROUTES.hqAllSubmissions)) history.push(ROUTES.hqAllSubmissions);
            else if (branchId !== null && transaction.fromInbox !== true) history.push(ROUTES.viewBranch);
            else history.push(ROUTES.dashboard);
        } else {
            history.push(ROUTES.dashboardBranch);
        }
    };

    // Fn to handle reject order request
    const handleSubmit = () => {
        const payload: IUpdateTransactionStatusProps = {
            transactionRef: [transaction.transactionRef],
            action: 'Reject',
            reasonId: multiSelectDropdownState.flat(),
            remark: remark,
        };
        // set feedback modal data
        if (pendingTab.actions.canReject === LABEL.autoAuthorizer) {
            modalMessages.feedback.heading = LABEL.orderRejected;
            modalMessages.feedback.icon = 'trx-verification-request-approve';
        } else {
            modalMessages.feedback.heading = CE_DASHBOARD_LABELS.DAILY.rejectRequestSubmit;
            modalMessages.feedback.icon = 'trx-verification-request-submit';
        }
        updateTransactionStatus(payload);
    };

    // Fn to handle approve reroute order requests
    const handleApproveRequest = () => {
        // set confirmation modal data
        modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveRejectOrderRequest}`;
        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.approveRejectOrderRequestMessage}</Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
            </FlexedDiv>
        );
        modalMessages.confirmation.type = 'approve request';

        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rejectRequestApproved}`;

        modalMessages.feedback.icon = 'trx-verification-request-approve';
        setShowConfirmationModal((prevState) => !prevState);
    };
    // Fn to handle reject reroute order requests
    const handleRejectRequest = () => {
        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rejectRequestRejected}`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                    Request for&nbsp;
                    <Bold>Order&nbsp;{transaction.orderNo}</Bold>
                    &nbsp;has been rejected. <br /> The maker will be notified.
                </p>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        requestData.requestId !== undefined ? reviewRequest(requestData.requestId, 'reject', rejectRemarks) : null;
    };
    const handleIsRequest = () => {
        setIsRequest(!isRequest);
    };

    const checkboxStatus = (index: number): boolean => {
        let temp = false;
        if (isRequest === false) {
            temp = checkedReasons[index] !== undefined ? checkedReasons[index] : false;
        } else {
            if (requestData.reasons.length !== 0) temp = requestData.reasons[index].subCategory !== null;
        }
        return temp;
    };
    const otherStatus = (): boolean => {
        let temp = false;
        if (isRequest === false) temp = other.checked;
        else {
            if (requestData.reasons.length !== 0) {
                requestData.reasons.map((item) => {
                    if (item.subCategory !== null && item.category === 'Others') {
                        temp = true;
                    }
                });
            }
        }
        return temp;
    };
    const disableSubmit = (): boolean => {
        let temp = false;
        if (multiSelectDropdownState.flat().length === 0 && remark.trim().length === 0) {
            temp = true;
        } else {
            multiSelectDropdownState.map((item, index) => {
                if (checkedReasons[index] && item.length === 0) {
                    temp = true;
                }
            });
            if (other.checked && remark.trim().length === 0) temp = true;
        }
        return temp;
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };
    // Fn to toggle feedback modal
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        // setApprove(!approve);
    };
    /** function to handle the reviewRequest modal messages */
    const handleFeedbackModalMessage = (type: string) => {
        return type !== 'maker' ? (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>Order {transaction.orderNo}</Bold>
                    &nbsp; has been rejected.
                    <br />
                    {pendingTab.reviewApproval.canApproveReject === LABEL.checker
                        ? 'The maker, adviser and CE(HQ) will be notified.'
                        : 'Adviser and CE(HQ) will be notified.'}
                </p>
                {showTransactionRefs.transactions.length !== 0 ? (
                    <CollapseWrapper removeBorder={showTransactionRefs.showTransactions}>
                        <CollapsibleDisplay
                            displayTextForExpand={CE_DASHBOARD_LABELS.DAILY.viewMore}
                            displayTextForCollapse={CE_DASHBOARD_LABELS.DAILY.viewLess}
                            textColor="#002043"
                            expanded={showTransactionRefs.showTransactions}
                            handleExpand={() =>
                                setShowTransactionRefs({
                                    ...showTransactionRefs,
                                    showTransactions: !showTransactionRefs.showTransactions,
                                })
                            }
                            testId="confirm"
                            content={
                                <div>
                                    <Bold style={{ fontSize: '0.75rem' }}>{`Rejected transactions in this order`}</Bold>
                                    <TransactionRefList>
                                        {showTransactionRefs.transactions.map((item, index) => (
                                            <li key={index + 1}>{item}</li>
                                        ))}
                                    </TransactionRefList>
                                </div>
                            }
                            contentStyle={{ textAlign: 'left' }}
                            buttonStyle={{ alignSelf: 'center' }}
                        />
                    </CollapseWrapper>
                ) : null}
            </FlexedDiv>
        ) : (
            <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>{LABEL.dueDateRequestSubtitle}</p>
        );
    };
    /** * @maintainableListEndpoint */
    useEffect(() => {
        //setLoading(true);
        checkMode();
    }, [rejectReq]);

    useEffect(() => {
        isRequest ? getRequestDetails() : null;
        if (isRejectRequest === true) setRejectReq(true);
    }, [isRequest]);

    useMemo(() => {
        requestData.reasons.length !== 0 ? getRequestReasons() : null;
    }, [requestData]);

    return (
        <Fragment>
            {rejectReq == false ? (
                <Fragment>
                    <PreviousPage
                        title={isRequest ? LABEL.reviewRejectRequest : LABEL.rejectOrder}
                        subTitle={
                            isRequest
                                ? approval.status === ''
                                    ? LABEL.reviewUserDescription
                                    : ''
                                : LABEL.rejectOrderDescription
                        }
                        secondSubtitle={
                            isRequest
                                ? approval.status === ''
                                    ? LABEL.reviewUserDescription2
                                    : ''
                                : LABEL.rejectOrderDescription_part2
                        }
                    />
                    <div style={{ marginLeft: '3.5rem' }}>
                        <ReasonsLabel>{LABEL.reasonsToReject}</ReasonsLabel>
                        <div>
                            {approval.status.length !== 0 ? (
                                <ApprovalBanner direction="column">
                                    <Title>{`This request was ${approval.status.toLowerCase()} by ${
                                        approval.name
                                    }`}</Title>

                                    <FlexedDiv
                                        style={{
                                            paddingTop: '0.5rem',
                                            width: '100%',
                                            whiteSpace: 'break-spaces',
                                            wordBreak: 'break-all',
                                        }}
                                        justifyContent="space-between"
                                    >
                                        {approval.remarks !== undefined && approval.remarks !== null
                                            ? `${LABEL.rejectedReason}: ${approval.remarks}`
                                            : ''}
                                    </FlexedDiv>
                                </ApprovalBanner>
                            ) : null}
                        </div>
                        <WrapperDiv>
                            {rejectData.map(({ title, reasons }, index) => {
                                return (
                                    <div key={`reason_${index}`} style={{ marginBottom: '1.5rem', maxWidth: '360px' }}>
                                        <CheckboxWithMultiSelectV2
                                            label={title}
                                            value={title}
                                            id={index.toString()}
                                            checked={checkboxStatus(index)}
                                            handleCheckbox={handleCheckboxMultiSelect}
                                            data={reasons}
                                            placeholder={LABEL.dropdownPlaceholderReason}
                                            selectLabel={LABEL.reason}
                                            disabled={isRequest}
                                            multiSelectState={multiSelectDropdownState[index]}
                                            handleMultiSelectDropdown={handleMultiSelectDropdown}
                                        />
                                    </div>
                                );
                            })}
                            <CheckboxWithInput
                                label={LABEL.others}
                                value={LABEL.others}
                                inputLabel={LABEL.reason}
                                id={LABEL.others}
                                text={remark}
                                handleText={handleRemarks}
                                checked={otherStatus()}
                                disabled={isRequest}
                                handleCheckbox={handleCheckboxOthers}
                            />
                        </WrapperDiv>

                        <ButtonDiv>
                            <CustomButton
                                style={reasonsPageButtons}
                                onClick={() => history.goBack()}
                                disabled={isRequest}
                                id="reject-transaction-cancel-btn"
                            >
                                {LABEL.cancel}
                            </CustomButton>
                            <CustomButton
                                primary
                                style={reasonsPageButtons}
                                disabled={disableSubmit() || isRequest}
                                onClick={() => handleSubmit()}
                                id="reject-transaction-confirm-btn"
                            >
                                {LABEL.submit}
                            </CustomButton>
                        </ButtonDiv>
                    </div>
                    {isRequest &&
                        (pendingTab.reviewApproval.canApproveReject === LABEL.checker ||
                            pendingTab.reviewApproval.canApproveReject === LABEL.autoAuthorizer) &&
                        approval.status === '' && (
                            <Fragment>
                                <Banner
                                    title={`Request Pending Approval`}
                                    isRequestBanner
                                    toggle={isRequest}
                                    handleClose={handleIsRequest}
                                    description={`${LABEL.requestedBy}`}
                                    descriptionEmText={requestData?.requestorName}
                                    testId="rejecttransaction-banner"
                                    primaryButtonObject={{
                                        label: LABEL.approve,
                                        handlePrimaryBtn: () => handleApproveRequest(),
                                        width: '268px',
                                        icon: 'check',
                                    }}
                                    secondaryButtonObject={{
                                        label: LABEL.reject,
                                        handleSecondaryBtn: () => {
                                            setRejectReq(!rejectReq);
                                        },
                                        width: '268px',
                                        icon: 'terminate',
                                        color: '#E84C3D',
                                    }}
                                />
                            </Fragment>
                        )}

                    {/* Yes or No modal */}
                    {showConfirmationModal ? (
                        <Modal
                            title={modalMessages.confirmation.heading}
                            modalActive={showConfirmationModal}
                            setModalActive={handleConfirmModal}
                            primaryBtn={{
                                onClick:
                                    modalMessages.confirmation.type === 'approve request'
                                        ? () => reviewRequest(requestData?.requestId, 'approve')
                                        : () => reviewRequest(requestData.requestId, 'reject', rejectRemarks),
                                label: LABEL.yes,
                                primary: true,
                            }}
                            secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo }}
                            testId="rejct-order-confirmation"
                        >
                            {modalMessages.confirmation.content}
                        </Modal>
                    ) : null}
                </Fragment>
            ) : (
                <Fragment>
                    <RejectRemarks
                        handleCancel={() => {
                            setRejectReq(!rejectReq);
                            history.goBack();
                        }}
                        handleSubmit={handleRejectRequest}
                        handleRejectRemarks={(e) => {
                            setRejectRemarks(e.target.value);
                        }}
                        rejectRemarks={rejectRemarks}
                    />
                </Fragment>
            )}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => handleDone(),
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="reject-order-feedback"
                    contentAlignment="center"
                >
                    {rejectReq
                        ? modalMessages.feedback.content
                        : handleFeedbackModalMessage(pendingTab.actions.canReject)}
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

const ReasonsLabel = styled.div`
    font-size: 1.125rem;
    letter-spacing: -0.2px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.25rem;
`;
const WrapperDiv = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 2.25rem;
    max-width: 784px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    height: 3rem;
    max-width: 25vw;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;
const CollapseWrapper = styled.div<IOnPropStyles>`
    box-shadow: ${(props) => (props.removeBorder ? `inset 0px 1px 0px #66798e` : `unset`)};
    padding-top: ${(props) => (props.removeBorder ? '1rem' : '0')};
    margin-top: 1rem;
`;
const TransactionRefList = styled.ul`
    margin: 0;
    list-style-position: inside;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333333;
    max-height: 9.5rem;
    overflow: auto;
`;
export default RejectTransaction;
