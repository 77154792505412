import React, { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { ExtendTargetDate, LocationState, Page } from './ExtendTargetDate';
import { RejectRemarks } from '../../templates';

const DueDateExtension = (): JSX.Element => {
    const [targetDate, setTargetDate] = useState<IExtendTargetDate>({ date: [null, null], remarks: '' });
    const [page, setPage] = useState<Page>('approve');
    const location = useLocation();
    const locationState: LocationState = location.state as LocationState;

    let content: JSX.Element = <div />;

    if (page === 'approve') {
        content = (
            <ExtendTargetDate
                locationState={locationState}
                targetDate={targetDate}
                setPage={setPage}
                setTargetDate={setTargetDate}
            />
        );
    } else {
        content = <RejectRemarks />;
    }

    return <Fragment>{content}</Fragment>;
};

export default DueDateExtension;
