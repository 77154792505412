/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { caseDetailsStatusQuery } from '../../_graphql/queries/edd/caseDetails/caseDetailsStatus';
import { getRequestDetailsCEApprove } from '../../_graphql/queries/inbox/getRequestDetailsCEApprove';
import { FlexedDiv, Modal, Avatar } from '../../components';
import { LABEL } from '../../constants';
import { getRequestDetailsCEOthers } from '../../_graphql/queries/inbox/getRequestDetailsCEOthers';
import { getSystemAdminInboxData } from '../../_graphql/queries/inbox/getSystemAdminInboxData';
import { getInitials, userColorPicker } from '../../utils';
import { IcoMoon } from '../../icons';

import * as ROUTES from '../../routes';
import AuthContext from '../../context/AuthContext';
import getRequestDetailsFundUpload from '../../_graphql/queries/inbox/getRequestDetailsFundUpload';
import styled from 'styled-components';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import InboxContext from '../../context/InboxContext/InboxContext';

type notificationStyleProps = {
    isExpand?: boolean;
    isUnRead?: boolean;
};
interface CustomNotificationProps {
    notification: INotificationObject;
    updateRead: (ids: string) => void;
    testId?: number;
}

const inboxModal = {
    title: '',
    subTitle: LABEL.requestApprovedMessage,
    label: LABEL.viewDetails,
    icon: 'inbox-approved',
};
export const Notifications: React.FC<CustomNotificationProps> = ({
    notification,
    updateRead,
    testId,
}: CustomNotificationProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { getSource } = useContext(InboxContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const [expand, setExpand] = useState<boolean>(false);
    const history = useHistory();
    const [requestApprovedModal, setRequestApprovedModal] = useState<boolean>(false);
    const [eventType, setEventType] = useState<'approved' | 'rejected'>();
    const [redirectState, setRedirectState] = useState<string>('');

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { systemSettings } = parsedPermission.hq.permission;

    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const branchUser: boolean = parsedPermission.branch.grant !== undefined ? true : false;
    /** To check whether the routes to be redirected are hq or branch routes */
    const getRoute = () => {
        let temp = '';
        branchUser ? (temp = ROUTES.branchInbox) : (temp = ROUTES.hqInbox);
        return temp;
    };
    /** Mark the notification as read i.e changes the background color and update the isRead BE flag */
    const markRead = () => {
        updateRead(notification.notificationId);
    };
    const fundObj = { fundDocumentId: '', title: 'Documents', status: 'Pending Approval', fromInbox: false };
    const rerouteState: ITableData = {
        status: 'Pending Approval',
        transactionRef: '',
        approvalType: '',
        requestId: '',
        fromInbox: false,
    };
    const systemAdminObj = { id: '', source: '', status: 'pending' };
    /** @apis to find approval status */
    //  CE
    const getApprovalStatusEventApprove = async (ref: string, requestId: string) => {
        // loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(getRequestDetailsCEApprove, {
                    input: {
                        transactionRef: ref,
                        requestId: requestId,
                        tab: 'daily',
                        endpoint: 'branchDashboard',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.getOrderSummaryV2;
            if (resultCheck.error) throw resultCheck.error;
            const { result } = resultCheck.data;
            if (result.approval !== null) {
                if (result.approval.remarks !== null) {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    setRedirectState('request-approve');
                } else {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    setRedirectState('request-approve');
                }
            } else {
                redirectRequest('request-approve');
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Notification Status',
                testId: 'notification-status-error-modal',
            });
            handleErrorHandler();
        }
    };
    const getEDDCaseStatus = async (caseId: string, tab: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(caseDetailsStatusQuery, {
                    input: {
                        caseId: caseId,
                        tab: tab,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.caseDetails;
            if (error !== null) throw error;
            if (data) {
                sessionStorage.setItem('caseId', caseId);
                return data.result;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Case details',
                testId: 'edd-case-details-error-modal',
            });
        }
    };

    const getApprovalStatusEventOther = async (ref: string, event: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getRequestDetailsCEOthers, {
                    input: {
                        requestId: ref,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionRequest;
            if (resultCheck.error !== null) throw resultCheck.error;
            const result = response.data.transactionRequest.data.result;
            if (result.approval !== null) {
                if (result.approval.remarks !== null) {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    handleRedirectStateForCE(event);
                } else {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    handleRedirectStateForCE(event);
                }
            } else {
                event === 'reroute' ? redirectRequest('request-reroute') : null;
                event === 'reject' ? redirectRequest('request-reject') : null;
                event === 'duedate' ? redirectRequest('request-duedate') : null;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Notification Status',
                testId: 'notification-status-error-modal',
            });
            handleErrorHandler();
        }
    };
    const handleRedirectStateForCE = (event: string) => {
        switch (event) {
            case 'reroute':
                setRedirectState('request-reroute');
                break;
            case 'reject':
                setRedirectState('request-reject');
                break;
            case 'duedate':
                setRedirectState('request-duedate');
                break;
        }
    };
    // Uploads Dashboard (Fund)
    const getApprovalStatusFund = async (fundId: string, event: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getRequestDetailsFundUpload, {
                    input: {
                        fundDocumentId: fundId,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.fundUploadData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            if (result.transaction.user !== null) {
                if (result.transaction.remark !== null) {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    event === 'fund-upload' ? setRedirectState('fund-upload') : setRedirectState('bulk-upload');
                } else {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    event === 'fund-upload' ? setRedirectState('fund-upload') : setRedirectState('bulk-upload');
                }
            } else {
                redirectRequest(event);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Notification Status',
                testId: 'notification-status-error-modal',
            });
            handleErrorHandler();
        }
    };
    //  System Admin
    const getApprovalStatusSystemAdmin = async (id: string, event: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getSystemAdminInboxData, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.systemAdminInboxData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            const value = JSON.parse(result.data.value);
            if (event === 'system-settings' && result.data.approvalStatus.toLowerCase() !== 'pending') {
                inboxModal.title = (
                    <span>
                        {value.value === 'true' ? 'Enable' : 'Disable'} Multiple UTMC <br /> request has{' '}
                        {result.data.isExpired ? 'expired' : 'been ' + result.data.approvalStatus.toLowerCase()}
                    </span>
                ) as unknown as string;
                inboxModal.label = 'Okay';
                inboxModal.subTitle = (result.data.isExpired ? (
                    <Subtitle>
                        This request is no longer available.
                        <br /> Please submit a new request to the checker.
                    </Subtitle>
                ) : (
                    <Subtitle>
                        This request is no longer available.
                        <br /> The checker has already completed this request.
                    </Subtitle>
                )) as unknown as string;
                inboxModal.icon = 'setting-inbox';
                setRequestApprovedModal(!requestApprovedModal);
                setEventType('approved');
                setRedirectState(event);
            } else if (result.data.approvalStatus.toLowerCase() !== 'pending') {
                if (result.data.approvalStatus.toLowerCase() === 'approved') {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    setRedirectState(event);
                }
                if (result.data.approvalStatus.toLowerCase() === 'rejected') {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    setRedirectState(event);
                }
            } else {
                redirectRequest(event);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Notification Status',
                testId: 'notification-status-error-modal',
            });
            handleErrorHandler();
        }
    };
    /** The function that handles the view Notification Link */
    const handleViewNotification = async () => {
        switch (notification.searchType.toLowerCase()) {
            case 'order':
                const data = {
                    orderNo: notification.searchKey,
                    tab: 'hardcopy',
                    endpoint: 'branchDashboard',
                    fromInbox: true,
                };
                markRead();
                history.push(`${getRoute()}/${ROUTES.orderSummary}`, data);
                break;
            case 'transaction':
                const dataTransaction = {
                    transactionRef: notification.searchKey,
                    tab: 'daily',
                    endpoint: 'branchDashboard',
                    fromInbox: true,
                };
                markRead();
                history.push(`${getRoute()}/${ROUTES.orderSummary}`, dataTransaction);
                break;
            case 'request-approve':
                getApprovalStatusEventApprove(notification.searchKey, notification.searchId);
                break;
            case 'request-reject':
                getApprovalStatusEventOther(notification.searchId, 'reject');
                break;
            case 'request-reroute':
                getApprovalStatusEventOther(notification.searchId, 'reroute');
                break;
            case 'request-duedate':
                getApprovalStatusEventOther(notification.searchId, 'duedate');
                break;
            case 'groups':
                getApprovalStatusSystemAdmin(notification.searchKey, 'groups');
                break;
            case 'roles':
                getApprovalStatusSystemAdmin(notification.searchKey, 'roles');
                break;
            case 'users':
                getApprovalStatusSystemAdmin(notification.searchKey, 'users');
                break;
            case 'bulk-upload':
                getApprovalStatusFund(notification.searchKey, 'bulk-upload');
                break;
            case 'fund-upload':
                getApprovalStatusFund(notification.searchKey, 'fund-upload');
                break;
            case 'user-bulk-upload':
                getApprovalStatusSystemAdmin(notification.searchKey, 'user-bulk-upload');
                break;
            case 'branch-pending':
                const pendingObj = {
                    fromInbox: {
                        status: true,
                        currentBranchId: branchUser ? '' : notification.searchKey,
                    },
                    currentTab: 'daily',
                };
                markRead();
                if (branchUser === false) {
                    sessionStorage.setItem('branchId', notification.searchKey);
                    sessionStorage.setItem('branchName', notification.senderName);
                }
                const pendingRoute = branchUser ? ROUTES.dashboardBranch : ROUTES.viewBranch;
                history.push(pendingRoute, pendingObj);
                break;
            case 'case-submit-new':
                const caseDetailsData = {
                    caseId: notification.searchKey,
                    tab: 'new',
                    endpoint: 'caseDetails',
                    fromInbox: true,
                };
                markRead();
                const caseStatus = await getEDDCaseStatus(notification.searchKey, caseDetailsData.tab);
                caseStatus.status === 'Completed' || caseStatus.status === 'Cancelled'
                    ? history.push(`${getRoute()}/${ROUTES.cancelledSatisfactory}`, caseDetailsData)
                    : history.push(`${getRoute()}/${ROUTES.satisfactory}`, caseDetailsData);

                break;
            case 'case-submit-reroute':
                const reroutedCaseData = {
                    caseId: notification.searchKey,
                    tab: 'reroute',
                    endpoint: 'caseDetails',
                    fromInbox: true,
                };
                markRead();
                const reroutedCaseStatus = await getEDDCaseStatus(notification.searchKey, reroutedCaseData.tab);
                reroutedCaseStatus.status === 'Completed' || reroutedCaseStatus.status === 'Cancelled'
                    ? history.push(`${getRoute()}/${ROUTES.cancelledSatisfactory}`, reroutedCaseData)
                    : history.push(`${getRoute()}/${ROUTES.reroutedSatisfactory}`, reroutedCaseData);
                break;
            case 'auto-pull':
                markRead();
                window.open(notification.searchLink, '_blank');
                break;
            case 'system-settings':
                markRead();
                const _X = getSource();
                if (_X.includes('systemadmin') && systemSettings.epfTab.reviewApproval.canApproveChange === 'checker')
                    getApprovalStatusSystemAdmin(notification.searchKey, 'system-settings');
                else
                    _X.includes('systemadmin')
                        ? history.push({
                              pathname: ROUTES.systemConfiguration,
                          })
                        : undefined;
                break;
        }
    };

    const redirectRequest = (type: string) => {
        switch (type.toLowerCase()) {
            case 'request-approve':
                const request_approve = {
                    transactionRef: notification.searchKey,
                    tab: 'daily',
                    endpoint: 'branchDashboard',
                    fromInbox: true,
                    isRequestFromInbox: true,
                    requestIdFromInbox: notification.searchId,
                };

                markRead();
                history.push(`${getRoute()}/${ROUTES.orderSummary}`, request_approve);
                break;
            case 'request-reject':
                rerouteState.transactionRef = notification.searchKey;
                rerouteState.approvalType = 'reject';
                rerouteState.requestId = notification.searchId;
                eventType !== undefined ? (rerouteState.fromInbox = true) : null;
                markRead();
                history.push(`${getRoute()}/reject-transaction`, { data: rerouteState });
                break;
            case 'request-reroute':
                rerouteState.transactionRef = notification.searchKey;
                rerouteState.approvalType = 'reroute';
                rerouteState.requestId = notification.searchId;
                eventType !== undefined ? (rerouteState.fromInbox = true) : null;
                markRead();
                history.push(`${getRoute()}/reroute-transaction`, { data: rerouteState });
                break;
            case 'request-duedate':
                rerouteState.transactionRef = notification.searchKey;
                rerouteState.approvalType = 'duedate';
                rerouteState.requestId = notification.searchId;
                eventType !== undefined ? (rerouteState.fromInbox = true) : null;
                markRead();
                history.push(`${getRoute()}/due-date-extension`, { item: rerouteState });
                break;
            case 'bulk-upload':
                fundObj.fundDocumentId = notification.searchKey;
                eventType !== undefined && eventType === 'rejected'
                    ? (fundObj.status = 'rejected')
                    : eventType === 'approved'
                    ? (fundObj.status = 'active')
                    : null;
                fundObj.fromInbox = true;
                markRead();
                history.push(`${ROUTES.hqInbox}/review-bulk-upload`, fundObj);
                break;
            case 'fund-upload':
                fundObj.fundDocumentId = notification.searchKey;
                fundObj.title = getFundTitle(notification.title);
                // fundObj.title = 'Master Fund';

                eventType !== undefined && eventType === 'rejected'
                    ? (fundObj.status = 'rejected')
                    : eventType === 'approved'
                    ? (fundObj.status = 'active')
                    : null;
                fundObj.fromInbox = true;
                markRead();
                history.push(`${ROUTES.hqInbox}/review-import-file`, fundObj);
                break;
            case 'users':
                systemAdminObj.id = notification.searchKey;
                markRead();
                history.push(`${ROUTES.hqInbox}/review-users`, systemAdminObj);
                break;
            case 'roles':
                systemAdminObj.id = notification.searchKey;
                markRead();
                history.push(`${ROUTES.hqInbox}/review-roles`, systemAdminObj);
                break;
            case 'groups':
                systemAdminObj.id = notification.searchKey;
                markRead();
                history.push(`${ROUTES.hqInbox}/review-groups`, systemAdminObj);

                break;
            case 'user-bulk-upload':
                markRead();
                history.push(`${ROUTES.hqInbox}/bulk-import-users/review-import`, {
                    uploadId: notification.searchKey,
                    fromInbox: true,
                });
                break;
            case 'system-settings':
                markRead();
                history.push({
                    pathname: ROUTES.systemConfiguration,
                });
                break;
        }
    };
    const getFundTitle = (title: string): string => {
        let temp = '';
        const nav = title.toLowerCase().includes('nav');
        const distribution = title.toLowerCase().includes('distribution');
        const masterList = title.toLowerCase().includes('master');
        if (nav) temp = 'NAV';
        if (distribution) temp = 'Distribution';
        if (masterList) temp = 'Master Fund';
        return temp;
    };
    // Avatar background Color
    const color = userColorPicker(notification.source.toLowerCase());
    const viewLinkText = () => {
        let temp = 'View Transactions';
        switch (notification.searchType) {
            case 'order':
            case 'transaction':
                temp = 'View Transaction';
                break;
            case 'auto-pull':
                temp = 'Download Reports';
                break;
            case 'branch-pending':
                temp = 'View Transactions';
                break;
            case 'case-submit-reroute':
                temp = 'View EDD Case';
                break;
            case 'case-submit-new':
                temp = 'View EDD Case';
                break;
            case 'system-settings':
                notification.title.split(' ')[0] === 'Request' || notification.title.includes('Reminder')
                    ? (temp = 'View Details')
                    : (temp = 'View EPF Settings');

                break;
            default:
                temp = 'View Request';
        }
        return temp;
    };

    const generateViewMore = (): JSX.Element | null => {
        let _temp = (
            <MessageLink>
                {notification.title !== 'Excess Payment' && (
                    <ViewMoreLink
                        onClick={handleViewNotification}
                        isExpand={expand}
                        id={`notification-expand-btn-${testId}`}
                    >
                        {viewLinkText()}
                        <IcoMoon name="arrow-right" size="1rem" />
                    </ViewMoreLink>
                )}
            </MessageLink>
        );

        const _source = getSource();
        const hideMessageLink =
            notification.searchType === 'system-settings' &&
            (systemSettings === undefined || (systemSettings && systemSettings.epfTab.isAll === 'false'));
        switch (_source[0]) {
            case 'ce':
                if (hideMessageLink) _temp = <MessageLink></MessageLink>;
                break;
            case 'amla':
            case 'systemadmin':
                if (hideMessageLink) _temp = <MessageLink></MessageLink>;
                break;
            case 'finance':
                if (notification.searchType === 'system-settings') _temp = <MessageLink></MessageLink>;
                break;
        }

        return _temp;
    };

    return (
        <Fragment>
            <NotificationContainer className="notifications_container" id={`notification-${testId}`}>
                <NotificationRow>
                    <Day
                        id={`${
                            notification.day && notification.day.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()
                        }`}
                    >
                        {notification.day}
                    </Day>
                </NotificationRow>

                <NotificationsMessage
                    className="notifications_message"
                    style={{ height: expand ? 'auto' : 'auto' }}
                    onClick={() => setExpand(!expand)}
                    isUnRead={notification.isRead !== undefined && notification.isRead ? false : !notification.isRead}
                >
                    <Avatar backgroundColor={color} testId={`${testId}-notification`}>
                        <span id={`notification-name-${testId}`}>
                            {notification.senderName === 'HQ - Kuala Lumpur'
                                ? 'HQ'
                                : getInitials(notification.senderName)}
                        </span>
                    </Avatar>
                    <MessageContainer>
                        <MessageTitle id={`notification-subject-${testId}`}>
                            {notification.title}
                            {notification.isSeen !== undefined && !notification.isSeen ? <Badge /> : ''}
                        </MessageTitle>
                        <MessageBody isExpand={expand} id={`notification-message-${testId}`}>
                            {notification.message}
                        </MessageBody>
                        {generateViewMore()}
                    </MessageContainer>
                    <MessageTime id={`notification-time-${testId}`}> {notification.createdOn}</MessageTime>
                </NotificationsMessage>
            </NotificationContainer>
            {requestApprovedModal && (
                <Modal
                    title={inboxModal.title}
                    modalActive={requestApprovedModal}
                    setModalActive={setRequestApprovedModal}
                    primaryBtn={{
                        onClick: () => {
                            setRequestApprovedModal(!requestApprovedModal);
                            redirectRequest(redirectState);
                        },
                        label: inboxModal.label,
                        primary: true,
                        size: 'large',
                    }}
                    icon={inboxModal.icon}
                    contentAlignment="center"
                    testId="notification-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {inboxModal.subTitle}
                    </FlexedDiv>
                </Modal>
            )}
        </Fragment>
    );
};

const NotificationContainer = styled.div`
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
`;

const NotificationRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Day = styled.p`
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 0.85rem;
    color: #002043;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin-bottom: 0;
`;
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const NotificationsMessage = styled.div<notificationStyleProps>`
    cursor: pointer;
    margin: 0.5rem 0rem;
    padding: 1rem;
    transition: height 0.2s ease-in;
    background: ${(props) => (props.isUnRead ? '#fff' : '#F2F4F6')};
    box-shadow: ${(props) => (props.isUnRead ? '0px 0px 32px rgba(18, 39, 120, 0.12)' : 'none')};
    border: ${(props) => (props.isUnRead ? '0px' : '1px solid #EAEBEE;')};
    border-radius: 8px;
    display: flex;
    /* width: 76.67vw;
    min-width: 1104px; */
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 44.86vw;
    min-width: 646px; */
    margin: 0rem 1.22rem;
    white-space: nowrap;
    /* height: 3.5rem; */
    max-width: 90%;
    word-break: break-all;
    width: 100%;
`;

const MessageTitle = styled.h1`
    font-weight: 700;
    line-height: 24px;
    font-size: 1rem;
    /* display: flex; */
    /* width: 14.72vw; */
`;

const MessageBody = styled.div<notificationStyleProps>`
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.isExpand ? 'unset' : 1)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.75rem;
    color: #333333;
    // width: 590px;
    white-space: pre-wrap;
`;

const MessageTime = styled.div`
    color: #002043;
    mix-blend-mode: normal;
    opacity: 0.5;
    font-size: 0.625rem;
`;
const LinkBtn = styled.a`
    display: inline-block;
    font-weight: 700;
    font-size: 0.75rem;
    color: #0089ec;
    line-height: 0.85rem;
    &:hover {
        color: #006dbd;
    }
`;
const MessageLink = styled.div`
    display: flex;
    align-items: center;
    fill: #0089ec;
    &:hover {
        fill: #006dbd;
    }
`;
const ViewMoreLink = styled(LinkBtn)<notificationStyleProps>`
    display: ${(props) => (props.isExpand ? '' : 'none')};
    margin-top: 0.5em;
`;
const Badge = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem !important;
    display: inline-block;
`;
