import React, { Fragment } from 'react';
import { PreviousPage } from '../../../components/molecules';
import { AdvisorBranch } from '../../Advisers';
import { useLocation } from 'react-router-dom';

declare interface PassedDataInside {
    hq: boolean;
    item: {
        index: number;
        rawData: ITableData;
    };
}

const ViewAdvisors = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const location: any = useLocation();
    const locationState: PassedDataInside = location.state;
    const data: ITableData = locationState.item.rawData;

    return (
        <Fragment>
            <PreviousPage title={data.name} />
            <AdvisorBranch hq={locationState.hq} />
        </Fragment>
    );
};

export default ViewAdvisors;
