import React, { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { EDD, LABEL, SearchOptionsEddInvestors } from '../../../../../constants';
import { ComponentLoader, PreviousPage, SearchBar } from '../../../../../components';
import { ROUTES } from '../../../..';
import { CurvedCard, InvestorDashboard, ListLayout } from '../Investors';
import { getInitialsByFirstTwo } from '../../../../../utils';

import styled from 'styled-components';
import EDDContext from '../../../../../context/EDDContext/EDDContext';

const columns = [
    // {
    //     column: '',
    //     subColumn: ' ',
    //     value: '',
    // },
    {
        column: 'Account No.',
        subColumn: ' ',
        value: '',
    },
    {
        column: 'Investor Name  ',
        subColumn: 'ID No.',
        value: '',
    },
    {
        column: 'Joint Account Name ',
        subColumn: 'ID No.',
        value: '',
    },
    {
        column: 'Adviser Name ',
        subColumn: 'Code',
        value: '',
    },
    {
        column: 'Account Opened On',
        subColumn: ' ',
        value: '',
    },
    {
        column: 'Type',
        subColumn: ' ',
        value: '',
    },
    {
        column: 'History',
        subColumn: ' ',
        value: '',
    },
];
export const InvestorsList: React.FC = () => {
    const {
        clientsList,
        getEDDClientList,
        investorsPage,
        investorsPageMax,
        investorsResultLimit,
        loading,
        pagesDropDown,
        pills,
        searchInvestorInput,
        setClientAccountSearchInput,
        setClientIdNum,
        setInvestorsResultLimit,
        setInvestorsPage,
        setSearchInvestorInput,
        setSortClientList,
        totalResultCount,
    } = useContext(EDDContext);

    const [expand, setExpand] = useState<boolean>(false);
    const [showDashboard, setShowDashboard] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [indexValue, setIndexValue] = useState<number>(0);
    const [condition, setCondition] = useState<string>('');
    const [arrowIndex, setArrowIndex] = useState<number>(0);

    const handleNext = () => {
        if (investorsPage < investorsPageMax) {
            setInvestorsPage(investorsPage + 1);
        }
    };
    const handlePrevious = () => {
        if (investorsPage > 1) {
            setInvestorsPage(investorsPage - 1);
        }
    };
    const handleResultLimit = (limit: number) => {
        // setInvestorsPage(1);
        setInvestorsResultLimit(limit);
    };
    const handlePage = (page: number) => {
        setInvestorsPage(page);
    };
    const handleClick = async (e: string, index: number) => {
        showDashboard == e ? setShowDashboard('') : setShowDashboard(e);
        await setClientIdNum(e);
        setDisabled(index == indexValue ? !disabled : true);
        setIndexValue(index);
        setSortClientList({
            column: 'clientName',
            value: 'Descending',
        });
        setClientAccountSearchInput({ value: '', column: 'all' });
        for (let i = 1; i <= investorsResultLimit / 4; i++) {
            if (index <= 4) setCondition('1,4');
            else if (index > i * 4 && index <= (i + 1) * 4) setCondition(`${i * 4},${(i + 1) * 4}`);
        }
        setArrowIndex(index);
    };
    // Fn to clear states on component unmount
    const clearContext = () => {
        setInvestorsPage(1);
        setInvestorsResultLimit(12);
    };

    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsEddInvestors.investors.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInvestorInput({ ...searchInvestorInput, column: value }),
                testId: `investors-list-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    useEffect(() => {
        getEDDClientList();
        setShowDashboard('');
    }, [searchInvestorInput, investorsResultLimit, investorsPage, investorsPageMax]);

    useEffect(() => {
        clearContext();
    }, []);
    return (
        <Fragment>
            <PreviousPage
                backIcon={true}
                title={EDD.LABEL_ADD_NEW_CASE}
                handleBack={ROUTES.amlaEDDManagement}
                subTitle={EDD.LABEL_ADD_NEW_CASE_SUB}
            />
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={LABEL.searchPlaceHolderByNameID}
                searchTitle={LABEL.investors}
                filter={false}
                dropDownOptions={searchOptions()}
                searchInput={searchInvestorInput}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInvestorInput({ ...searchInvestorInput, value: e.currentTarget.value });
                }}
                clearSearchInput={() => {
                    setSearchInvestorInput({ ...searchInvestorInput, value: '' });
                }}
                testId="investors-list"
            ></SearchBar>
            <ListLayout
                tabs={[]}
                page={investorsPage}
                handlePage={handlePage}
                maxPage={investorsPageMax}
                setPagesDropDown={pagesDropDown}
                selectedTab={0}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                resultLimit={investorsResultLimit}
                setResultLimit={(limit) => handleResultLimit(limit)}
                count={totalResultCount}
                setCurrentPage={setInvestorsPage}
            >
                {clientsList &&
                    clientsList.map((item, i) => {
                        return (
                            <Fragment key={i}>
                                <CardWrapper onClick={() => handleClick(item.clientIdNum, i + 1)} key={i}>
                                    <CurvedCard
                                        title={item.clientName}
                                        subTitle={`${item.clientIdNum} | ${
                                            parseInt(item.clientAccountCount) > 1
                                                ? item.clientAccountCount + ' Accounts'
                                                : item.clientAccountCount + ' Account'
                                        }`}
                                        label={getInitialsByFirstTwo(item.clientName !== null ? item.clientName : '')}
                                        key={i}
                                        index={i}
                                        showDashboard={showDashboard}
                                        circleBackGround={
                                            showDashboard != '' && showDashboard != item.clientIdNum
                                                ? '#838383'
                                                : '#a85846'
                                        }
                                        indexValue={indexValue}
                                        disabled={disabled}
                                        showArrow={
                                            i + 1 == arrowIndex && showDashboard == item.clientIdNum ? true : false
                                        }
                                    />
                                </CardWrapper>
                                {/* show/hide Investor card dashboard div in below the clicked card
                                 */}
                                {pills.length > 0
                                    ? clientsList.length == 1
                                        ? ((i + 1) % 4 == 0 || clientsList.length == i + 1) &&
                                          i + 1 >= parseInt(condition.split(',')[0]) &&
                                          i + 1 <= parseInt(condition.split(',')[1]) &&
                                          showDashboard != '' &&
                                          (showDashboard == pills[0].clientIdNum ||
                                              showDashboard == pills[0].jointIdNum) && (
                                              <InvestorDashboard columns={columns} pills={pills} />
                                          )
                                        : ((i + 1) % 4 == 0 || clientsList.length == i + 1) &&
                                          i + 1 > parseInt(condition.split(',')[0]) &&
                                          i + 1 <= parseInt(condition.split(',')[1]) &&
                                          showDashboard != '' &&
                                          (showDashboard == pills[0].clientIdNum ||
                                              showDashboard == pills[0].jointIdNum) && (
                                              <InvestorDashboard columns={columns} pills={pills} />
                                          )
                                    : clientsList.length == 1
                                    ? ((i + 1) % 4 == 0 || clientsList.length == i + 1) &&
                                      i + 1 >= parseInt(condition.split(',')[0]) &&
                                      i + 1 <= parseInt(condition.split(',')[1]) &&
                                      showDashboard != '' && <InvestorDashboard columns={columns} pills={pills} />
                                    : ((i + 1) % 4 == 0 || clientsList.length == i + 1) &&
                                      i + 1 > parseInt(condition.split(',')[0]) &&
                                      i + 1 <= parseInt(condition.split(',')[1]) &&
                                      showDashboard != '' && <InvestorDashboard columns={columns} pills={pills} />}
                                {loading ? <ComponentLoader /> : null}
                            </Fragment>
                        );
                    })}
            </ListLayout>
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

const CardWrapper = styled.div`
    cursor: pointer;
    margin: 30px 30px 11px 30px;
    width: calc((25% - 75px) - 1px);
`;
