/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Fragment, ReactText, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import FlexSpacer, {
    Banner,
    ComponentLoader,
    CustomButton,
    FlexedDiv,
    Modal,
    SelectDropdown,
    TextArea,
} from '../../../../components';
import { CustomInput } from '../../../../components/atoms/Input';
import {
    Fs16RegSecPrimaryBlack,
    Fs16SemiBoldSecPrimaryBlack,
    Fs24BoldPrimaryBlack,
    LABEL,
} from '../../../../constants';
import { TextBold24, TextNormal16, TextBold18, TextBold12 } from '../../../../constants/textStyles';
import { IcoMoonSelection } from '../../../../icons';
import { IValidation } from '../../../../interfaces/IValidation';
import { sh16, sh32, sh240, sh56, sh24, sh64, sh784, sh80 } from '../../../../styles';
import { Validate } from '../../../../validation/validate';
import * as ROUTES from '../../../../routes';
import MultiSelectV2 from '../../../../components/molecules/MultiSelect';
import { getUserGroupInfo } from '../../../../_graphql/queries/systemadmin/userGroup/userGroupgetIdInfo';
import { editUserGroupStatus } from './userGroupStatus';
import { editUserGroup } from '../../../../_graphql/mutations/systemAdmin/editGroup';
import { approveReject } from '../../../../_graphql/mutations/systemAdmin/approveReject';
import {
    canCombineGrant,
    isAdminLevel,
    isBranchLevel,
    isHqLevel,
    isOnlyAccountManagement,
    isOnlyFund,
    permissionKeyLabels,
} from '../permissionKeys';
import { IBranchDropDownBehavior } from '../AddUserGroup';
import { validateRolesDropdown } from '../../../../_graphql/mutations/systemAdmin/validateRolesDropdown';
import { IUserManagementUserGroupTab } from '../../../../utils/permissionTypes';
import { getEditGroupModalMessage, pageTitle } from './pageLabels';
import { useComponentLoader } from '../../../../customHooks';
import { dashboardSystemAdmin } from '../../../../routes';

import styled from 'styled-components';
import AuthContext from '../../../../context/AuthContext';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import IcomoonReact from 'icomoon-react';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface addUserGroupProps {
    name: string;
    status: ReactText;
    author: string;
    description: string;
    userRoles: ReactText[];
    branchOffices: ReactText[];
    grant: string;
}
interface IEditUserGroupProps {
    author: any;
    mode: string;
    data: ITableData;
    source: string;
    status: string;
    reject?: boolean;
}
const initialUserGroup: addUserGroupProps = {
    name: '',
    status: 'Pending',
    author: '',
    description: '',
    userRoles: [],
    branchOffices: [],
    grant: '',
};

const initialBehavior: IBranchDropDownBehavior = {
    type: permissionKeyLabels.normalHQ,
    disabled: false,
};

const EditUserGroup = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [error, setError] = useState<string>('');
    const [userGroupData, setUserGroupData] = useState({ ...initialUserGroup });
    const [oldUserGroupData, setOldUserGroupData] = useState({ ...initialUserGroup });
    const [isRejected, setIsRejected] = useState(false);
    const [isRejectedConfirm, setIsRejectedConfirm] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [rejectRemark, setRejectRemark] = useState('');
    const [branchDropdownBehavior, setBranchDropdownBehavior] = useState<IBranchDropDownBehavior>(initialBehavior);
    const [defaultBranch, setDefaultBranch] = useState<ReactText[]>([0]);
    const [errorMessages, setErrorMessages] = useState<string>('');
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission = parsedPermission.hq.permission.userManagement
        .userGroupTab as IUserManagementUserGroupTab;
    const { state } = useLocation<IEditUserGroupProps>();
    const [userType, setUserType] = useState('maker');
    const { loading, loadingHandler } = useComponentLoader();
    const [modified, setModified] = useState(false);
    const [validationModal, setValidationModal] = useState(false);
    const [validationMsg, setValidationMsg] = useState<string>('');
    const { getDropdown, branchOptions, roleOptions } = useContext(SystemAdminContext);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    useEffect(() => {
        if (state.mode === 'review') isChecker(state.source);
        else {
            userManagementPermission.actions.canEditUserGroup === 'auto-authorizer'
                ? setUserType(LABEL.autoAuthorizer)
                : null;
        }
    }, []);
    const isChecker = (action: string) => {
        switch (action) {
            case 'createGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveCreateNewUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveCreateNewUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveCreateNewUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'editGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveEditUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveEditUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveEditUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'suspendGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveSuspendUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveSuspendUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveSuspendUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'deleteGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveDeleteUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveDeleteUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveDeleteUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            case 'enableGroup':
                if (
                    userManagementPermission.reviewApproval.canApproveReactivateUserGroup === 'auto-authorizer' ||
                    userManagementPermission.reviewApproval.canApproveReactivateUserGroup === 'checker'
                ) {
                    setUserType(userManagementPermission.reviewApproval.canApproveReactivateUserGroup);
                    setShowBanner(!showBanner);
                    return true;
                } else return false;
            default:
                setUserType('maker');
                return false;
        }
    };
    const runApproveRejectGroup = async (action: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: {
                        requestId: state.data.requestId,
                        action: action,
                        remarks: rejectRemark,
                    },
                }),
                idTokenHeader,
            );
            const { data } = await response.data.approveReject;

            if (data !== null) {
                if (data.result.status) {
                    setShowModal(true);
                }
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Review Request',
                testId: 'edit-usergroup-other-error-modal',
            });
            handleErrorHandler();
        }
    };

    const handleApproveReject = (action: string) => {
        runApproveRejectGroup(action);
    };

    const validateRoles = async (roles: Array<ReactText>, grant: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(validateRolesDropdown, {
                    input: {
                        roles: roles,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.validateDropdown;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.validateDropdown.data;
            setUserGroupData({ ...userGroupData, userRoles: roles });
            const _grant = canCombineGrant(grant, result.roles);
            if (_grant === 'branch') {
                if (isBranchLevel(result.roles) && result.roles.length <= 3) {
                    setBranchDropdownBehavior({ ...branchDropdownBehavior, type: permissionKeyLabels.branch });
                    setErrorMessages('');
                } else if (isOnlyAccountManagement(result.roles)) {
                    setBranchDropdownBehavior(initialBehavior);
                    setErrorMessages('');
                }
            } else if (_grant === 'hq') {
                if (isAdminLevel(result.roles) && result.roles.length <= 4) {
                    setBranchDropdownBehavior({
                        ...branchDropdownBehavior,
                        type: permissionKeyLabels.admin,
                        disabled: true,
                    });
                    const temp: string[] = [];
                    branchOptions.map((item) => {
                        item.key && temp.push(item.key);
                    });
                    setUserGroupData({ ...userGroupData, branchOffices: temp, userRoles: roles });
                    setErrorMessages('');
                } else if (isOnlyFund(result.roles) && result.roles.length <= 3) {
                    setBranchDropdownBehavior({
                        ...branchDropdownBehavior,
                        type: permissionKeyLabels.pureFund,
                        disabled: true,
                    });
                    const temp: string[] = [];
                    branchOptions.map((item) => {
                        item.key && temp.push(item.key);
                    });
                    setUserGroupData({ ...userGroupData, branchOffices: temp, userRoles: roles });
                    setErrorMessages('');
                } else if (isHqLevel(result.roles)) {
                    setBranchDropdownBehavior(initialBehavior);
                    setErrorMessages('');
                } else if (isOnlyAccountManagement(result.roles)) {
                    setBranchDropdownBehavior(initialBehavior);
                    setErrorMessages('');
                } else {
                    setErrorMessages('Cannot combine the selected Roles');
                    setUserGroupData({ ...userGroupData, branchOffices: defaultBranch, userRoles: roles });
                }
            } else {
                setErrorMessages(_grant);
                // setUserGroupData({ ...userGroupData, branchOffices: defaultBranch, userRoles: roles });
            }
        } catch (error) {
            setUserGroupData({ ...userGroupData, userRoles: roles });
            setUserGroupData({ ...userGroupData, branchOffices: defaultBranch });
        }
    };

    const getSelectedList = (list: Array<ReactText>, id: number) => {
        setModified(true);
        if (list.length < 1 && id === 1) {
            setUserGroupData({ ...userGroupData, userRoles: [], branchOffices: [] });
            return;
        }
        switch (id) {
            case 1: {
                validateRoles(list, userGroupData.grant);
                break;
            }
            case 2: {
                setUserGroupData({ ...userGroupData, branchOffices: list });
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleModal = () => {
        const data = { tab: 'User Groups' };
        history.push(ROUTES.dashboardSystemAdmin, data);
        setShowModal(false);
    };
    const fetchUserGroupData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(getUserGroupInfo, {
                    input: {
                        id: state.data.userGroupId,
                        viewType: 'groups',
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = response.data.getIdInformation;
            const userGroupResponse = JSON.parse(data.result.info);

            setDefaultBranch(userGroupResponse.branchIds.map(String));
            await validateRoles(userGroupResponse.roleIds.map(String), userGroupResponse.grant);
            const temp = initialUserGroup;

            temp.name = userGroupResponse.groupName;
            temp.status = state.status;
            temp.author = userGroupResponse.author;

            temp.branchOffices = userGroupResponse.branchIds.map(String);
            temp.userRoles = userGroupResponse.roleIds.map(String);
            temp.description = userGroupResponse.description;
            temp.grant = userGroupResponse.grant;
            setUserGroupData(temp);
            setOldUserGroupData(temp);

            loadingHandler();
            if (error !== null) {
                loadingHandler();
                const _error = error as IErrorHandling;
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch User Group Data',
                    testId: 'edit-usergroup-other-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {
            loadingHandler();
        }
    };

    useEffect(() => {
        fetchUserGroupData();
        if (state.reject === true) {
            setIsRejected(true);
        }
    }, []);

    const runEditGroup = async () => {
        try {
            const mutation: any = await API.graphql(
                graphqlOperation(editUserGroup, {
                    input: {
                        groupId: state.data.userGroupId,
                        status: userGroupData.status,
                        description: userGroupData.description,
                        roles: userGroupData.userRoles,
                        branch: userGroupData.branchOffices,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = mutation.data.editGroup;
            // continue to show results
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = mutation.data.editGroup.data;
            if (result.status) {
                setShowModal(true);
                getDropdown();
            }
            // checks if error exists
        } catch (error) {
            const _error = error as IErrorHandling;
            switch (_error.errorCode) {
                case 'BO489':
                case 'BO490':
                case 'BO491':
                case 'BO492':
                case 'BO493':
                case 'BO612':
                    setValidationModal(true);
                    setValidationMsg(_error.message);
                    break;
                case 'BO403':
                    if (_error.errorList !== undefined) {
                        const c = _error.errorList;
                        if (c.length !== 0 && c !== null) {
                            c.includes('Combination of grant for group is not allowed.')
                                ? setErrorMessage({
                                      ...errorMessage,
                                      message: `${_error.message} Please select roles assigned to HQ Backoffice only.`,
                                      errorCode: _error.errorCode,
                                      title: 'Cannot Edit User Group',
                                      testId: 'edit-usergroup-other-error-modal',
                                  })
                                : c.includes(`Grant 'branch' for this group is not allowed be changed.`)
                                ? setErrorMessage({
                                      ...errorMessage,
                                      message: `${_error.message} Please select roles assigned to Branch Backoffice only.`,
                                      errorCode: _error.errorCode,
                                      title: 'Cannot Edit User Group',
                                      testId: 'edit-usergroup-other-error-modal',
                                  })
                                : setErrorMessage({
                                      ...errorMessage,
                                      message: _error.message,
                                      errorCode: _error.errorCode,
                                      title: 'Cannot Edit User Group',
                                      testId: 'edit-usergroup-other-error-modal',
                                  });
                            handleErrorHandler();
                        }
                    } else {
                        setErrorMessage({
                            ...errorMessage,
                            message: _error.message,
                            errorCode: _error.errorCode,
                            title: 'Cannot Edit User Group',
                            testId: 'edit-usergroup-other-error-modal',
                        });
                        handleErrorHandler();
                    }
                    break;
                default:
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Edit User Group',
                        testId: 'edit-usergroup-other-error-modal',
                    });
                    handleErrorHandler();

                    break;
            }
        }
    };

    const updateUserGroupData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        const att = e.currentTarget.getAttribute('validation');
        let valmsg = e.currentTarget.getAttribute('validationMessage');
        if (valmsg === null) {
            valmsg = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;
        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valmsg);
            if (err.code !== 'NoError') {
                setError(err.message);
                return;
            } else {
                setError('');
            }
        }
        setUserGroupData({ ...userGroupData, [key]: value });
        setModified(true);
    };
    const updateUserGroupDataSelect = (value: ReactText, field: string) => {
        if (field === 'branchOffices') {
            const tempBranch = [value as string];
            setUserGroupData({ ...userGroupData, branchOffices: tempBranch });
        } else setUserGroupData({ ...userGroupData, [field]: value });
        setModified(true);
    };

    const handleBack = () => {
        const data = { tab: 'User Groups' };
        history.push(ROUTES.dashboardSystemAdmin, data);
    };
    let rejectApproveText = isRejectedConfirm ? 'Edit User Group request rejected' : 'Edit User Group request approved';
    switch (state.source) {
        case 'createGroup':
            rejectApproveText = isRejectedConfirm ? 'User Group request rejected' : 'User Group request approved';
            break;
        case 'suspendGroup':
            rejectApproveText = isRejectedConfirm
                ? 'Suspend User Group request rejected'
                : 'Suspend User Group request approved';
            break;
        case 'enableGroup':
            rejectApproveText = isRejectedConfirm
                ? 'Reactivation User Group request rejected'
                : 'Reactivation User Group request approved';
            break;
        case 'deleteGroup':
            rejectApproveText = isRejectedConfirm
                ? 'Delete User Group request rejected'
                : 'Delete User Group request approved';
            break;
    }

    const hasDataChanged = () => {
        if (!modified) return false;

        if (
            userGroupData.status!.toString().toLocaleLowerCase() !==
                oldUserGroupData.status!.toString().toLocaleLowerCase() ||
            userGroupData.description !== oldUserGroupData.description
        ) {
            return true;
        }
        if (userGroupData.userRoles.length !== oldUserGroupData.userRoles.length) {
            return true;
        }
        if (userGroupData.branchOffices.length !== oldUserGroupData.branchOffices.length) {
            return true;
        }
        let oldData = oldUserGroupData.userRoles.concat().sort();
        let newData = userGroupData.userRoles.concat().sort();
        for (let i = 0; i < oldData.length; i++) {
            if (oldData[i] !== newData[i]) {
                return true;
            }
        }
        oldData = oldUserGroupData.branchOffices.concat().sort();
        newData = userGroupData.branchOffices.concat().sort();
        for (let i = 0; i < oldData.length; i++) {
            if (oldData[i] !== newData[i]) {
                return true;
            }
        }
        return false;
    };
    const disabledSave =
        !hasDataChanged() ||
        userGroupData.name.length === 0 ||
        errorMessages !== '' ||
        error !== '' ||
        userGroupData.branchOffices.length === 0;

    return (
        <Container>
            {/* the page that displayed when the request is rejected */}
            {showEditModal ? (
                <Modal
                    modalActive={showEditModal}
                    setModalActive={setShowEditModal}
                    title={`Edit ${userGroupData.name}?`}
                    primaryBtn={{
                        onClick: () => {
                            setShowEditModal(false);
                            runEditGroup();
                        },
                        label: LABEL.yes,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            setShowEditModal(false);
                        },
                        label: LABEL.cancel,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="left"
                    testId="add-usergroup-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'left' }}>
                        <TextNormal16>Are you sure you want to edit this user group?</TextNormal16>
                    </FlexedDiv>
                </Modal>
            ) : null}
            {isRejected ? (
                <div style={{ marginLeft: sh16 }}>
                    <TextBold24 style={{ display: 'block' }}>{LABEL.rejectRemark}</TextBold24>
                    <TextNormal16 style={{ marginBottom: sh32, display: 'block' }}>
                        {LABEL.rejectRemarkDescription}
                    </TextNormal16>
                    <TextArea
                        name="rejectRemark"
                        label={LABEL.remarks}
                        value={rejectRemark}
                        handleInput={(e) => setRejectRemark(e.currentTarget.value)}
                        maxLength={255}
                        style={{ width: '520px' }}
                    />
                    <FlexedDiv direction="column">
                        <FlexedDiv style={{ marginTop: sh80, justifyContent: 'flex-start' }}>
                            <CustomButton
                                onClick={() => {
                                    setIsRejected(false);
                                    setIsRejectedConfirm(false);
                                    setRejectRemark('');
                                }}
                                style={{ width: sh240, height: '40px' }}
                            >
                                {LABEL.cancel}
                            </CustomButton>
                            <CustomButton
                                disabled={rejectRemark.trim() === ''}
                                primary={true}
                                style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                onClick={() => {
                                    setIsRejectedConfirm(true);
                                    handleApproveReject('reject');
                                }}
                            >
                                {LABEL.submit}
                            </CustomButton>
                        </FlexedDiv>
                        <div style={{ height: sh56 }} />
                    </FlexedDiv>
                </div>
            ) : (
                <Fragment>
                    <TitleContainer style={{ flexDirection: 'row', display: 'flex' }}>
                        <IcomoonReact
                            iconSet={IcoMoonSelection}
                            icon="arrow-left"
                            size="1.5rem"
                            onClick={handleBack}
                            style={{ cursor: 'pointer' }}
                        />

                        <Fs24BoldPrimaryBlack style={{ marginLeft: '0.5rem' }}>
                            {state.mode === 'edit' ? LABEL.editUserGroup : pageTitle(state.source)}
                        </Fs24BoldPrimaryBlack>
                    </TitleContainer>

                    <div style={{ paddingLeft: sh32, width: sh784 }}>
                        <TextNormal16 style={{ marginBottom: '24px', display: 'block' }}>
                            {state.mode === 'edit' ? LABEL.editUserGroupSubHeading : LABEL.reviewUserDescription}
                        </TextNormal16>

                        <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userGroupDetails}</TextBold18>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                            <CustomInput
                                label={LABEL.groupName}
                                name="name"
                                style={{ width: '25vw', maxWidth: '720px' }}
                                value={userGroupData.name}
                                inputStyle={{ fontSize: '1rem', fontWeight: 700 }}
                                onChange={(e) => updateUserGroupData(e)}
                                onLostFocus={(e) => updateUserGroupData(e)}
                                validation={JSON.stringify({ type: 'alphanumeric' } as IValidation)}
                                validationMessage="Name"
                                errorText={error}
                                canClearContents
                                handleClearContents={() => setUserGroupData({ ...userGroupData, name: '' })}
                                disabled
                            />
                            <FlexSpacer />
                            <FlexedDiv direction="column">
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.status}</TextBold12>

                                <SelectWrapper>
                                    <SelectDropdown
                                        onChange={(value) => updateUserGroupDataSelect(value, 'status')}
                                        options={
                                            state.mode !== 'edit'
                                                ? [
                                                      {
                                                          value: userGroupData.status as string,
                                                          label: userGroupData.status as string,
                                                      },
                                                  ]
                                                : editUserGroupStatus.map((item) => {
                                                      return { label: item.label, value: item.value };
                                                  })
                                        }
                                        testId={`status-dropdown`}
                                        selectedValue={userGroupData.status as string}
                                        isDisabled={state.mode === 'review'}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv>
                            <TextArea
                                name="description"
                                label={LABEL.groupDescription}
                                value={userGroupData.description}
                                handleInput={(e) => updateUserGroupData(e)}
                                maxLength={255}
                                disabled={state.mode === 'review'}
                                style={{ width: '25vw', maxWidth: '720px' }}
                            />
                        </FlexedDiv>
                        <TextBold18 style={{ marginBottom: '5px', marginTop: sh64 }}>
                            {LABEL.grantAccessLevel}
                        </TextBold18>
                        <TextNormal16 style={{ marginBottom: '24px', display: 'block' }}>
                            You can only select the previously assigned privileges, either Branch Backoffice or HQ
                            Backoffice.
                        </TextNormal16>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                            <MultiSelectV2
                                label={LABEL.assignUserRole}
                                options={roleOptions}
                                placeHolder={LABEL.selectRoles}
                                checkedState={userGroupData.userRoles}
                                handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                                selectId={1}
                                noOverlay={false}
                                expandable={state.mode === 'review'}
                                style={{ width: '25vw', maxWidth: '720px' }}
                                disabled={state.mode === 'review'}
                                errorMessage={errorMessages}
                            />
                            <FlexSpacer />
                            {branchDropdownBehavior.type === permissionKeyLabels.branch &&
                            userGroupData.userRoles.length !== 0 ? (
                                <FlexedDiv direction="column">
                                    <TextBold12 style={{ color: '#333333' }}>{LABEL.branchOffice}</TextBold12>

                                    <SelectWrapper>
                                        <SelectDropdown
                                            onChange={(value) => updateUserGroupDataSelect(value, 'branchOffices')}
                                            options={branchOptions.map((item) => {
                                                return { label: item.label, value: item.key as string };
                                            })}
                                            testId={`branchoffices-dropdown`}
                                            selectedValue={userGroupData.branchOffices[0] as string}
                                            isDisabled={state.mode === 'review'}
                                            placeHolder={LABEL.selectOneBranch}
                                        />
                                    </SelectWrapper>
                                </FlexedDiv>
                            ) : (
                                <MultiSelectV2
                                    label={LABEL.branchOffice}
                                    options={branchOptions}
                                    placeHolder={LABEL.selectOneOrMultipleBranch}
                                    checkedState={userGroupData.branchOffices}
                                    handleMultiSelect={(item, fn, id) => getSelectedList(item, id)}
                                    selectId={2}
                                    hasSelectAll
                                    noOverlay={false}
                                    expandable={state.mode === 'review'}
                                    disabled={
                                        branchDropdownBehavior.disabled ||
                                        userGroupData.userRoles.length === 0 ||
                                        state.mode === 'review'
                                    }
                                    selectAllLabel={LABEL.kibAllBranch}
                                    style={{ width: '25vw', maxWidth: '720px' }}
                                />
                            )}
                        </FlexedDiv>
                        {state.mode === 'edit' ? (
                            <FlexedDiv direction="column">
                                <FlexedDiv
                                    alignItems="center"
                                    style={{ marginTop: sh56, justifyContent: 'flex-start' }}
                                >
                                    <CustomButton
                                        onClick={() => history.push(ROUTES.dashboardSystemAdmin)}
                                        style={{ width: sh240, height: '40px' }}
                                    >
                                        {LABEL.cancel}
                                    </CustomButton>
                                    <CustomButton
                                        primary={true}
                                        style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                        onClick={() => {
                                            setShowEditModal(true);
                                        }}
                                        disabled={disabledSave}
                                    >
                                        {LABEL.save}
                                    </CustomButton>
                                </FlexedDiv>
                                <div style={{ height: sh56 }} />
                            </FlexedDiv>
                        ) : (
                            <div style={{ marginBottom: '180px' }}>
                                {state.status === LABEL.pendingApproval && showBanner ? (
                                    <Banner
                                        toggle={true}
                                        title="Pending Approval"
                                        descriptionEmText={userGroupData.author}
                                        description="Requested by"
                                        testId="editusergroup-banner"
                                        primaryButtonObject={{
                                            handlePrimaryBtn: () => handleApproveReject('approve'),
                                            label: LABEL.approve,
                                        }}
                                        secondaryButtonObject={{
                                            handleSecondaryBtn: () => setIsRejected(true),
                                            label: LABEL.reject,
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={
                        userType === LABEL.maker
                            ? `Edit User Group request submitted`
                            : userType === LABEL.autoAuthorizer
                            ? 'User Group Updated'
                            : rejectApproveText
                    }
                    primaryBtn={{
                        onClick: () => {
                            handleModal();
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    icon={
                        state.status === LABEL.pendingApproval
                            ? 'user-modal-success'
                            : userType === LABEL.autoAuthorizer
                            ? 'user-modal-success'
                            : 'user-modal-processing'
                    }
                    contentAlignment="center"
                    testId="edit-usergroup-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {state.mode === 'edit' ? (
                            userType === LABEL.maker ? (
                                <Fs16RegSecPrimaryBlack>{LABEL.submittedMsg}</Fs16RegSecPrimaryBlack>
                            ) : userType === LABEL.autoAuthorizer ? (
                                <div style={{ padding: '0rem 2rem', textAlign: 'center', display: 'block' }}>
                                    <Fs16SemiBoldSecPrimaryBlack>
                                        {userGroupData.name.trim()}&nbsp;
                                    </Fs16SemiBoldSecPrimaryBlack>
                                    <Fs16RegSecPrimaryBlack>{`has been updated`}</Fs16RegSecPrimaryBlack>
                                </div>
                            ) : (
                                <div style={{ padding: '0rem 2rem', textAlign: 'center', display: 'block' }}>
                                    <Fs16SemiBoldSecPrimaryBlack>
                                        {userGroupData.name.trim()}&nbsp;
                                    </Fs16SemiBoldSecPrimaryBlack>
                                    <Fs16RegSecPrimaryBlack>
                                        {getEditGroupModalMessage(state.source, isRejectedConfirm)}
                                    </Fs16RegSecPrimaryBlack>
                                </div>
                            )
                        ) : (
                            <div style={{ padding: '0rem 2rem', textAlign: 'center', display: 'block' }}>
                                {!isRejectedConfirm ? (
                                    <Fs16SemiBoldSecPrimaryBlack>
                                        {userGroupData.name.trim()}&nbsp;
                                    </Fs16SemiBoldSecPrimaryBlack>
                                ) : null}
                                <Fs16RegSecPrimaryBlack>
                                    {getEditGroupModalMessage(state.source, isRejectedConfirm)}
                                </Fs16RegSecPrimaryBlack>
                            </div>
                        )}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {validationModal ? (
                <Modal
                    modalActive={validationModal}
                    setModalActive={setValidationModal}
                    title={'Unable to edit user group'}
                    primaryBtn={{
                        onClick: () => {
                            setValidationModal(false);
                            history.push(dashboardSystemAdmin, { tab: 'User Groups' });
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    icon="suspend-failed"
                    contentAlignment="center"
                    testId="edit-usergroup-error-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {validationMsg}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {loading ? <ComponentLoader /> : null}
        </Container>
    );
};
const TitleContainer = styled.div`
    align-items: center;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const SelectWrapper = styled.div`
    width: 25vw;
    maxwidth: 720px;
`;
export default EditUserGroup;
