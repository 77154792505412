import gql from 'graphql-tag';

/**
 * @getTransactionStatusOverview @query - Common for all order summary
 * @description: Returns status overview for all process
 */

export const getTransactionStatusOverview = /* GraphQL */ gql`
    query getTransactionStatusOverview($input: TransactionStatusOverviewInput) {
        getTransactionStatusOverview(input: $input) {
            data {
                result {
                    statusOverview {
                        title: level
                        steps {
                            subText: createdOn
                            stepName: status
                            current
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
