/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, ReactText, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL } from '../../constants';
import { CustomButton, FlexedDiv, PreviousPage, TextArea, ComponentLoader, Modal } from '../../components';
import { reasonsPageButtons } from '../../styles';
import { useComponentLoader } from '../../customHooks';

import * as Routes from '../../routes';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import fundCheckerApproveMutation from '../../_graphql/mutations/fundData/checkerApprove';
import bulkUploadStatusMutation from '../../_graphql/mutations/fundData/bulkUploadStatus';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import WebSocketContext from '../../context/WebSocketContext';

interface IRouteProps {
    fundDocumentId: number;
    tab?: string;
    type: string;
    tabIndex?: number;
}

interface IBulkApproval {
    fundDocumentId: number;
    status: string;
    remark: ReactText;
}
const initialCheckerModal = {
    fundDocumentId: 0,
    tab: '',
    status: '',
    remark: '',
};
const initialCheckerBulkModal = {
    fundDocumentId: 0,
    status: '',
    remark: '',
};
const RejectImport: React.FC = () => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { ws } = useContext(WebSocketContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const history = useHistory();
    const [showModal, setShowModal] = useState(true);
    const [checkerModal, setCheckerModal] = useState<IFundApproval>(initialCheckerModal);
    const [checkerBulkModal, setCheckerBulkModal] = useState<IBulkApproval>(initialCheckerBulkModal);
    const [remarks, setRemarks] = useState<ReactText>('');
    const { state: propsData } = useLocation<IRouteProps>();
    // custom hooks
    const { loading, loadingHandler } = useComponentLoader();

    //Fn to handle submit
    const submitHandler = () => {
        if (propsData.tab === 'documents') {
            const checkerBulkPayload: IBulkApproval = { ...checkerBulkModal };
            checkerBulkPayload.remark = remarks;
            rejectBulkUploadMutation(checkerBulkPayload);
        } else {
            const checkerPayload: IFundApproval = { ...checkerModal };
            checkerPayload.remark = remarks.toString();
            initiateWebsocket() ? handleWebsocket(checkerPayload) : console.log('Unable to establlish WS');
            // rejectFundMutation(checkerPayload);
        }
    };

    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    //mutation function to reject fund
    const rejectFundMutation = async (checkerPayload: IFundApproval) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundCheckerApproveMutation, {
                    input: checkerPayload,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.fundStatus;
            if (error) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Reject ${propsData.type} request failed`,
                    testId: 'reject-import-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };
    //Fn to handle websocket listener
    const handleWebsocket = async (checkerPayload: IFundApproval) => {
        if (ws !== undefined) {
            rejectFundMutation(checkerPayload);
            ws.addEventListener('message', (event) => {
                // console.log('ws.addEventListener', event.data);
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);
                    // console.log(data, 'event.data');
                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;
                        // console.log(result, 'result');
                        if (result.status) {
                            loadingHandler();
                            handleShowConfirmation();
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;

                        setErrorMessage({
                            ...errorMessage,
                            errorCode: error.errorCode,
                            message: error.message,
                            title: `Reject ${propsData.type} request failed`,
                            testId: 'reject-import-error-modal',
                        });
                        handleErrorHandler();
                    }
                }
            });
        }
    };
    //Fn to initiate websocket connection
    const initiateWebsocket = (): boolean => {
        if (ws !== undefined) {
            loadingHandler();
            ws.send('Ping');
            return true;
        } else {
            return false;
        }
    };
    //mutation function to reject bulkuploads
    const rejectBulkUploadMutation = async (bulkUploadPayload: IBulkApproval) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(bulkUploadStatusMutation, {
                    input: bulkUploadPayload,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.bulkUploadStatus;
            if (!error) {
                handleShowConfirmation();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: error.errorCode,
                    message: error.message,
                    title: `Reject ${propsData.type} request failed`,
                    testId: 'reject-import-error-modal',
                });
                handleErrorHandler();
            }
        } catch (error) {}
    };

    //Fn to handle confirmation modal
    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };
    //Fn to handle route back
    const goBacktoUploadDashboard = () => {
        history.push({
            pathname: Routes.financeUploads,
            state: {
                activeTab: propsData.tabIndex !== undefined ? propsData.tabIndex : 0,
            },
        });
    };
    useEffect(() => {
        propsData.tab === 'documents'
            ? setCheckerBulkModal({
                  ...checkerBulkModal,
                  fundDocumentId: propsData.fundDocumentId,
                  status: 'reject',
              })
            : setCheckerModal({
                  ...checkerModal,
                  fundDocumentId: propsData.fundDocumentId,
                  tab: propsData.tab ? propsData.tab : '',
                  status: 'reject',
              });
    }, []);
    return (
        <Fragment>
            <PreviousPage title={LABEL.rejectRemark} backIcon={false} />
            <StyledInner>
                <PageDescription>You’re about to reject this request.</PageDescription>
                <TextArea
                    label="Remarks"
                    name="remarks"
                    value={remarks}
                    handleInput={handleInput}
                    maxLength={255}
                    testId="reject-remarks-textarea"
                    style={{ width: '492px' }}
                />
                <ButtonDiv>
                    <CustomButton style={reasonsPageButtons} onClick={goBacktoUploadDashboard} id="reject-cancel-btn">
                        {LABEL.cancel}
                    </CustomButton>
                    <CustomButton
                        primary
                        style={reasonsPageButtons}
                        onClick={() => submitHandler()}
                        id="reject-submit-btn"
                    >
                        {LABEL.submit}
                    </CustomButton>
                </ButtonDiv>
            </StyledInner>
            {!showModal ? (
                <Modal
                    modalActive={!showModal}
                    setModalActive={handleShowConfirmation}
                    title={`${propsData.type} request rejected`}
                    primaryBtn={{
                        onClick: () => {
                            goBacktoUploadDashboard();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="reject-import-modal"
                    contentAlignment="center"
                    icon="approved-transaction"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`Current ${propsData.type} will not be changed  and maker will be notified.`}{' '}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader position="fixed" backgroundColor="#0000001a" /> : null}
        </Fragment>
    );
};

const StyledInner = styled.div`
    margin-left: 0rem;
`;

const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: -0.05px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    max-width: 25vw;
    margin-top: 2.25rem;
`;

export default RejectImport;
