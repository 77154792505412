export interface ILoginRoleSection {
    permissionLevel: string;
    isAll: boolean;
    login: {
        isAll: boolean;
        actions: boolean[];
    };
    inbox: {
        isAll: boolean;
        actions: boolean[];
    };
    profile: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IUserManagementSection {
    permissionLevel: string;
    isAll: boolean;
    userTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    userGroupTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    rolesPermissionTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
}

export interface IActivityLogsSection {
    permissionLevel: string;
    isAll: boolean;
    userActivityTab: {
        isAll: boolean;
        actions: boolean[];
    };
    reportsTab: {
        isAll: boolean;
        actions: boolean[];
    };
}
export interface ISystemSettingsSection {
    permissionLevel: string;
    isAll: boolean;
    epfTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
}
export interface ICustomerExperienceSection {
    permissionLevel: string;
    isAll: boolean;
    overview: {
        isAll: boolean;
        actions: boolean[];
    };
    allBranch: {
        isAll: boolean;
        actions: boolean[];
    };
    pendingTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    upcomingTab: {
        isAll: boolean;
        actions: boolean[];
    };
    hardCopyTab: {
        isAll: boolean;
        actions: boolean[];
    };
    reRoutedTab: {
        isAll: boolean;
        actions: boolean[];
    };
    historyTab: {
        isAll: boolean;
        actions: boolean[];
    };
    dueDateTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
}

export interface IBranchOfficeSection {
    permissionLevel: string;
    isAll: boolean;
    allBranchTab: {
        isAll: boolean;
        actions: boolean[];
    };
    advisersTab: {
        isAll: boolean;
        actions: boolean[];
    };
    investorsTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IEDDCasesSection {
    permissionLevel: string;
    isAll: boolean;
    newTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    reRoutedTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    historyTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IFinanceDashboardSection {
    permissionLevel: string;
    isAll: boolean;
    overview: {
        isAll: boolean;
        actions: boolean[];
    };
    branchVerifiedTab: {
        isAll: boolean;
        actions: boolean[];
    };
    moneySightedFundTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IFinanceCasesSection {
    permissionLevel: string;
    isAll: boolean;
    reRoutedTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    historyTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IProductSettings {
    permissionLevel: string;
    isAll: boolean;
    allFundsTab: {
        isAll: boolean;
        actions: boolean[];
    };
    allAmpTab: {
        isAll: boolean;
        actions: boolean[];
    };
    navPerUnitTab: {
        isAll: boolean;
        actions: boolean[];
    };
    distributionTab: {
        isAll: boolean;
        actions: boolean[];
    };
    documentsTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IFundUploads {
    permissionLevel: string;
    isAll: boolean;
    masterFundTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    navPerUnitTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    distributionTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    documentsTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
}

export interface IOperationDashboard {
    permissionLevel: string;
    isAll: boolean;
    overview: {
        isAll: boolean;
        actions: boolean[];
    };
    branchVerifiedTab: {
        isAll: boolean;
        actions: boolean[];
    };
    moneySightedFundTab: {
        isAll: boolean;
        actions: boolean[];
    };
    epfTab: {
        isAll: boolean;
        actions: boolean[];
    };
    ddaTab: {
        isAll: boolean;
        actions: boolean[];
    };
    financeReRoutedTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IOperationCases {
    permissionLevel: string;
    isAll: boolean;
    reRoutedTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    rejectedTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
    historyTab: {
        isAll: boolean;
        actions: boolean[];
        reviewApproval: boolean[];
    };
}

export const hqLoginAccessInitialData: ILoginRoleSection = {
    permissionLevel: 'General Access',
    isAll: true,
    login: {
        isAll: true,
        actions: [],
    },
    inbox: {
        isAll: true,
        actions: [true],
    },
    profile: {
        isAll: true,
        actions: [true],
    },
};

export const customerExperienceInitialData: ICustomerExperienceSection = {
    permissionLevel: 'Maker',
    isAll: false,
    overview: {
        isAll: false,
        actions: [false],
    },
    allBranch: {
        isAll: false,
        actions: [false, false],
    },
    pendingTab: {
        isAll: false,
        actions: [false, false, false, false, false, false, false, false],
        reviewApproval: [false, false, false],
    },
    upcomingTab: {
        isAll: false,
        actions: [false, false, false],
    },
    hardCopyTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
    reRoutedTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
    historyTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
    dueDateTab: {
        isAll: false,
        actions: [false, false, false],
        reviewApproval: [false],
    },
};
export const userManagementInitialData: IUserManagementSection = {
    permissionLevel: 'Maker',
    isAll: false,
    userTab: {
        isAll: false,
        actions: [false, false, false, false, false, false, false, false, false],
        reviewApproval: [false, false, false, false, false, false],
    },
    userGroupTab: {
        isAll: false,
        actions: [false, false, false, false, false, false, false],
        reviewApproval: [false, false, false],
    },
    rolesPermissionTab: {
        isAll: false,
        actions: [false, false, false, false, false, false, false],
        reviewApproval: [false, false, false, false, false, false],
    },
};

export const activityLogsInitialData: IActivityLogsSection = {
    permissionLevel: 'General Access',
    isAll: false,
    userActivityTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
    reportsTab: {
        isAll: false,
        actions: [false, false],
    },
};

export const systemSettingsInitialData: ISystemSettingsSection = {
    permissionLevel: 'Maker',
    isAll: false,
    epfTab: {
        isAll: false,
        actions: [false],
        reviewApproval: [false],
    },
};

export const branchOfficesInitialData: IBranchOfficeSection = {
    permissionLevel: 'General Access',
    isAll: false,
    allBranchTab: {
        isAll: false,
        actions: [false, false, false],
    },
    advisersTab: {
        isAll: false,
        actions: [false, false, false],
    },
    investorsTab: {
        isAll: false,
        actions: [false],
    },
};

export const eddCaseInitialData: IEDDCasesSection = {
    permissionLevel: 'Auto-Authorizer',
    isAll: false,
    newTab: {
        isAll: false,
        actions: [false, false, false, false, false, false, false],
        reviewApproval: [false, false],
    },
    reRoutedTab: {
        isAll: false,
        actions: [false, false, false, false, false],
        reviewApproval: [false],
    },
    historyTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
};

export const financeDashboardInitialData: IFinanceDashboardSection = {
    permissionLevel: 'Maker',
    isAll: false,
    overview: {
        isAll: false,
        actions: [false],
    },
    branchVerifiedTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
    moneySightedFundTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
};

export const financeCasesInitialData: IFinanceCasesSection = {
    permissionLevel: 'General Access',
    isAll: false,
    reRoutedTab: {
        isAll: false,
        actions: [false, false, false],
        reviewApproval: [false],
    },
    historyTab: {
        isAll: false,
        actions: [false, false, false],
    },
};

export const productSettingsInitialData: IProductSettings = {
    permissionLevel: 'General Access',
    isAll: false,
    allFundsTab: {
        isAll: false,
        actions: [false, false, false],
    },
    allAmpTab: {
        isAll: false,
        actions: [false, false, false],
    },
    navPerUnitTab: {
        isAll: false,
        actions: [false, false, false],
    },
    distributionTab: {
        isAll: false,
        actions: [false, false, false],
    },
    documentsTab: {
        isAll: false,
        actions: [false, false, false, false, false, false],
    },
};

export const fundUploadsInitialData: IFundUploads = {
    permissionLevel: 'Maker',
    isAll: false,
    masterFundTab: {
        isAll: false,
        actions: [false, false, false, false],
        reviewApproval: [false],
    },
    navPerUnitTab: {
        isAll: false,
        actions: [false, false, false, false],
        reviewApproval: [false],
    },
    distributionTab: {
        isAll: false,
        actions: [false, false, false, false],
        reviewApproval: [false],
    },
    documentsTab: {
        isAll: false,
        actions: [false, false, false, false],
        reviewApproval: [false],
    },
};

export const operationDashboardInitialData: IOperationDashboard = {
    permissionLevel: 'Maker',
    isAll: false,
    overview: {
        isAll: false,
        actions: [false],
    },
    branchVerifiedTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
    moneySightedFundTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
    epfTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
    ddaTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
    financeReRoutedTab: {
        isAll: false,
        actions: [false, false, false, false, false],
    },
};

export const operationCasesInitialData: IOperationCases = {
    permissionLevel: 'General Access',
    isAll: false,
    reRoutedTab: {
        isAll: false,
        actions: [false, false, false],
        reviewApproval: [false],
    },
    rejectedTab: {
        isAll: false,
        actions: [false, false, false],
        reviewApproval: [false],
    },
    historyTab: {
        isAll: false,
        actions: [false, false, false],
        reviewApproval: [false],
    },
};
