import React, { Fragment, useContext, useEffect, useState } from 'react';
import { PreviousPage } from '../../../components/molecules';
import DashboardBranch from '../../DashboardBranch/DashboardBranch';
import * as ROUTES from '../../../routes';
import { useLocation } from 'react-router';
import { getBranchName } from '../../../utils';
import AuthContext from '../../../context/AuthContext';
import { PendingSubmission } from '../../PendingSubmission';
interface IViewBranchRouteProps {
    fromInbox?: {
        currentBranchId: string;
        status: boolean;
    };
}
export const ViewBranch = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const location = useLocation();
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const { state } = useLocation<IViewBranchRouteProps>();
    const [name, setName] = useState<string>('');
    useEffect(() => {
        if (state?.fromInbox?.status) {
            getBranchName(state.fromInbox.currentBranchId, idTokenHeader)
                .then((value) => {
                    setName(value);
                })
                .catch();
        } else {
            const temp = sessionStorage.getItem('branchName') as string;
            setName(temp);
        }
    }, []);

    return (
        <Fragment>
            <PreviousPage title={name as string} handleBack={ROUTES.dashboard} />
            {location.pathname === ROUTES.hqPendingSubmission ? <PendingSubmission /> : <DashboardBranch />}
        </Fragment>
    );
};
export default ViewBranch;
