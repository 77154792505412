import React, { useContext } from 'react';
import { AssignedAdviser } from './AssignedAdviser';
import { CaseSummary } from './CaseSummary';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../../../../routes';
import { Tabs } from '../../../../../components/organisms/Tabs/Tabs';
import { LABEL } from '../../../../../constants';
import EDDContext from '../../../../../context/EDDContext/EDDContext';

export const CancelledSatisfactoryCase = (): JSX.Element => {
    const history = useHistory();

    const { getCaseDetails, caseDetails } = useContext(EDDContext);

    // console.log('KKKK', caseDetails);

    const tabs = [<CaseSummary key={1} />, <AssignedAdviser key={2} />];

    const handleBack = () => {
        history.push(ROUTES.amlaEDDManagement, { currentTab: 'History' });
    };

    React.useEffect(() => {
        getCaseDetails(0);
    }, []);

    return (
        <React.Fragment>
            <div style={{ position: 'relative' }}>
                <Tabs
                    tabTitles={[LABEL.caseSummary, LABEL.assignedAdviser]}
                    title={caseDetails.name}
                    suffixHelper={caseDetails.status}
                    tabs={tabs}
                    handleBackFunction={handleBack}
                />
            </div>
        </React.Fragment>
    );
};
