/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { camelCaseToTitleCase } from './camelCaseToTitleCase';
import { emptyCheck } from './emptyCheck';
import { exportXLSX } from './exportXLSX';

export const exportList = (dashboard: string, tab: string, data: any[]): void => {
    const fileName = `Export_List_${dashboard}_${camelCaseToTitleCase(tab)}_${moment().format('DD/MM/YYYY_ h:mm:ssa')}`;

    const keys = Object.keys(data[0]);

    const list: { [k: string]: any }[] = [];
    data.map((item) => {
        const tempObj = [];

        for (let i = 0; i < keys.length; i++) {
            const temp = [];
            const currentKey = keys[i];

            switch (keys[i]) {
                case 'agentCount':
                    temp.push('No. of Advisers', emptyCheck(item[currentKey]));
                    break;
                case 'clientCount':
                    temp.push('No. of Investors', emptyCheck(item[currentKey]));
                    break;
                case 'clientName':
                    temp.push('Investor Name', emptyCheck(item[currentKey]));
                    break;
                case 'eventType':
                    let eventType = '';
                    eventType = item[currentKey] !== null ? item[currentKey] : '';
                    temp.push(camelCaseToTitleCase(currentKey), emptyCheck(eventType));
                    break;
                case 'name':
                    temp.push('Branch Name', emptyCheck(item[currentKey]));
                    break;
                case 'omniEnabled':
                    const enabled = item[currentKey] === 0 ? 'False' : 'True';
                    temp.push(camelCaseToTitleCase(currentKey), emptyCheck(enabled));
                    break;
                case 'code':
                    temp.push('Branch Code', emptyCheck(item[currentKey]));
                    break;
                case 'dailyCount':
                    temp.push('No. of Daily Transactions', emptyCheck(item[currentKey]));
                    break;
                case 'hardcopyCount':
                    temp.push('No. of Hardcopy Pending', emptyCheck(item[currentKey]));
                    break;
                case 'rerouteCount':
                    temp.push('No. of Reroute Transactions', emptyCheck(item[currentKey]));
                    break;
                case 'clientIdNum':
                    temp.push('Investor ID No', emptyCheck(item[currentKey]));
                    break;
                case 'fundType':
                    temp.push('Product', emptyCheck(item[currentKey]));
                    break;
                case 'transactionRef':
                    item[currentKey] &&
                        temp.push('Transaction No', emptyCheck(`${item['orderNo'] + item[currentKey]}`));
                    break;
                case 'paymentMethod':
                    temp.push('Payment Method', emptyCheck(item[currentKey]));
                    break;
                case 'agentName':
                    temp.push('Adviser Name', emptyCheck(item[currentKey]));
                    break;
                case 'agentCode':
                    temp.push('Adviser ID', emptyCheck(item[currentKey]));
                    break;
                case 'transactionRef':
                    item[currentKey] &&
                        temp.push('Transaction No', emptyCheck(`${item['orderNo'] + item[currentKey]}`));
                    break;
                case 'totalInvestment':
                    let investment = '';
                    item[currentKey].map((item: { [s: string]: unknown } | ArrayLike<unknown>) => {
                        const value = Object.values(item).join(',');

                        investment += `${value}`;
                    });

                    temp.push(camelCaseToTitleCase(currentKey), emptyCheck(investment));
                    break;
                case 'userGroup':
                    let group = '';

                    const groupArray: string[] = item[currentKey];
                    group = item[currentKey] !== null ? groupArray.join() : '';
                    temp.push(camelCaseToTitleCase(currentKey), group);
                    break;
                case 'branch':
                    let branch = '';

                    const branchArray: string[] =
                        typeof item[currentKey] === 'string' ? [item[currentKey]] : item[currentKey];
                    branch = item[currentKey] !== null ? branchArray.join() : '';
                    temp.push(camelCaseToTitleCase(currentKey), branch);
                    break;
                case 'remarks':
                    let remarks = '';
                    item[currentKey] !== null
                        ? item[currentKey].map((item: { title: any; content: any[] }) => {
                              const title = item.title;
                              const value = item.content.join(',');
                              remarks += `${title}: ${value}.\n`;
                          })
                        : '';
                    temp.push(camelCaseToTitleCase(currentKey), remarks);
                    break;
                case 'roles':
                    let roles = '';

                    const roleArray: string[] = item[currentKey];
                    roles = item[currentKey] !== null ? roleArray.join() : '';
                    temp.push(camelCaseToTitleCase(currentKey), roles);
                    break;
                case 'timestamp':
                case 'lastLogin':
                case 'lastUpdated':
                case 'targetDate':
                case 'submittedOn':
                case 'createdOn':
                    const date =
                        item[currentKey] !== null ? moment(item[currentKey], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push(camelCaseToTitleCase(currentKey), emptyCheck(date));
                    break;
                default:
                    temp.push(camelCaseToTitleCase(currentKey), emptyCheck(item[currentKey]));
                    break;
            }
            tempObj.push(temp);
        }
        const obj = Object.fromEntries(tempObj);
        if (obj.hasOwnProperty('Transaction No')) delete obj['Order No'];
        delete obj.undefined;
        list.push(obj);
    });

    exportXLSX(list, fileName);
};
