/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment, useState, FunctionComponent, useEffect } from 'react';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { FlexedDiv, CustomSpacer, CustomDropdownV2, Tooltip } from '../../../../../components';
import { IcoMoon } from '../../../../../icons';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import {
    IUserManagementSection,
    IActivityLogsSection,
    ISystemSettingsSection,
} from '../../AddRoles/HqPrivileges/hqRolesInitialData';
import { CollapsibleTooltipMessage } from '../BranchPrivileges';
import styled from 'styled-components';
import { handleChangeData, handleUserChange, hasKey } from '../../utils/functions';
import { PermissionLevelInfoTemplate } from '../../../../../components/atoms/TooltipTemplates';

export interface UserManagementProps {
    approval: boolean;
    expanded: boolean[];
    handleExpand: (index: number) => void;
    userManagement: IUserManagementSection;
    setUserManagement: (data: IUserManagementSection) => void;
    activityLogs: IActivityLogsSection;
    setActivityLogs: (data: IActivityLogsSection) => void;
    systemSettings: ISystemSettingsSection;
    setSystemSettings: (data: ISystemSettingsSection) => void;
    setSystemAdminData: (
        newData: IUserManagementSection | IActivityLogsSection | ISystemSettingsSection,
        module: string,
    ) => void;
}

export const UserPrivileges: FunctionComponent<UserManagementProps> = ({
    approval,
    expanded,
    userManagement,
    setUserManagement,
    handleExpand,
    activityLogs,
    setActivityLogs,
    systemSettings,
    setSystemSettings,
    setSystemAdminData,
}: UserManagementProps) => {
    const setInitialValue = () => {
        let accessLevelObject = { label: '', value: '' };
        switch (userManagement.permissionLevel.toLowerCase()) {
            case 'maker':
                accessLevelObject = { label: 'Maker', value: 'maker' };
                break;
            case 'maker (edited)':
                accessLevelObject = { label: 'Maker (Edited)', value: 'maker' };
                break;
            case 'checker':
                accessLevelObject = { label: 'Checker', value: 'checker' };
                break;
            case 'checker (edited)':
                accessLevelObject = { label: 'Checker (Edited)', value: 'checker' };
                break;
            case 'auto-authorizer':
                accessLevelObject = { label: 'Auto-Authorizer', value: 'autoAuthorizer' };
                break;
            case 'auto-authorizer (edited)':
                accessLevelObject = { label: 'Auto-Authorizer (Edited)', value: 'autoAuthorizer' };
                break;
            default:
                accessLevelObject = { label: 'Maker', value: 'maker' };
        }
        return accessLevelObject;
    };
    const setInitialSettingsDropdownValues = () => {
        let accessLevelObject = { label: '', value: '' };
        switch (systemSettings.permissionLevel.toLowerCase()) {
            case 'maker':
                accessLevelObject = { label: 'Maker', value: 'maker' };
                break;
            case 'maker (edited)':
                accessLevelObject = { label: 'Maker (Edited)', value: 'maker' };
                break;
            case 'checker':
                accessLevelObject = { label: 'Checker', value: 'checker' };
                break;
            case 'checker (edited)':
                accessLevelObject = { label: 'Checker (Edited)', value: 'checker' };
                break;
            default:
                accessLevelObject = { label: 'Maker', value: 'maker' };
        }
        return accessLevelObject;
    };
    const [userManagementDropdown, setUserManagementDropdown] = useState<ISelectValue>(setInitialValue());
    const [systemSettingsDropdown, setSystemSettingsDropdown] = useState<ISelectValue>(
        setInitialSettingsDropdownValues(),
    );
    const [initial, setInitial] = useState<boolean>(true);

    const handleUserManagement = () => {
        if (approval === false) {
            const temp = { ...userManagement };
            const newData = handleChangeData(temp);
            setSystemAdminData(newData as IUserManagementSection, 'userManagement');
        }
        // setUserManagement(newData as IUserManagementSection);
    };

    const handleActivityLogs = () => {
        if (approval === false) {
            const temp = { ...activityLogs, permissionLevel: activityLogs.isAll ? 'General Access' : 'General Access' };
            const newData = handleChangeData(temp);
            setSystemAdminData(newData as IActivityLogsSection, 'activityLogs');
        }
    };

    const handleSystemSettings = () => {
        if (approval === false) {
            const temp = { ...systemSettings };
            const newData = handleChangeData(temp);
            setSystemAdminData(newData as ISystemSettingsSection, 'systemSettings');
        }
    };

    const handleUserManagementDropdown = (item: string) => {
        let temp: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item) {
            case 'Maker':
                temp = { label: item, value: 'maker' };
                break;
            case 'Checker':
                temp = { label: item, value: 'checker' };
                break;
            case 'Auto-Authorizer':
                temp = { label: item, value: 'autoAuthorizer' };
                break;
        }
        setUserManagementDropdown(temp);
    };
    const handleSystemSettingsDropdown = (item: string) => {
        let temp: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item) {
            case 'Maker':
                temp = { label: item, value: 'maker' };
                break;
            case 'Checker':
                temp = { label: item, value: 'checker' };
                break;
            case 'Auto-Authorizer':
                temp = { label: item, value: 'autoAuthorizer' };
                break;
        }
        setSystemSettingsDropdown(temp);
    };
    const userManagementDropdownOptions: IDropdownItemV2[] = [
        { item: 'Maker', value: 'maker', handleItem: handleUserManagementDropdown },
        { item: 'Checker', value: 'checker', handleItem: handleUserManagementDropdown },
        { item: 'Auto-Authorizer', value: 'autoAuthorizer', handleItem: handleUserManagementDropdown },
    ];
    const systemSettingsDropdownOptions: IDropdownItemV2[] = [
        { item: 'Maker', value: 'maker', handleItem: handleSystemSettingsDropdown },
        { item: 'Checker', value: 'checker', handleItem: handleSystemSettingsDropdown },
    ];
    useEffect(() => {
        if (initial === false) {
            const temp = userManagementData;
            const tempSettings = systemSettingsData;
            const stateData = { ...userManagement };
            const settingsData = { ...systemSettings };

            const newData: IUserManagementSection = handleUserChange(
                temp!,
                stateData,
                userManagementDropdown,
            ) as IUserManagementSection;

            setUserManagement(newData);

            const newSettingsData: ISystemSettingsSection = handleUserChange(
                tempSettings!,
                settingsData,
                systemSettingsDropdown,
            ) as ISystemSettingsSection;

            setSystemSettings(newSettingsData);
        }
    }, [userManagementDropdown, systemSettingsDropdown]);

    useEffect(() => {
        setInitial(false);
    }, []);

    const userManagementData = hasKey(HQ_ROLE.userManagement.data, userManagementDropdown.value)
        ? HQ_ROLE.userManagement.data[userManagementDropdown.value]
        : undefined;

    const systemSettingsData = hasKey(HQ_ROLE.systemSettings.data, systemSettingsDropdown.value)
        ? HQ_ROLE.systemSettings.data[systemSettingsDropdown.value]
        : undefined;

    return (
        <Fragment>
            <Collapsible
                checked={userManagement.isAll}
                checkboxDisabled={approval}
                expanded={expanded[0]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <CustomDropdownV2
                                    items={userManagementDropdownOptions}
                                    noMinWidth={false}
                                    showDivider={false}
                                    maxWidth={'22.5rem'}
                                    disableDropdown={approval}
                                >
                                    <DropdownBase>
                                        <BasicInput value={userManagement.permissionLevel} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </CustomDropdownV2>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={userManagement.isAll}
                            isDisabled={approval}
                            item={userManagementData}
                            data={userManagement}
                            setData={setUserManagement as ISetSectionTypes}
                            offsetArray={[3, 2, 2]}
                            user={userManagementDropdown.value}
                        />
                    </Fragment>
                }
                handleExpand={handleExpand}
                handleChecked={handleUserManagement}
                index={1}
                isCheckable={true}
                title={HQ_ROLE.userManagement.title}
                subtitle={HQ_ROLE.userManagement.subtitle}
                testId="user-management-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'1rem'} />
            <Collapsible
                checked={activityLogs.isAll}
                checkboxDisabled={approval}
                expanded={expanded[1]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput
                                        style={{ backgroundColor: 'transparent' }}
                                        disabled={true}
                                        value={activityLogs.permissionLevel}
                                    />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={activityLogs.isAll}
                            isDisabled={approval}
                            item={HQ_ROLE.activityLogs.data.general}
                            data={activityLogs}
                            setData={setActivityLogs as ISetSectionTypes}
                        />
                    </Fragment>
                }
                handleChecked={handleActivityLogs}
                handleExpand={handleExpand}
                index={2}
                isCheckable={true}
                title={HQ_ROLE.activityLogs.title}
                subtitle={HQ_ROLE.activityLogs.subtitle}
                testId="activity-logos-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'1rem'} />
            <Collapsible
                checked={systemSettings.isAll}
                checkboxDisabled={approval}
                expanded={expanded[2]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <CustomDropdownV2
                                    items={systemSettingsDropdownOptions}
                                    noMinWidth={false}
                                    showDivider={false}
                                    maxWidth={'22.5rem'}
                                    disableDropdown={approval}
                                >
                                    <DropdownBase>
                                        <BasicInput value={systemSettings.permissionLevel} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </CustomDropdownV2>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={
                                    <PermissionLevelInfoTemplate
                                        message={[CollapsibleTooltipMessage[1], CollapsibleTooltipMessage[2]]}
                                    />
                                }
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={systemSettings.isAll}
                            item={systemSettingsData}
                            data={systemSettings}
                            setData={setSystemSettings as ISetSectionTypes}
                            user={systemSettingsDropdown.value}
                            isDisabled={approval}
                        />
                    </Fragment>
                }
                handleChecked={handleSystemSettings}
                handleExpand={handleExpand}
                index={8}
                isCheckable={true}
                title={SYSTEM_ADMIN.ADD_ROLE.LABEL_SYSTEM_SETTINGS}
                subtitle={SYSTEM_ADMIN.ADD_ROLE.LABEL_SYSTEM_SETTINGS_SUB}
                testId="system-settings-accordion"
                noXMargin={true}
                noMargin={true}
            />
        </Fragment>
    );
};

const DropdownBase = styled.div`
    display: 'flex';
    background-color: 'red';
    flex-direction: 'row';
    background-color: white;
    border-color: #c9c9cd;
    border-width: 0.0625rem;
    border: 1px solid #c9c9cd;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;
    height: 2.5rem;
    width: 22.5rem;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

const BasicInput = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 18.8rem;
`;

const InputText = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #333333;
    opacity: 0.5;
`;
