import gql from 'graphql-tag';

/**
 * @DistributionHistoryQuery @query - Distribution History
 * @description: Returns NAV and Distribution History
 */

export const navPerUnitHistorydQuery = gql`
    query distributionHistory($input: DistributionHistoryInput) {
        distributionHistory(input: $input) {
            data {
                result {
                    message
                    status
                    fundClass
                    result {
                        closingDate
                        fundCurrency
                        latestNavPerUnit
                        change
                        changePercentage
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export const grossDistributionHistorydQuery = gql`
    query distributionHistory($input: DistributionHistoryInput) {
        distributionHistory(input: $input) {
            data {
                result {
                    message
                    status
                    fundClass
                    result {
                        closingDate
                        fundCurrency
                        grossDistribution
                        change
                        changePercentage
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export const netDistributionHistorydQuery = gql`
    query distributionHistory($input: DistributionHistoryInput) {
        distributionHistory(input: $input) {
            data {
                result {
                    message
                    status
                    fundClass
                    result {
                        closingDate
                        fundCurrency
                        netDistribution
                        change
                        changePercentage
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export const unitDistributionHistorydQuery = gql`
    query distributionHistory($input: DistributionHistoryInput) {
        distributionHistory(input: $input) {
            data {
                result {
                    message
                    status
                    fundClass
                    result {
                        closingDate
                        unitSplit
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
