import React, { Fragment, useContext } from 'react';

import { AdvanceTable } from '../../../../components';
import { EMPTY_STATES, LABEL, SYSTEM_ADMIN } from '../../../../constants';
import { subTitleStyle } from '../../../../styles';
import { statusStyle } from '../../../../utils';
import CustomItem from './CustomItem';

import { useHistory } from 'react-router-dom';
import { handleRouteRedirect } from './functions';

import ActivityLogsContext from '../../../../context/SystemAdminContext/activityLogs';
declare interface IUserActivityProps {
    searchEmpty: boolean;
}

export interface IData {
    id: string;
    approval?: boolean;
    title?: string;
    approvalType?: 'reroute' | 'reject';
    status?: string;
    maker?: boolean;
}

export interface IRouteAndData {
    route: string;
    data: IData;
}

export const UserActivity: React.FC<IUserActivityProps> = ({ searchEmpty }: IUserActivityProps) => {
    // Context

    const { allActivityData, searchInput, sortData, setSortData } = useContext(ActivityLogsContext);

    const history = useHistory();

    const handleSort = (keyName: string) => {
        const tempSort = { ...sortData };
        const sortType = sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortData.column === keyName) {
            const icon = sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };

    const handleRedirect = (item: ITableData) => {
        const data: IData = { id: item.rawData.id };

        if (typeof item.rawData.status === 'string' && item.rawData.status.toString().toLowerCase() !== 'failed') {
            const routeAndData: IRouteAndData = handleRouteRedirect(item.rawData.action, data);

            history.push(routeAndData.route, routeAndData.data);
        }
    };

    const handleShowIcon = (item: IColumnItemAccordion): ITableIcon => {
        const itemIcon: ITableIcon = {
            name: 'eye-show',
            size: '1.5rem',
        };
        if (typeof item.rawData.status === 'string' && item.rawData.status.toString().toLowerCase() !== 'failed') {
            itemIcon.style = { opacity: 1 };
        } else {
            itemIcon.style = { opacity: 0.6, pointerEvents: 'none', cursor: 'none' };
        }
        return itemIcon;
    };

    const columns: ITableColumn[] = [
        {
            key: [
                {
                    key: 'operationName',
                    textStyle: { fontWeight: sortData.column === 'operationName' ? 700 : 400 },
                },
            ],

            viewStyle: {
                width: '18.8vw',
            },
            icon: {
                name: handleSortIcon('operationName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('operationName'),
            title: SYSTEM_ADMIN.LABEL_OPERATION_NAME,
            titleStyle: {
                fontWeight: sortData.column === 'operationName' ? 700 : 400,
            },
        },
        {
            key: [
                {
                    key: 'name',
                    textStyle: { fontWeight: sortData.column === 'name' ? 700 : 400 },
                },
                { key: 'userName', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '13.3vw',
            },
            title: SYSTEM_ADMIN.LABEL_INITIATED_BY,
            titleStyle: {
                fontWeight: sortData.column === 'name' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('name'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('name'),
            customItem: true,
        },
        {
            customItem: true,
            key: [{ key: 'roles' }],
            viewStyle: {
                width: '12.2vw',
            },
            title: SYSTEM_ADMIN.LABEL_ROLES,
            titleStyle: {
                fontWeight: sortData.column === 'roles' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('roles'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('roles'),
        },
        {
            key: [{ key: 'eventType', textStyle: { fontWeight: sortData.column === 'eventType' ? 700 : 400 } }],
            viewStyle: {
                width: '10vw',
            },
            title: SYSTEM_ADMIN.LABEL_EVENT_TYPE,
            titleStyle: {
                fontWeight: sortData.column === 'eventType' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('eventType'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('eventType'),
        },
        {
            customItem: true,
            key: [{ key: 'createdOn' }],
            viewStyle: {
                width: '8.472vw',
            },
            title: SYSTEM_ADMIN.LABEL_CREATED_ON,
            titleStyle: {
                fontWeight: sortData.column === 'createdOn' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('createdOn'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('createdOn'),
        },

        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: '8.89vw',
            },
            title: LABEL.status,
            titleStyle: {
                fontWeight: sortData.column === 'status' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('status'),
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            getItem: handleShowIcon,
            viewStyle: {
                width: '3.19vw',
                paddingLeft: '1rem',
            },
            title: SYSTEM_ADMIN.LABEL_VIEW,
            onClickItem: (item: IColumnItemAccordion) => handleRedirect(item),
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allActivityData}
                columns={columns}
                onEmptyState={EMPTY_STATES.emptyStateActivityLogs}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData.column} {...props} />}
                isSearchResult={searchEmpty}
                searchInput={searchInput.value}
            />
        </Fragment>
    );
};
