/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PreviousPage, TableSheet } from '../../../../components';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import fileCheckQuery from '../../../../_graphql/queries/fundData/documentDashboard/fileNameCheck';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

interface IRouteProps {
    id: string;
    maker: boolean;
}

type BulkDocument = {
    fileName: string;
    fileSize: number;
    fundAbbr: string;
    documentType: string;
    signedUrl: string;
};
interface StyledInnerProps {
    paddingBottom: number;
}
type fundData = {
    fundAbbr: string;
    fundName: string;
};
// masterdata table fixed column widths
const fixedCoLumnlWidth = {
    firstColWidth: 96,
    secondColWidth: 260,
    thirdColWidth: 62,
};
export const BulkUploadActivity: React.FC = () => {
    // states for modals, title and description
    const [operationName, setOperationName] = useState<string>('');
    // states to generate table
    const [columns, setColumns] = useState<ISheetColumn[]>([]);
    const [rows, setRows] = useState<ISheetCell[][]>([]);
    const [externalLinks, setExternalLinks] = useState<string[]>();
    const [isProcessing, setIsProcessing] = useState(false);
    const { state: propsData } = useLocation<IRouteProps>();
    const [fundNames, setFundNames] = useState<fundData[]>([]);
    const [fundAbbr, setfundAbbr] = useState<string[]>([]);
    const [bulkDocumentsTableData, setBulkDocumentsTableData] = useState<BulkDocument[]>([]);
    const [generateTable, setGenerateTable] = useState<boolean>(false);
    // states to handle errors
    const [bannerHeight] = useState<number>(0);

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    //call query to get bulkupload documents by  id
    const getFundUploadDataQuery = async (id: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );
            const { error } = response.data.getLogData;
            if (!error) {
                const { result } = await response.data.getLogData.data;

                setOperationName(result.data.operationName);

                if (result.data.status === 'FAILED') {
                    const _tempFailData = JSON.parse(result.data.data);
                    _tempFailData.documents.map((doc: BulkDocument) => (doc.signedUrl = ''));
                    const _rawData: BulkDocument[] = _tempFailData.documents;
                    const _fundAbbr: string[] = [];
                    Object.values(_rawData).map((data) => {
                        _fundAbbr.push(data.fundAbbr);
                    });

                    setfundAbbr(_fundAbbr);
                    setBulkDocumentsTableData(_rawData);
                } else {
                    const rawData: BulkDocument[] = JSON.parse(result.data.fundData);
                    const _fundAbbr: string[] = [];
                    Object.values(rawData).map((data) => {
                        _fundAbbr.push(data.fundAbbr);
                    });
                    setfundAbbr(_fundAbbr);
                    setBulkDocumentsTableData(rawData);
                }
            }
            setTimeout(() => {
                setIsProcessing(false);
            }, 0);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Bulk Upload Data',
                testId: 'activitylogs-bulkdocuments-error-modal',
            });
            handleErrorHandler();
        }
    };
    //function to get fund names from fund abbreviations
    const uploadBulkFileHandler = async (fundAbbr: string[]) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fileCheckQuery, {
                    input: {
                        tab: 'documents',
                        fundAbbr: fundAbbr,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fileCheck;
            if (!error) {
                setFundNames(data.result.fundData);
            } else {
                setGenerateTable(true);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Fund Names',
                testId: 'activitylogs-bulkdocuments-error-modal',
            });
            handleErrorHandler();
        }
    };
    const processBulkDocumentData = (rawData: BulkDocument[]) => {
        //build column objects for sheet table
        const tempCols: string[] = ['Fund Documents (.pdf)', 'File Size', 'Fund Name', 'Documents'];
        const columnsOutput: ISheetColumn[] = [];
        let columnObj: any = {};

        try {
            tempCols.map((column: string) => {
                columnObj = {
                    colName: column,
                    type: 'string',
                };
                columnsOutput.push(columnObj);
            });

            //build cell objects for sheet table
            let cellObj: any = {};
            const cellRow: ISheetCell[][] = [];

            const tempCells: BulkDocument[] = rawData.map((document: BulkDocument) => document);
            const externalLinkCells: string[] = []; //to separate the external links
            //make tempCells to objects and then push to an array
            tempCells.map((row: BulkDocument) => {
                const tempRow: ISheetCell[] = [];
                columnsOutput.map((column, columnIndex) => {
                    if (columnIndex === 0) {
                        externalLinkCells.push(row.signedUrl);
                    }
                    switch (column.colName) {
                        case 'Fund Documents (.pdf)':
                            cellObj = {
                                cellValue: row.fileName,
                                isValid: true,
                            };
                            break;
                        case 'File Size':
                            cellObj = {
                                cellValue: row.fileSize,
                                isValid: true,
                                isFileSize: true,
                            };
                            break;
                        case 'Fund Name':
                            cellObj = {
                                cellValue: getFundName(row.fundAbbr),
                                isValid: true,
                            };
                            break;
                        case 'Documents':
                            cellObj = {
                                cellValue: row.documentType,
                                isValid: true,
                            };
                            break;
                        default:
                    }

                    tempRow.push(cellObj);
                });
                cellRow.push(tempRow);
            });

            setExternalLinks(externalLinkCells);
            setColumns(columnsOutput);
            setRows(cellRow);
        } catch (error) {}
    };
    //function to get fund name from state
    const getFundName = (fundAbbr: string): string => {
        let _fundName = '';
        if (fundNames) {
            const fundObj = fundNames.find((item) => item.fundAbbr === fundAbbr);
            fundObj ? (_fundName = fundObj.fundName) : (_fundName = 'FUND NAME NOT IN SYSTEM');
        }

        return _fundName;
    };

    const handleTitle = (operationName: string) => {
        if (propsData.maker === true) {
            return 'Bulk Upload Documents Request';
        }
        return operationName;
    };

    useEffect(() => {
        setIsProcessing(true);
        getFundUploadDataQuery(propsData.id);
    }, []);
    useEffect(() => {
        fundAbbr.length !== 0 && uploadBulkFileHandler(fundAbbr);
    }, [fundAbbr]);

    // process to bind failed resposnse data to table
    useEffect(() => {
        processBulkDocumentData(bulkDocumentsTableData);
    }, [fundNames, generateTable]);
    return (
        <Fragment>
            <PreviousPage title={handleTitle(operationName)} />
            <DisabledView>
                <StyledInner paddingBottom={bannerHeight}>
                    <PageDescription>You can upload multiple pdf file all at once.</PageDescription>
                    {isProcessing ? (
                        <p>Processing data, hold on!</p>
                    ) : (
                        <TableSheet
                            columns={columns}
                            rows={rows}
                            readyOnly={true}
                            externalLinks={externalLinks}
                            hasExternalLinks={true}
                            columnWidths={fixedCoLumnlWidth}
                        />
                    )}
                </StyledInner>
            </DisabledView>
        </Fragment>
    );
};

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 2.6rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;

const DisabledView = styled.div`
    pointer-events: none;
`;
const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: -0.05px;
`;
