import { Auth } from 'aws-amplify';
/**
 * @signOut @function
 */
export const signOutFn = async (): Promise<void> => {
    try {
        await Auth.signOut();
        sessionStorage.removeItem('session_token');
        sessionStorage.removeItem('user_role');
        sessionStorage.removeItem('expirationDate');
        sessionStorage.removeItem('permission');
        sessionStorage.clear();
    } catch (error) {}
};
/**
 * use sessionStorage instead
 * How do we know when the user leaves the site
 */
let x = false;
export const onRefreshFn = (): boolean => {
    window.addEventListener('load', (ev) => {
        ev.preventDefault();
        x = true;
    });

    return x;
};
