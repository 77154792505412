import React from 'react';
import { IFilterBranch } from '../../pages/DashboardBranch/dashboardBranchTypes';

export interface InboxContextProps {
    getInboxData: () => Promise<void>;
    getSource: () => string[];
    loading: boolean;
    loadingHandler: () => void;
    message: INotificationObject[];
    newMessageCount: number;
    page: number;
    pages: number;
    resultLimit: number;
    searchInput: IFilterBranch;
    setMessage: React.Dispatch<React.SetStateAction<INotificationObject[]>>;
    setNewMessageCount: React.Dispatch<React.SetStateAction<number>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
    setResultLimit: React.Dispatch<React.SetStateAction<number>>;
    setSearchInput: React.Dispatch<React.SetStateAction<IFilterBranch>>;
    setTab: React.Dispatch<React.SetStateAction<number>>;
    setUnreadIds: React.Dispatch<React.SetStateAction<string[]>>;
    tab: number;
    unreadIds: string[];
}

const InboxContext = React.createContext({} as InboxContextProps);

export default InboxContext;
