/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { DateColumnItem } from '../../../templates';
import { statusStyle, toTitleCase } from '../../../utils';
const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    // let outputDate: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let outputData: any;
    const generateOutput = () => {
        switch (itemKey[0].key) {
            case 'isSyariah':
                data[itemKey[0].key] == 0 ? (outputData = 'Conventional') : (outputData = 'Shariah');
                break;
            case 'lastUpdated':
            case 'startDate':
                outputData = <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
                break;
            case 'fundStatus':
                outputData = (
                    <div style={statusStyle({ index: 0, rawData: data }, 'fundStatus')}>
                        {toTitleCase(data[itemKey[0].key])}
                    </div>
                );
                break;
            default:
                outputData = '';
        }
        return outputData;
    };

    return (
        <Fragment>
            <div data-testid="custom">{generateOutput()}</div>
        </Fragment>
    );
};
export default CustomItem;
