/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
/**
 * points to SIT GRAPHQL SITE
 */
const awsmobile = {
    aws_project_region: 'ap-southeast-1',
    aws_appsync_graphqlEndpoint: 'https://gmhuvlvm3jbonc5hb7emzs7j4i.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-1',
    aws_appsync_authenticationType: 'AWS_IAM',
};

export default awsmobile;
