/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Fragment, useContext, useEffect } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import * as ROUTES from '../../routes';

const ProductSettings = lazy<any>(() => import('../../pages/ProductSettings/ProductSettings'));
const UploadsDashboard = lazy<any>(() => import('../../pages/Upload/UploadsDashboard'));
const HQCEContainer = lazy<any>(() => import('../../pages/DashboardHQCE/HQCEWrapper'));
const HQCEDashboard = lazy<any>(() => import('../../pages/DashboardHQCE/DashboardHQCE'));
const HQCEBranchAdvisors = lazy<any>(() => import('../../pages/DashboardHQCE/BranchOffices/ViewAdvisers'));
const HQCEBranchOffices = lazy<any>(() => import('../../pages/DashboardHQCE/BranchOffices'));
const HQCEViewBranch = lazy<any>(() => import('../../pages/DashboardHQCE/AllBranches/ViewBranch'));
const SystemAdminDashboard = lazy<any>(() => import('../../pages/SystemAdmin/Dashboard/Dashboard'));
const OrderSummaryNew = lazy<any>(() => import('../../pages/OrderDetails/OrderSummaryNew'));

import {
    AdviserProfile,
    BranchInfo,
    AddRoles,
    RejectTransaction,
    RerouteTransaction,
    Inbox,
    TrackOrder,
} from '../../pages';
import { ActivityLogsProvider, SystemAdminProvider, AdviserAccessProvider } from '../../context/SystemAdminContext';
import { ProductSettingsProvider } from '../../context/ProductSettingsContext';
import { UploadsProvider } from '../../context/UploadsContext';
import { ExtendTargetDateBranch } from '../../pages/DashboardBranch/ExtendTargetDate/ExtendTargetDateBranch';
import { AdviserAccessDashboard } from '../../pages/SystemAdmin/AdviserAccess/Dashboard';
import { RejectRemarks } from '../../templates/RejectRemarks';

import { CEExtendDueDateRequest } from '../../pages/Inbox/CEPages/CEExtendDueDateRequest';
import { onRefreshFn } from '../../utils/authServices';
import { FlexedDiv, Modal } from '../../components';
import { LABEL } from '../../constants';

import WebSocketContext from '../../context/WebSocketContext';
import AddUser from '../../pages/SystemAdmin/AddUser';
import EditRoles from '../../pages/SystemAdmin/RoleSettings/EditRoles';
import AddUserGroup from '../../pages/SystemAdmin/UserGroup/AddUserGroup';

// import ProductSettings from '../../pages/ProductSettings/ProductSettings';
import DetailsPage from '../../pages/ProductSettings/DetailsPage';
import Import from '../../pages/Import';
import BulkUploadDocuments from '../../pages/Import/BulkUploadDocuments';
import ViewBulkUpload from '../../pages/Import/ViewBulkUpload';
import ViewImport from '../../pages/Import/ViewImport';
import ReviewImport from '../../pages/Import/ReviewImport';
import ReviewBulkImport from '../../pages/Import/ReviewBulkImport';
import RejectImport from '../../pages/Import/RejectImport';
import DueDateExtension from '../../pages/ExtendTargetDate';
import OrderDetails from '../../pages/OrderDetails';
// Shared imports
import AuthContext from '../../context/AuthContext';
import Profile from '../../pages/Profile';
import UserViewInbox from '../../pages/Inbox/SystemAdminPages/UserViewInbox';

import BranchOfficeProvider from '../../context/HQCEContext/branchOffice/BranchOfficeProvider';
import DashboardBranchProvider from '../../context/BranchContext/DashboardBranchProvider';
import HQCEProvider from '../../context/HQCEContext/HQCEProvider';
import { AdvisersProvider } from '../../context/BranchContext/advisers/AdvisersProvider';
import { InboxProvider } from '../../context/InboxContext';

// Edd Imports commented Out
import { SatisfactoryCase } from '../../pages/DashboardAmla/DashboardEDD/SatisfactoryCase';
import { UnsatisfactoryCase } from '../../pages/DashboardAmla/DashboardEDD/UnsatisfactoryCase';
import { ReroutedSatisfactory } from '../../pages/DashboardAmla/DashboardEDD/ReroutedSatisfactory';
import { ReroutedUnsatisfactory } from '../../pages/DashboardAmla/DashboardEDD/ReroutedUnsatisfactory';
import { RerouteEddCaseUnsatisfactory } from '../../pages/DashboardAmla/DashboardEDD/ReroutedUnsatisfactory';

import { RerouteEddCase } from '../../pages/DashboardAmla/DashboardEDD/UnsatisfactoryCase/RerouteEddCase';
import { InvestorsList } from '../../pages/DashboardAmla/DashboardEDD/AddNewCase/Investors';
import { QuestionTemplate } from '../../pages/DashboardAmla/DashboardEDD/AddNewCase/QuestionTemplate';
import { CancelRequest } from '../../pages/DashboardAmla/DashboardEDD/CancelRequest';
import { CancelledSatisfactoryCase } from '../../pages/DashboardAmla/DashboardEDD/CancelRequest/CancelSatisfactory';
import { DownloadMonthlyReport } from '../../pages/DashboardAmla/DashboardEDD/DownloadMonthlyReport';
import DashboardEDD from '../../pages/DashboardAmla/DashboardEDD';
import EDDProvider from '../../context/EDDContext/EDDProvider';
import { NewCaseForm } from '../../pages/DashboardAmla/DashboardEDD/AddNewCase/NewCaseForm';

// System Admin imports
import AdviserActivity from '../../pages/SystemAdmin/ActivityLogs/Redirections/AdviserActivity';
import EditUserGroup from '../../pages/SystemAdmin/UserGroup/EditUserGroup';
import ImportUsers from '../../pages/SystemAdmin/BulkImportUsers';
import ViewImportUsers from '../../pages/SystemAdmin/BulkImportUsers/ViewImportUsers';
import ReviewBulkImportUsers from '../../pages/SystemAdmin/BulkImportUsers/ReviewBulkImportUsers';
import RejectBulkImportUsers from '../../pages/SystemAdmin/BulkImportUsers/RejectBulkImportUsers';
import UserGroupActivity from '../../pages/SystemAdmin/ActivityLogs/Redirections/CreateUserGroupActvitiy';
import EditEPF from '../../pages/SystemAdmin/SystemSettings/EPF/Pages/EditEPF';
import RejectRemarkSettings from '../../pages/SystemAdmin/SystemSettings/EPF/Pages/RejectRemarkSettings';
import UserGroupViewInbox from '../../pages/Inbox/SystemAdminPages/UserGroupViewInbox';
import RolesViewInbox from '../../pages/Inbox/SystemAdminPages/RolesViewInbox';
import SystemSettingsDashboard from '../../pages/SystemAdmin/SystemSettings';
import SettingsViewInbox from '../../pages/SystemAdmin/SystemSettings/EPF';
import OrderSummaryActivity from '../../pages/SystemAdmin/ActivityLogs/Redirections/OrderSummaryActivity';

import { SystemAdminAdviserProfile } from '../../pages/SystemAdmin/AdviserProfile/SystemAdminAdviserProfile';
import { ActivityLogsDashboard } from '../../pages/SystemAdmin/ActivityLogs/Dashboard';
import { ReviewImportActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/ReviewImportActivity';
import { RolesActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/RolesActivity';
import { UserActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/CreateUserActivity';
import { RerouteActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/RerouteActivity';
import { RejectActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/RejectActivity';
import { DueDateActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/DueDateActivity';
import { BulkUploadActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/BulkUploadActivity';
import { BulkImportActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/BulkImportActivity';
import { EditEPFActivity } from '../../pages/SystemAdmin/ActivityLogs/Redirections/EditEPFActivity';
import TrackOrderProvider from '../../context/TrackOrderContext/TrackOrderProvider';
import PendingSubmissionProvider from '../../context/PendingSubmissionContext/PendingSubmissionProvider';
import { PendingSubmission } from '../../pages/PendingSubmission';
import { AllSubmittedCEDashboard } from '../../pages/DashboardBranch/AllSubmittedDashboard';
import Error404 from '../../pages/ExceptionHandling/Error404';

export interface IHqRouteProps extends RouteProps {
    component?: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HqRoute = ({ component: Component, ...rest }: IHqRouteProps): JSX.Element => {
    const { userLoginContext, windowReloadFn } = useContext(AuthContext);
    const { isLoggedOut, setIsLoggedOut } = useContext(WebSocketContext);

    const checkPermission = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.grant === 'hq') {
            return true;
        }
        return false;
    };

    const checkCeDashboard = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.permission.ceDashboard.isAll === 'true') {
            return true;
        }
        return false;
    };

    const checkFund = (permission: any) => {
        const parsedPermission = JSON.parse(permission);

        if (
            parsedPermission.hq.permission.productSettings.isAll === 'true' ||
            parsedPermission.hq.permission.uploads.isAll === 'true'
        ) {
            return true;
        }
        return false;
    };

    const checkSystemAdmin = (permission: any) => {
        const parsedPermission = JSON.parse(permission);

        if (parsedPermission.hq.permission.userManagement.isAll === 'true') {
            return true;
        }
        return false;
    };
    const checkActivityLogs = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.permission.activityLogs.isAll === 'true') {
            return true;
        }
        return false;
    };
    const checkSystemSettings = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.permission.systemSettings.isAll === 'true') {
            return true;
        }
        return false;
    };

    const checkEDD = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.permission.eddCase.isAll === 'true') {
            return true;
        }
        return false;
    };

    useEffect(() => {
        windowReloadFn();
    }, [onRefreshFn]);

    return (
        <Fragment>
            {userLoginContext.isAuthenticated && checkPermission(userLoginContext.permission) ? (
                <HQCEContainer>
                    {checkSystemAdmin(userLoginContext.permission) ? (
                        <InboxProvider>
                            <SystemAdminProvider>
                                <AdviserAccessProvider>
                                    <Switch>
                                        <Route
                                            exact
                                            path={ROUTES.dashboardSystemAdmin}
                                            component={SystemAdminDashboard}
                                        />
                                        <Route exact path={ROUTES.addUser} component={AddUser} />
                                        <Route exact path={ROUTES.branchManagement} component={BranchInfo} />
                                        <Route exact path={ROUTES.addRoles} component={AddRoles} />
                                        <Route exact path={ROUTES.editRoles} component={EditRoles} />
                                        <Route exact path={ROUTES.approveEditRole} component={EditRoles} />
                                        <Route exact path={ROUTES.addUserGroup} component={AddUserGroup} />
                                        <Route exact path={ROUTES.editUserGroup} component={EditUserGroup} />
                                        <Route exact path={ROUTES.bulkImportUsers} component={ImportUsers} />
                                        <Route exact path={ROUTES.viewImportUsers} component={ViewImportUsers} />
                                        <Route
                                            exact
                                            path={ROUTES.reviewBulkImportUsers}
                                            component={ReviewBulkImportUsers}
                                        />
                                        <Route
                                            exact
                                            path={ROUTES.rejectBulkImportUsers}
                                            component={RejectBulkImportUsers}
                                        />

                                        <Route exact path={ROUTES.adviserAccess} component={AdviserAccessDashboard} />
                                        <Route
                                            exact
                                            path={ROUTES.systemAdminAdviserView}
                                            component={SystemAdminAdviserProfile}
                                        />
                                        <Route
                                            exact
                                            path={`${ROUTES.hqInbox}/review-groups`}
                                            component={UserGroupViewInbox}
                                        />
                                        <Route
                                            exact
                                            path={`${ROUTES.hqInbox}/review-roles`}
                                            component={RolesViewInbox}
                                        />
                                        <Route
                                            exact
                                            path={`${ROUTES.hqInbox}/review-users`}
                                            component={UserViewInbox}
                                        />
                                        <Route component={Error404} />
                                    </Switch>
                                </AdviserAccessProvider>
                            </SystemAdminProvider>
                        </InboxProvider>
                    ) : null}
                    {checkActivityLogs(userLoginContext.permission) ? (
                        <InboxProvider>
                            <SystemAdminProvider>
                                <ActivityLogsProvider>
                                    <Switch>
                                        <Route exact path={ROUTES.activityLogs} component={ActivityLogsDashboard} />
                                        <Route exact path={ROUTES.activityLogsFund} component={ReviewImportActivity} />
                                        <Route exact path={ROUTES.activityLogsRoles} component={RolesActivity} />
                                        <Route exact path={ROUTES.activityLogsGroups} component={UserGroupActivity} />
                                        <Route exact path={ROUTES.activityLogsUser} component={UserActivity} />
                                        <Route exact path={ROUTES.activityLogsReroute} component={RerouteActivity} />
                                        <Route exact path={ROUTES.activityLogsDueDate} component={DueDateActivity} />
                                        <Route
                                            exact
                                            path={ROUTES.activityLogsOrderSummary}
                                            component={OrderSummaryActivity}
                                        />
                                        <Route exact path={ROUTES.activityLogsReject} component={RejectActivity} />
                                        <Route exact path={ROUTES.activityLogsAdviser} component={AdviserActivity} />
                                        <Route
                                            exact
                                            path={ROUTES.activityLogsBulkUpload}
                                            component={BulkUploadActivity}
                                        />
                                        <Route
                                            exact
                                            path={ROUTES.activityLogsBulkImport}
                                            component={BulkImportActivity}
                                        />
                                        <Route
                                            exact
                                            path={ROUTES.activityLogsEpfSettings}
                                            component={EditEPFActivity}
                                        />
                                    </Switch>
                                </ActivityLogsProvider>
                            </SystemAdminProvider>
                        </InboxProvider>
                    ) : null}
                    {checkSystemSettings(userLoginContext.permission) ? (
                        <InboxProvider>
                            <Switch>
                                <Route exact path={ROUTES.systemConfiguration} component={SystemSettingsDashboard} />
                                <Route exact path={ROUTES.editEPFConfiguration} component={EditEPF} />
                                <Route exact path={ROUTES.systemSettingRemark} component={RejectRemarkSettings} />
                                <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                <Route exact path={ROUTES.hqProfile} component={Profile} />
                                <Route
                                    exact
                                    path={`${ROUTES.hqInbox}/configuration/edit-epf`}
                                    component={SettingsViewInbox}
                                />

                                <Route component={Error404} />
                            </Switch>
                        </InboxProvider>
                    ) : null}
                    {checkCeDashboard(userLoginContext.permission) ? (
                        <InboxProvider>
                            <HQCEProvider>
                                <DashboardBranchProvider>
                                    <TrackOrderProvider>
                                        <Switch>
                                            <Route exact path={ROUTES.dashboard} component={HQCEDashboard} />
                                            <Route exact path={ROUTES.hqTrackOrder} component={TrackOrder} />
                                            <Route exact path={ROUTES.viewBranch} component={HQCEViewBranch} />

                                            <Route
                                                exact
                                                path={ROUTES.hqAllSubmissions}
                                                component={AllSubmittedCEDashboard}
                                            />
                                            <Route
                                                path={`${ROUTES.viewBranch}/${ROUTES.orderSummary}`}
                                                component={OrderDetails}
                                            />
                                            <Route
                                                path={`${ROUTES.viewBranch}/${ROUTES.orderSummaryChangeRequest}`}
                                                component={OrderSummaryNew}
                                            />
                                            <Route
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}`}
                                                component={OrderDetails}
                                            />
                                            <Route
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.orderSummaryChangeRequest}`}
                                                component={OrderSummaryNew}
                                            />
                                            <Route
                                                path={`${ROUTES.hqInbox}/${ROUTES.orderSummary}`}
                                                component={OrderDetails}
                                            />
                                            <Route
                                                // exact
                                                path={`${ROUTES.hqInbox}/${ROUTES.orderSummaryChangeRequest}`}
                                                component={OrderSummaryNew}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDate}`}
                                                component={ExtendTargetDateBranch}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.rejectTargetDate}`}
                                                component={RejectRemarks}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDateRequest}`}
                                                component={DueDateExtension}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.rejectTransaction}`}
                                                component={RejectTransaction}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.hqAllSubmissions}/${ROUTES.rerouteTransaction}`}
                                                component={RerouteTransaction}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.viewBranch}/${ROUTES.extendTargetDate}`}
                                                component={ExtendTargetDateBranch}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.viewBranch}/${ROUTES.rejectTargetDate}`}
                                                component={RejectRemarks}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.viewBranch}/${ROUTES.extendTargetDateRequest}`}
                                                component={DueDateExtension}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.viewBranch}/${ROUTES.rejectTransaction}`}
                                                component={RejectTransaction}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.viewBranch}/${ROUTES.rerouteTransaction}`}
                                                component={RerouteTransaction}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.hqTargetDateExtension}
                                                component={DueDateExtension}
                                            />
                                            <Route exact path={ROUTES.hqRejectExtension} component={RejectRemarks} />
                                            <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                            <Route exact path={ROUTES.hqProfile} component={Profile} />

                                            <Route
                                                exact
                                                path={ROUTES.hqInboxReRouteReview}
                                                component={RerouteTransaction}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.hqInboxRejectReview}
                                                component={RejectTransaction}
                                            />
                                            <Route
                                                exact
                                                path={`${ROUTES.hqInbox}/due-date-extension`}
                                                component={CEExtendDueDateRequest}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.hqPendingSubmissionTrackOrder}
                                                component={TrackOrder}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.hqAllPendingSubmissionTrackOrder}
                                                component={TrackOrder}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.hqAllSubmissionsTrackOrder}
                                                component={TrackOrder}
                                            />
                                            <Route
                                                exact
                                                path={ROUTES.hqSingleBranchTrackOrder}
                                                component={TrackOrder}
                                            />
                                            <PendingSubmissionProvider>
                                                <BranchOfficeProvider>
                                                    <AdvisersProvider>
                                                        <Switch>
                                                            <Route
                                                                path={`${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}`}
                                                                component={OrderDetails}
                                                            />
                                                            <Route
                                                                path={`${ROUTES.hqPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`}
                                                                component={OrderSummaryNew}
                                                            />
                                                            <Route
                                                                exact
                                                                path={ROUTES.hqAllPendingSubmission}
                                                                component={PendingSubmission}
                                                            />
                                                            <Route
                                                                path={`${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}`}
                                                                component={OrderDetails}
                                                            />
                                                            <Route
                                                                path={`${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`}
                                                                component={OrderSummaryNew}
                                                            />
                                                            <Route
                                                                exact
                                                                path={ROUTES.hqPendingSubmission}
                                                                component={HQCEViewBranch}
                                                            />
                                                            <Route
                                                                exact
                                                                path={ROUTES.branchOffices}
                                                                component={HQCEBranchOffices}
                                                            />
                                                            <Route
                                                                exact
                                                                path={ROUTES.hqAdvisers}
                                                                component={HQCEBranchAdvisors}
                                                            />
                                                            <Route
                                                                exact
                                                                path={ROUTES.hqAdviserProfile}
                                                                component={AdviserProfile}
                                                            />

                                                            <Route component={Error404} />
                                                        </Switch>
                                                    </AdvisersProvider>
                                                </BranchOfficeProvider>
                                            </PendingSubmissionProvider>

                                            <Route component={Error404} />
                                        </Switch>
                                    </TrackOrderProvider>
                                </DashboardBranchProvider>
                            </HQCEProvider>
                        </InboxProvider>
                    ) : null}

                    {checkFund(userLoginContext.permission) ? (
                        <InboxProvider>
                            <ProductSettingsProvider>
                                <UploadsProvider>
                                    <Switch>
                                        <Route
                                            exact
                                            path={ROUTES.financeProductSetingsDashboard}
                                            component={ProductSettings}
                                        />
                                        <Route
                                            exact
                                            path={ROUTES.financeProductSetingsDetails}
                                            component={DetailsPage}
                                        />

                                        <Route exact path={ROUTES.financeUploads} component={UploadsDashboard} />
                                        <Route exact path={ROUTES.financeImportFileUploader} component={Import} />
                                        <Route
                                            exact
                                            path={ROUTES.financeImportBulkUploader}
                                            component={BulkUploadDocuments}
                                        />
                                        <Route exact path={ROUTES.financeViewImportFile} component={ViewImport} />
                                        <Route exact path={ROUTES.financeReviewImportFile} component={ReviewImport} />
                                        <Route exact path={ROUTES.financeViewBulkUpload} component={ViewBulkUpload} />
                                        <Route
                                            exact
                                            path={ROUTES.financeReviewBulkUpload}
                                            component={ReviewBulkImport}
                                        />
                                        <Route exact path={ROUTES.financeRejectImport} component={RejectImport} />
                                        <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                        <Route exact path={ROUTES.hqProfile} component={Profile} />
                                        <Route
                                            exact
                                            path={`${ROUTES.hqInbox}/review-bulk-upload`}
                                            component={ReviewBulkImport}
                                        />
                                        <Route
                                            exact
                                            path={`${ROUTES.hqInbox}/review-import-file`}
                                            component={ReviewImport}
                                        />
                                        <Route
                                            exact
                                            path={`${ROUTES.hqInbox}/bulk-import-users/review-import`}
                                            component={ReviewBulkImportUsers}
                                        />
                                        <Route component={Error404} />
                                    </Switch>
                                </UploadsProvider>
                            </ProductSettingsProvider>
                        </InboxProvider>
                    ) : null}
                    {checkEDD(userLoginContext.permission) ? (
                        <InboxProvider>
                            <EDDProvider>
                                <Route exact path={ROUTES.amlaEDDManagement} component={DashboardEDD} />

                                <Route exact path={ROUTES.investorsList} component={InvestorsList} />
                                <Route exact path={ROUTES.questionTemplate} component={QuestionTemplate} />
                                <Route exact path={ROUTES.rerouteEddCase} component={RerouteEddCase} />
                                <Route exact path={ROUTES.newCaseForm} component={NewCaseForm} />
                                <Route exact path={ROUTES.satisfactoryCase} component={SatisfactoryCase} />
                                <Route exact path={ROUTES.unsatisfactoryCase} component={UnsatisfactoryCase} />

                                <Route exact path={ROUTES.satisfactory} component={SatisfactoryCase} />
                                <Route
                                    exact
                                    path={ROUTES.reroutedEddCaseSatisfactory}
                                    component={ReroutedSatisfactory}
                                />
                                <Route
                                    exact
                                    path={ROUTES.reroutedEddCaseUnsatisfactory}
                                    component={ReroutedUnsatisfactory}
                                />
                                <Route
                                    exact
                                    path={ROUTES.rerouteEddCaseUnsatisfactory}
                                    component={RerouteEddCaseUnsatisfactory}
                                />

                                <Route exact path={ROUTES.cancelRequest} component={CancelRequest} />
                                <Route
                                    exact
                                    path={ROUTES.cancelledSatisfactoryCase}
                                    component={CancelledSatisfactoryCase}
                                />
                                <Route exact path={ROUTES.downloadMonthlyReport} component={DownloadMonthlyReport} />
                                <Route
                                    exact
                                    path={`${ROUTES.hqInbox}/${ROUTES.satisfactory}`}
                                    component={SatisfactoryCase}
                                />
                                <Route
                                    exact
                                    path={`${ROUTES.hqInbox}/${ROUTES.reroutedSatisfactory}`}
                                    component={ReroutedSatisfactory}
                                />
                                <Route
                                    exact
                                    path={`${ROUTES.hqInbox}/${ROUTES.cancelledSatisfactory}`}
                                    component={CancelledSatisfactoryCase}
                                />
                                <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                <Route exact path={ROUTES.hqProfile} component={Profile} />
                                <Route component={Error404} />
                            </EDDProvider>
                        </InboxProvider>
                    ) : null}
                </HQCEContainer>
            ) : null}
            {isLoggedOut ? (
                <Modal
                    title={'Duplicate log in'}
                    modalActive={isLoggedOut}
                    setModalActive={setIsLoggedOut}
                    primaryBtn={{
                        onClick: () => {
                            setIsLoggedOut(!isLoggedOut);
                            location.href = '/';
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon="logout-modal-error"
                    contentAlignment="center"
                    testId="custom_modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {'Your account has been logged in from another device.'}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default HqRoute;
