/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, ChangeEvent, useMemo } from 'react';
import { SearchBar, Dashboard, DatePicker } from '../../components/organisms';
import { LABEL, Fs12BoldPrimaryBlack } from '../../constants';
import { API, graphqlOperation } from 'aws-amplify';
import { FlexedDiv, CustomButton, CustomSpacer, ComponentLoader, SelectDropdown } from '../../components';

import { adviserDashboardExportListCreatedOn } from '../../_graphql/queries/hqCE/exportList/adviserDashboardCreatedOn';
import { clearContextOnNavigation, exportList } from '../../utils';
import { SearchOptionsAdvisers } from './searchDropdown';
import { dateSortingDropDownAdvisers } from './filterData';
import { adviserDashboardExportListLastUpdated } from '../../_graphql/queries/hqCE/exportList/adviserDashbaordLastUpdated';
import { useDebounce } from '../../customHooks/useDebounce';
import { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import moment from 'moment';
import styled from 'styled-components';
import Advisers from './AdviserTable';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import AdvisersContext from '../../context/BranchContext/advisers/AdvisersContext';
import BranchOfficeContext from '../../context/HQCEContext/branchOffice';
import { useHistory } from 'react-router';

export interface AdvisorBranchProps {
    hq?: boolean;
}

export interface IColumnValue {
    column: string;
    value: string;
}

export interface ISortValues {
    Advisers: IColumnValue[];
}

const initialAdviserFilters = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];
const statusOptions = [
    { value: '', label: LABEL.all },
    { value: 'active', label: LABEL.active },
    { value: 'suspended', label: LABEL.suspended },
    { value: 'terminated', label: LABEL.terminated },
];
export const AdvisorBranch: React.FC<AdvisorBranchProps> = ({ hq }: AdvisorBranchProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const {
        adviserCount,
        adviserData,
        createdOnDropdown,
        currentPage,
        dateSorting,
        filters,
        getAdviserBranchData,
        loading,
        loadingHandler,
        maxPages,
        resultLimit,
        searchInput,
        setCreatedOnDropdown,
        setCurrentPage,
        setDateSorting,
        setFilters,
        setResultLimit,
        setSearchInput,
        setSortData,
        setStatus,
        setTab,
        setTargetRange,
        setTempFilters,
        sortData,
        status,
        tab,
        targetDateRange,
        tempFilters,
    } = useContext(AdvisersContext);
    const { branchesId } = useContext(BranchOfficeContext);
    const [expand, setExpand] = useState<boolean>(false);
    const history = useHistory();
    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 700);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    // permission object form session storage
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const adviserTab =
        hq === true
            ? parsedPermission.hq.permission.branchOffices.advisersTab
            : parsedPermission.branch.permission.adviser.advisersTab;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const switchTabs = (index: number) => {
        setTab(index);
    };

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleReset = () => {
        setStatus('');
        setTargetRange([null, null]);
        setDateSorting('createdOn');
        setTempFilters(initialAdviserFilters);
        setExpand(!expand);
        setFilters(initialAdviserFilters);
    };

    const handleApply = () => {
        setExpand(false);
        setFilters(tempFilters);
    };

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const update = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetRange(update);
        const temp = [...tempFilters];
        temp[0] = {
            column: tempFilters[0].column,
            value: `${moment(update[0]).valueOf()}~${moment(update[1]).valueOf()}`,
        };
        setTempFilters(temp);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };
    const handleDateSorting = (value: string | number) => {
        const tmp = [...tempFilters];
        const obj: IColumnValue = {
            column: value!.toString(),
            value:
                moment(targetDateRange[0]).format('DD/MM/YYYY') !== moment().format('DD/MM/YYYY') &&
                targetDateRange[0] !== null
                    ? `${moment(targetDateRange[0]).valueOf()}~${moment(targetDateRange[1]).valueOf()}`
                    : '',
        };
        tmp[0] = obj;
        setDateSorting(value!.toString());
        setTempFilters(tmp);
    };

    const handleStatus = (text: string | number) => {
        setStatus(text!.toString());
        const temp = [...tempFilters];
        temp[1] = { column: 'status', value: text!.toString() };
        setTempFilters(temp);
    };

    const handleSecondary = () => {
        getExportList();
    };

    const getExportList = async () => {
        const query =
            createdOnDropdown.keyName === 'createdOn'
                ? adviserDashboardExportListCreatedOn
                : adviserDashboardExportListLastUpdated;
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: [...filters],
                        endpoint: 'adviserdashboard',
                        branchId: branchesId !== undefined && branchesId !== '' ? branchesId : '',
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;

            const prefix = branchesId !== undefined && branchesId !== '' ? 'Hq' : 'Branch';
            exportList(prefix, 'adviserdashboard', result.adviserdashboard);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Generate Export list',
                testId: 'adviserbranch-modal',
            });
        }
    };
    /** * end */

    useEffect(() => {
        getAdviserBranchData();
        return function cleanup() {
            clearContextOnNavigation('advisersDashboard', history.location.pathname) === false ? clearContext() : null;
        };
    }, []);
    useMemo(() => {
        getAdviserBranchData();
    }, [filters, sortData, debouncedSearchTerm, currentPage, resultLimit]);
    const clearContext = () => {
        setCurrentPage(1);
        setResultLimit(10);
        setSearchInput({ value: '', column: 'all' });
        setStatus('');
        setTargetRange([null, null]);
        setFilters([]);
        setTempFilters([]);
        setSortData([{ column: 'createdOn', value: 'descending' }]);
    };
    const sameFilter = JSON.stringify(initialAdviserFilters) !== JSON.stringify(filters);
    const searchOrFiltered = sameFilter || searchInput.value !== '';

    const tabs: IDashboardTabs[] = [];
    if (adviserTab.isAll === 'true') {
        tabs.push({
            name: 'Advisers',
            table: (
                <Advisers
                    adviserData={adviserData}
                    adviserTab={adviserTab}
                    createdOnDropdown={createdOnDropdown}
                    filters={filters}
                    searchEmpty={searchOrFiltered}
                    searchInput={searchInput.value}
                    setCreatedOnDropdown={setCreatedOnDropdown}
                    setFilters={setFilters}
                    setSortData={setSortData}
                    sortData={sortData}
                    hq={hq !== undefined ? hq : false}
                    testId="advisers-tb"
                />
            ),
            testId: 'advisers-tab',
        });
    }
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsAdvisers.advisers.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `advisers-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    //Fn to generate search placeholder text
    const generatePlaceHolderText = (input: string): string => {
        let _temp = '';
        switch (input) {
            case 'agentName':
                _temp = 'by Adviser Name';
                break;
            case 'agentCode':
                _temp = 'by Adviser Code';
                break;
        }

        return _temp;
    };
    return (
        <Fragment>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={`Search Advisers ${generatePlaceHolderText(searchInput.column)}`}
                searchTitle={LABEL.advisers}
                dropDownOptions={searchOptions()}
                handleSecondary={handleSecondary}
                secondaryButton={
                    adviserTab.actions.canExportList
                        ? [
                              {
                                  type: 'secondary',
                                  title: 'Export List',
                                  actionIcon: 'export',
                                  testId: 'advisers-export-btn',
                              },
                          ]
                        : []
                }
                searchInput={searchInput}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                testId="advisers"
            >
                <FlexedDiv direction="row">
                    <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                            {LABEL.dateSorting}
                        </Fs12BoldPrimaryBlack>
                        <SelectWrapper>
                            <SelectDropdown
                                onChange={handleDateSorting}
                                options={dateSortingDropDownAdvisers}
                                testId={`date-dropdown-filter`}
                                selectedValue={dateSorting}
                            />
                        </SelectWrapper>
                    </FlexedDiv>
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>{'Date Range'}</Fs12BoldPrimaryBlack>
                        <InputWrap>
                            <DatePicker
                                setTargetDate={handleTargetDate}
                                targetDate={targetDateRange}
                                range={true}
                                width="360"
                                primaryBorderColor="#002043"
                                handleDate={handleDate}
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                            />
                        </InputWrap>
                    </FlexedDiv>
                </FlexedDiv>
                <FlexedDiv>
                    <FlexedDiv direction="column">
                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>{LABEL.status}</Fs12BoldPrimaryBlack>
                        <SelectWrapper>
                            <SelectDropdown
                                onChange={handleStatus}
                                options={statusOptions}
                                testId={`account-dropdown-filter`}
                                selectedValue={status}
                            />
                        </SelectWrapper>
                    </FlexedDiv>
                </FlexedDiv>
                <CustomSpacer space={'7vh'} />
                <FlexedDiv style={{ marginTop: '2rem' }} justifyContent="center">
                    <CustomButton onClick={handleReset} style={{ width: '16.67vw' }} id="advisers-reset-btn">
                        <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                    </CustomButton>
                    <CustomSpacer horizontal={true} space={'1rem'} />
                    <CustomButton
                        primary={true}
                        onClick={handleApply}
                        style={{ width: '16.67vw', padding: '14px 0' }}
                        id="advisers-apply-btn"
                    >
                        {LABEL.apply}
                    </CustomButton>
                </FlexedDiv>
            </SearchBar>
            <TabWrapper>
                <Dashboard
                    tabs={tabs}
                    page={currentPage}
                    maxPage={maxPages}
                    selectedTab={tab}
                    setResultLimit={setResultLimit}
                    orderCounter={[adviserCount]}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    testId="advisers"
                    setCurrentPage={setCurrentPage}
                    resultLimit={resultLimit}
                    disableResultLimit={adviserData.length === 0}
                />
                {loading ? <ComponentLoader /> : null}
            </TabWrapper>
        </Fragment>
    );
};

export default AdvisorBranch;

const TabWrapper = styled.div`
    position: relative;
`;
const InputWrap = styled.div`
    width: 18.33vw;
    max-width: 360px;
    margin-right: 1rem;
`;
const SelectWrapper = styled.div`
    margin-right: 1vw;
    width: 18.33vw;
    height: 2.5rem;
    margin-bottom: 2rem;
`;
