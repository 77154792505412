import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES, LABEL } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { AdvanceTable } from '../../../components';
import { DateFilter } from '../AllFunds';
import { sh104, sh144, sh38, sh205, sh96, sh120, sh170 } from '../../../styles';

import * as Routes from '../../../routes';
import ProductSettingsContext from '../../../context/ProductSettingsContext';
import CustomItem from './CustomItem';
import AuthContext from '../../../context/AuthContext';

export interface IAllAMPProps {
    allAMPData: ITableData[];
    getTabIndex: () => void;
}

export const AllAmp: React.FC<IAllAMPProps> = ({ allAMPData, getTabIndex }: IAllAMPProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { allAmpTab } = parsedPermission.hq.permission.productSettings;

    const history = useHistory();
    const { sortData, setSortData, columnFilterValues } = useContext(ProductSettingsContext);
    const handleViewDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeProductSetingsDetails,
            state: {
                type: 'allamp',
                code: item.rawData.fundId,
                tabIndex: getTabIndex(),
            },
        });
    };

    const handleSort = (keyName: string) => {
        const tempSort = { ...sortData };
        const sortType = sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (sortData.column === keyName) {
            const icon = sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };

    const handleDateFilterDrop = () => {
        return <DateFilter />;
    };

    const allAmpCols: ITableColumn[] = [
        {
            icon: {
                name: handleSortIcon('fundAbbr'),
                size: '1rem',
            },
            key: [{ key: 'fundAbbr', textStyle: { fontWeight: sortData.column === 'fundAbbr' ? 700 : 400 } }],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.code,
            titleStyle: {
                fontWeight: sortData.column === 'fundAbbr' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fundAbbr'),
        },
        {
            icon: {
                name: handleSortIcon('fundName'),
                size: '1rem',
            },
            key: [{ key: 'fundName', textStyle: { fontWeight: sortData.column === 'fundName' ? 700 : 400 } }],
            viewStyle: {
                width: sh205,
            },
            title: LABEL.ampName,
            titleStyle: {
                fontWeight: sortData.column === 'fundName' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fundName'),
        },
        {
            icon: {
                name: handleSortIcon('fundCategory'),
                size: '1rem',
            },
            key: [{ key: 'fundCategory' }],
            viewStyle: {
                width: sh120,
            },
            title: columnFilterValues !== undefined ? columnFilterValues['fundCategory'].columnLabel : '',
            titleStyle: {
                fontWeight: sortData.column === 'fundCategory' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('fundCategory'),
        },
        {
            icon: {
                name: handleSortIcon('issuingHouse'),
                size: '1rem',
            },
            key: [{ key: 'issuingHouse', textStyle: { fontWeight: sortData.column === 'issuingHouse' ? 700 : 400 } }],
            viewStyle: {
                width: sh170,
            },
            title: LABEL.fundUtmc,
            titleStyle: {
                fontWeight: sortData.column === 'issuingHouse' ? 700 : 400,
            },
            onPressHeader: () => handleSort('issuingHouse'),
        },
        {
            icon: {
                name: handleSortIcon('riskCategory'),
                size: '1rem',
            },
            key: [{ key: 'riskCategory' }],
            viewStyle: {
                width: sh96,
            },
            titleStyle: {
                fontWeight: sortData.column === 'riskCategory' ? 700 : 400,
            },
            title: columnFilterValues !== undefined ? columnFilterValues['riskCategory'].columnLabel : '',
            onPressHeader: () => handleSort('riskCategory'),
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('fundType'),
                size: '1rem',
            },
            key: [{ key: 'fundType', textStyle: { fontWeight: sortData.column === 'fundType' ? 700 : 400 } }],
            viewStyle: {
                width: sh96,
            },
            titleStyle: {
                fontWeight: sortData.column === 'fundType' ? 700 : 400,
            },
            title: columnFilterValues !== undefined ? columnFilterValues['fundType'].columnLabel : '',
            onPressHeader: () => handleSort('fundType'),
        },
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [
                {
                    key:
                        columnFilterValues !== undefined &&
                        columnFilterValues['lastUpdated'].columnLabel === 'Last Updated'
                            ? 'lastUpdated'
                            : 'startDate',
                },
            ],
            viewStyle: {
                width: sh144,
            },
            title: columnFilterValues !== undefined ? columnFilterValues['lastUpdated'].columnLabel : '',
            customItem: true,
            titleStyle: {
                fontWeight:
                    sortData.column ===
                    (columnFilterValues && columnFilterValues['lastUpdated'].columnLabel === 'Last Updated'
                        ? 'lastUpdated'
                        : 'startDate')
                        ? 700
                        : 400,
            },
            RenderHeaderMenu: () => handleDateFilterDrop(),
        },
        {
            icon: {
                name: handleSortIcon('fundStatus'),
                size: 16,
            },
            key: [{ key: 'fundStatus' }],
            viewStyle: {
                width: sh104,
            },
            titleStyle: {
                fontWeight: sortData.column === 'fundStatus' ? 700 : 400,
            },
            title: columnFilterValues !== undefined ? columnFilterValues['fundStatus'].columnLabel : '',
            onPressHeader: () => handleSort('fundStatus'),
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: allAmpTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                allAmpTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'allamp-view-btn',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allAMPData}
                columns={allAmpCols}
                onEmptyState={EMPTY_STATES.emptyStateAllAMP}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData.column} {...props} />}
            />
        </Fragment>
    );
};
