/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { unitDistributionHistorydQuery } from '../../../_graphql/queries/fundData/productSettingsDashbaord/distributionHistory';
import { DataWithLabel, FlexedDiv, SelectDropdown } from '../../../components';
import { DatePicker, StandardTable } from '../../../components/organisms';
import { LABEL } from '../../../constants';

import moment from 'moment';
import styled from 'styled-components';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import AuthContext from '../../../context/AuthContext';

interface IGrossHistroyProps {
    fundId: number;
    fundName: string | undefined;
}
export const UnitHistoryDetails: React.FC<IGrossHistroyProps> = ({ fundId, fundName }: IGrossHistroyProps) => {
    const [unitHistory, setUnitHistory] = useState<ITableCellData[]>([]);
    const [rangePickerValue, setRangePickerValue] = useState<[moment.Moment | null, moment.Moment | null]>([
        null,
        null,
    ]); // state to manage date range picker value
    const [unitHistoryCount, setUnitHistoryCount] = useState<number>(0);
    const [toDate, setToDate] = useState<string | undefined>('');
    const [FromDate, setFromDate] = useState<string | undefined>('');
    const [fundClass, setFundClass] = useState<string>();
    const [fundClassFilters, setFundClassFilters] = useState<string[]>([]);
    // const { RangePicker } = DatePicker;
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //call query to get unit distribution histroy data
    const getUnitHistory = async (fundId: number, FromDate: string, toDate: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(unitDistributionHistorydQuery, {
                    input: {
                        fundId: fundId,
                        tab: 'unitsplit',
                        dateFrom: FromDate,
                        dateTo: toDate,
                        fundClass: fundClass,
                    },
                }),
                idTokenHeader,
            );

            const { data } = await response.data.distributionHistory;

            if (data) {
                setUnitHistoryCount(data.result.result.length);
                setUnitHistory(data.result.result);
                setFundClassFilters(data.result.fundClass);
            } else {
                setUnitHistoryCount(0);
                setUnitHistory([]);
            }
        } catch (error) {
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Fetch Unit History Data',
                testId: 'unitdistribution-history-error-modal',
            });
        }
    };
    const dateRangeHandler = (dateRange: [moment.Moment | null, moment.Moment | null]) => {
        // const tempFromDate = moment(dateString[0]).valueOf().toString();
        // const tempToDate = moment(dateString[1]).valueOf().toString();
        const tempFromDate = moment(dateRange[0]?.format('YYYY-MM-DD')).valueOf().toString();
        const tempToDate = moment(dateRange[0]?.format('YYYY-MM-DD')).valueOf().toString();

        setToDate(tempToDate);
        setFromDate(tempFromDate);
        setRangePickerValue(dateRange);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item.format('YYYY-MM-DD')).valueOf();
            time.push(milliSeconds);
        });
        const _validTo = time[1] > time[0];
        if (!_validTo) {
            setDatePickerInvalidMessage(`Start Date cannot be from ${moment(date[0]?.format('DD/MM/YYYY'))}`);
        }
    };
    const generateFilterOptions = (filters: string[]): Array<ISelectOption> => {
        const _filters: Array<ISelectOption> = [];
        filters.forEach((item) => {
            if (item) {
                const _obj = { value: item, label: item, testId: `${item}--option` };
                _filters.push(_obj);
            }
        });
        return _filters;
    };
    useEffect(() => {
        if (rangePickerValue && FromDate !== undefined && toDate !== undefined) {
            getUnitHistory(fundId, FromDate, toDate);
        } else {
            setRangePickerValue([null, null]);
        }
    }, [toDate, fundClass]);
    const handleSelectChange = (value: any) => {
        setFundClass(value);
    };
    const headrCols: IStandardColumns[] = [
        {
            thTitle: 'Closing Date',
        },
        {
            thTitle: 'Unit Split/Cancellation',
        },
    ];
    return (
        <Fragment>
            <FlexedDiv alignItems="flex-start">
                <FlexedDiv direction="column">
                    <DataWithLabel
                        direction="column"
                        label={LABEL.fundName}
                        data={fundName !== undefined ? fundName : ''}
                        testId="unit-distribution-fund-name"
                    />
                    <FlexedDiv direction="row" id="row" style={{ alignItems: 'flex-end' }}>
                        <InputWrapper>
                            <InputLabel>Date Range</InputLabel>

                            <DatePicker
                                targetDate={rangePickerValue && rangePickerValue}
                                setTargetDate={dateRangeHandler}
                                range={true}
                                width="360"
                                primaryBorderColor="#002043"
                                handleDate={handleDate}
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <InputLabel>Fund Class</InputLabel>

                            <SelectWrapper>
                                <SelectDropdown
                                    onChange={(value) => handleSelectChange(value)}
                                    options={generateFilterOptions(fundClassFilters)}
                                    testId={`branch-dropdown`}
                                    selectedValue={fundClass !== undefined && fundClass !== '' ? fundClass : ''}
                                    fontWeight={700}
                                    placeHolder="Select fund class"
                                    isDisabled={generateFilterOptions(fundClassFilters).length === 0 ? true : false}
                                />
                            </SelectWrapper>
                        </InputWrapper>
                        <LabelGrey>Total {unitHistoryCount} results</LabelGrey>
                    </FlexedDiv>
                </FlexedDiv>
            </FlexedDiv>
            <TabContainer>
                <StandardTable data={unitHistory} columns={headrCols} />
            </TabContainer>
        </Fragment>
    );
};

const TabContainer = styled.div`
    margin: 1rem 0px;
    width: 100%;
`;
const LabelGrey = styled.p`
    margin: 0;
    color: #2a365a;
    font-size: 0.75rem;
    flex: 1 0 auto;
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    width: 100%;
    max-width: 264px;
`;
const InputLabel = styled.label`
    display: block;
    color: #333333;
    margin-bottom: 8px;
    font-size: 0.75rem;
    font-weight: 700;
`;
const SelectWrapper = styled.label`
    padding: 0px;
    margin-top: -7px;
`;
