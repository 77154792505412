export const handleDashbordResults = (results: InvestorsDetailsResult[]): ITableData[] => {
    const _tempTableData: ITableData[] = results.map((result) => {
        const _newItem = Object.entries(result).reduce((acc, current) => {
            if (Array.isArray(current[1])) {
                switch (current[0]) {
                    case 'profile':
                        const _user = {
                            clientName: current[1][0].clientName,
                            riskCategory: current[1][0].riskInfo.riskCategory,
                            jointName: current[1][1] && current[1][1].clientName,
                        };

                        acc = { ...acc, ..._user };
                        break;
                }
            } else if (current[1] && typeof current[1] !== 'string') {
                Object.assign(acc, current[1]);
            } else {
                switch (current[0]) {
                    case 'lastUpdated':
                        acc = { ...acc, lastUpdated: current[1] };
                        break;
                    case 'transactionRef':
                        acc = { ...acc, transactionRef: current[1] };
                        break;
                }
            }
            return acc;
        }, {});

        return _newItem;
    });
    console.log(_tempTableData, '_tempTableData');
    return _tempTableData;
};
