import gql from 'graphql-tag';
/**
 * @newCaseDefinitionQuery @query - EDD New Case Form API
 * @description: Retrieving client details and loading page for amla to set edd reason, target date and trigger
 */

export const newCaseDefinitionQuery = gql`
    query newCaseDefinition($input: NewCaseDefinitionInput) {
        newCaseDefinition(input: $input) {
            data {
                result {
                    targetDate
                    eddTrigger {
                        reasonId
                        category
                    }
                    eddReason {
                        reasonId
                        category
                        subCategory
                    }
                    transactionDetails {
                        utaId
                        accountNo
                        registrationDate
                        servicingAdviserName
                        servicingAdviserCode
                    }
                    investmentSummary {
                        accountFund
                        productType
                    }
                    onboardingReceipt {
                        name
                        url
                        type
                    }
                    profile {
                        name
                        idNumber
                        idType
                        idFile
                        origin
                        accountHolder
                        personalDetails {
                            nationality
                            monthlyHouseholdIncome
                        }
                        employmentInformation {
                            occupation
                            annualIncome
                            nameOfEmployer
                        }
                        contactDetails {
                            mobileNumber
                            email
                        }
                    }
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;
