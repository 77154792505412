import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CustomDropdownV2, IDropdownItemV2 } from '..';
import { IcoMoon } from '../../../icons';

export type ISelectValue = {
    label: string;
    value: string;
};
interface filterSearchProps {
    disabled?: boolean;
    disableFilterSearchDropDown?: boolean;
    handleClearContents?: () => void;
    handleSearch: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
    isSimpleSearch?: boolean;
    options?: Array<IDropdownItemV2>;
    placeHolder: string;
    searchValue?: string;
    selectValue?: string;
    testId?: string;
}

export const FilterSearch = ({
    disabled,
    handleClearContents,
    handleSearch,
    isSimpleSearch,
    options,
    placeHolder,
    searchValue,
    selectValue,
    testId,
    disableFilterSearchDropDown,
}: filterSearchProps): JSX.Element => {
    const [onFocus, setOnFocus] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setOnFocus(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef]);

    const handleClear = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        handleClearContents && handleClearContents();
    };
    const getSelectValue = (): string => {
        let _selectValue = selectValue ?? '';
        options?.map(({ item, value }) => {
            value === selectValue ? (_selectValue = item) : null;
        });
        return _selectValue;
    };
    return (
        <div>
            <FilterSearchWrapper
                ref={containerRef}
                data-testid="flex_container"
                disabled={disabled}
                isOnFocus={onFocus}
            >
                <SearchIconSpan data-testid="icon_span">
                    <IcoMoon name="search-dashboard" size="1.5rem" data-testid="custom-element" />
                </SearchIconSpan>
                <FilterSearchInput
                    maxLength={255}
                    placeholder={placeHolder}
                    onChange={handleSearch}
                    value={searchValue}
                    id={`${testId}-search-input`}
                    disabled={disabled}
                    ref={inputRef}
                    onFocus={() => setOnFocus(true)}
                    isSimpleSearch={isSimpleSearch}
                />
                {searchValue && searchValue.trim().length !== 0 && onFocus ? (
                    <ClearInputSpan
                        onClick={() => handleClear()}
                        isSimpleSearch={isSimpleSearch}
                        data-testId="clearAll-search-input"
                    >
                        <IcoMoon name={'error-input'} style={{ fill: '#C6CBD4' }} size="1.5rem" />
                    </ClearInputSpan>
                ) : null}
                {(isSimpleSearch === false || isSimpleSearch === undefined) && options !== undefined ? (
                    <FilterSelectWrapper
                        disabled={disabled || disableFilterSearchDropDown}
                        data-testid="filter-search-select-wrapper"
                    >
                        <CustomDropdownV2
                            items={options}
                            noMinWidth={false}
                            disableDropdown={disabled || disableFilterSearchDropDown}
                            showDivider={false}
                            maxWidth={'15.5vw'}
                            selected={[getSelectValue(), selectValue ?? '']}
                        >
                            <DropDownInner>
                                <DropDownSpan>{getSelectValue()}</DropDownSpan>
                                <IconSpan>
                                    <IcoMoon name="caret-down" size="1.5rem" />
                                </IconSpan>
                            </DropDownInner>
                        </CustomDropdownV2>
                    </FilterSelectWrapper>
                ) : null}
            </FilterSearchWrapper>
        </div>
    );
};

export default FilterSearch;
interface IIsSimpleSearch {
    isSimpleSearch?: boolean;
}
const FilterSearchWrapper = styled.div<IOnPropStyles>`
    position: relative;
    border-radius: 32px;
    height: 3rem;
    border: ${(props) => (props.isOnFocus ? '2px solid #002043' : '1px solid #c9c9cd')};
    width: 100%;
    align-items: center;
    display: flex;
    background: ${(props) => (props.disabled ? '#F4F4F4' : '#fff')};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    &:hover {
        border: ${(props) => (props.isOnFocus ? '2px solid #002043' : ' 1px solid #66798e')};
    }
`;
const ClearInputSpan = styled.span<IIsSimpleSearch>`
    position: absolute;
    align-self: center;
    right: ${(props) => (props.isSimpleSearch === true ? '1rem' : '258px')};
    bottom: 10px;
    z-index: 100;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 50%;
`;
const FilterSelectWrapper = styled.div<IOnPropStyles>`
    position: absolute;
    align-self: center;
    border-left: 1px solid #c9c9cd;
    right: 0rem;
    width: 240px;
    height: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background: ${(props) => (props.disabled ? '#F4F4F4' : '#fff')};
`;
const SearchIconSpan = styled.span`
    position: absolute;
    align-self: center;
    left: 1rem;
    z-index: 100;
    font-size: 1rem;
`;
// for the width the 356px came from 18px X 2 from left and right side of the x mark, 240px right of dropdown, 60 from left of search wrapper and 20px of xlear 'x' marker = 36+240+60+20 = 356
const FilterSearchInput = styled.input<IIsSimpleSearch>`
    width: ${(props) => (props.isSimpleSearch === true ? '80%' : 'calc(100% - 356px)')};
    letter-spacing: -0.33px;
    outline: none !important;
    border: none;
    background-color: transparent !important;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #002043 !important;
    position: absolute;
    left: 60px;
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        border: none;
        box-shadow: 0 0 0px 1000px transparent inset;
        -webkit-text-fill-color: inherit;
        box-shadow: 0 0 0px 1000px transparent inset;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }
`;
const DropDownInner = styled.div`
    cursor: pointer;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    margin-right: 5px;
    font-weight: bold;
`;
const IconSpan = styled.span`
    right: 1rem;
    position: absolute;
`;
