/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EnableOmniMutation } from '../../../_graphql/mutations/systemAdmin/enableOmni';
import { API, graphqlOperation } from 'aws-amplify';
import { FlexedDiv } from '../../../components/atoms';
import Toggle from '../../../components/atoms/Toggle';
import { SYSTEM_ADMIN, LABEL } from '../../../constants';

import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import { Modal } from '../../../components';

declare interface IOmniEnabled {
    data: ITableData;
    shouldUpdate: boolean;
    setShouldUpdate: (toggle: boolean) => void;
}

export const OmniEnabledColumnItem = ({ data, shouldUpdate, setShouldUpdate }: IOmniEnabled): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const omniEnabled = data.omniEnabled;

    const setOmni = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(EnableOmniMutation, {
                    input: {
                        id: data.agentId,
                        action: data.omniEnabled === '1' ? 'disable' : 'enable',
                    },
                }),
                idTokenHeader,
            );

            const { result } = await response.data.enableOmni.data;

            if (result.status === true) {
                setShowModal(!showModal);
            }
        } catch (error) {
            setErrorMessage({
                ...errorMessage,
                message: (error as IErrorHandling).message,
                errorCode: (error as IErrorHandling).errorCode,
                title: 'Cannot Perform Action',
                testId: 'omni-enable-error-modal',
            });
            handleErrorHandler();
        }
    };

    const modalTitle =
        omniEnabled === '1' ? SYSTEM_ADMIN.LABEL_OMNI_DISABLED_SUCCESS : SYSTEM_ADMIN.LABEL_OMNI_ENABLED_SUCCESS;
    const modalSubTitle =
        omniEnabled === '1'
            ? `${data.agentName} ${SYSTEM_ADMIN.LABEL_HAS_BEEN_DENIED}`
            : `${data.agentName} ${SYSTEM_ADMIN.LABEL_HAS_BEEN_GRANTED}`;

    return (
        <Fragment>
            <FlexedDiv direction="row" style={{ width: '6.67vw' }} data-testid="row">
                <Toggle selected={omniEnabled === '1'} onClick={setOmni} id="omni-enabled" />
            </FlexedDiv>
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTitle}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            setShouldUpdate(!shouldUpdate);
                            history.push(Routes.adviserAccess);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="omnienabled-modal"
                    icon="user-modal-success"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
