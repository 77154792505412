import React from 'react';
import { IcoMoon } from '../../../icons';

import styled from 'styled-components';

interface IToggleTokenProps {
    label: string;
    description?: string;
    testId?: string;
    disabled?: boolean;
    partial?: boolean;
    selected: boolean;
    handleToggleToken: (value: string) => void;
}
type ToggleTokenElmProps = {
    description: boolean;
    disabled?: boolean;
};

type CircleIconProps = {
    disabled: boolean;
    selected: boolean;
    color: string;
    partial: boolean;
};
type ToggleTokenLabelProps = {
    disabled: boolean;
    selected: boolean;
};
export const ToggleToken: React.FC<IToggleTokenProps> = ({
    label,
    description,
    testId,
    disabled,
    selected,
    partial,
    handleToggleToken,
}: IToggleTokenProps): JSX.Element => {
    const generateBgColor = (): string => {
        let _color = '';
        if (disabled && selected) {
            _color = '#C61230';
        } else if (disabled && !selected) {
            _color = '#878787';
        } else if (!disabled && !selected) {
            _color = 'transparent';
        } else if (!disabled && selected) {
            _color = '#C61230';
        }
        return _color;
    };
    return (
        <ToggleTokenElm
            id={`${testId}-toggle-token`}
            description={description ? true : false}
            disabled={disabled ? true : false}
            onClick={() => handleToggleToken(label.toLowerCase())}
        >
            <CircleIcon
                disabled={disabled ? true : false}
                selected={selected}
                color={generateBgColor()}
                partial={partial ? true : false}
            >
                {partial && selected ? null : (
                    <IcoMoon name={'check'} size="15px" color={selected ? '#fff' : '#002043'} />
                )}
            </CircleIcon>
            <ToggleTokenRight>
                <ToggleTokenLabel disabled={disabled ? true : false} selected={selected}>
                    <input type="text" value={label.toLowerCase()} />
                    {label}
                </ToggleTokenLabel>
                {description ? <ToggleTokenDescription>{description}</ToggleTokenDescription> : null}
            </ToggleTokenRight>
        </ToggleTokenElm>
    );
};

const ToggleTokenElm = styled.div<ToggleTokenElmProps>`
    display: flex;
    align-items: ${(props) => (props.description ? ' flex-start' : 'center')};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
const ToggleTokenRight = styled.div`
    line-height: 1.15;
    margin-left: 0.8rem;
`;
const ToggleTokenLabel = styled.label<ToggleTokenLabelProps>`
    font-size: 16px;
    font-weight: ${(props) =>
        (!props.disabled && !props.selected) || (props.disabled && !props.selected) ? 400 : 700};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    margin-bottom: 0.4 rem;
    display: block;
    color: #171717;
    input {
        display: none;
        width: 0px;
        pointer-events: none;
    }
`;
const ToggleTokenDescription = styled.div`
    font-size: 0.9rem;
    color: #4d4d4d;
`;
const CircleIcon = styled.div<CircleIconProps>`
    position: relative;
    width: 21px;
    height: 21px;
    background-color: ${(props) => props.color};
    border: ${(props) => (props.selected ? '0px' : '1px solid #002043')};
    border-radius: 50%;
    text-align: center;
    line-height: 19px;
    opacity: ${(props) => (!props.disabled && props.selected ? '1' : '0.6')};
    &:hover {
        background-color: ${(props) => (!props.disabled && !props.selected ? '#C6CBD4' : '')};
    }
    &:before {
        display: ${(props) => (props.partial && props.selected ? 'block' : 'none')};
        content: '';
        width: 12px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
    }
`;
