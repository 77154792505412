/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactText, useContext } from 'react';
import HQCEContext, { HQCEContextData } from './HQCEContext';
import { useComponentLoader } from '../../customHooks';
import { IColumnValue } from '../../pages/Advisers';
import { API, graphqlOperation } from 'aws-amplify';
import { allBranchQuery } from '../../_graphql/queries/hqCE/dashboard/allBranches/allBranch';
import { dueDateExtensionQuery } from '../../_graphql/queries/hqCE/dashboard/dueDateExtension/dueDateExtension';
import moment from 'moment';
import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import { IDropdownWithKey } from '../../pages/DashboardHQCE';
import { groupTransactionsByBranch } from './groupByBranch';
import { SearchOptionsHQCE } from '../../pages/DashboardHQCE/searchDropdown';

const initialBranchFilter = [
    {
        column: 'state',
        value: '',
    },
];

export interface HQCEProviderProps {
    children: React.ReactNode;
}

const HQCEProvider: React.FC<HQCEProviderProps> = ({ children }: HQCEProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const mockOverView: ISummaryCardData[] = [
        {
            data: '0',
            title: 'Daily Branch',
            testId: 'daily-branch-transaction',
            dataLabel: 'Transaction(s)',
            dataIcon: 'transactions-overview',
        },
        {
            title: 'Branch Rerouted',
            data: '0',
            testId: 'rerouted-transaction',
            dataLabel: 'Transaction(s)',
            dataIcon: 'transactions-overview',
        },
        {
            data: '0',
            title: 'Pending Physical Document',
            dataLabel: 'Transaction(s)',
            testId: 'pending-hardcopy',
            dataIcon: 'transactions-overview',
        },
        {
            data: '0',
            title: 'Due Date Extension',
            dataLabel: 'Request(s)',
            dataIcon: 'reverse-clock',
            testId: 'due-date-extensionreuest',
            cardId: 1,
        },
    ];
    const [targetDateRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [orderCounter, setOrderCounter] = useState<Array<number>>([1, 1]);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState({ value: '', column: SearchOptionsHQCE['branch'][0].value });
    const [searchbarFilterTags, setSearchbarFilterTags] = useState<Array<ISelectValue>>([]); // The filter tags to show when the dropdown filter is disabled
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'name', value: 'Ascending' }]);
    const [overview, setOverview] = useState<ISummaryCardData[]>([...mockOverView]);
    const [offices, setOffices] = useState<ITableData[]>([]);
    const [targetDate, setTargetDate] = useState<ITableData[]>([]);
    const [tempFilters, setTempFilters] = useState<IColumnValue[]>(initialBranchFilter);
    const [filters, setFilters] = useState<IColumnValue[]>(initialBranchFilter);
    const [productTypes, setProductTypes] = useState<ReactText[]>([]);
    const [fundingOption, setFundingOption] = useState<ReactText[]>([]);
    const [accountType, setAccountType] = useState<string>('');
    const [states, setStates] = useState<ReactText[]>([]);
    const [stateList, setStateList] = useState<string[]>([]);
    const [label, setLabel] = useState<string[]>([]);
    const [lastUpdatedDropdown, setLastUpdatedDropdown] = useState<IDropdownWithKey>({
        value: 'Created On',
        keyName: 'createdOn',
    });
    const [transactionType, setTransactionType] = useState<IDropdownWithKey>({
        value: 'Trans. Types',
        keyName: 'transactionType',
    });

    //loader
    const { loading, loadingHandler } = useComponentLoader();

    /*** @getDateExtensionData @APICall */
    const getDateExtensionData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(dueDateExtensionQuery, {
                    input: {
                        tab: 'extension',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: [...filters],
                    },
                }),
                idTokenHeader,
            );
            const { result } = await response.data.hqCeDashboard.data;
            setOverview([
                { ...overview[0], data: result.overview.daily },
                { ...overview[1], data: result.overview.reroute },
                { ...overview[2], data: result.overview.hardcopy },
                { ...overview[3], data: result.overview.extension },
            ]);
            if (currentPage > result.pages) {
                setCurrentPage(1);
            }
            setOrderCounter([result.branchCount, result.extensionCount]);
            setMaxPages(result.pages);
            setTargetDate(groupTransactionsByBranch(result.transactions));
            setDisableResultLimit(result.transactions.length === 0);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Due Date Extensions Data',
                testId: 'hq-dashboard-error-modal',
            });
        }
    };

    /** * @getAllBranchData @APICall */
    const getAllBranchData = async () => {
        loadingHandler();
        // console.log('--->filters', filters);
        try {
            const response: any = await API.graphql(
                graphqlOperation(allBranchQuery, {
                    input: {
                        tab: 'branch',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: [...filters],
                    },
                }),
                idTokenHeader,
            );
            const { result } = await response.data.hqCeDashboard.data;
            setOverview([
                { ...overview[0], data: result.overview.daily },
                { ...overview[1], data: result.overview.reroute },
                { ...overview[2], data: result.overview.hardcopy },
                { ...overview[3], data: result.overview.extension },
            ]);
            if (currentPage > result.pages) {
                setCurrentPage(1);
            }
            setStateList(result.stateList);
            setOrderCounter([result.branchCount, result.extensionCount]);
            setMaxPages(result.pages);
            setOffices(result.branches);
            setDisableResultLimit(result.branches.length === 0);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            handleErrorHandler();
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Branch Data',
                testId: 'hq-dashboard-error-modal',
            });
        }
    };

    const ProviderValue: HQCEContextData = {
        accountType,
        currentPage,
        disableResultLimit,
        filters,
        fundingOption,
        getAllBranchData,
        getDateExtensionData,
        label,
        lastUpdatedDropdown,
        loading,
        loadingHandler,
        maxPages,
        offices,
        orderCounter,
        overview,
        productTypes,
        resultLimit,
        searchbarFilterTags,
        searchInput,
        setAccountType,
        setCurrentPage,
        setFilters,
        setFundingOption,
        setLabel,
        setLastUpdatedDropdown,
        setMaxPages,
        setOffices,
        setOrderCounter,
        setOverview,
        setProductTypes,
        setResultLimit,
        setSearchbarFilterTags,
        setSearchInput,
        setSortData,
        setStateList,
        setStates,
        setTab,
        setTargetDate,
        setTargetRange,
        setTempFilters,
        setTransactionType,
        sortData,
        stateList,
        states,
        tab,
        targetDate,
        targetDateRange,
        tempFilters,
        transactionType,
    };

    return <HQCEContext.Provider value={ProviderValue}>{children}</HQCEContext.Provider>;
};

export default HQCEProvider;
