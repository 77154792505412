import { IEddCaseDetailsProps } from './EddTypes';

export const initialCaseDetails: IEddCaseDetailsProps = {
    name: '',
    status: '',
    clientIdNum: '',
    idFile: '',
    idType: '',
    officerName: '',
    headings: [
        {
            responseId: 0,
            eddCount: 0,
            submittedOn: '',
            remarkOn: '',
        },
    ],
    caseSummary: {
        caseDetails: {
            caseId: '',
            caseNo: '',
            createdOn: '',
            closedDate: '',
            eddTrigger: [
                {
                    title: '',
                    content: [''],
                },
            ],
            eddReason: [
                {
                    title: '',
                    content: [''],
                },
            ],
            satisfactoryStatus: '',
        },
        personalDetails: {
            nationality: '',
            email: '',
            phone: '',
            occupation: '',
            employerName: '',
            grossIncome: '',
        },
        accountSummary: {
            accountNo: '',
            accountCreatedOn: '',
            investorType: '',
            accountHolder: '',
            agentId: '',
            agentCode: '',
            receipt: {
                url: '',
                base64: '',
                name: '',
                path: '',
                type: '',
                size: '',
            },
            productType: '',
            fundOption: '',
        },
    },
    assignedAdviser: {
        personalDetails: {
            agentName: '',
            agentCode: '',
            nric: '',
            licenseCode: '',
            branch: '',
            region: '',
            channel: '',
            omniEnabled: '',
            bdmName: '',
            bdmCode: '',
            bdmEmail: '',
            uplineName: '',
            uplineCode: '',
            rank: '',
            agency: '',
        },
        contactDetails: {
            email: '',
            mobileNo: '',
        },
        addressInformation: {
            address: '',
            address2: '',
            address3: '',
            addrTown: '',
            country: '',
            postcode: '',
            state: '',
        },
    },
    response: [{ questionNo: '', questionId: '', title: '', description: '', answers: '', amlaRemark: '' }],
    remark: [{ title: '', content: '' }],
};
