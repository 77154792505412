/* eslint-disable @typescript-eslint/no-explicit-any */
// import * as React from 'react';
import React, { useContext, useReducer, useRef, useState } from 'react';
import { AuthReducer } from './AuthReducer';
import AuthContext, { AuthContextData, IUserLoginProps } from './AuthContext';
import { useHistory } from 'react-router-dom';
import { logOutQuery } from '../../_graphql/queries/common/logOut';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { encryption } from '../../utils';
import { userLogin } from '../../_graphql/mutations/userLogin';
import * as ROUTES from '../../routes';

import WebSocketContext from '../WebSocketContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

export interface AuthProviderProps {
    children: React.ReactNode;
}
export interface IUserLoginContext {
    role: string;
    loginId: string;
    email: string;
    sessionToken: string;
    department: string;
    permission: string;
    accessLevel: string;
    inboxCount: string;
    username: string;
    branchName: string;
    expirationDate: string;
    isAuthenticated: boolean;
    idToken?: string | null;
    refreshToken?: string;
}

export const initialUserLogin: IUserLoginContext = {
    role: '',
    username: '',
    loginId: '',
    email: '',
    sessionToken: '',
    department: '',
    permission: '',
    accessLevel: '',
    inboxCount: '',
    branchName: '',
    expirationDate: '',
    isAuthenticated: false,
};
interface authCredentials {
    accessKeyId: string;
    authenticated: boolean;
    expiration: Date;
    identityId: string;
    secretAccessKey: string;
    sessionToken: string;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
    const [onLogin, setOnLogin] = useState<boolean>(false);
    const history = useHistory();
    const { ws, setPath, setUrl } = useContext(WebSocketContext);
    const [userLoginContext, authDispatch] = useReducer(AuthReducer, initialUserLogin);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const currentPathname = useRef<string>('');
    currentPathname.current = window.location.pathname;

    const checkHqDashboard = (permission: any) => {
        // console.log('checkHqDashboard', permission.productSettings.isAll);
        if (permission.userManagement.isAll === 'true') {
            history.push(ROUTES.dashboardSystemAdmin);
        } else if (permission.ceDashboard.isAll === 'true') {
            // console.log('route');
            history.push(ROUTES.dashboard);
        } else if (permission.productSettings.isAll === 'true') {
            // console.log('finacne route');
            history.push(ROUTES.financeProductSetingsDashboard);
        } else if (permission.uploads.isAll === 'true') {
            history.push(ROUTES.financeUploads);
        } else if (permission.eddCase.isAll === 'true') {
            history.push(ROUTES.amlaEDDManagement);
        } else if (permission.systemSettings.isAll === 'true') {
            history.push(ROUTES.systemConfiguration);
        } else if (permission.accountManagement.isAll === 'true') {
            history.push(ROUTES.hqInbox);
        } else {
            history.push('/');
        }
    };

    /**  @roleBasedRedirect = route the User based on the specific role */
    const roleBasedRedirect = (permission: any) => {
        setErrorMessage({
            ...errorMessage,
            message: '',
            errorCode: '',
            title: '',
            testId: '',
        });
        const parsedPermission = JSON.parse(permission);
        if (Object.keys(parsedPermission.hq.permission).length > 0) {
            checkHqDashboard(parsedPermission.hq.permission);
        } else {
            if (parsedPermission.branch.permission.ceTransaction.isAll === 'true') {
                // console.log('route branch');
                history.push(ROUTES.dashboardBranch);
            } else if (parsedPermission.branch.permission.adviser.isAll === 'true') {
                // console.log('route');
                history.push(ROUTES.advisers);
            } else if (parsedPermission.branch.permission.accountManagement.isAll === 'true') {
                history.push(ROUTES.branchInbox);
            } else {
                history.push('/');
            }
        }
    };

    const userLoginFn = async (signInState: IUserLoginProps) => {
        const _credentials: authCredentials = await Auth.Credentials.get();
        const encryptedPassword = await encryption(_credentials.sessionToken, signInState.password);
        setOnLogin(true);
        try {
            const response: any = await API.graphql(
                graphqlOperation(userLogin, {
                    input: { username: signInState.username, password: encryptedPassword },
                }),
                { encryptionKey: _credentials.sessionToken },
            );
            if (response.data.userLogin.error !== null) throw response.data.userLogin.error;

            if (response.data.userLogin.data !== null) {
                await Auth.signIn(signInState.username, signInState.password);
            }
            const credentialsPayload = response.data.userLogin.data.result;

            const {
                role,
                name,
                email,
                sessionToken,
                department,
                permission,
                accessLevel,
                inboxCount,
                username,
                branchName,
            } = credentialsPayload;
            // Context implementation
            const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
            const tempContext: IUserLoginContext = {
                role: role,
                username: name,
                loginId: username,
                email: email,
                sessionToken: JSON.stringify(sessionToken),
                department: department,
                permission: permission,
                accessLevel: accessLevel,
                inboxCount: inboxCount,
                branchName: branchName,
                expirationDate: JSON.stringify(expirationDate),
                isAuthenticated: true,
            };
            sessionStorage.setItem('session', JSON.stringify(tempContext));

            authDispatch({ type: 'saveApiResponse', payload: tempContext });
            authDispatch({ type: 'saveToken', payload: tempContext.sessionToken });

            const WEBSOCKET_URL = 'jesmx0e0o4.execute-api.ap-southeast-1.amazonaws.com'; //SIT

            const stage = 'dev'; //SIT

            const path = `/${stage}?username=${username}&x-api-key=abc`; //SIT
            sessionStorage.setItem('url', WEBSOCKET_URL);
            sessionStorage.setItem('path', path);
            setUrl(WEBSOCKET_URL);
            setPath(path);
            roleBasedRedirect(permission);
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Login error',
                testId: 'login-error-modal',
            });
            setOnLogin(false);
            if (_error.errorCode !== 'BO101' && _error.errorCode !== 'BO100') {
                handleErrorHandler();
            }
        }
    };

    const windowReloadFn = () => {
        const _session = sessionStorage.getItem('session');
        const _parsedSession = _session ? JSON.parse(_session) : null;

        if (_session !== null && userLoginContext.isAuthenticated === false) {
            authDispatch({ type: 'saveApiResponse', payload: JSON.parse(_session) });
        } else if (currentPathname.current !== '/' && _parsedSession === null) {
            // logs user out if session is cleared
            userLogoutFn();
            ws !== undefined ? ws.close() : null;
        }
    };
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const userLogoutFn = async () => {
        console.log('userLogoutFn');
        type logOutResponse = {
            data: {
                logOut: {
                    data: {
                        result: {
                            status: boolean;
                            message: string;
                        };
                    };
                };
            };
        };

        try {
            const response = (await API.graphql(graphqlOperation(logOutQuery), idTokenHeader)) as logOutResponse;

            if (response.data.logOut.data.result.status) {
                await Auth.signOut();

                sessionStorage.removeItem('session');
                localStorage.clear();
                sessionStorage.clear();
                authDispatch({ type: 'logOut' });
                setOnLogin(false);
                history.push(ROUTES.signIn);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Logout error',
                testId: 'logout-error-modal',
            });
            setOnLogin(false);
            if (_error.errorCode !== 'BO101' && _error.errorCode !== 'BO100') {
                handleErrorHandler();
            }
        }
    };
    const ProviderValue: AuthContextData = {
        userLoginFn,
        userLoginContext,
        authDispatch,
        windowReloadFn,
        userLogoutFn,
        onLogin,
        roleBasedRedirect,
    };
    return <AuthContext.Provider value={ProviderValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
