/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ChangeEvent, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import FlexSpacer, { CustomSpacer, FlexedDiv, PreviousPage, ComponentLoader } from '../../../../components';
import { RadioButton } from '../../../../components/atoms/RadioButton/RadioButton';
import {
    Fs10RegNeutralGrey4,
    Fs12BoldPrimaryBlack,
    Fs18BoldPrimaryBlack,
    SYSTEM_ADMIN,
    TextPureBlack,
    LABEL,
    Fs12RegPrimaryBlack,
} from '../../../../constants';
import * as Routes from '../../../../routes';
import { setInitialData } from '../../RoleSettings/utils/functions';
import {
    adviserInitialData,
    customerExpInitialData,
    IAdviserSection,
    ICustomerExpSection,
    ILoginRoleSection,
    loginAccessInitialData,
} from '../../RoleSettings/AddRoles/BranchPrivileges/branchRolesInitialData';
import {
    activityLogsInitialData,
    branchOfficesInitialData,
    customerExperienceInitialData,
    eddCaseInitialData,
    financeCasesInitialData,
    financeDashboardInitialData,
    fundUploadsInitialData,
    IActivityLogsSection,
    IBranchOfficeSection,
    ICustomerExperienceSection,
    IEDDCasesSection,
    IFinanceCasesSection,
    IFinanceDashboardSection,
    IFundUploads,
    IOperationCases,
    IOperationDashboard,
    IProductSettings,
    ISystemSettingsSection,
    IUserManagementSection,
    operationCasesInitialData,
    operationDashboardInitialData,
    productSettingsInitialData,
    systemSettingsInitialData,
    userManagementInitialData,
} from '../../RoleSettings/AddRoles/HqPrivileges/hqRolesInitialData';
import { API, graphqlOperation } from 'aws-amplify';
import { ActivityLogsSummaryQuery } from '../../../../_graphql/queries/systemadmin/activityLogsSummary';
import { useComponentLoader } from '../../../../customHooks';
import { DropdownBase, BranchPrivileges } from '../..';
import { HqPrivileges } from '../../RoleSettings/AddRoles/HqPrivileges';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import AuthContext from '../../../../context/AuthContext';

export interface CheckBoxState {
    checked: boolean;
    value: string;
}

export type Privileges = 'branch' | 'hq';

export const RolesActivity: FunctionComponent = () => {
    const [roleName, setRoleName] = useState<string>('');
    const [status, setStatus] = useState<string>('Pending');
    const [roleDescription, setRoleDescription] = useState<string>('');
    const [loginAccess, setLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [hqLoginAccess, setHqLoginAccess] = useState<ILoginRoleSection>(loginAccessInitialData);
    const [customerExp, setCustomerExp] = useState<ICustomerExpSection>(customerExpInitialData);
    const [userManagement, setUserManagement] = useState<IUserManagementSection>(userManagementInitialData);
    const [activityLogs, setActivityLogs] = useState<IActivityLogsSection>(activityLogsInitialData);
    const [systemSettings, setSystemSettings] = useState<ISystemSettingsSection>(systemSettingsInitialData);
    const [customerExpDashboard, setCustomerExpDashboard] =
        useState<ICustomerExperienceSection>(customerExperienceInitialData);
    const [branchOffices, setBranchOffices] = useState<IBranchOfficeSection>(branchOfficesInitialData);
    const [eddCase, setEddCase] = useState<IEDDCasesSection>(eddCaseInitialData);
    const [financeDashboard, setFinanceDashboard] = useState<IFinanceDashboardSection>(financeDashboardInitialData);
    const [financeCases, setFinanceCases] = useState<IFinanceCasesSection>(financeCasesInitialData);
    const [productSettings, setProductSettings] = useState<IProductSettings>(productSettingsInitialData);
    const [fundUploads, setFundUploads] = useState<IFundUploads>(fundUploadsInitialData);
    const [operation, setOperation] = useState<IOperationDashboard>(operationDashboardInitialData);
    const [operationCases, setOperationCases] = useState<IOperationCases>(operationCasesInitialData);
    const [advisers, setAdvisers] = useState<IAdviserSection>(adviserInitialData);
    const [privilege, setPrivilege] = useState<Privileges>('branch');
    const [operationName, setOperationName] = useState<string>('');
    const [rejectedBy, setRejectedBy] = useState<string>('');
    const [rejectRemarks, setRejectRemarks] = useState<string>('');
    const [operationStatus, setOperationStatus] = useState<string>('Successful');
    const location = useLocation();
    const locationState: any = location.state;
    const id = locationState.id;
    const maker = locationState.maker;
    const approval: boolean = locationState.approval;

    // loader
    const { loading, loadingHandler } = useComponentLoader();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const handleRoleName = (event: ChangeEvent<HTMLInputElement>) => {
        setRoleName(event.target.value);
    };

    const handleRoleDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRoleDescription(event.target.value);
    };

    const handleBranchPrivilege = () => {
        if (privilege !== 'branch') {
            setPrivilege('branch');
        }
    };
    const handleHqPrivilege = () => {
        if (privilege !== 'hq') {
            setPrivilege('hq');
        }
    };

    const handleTitle = (operationName: string) => {
        switch (operationName) {
            case 'Approved Create Role Request':
                return 'Approved Add Role Request';
            case 'Rejected Create Role Request':
                return 'Rejected Add New Role Request';
            case 'Rejected Edit Role Request':
                return 'Rejected Edit Role Request';
            case 'Update User Role':
                if (maker === true) {
                    return 'Review Edit Role Request';
                } else {
                    return operationName;
                }
                break;
            default:
                return operationName;
        }
    };

    const branchProps = {
        approval,
        loginAccess,
        setLoginAccess,
        advisers,
        setAdvisers,
        customerExp,
        setCustomerExp,
    };
    const hqProps = {
        approval,
        hqLoginAccess,
        setHqLoginAccess,
        userManagement,
        setUserManagement,
        activityLogs,
        setActivityLogs,
        systemSettings,
        setSystemSettings,
        customerExpDashboard,
        setCustomerExpDashboard,
        branchOffices,
        setBranchOffices,
        eddCase,
        setEddCase,
        financeDashboard,
        setFinanceDashboard,
        financeCases,
        setFinanceCases,
        productSettings,
        setProductSettings,
        fundUploads,
        setFundUploads,
        operationDashBoard: operation,
        setOperationDashBoard: setOperation,
        operationCases,
        setOperationCases,
    };

    const getRoleData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsSummaryQuery, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );

            const result = response.data.getLogData.data.result;
            setOperationName(result.data.operationName);
            setOperationStatus(result.data.operationStatus);
            if (result.data.status.toLowerCase() === 'rejected') {
                setRejectedBy(result.data.name);
                setRejectRemarks(result.data.remark);
            }

            const allData = result.data;
            if (result.data.status.toLowerCase() === 'failed') {
                const failedRoleResponse = JSON.parse(allData.data);
                if (
                    result.data.operationName.toLowerCase().includes('delete') ||
                    result.data.operationName.toLowerCase().includes('suspend') ||
                    result.data.operationName.toLowerCase().includes('reactivate')
                ) {
                    setRoleName(failedRoleResponse.name);
                    setStatus(failedRoleResponse.currentStatus !== undefined ? failedRoleResponse.currentStatus : '-');
                    setRoleDescription(
                        failedRoleResponse.description !== undefined ? failedRoleResponse.description : '',
                    );
                    const { permission, grant } = failedRoleResponse.permission;
                    setPrivilege(grant);

                    if (grant === 'branch') {
                        const newLoginAccess = setInitialData(
                            permission.accountManagement !== undefined ? permission.accountManagement : loginAccess,
                            loginAccess,
                            grant,
                            'accountManagement',
                        ) as ILoginRoleSection;
                        setLoginAccess(newLoginAccess);
                        const newCustomerExp = setInitialData(
                            permission.ceTransaction !== undefined ? permission.ceTransaction : customerExp,
                            customerExp,
                            grant,
                            'ceTransaction',
                        ) as ICustomerExpSection;
                        setCustomerExp(newCustomerExp);
                        const newAdvisers = setInitialData(
                            permission.adviser !== undefined ? permission.adviser : advisers,
                            advisers,
                            grant,
                            'adviser',
                        ) as IAdviserSection;

                        setAdvisers(newAdvisers);
                    } else {
                        const newHqLoginAccess = setInitialData(
                            permission.accountManagement !== undefined ? permission.accountManagement : hqLoginAccess,
                            hqLoginAccess,
                            grant,
                            'accountManagement',
                        ) as ILoginRoleSection;
                        setHqLoginAccess(newHqLoginAccess);
                        const newHqUserManagement = setInitialData(
                            permission.userManagement !== undefined ? permission.userManagement : userManagement,
                            userManagement,
                            grant,
                            'userManagement',
                        ) as IUserManagementSection;

                        setUserManagement(newHqUserManagement);
                        const newHqActivityLogs = setInitialData(
                            permission.activityLogs !== undefined ? permission.activityLogs : activityLogs,
                            activityLogs,
                            grant,
                            'activityLogs',
                        ) as IActivityLogsSection;
                        setActivityLogs(newHqActivityLogs);
                        const newHqSystemSettings = setInitialData(
                            permission.systemSettings !== undefined ? permission.systemSettings : systemSettings,
                            systemSettings,
                            grant,
                            'systemSettings',
                        ) as ISystemSettingsSection;
                        setSystemSettings(newHqSystemSettings);
                        const newHqCustomerExpDashboard = setInitialData(
                            permission.ceDashboard !== undefined ? permission.ceDashboard : customerExpDashboard,
                            customerExpDashboard,
                            grant,
                            'ceDashboard',
                        ) as ICustomerExperienceSection;
                        setCustomerExpDashboard(newHqCustomerExpDashboard);
                        const newBranchOffices = setInitialData(
                            permission.branchOffices !== undefined ? permission.branchOffices : branchOffices,
                            branchOffices,
                            grant,
                            'branchOffices',
                        ) as IBranchOfficeSection;
                        setBranchOffices(newBranchOffices);
                        const newProductSettings = setInitialData(
                            permission.productSettings !== undefined ? permission.productSettings : productSettings,
                            productSettings,
                            grant,
                            'productSettings',
                        ) as IProductSettings;
                        setProductSettings(newProductSettings);
                        const newFundUploads = setInitialData(
                            permission.uploads !== undefined ? permission.uploads : fundUploads,
                            fundUploads,
                            grant,
                            'uploads',
                        ) as IFundUploads;
                        setFundUploads(newFundUploads);
                        const newEddCase = setInitialData(
                            permission.eddCase,
                            eddCase,
                            grant,
                            'eddCase',
                        ) as IEDDCasesSection;
                        setEddCase(newEddCase);
                    }
                } else {
                    setRoleName(failedRoleResponse.roleName);
                    setStatus('-');
                    setRoleDescription(
                        failedRoleResponse.description !== undefined ? failedRoleResponse.description : '',
                    );
                    setPrivilege(failedRoleResponse.grant);
                    if (failedRoleResponse.grant === 'branch') {
                        const newLoginAccess = setInitialData(
                            failedRoleResponse.permission.accountManagement !== undefined
                                ? failedRoleResponse.permission.accountManagement
                                : loginAccess,
                            loginAccess,
                            failedRoleResponse.grant,
                            'accountManagement',
                        ) as ILoginRoleSection;
                        setLoginAccess(newLoginAccess);
                        const newCustomerExp = setInitialData(
                            failedRoleResponse.permission.ceTransaction !== undefined
                                ? failedRoleResponse.permission.ceTransaction
                                : customerExp,
                            customerExp,
                            failedRoleResponse.grant,
                            'ceTransaction',
                        ) as ICustomerExpSection;
                        setCustomerExp(newCustomerExp);
                        const newAdvisers = setInitialData(
                            failedRoleResponse.permission.adviser !== undefined
                                ? failedRoleResponse.permission.adviser
                                : advisers,
                            advisers,
                            failedRoleResponse.grant,
                            'adviser',
                        ) as IAdviserSection;

                        setAdvisers(newAdvisers);
                    } else {
                        const newHqLoginAccess = setInitialData(
                            failedRoleResponse.permission.accountManagement !== undefined
                                ? failedRoleResponse.permission.accountManagement
                                : hqLoginAccess,
                            hqLoginAccess,
                            failedRoleResponse.grant,
                            'accountManagement',
                        ) as ILoginRoleSection;
                        setHqLoginAccess(newHqLoginAccess);
                        const newHqUserManagement = setInitialData(
                            failedRoleResponse.permission.userManagement !== undefined
                                ? failedRoleResponse.permission.userManagement
                                : userManagement,
                            userManagement,
                            failedRoleResponse.grant,
                            'userManagement',
                        ) as IUserManagementSection;

                        setUserManagement(newHqUserManagement);
                        const newHqActivityLogs = setInitialData(
                            failedRoleResponse.permission.activityLogs !== undefined
                                ? failedRoleResponse.permission.activityLogs
                                : activityLogs,
                            activityLogs,
                            failedRoleResponse.grant,
                            'activityLogs',
                        ) as IActivityLogsSection;
                        setActivityLogs(newHqActivityLogs);
                        const newHqSystemSettings = setInitialData(
                            failedRoleResponse.permission.systemSettings !== undefined
                                ? failedRoleResponse.permission.systemSettings
                                : systemSettings,
                            systemSettings,
                            failedRoleResponse.grant,
                            'systemSettings',
                        ) as ISystemSettingsSection;
                        setSystemSettings(newHqSystemSettings);
                        const newHqCustomerExpDashboard = setInitialData(
                            failedRoleResponse.permission.ceDashboard !== undefined
                                ? failedRoleResponse.permission.ceDashboard
                                : customerExpDashboard,
                            customerExpDashboard,
                            failedRoleResponse.grant,
                            'ceDashboard',
                        ) as ICustomerExperienceSection;
                        setCustomerExpDashboard(newHqCustomerExpDashboard);
                        const newBranchOffices = setInitialData(
                            failedRoleResponse.permission.branchOffices !== undefined
                                ? failedRoleResponse.permission.branchOffices
                                : branchOffices,
                            branchOffices,
                            failedRoleResponse.grant,
                            'branchOffices',
                        ) as IBranchOfficeSection;
                        setBranchOffices(newBranchOffices);
                        const newProductSettings = setInitialData(
                            failedRoleResponse.permission.productSettings !== undefined
                                ? failedRoleResponse.permission.productSettings
                                : productSettings,
                            productSettings,
                            failedRoleResponse.grant,
                            'productSettings',
                        ) as IProductSettings;
                        setProductSettings(newProductSettings);
                        const newFundUploads = setInitialData(
                            failedRoleResponse.permission.uploads !== undefined
                                ? failedRoleResponse.permission.uploads
                                : fundUploads,
                            fundUploads,
                            failedRoleResponse.grant,
                            'uploads',
                        ) as IFundUploads;
                        setFundUploads(newFundUploads);
                        const newEddCase = setInitialData(
                            failedRoleResponse.permission.eddCase !== undefined
                                ? failedRoleResponse.permission.eddCase
                                : eddCase,
                            eddCase,
                            failedRoleResponse.grant,
                            'eddCase',
                        ) as IEDDCasesSection;
                        setEddCase(newEddCase);
                    }
                }
            } else {
                const info = JSON.parse(allData.data);

                setRoleName(info.name);
                const status =
                    allData.status.toLowerCase() === 'rejected'
                        ? info.currentStatus.toLowerCase() === 'pending'
                            ? '-'
                            : info.currentStatus
                        : info.requestedStatus === null
                        ? '-'
                        : info.requestedStatus;
                setStatus(status);
                setRoleDescription(info.description);

                const parsedPermission =
                    typeof info.permission !== 'string' ? info.permission : JSON.parse(info.permission);

                const { permission, grant } = parsedPermission;

                setPrivilege(grant);

                if (grant === 'branch') {
                    const newLoginAccess = setInitialData(
                        permission.accountManagement,
                        loginAccess,
                        grant,
                        'accountManagement',
                    ) as ILoginRoleSection;
                    setLoginAccess(newLoginAccess);
                    const newCustomerExp = setInitialData(
                        permission.ceTransaction,
                        customerExp,
                        grant,
                        'ceTransaction',
                    ) as ICustomerExpSection;
                    setCustomerExp(newCustomerExp);
                    const newAdvisers = setInitialData(
                        permission.adviser,
                        advisers,
                        grant,
                        'adviser',
                    ) as IAdviserSection;

                    setAdvisers(newAdvisers);
                } else {
                    const newHqLoginAccess = setInitialData(
                        permission.accountManagement,
                        hqLoginAccess,
                        grant,
                        'accountManagement',
                    ) as ILoginRoleSection;
                    setHqLoginAccess(newHqLoginAccess);
                    const newHqUserManagement = setInitialData(
                        permission.userManagement,
                        userManagement,
                        grant,
                        'userManagement',
                    ) as IUserManagementSection;

                    setUserManagement(newHqUserManagement);
                    const newHqActivityLogs = setInitialData(
                        permission.activityLogs,
                        activityLogs,
                        grant,
                        'activityLogs',
                    ) as IActivityLogsSection;
                    setActivityLogs(newHqActivityLogs);
                    const newHqSystemSettings = setInitialData(
                        permission.systemSettings,
                        systemSettings,
                        grant,
                        'systemSettings',
                    ) as ISystemSettingsSection;
                    setSystemSettings(newHqSystemSettings);
                    const newHqCustomerExpDashboard = setInitialData(
                        permission.ceDashboard,
                        customerExpDashboard,
                        grant,
                        'ceDashboard',
                    ) as ICustomerExperienceSection;
                    setCustomerExpDashboard(newHqCustomerExpDashboard);
                    const newBranchOffices = setInitialData(
                        permission.branchOffices,
                        branchOffices,
                        grant,
                        'branchOffices',
                    ) as IBranchOfficeSection;
                    setBranchOffices(newBranchOffices);
                    const newProductSettings = setInitialData(
                        permission.productSettings,
                        productSettings,
                        grant,
                        'productSettings',
                    ) as IProductSettings;
                    setProductSettings(newProductSettings);
                    const newFundUploads = setInitialData(
                        permission.uploads,
                        fundUploads,
                        grant,
                        'uploads',
                    ) as IFundUploads;
                    setFundUploads(newFundUploads);
                    const newEddCase = setInitialData(
                        permission.eddCase,
                        eddCase,
                        grant,
                        'eddCase',
                    ) as IEDDCasesSection;
                    setEddCase(newEddCase);
                }
            }
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            setErrorMessage({
                ...errorMessage,
                message: _error.message,
                errorCode: _error.errorCode,
                title: 'Cannot Fetch Role Data',
                testId: 'activitylogs-roles-error-modal',
            });
            handleErrorHandler();
        }
    };

    useEffect(() => {
        getRoleData();
    }, []);
    const disabledStyle: React.CSSProperties = approval === true ? { opacity: 0.5, pointerEvents: 'none' } : {};

    return (
        <Container>
            <div>
                <PreviousPage
                    backIcon={true}
                    title={handleTitle(operationName)}
                    handleBack={Routes.activityLogs}
                    suffixHelper={operationStatus}
                />
                <div style={{ paddingLeft: '2.5rem' }}>
                    {rejectedBy !== '' && rejectRemarks !== '' ? (
                        <RejectedBanner direction="column">
                            <TextPureBlack>{`${LABEL.thisRequestRejectedBy} ${rejectedBy}.`}</TextPureBlack>
                            <CustomSpacer space={'.5rem'} />
                            <Fs12BoldPrimaryBlack>{LABEL.rejectReason}</Fs12BoldPrimaryBlack>
                            <Fs12RegPrimaryBlack>{rejectRemarks}</Fs12RegPrimaryBlack>
                        </RejectedBanner>
                    ) : null}
                    <CustomSpacer space={'1.5rem'} />
                    <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DETAILS}</Fs18BoldPrimaryBlack>
                    <CustomSpacer space={'1.5rem'} />
                    <FlexedDiv direction="row">
                        <FlexedDiv direction="column" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                            <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_NAME}
                            </Fs12BoldPrimaryBlack>
                            <BasicInput disabled value={roleName} maxLength={255} onInput={handleRoleName} />
                        </FlexedDiv>
                        <CustomSpacer horizontal={true} space={'4rem'} />
                        <FlexedDiv direction="column" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                            <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                {SYSTEM_ADMIN.ADD_ROLE.LABEL_STATUS}
                            </Fs12BoldPrimaryBlack>
                            <DropdownBase>
                                <BasicInputDropdown value={status} />
                            </DropdownBase>
                        </FlexedDiv>
                    </FlexedDiv>
                    <CustomSpacer space={'2rem'} />

                    <FlexedDiv direction="column" style={disabledStyle}>
                        <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                            {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DESCRIPTION}
                        </Fs12BoldPrimaryBlack>
                        <BasicTextArea value={roleDescription} onChange={handleRoleDescription} />
                        <FlexedDiv direction="row" style={{ width: '22.5rem' }}>
                            <FlexSpacer />
                            <Fs10RegNeutralGrey4>{roleDescription.length}/255</Fs10RegNeutralGrey4>
                        </FlexedDiv>
                    </FlexedDiv>
                    <CustomSpacer space={'0.5rem'} />
                </div>
                <div style={{ backgroundColor: '#EAEBEE', height: '1px' }} />
                <CustomSpacer space={'2rem'} />
                <div style={{ paddingLeft: '2.5rem' }}>
                    <Fs18BoldPrimaryBlack>{SYSTEM_ADMIN.ADD_ROLE.LABEL_GRANT_PRIVILEGES}</Fs18BoldPrimaryBlack>
                    <CustomSpacer space={'1.75rem'} />
                    <DisabledView>
                        <RadioButton
                            label={SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_BACKOFFICE}
                            selected={privilege === 'branch'}
                            setSelected={handleBranchPrivilege}
                        />
                        <CustomSpacer space={'1rem'} />
                        <RadioButton
                            label={SYSTEM_ADMIN.ADD_ROLE.LABEL_HQ_BACKOFFICE}
                            selected={privilege === 'hq'}
                            setSelected={handleHqPrivilege}
                        />
                    </DisabledView>
                    {privilege === 'branch' ? (
                        <Fragment>
                            <CustomSpacer space={'1.75rem'} />
                            <div
                                style={{
                                    backgroundColor: '#EAEBEE',
                                    height: '1px',
                                    marginRight: '8.5rem',
                                }}
                            />
                            <CustomSpacer space={'1.5rem'} />
                            <BranchPrivileges {...branchProps} />
                        </Fragment>
                    ) : null}
                    {privilege === 'hq' ? (
                        <Fragment>
                            <CustomSpacer space={'1.75rem'} />
                            <div
                                style={{
                                    backgroundColor: '#EAEBEE',
                                    height: '1px',
                                    marginRight: '8.5rem',
                                }}
                            />
                            <CustomSpacer space={'1.5rem'} />
                            <HqPrivileges {...hqProps} />
                        </Fragment>
                    ) : null}
                    {loading ? <ComponentLoader /> : null}
                    <CustomSpacer space={'1.5rem'} />
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    overflow: hidden;
    position: relative;
`;

const DisabledView = styled.div`
    opacity: 0.5;
    pointer-events: none;
`;

const RejectedBanner = styled((props) => <FlexedDiv {...props} />)`
    background-color: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
    max-width: 70vw;
`;

const BasicInput = styled.input.attrs({ type: 'input' })`
    background-color: white;
    border-color: #c9c9cd;
    border-width: 0.0625rem;
    border: 1px solid #c9c9cd;
    border-radius: 24px;
    height: 2.5rem;
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
    width: 22.5rem;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

export const BasicInputDropdown = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 19.25rem;
`;

export const BasicTextArea = styled.textarea`
    height: 7.5rem;
    border-radius: 8px;
    width: 22.5rem;
    border: 1px solid #c9c9cd;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;
