import gql from 'graphql-tag';
/**
 * @eddReasonListQuery @query - EDD Reasons list
 * @description: Retrieving Edd Reroute Reasons List
 */

export const eddReasonListQuery = gql`
    query eddReasonList($input: EddReasonListInput) {
        eddReasonList(input: $input) {
            data {
                result {
                    reasons {
                        reasonId
                        category
                        subCategory
                    }
                    targetDate
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
