import { ROUTES } from '../pages';

export * as ROUTES from '../routes';

/** Checks for the routes for the given dashboard for which the state in context has to be kept or not
 * @param dashboard - name of the dashboard to navigate from
 * @param route - route to which to navigate to
 * @returns true If the state is to be kept
 * @returns false If the state is to be cleared
 */
export const clearContextOnNavigation = (dashboard: string, route: string): boolean => {
    let temp = false;
    const routesToKeepContext = {
        branchDashboard: [
            ROUTES.dashboardBranch,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/document`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/track`,
            `${ROUTES.dashboardBranch}/${ROUTES.rejectTransaction}`,
            `${ROUTES.dashboardBranch}/${ROUTES.rerouteTransaction}`,
            `${ROUTES.dashboardBranch}/${ROUTES.extendTargetDate}`,
            `${ROUTES.dashboardBranch}/${ROUTES.rejectTargetDate}`,
            `${ROUTES.dashboardBranch}/${ROUTES.extendTargetDateRequest}`,
            ROUTES.branchTrackOrder,
        ],
        viewBranch: [
            ROUTES.viewBranch,
            ROUTES.hqRejectExtension,
            ROUTES.hqTargetDateExtension,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}`,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}/document`,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}/track`,
            `${ROUTES.viewBranch}/${ROUTES.rejectTransaction}`,
            `${ROUTES.viewBranch}/${ROUTES.rerouteTransaction}`,
            `${ROUTES.viewBranch}/${ROUTES.extendTargetDate}`,
            `${ROUTES.viewBranch}/${ROUTES.rejectTargetDate}`,
            `${ROUTES.viewBranch}/${ROUTES.extendTargetDateRequest}`,
            ROUTES.hqTrackOrder,
            ROUTES.hqSingleBranchTrackOrder,
        ],
        eddDashboard: [
            ROUTES.amlaEDDManagement,
            ROUTES.satisfactory,
            ROUTES.rerouteEddCase,
            ROUTES.satisfactoryCase,
            ROUTES.unsatisfactoryCase,
            ROUTES.reroutedEddCaseSatisfactory,
            ROUTES.reroutedEddCaseUnsatisfactory,
            ROUTES.rerouteEddCaseUnsatisfactory,
            ROUTES.cancelRequest,
            ROUTES.cancelledSatisfactoryCase,
        ],
        advisersDashboard: [ROUTES.advisers, ROUTES.branchAdvisorProfile],
        activityLogsDashboard: [
            ROUTES.activityLogs,
            ROUTES.activityLogsFund,
            ROUTES.activityLogsRoles,
            ROUTES.activityLogsGroups,
            ROUTES.activityLogsUser,
            ROUTES.activityLogsReroute,
            ROUTES.activityLogsDueDate,
            ROUTES.activityLogsOrderSummary,
            ROUTES.activityLogsReject,
            ROUTES.activityLogsAdviser,
            ROUTES.activityLogsBulkUpload,
            ROUTES.activityLogsBulkImport,
            ROUTES.activityLogsEpfSettings,
        ],
        pendingSubmission: [
            ROUTES.hqPendingSubmission,
            ROUTES.hqAllPendingSubmission,
            ROUTES.branchPendingSubmission,
            // ROUTES.viewBranch,
            `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}`,
            ROUTES.hqPendingSubmissionTrackOrder,
            ROUTES.hqAllPendingSubmissionTrackOrder,
            ROUTES.branchPendingSubmissionTrackOrder,
        ],
        tracking: [
            ROUTES.hqTrackOrder,
            ROUTES.branchTrackOrder,
            ROUTES.branchPendingSubmissionTrackOrder,
            ROUTES.hqPendingSubmissionTrackOrder,
            ROUTES.hqAllPendingSubmissionTrackOrder,
            ROUTES.hqAllSubmissionsTrackOrder,
            ROUTES.hqSingleBranchTrackOrder,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}`,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}/document`,
            `${ROUTES.viewBranch}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}/track`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}/document`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}/track`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/document`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/track`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqPendingSubmissionTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.hqPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqSingleBranchTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.hqSingleBranchTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqSingleBranchTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqSingleBranchTrackOrder}/${ROUTES.orderSummary}/track`,
        ],
        allSubmitted: [
            ROUTES.hqAllSubmissions,
            ROUTES.hqAllSubmissionsTrackOrder,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTransaction}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.rerouteTransaction}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDate}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTargetDate}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDateRequest}`,
        ],
    };
    switch (dashboard) {
        case 'branchDashboard':
            if (routesToKeepContext.branchDashboard.includes(route)) temp = true;
            else temp = false;
            break;
        case 'viewBranch':
            if (routesToKeepContext.viewBranch.includes(route)) temp = true;
            else temp = false;
            break;
        case 'eddDashboard':
            if (routesToKeepContext.eddDashboard.includes(route)) temp = true;
            else temp = false;
            break;
        case 'advisersDashboard':
            if (routesToKeepContext.advisersDashboard.includes(route)) temp = true;
            else temp = false;
            break;
        case 'activityLogs':
            if (routesToKeepContext.activityLogsDashboard.includes(route)) temp = true;
            else temp = false;
            break;
        case 'pendingSubmission':
            if (routesToKeepContext.pendingSubmission.includes(route)) temp = true;
            else temp = false;
            break;
        case 'tracking':
            if (routesToKeepContext.tracking.includes(route)) temp = true;
            else temp = false;
            break;
        case 'allSubmittedDashboard':
            if (routesToKeepContext.allSubmitted.includes(route)) temp = true;
            else temp = false;
            break;
    }
    return temp;
};
