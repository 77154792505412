/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { EMPTY_STATES, LABEL } from '../../../constants';
import { CreatedOn } from './CreatedOn';
import { TargetDateExActions } from './TargetDateExtensionsActions';
import { GroupBy } from './GroupBy';
import { subTitleStyle, titleStyle } from '../../../styles';
import { statusStyle } from '../../../utils';
import { AdvanceTable, Modal } from '../../../components/organisms';
import { IColumnValue } from '../../Advisers';
import { dueDateExtensionMutation } from '../../../_graphql/mutations/hq/dueDateExtension/dueDateExtension';
import { FlexedDiv } from '../../../components';
import { IDropdownWithKey } from '../DashboardHQCE';
import { IDueDateTab } from '../../../utils/permissionTypes';

import * as Routes from '../../../routes';
import moment from 'moment';
import CustomItem from './CustomItem';

interface ITargetDateExProps {
    dueDateTab: IDueDateTab;
    filters: IColumnValue[];
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    lastUpdatedDropdown: IDropdownWithKey;
    searchEmpty: boolean;
    searchInput: string;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: IColumnValue[]) => void;
    setLastUpdatedDropdown: (value: IDropdownWithKey) => void;
    setShowModal: (toggle: boolean) => void;
    setSortData: (sort: ISort_FilterInput) => void;
    setTransactionType: (value: IDropdownWithKey) => void;
    showModal: boolean;
    sortData: ISort_FilterInput;
    targetDateData: ITableData[];
    transactionType: IDropdownWithKey;
}

const TargetDateExtension: React.FC<ITargetDateExProps> = ({
    dueDateTab,
    filters,
    idTokenHeader,
    lastUpdatedDropdown,
    searchEmpty,
    searchInput,
    setErrorHandler,
    setErrorMessage,
    setFilters,
    setLastUpdatedDropdown,
    setShowModal,
    setSortData,
    showModal,
    sortData,
    targetDateData,
    transactionType,
}: ITargetDateExProps) => {
    const [currentData, setCurrentData] = useState<ITableData>();

    const handleLastUpdatedFilter = (item: string, value: string) => {
        const temp = [...filters];
        let currentFilter = 'createdOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
        }
        temp[0] = {
            column: currentFilter,
            value: '',
        };
        setLastUpdatedDropdown({ value: item, keyName: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
        setFilters(temp);
    };

    const handleLastUpdated = (menu: IHeaderMenu) => {
        return (
            <CreatedOn
                {...menu}
                dropdownData={[
                    // eslint-disable-next-line react/prop-types
                    lastUpdatedDropdown.value,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === lastUpdatedDropdown.keyName ? sortData[0].value : '',
                ]}
                setData={handleLastUpdatedFilter}
            />
        );
    };

    const history = useHistory();

    const handleReject = (item: IColumnItemAccordion) => {
        const data = { item: item.rawData, isDisabled: true };

        history.push(Routes.hqRejectExtension, data);
    };

    const handleViewExtension = (item: IColumnItemAccordion) => {
        const data = { item: item.rawData, isDisabled: true };

        history.push(Routes.hqTargetDateExtension, data);
    };

    const handleApprove = async (item: IColumnItemAccordion) => {
        setCurrentData(item.rawData);
        try {
            const response: any = await API.graphql(
                graphqlOperation(dueDateExtensionMutation, {
                    input: {
                        requestId: item.rawData.requestId,
                        action: 'Approve',
                        targetDate: item.rawData.targetDate,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.targetDateResponse.data !== null) {
                setShowModal(true);
            } else {
                throw response.data.targetDateResponse.error;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setErrorHandler();
            setErrorMessage({
                title: 'Cannot Approve Due Date Extension',
                message: _error.message,
                errorCode: _error.errorCode,
            });
        }
    };

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const dateInt = currentData !== undefined ? parseInt(currentData.targetDate, 10) : 0;
    const modalTitle = LABEL.dueDateExtensionApprovedTitle;
    const modalSubtitle =
        currentData !== undefined
            ? `${LABEL.dueDateExtensionApprovedSubtitle1} ${moment(dateInt).format('DD/MM/YYYY')} ${
                  LABEL.dueDateExtensionApprovedSubtitle2
              }`
            : `${LABEL.dueDateExtensionApprovedSubtitle1} ${moment().format('DD/MM/YYYY')} ${
                  LABEL.dueDateExtensionApprovedSubtitle2
              }`;

    const columns: ITableColumn[] = [
        {
            customItem: true,
            key: [{ key: lastUpdatedDropdown.keyName }],
            viewStyle: {
                width: '6.88vw',
                justifyContent: 'center',
            },
            icon: { name: 'caret-down', size: '1rem' },
            title: lastUpdatedDropdown.value,
            titleStyle: {
                fontWeight: sortData[0].column === lastUpdatedDropdown.keyName ? 700 : 400,
            },
            RenderHeaderMenu: (props: IHeaderMenu) => handleLastUpdated(props),
        },
        {
            key: [{ key: 'clientName' }, { key: 'clientIdNum', textStyle: subTitleStyle }, { key: 'accountType' }],
            viewStyle: {
                width: '10vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'clientName' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('clientName'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'clientName' ? 700 : 400 },
            },
            onPressHeader: () => handleSort('clientName'),
            title: LABEL.investorName,
            subtitle: LABEL.idNo,
            customItem: true,
        },
        {
            key: [
                { key: 'orderNo', textStyle: { fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400 } },
                { key: 'transactionRef', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '6.11vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400 },
            },
            onPressHeader: () => handleSort('transactionRef'),
            title: LABEL.transNo,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: '6.66vw',
            },
            icon: { name: 'arrow-down', size: '1rem', style: { opacity: 0.6 } },
            title: transactionType.value,
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionType' ? 700 : 400,
            },
            // onPressHeader: () => handleSort('transactionType'),
        },
        {
            key: [
                { key: 'fundType', textStyle: { ...titleStyle, fontWeight: 700 } },
                { key: 'paymentMethod', textStyle: { ...subTitleStyle, textTransform: 'initial' } },
            ],
            viewStyle: {
                width: '4.44vw',
            },
            title: LABEL.products,
            subtitle: LABEL.fundType,
        },
        {
            key: [{ key: 'totalInvestment' }],
            viewStyle: {
                width: '8.33vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400 },
            },
            onPressHeader: () => handleSort('totalInvestment'),
            title: LABEL.totalInvestments,
            customItem: true,
        },
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: '7vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 },
            },
            onPressHeader: () => handleSort('agentName'),
            title: LABEL.adviser,
            subtitle: LABEL.code,
        },
        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: '7.55vw',
            },
            title: 'Status',
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: {
                width: '3.56vw',
                textAlign: 'center',
            },
            title: LABEL.remarks,
            customItem: true,
        },
        {
            key: [{ key: 'targetDate' }],
            viewStyle: {
                width: '6.2vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'targetDate' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('targetDate'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'targetDate' ? 700 : 400 },
            },
            onPressHeader: () => handleSort('targetDate'),
            title: LABEL.dueDate,
            subtitle: LABEL.aging,
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: '3vw',
            },
            itemIcon: dueDateTab.actions.canViewDetails
                ? {
                      name: 'eye-show',
                      size: '1.75rem',
                  }
                : undefined,
            title: LABEL.view,
            onClickItem: dueDateTab.actions.canViewDetails
                ? (item: IColumnItemAccordion) => handleViewExtension(item)
                : undefined,
            testId: 'target-date-extension-view-column',
        },
    ];

    return (
        <Fragment>
            <AdvanceTable
                data={targetDateData}
                columns={columns}
                onEmptyState={
                    searchEmpty ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateDueDateExtension
                }
                RenderOptions={
                    dueDateTab.reviewApproval.canApproveDueDateExtension
                        ? (props: ITableOptions) => (
                              <TargetDateExActions
                                  {...props}
                                  handleApprove={handleApprove}
                                  handleReject={handleReject}
                              />
                          )
                        : undefined
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                groupByLabel={'groupByLabel'}
                RenderGroupByLabel={(props: ITableGroupBy) => <GroupBy {...props} />}
                isSearchResult={searchInput !== ''}
                searchInput={searchInput}
                testId="hq-target-date-extension"
            />
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTitle}
                    icon={'targetdate-modal'}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.dashboard);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="hq-target-date-extension-modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubtitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default TargetDateExtension;
