/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { RegulartTooltipTemplate, Tooltip } from '../../components';
import { IcoMoon } from '../../icons';
import { orderSummary } from '../../routes';
import { DateColumnItem, TotalInvestmentColumnItem, TransactionNumberColumnItem } from '../../templates';
import { ROUTES } from '../DashboardBranch';
import { IOrderSummaryRouteProps } from '../OrderDetails/orderSummaryTypes';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const _history = useHistory();
    const Remarks = () => {
        return data && data.remarks.length !== 0 ? (
            <Tooltip tooTipContent={<RegulartTooltipTemplate message={data.reasons} />} style={{ padding: '1rem' }}>
                <div>
                    <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                </div>
            </Tooltip>
        ) : (
            <div>--</div>
        );
    };

    const FundCodes = () => {
        return data && data.fundCode ? (
            <div>
                {Array.isArray(data.fundCode) ? (
                    data.fundCode.map((code: { fundAbbr: string }, index: number) => {
                        return <div key={code.fundAbbr + index}>{code.fundAbbr ?? ''}</div>;
                    })
                ) : (
                    <div>{data.fundCode}</div>
                )}
            </div>
        ) : null;
    };

    const Viewable = () => {
        // const branchId = sessionStorage.getItem('branchId');
        const _orderSummaryState: IOrderSummaryRouteProps = {
            tab: 'daily',
            transactionRef: data.transactionRef || undefined,
            orderNo: data.orderNo,
            endpoint: 'branchDashboard',
            url: window.location.pathname,
        };

        const handleViewDetails = () => {
            const _route =
                window.location.pathname === ROUTES.hqTrackOrder
                    ? `${ROUTES.viewBranch}/${ROUTES.orderSummary + `/track`}`
                    : orderSummary + `/track`;
            _history.push({ pathname: _route, state: _orderSummaryState });
        };

        return data && data.viewable === 'true' ? (
            <div onClick={() => handleViewDetails()} style={{ cursor: 'pointer' }}>
                <IcoMoon name="eye-show" size="1.75rem" />
            </div>
        ) : null;
    };
    const status = data.status as string;
    const renderItems = (key: string) => {
        switch (key) {
            case 'remarks':
                return <Remarks />;
            case 'totalInvestment':
                return <TotalInvestmentColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
            case 'lastUpdated':
            case 'createdOn':
            case 'submittedOn':
            case 'targetDate':
                return (
                    <DateColumnItem
                        tableBadge={
                            data.isVerified && !status.toLowerCase().includes('completed')
                                ? { color: '#243547', text: 'Verify Only' }
                                : undefined
                        }
                        data={data}
                        itemKey={itemKey}
                        isSortedColumn={isSortedColumn}
                        boldHeading
                    />
                );
            case 'fundCode':
                return <FundCodes />;
            case 'viewable':
                return <Viewable />;
        }
    };
    return (
        <Fragment>
            {itemKey.length === 1 ? (
                renderItems(itemKey[0].key)
            ) : (
                <Fragment>
                    <TransactionNumberColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />
                </Fragment>
            )}
        </Fragment>
    );
};
export default CustomItem;
