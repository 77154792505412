import React from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../..';
import { Fs12BoldPrimaryBlack5, TextDarkGrey6 } from '../../../constants';
import { IcoMoon } from '../../../icons';

interface ISelected {
    selected?: boolean;
}

export const SummaryCard = (props: ISummaryCardProps): JSX.Element => {
    const _tabInput = props.cardId ?? 0;
    const _label = props.dataLabel ?? '';

    const handleClick = () => {
        if (props.handleClick) {
            props.handleClick(_tabInput, _label);
        }
    };

    return (
        <SummaryCardWrapper onClick={() => handleClick()} selected={props.selected}>
            <CardTitle selected={props.selected}>{props.title}</CardTitle>
            <CardBody selected={props.selected}>
                <FlexedDiv justifyContent={'space-between'} alignItems="center">
                    <FlexedDiv>
                        {props.dataIcon && <IcoMoon name={props.dataIcon} size="32px"></IcoMoon>}
                        <CardDataInfo>
                            <TextDarkGrey6 size="20px" weight="bold">
                                {props.data}
                            </TextDarkGrey6>
                            <Fs12BoldPrimaryBlack5>{props.dataLabel}</Fs12BoldPrimaryBlack5>
                        </CardDataInfo>
                    </FlexedDiv>
                    {!props.noClickIcon && (
                        <CardIconWrapper>
                            <IcoMoon name={props.clickIconName || 'arrow-right'} size="16px"></IcoMoon>
                        </CardIconWrapper>
                    )}
                </FlexedDiv>
            </CardBody>
        </SummaryCardWrapper>
    );
};

const CardTitle = styled.div<ISelected>`
    background: ${(props) => (props.selected ? ' #002043' : '#f2f4f6')};
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: ${(props) => (props.selected ? '#ffffff' : '#4d637b')};
    padding: 8px 16px;
    border-radius: 8px 8px 0px 0px;
`;
const CardBody = styled.div<ISelected>`
    padding: 16px 16px;
    ${(props) => (props.selected ? 'background: #E7EBF1;' : '')}
    border-radius: 0px 0px 8px 8px;
`;
const CardDataInfo = styled.div`
    margin-left: 8px;
`;

const CardIconWrapper = styled.div<ISelected>`
    visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
`;

const SummaryCardWrapper = styled.div<ISelected>`
    z-index: 2;
    border-radius: 8px;
    border: 1px solid #e7ebf1;
    max-width: 370px;
    font-size: 0;
    ${(props) =>
        props.selected
            ? 'box-shadow: 3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06),#002043 0px 0px 0px 1px inset;'
            : 'box-shadow: 0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02),#e7ebf1 0px 0px 0px 1px inset;'}
    &:hover {
        box-shadow: 3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06),
            #002043 0px 0px 0px 1px inset;
    }
    &:hover ${CardTitle} {
        background: #002043;
        color: #ffffff;
    }
    &:hover ${CardIconWrapper} {
        visibility: visible;
    }
`;
