import React, { Fragment } from 'react';
import { EMPTY_STATES, LABEL } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { hqAdvisers, viewBranch } from '../../../routes';
import { AdvanceTable } from '../../../components/organisms';

export interface IAllBranchProps {
    viewAdvisors?: boolean;
    searchInput: string;
    sortData: ISort_FilterInput;
    allBranchData: ITableData[];
    searchEmpty: boolean;
    setSortData: (sort: ISort_FilterInput) => void;
}

const AllBranch: React.FC<IAllBranchProps> = ({
    viewAdvisors,
    allBranchData,
    searchEmpty,
    searchInput,
    sortData,
    setSortData,
}: IAllBranchProps) => {
    const history = useHistory();

    const handleViewBranches = (item: IColumnItemAccordion) => {
        sessionStorage.setItem('branchId', item.rawData.branchId);
        sessionStorage.setItem('branchName', item.rawData.name);
        history.push(viewBranch, item);
    };

    const handelViewAdvisors = (item: IColumnItemAccordion) => {
        history.push(hqAdvisers, item);
    };

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const columns: ITableColumn[] = [
        {
            key: [{ key: 'name', textStyle: { fontWeight: sortData[0].column === 'name' ? 700 : 400 } }],
            viewStyle: {
                width: '15.5vw',
            },
            title: LABEL.branchName,

            titleStyle: {
                fontWeight: sortData[0].column === 'name' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('name'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('name'),
        },
        {
            key: [{ key: 'state', textStyle: { fontWeight: sortData[0].column === 'state' ? 700 : 400 } }],
            viewStyle: {
                width: '13.889vw',
            },
            title: LABEL.state,
            titleStyle: {
                fontWeight: sortData[0].column === 'state' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('state'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('state'),
        },
        {
            key: [{ key: 'dailyCount', textStyle: { fontWeight: sortData[0].column === 'dailyCount' ? 700 : 400 } }],
            viewStyle: {
                width: '13.889vw',
            },
            title: LABEL.noOfDailyTransactions,
            titleStyle: {
                fontWeight: sortData[0].column === 'dailyCount' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('dailyCount'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('dailyCount'),
        },
        {
            key: [
                {
                    key: 'rerouteCount',
                    textStyle: {
                        fontWeight: sortData[0].column === 'rerouteCount' ? 700 : 400,
                    },
                },
            ],
            viewStyle: {
                width: '13.889vw',
            },
            title: LABEL.noOfRerouteTransactions,
            titleStyle: {
                fontWeight: sortData[0].column === 'rerouteCount' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('rerouteCount'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('rerouteCount'),
        },
        {
            key: [
                { key: 'hardcopyCount', textStyle: { fontWeight: sortData[0].column === 'hardcopyCount' ? 700 : 400 } },
            ],
            viewStyle: {
                width: '14.65vw',
            },
            title: LABEL.noOfHardcopy,
            titleStyle: {
                fontWeight: sortData[0].column === 'hardcopyCount' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('hardcopyCount'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('hardcopyCount'),
        },
        {
            key: [],
            viewStyle: {
                width: '4.44vw',
                padding: 0,
                justifyContent: 'center',
            },
            itemIcon: {
                name: 'eye-show',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem: (item: IColumnItemAccordion) =>
                viewAdvisors ? handelViewAdvisors(item) : handleViewBranches(item),
            testId: 'allbranches-view-column',
        },
    ];
    return (
        <Fragment>
            <AdvanceTable
                data={allBranchData}
                columns={columns}
                isSearchResult={searchInput !== ''}
                onEmptyState={searchEmpty ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableOffices}
                searchInput={searchInput}
                testId="hq-allbranches"
            />
        </Fragment>
    );
};

export default AllBranch;
