import React from 'react';
import styled from 'styled-components';

interface IDividerProps {
    customStyles?: React.CSSProperties;
}

export const Divider: React.FC<IDividerProps> = ({ customStyles }: IDividerProps) => {
    return <DividerElm style={customStyles} />;
};

const DividerElm = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eaebee;
`;
