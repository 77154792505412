import React, { Fragment, useState } from 'react';
import { TextBold12 } from '../../../constants/textStyles';
import { IcoMoon } from '../../../icons';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    label?: string;
    style?: React.CSSProperties;
}
export const PasswordInput: React.FC<Props> = ({ error, label, style, ...rest }: Props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const toggleVisibility: () => void = () => {
        setVisible(!visible);
    };

    return (
        <Fragment>
            <div style={style}>
                <TextBold12 style={{ color: '#333333' }}>{label}</TextBold12>
                <StyledPasswordInputContainer data-testid="password-input-div">
                    <StyledSpan onClick={toggleVisibility} data-testid="toggle-visibility">
                        <IcoMoon name={visible ? 'eye-hide' : 'eye-show'} size="1.5rem" />
                    </StyledSpan>
                    <StyledPasswordInput
                        {...rest}
                        type={visible ? 'text' : 'password'}
                        className="input-password-padding"
                        data-testid="input-password"
                        error={error}
                    />
                </StyledPasswordInputContainer>
            </div>
        </Fragment>
    );
};

export default PasswordInput;

const StyledPasswordInput = styled.input<Props>`
    padding: 0.75rem 1.75rem 0.75rem 1rem;
    background: ${(props) => (props.error !== undefined && props.error !== '' ? '#FDF1EF' : '#ffffff')};
    border: ${(props) =>
        props.error !== undefined && props.error !== '' ? '2px solid #E84C3D' : '1px solid #cecece'}!important;
    box-sizing: border-box;
    border-radius: 32px;
    min-width: 360px;
    font-size: 1rem;
    line-height: 1.5rem;
    max-height: 3rem;
    &:disabled {
        background: #f4f4f4;
        opacity: 0.6;
        pointer-events: none;
        color: #171717;
    }
    &:hover {
        border: ${(props) =>
            props.error !== undefined && props.error !== '' ? '2px solid #E84C3D' : '1px solid #66798e'} !important;
    }
    &:focus {
        outline: ${(props) => (props.error !== undefined && props.error !== '' ? 'none' : '')};
        box-shadow: ${(props) =>
            props.error !== undefined && props.error !== ''
                ? ''
                : '0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02)'};
        color: #002043;
    }
    ::placeholder {
        font-weight: 400;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-text-fill-color: inherit;
        box-shadow: '';
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }
`;
const StyledSpan = styled.span`
    position: absolute;
    align-self: center;
    right: 0.75rem;
    z-index: 100;
    font-size: 1rem;
    &:hover {
        color: #66798e;
        cursor: pointer;
    }
`;
const StyledPasswordInputContainer = styled.div`
    display: flex;
    position: relative;
`;
