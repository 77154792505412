import { statusStyle, stringToCamelCase } from '../../../utils';
import { LABEL } from '../../../constants';
import {
    subTitleStyle,
    sh80,
    sh184,
    sh96,
    sh120,
    sh72,
    sh100,
    sh38,
    sh160,
    sh92,
    sh128,
    sh144,
    sh48,
    sh56,
    sh200,
    sh240,
} from '../../../styles';
import { useContext } from 'react';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import React from 'react';
import { DateHeader } from '../DateHeaderMenu';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../../routes';
import { IHistoryTab } from '../../../utils/permissionTypes';

interface IHistoryTableColumnsProps {
    filters: ISort_FilterInput;
    historyTab: IHistoryTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    lastUpdatedDropdown: string;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    changeRequestColumn: boolean;
}

interface IColumnReturnType {
    columns: ITableColumn[];
}

export const useHistoryTableColumns = ({
    filters,
    historyTab,
    lastUpdatedDropdown,
    setFilters,
    setLastUpdatedDropdown,
    changeRequestColumn,
}: IHistoryTableColumnsProps): IColumnReturnType => {
    const { sortData, setSortData, dateFilter, setDateFilter, orderTypePills } = useContext(DashboardBranchContext);
    const _history = useHistory();

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleDateFilter = (item: string, value: string) => {
        setLastUpdatedDropdown(item);
        const temp = [...filters];
        let currentFilter = 'lastUpdated';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        temp[1] = tempObj;
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
        setFilters(temp);
    };

    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data =
            item.rawData.transactionRef !== null
                ? {
                      transactionRef: item.rawData.transactionRef,
                      tab: 'history',
                      endpoint: 'branchDashboard',
                      url: _history.location.pathname,
                  }
                : {
                      orderNo: item.rawData.orderNo,
                      tab: 'history',
                      endpoint: 'branchDashboard',
                      url: _history.location.pathname,
                  };
        const _orderType = orderTypePills.tags.find((item) => item.active === true);
        _orderType?.title.toLowerCase() === 'transactions'
            ? _history.push(`${_history.location.pathname}/${ROUTES.orderSummary}`, {
                  orderNumber: item.rawData.orderNo,
                  ...data,
              })
            : _history.push(`${_history.location.pathname}/${ROUTES.orderSummaryChangeRequest}`, {
                  orderNumber: item.rawData.orderNo,
                  ...data,
              });
    };

    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    lastUpdatedDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(lastUpdatedDropdown) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const columnsTransaction: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(lastUpdatedDropdown) }],
            viewStyle: {
                width: sh100,
            },
            title: lastUpdatedDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(lastUpdatedDropdown) ? 700 : 400,
            },
        },
        {
            key: [{ key: 'clientName' }, { key: 'clientIdNum', textStyle: subTitleStyle }, { key: 'accountType' }],
            viewStyle: {
                width: sh184,
            },
            title: LABEL.investorName,
            subtitle: LABEL.idNo,
            customItem: true,
            icon: {
                name: handleSortIcon('clientName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('clientName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'clientName' ? 700 : 400,
            },
        },
        {
            key: [
                { key: 'orderNo', textStyle: { fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400 } },
                { key: 'transactionRef', textStyle: subTitleStyle },
            ],

            viewStyle: {
                width: sh96,
                justifyContent: 'center',
            },
            title: LABEL.transNo,
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
        },

        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh92,
            },
            icon: {
                name: 'arrow-down',
                size: '1rem',
                style: { opacity: '0.2' },
            },
            title: LABEL.transTypes,
        },
        {
            key: [{ key: 'fundType' }, { key: 'paymentMethod', textStyle: subTitleStyle }],
            viewStyle: {
                width: sh80,
            },
            title: LABEL.products,
            subtitle: LABEL.fundType,
        },
        {
            key: [{ key: 'totalInvestment', textStyle: { fontWeight: 700 } }],
            viewStyle: {
                width: sh160,
            },
            title: LABEL.totalInvestments,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },

        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh100,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: { width: sh72 },
            title: LABEL.remarks,
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: historyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                historyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'history-view-column',
        },
    ];

    const columnsChangeRequest: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(lastUpdatedDropdown) }],
            viewStyle: {
                width: sh128,
            },
            title: lastUpdatedDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(lastUpdatedDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }, { key: 'transactionRef', textStyle: subTitleStyle }],

            viewStyle: {
                width: sh144,
            },
            title: LABEL.transNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: 16,
                style: { opacity: '0.2' },
            },
        },
        {
            icon: {
                name: handleSortIcon('risk'),
                size: '1rem',
            },
            key: [{ key: 'risk', textStyle: { fontWeight: sortData[0].column === 'risk' ? 700 : 400 } }],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.risk,
            onPressHeader: () => handleSort('risk'),
            titleStyle: {
                fontWeight: sortData[0].column === 'risk' ? 700 : 400,
            },
        },
        // {
        //     icon: {
        //         name: handleSortIcon('mobileNumber'),
        //         size: '1rem',
        //     },
        //     key: [
        //         { key: 'mobileNumber', textStyle: { fontWeight: sortData[0].column === 'mobileNumber' ? 700 : 400 } },
        //     ],
        //     viewStyle: {
        //         width: showCheckBox ? sh128 : sh136,
        //     },
        //     title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_MOBILE,
        //     onPressHeader: () => handleSort('mobileNumber'),
        //     titleStyle: {
        //         fontWeight: sortData[0].column === 'mobileNumber' ? 700 : 400,
        //     },
        // },
        // {
        //     key: [{ key: 'email' }],
        //     viewStyle: {
        //         width: showCheckBox ? sh176 : sh184,
        //     },
        //     title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_EMAIL,
        //     icon: {
        //         name: handleSortIcon('email'),
        //         size: '1rem',
        //     },
        //     customItem: true,
        //     onPressHeader: () => handleSort('email'),
        //     titleStyle: {
        //         fontWeight: sortData[0].column === 'email' ? 700 : 400,
        //     },
        // },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh240,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh200,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: {
                width: sh56,
            },
            title: LABEL.remarks,
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: sh48,
            },
            itemIcon: {
                name: historyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                historyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'history-view-column',
        },
    ];

    return { columns: changeRequestColumn ? columnsChangeRequest : columnsTransaction };
};
