import React, { Fragment, useContext, useEffect } from 'react';
import { AdvanceTable } from '../../../../components';
import { EMPTY_STATES, SYSTEM_ADMIN, LABEL } from '../../../../constants';
import { subTitleStyle } from '../../../../styles';
import { statusStyle } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { GroupBy } from '../GroupBy';
import { CreatedOn } from '../../../Advisers/CreatedOn';

import * as Routes from '../../../../routes';
import AdviserAccessContext from '../../../../context/SystemAdminContext/adviserAccess';
import CustomItem from '../CustomItem';

export interface AdviserAccessProps {
    searchEmpty: boolean;
}

export const Advisers: React.FC<AdviserAccessProps> = ({ searchEmpty }: AdviserAccessProps) => {
    // Context
    const {
        createdOnDropdown,
        allAdvisers,
        filters,
        label,
        searchInput,
        shouldUpdate,
        sortData,
        setCreatedOnDropdown,
        setFilters,
        setLabel,
        setShouldUpdate,
        setSortData,
    } = useContext(AdviserAccessContext);
    const history = useHistory();

    const handleView = (item: IColumnItemAccordion) => {
        const data = {
            userId: item.rawData.agentId,
            name: item.rawData.agentName,
            code: item.rawData.agentCode,
            status: item.rawData.status,
        };
        history.push(Routes.systemAdminAdviserView, data);
    };

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleCreatedOnFilter = (item: string, value: string) => {
        const temp = [...filters];
        let currentFilter = 'createdOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        setCreatedOnDropdown({ value: item, keyName: currentFilter });
        temp[0] = {
            column: currentFilter,
            value: '',
        };
        setFilters(temp);
        setSortData([{ column: currentFilter, value: value }]);
    };

    const handleCreatedOn = (menu: IHeaderMenu) => {
        return (
            <CreatedOn
                {...menu}
                dropdownData={[
                    // eslint-disable-next-line react/prop-types
                    createdOnDropdown.value,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === createdOnDropdown.keyName ? sortData[0].value : '',
                ]}
                setData={handleCreatedOnFilter}
            />
        );
    };

    const columns: ITableColumn[] = [
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],

            viewStyle: {
                width: '13.89vw',
            },
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 },
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
            onPressHeader: () => handleSort('agentName'),
            title: SYSTEM_ADMIN.LABEL_ADVISER_NAME,
            subtitle: SYSTEM_ADMIN.LABEL_CODE,
        },
        {
            key: [
                { key: 'bdmName', textStyle: { fontWeight: sortData[0].column === 'bdmName' ? 700 : 400 } },
                { key: 'bdmCode' },
            ],
            viewStyle: {
                width: '13.3vw',
            },
            title: SYSTEM_ADMIN.LABEL_BDM_NAME,
            subtitle: SYSTEM_ADMIN.LABEL_CODE,
            icon: {
                name: handleSortIcon('bdmName'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'bdmName' ? 700 : 400 },
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'bdmName' ? 700 : 400,
            },
            onPressHeader: () => handleSort('bdmName'),
        },
        {
            key: [{ key: 'channel', textStyle: { fontWeight: sortData[0].column === 'channel' ? 700 : 400 } }],
            viewStyle: {
                width: '7.78vw',
            },
            title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_CHANNEL,
            icon: {
                name: handleSortIcon('channel'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'channel' ? 700 : 400 },
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'channel' ? 700 : 400,
            },
            onPressHeader: () => handleSort('channel'),
        },
        {
            key: [{ key: 'agency', textStyle: { fontWeight: sortData[0].column === 'agency' ? 700 : 400 } }],
            viewStyle: {
                width: '7.78vw',
            },
            title: SYSTEM_ADMIN.LABEL_AGENCY,
            icon: {
                name: handleSortIcon('agency'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'agency' ? 700 : 400 },
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'agency' ? 700 : 400,
            },
            onPressHeader: () => handleSort('agency'),
        },
        {
            key: [{ key: 'licenseCode', textStyle: { fontWeight: sortData[0].column === 'licenseCode' ? 700 : 400 } }],
            viewStyle: {
                width: '11.67vw',
            },
            title: SYSTEM_ADMIN.LABEL_LICENSE_CODE,
            icon: {
                name: handleSortIcon('licenseCode'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'licenseCode' ? 700 : 400 },
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'licenseCode' ? 700 : 400,
            },
            onPressHeader: () => handleSort('licenseCode'),
        },
        {
            customItem: true,
            key: [{ key: createdOnDropdown.keyName }],
            viewStyle: {
                width: '8.33vw',
            },
            title: createdOnDropdown.value,
            icon: {
                name: 'caret-down',
                size: '1rem',
            },
            titleStyle: {
                fontWeight: sortData[0].column === createdOnDropdown.keyName ? 700 : 400,
            },
            RenderHeaderMenu: (props: IHeaderMenu) => handleCreatedOn(props),
        },

        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: '6.73vw',
            },
            title: LABEL.status,
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'status' ? 700 : 400 },
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [{ key: 'omniEnabled' }],
            customItem: true,
            titleStyle: { border: 0 },
            viewStyle: {
                width: '3.19vw',
                border: '1px transparent',
            },
            title: SYSTEM_ADMIN.LABEL_OMNI,
        },
        {
            key: [],
            noBorder: true,
            itemIcon: {
                name: 'eye-show',
                size: '1.75rem',
            },
            titleStyle: { border: 0 },
            viewStyle: {
                width: '3.19vw',
            },
            title: SYSTEM_ADMIN.LABEL_VIEW,
            onClickItem: handleView,
        },
    ];

    useEffect(() => {
        setLabel([]);
    }, [allAdvisers]);
    return (
        <Fragment>
            <AdvanceTable
                data={allAdvisers}
                columns={columns}
                onEmptyState={
                    searchEmpty ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateAdviserAccess
                }
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItem
                        {...props}
                        isSortedColumn={sortData[0].column}
                        shouldUpdate={shouldUpdate}
                        setShouldUpdate={setShouldUpdate}
                    />
                )}
                groupByLabel={'branch'}
                RenderGroupByLabel={(props: ITableGroupBy) => <GroupBy {...props} label={label} />}
                isSearchResult={searchInput.value !== ''}
                searchInput={searchInput.value}
            />
        </Fragment>
    );
};
